import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  DP_DOWNLOAD_FAILURE,
  DP_DOWNLOAD_REQUEST,
  LONG_RUNNING_ADD_TASK_REQUEST,
  LONG_RUNNING_REMOVE_TASK_REQUEST,
  LONG_RUNNING_RETRY_TASK_FAILURE,
  LONG_RUNNING_RETRY_TASK_REQUEST,
  LONG_RUNNING_TASK_FAILURE,
  LONG_RUNNING_TASK_REQUEST,
  LONG_RUNNING_TASK_SUCCESS,
  LONG_RUNNING_TRIGGER_TASK_FAILURE,
  LONG_RUNNING_TRIGGER_TASK_REQUEST,
  PLAYER_DETAIL_REQUEST,
} from "../../../constants";
import { enqueueSnackbar } from "../../../snackbars/actions";
import LongRunningTaskService from "./long-running-task-service";

const loadLongRunningTaskList = function* (action) {
  try {
    const data = yield call(
      LongRunningTaskService.loadTaskList,
      action.contractId,
      action.brand,
      action.partner
    );
    yield put({
      type: LONG_RUNNING_TASK_SUCCESS,
      payload: data,
    });

    action.cb != null && typeof action.cb === "function" && action.cb(data);
  } catch (e) {
    console.error("long running task", e);
    yield put({
      type: LONG_RUNNING_TASK_FAILURE,
    });
  }
};

const addTask = function* (action) {
  try {
    yield call(
      LongRunningTaskService.addTask,
      action.taskId,
      action.contractId,
      action.brand,
      action.partner,
      action.username
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.longRunningTask.insertSuccess",
          variables: [action.taskName],
        },
        options: {
          key: "LongRunningTask_error",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {}
};

const cancelLongRunningTask = function* (action) {
  try {
    yield call(
      LongRunningTaskService.removeTask,
      action.taskId,
      action.brand,
      action.partner
    );
    yield put ({
      type: PLAYER_DETAIL_REQUEST,
      contractId: action.contractId,
      brand: action.brand,
      partner: action.partner,
      isPam: action.pam,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.longRunningTask.cancelSuccess",
          variables: [action.taskName],
        },
        options: {
          key: "LongRunningTask_error",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {}
};

const retryLongRunningTask = function* (action) {
  try {
    yield call(
      LongRunningTaskService.retryTask,
      action.taskId,
      action.componentId,
      action.componentDesc
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.longRunningTask.retrySuccess",
          variables: [action.componentDesc],
        },
        options: {
          key: "LongRunningTask_error",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("long running task", e);
    yield put({
      type: LONG_RUNNING_RETRY_TASK_FAILURE,
    });
  }
};

const triggerLongRunningTask = function* (action) {
  try {
    yield call(
      LongRunningTaskService.triggerTask,
      action.taskId,
      action.brand,
      action.partner,
      action.username
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.longRunningTask.triggerSuccess",
          variables: [action.taskName],
        },
        options: {
          key: "LongRunningTask_error",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("long running task", e);
    yield put({
      type: LONG_RUNNING_TRIGGER_TASK_FAILURE,
    });
  }
};

const getDataPortabilityAttachment = function* (action) {
  try {
    var data = yield call(
      LongRunningTaskService.getDataPortabilityAttachment,
      action.contractId,
      action.values
    );

    Object.entries(data.attachmentData).forEach(([fileName, fileValue]) => {
      let link = document.createElement("a");
      link.download = fileName;
      const byteCharacters = atob(fileValue);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let uArray = new Uint8Array(byteNumbers);
      let blob = new Blob([uArray], { type: "application/octet-stream" });
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.longRunningTask.downloadSuccess",
        },
        options: {
          key: "Blocklist_error",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("long running task", e);
    yield put({
      type: DP_DOWNLOAD_FAILURE,
    });
  }
};

const LongRunningTaskSaga = function* longRunningTaskSaga() {
  yield takeLatest(LONG_RUNNING_TASK_REQUEST, loadLongRunningTaskList);
  yield takeLatest(LONG_RUNNING_ADD_TASK_REQUEST, addTask);
  yield takeLatest(LONG_RUNNING_REMOVE_TASK_REQUEST, cancelLongRunningTask);
  yield takeLatest(LONG_RUNNING_RETRY_TASK_REQUEST, retryLongRunningTask);
  yield takeLatest(LONG_RUNNING_TRIGGER_TASK_REQUEST, triggerLongRunningTask);
  yield takeLatest(DP_DOWNLOAD_REQUEST, getDataPortabilityAttachment);
};

export default LongRunningTaskSaga;
