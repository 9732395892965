import React, {useState} from "react";
import { useRouteMatch } from "react-router-dom";
import { useSession } from "../../../../../utilities/hooks/useSession";
import { useIndividualPlayerLoginHistory } from "../../../../../utilities/hooks/useIndividualPlayerLoginHistory";
import Table from "../../../../../Layouts/Table/Table";
import ListPanel from "../../../../../Layouts/Panel/ListPanel";

function LoginHistory({ widget, playerInfo }) {
  const match = useRouteMatch();
  const { brandSelected, partnerSelected } = useSession();

  const contractId = match.params.id;
  const { data, columns, loading, loadData } = useIndividualPlayerLoginHistory();

  const fetchHistory = (query)=>{
    loadData(contractId, brandSelected.id, partnerSelected.id, query.page, query.pageSize)
  }



  return widget ? (
    <ListPanel loading={playerInfo.loading} data={playerInfo.lastLogin} fields={columns} />
  ) : (
    <Table
      isLoading={loading}
      pageSize={data.pageSize}
      title={"label.individualplayer.loginHistory.list"}
      type={"advanced"}
      pagination={true}
      columns={columns}
      data={{...data}}
      onFetch={fetchHistory}
      exportButton={false}
      page={data ? data.page : 0}
      actions={[]}
    />
  );
}

export default LoginHistory;
