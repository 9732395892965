import axios from "axios";

import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_BOOKMARKS_ACTION,
  ADD_BOOKMARKS_REQUEST,
  GET_BOOKMARKS_ACTION,
  GET_BOOKMARKS_REQUEST,
  REMOVE_BOOKMARKS_ACTION,
  REMOVE_BOOKMARKS_REQUEST,
  SESSION_INFO_REQUEST,
} from "../constants";

const addBookmark = async ({ id }) => {
  try {
    const response = await axios.post(`/gov/api/rest/v1/bookmark?menuItemId=${id}`);
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const removeBookmark = async ({ id }) => {
  try {
    const response = await axios.delete(
      `/gov/api/rest/v1/bookmark?menuItemId=${id}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getBookmark = async () => {
  try {
    const response = await axios.get(`/gov/api/rest/v1/bookmark`);
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const addBookmarks = function* (action) {
  const data = yield call(addBookmark, action.payload);
  yield put({
    type: ADD_BOOKMARKS_ACTION,
    payload: data,
  });

  yield put({
    type: SESSION_INFO_REQUEST,
    payload: { result: true },
  });
};

const removeBookmarks = function* (action) {
  const data = yield call(removeBookmark, action.payload);
  yield put({
    type: REMOVE_BOOKMARKS_ACTION,
    payload: data,
  });

  yield put({
    type: SESSION_INFO_REQUEST,
    payload: { result: true },
  });
};

const getBookmarks = function* (action) {
  const data = yield call(getBookmark, action.payload);
  yield put({
    type: GET_BOOKMARKS_ACTION,
    payload: data,
  });
};

const BookmarksSaga = function* bookmarksSaga() {
  yield takeEvery(ADD_BOOKMARKS_REQUEST, addBookmarks);
  yield takeEvery(REMOVE_BOOKMARKS_REQUEST, removeBookmarks);
  yield takeEvery(GET_BOOKMARKS_REQUEST, getBookmarks);
};

export default BookmarksSaga;
