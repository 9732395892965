import axios from "axios";

class PasswordServices {
  async pwResetCheck(currentPassword, newPassword, username) {
    let url = `/gov/api/rest/changePasswordExpired?username=${username}`;
    const response = await axios.put(url, {
      id: "0",
      username: username,
      oldPassword: currentPassword,
      newPassword: newPassword,
      firstLogin: null,
    });

    if (response && response.status !== 204) {
      throw Error("Invalid response for add platform nickname");
    }
  }
}
const passwordServices = new PasswordServices();
export default passwordServices;
