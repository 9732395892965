import { call, put } from "redux-saga/effects";
import {
  REASONS_SUCCESS,
  REASONS_FAILURE,
  REASONS_SAVE_REQUEST,
  REASONS_SAVE_SUCCESS,
  REASONS_SAVE_FAILURE,
  REASONS_REQUEST,
  REASONS_ACTIONS_SUCCESS,
  REASONS_ACTIONS_FAILURE,
  REASONS_ACTIONS_REQUEST,
  LANGUAGES_REQUEST,
  LANGUAGES_SUCCESS,
  LANGUAGES_FAILURE,
  REASON_DELETE_SUCCESS,
  REASON_DELETE_FAILURE,
  REASON_DELETE_REQUEST,
} from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import reasonsService from "./reasons-service";
import { takeLatest } from "@redux-saga/core/effects";

const getReasons = function* (action) {
  try {
    const data = yield call(
      reasonsService.getReasons,
      action.brand,
      action.partner,
      action.searchTerms
    );
    yield put({
      type: REASONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: REASONS_FAILURE,
    });
  }
};

const getReasonsActions = function* (action) {
  try {
    const data = yield call(
      reasonsService.getActions,
      action.brand,
      action.partner
    );
    yield put({
      type: REASONS_ACTIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: REASONS_ACTIONS_FAILURE,
    });
  }
};

const deleteReason = function* (action) {
  try {
    yield call(
      reasonsService.deleteReason,
      action.brand,
      action.partner,
      action.data,
      action.actionFilter
    );
    yield put({
      type: REASON_DELETE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.clubCard.reasons.delete_success",
          variables: ["label.clubCard.reasons"],
        },
        options: {
          key: "Reasons",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({
      type: REASONS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      searchTerms: action.actionFilter,
    });
  } catch (e) {
    yield put({
      type: REASON_DELETE_FAILURE,
    });
  }
};

const getLanguages = function* () {
  try {
    const data = yield call(reasonsService.getLanguages);
    yield put({
      type: LANGUAGES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: LANGUAGES_FAILURE,
    });
  }
};
const saveReason = function* (action) {
  try {
    yield call(
      reasonsService.saveReason,
      action.brand,
      action.partner,
      action.data,
      action.actionType,
      action.actionFilter
    );
    yield put({
      type: REASONS_SAVE_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            action.actionType === "edit"
              ? "label.clubCard.reasons.edit_success"
              : "label.clubCard.reasons.add_success",
          variables: ["label.clubCard.reasons"],
        },
        options: {
          key: "Reasons",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({
      type: REASONS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      searchTerms: action.actionFilter,
    });
  } catch (e) {
    yield put({
      type: REASONS_SAVE_FAILURE,
    });
  }
};

const ReasonsSaga = function* AccountDetailsSaga() {
  yield takeLatest(REASONS_REQUEST, getReasons);
  yield takeLatest(REASONS_SAVE_REQUEST, saveReason);
  yield takeLatest(REASONS_ACTIONS_REQUEST, getReasonsActions);
  yield takeLatest(LANGUAGES_REQUEST, getLanguages);
  yield takeLatest(REASON_DELETE_REQUEST, deleteReason);
};
export default ReasonsSaga;
