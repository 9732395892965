import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { MenuItem, TextField } from "@material-ui/core";
import FormLabel from "./form-label";
import { useTranslator } from "../../utilities/hooks/useTranslator";

function SelectField({
  isConditional = false,
  optional,
  onChange,
  label,
  tooltip,
  options,
  placeholder,
  labelKey,
  valueKey,
  ...props
}) {
  const t = useTranslator();
  const { values } = useFormikContext();
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  const { value } = field;
  const isError = touched && error && true;

  const plchld = placeholder
    ? typeof placeholder === "string"
      ? t({ needle: placeholder })
      : placeholder
    : props.multiple
    ? t({ needle: "label.placeholders.multiselect" })
    : t({ needle: "label.placeholders.select" });

  return (
    <TextField
      {...field}
      value={
        value == null
          ? ""
          : valueKey
          ? value
          : options.find(
              (it) =>
                "" + it.value === "" + value.value ||
                "" + it.value === "" + value
            ) || ""
      }
      error={isError}
      {...props}
      select
      label={
        label ? (
          <FormLabel optional={optional} label={label} tooltip={tooltip} />
        ) : null
      }
      helperText={!isConditional && isError && error}
      onChange={(event) => {
        field.onChange(event);
        onChange && onChange(event.target.value, values);
      }}
      SelectProps={{ displayEmpty: true }}
    >
      <MenuItem value="">
        <span className="placeholder">{plchld}</span>
      </MenuItem>
      {options.map((item, index) => (
        <MenuItem key={index} value={valueKey ? item[valueKey] : item}>
          {labelKey ? item[labelKey] : item}
        </MenuItem>
      ))}
    </TextField>
  );
}

SelectField.defaultProps = {
  options: [],
};

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
};

export default SelectField;
