import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import { CRITERIA_TYPE_SELECT } from "../../../constants";

class AccountTransactionsService {
  async getFilterFields() {
    const filters = [
      {
        name: "period",
        label: "label.player.accountTransactions.period",
        type: "period",
        placeholder: "label.placeholders.date",
      },
      {
        name: "showPending",
        label: "label.player.accountTransactions.pending",
        type: "checkbox",
      },
    ];

    let url = `/gov/api/rest/v1/ewl/operationGroup`;
    const response = await axios.get(url);
    let groupList = "";
    if (response && response.data) {
      groupList = {
        name: "groupId",
        label: "label.player.accountTransactions.group",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        hidden: (values) => {
          return values.showPending;
        },
        options: [],
        initialValue: 113,
      };
      response.data.forEach((it) => {
        if (it.flagVisibility === 1) {
          groupList["options"].push({
            label: it.name,
            value: it.operationGroupId,
          });
        }
      });

      filters.unshift(groupList);
    }

    filters.unshift({
      name: "transactionId",
      label: "label.individualplayer.tickets.transactionId",
    });

    return filters;
  }

  async getTransactions(
    contractId,
    brand,
    partner,
    searchTerms,
    page,
    pageSize,
    orderBy
  ) {
    const order =
      orderBy != null
        ? encodeURIComponent(searchUtils.getOrder(orderBy.order)) +
          orderBy.field
        : null;
    const url = `/gov/api/rest/v1/contracts/${contractId}/ewl_transactions?brand=${brand.id}&partner=${partner.id}&page=${page}&pageSize=${pageSize}&order=${order}`;
    const queryParam = searchTerms
      ? searchUtils.getQueryParam(searchTerms, true)
      : "";

    const response = await axios.get(url + queryParam);
    if (response && response.data && response.data.results) {
      return {
        data: response.data.results,
        page: page,
        totalCount: response.data.totalCount,
      };
    }
    throw Error("Invalid response for expiring bonus");
  }

  async getTransactionsDetails(
    transactionId,
    externalSystemId,
    confirmTransactionId,
    isConfirm,
    brand,
    partner,
    phase
  ) {
    const walletId = confirmTransactionId ? confirmTransactionId : transactionId;
    const url = ` /gov/api/rest/v1/contracts/ewl_transaction/details/${walletId}?brand=${brand.id}&partner=${partner.id}`;
    const response = await axios.get(url);
    if (response && response.data) {
      response.data["transactionId"] = transactionId;
      response.data["confirmTransactionId"] = confirmTransactionId;
      response.data["phase"] = phase;
      return response.data;
    }
    throw Error("Invalid response for expiring bonus");
  }

  async setWriteOff(
    amount,
    reason,
    brandSelected,
    partnerSelected,
    contractId,
    transactionId,
    gameAccountId
  ) {
    const response = await axios.post("/gov/api/rest/v1/contracts/write_off", {
      accountId: gameAccountId,
      reason: reason,
      amount: amount,
      brandId: brandSelected.id,
      confirmInternalTransactionId: transactionId,
      contractId: contractId,
      partnerId: partnerSelected.id,
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for expiring bonus");
  }

  async setReserve(action, body) {
    const url =
      "/gov/api/rest/v1/contracts/" +
      (action === "confirmReserve" ? "confirmReserve" : "cancelReserve");
    const response = await axios.post(url, body);

    if (response == null || response.status !== 204) {
      throw Error("Invalid response account transaction " + action);
    }
  }
}
const accountTransactionsService = new AccountTransactionsService();
export default accountTransactionsService;
