import {
  INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_SUCCESS,
  INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_FAILURE,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";

const initialState = {
  loading: true,
  customerAccountUpdateList: {
    data: [],
    columns: [
      {
        field: "customerAccountUpdateDate",
        title: "label.individualplayer.customerAccountUpdate.date",
        default: true,
        render: (rowData) => {
          if (rowData["customerAccountUpdateDate"]) {
            return (
              <Moment format="L LT">
                {rowData["customerAccountUpdateDate"]}
              </Moment>
            );
          }
        },
      },

      {
        field: "transactionCallerId",
        title:
          "label.individualplayer.customerAccountUpdate.transactionCallerId",
      },
      {
        field: "reason",
        title: "label.individualplayer.customerAccountUpdate.reason",
      },
      {
        field: "channelCallerId",
        title: "label.individualplayer.customerAccountUpdate.channelCallerId",
      },
      {
        field: "clientCallerId",
        title: "label.individualplayer.customerAccountUpdate.clientCallerId",
      },
      {
        field: "systemCallerId",
        title: "label.individualplayer.customerAccountUpdate.systemCallerId",
      },
    ],
    detailCols: [
      {
        field: "customerAccountUpdateTypeId",
        title: "label.individualplayer.customerAccountUpdate.typeId",
      },
      {
        field: "customerAccountUpdateTypeLabel",
        title: "label.individualplayer.customerAccountUpdate.typeLabel",
      },
      {
        field: "customerAccountUpdateTypeDescription",
        title: "label.individualplayer.customerAccountUpdate.typeDescription",
      },
      {
        field: "customerAccountUpdateOldValue",
        title: "label.individualplayer.customerAccountUpdate.oldValue",
      },
      {
        field: "customerAccountUpdateNewValue",
        title: "label.individualplayer.customerAccountUpdate.newValue",
      },
    ],
  },
};
const CustomerAccountUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerAccountUpdateList: {
          ...state.customerAccountUpdateList,
          data: action.payload,
        },
      };
    }
    case INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        customerAccountUpdateList: {
          ...state.customerAccountUpdateList,
          data: null,
        },
      };
    }
    default:
      return state;
  }
};

export default CustomerAccountUpdateReducer;
