import axios from "axios";

class CustomerAccountUpdateService {
  async loadIndividualPlayerAccountUpdate(contractId, brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v1/smsconsole/contracts/${contractId}/customerAccountUpdateData?brand=${brand}&partner=${partner}`
    );
    if (response && response.data) {
      response.data.forEach((element, index) => {
        element.accountUpdateId = index;
      });
      return response.data;
    }
    throw Error("Invalid response for individual player account update");
  }
}

const customerAccountUpdateService = new CustomerAccountUpdateService();
export default customerAccountUpdateService;
