import axios from "axios";

class LinkedAccountsService {
  async loadLinkedAccounts(brand, partner, contractId) {
    let url = `/gov/api/rest/v1/player/linkAccount/${contractId}`;

    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for linked accounts");
  }

  async unlinkLinkedAccounts(brand, partner, contractId, linkedAccount) {
    let url = `/gov/api/rest/v1/player/linkAccount/${contractId}`;
    const body = {
      id: contractId,
      brandCode: brand.id,
      partnerCode: partner.id,
      contractId: linkedAccount,
    };
    const response = await axios.put(url, body, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && response.status !== 200) {
      throw Error("Invalid response for linked account unlink");
    }
  }

  async linkLinkedAccounts(brand, partner, contractId, linkedAccount) {
    let url = `/gov/api/rest/v1/player/linkAccount/${contractId}`;
    const body = {
      brandCode: brand.id,
      partnerCode: partner.id,
      contractId: linkedAccount.contract,
      linkAccounts: [
        {
          contractId: linkedAccount.contract,
          brandCode: linkedAccount.brand.id,
          partnerCode: linkedAccount.partner.id,
        },
      ],
    };
    const response = await axios.post(url, body, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && !(response.status == 200 || response.status == 201)) {
      throw Error("Invalid response for linked account creation");
    }
  }
}

const linkedAccountsService = new LinkedAccountsService();
export default linkedAccountsService;
