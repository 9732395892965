import { PAGE_ACTIONS_REQUEST, PAGE_ACTIONS_RESET } from "../constants";

const loadPageActions = (menuItem) => {
  return {
    type: PAGE_ACTIONS_REQUEST,
    payload: menuItem,
  };
};

const resetPageActions = () => {
  return {
    type: PAGE_ACTIONS_RESET,
  };
};

export { loadPageActions, resetPageActions };
