import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getUserPreferencesHandler } from "../../store/user-preferences/action";

export const useUserPreferences = (
  brand,
  partner,
  url,
  componentIdentifier
) => {
  const { userPreferences } = useSelector(
    (state) => state.UserPreferencesReducer
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (componentIdentifier) {
      dispatch(
        getUserPreferencesHandler(brand, partner, url, componentIdentifier)
      );
    }
  }, [dispatch, brand, partner, url, componentIdentifier]);

  return userPreferences;
};
