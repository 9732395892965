import axios from "axios";

class BarCodeService {
  async getBarCode(brand, partner) {
    let url = `/gov/api/rest/v1/sms/barCode?brandCode=${brand.id}&partnerCode=${partner.id}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for reasons list");
  }
}

const barCodeService = new BarCodeService();
export default barCodeService;
