import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";

import { EXTERNAL_URL_ACTION, EXTERNAL_URL_REQUEST } from "../constants";
import menuItemsService from "../../utilities/menuItems";

const replaceUrlVariables = async ({ openUrl, session, toReplace = {} }) => {
  let currencyResult = "";
  let tokenResult = "";
  let iFrameUrlDecoded = decodeURIComponent(openUrl);
  if (session !== undefined && session !== "") {
    iFrameUrlDecoded = iFrameUrlDecoded.replace(
      /{operatorId}/g,
      session.user.id
    );

    // get data from localStorage after Login
    const brand = session.brandSelected.id;
    const partner = session.partnerSelected.id;
    const defaultLanguage = session.defaultLanguage;

    iFrameUrlDecoded = iFrameUrlDecoded.replace(/{brandId}/g, brand);
    iFrameUrlDecoded = iFrameUrlDecoded.replace(/{partnerId}/g, partner);
    iFrameUrlDecoded = iFrameUrlDecoded.replace(/{language}/g, defaultLanguage);
  }

  if (iFrameUrlDecoded.indexOf("{currency}") !== -1) {
    const currencyResponse = await axios.get(
      "/gov/api/rest/v1/currencies/system_currency"
    );
    currencyResult = currencyResponse.data.currency;
    if (currencyResult) {
      iFrameUrlDecoded = iFrameUrlDecoded.replace(
        /{currency}/g,
        currencyResult
      );
    }
  }
  if (toReplace) {
    iFrameUrlDecoded = menuItemsService.parseOptionalParameters(
      iFrameUrlDecoded,
      toReplace
    );
  }
  if (iFrameUrlDecoded.indexOf("{sso}") !== -1) {
    const tokenResponse = await getToken(openUrl);
    tokenResult = tokenResponse.data.token;
    if (tokenResult) {
      iFrameUrlDecoded = iFrameUrlDecoded.replace(/{sso}/g, tokenResult);
    }
  }
  return iFrameUrlDecoded;
};

export const getToken = (openUrl) => {
  return axios.get(
    `/gov/api/rest/v2/token?requestedPageURL=${openUrl}&_=${new Date().getTime()}`
  );
};

const externalUrl = function* (action) {
  const data = yield call(replaceUrlVariables, action.payload);
  yield put({
    type: EXTERNAL_URL_ACTION,
    payload: data,
  });
};

const ExternalUrlSaga = function* externalUrlSaga() {
  yield takeEvery(EXTERNAL_URL_REQUEST, externalUrl);
};

export default ExternalUrlSaga;
