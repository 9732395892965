import { call, put } from "redux-saga/effects";
import {
  INDIVIDUAL_PLAYER_CONSENTS_LIST_REQUEST,
  INDIVIDUAL_PLAYER_CONSENTS_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_CONSENTS_LIST_FAILURE,
  INDIVIDUAL_PLAYER_CONSENTS_EDIT_SUCCESS,
  INDIVIDUAL_PLAYER_CONSENTS_EDIT_REQUEST,
  INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_REQUEST,
  INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_FAILURE,
} from "../../../constants";
import { takeLatest, delay } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import individualConsentsService from "./indivudual-consents-service";

const getConsentsList = function* (action) {
  try {
    const data = yield call(
      individualConsentsService.loadConsentsList,
      action.contractId,
      action.brand,
      action.partner,
      action.registrationLevelId
    );
    yield put({
      type: INDIVIDUAL_PLAYER_CONSENTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerConsents", e);
    yield put({
      type: INDIVIDUAL_PLAYER_CONSENTS_LIST_FAILURE,
    });
  }
};

const editConsent = function* (action) {
  try {
    const { contractId, brand, partner, userTypeId } = action;
    yield call(
      individualConsentsService.editConsent,
      action.contractId,
      action.brand,
      action.partner,
      action.consentTypeId,
      action.languageId,
      action.userTypeId,
      action.requestDate,
      action.state,
      action.text,
      action.version
    );
    // 1 second delay to wait for Solr soft commit (whose value is currently set to 1000ms)
    yield delay(1000);
    yield put({
      type: INDIVIDUAL_PLAYER_CONSENTS_EDIT_SUCCESS,
    });
    yield put({
      type: INDIVIDUAL_PLAYER_CONSENTS_LIST_REQUEST,
      contractId,
      brand,
      partner,
      registrationLevelId: userTypeId,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.consents.edit_successfully",
        },
        options: {
          key: "EditConsent",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
  } catch (e) {
    console.error("IndividualPlayerConsents", e);
  }
};

const getConsentsHistoryList = function* (action) {
  try {
    const data = yield call(
      individualConsentsService.loadConsentsHistoryList,
      action.contractId,
      action.brand,
      action.partner,
      action.consentTypeId,
      action.userTypeId
    );
    yield put({
      type: INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerConsents", e);
    yield put({
      type: INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_FAILURE,
    });
  }
};

const IndividualConsentsSaga = function* individualConsentsSaga() {
  yield takeLatest(INDIVIDUAL_PLAYER_CONSENTS_LIST_REQUEST, getConsentsList);
  yield takeLatest(INDIVIDUAL_PLAYER_CONSENTS_EDIT_REQUEST, editConsent);
  yield takeLatest(
    INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_REQUEST,
    getConsentsHistoryList
  );
};

export default IndividualConsentsSaga;
