import axios from "axios";

class LoyaltyTransactionTypesService {
  async getTransactionTypes() {
    const url = "/gov/api/rest/v2/loy/transactionTypes";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for transaction type list");
  }
}

const loyaltyTransactionTypesService = new LoyaltyTransactionTypesService();
export default loyaltyTransactionTypesService;
