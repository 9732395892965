import axios from "axios";

class AccountDetailsService {
  async getAccountDetails(contractId, brand, partner, currency) {
    let url = `/gov/api/rest/v1/contracts/${contractId}/ewl_account_details?brand=${brand.id}&partner=${partner.id}&currency=${currency}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }
}

const accountDetailsService = new AccountDetailsService();
export default accountDetailsService;
