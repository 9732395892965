import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "@material-ui/core";
import UnsafeHtml from "../unsafe-html";

const useStyle = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    userSelect: "none",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fill: "currentColor",
    flexShrink: 0,
    color: theme.palette.text.icon,
    fontSize: theme.typography.pxToRem(24),
    transition: theme.transitions.create("fill", {
      duration: theme.transitions.duration.shorter,
    }),
  },
  /* Styles applied to the root element if `color="primary"`. */
  colorPrimary: {
    color: theme.palette.primary2.main,
  },
  /* Styles applied to the root element if `color="secondary"`. */
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  /* Styles applied to the root element if `color="action"`. */
  colorAction: {
    color: theme.palette.action.active,
  },
  /* Styles applied to the root element if `color="error"`. */
  colorError: {
    color: theme.palette.error.main,
  },
  /* Styles applied to the root element if `color="disabled"`. */
  colorDisabled: {
    color: theme.palette.action.disabled,
  },
  /* Styles applied to the root element if `fontSize="inherit"`. */
  fontSizeInherit: {
    fontSize: "inherit",
  },
  /* Styles applied to the root element if `fontSize="small"`. */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(20),
  },
  /* Styles applied to the root element if `fontSize="large"`. */
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(35),
  },
}));

export default function CustomIcon({
  icon,
  color = "inherit",
  fontSize = "default",
  className,
}) {
  const classes = useStyle();
  return (
    <UnsafeHtml
      className={clsx(
        classes.root,
        {
          [classes[`color${capitalize(color)}`]]: color !== "inherit",
          [classes[`fontSize${capitalize(fontSize)}`]]: fontSize !== "default",
        },
        className
      )}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
}
