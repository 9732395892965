import React from "react";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import { useSelector } from "react-redux";

const CommunicationSettings = ({ playerInfo }) => {
  const { fields } = useSelector(
    (state) => state.PlayerDetailReducer.playerDetails.communicationSettings
  );

  const data = Object.keys(playerInfo).length > 0 ? playerInfo : null;

  return <ListPanel data={data} loading={data == null} fields={fields} />;
};

export default CommunicationSettings;
