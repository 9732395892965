import React, { Component } from "react";
import TrackNavLink from "../track-nav-link/track-nav-link";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
// Import Component(s)
import TreeViewItems from "./TreeView";
import Translate from "../Translator/Translate";
// Import Stylesheet(s)
// Import Icon(s)
import { ReactComponent as PinIcon } from "../../Images/Icon-Pin.svg";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { debounce } from "lodash";
import { sideMenuHandler } from "../../store/tree-menu/actions";
import SvgIcon from "@material-ui/core/SvgIcon";
import CustomIcon from "../CustomIcon/CustomIcon";
import { Hidden } from "@material-ui/core";
import clsx from "clsx";
import { withRouter } from "react-router";

import menuItemsService from "../../utilities/menuItems";
import { ReactComponent as TradeIcon } from "../../Images/icon_trademark.svg";
import Versions from "./versions";
import Modal from "../Modal/Modal";
import Button from "@material-ui/core/Button";
import DoubleArrow from "@material-ui/icons/DoubleArrow";

const drawerContentWidth = 300;
const styles = (theme) => ({
  cueUp: {
    transform: "rotate(-90deg)",
    color: theme.palette.primary.main + "!important",
  },
  buttonCueUp: {
    position: "absolute",
    top: "64px",
    backgroundColor: "transparent",
    backgroundImage: `linear-gradient(${theme.palette.background.paper},${theme.palette.background.paper},${theme.palette.background.paper}00)`,
    width: "100%",
    height: 50,
    right: 0,
    zIndex: 1,
    "&:hover": {
      // backgroundColor: theme.palette.background.
    },
  },
  cueDown: {
    transform: "rotate(90deg)",
    color: theme.palette.primary.main + "!important",
  },
  buttonCueDown: {
    position: "absolute",
    bottom: "40px",
    borderBottom: "1px solid " + theme.palette.divider,
    backgroundColor: "transparent",
    backgroundImage: `linear-gradient(${theme.palette.background.paper}00,${theme.palette.background.paper},${theme.palette.background.paper})`,
    right: 0,
    height: 50,
    zIndex: 1,
  },
  treeviewContainer: {
    position: "fixed",
    top: "calc(64px + 37px)",
    height: "calc(100% - 64px - 37px)",
    [theme.breakpoints.down("xs")]: {
      position: "static",
      height: "100%",
    },
    "& > .treeview": {
      display: "none",
    },
    width: 0,
  },
  treeviewContainerOpen: {
    width: "300px",
    "& > .treeview": {
      display: "flex",
    },
  },
  menuContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  noScrollbar: {
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  paper: {
    overflow: "hidden",
    boxShadow: "3px 0px 6px #00000029",
  },
  mobileWidth: {
    width: 296,
  },
  drawer: {
    width: "60px",
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& $headerIcon": {
      transition: "0.3s all ease-in-out",
      transform: "rotate(0deg)",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.icon,
    },
  },
  sideMenuContainer: {
    top: "0",
    height: "100%",
    position: "fixed",
    borderRight: "1px solid " + theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
  },
  sideMenuWrapper: {
    flex: "1 0 auto",
    height: "calc(100% - 40px)",
    display: "flex",
    overflowY: "auto",
    flexDirection: "column",
  },
  sideMenuContainerOpen: {
    boxShadow: "3px 0px 6px #00000029",
  },
  sideMenu: {
    width: "60px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  menuItem: {
    height: theme.spacing(7),
    "&.active": {
      backgroundColor: theme.palette.secondary2.light,
      borderRight: "5px solid " + theme.palette.secondary2.main,
      "& svg": {
        fill: theme.palette.text.highlight,
      },
      "&:hover": {
        backgroundColor: theme.palette.secondary2.light,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary2.light,
      "& svg": {
        fill: theme.palette.text.highlight,
      },
    },
  },
  drawerMenu: {
    transition: theme.transitions.create(["width", "opacity"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    color: theme.palette.text.primary2,
    marginLeft: "60px",
    opacity: 0,
    width: 0,
    flex: 1,
    overflow: "hidden",
  },
  drawerMenuOpen: {
    borderLeft: "1px solid " + theme.palette.divider,
    width: drawerContentWidth,
    display: "block",
    height: "100%",
    opacity: 1,
  },
  DrawerOpen: {
    width: drawerContentWidth + 60,
    overflowX: "hidden",
    "& $headerIcon": {
      transform: "rotate(-45deg)",
    },
  },
  header: {
    padding: theme.spacing(1),
    display: "flex",
    borderBottom: "1px solid " + theme.palette.divider,
    height: 37,
  },
  fakeHeader: {
    height: 37,
  },
  headerTitle: {
    justifyContent: "left",
    width: `calc(100% - 20px)`,
  },
  headerIcon: {
    justifyContent: "right",
    width: 20,
    height: 20,
    cursor: "pointer",
    color: "inherit",
    transition: "0.3s all ease-in-out",
    transform: "rotate(0deg)",
  },
  tradeIcon: {
    width: 60,
    height: 40,
    padding: 5,
    textAlign: "center",
    "& svg": {
      fontSize: 30,
    },
  },
});

class TreeMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false,
      parentMenu: null,
      openModal: false,
      showCueUp: false,
      showCueDown: props.isMobile ? false : true,
    };
    this.brand = props.session.brandSelected.id;
    this.partner = props.session.partnerSelected.id;
    this.parentRef = React.createRef();
  }

  componentDidMount() {
    this.props.loadMenu(this.props.session);

    if (!this.props.isMobile) {
      window.addEventListener("resize", () => {
        this.setVisualCue();
      });
      this.parentRef.current.addEventListener("scroll", () => {
        this.setVisualCue();
      });
    }
  }

  setVisualCue = () => {
    if (this.isOverflown(this.parentRef.current)) {
      if (!this.state.showCueUp && this.parentRef.current.scrollTop > 0) {
        this.setState({ showCueUp: true });
      }
      if (this.state.showCueUp && this.parentRef.current.scrollTop === 0) {
        this.setState({ showCueUp: false });
      }

      if (!this.state.showCueDown && this.parentRef.current.scrollTop === 0) {
        this.setState({ showCueDown: true });
      }
      if (this.state.showCueDown && this.parentRef.current.scrollTop > 0) {
        this.setState({ showCueDown: false });
      }
    } else {
      this.setState({ showCueUp: false, showCueDown: false });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.brand !== this.props.session.brandSelected.id ||
      this.partner !== this.props.session.partnerSelected.id
    ) {
      this.brand = this.props.session.brandSelected.id;
      this.partner = this.props.session.partnerSelected.id;
      this.props.loadMenu(this.props.session);
    }
    let foundItem = menuItemsService.findItemByLocation(
      this.props.location,
      this.props.flatMenuItems
    );
    if (foundItem) {
      foundItem = menuItemsService.findItemRoot(
        foundItem,
        this.props.flatMenuItems
      );
      if (foundItem && foundItem.id !== this.state.current) {
        this.setState({ current: foundItem.id, parentMenu: foundItem });
      }
    }
    if (
      !this.props.isMobile &&
      prevProps.menuItems.length !== this.props.menuItems.length
    ) {
      this.setVisualCue();
    }
  }

  isOverflown = (element) => {
    return element ? element.scrollHeight > element.clientHeight : false;
  };

  isOpened = () => {
    return (
      this.state.openDrawer || (this.props.isPinned && !this.props.pinDisabled)
    );
  };

  handleDrawerOpen = debounce((leaf) => {
    if (!leaf) {
      this.setState({ openDrawer: true });
    }
  }, 100);

  handleDrawerClose = debounce(() => {
    this.setState({ openDrawer: false });
  }, 100);

  handleSubMenu = debounce((submenu) => {
    this.setState({
      parentMenu: submenu,
      openDrawer: submenu.children && submenu.children.length > 0,
    });
  }, 200);

  getMenu = () => {
    const { menuItems, classes } = this.props;
    return menuItems.map((menuItem, index) => {
      let url = menuItemsService.getItemUrl(menuItem.url);
      return (
        <ListItem
          component={TrackNavLink}
          element={"treeMenu"}
          tooltip={menuItem.children.length === 0}
          labelTo={menuItem.label}
          to={url}
          key={index}
          isActive={(match, location) =>
            match || this.state.current === menuItem.id
          }
          onMouseEnter={() => this.handleSubMenu(menuItem)}
          onMouseLeave={() => this.handleSubMenu.cancel()}
          className={classes.menuItem}
          id={"menuItem_" + menuItem.id}
        >
          <CustomIcon icon={menuItem.imageOff} />
        </ListItem>
      );
    });
  };

  togglePinned = () => {
    const isPinned = !this.props.isPinned;
    sessionStorage.setItem("isPinned", isPinned);
    this.props.togglePinned(isPinned);
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { classes, menuItems, isPinned, pinDisabled } = this.props;

    const drawerClasses = [classes.drawer];
    if (isPinned && !pinDisabled) {
      drawerClasses.push(classes.DrawerOpen);
    }
    const drawerMenuClasses = [classes.drawerMenu];
    if (this.isOpened()) {
      drawerMenuClasses.push(classes.drawerMenuOpen);
    }
    const drawerProps = {
      variant: "permanent",
      open: true,
    };
    if (this.props.isMobile === true) {
      drawerProps.anchor = "left";
      drawerProps.variant = "temporary";
      drawerProps.open = this.props.open;
      drawerProps.onClose = this.props.handleOpen;
      drawerProps["ModalProps"] = { keepMounted: true };
    }

    const { openModal, showCueUp, showCueDown } = this.state;
    const { controlVersion } = this.props;
    return (
      <>
        <Drawer
          classes={{
            root: drawerClasses.join(" "),
            paper: clsx(classes.paper, {
              [classes.mobileWidth]: this.props.isMobile === true,
            }),
          }}
          {...drawerProps}
        >
          <Hidden xsDown implementation={"css"}>
            <div className={classes.toolbar} />
          </Hidden>
          {this.props.isMobile ? (
            menuItems &&
            menuItems.length > 0 && (
              <TreeViewItems
                controlVersion={controlVersion}
                openModal={this.handleOpenModal}
                parentMenu={{ children: menuItems }}
              />
            )
          ) : (
            <div
              className={classes.menuContainer}
              onMouseLeave={this.handleDrawerClose}
              onMouseEnter={this.handleDrawerOpen}
            >
              <div
                className={clsx(classes.sideMenuContainer, {
                  [classes.sideMenuContainerOpen]: this.isOpened(),
                })}
              >
                {showCueUp ? (
                  <Button
                    className={classes.buttonCueUp}
                    onClick={() => {
                      this.parentRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <DoubleArrow className={classes.cueUp} />
                  </Button>
                ) : null}
                <div
                  ref={this.parentRef}
                  className={clsx(classes.sideMenuWrapper, classes.noScrollbar)}
                >
                  <div className={classes.toolbar} />
                  <List
                    className={[classes.sideMenu, classes.noScrollbar].join(
                      " "
                    )}
                    disablePadding={true}
                  >
                    {this.getMenu()}
                  </List>
                </div>
                {showCueDown ? (
                  <Button
                    className={classes.buttonCueDown}
                    onClick={() => {
                      this.parentRef.current.scrollTo({
                        top: this.parentRef.current.scrollHeight,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <DoubleArrow className={classes.cueDown} />
                  </Button>
                ) : null}
                <div className={classes.tradeIcon}>
                  <SvgIcon component={TradeIcon} />
                </div>
              </div>
              <div className={drawerMenuClasses.join(" ")}>
                {this.isOpened() && this.state.parentMenu ? (
                  <div className={classes.header}>
                    <div className={classes.headerTitle}>
                      {this.state.parentMenu && (
                        <Translate needle={this.state.parentMenu.label} />
                      )}
                    </div>
                    {!pinDisabled && (
                      <div
                        className={classes.headerIcon}
                        onClick={this.togglePinned}
                      >
                        <SvgIcon color={"inherit"} component={PinIcon} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={classes.fakeHeader} />
                )}
                <div
                  className={clsx(classes.treeviewContainer, {
                    [classes.treeviewContainerOpen]: this.isOpened(),
                  })}
                >
                  <TreeViewItems
                    controlVersion={controlVersion}
                    openModal={this.handleOpenModal}
                    parentMenu={this.state.parentMenu}
                  />
                </div>
              </div>
            </div>
          )}
        </Drawer>
        <Modal
          open={openModal}
          title={"label.appVersion"}
          content={
            <Versions
              versions={JSON.parse(sessionStorage.getItem("versions"))}
            />
          }
          handleClose={this.handleCloseModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { session, controlVersion } = state.AuthenticationReducer;
  const { flatMenuItems, menuItems } = state.SideMenuReducer.result;

  return { menuItems, session, flatMenuItems, controlVersion };
};

const mapDispatchToProps = {
  loadMenu: sideMenuHandler,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(TreeMenu)));
