import axios from "axios";

class UsersService {
  loadUsers = async () => {
    const response = await axios.get("/gov/api/rest/v2/users");
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for users list");
  };

  jwtLogout = async () => {
    const response = await axios.post("/gov/api/rest/v1/users/jwt-logout");
    if (response == null || response.status !== 204) {
      throw Error("Logout failed");
    }
  };

  getUserDetail = async (userId) => {
    const response = await axios.get(`/gov/api/rest/v2/users/${userId}`);
    if (response && response.data) {
      let partnersIds = [];
      response.data.brands.forEach((brand) =>
        brand.partners.forEach((partner) => partnersIds.push(partner.id))
      );
      const data = {
        ...response.data,
        role: {
          value: response.data.role.id,
          id: response.data.role.id,
          admin: response.data.role.admin,
          name: response.data.role.name,
        },
        language: {
          ...response.data.language,
          value: response.data.language.id,
        },
        partnersIds,
      };
      return data;
    }
    throw Error("Invalid response for users detail");
  };

  getRoles = async (own, brandId, partnerId) => {
    const response = await axios.get(
      `/gov/api/rest/v2/roles?own=${own}&brand-id=${brandId}&partner-id=${partnerId}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for users detail");
  };

  addSingleUser = async (values) => {
    const response = await axios.post("/gov/api/rest/v2/users", values);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for add single user");
  };

  parseMultipleUsersData = async (values) => {
    let response = [];
    let url = "/gov/api/rest/v2/users/multiple/parse";

    let dataForm = new FormData();
    dataForm.set("roleId", values.role.id);
    values.partnersIds.forEach((it) =>
      dataForm.append("partners", JSON.stringify(it))
    );
    dataForm.append("fileName", values.csvFile);
    dataForm.append("skipPassword", values.skipPassword);

    response = await axios({
      method: "post",
      url: url,
      data: dataForm,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for parse multiple users data");
  };

  addMultipleUsers = async (body) => {
    const response = await axios.post("/gov/api/rest/v2/users/multiple", body);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for add multiple users");
  };

  editUser = async (body) => {
    const response = await axios.put(`/gov/api/rest/v2/users/${body.id}`, body);
    if (response && response.status !== 204) {
      throw Error("Invalid response for edit user");
    }
  };

  changeUserPassword = async (userId, body) => {
    const response = await axios.put(
      `/gov/api/rest/v2/users/${userId}/change_password`,
      body
    );
    if (response && response.status !== 204) {
      throw Error("Invalid response for edit user");
    }
  };

  setUserHomePage = async (userId, body, wait) => {
    const response = await axios.put(
      `/gov/api/rest/v2/users/home_page/${userId}`,
      { ...body, wait, id: userId }
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for edit user");
  };

  deleteUser = async (userId) => {
    const response = await axios.delete(`/gov/api/rest/v2/users/${userId}`);
    if (response && response.status !== 204) {
      throw Error("Invalid response for delete user");
    }
  };

  unlockUser = async (userId) => {
    const response = await axios.put(
      `/gov/api/rest/v2/users/${userId}/user_unlock`
    );
    if (response && response.status !== 204) {
      throw Error("Invalid response for unlock user");
    }
  };
}

const usersService = new UsersService();
export default usersService;
