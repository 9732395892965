import {
  ACCOUNT_DETAILS_SUCCESS,
  ACCOUNT_DETAILS_FAILURE,
} from "../../../constants";
import React from "react";
import Currency from "../../../../Layouts/Currency/Currency";
import Translate from "../../../../Layouts/Translator/Translate";
const initialState = {
  realMoneyBalance: {
    data: [],
    columns: (playerCurrecy) => {
      return [
        {
          title: "label.player.accountDetails.type",
          field: "name",
          render: (rowData) => {
            return (
              <Translate
                needle={
                  "label.player.accountDetails." +
                  rowData["name"].toLowerCase().replaceAll(" ","")
                }
              />
            );
          },
        },
        {
          field: "availableBalance",
          title: "label.player.accountDetails.accountBalance",
          render: (rowData) => {
            if (rowData["availableBalance"]) {
              return (
                <Currency
                  amount={rowData["availableBalance"]}
                  currency={playerCurrecy}
                  justify="flex-end"
                />
              );
            }
          },
        },
        {
          field: "balance",
          title: "label.player.accountDetails.amountAvaiable",
          render: (rowData) => {
            if (rowData["balance"]) {
              return (
                <Currency
                  amount={rowData["balance"]}
                  currency={playerCurrecy}
                  justify="flex-end"
                />
              );
            }
          },
        },
      ];
    },
  },
  gameVerticals: {
    data: [],
    columns: (playerCurrecy) => {
      return [
        {
          field: "name",
          title: "label.player.accountDetails.type",
          render: (rowData) => {
            return (
              <Translate
                needle={
                  "label.player.accountDetails." +
                  rowData["name"].toLowerCase().replaceAll(" ","")
                }
              />
            );
          },
        },
        {
          field: "amount",
          title: "label.player.accountDetails.accountBalance",
          nowrap: false,
          render: (rowData) => {
            if (rowData["amount"]) {
              return (
                <Currency
                  amount={rowData["amount"]}
                  currency={playerCurrecy}
                  justify="flex-end"
                />
              );
            }
          },
        },
        {
          field: "amountAvailable",
          title: "label.player.accountDetails.amountAvaiable",
          nowrap: false,
          render: (rowData) => {
            if (rowData["amountAvailable"]) {
              return (
                <Currency
                  amount={rowData["amountAvailable"]}
                  currency={playerCurrecy}
                  justify="flex-end"
                />
              );
            }
          },
        },
      ];
    },
  },
  pockets: {
    data: [],
    columns: (playerCurrecy) => {
      return [
        { field: "name",
          title: "label.player.accountDetails.type" ,
          render: (rowData) => {
            return (
              <Translate
                needle={
                  "label.player.accountDetails." +
                  rowData["name"].toLowerCase().replaceAll(" ","")
                }
              />
            );
          },
        },
        {
          field: "amount",
          title: "label.player.accountDetails.accountBalance",
          render: (rowData) => {
            if (rowData["amount"]) {
              return (
                <Currency
                  amount={rowData["amount"]}
                  currency={playerCurrecy}
                  justify="flex-end"
                />
              );
            }
          },
        },
        {
          field: "amountAvailable",
          title: "label.player.accountDetails.amountAvaiable",
          render: (rowData) => {
            if (rowData["amountAvailable"]) {
              return (
                <Currency
                  amount={rowData["amountAvailable"]}
                  currency={playerCurrecy}
                  justify="flex-end"
                />
              );
            }
          },
        },
      ];
    },
  },
};

const AccountDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        realMoneyBalance: {
          ...state.realMoneyBalance,
          data: [
            {
              name: "Account",
              availableBalance: action.payload.balance,
              balance: action.payload.availableBalance,
            },
            {
              name: "Withdrawal",
              availableBalance: action.payload.withdrawable,
              balance: action.payload.withdrawableAvailable,
            },

            {
              name: "Closing Balance",
              availableBalance: action.payload.clousure,
              balance: action.payload.clousureAvailable,
            },
          ],
        },
        gameVerticals: {
          ...state.gameVerticals,
          data: action.payload.virtualPockets,
        },
        pockets: { ...state.pockets, data: action.payload.pockets },
      };
    }

    case ACCOUNT_DETAILS_FAILURE: {
      return {
        ...state,
        realMoneyBalance: {
          ...state.realMoneyBalance,
          data: null,
        },
        gameVerticals: { ...state.gameVerticals, data: null },
        pockets: { ...state.pockets, data: null },
      };
    }

    default: {
      return state;
    }
  }
};

export default AccountDetailsReducer;
