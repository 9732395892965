import { EXTERNAL_URL_PATH, OPEN_WINDOW_URL_PATH } from "../store/constants";
import { checkEncodeURI } from "../utils";

class MenuItemsService {
  parseSubPageUrl(url) {
    let itemUrl = url;
    if (itemUrl && itemUrl.indexOf("#externalUrl") === 0) {
      itemUrl =
        "/externalUrl/" +
        encodeURIComponent(itemUrl.replace("#externalUrl/", ""));
    }
    return itemUrl;
  }

  parseOptionalParameters(url, toReplace = {}) {
    let itemUrl = url;
    if (itemUrl) {
      Object.entries(toReplace).forEach(([key, value]) => {
        const regExp = new RegExp(`{${key}}`, "g");
        itemUrl = itemUrl.replace(regExp, value);
      });
    }
    return itemUrl;
  }

  getItemUrl(url) {
    if (url && url.indexOf(EXTERNAL_URL_PATH) !== -1) {
      const externalUrlPathLength = EXTERNAL_URL_PATH.length;
      let externalUrlPathEncoded = url.substring(externalUrlPathLength);
      if (!checkEncodeURI(externalUrlPathEncoded)) {
        externalUrlPathEncoded = encodeURIComponent(externalUrlPathEncoded);
      }
      url = EXTERNAL_URL_PATH + externalUrlPathEncoded;
    }
    
    if (url && url.indexOf(OPEN_WINDOW_URL_PATH)!== -1) {
      const externalUrlPathLength = OPEN_WINDOW_URL_PATH.length;
      let externalUrlPathEncoded = url.substring(externalUrlPathLength);
      if (!checkEncodeURI(externalUrlPathEncoded)) {
        externalUrlPathEncoded = encodeURIComponent(externalUrlPathEncoded);
      }
      url = OPEN_WINDOW_URL_PATH + externalUrlPathEncoded;
    }

    return url;
  }

  findItemByCurrentUrl = (url, menuItems) => {
    let pathName = url.replace(/{/g, "%7B").replace(/}/g, "%7D");

    return menuItems
      .filter((it) => it.url != null)
      .find((it) => {
        const itemUrl = this.getItemUrl(it.url);
        return itemUrl === pathName || itemUrl === "/" + pathName;
      });
  };

  findItemByLocation(location, menuItems) {
    let pathName = location.pathname.replace(/{/g, "%7B").replace(/}/g, "%7D");

    return (
      menuItems &&
      menuItems
        .filter((it) => it.url != null)
        .find((it) => this.getItemUrl(it.url) === pathName)
    );
  }

  findItemRoot = (item, menuItems) => {
    if (item.parentId) {
      let parent = menuItems && menuItems.find((it) => it.id === item.parentId);
      if (parent) return this.findItemRoot(parent, menuItems);
      return null;
    }

    return item;
  };

  getSelected = (item, menuItems, sel) => {
    let selected = sel;
    if (item) {
      selected.push(item.id.toString());

      if (item.parentId) {
        let parent =
          menuItems && menuItems.find((it) => it.id === item.parentId);
        if (parent) return this.getSelected(parent, menuItems, selected);
        return null;
      }
    }

    return selected;
  };

  findItemByPath(location, flatMenuItems) {
    let item = this.findItemByLocation(location, flatMenuItems);
    if (item) return item;
    const parts = location.pathname.split("/");
    for (let i = 1; i < parts.length; i++) {
      let l = parts.slice(0, parts.length - i).join("/");
      l = l.replace(/{/g, "%7B").replace(/}/g, "%7D");
      item =
        flatMenuItems &&
        flatMenuItems
          .filter((it) => it.url != null)
          .find(
            (it) => this.getItemUrl(it.url.toLowerCase()) === l.toLowerCase()
          );
      if (item) return item;
    }
  }
  
  getFirst = (item) => {
    if (item && item.children?.length > 0) {
      return this.getFirst(item.children[0]);
    }
    return item?.url;
  };
}

const menuItemsService = new MenuItemsService();

export default menuItemsService;
