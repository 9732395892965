import axios from "axios";

class ProgramService {
  async getScheduledProgram(brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v2/loy/scheduledPrograms?brandId=${brand}&partnerId=${partner}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for loyalty program");
  }

  async getLoyProgram(programId) {
    const response = await axios.get(`/gov/api/rest/v2/loy/program/${programId}`);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for loyalty program");
  }

  async getBonus(programId, gameDomainId) {
    const response = await axios.get(
      `/gov/api/rest/v2/loy/program/${programId}/bonus?gameDomainId=${gameDomainId}`
    );

    if (response && response.data) {
      const data = response.data.sort((a, b) => b.lastUpdate - a.lastUpdate);

      return data;
    }
    throw Error("Invalid response for loyalty program");
  }

  async getLevelScale(programId, gameDomainId) {
    const response = await axios.get(
      `/gov/api/rest/v2/loy/program/${programId}/levelScale?gameDomainId=${gameDomainId}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for program");
  }

  async getLoyRewardTypes(brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v2/loy/rewardTypes?brandId=${brand}&partnerId=${partner}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for program");
  }

  async getLoyCreditType(brand, partner, rewardType) {
    const response = await axios.get(
      `/gov/api/rest/v2/loy/creditType?brandId=${brand}&partnerId=${partner}&rewardType=${rewardType}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for program");
  }

  async saveNewBonus(body, isEdit) {
    let response;
    if (isEdit) {
      response = await axios.put(
        `/gov/api/rest/v2/loy/bonus/${body.bonusId}`,
        body
      );
    } else {
      response = await axios.post(`/gov/api/rest/v2/loy/bonus`, body);
    }

    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response for program");
  }

  async removeLevel(levelId, bonusId) {
    const response = await axios.delete(
      `/gov/api/rest/v2/loy/bonus/${bonusId}/level/${levelId}`
    );

    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response for program");
  }

  async getLevels(programId, gameDomainId) {
    const response = await axios.get(
      `/gov/api/rest/v2/loy/program/${programId}/level?gameDomainId=${gameDomainId}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for program");
  }

  async saveAssociatedLevel(body, bonusId) {
    const response = await axios.put(
      `/gov/api/rest/v2/loy/bonus/${bonusId}/level`,
      body
    );

    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response for program");
  }

  async deleteBonus(bonusId) {
    const response = await axios.delete(`/gov/api/rest/v2/loy/bonus/${bonusId}`);

    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response for program");
  }
}

const programService = new ProgramService();
export default programService;
