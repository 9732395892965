import axios from "axios";
import { call, takeEvery } from "redux-saga/effects";
import { REFRESH_TOKEN_REQUEST } from "../constants";

const refresh = async () => {
  let url = "/gov/api/rest/v1/users/refresh-jwt-token";
  let refreshToken = sessionStorage.getItem("refresh-token");
  const response = await axios.post(url, { refreshToken: refreshToken });
  if (response && response.data) {
    return response.data;
  }
  throw Error("Token non refreshed");
};

const refreshToken = function* () {
  try {
    const data = yield call(refresh);
    const session = JSON.parse(sessionStorage.getItem("session"));
    sessionStorage.setItem(
      "nextTokenRefresh",
      new Date().getTime() + (session.accessTimeout - 2) * 60000
    );
    sessionStorage.setItem("access-token", data.accessToken);
  } catch (e) {
    console.error("token not refreshed", e);
  }
};

const TokenSaga = function* tokenSaga() {
  yield takeEvery(REFRESH_TOKEN_REQUEST, refreshToken);
};

export default TokenSaga;
