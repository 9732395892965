import { call, put } from "redux-saga/effects";
import {
  PLAYER_NOTE_DELETE_FAILURE,
  PLAYER_NOTE_DELETE_REQUEST,
  PLAYER_NOTE_DELETE_SUCCESS,
  PLAYER_NOTE_FAILURE,
  PLAYER_NOTE_REQUEST,
  PLAYER_NOTE_SAVE_FAILURE,
  PLAYER_NOTE_SAVE_REQUEST,
  PLAYER_NOTE_SAVE_SUCCESS,
  PLAYER_NOTE_SUCCESS,
  PLAYER_NOTES_FAILURE,
  PLAYER_NOTES_PRIORITIES_FAILURE,
  PLAYER_NOTES_PRIORITIES_REQUEST,
  PLAYER_NOTES_PRIORITIES_SUCCESS,
  PLAYER_NOTES_REQUEST,
  PLAYER_NOTES_SUCCESS,
  PLAYER_NOTES_TYPES_FAILURE,
  PLAYER_NOTES_TYPES_REQUEST,
  PLAYER_NOTES_TYPES_SUCCESS,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import playerNotesService from "./player-notes-service";

const getPlayerNotes = function* (action) {
  try {
    const data = yield call(
      playerNotesService.getPlayerNotes,
      action.contractId,
      action.brand,
      action.partner
    );
    yield put({
      type: PLAYER_NOTES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_NOTES_FAILURE,
    });
  }
};

const getPlayerNotePriorities = function* (action) {
  try {
    const data = yield call(playerNotesService.getPriorities);
    yield put({
      type: PLAYER_NOTES_PRIORITIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_NOTES_PRIORITIES_FAILURE,
    });
  }
};

const getPlayerNoteTypes = function* (action) {
  try {
    const data = yield call(playerNotesService.getTypes);
    yield put({
      type: PLAYER_NOTES_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_NOTES_TYPES_FAILURE,
    });
  }
};

const getPlayerNote = function* (action) {
  try {
    const data = yield call(playerNotesService.getNote, action.noteId);
    yield put({
      type: PLAYER_NOTE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_NOTE_FAILURE,
    });
  }
};

const deletePlayerNote = function* (action) {
  try {
    yield call(playerNotesService.deleteNote, action.contractId, action.noteId);
    yield put({
      type: PLAYER_NOTE_DELETE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.playerNotes.deleteSuccess",
          variables: [action.noteId + ""],
        },
        options: {
          key: "DeleteIndividualPlayerGroups",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
    yield put({
      type: PLAYER_NOTES_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: PLAYER_NOTE_DELETE_FAILURE,
    });
  }
};

const savePlayerNote = function* (action) {
  try {
    yield call(
      playerNotesService.saveNote,
      action.brand,
      action.partner,
      action.contractId,
      action.note
    );
    yield put({
      type: PLAYER_NOTE_SAVE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.player.playerNotes." +
            (action.note.id ? "editSuccess" : "saveSuccess"),
          variables: [action.note.id + ""],
        },
        options: {
          key: "PlayerNoteSave",
          variant: "success",
        },
      })
    );
    yield put({
      type: PLAYER_NOTES_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: PLAYER_NOTE_SAVE_FAILURE,
    });
  }
};

const PlayerNotesSaga = function* AccountDetailsSaga() {
  yield takeLatest(PLAYER_NOTES_REQUEST, getPlayerNotes);
  yield takeLatest(PLAYER_NOTES_PRIORITIES_REQUEST, getPlayerNotePriorities);
  yield takeLatest(PLAYER_NOTES_TYPES_REQUEST, getPlayerNoteTypes);
  yield takeLatest(PLAYER_NOTE_REQUEST, getPlayerNote);
  yield takeLatest(PLAYER_NOTE_DELETE_REQUEST, deletePlayerNote);
  yield takeLatest(PLAYER_NOTE_SAVE_REQUEST, savePlayerNote);
};

export default PlayerNotesSaga;
