import { getValidationSchema } from "../../../../../Layouts/form/utils";
import { useSession } from "../../../../../utilities/hooks/useSession";
import React from "react";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import { FormField } from "../../../../../Layouts/form";
import { Box, Button } from "@material-ui/core";
import Translate from "../../../../../Layouts/Translator/Translate";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { labelPrefix } from "../../../../../store/players/individual-player/player-Favorites/player-favorites-reducer";
import { savePlayerFavorites } from "../../../../../store/players/individual-player/player-Favorites/actions";

const FavoriteForm = ({ data, actionType, handleClose, save }) => {
  const fields = {
    reason: {
      name: "reason",
      type: "Text",
      optional: true,
      multiline: true,
      rows: 4,
    },
  };
  const { brandSelected, partnerSelected } = useSession();
  const t = useTranslator();
  const match = useRouteMatch();
  const contractId = match.params.id;
  let initialValues = {};
  if (data) {
    initialValues.type = data.type;
    initialValues.name = data.name;
    initialValues.category = data.category;
    initialValues.categoryName = data.categoryName;
    if (data.favoriteId) {
      initialValues.id = data.favoriteId;
    }
    initialValues.code = actionType === "edit" ? data.code : data.favoriteId;
    initialValues.reason = data.reason;
  }
  const validationSchema = getValidationSchema(labelPrefix, fields);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          return validateYupSchema(values, validationSchema, false, values)
            .then(() => ({}))
            .catch((err) => {
              return yupToFormErrors(err);
            });
        }}
        onSubmit={(fields) => {
          save(
            brandSelected,
            partnerSelected,
            fields,
            contractId,
            actionType,
            handleClose
          );
        }}
        render={() => (
          <Form>
            <Box p={2} pt={0}>
              {Object.keys(fields).map((fieldName) => {
                const field = { ...fields[fieldName] };
                field.label = t({ needle: labelPrefix + field.name });

                return (
                  <Box key={fieldName}>
                    <FormField fullWidth {...field} />
                  </Box>
                );
              })}
            </Box>
            <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
              <Box mr={1}>
                <Button variant={"outlined"} onClick={() => handleClose()}>
                  <Translate needle={"button.cancel"} />
                </Button>
              </Box>
              <Box>
                <Button color={"primary"} type="submit">
                  <Translate needle={"button.save"} />{" "}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      />
    </>
  );
};
const mapDispatchToProps = {
  save: savePlayerFavorites,
};
const mapStateToProps = (state) => {};
export default connect(mapStateToProps, mapDispatchToProps)(FavoriteForm);
