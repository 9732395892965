import axios from "axios";
class ManageTemplateService {
  async getTemplates(brandId, partnerId) {
    let url = `/gov/api/rest/v2/dlv/templates?brand=${brandId}&partner=${partnerId}&channel= `;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }
  async deleteTemplate(brandId, partnerId, templateName) {
    let url = `/gov/api/rest/v2/dlv/templates/${brandId}/${partnerId}/${templateName}`;
    const response = await axios.delete(url);
    if (!response) {
      throw Error(" Unable to delete template");
    }
  }
  async deleteChannel(brand, partner, data) {
    let url = `/gov/api/rest/v2/dlv/templates/channel/${data.templateName}`;
    data.brandCode = brand;
    data.partnerCode = partner;
    const response = await axios.put(url, data);
    if (!response) {
      throw Error(" Unable to delete channel");
    }
  }

  async addTemplate(brand, partner, data) {
    let url = `/gov/api/rest/v2/dlv/templates/${brand}/${partner}`;
    let body = {};
    body.templateName = data.templateName;
    body.required = data.required ? "1" : "0";
    body.smsBrandId = brand;
    body.smsPartnerId = partner;
    const response = await axios.post(url, body);
    if (!response) {
      throw Error("unable to add template");
    }
  }

  async getDetails(
    brand,
    partner,
    templateName,
    channelName,
    language,
    contentTypeId
  ) {
    let url = `/gov/api/rest/v2/dlv/template/${brand}/${partner}/${templateName}?channelName=${channelName}&language=${language}&contentTypeId=${contentTypeId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getLanguages(templateName) {
    let url = `/gov/api/rest/v2/templates/channelsLanguagesAvailable?templateName=${templateName}`;
    const response = await axios.get(url);
    if (response && response.data) {
      const languagesByChannelName = {};
      const channels = response.data.map((it) => {
        return it.channel;
      });
      response.data.map((it) => {
        languagesByChannelName[it.channel.channelName] = it.availableLanguages;
      });
      return { languagesByChannelName, channels };
    }
    throw Error("Invalid data");
  }

  async getVersions(contentTypeId, data, brand, partner, templateName) {
    let url = `/gov/api/rest/v2/dlv/template/${brand}/${partner}/${templateName}?channelName=${data.channelName}&language=en_US&contentTypeId=${contentTypeId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data.gmsDlvTemplateParameters[0].version;
    }
    throw Error("Invalid data");
  }

  async saveChannel(values) {
    let url = `/gov/api/rest/v2/dlv/templates/channel/`;

    const response = await axios.post(url, values);
    if (!response || response.status !== 204) {
      throw Error("unable to add channel");
    }
  }

  async getTemplateVariables(templateName) {
    let url = `/gov/api/rest/v1/template_variables?templateName=${templateName}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async loadHeaderFooter(brand, partner) {
    let url = `/gov/api/rest/v1/notifications/templates/headersFooters?brand=${brand}&partner=${partner}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getAllLanguages() {
    let url = `/gov/api/rest/v2/dlv/allLanguages`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async editChannel(body) {
    let url = `/gov/api/rest/v2/dlv/templates/${body.brandCode}/${body.partnerCode}/${body.templateName}`;
    const response = await axios.put(url, body);
    if (!response || response?.status !== 204) {
      throw Error(" Unable to update template");
    }
  }
}

const manageTemplateService = new ManageTemplateService();
export default manageTemplateService;
