import { STYLE_CURRENCY, STYLE_LABEL } from "../../store/constants";
import dataExporter, { ExportType } from "../../utilities/dataExporter";

const byString = (o, s) => {
  if (!s) {
    return;
  }

  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var x = a[i];
    if (o && x in o) {
      o = o[x];
    } else {
      return;
    }
  }
  return o;
};
export const getDataValue = (rowData, columnDef) => {
  let field =
    rowData[columnDef.field] !== undefined
      ? rowData[columnDef.field]
      : byString(rowData, columnDef.field);
  if (columnDef.lookup) {
    field = columnDef.lookup[field];
  }
  if (field !== undefined) {
    return Array.isArray(field) ? field.join(" ") : field;
  }
  return field;
};

export const getRenderDataValue = (rowData, columnDef, isEditComponent) => {
  let field = getDataValue(rowData, columnDef);
  if (field != null) {
    if (typeof field === "boolean") field = field.toString();
    if (isEditComponent && columnDef.editComponent) {
      columnDef.renderData = columnDef.editComponent;
    }
    return columnDef.renderData ? columnDef.renderData(rowData) : field;
  }

  return columnDef.emptyValue ? columnDef.emptyValue : field;
};

export const exportFn = ({
  fileFormat,
  tableData,
  includeFilters,
  filters,
  exportColumns,
  currencySymbol,
  intl,
  divisorAmount,
  t,
  title,
  ...props
}) => {
  const exportData = tableData?.map((rowData) =>
    exportColumns?.map((columnDef) => {
      if (columnDef.splitExportStringVal && fileFormat === ExportType.PDF) {
        var middle = Math.floor(rowData[columnDef.field].length / 2);
        var s1 = rowData[columnDef.field].substr(0, middle);
        var s2 = rowData[columnDef.field].substr(middle);
        return s1 + "\n" + s2;
      }
      if (typeof columnDef.getExportValueFn === "function") {
        return columnDef.getExportValueFn(rowData) || "";
      }
      if (typeof columnDef.getExportTypeFn === "function") {
        const styleType = columnDef.getExportTypeFn(rowData);
        if (styleType != null) {
          switch (styleType.style) {
            case STYLE_CURRENCY: {
              const currencyDisplay = currencySymbol ? "symbol" : "code";
              return intl.formatNumber(styleType.value / divisorAmount, {
                style: STYLE_CURRENCY,
                currency: styleType.currency,
                currencyDisplay: currencyDisplay,
              });
            }
            case STYLE_LABEL: {
              return t({ needle: styleType.value });
            }
            default: {
              throw Error("Unrecognized style type");
            }
          }
        }
      }
      return getDataValue(rowData, columnDef) || "";
    })
  );
  exportColumns = exportColumns.map((it) => it.title);
  const filename =
    "Export_" +
    (props.exportFileName || t({ needle: title }) || "data").replace(" ", "_");
  let filterFields = includeFilters ? filters : null;
  switch (fileFormat) {
    case ExportType.CSV: {
      dataExporter.exportCsv(
        exportColumns,
        exportData,
        filename,
        includeFilters,
        filterFields,
        t({ needle: title })
      );
      break;
    }
    case ExportType.EXCEL: {
      dataExporter.exportExcel(
        exportColumns,
        exportData,
        filename,
        includeFilters,
        filterFields,
        t({ needle: title })
      );
      break;
    }
    case ExportType.PDF: {
      dataExporter.exportPDF(
        exportColumns,
        exportData,
        filename,
        includeFilters,
        filterFields,
        t({ needle: title })
      );
      break;
    }
    default: {
      throw Error("Unrecognized export type");
    }
  }
};
