import {
  ADD_BOOKMARKS_ACTION,
  GET_BOOKMARKS_ACTION,
  REMOVE_BOOKMARKS_ACTION,
} from "../constants";

const INITIAL_STATE = {
  bookmarks: [],
  menuItem: null,
};

const BookmarksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_BOOKMARKS_ACTION:
      return {
        ...state,
        bookmarks: action.payload,
      };
    case REMOVE_BOOKMARKS_ACTION:
      return {
        ...state,
        bookmarks: action.payload,
      };
    case GET_BOOKMARKS_ACTION:
      return {
        ...state,
        bookmarks: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default BookmarksReducer;
