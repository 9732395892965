import axios from "axios";

class PlayerPreferencesService {
  async loadPlayerPreferences(playerId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/players/${playerId}/player-preferences?brandId=${brandId}&partnerId=${partnerId}`;

    const response = await axios.get(url);
    if (response && response.data) {
      const data = { ...response.data };
      Object.entries(data).forEach(([k, v]) => {
        data[k] = v || false;
      });

      return data;
    }
    throw Error("Invalid data");
  }

  async updatePlayerPreferences(playerId, brandId, partnerId, body) {
    let url = `/gov/api/rest/v1/players/${playerId}/player-preferences?brandId=${brandId}&partnerId=${partnerId}`;

    const response = await axios.put(url, body);
    if (response && response.status === 200) {
      return response.data;
    }
    throw Error("Invalid response player player preferences");
  }
}

const playerPreferencesService = new PlayerPreferencesService();
export default playerPreferencesService;
