import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import CustomIcon from "../CustomIcon/CustomIcon";
import menuItemsService from "../../utilities/menuItems";
import { usePageActions } from "../../utilities/hooks/usePageActions";
import { isMobileOnly } from "react-device-detect";
import TrackNavLink from "../track-nav-link/track-nav-link";

const searchMenuItem = (id, menus) => {
  let ret = [];
  let menuItem = menus.find((x) => x.id === id);
  if (menuItem) {
    ret.push(menuItem);
    if (menuItem.parentId != null) {
      ret = searchMenuItem(menuItem.parentId, menus).concat(...ret);
    }
  }
  return ret;
};

function getStandardBreadcrumbs(pathnames, menus, actions) {
  return pathnames.map((value, index) => {
    const to = `/${pathnames.slice(0, index + 1).join("/")}`;
    let menuItem = menus.find(
      (x) => x.url != null && x.url.toLowerCase() === to.toLowerCase()
    );
    if (menuItem == null) {
      menuItem = actions.find(
        (x) =>
          x.url != null &&
          x.url.toLowerCase() === "/" + pathnames[index].toLowerCase()
      );
    }
    if (menuItem && menuItem.label == null) {
      menuItem.label = menus.find((x) => x.id === menuItem.parentId).label;
    }
    let menuLabel =
      menuItem && menuItem.label
        ? menuItem.label
        : "label.breadcrumbs." + value;
    const intermediate =
      !!(menuItem && menuItem.children && menuItem.children.length > 0) &&
      index > 0;
    const icon = index === 0 && menuItem ? menuItem.imageOff : null;

    return { intermediate, to, menuLabel, icon, pathName: value };
  });
}

function getExternalUrlBreadCrumbs2(pathnames, icon) {
  return pathnames.map((value, index) => {
    const to = menuItemsService.getItemUrl(value.url);
    const menuLabel = value.label ? value.label : `label.${value}`;
    icon = index === 0 && value.imageOff ? value.imageOff : null;
    return {
      intermediate: value.url == null,
      menuLabel,
      to,
      icon,
    };
  });
}

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  text: {
    fontSize: isMobileOnly ? 12 : 14,
    display: "flex",
    color: theme.palette.text.primary2,
  },
  link: {
    fontSize: isMobileOnly ? 12 : 14,
    display: "flex",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary2,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    "& svg": {
      display: "block",
      width: "20px!important",
      height: "20px!important",
    },
  },
  separator: {
    color: theme.palette.text.primary2,
  },
}));

const Breadcrumb = ({ menus }) => {
  const t = useTranslator();
  const { all = [] } = usePageActions();
  const match = useRouteMatch("/externalUrl/:open");
  const match2 = useRouteMatch("*/:id/externalUrl/:open");

  const classes = useStyle();
  if (menus && menus.length > 0) {
    return (
      <Route>
        {({ location }) => {
          let breadcrumbs = [];
          if (match2 == null && match == null) {
            const pathnames = location.pathname.split("/").filter((x) => x);
            breadcrumbs = getStandardBreadcrumbs(pathnames, menus, all);
          } else {
            let open;
            let menuItem;
            if (match2 == null) {
              open = window.decodeURIComponent(match.params.open);
              menuItem = menus.find((x) => {
                if (x.url != null) {
                  let url = x.url
                    .replace("/ExternalUrl/", "")
                    .replace("#externalUrl/", "");
                  if (url.indexOf("#") === 0) url = url.substring(1);
                  return url.toLowerCase() === open.toLowerCase();
                }
                return false;
              });
              if (menuItem) {
                if (menuItem.parentId != null) {
                  const steps = searchMenuItem(
                    menuItem.parentId,
                    menus
                  ).concat([menuItem]);
                  breadcrumbs = getExternalUrlBreadCrumbs2(steps);
                } else {
                  breadcrumbs = getExternalUrlBreadCrumbs2([menuItem]);
                }
              } else {
                breadcrumbs = [
                  {
                    to: "none",
                    intermediate: true,
                    menuLabel: open,
                  },
                ];
              }
            } else {
              open = window.decodeURIComponent(match2.params.open);
              menuItem = all.find((x) => {
                if (x.url != null) {
                  let url = x.url
                    .replace("/ExternalUrl/", "")
                    .replace("#externalUrl/", "");
                  if (url.indexOf("#") === 0) url = url.substring(1);
                  return url.toLowerCase() === open.toLowerCase();
                }
                return false;
              });
              if (menuItem) {
                breadcrumbs = getStandardBreadcrumbs(
                  (match2.params[0] + "/" + match2.params.id)
                    .split("/")
                    .filter((x) => x),
                  menus,
                  all
                );
                breadcrumbs = [
                  ...breadcrumbs,
                  ...getExternalUrlBreadCrumbs2([menuItem]),
                ];
              }
            }
          }
          return (
            <Breadcrumbs
              classes={{
                root: classes.root,
                separator: classes.separator,
              }}
              separator={<NavigateNextIcon />}
              maxItems={isMobileOnly ? 2 : 8}
            >
              {breadcrumbs.map((value, index) => {
                const last = index === breadcrumbs.length - 1;
                const { icon, intermediate, menuLabel, to, pathName } = value;
                const label =
                  pathName === "create"
                    ? t({ needle: "label.tabs.create" })
                    : t({ needle: menuLabel, label: pathName });
                return last || intermediate ? (
                  <Typography className={classes.text} key={to}>
                    {icon && (
                      <CustomIcon className={classes.icon} icon={icon} />
                    )}
                    {label}
                  </Typography>
                ) : (
                  <Link
                    component={TrackNavLink}
                    element={"Breadcrumb"}
                    labelTo={menuLabel}
                    className={classes.link}
                    to={to}
                    key={to}
                  >
                    {icon && (
                      <CustomIcon className={classes.icon} icon={icon} />
                    )}
                    {label}
                  </Link>
                );
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    );
  } else return null;
};

export default Breadcrumb;
