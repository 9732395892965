import axios from "axios";

class ContactVerificationService {
  async getContactVerification(brandId, partnerId, contractId, widget) {
    let url = `/gov/api/rest/v1/communications/contactVerification?contractId=${contractId}&brandId=${brandId}&partnerId=${partnerId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      let data = response.data.filter((it) => {
        return (
          it.channelName === "sms" ||
          it.channelName === "reserved-sms" ||
          it.channelName === "e-mail" ||
          it.channelName === "reserved-e-mail"
        );
      });

      if (widget && data.length > 0) {
        const result = data.reduce(
          (obj, it) => ({ ...obj, [it.channelName]: it }),
          {}
        );

        const maxNumberDate =
          result["reserved-sms"] != null
            ? result["reserved-sms"]
            : result["sms"];
        const maxEmailDate =
          result["reserved-e-mail"] != null
            ? result["reserved-e-mail"]
            : result["e-mail"];

        data = [];
        if (maxNumberDate != null) {
          data.push(maxNumberDate);
        }
        if (maxEmailDate != null) {
          data.push(maxEmailDate);
        }
      }

      return data;
    }
    throw Error("Invalid data");
  }

  async sendPin(brandId, partnerId, contractId, channelName) {
    let url = `/gov/api/rest/v1/communications/contactVerification/pin?contractId=${contractId}&brandId=${brandId}&partnerId=${partnerId}&channelName=${channelName}`;
    const response = await axios.put(url);
    if (!response) {
      throw Error(" Unable to send Pin");
    }
  }

  async verifyPin(brand, partner, fields) {
    let validResponseStatus = 204;
    let url = "/gov/api/rest/v1/communications/contactVerification/verify";
    const response = await axios({
      method: "put",
      params: {
        brandId: brand.id,
        partnerId: partner.id,
      },
      url,
      data: fields,
    });
    if (!response || response.status !== validResponseStatus) {
      throw Error("Unable to chenge KYC status");
    }
  }
}

const contactVerificationService = new ContactVerificationService();
export default contactVerificationService;
