import * as moment from "moment";
import MomentUtils from "@date-io/moment";

class AdvancedMomentUtils extends MomentUtils {
  dateFormat = moment.localeData().longDateFormat("L");
  dateTime12hFormat =
    moment.localeData().longDateFormat("L") +
    " " +
    moment.localeData().longDateFormat("LT");
  dateTime24hFormat =
    moment.localeData().longDateFormat("L") +
    " " +
    moment.localeData().longDateFormat("LT");
  time12hFormat = moment.localeData().longDateFormat("LT");
  time24hFormat = moment.localeData().longDateFormat("LT");
}

export default AdvancedMomentUtils;
