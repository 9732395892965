import {
    SESSION_INFO_REQUEST,
    SILENT_AUTHENTICATION_PAGE_FAILURE,
    SILENT_AUTHENTICATION_PAGE_REQUEST,
    SILENT_AUTHENTICATION_PAGE_SUCCESS,
    SILENT_AUTHENTICATION_REQUEST
} from "../constants";
import silentLoginService from "./silent-login-service";
import { call, put, takeLatest } from "redux-saga/effects";
import history from "../../history";

const pageFetching = function* ({id}) {
    try {
        const url = yield call(silentLoginService.pageFetching,id);
        yield put({
            type: SILENT_AUTHENTICATION_PAGE_SUCCESS,
            payload: url,
        });
    }catch (e){
        yield put({
            type: SILENT_AUTHENTICATION_PAGE_FAILURE,
        });
    }
};

const silentLogin = function* (action) {
    let result = {};
    try {
        const response = yield call(silentLoginService.silentLoginRequest, action.payload);
        sessionStorage.setItem("access-token", response.accessToken);
        sessionStorage.setItem("refresh-token", response.refreshToken);
        const url = yield call(silentLoginService.pageFetching,action.payload.pageNumber);
        if(url != null){
            if(url.indexOf("{id}")!==-1){
                sessionStorage.setItem("silentLoginRoute",url.replace("{id}",action.payload.contractIdentity) );
            }else{
                sessionStorage.setItem("silentLoginRoute",url );
            }
        }
        result = { result: true, errorCode: null };
    }catch (e){
        console.error(e);
        let errorCode =
            e.response.data != null && e.response.data !== ""
                ? e.response.data.ERROR_CODE
                : "1002";
        result = { result: false, errorCode: errorCode };
         yield history.replace("/");
    }
    yield put({
        type: SESSION_INFO_REQUEST,
        payload: result,
    });

};

const SilentLoginSaga = function* silentLoginSaga() {
    yield takeLatest(SILENT_AUTHENTICATION_PAGE_REQUEST, pageFetching);
    yield takeLatest(SILENT_AUTHENTICATION_REQUEST, silentLogin);
};

export default SilentLoginSaga;
