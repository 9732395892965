import {
  WIDGET_DATA_FAILURE,
  WIDGET_DATA_SUCCESS,
  WIDGET_HOME_DATA_REQUEST,
  WIDGET_LAYOUT_REQUEST,
  WIDGET_LAYOUT_SUCCESS,
  WIDGET_LOY_LEVELS_DATA_REQUEST,
  WIDGET_RECENTLY_DATA_REQUEST,
  WIDGET_REPORT_DATA_REQUEST,
  WIDGET_REWARD_DATA_REQUEST,
} from "../constants";
import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { enqueueSnackbar } from "../snackbars/actions";

const LAYOUT = 0;
const layoutFetching = async ({ id }) => {
  const response = await axios.get(process.env.PUBLIC_URL + "/layouts.json");
  const layout = response.data.find((it) => it.id === id);
  return layout ? layout.layout[LAYOUT] : {};
};

const loadLayout = function* (action) {
  try {
    const response = yield call(layoutFetching, action.payload);
    yield put({
      type: WIDGET_LAYOUT_SUCCESS,
      payload: {
        id: action.payload.id,
        response,
      },
    });
  } catch (e) {
    yield put(
      enqueueSnackbar({
        message: {
          needle: "errors.dashboard_layout_error",
        },
        options: {
          key: "widget_data_error",
          variant: "error",
          autoHideDuration: 8000,
        },
      })
    );
  }
};

const dataFetching = async (
  url,
  { facet, brand, partner, query, params, sort }
) => {
  const body = {
    q: query,
    brandId: brand,
    partnerId: partner,
    rows: 20,
    start: 0,
  };
  if (facet) {
    body.facet = facet;
  }
  if (sort) {
    body.sort = sort;
  }
  const { status, data } = await axios.post(`${url}`, body, {
    params: {
      wt: "json",
      omitHeader: true,
      ...params,
    },
  });
  if (status === 200 && data) {
    return data;
  }
  throw Error("Invalid response fetching widgets data");
};

const loadHomeData = function* (action) {
  try {
    const { type, brand, partner } = action.payload;
    let query = [];
    if (type) {
      query.push(getTypeQuery(type));
    }
    query = query.length > 0 ? query.join(" AND ") : null;

    const response = yield call(dataFetching, "/gov/api/rest/v1/home-dashboard", {
      brand,
      partner,
      query,
    });
    let data = extractData(response, type);

    yield put({
      type: WIDGET_DATA_SUCCESS,
      data,
      action: action.payload.name,
    });
  } catch (e) {
    handleError(e, action.payload.name);
  }
};

const loadReportData = function* (action) {
  try {
    const { facet, type, brand, partner, playerId } = action.payload;
    let query = [];

    if (playerId) {
      query.push(`CONTRACT_IDENTITY:${playerId}`);
    }
    if (type) {
      query.push(getTypeQuery(type));
    }
    query = query.length > 0 ? query.join(" AND ") : null;

    const response = yield call(dataFetching, "/gov/api/rest/v1/report", {
      facet,
      brand,
      partner,
      query,
    });

    let data = [];
    if (facet) {
      data = extractDataFromFacet(response, type, facet);
    } else {
      data = extractData(response, type);
    }
    yield put({
      type: WIDGET_DATA_SUCCESS,
      data,
      action: action.payload.name,
    });
  } catch (e) {
    handleError(e, action.payload.name);
  }
};

const loadRecentlyData = function* (action) {
  try {
    const { type, brand, partner, playerId } = action.payload;
    let query = [];

    if (playerId) {
      query.push(`CONTRACT_IDENTITY:${playerId}`);
    }
    if (type) {
      query.push(getTypeQuery(type));
    }

    query = query.length > 0 ? query.join(" AND ") : null;

    const params = {
      groupLimit: 5,
      group: true,
      groupField: "PLATFORM_ID",
    };
    const response = yield call(dataFetching, "/gov/api/rest/v1/recently", {
      brand,
      partner,
      query,
      sort: "PURCHASE_DATE desc",
      params,
    });
    let data = extractGroupData(response, "PLATFORM_ID");

    yield put({
      type: WIDGET_DATA_SUCCESS,
      data: data,
      action: action.payload.name,
    });
  } catch (e) {
    handleError(e, action.payload.name);
  }
};

const loadRewardData = function* (action) {
  try {
    const { type, brand, partner } = action.payload;
    let query = [];
    if (type) {
      query.push(getTypeQuery(type));
    }
    query = query.length > 0 ? query.join(" AND ") : null;

    const response = yield call(
      dataFetching,
      "/gov/api/rest/v1/massiveRewards/rewards",
      {
        brand,
        partner,
        query,
      }
    );
    let data = extractData(response, type);

    yield put({
      type: WIDGET_DATA_SUCCESS,
      data,
      action: action.payload.name,
    });
  } catch (e) {
    handleError(e, action.payload.name);
  }
};

const loadLoyLevelsData = function* (action) {
  try {
    const { type, brand, partner } = action.payload;
    let query = [];
    if (type) {
      query.push(getTypeQuery(type));
    }
    query = query.length > 0 ? query.join(" AND ") : null;

    const response = yield call(
      dataFetching,
      "/gov/api/rest/v1/massiveRewards/loylevels",
      {
        brand,
        partner,
        query,
      }
    );
    let data = extractData(response, type);

    yield put({
      type: WIDGET_DATA_SUCCESS,
      data,
      action: action.payload.name,
    });
  } catch (e) {
    handleError(e, action.payload.name);
  }
};

const handleError = function* (e, action) {
  console.error("Error in WidgetSaga", e);
  yield put({
    type: WIDGET_DATA_FAILURE,
    action,
  });
  yield put(
    enqueueSnackbar({
      message: {
        needle: "errors.generic_error",
        variables: ["label.widgets.dashboard"],
      },
      options: {
        key: "widget_data_error",
        variant: "error",
        autoHideDuration: 8000,
      },
    })
  );
};

const getTypeQuery = (type) => {
  let typeToFetch = Array.isArray(type) ? type.join(" ") : type;
  if (typeToFetch) {
    return `ORDERING:(${typeToFetch})`;
  }
  return "";
};

const extractGroupData = ({ grouped }, key) => {
  const groups = grouped[key] ? grouped[key].groups : [];
  return groups.reduce((accumulator, it) => {
    accumulator[it.groupValue] = it.doclist.docs;
    return accumulator;
  }, {});
};

const extractData = ({ collection }, type) => {
  if (type && type.length > 0) {
    let orderedDocs = [];
    type.forEach((value) => {
      // eslint-disable-next-line eqeqeq
      const elems = collection.filter((it) => it["ORDERING"] == value);
      orderedDocs = orderedDocs.concat(elems);
    });
    return orderedDocs;
  } else {
    return collection;
  }
};

const extractDataFromFacet = ({ facets }, type, facet) => {
  let data = [];
  type.forEach((it) => {
    if (facet[it].hasTerms) {
      const key = facet[it].hasTerms;
      if (facets[it] && facets[it][key] && facets[it][key].buckets) {
        data = [
          ...data,
          ...facets[it][key].buckets.map((bucket) => {
            const mappedBuckets = {...bucket }
            Object.keys(mappedBuckets).forEach((k) => {
              if (mappedBuckets[k].total){
                mappedBuckets[k] = mappedBuckets[k].total;
              }else{
                if (k !== "count" && k !== "val" ){
                  mappedBuckets[k] = 0;
                }
              }
            })
            return {
              ORDERING: it,
              [facet[it].hasTerms]: bucket.val,
              ...mappedBuckets,
            };
          }),
        ];
      }
    } else {
      const mappedFacets = {...facets[it] }
      Object.keys(mappedFacets).forEach((k) => {
        if (mappedFacets[k].total){
          mappedFacets[k] = mappedFacets[k].total;
        }else{
          if (k !== "count" && k !== "val" ){
            mappedFacets[k] = 0;
          }
        }
      })
      data.push({ ORDERING: it, ...mappedFacets });
    }
  });
  return data;
};

const WidgetSaga = function* () {
  yield takeEvery(WIDGET_HOME_DATA_REQUEST, loadHomeData);
  yield takeEvery(WIDGET_REPORT_DATA_REQUEST, loadReportData);
  yield takeEvery(WIDGET_RECENTLY_DATA_REQUEST, loadRecentlyData);
  yield takeEvery(WIDGET_REWARD_DATA_REQUEST, loadRewardData);
  yield takeEvery(WIDGET_LOY_LEVELS_DATA_REQUEST, loadLoyLevelsData);
  yield takeEvery(WIDGET_LAYOUT_REQUEST, loadLayout);
};

export default WidgetSaga;
