import { useTranslator } from "../../utilities/hooks/useTranslator";

const Translate = ({
  needle,
  label = "",
  variables,
  avoidLowerCase = false,
}) => {
  const t = useTranslator();
  return t({ needle, label, variables, avoidLowerCase }) || null;
};

export default Translate;
