import React from "react";
import { useField, useFormikContext } from "formik";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import FormLabel from "./form-label";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import ScheduleIcon from "@material-ui/icons/Schedule";

const DatePickerField = ({
  isConditional = false,
  placeholder,
  optional,
  label,
  tooltip,
  dateType = "date",
  ...props
}) => {
  const t = useTranslator();
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  const { setFieldValue } = useFormikContext();

  const defaultPlaceholder =
    dateType === "date-time"
      ? t({ needle: "label.paceholderDate" }) +
        " " +
        t({ needle: "label.paceholderTime" })
      : dateType === "time"
      ? t({ needle: "label.paceholderTime" })
      : t({ needle: "label.paceholderDate" });
  const plchld = placeholder
    ? typeof placeholder === "string"
      ? t({ needle: placeholder })
      : placeholder
    : defaultPlaceholder;

  const Component =
    dateType === "date-time"
      ? KeyboardDateTimePicker
      : dateType === "time"
      ? KeyboardTimePicker
      : KeyboardDatePicker;
  return (
    <Component
      {...field}
      value={
        field.value != null
          ? dateType === "date"
            ? moment.utc(+field.value)
            : field.value
          : null
      }
      {...props}
      label={
        label ? (
          <FormLabel optional={optional} label={label} tooltip={tooltip} />
        ) : null
      }
      onChange={(date) => {
        let val = date ? moment(date).valueOf() : null;
        if (dateType === "date") {
          val = date ? moment.utc(date) : null;
          if (date && date._isValid && date._f != undefined) {
            val = date ? moment.utc(date._i, date._f).valueOf() : null;
          } else {
            val = date ? moment.utc(date).valueOf() : null;
          }
        }
        setFieldValue(field.name, val);
      }}
      placeholder={plchld}
      error={error && touched}
      invalidDateMessage={error && touched}
      helperText={!isConditional && touched && error}
      variant="inline"
    />
  );
};

export const DateTimePickerField = (props) => {
  return <DatePickerField {...props} dateType={"date-time"} />;
};

export const TimePickerField = (props) => {
  return (
    <DatePickerField
      {...props}
      dateType={"time"}
      keyboardIcon={<ScheduleIcon />}
    />
  );
};

export default DatePickerField;
