import { END_EXPORT_ALL, START_EXPORT_ALL } from "../constants";

export const startExportAll = (exportProp) => {
  return {
    type: START_EXPORT_ALL,
    ...exportProp,
  };
};

export const finishExportAll = () => {
  return {
    type: END_EXPORT_ALL,
  };
};
