import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React from "react";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import Modal from "../../../../Layouts/Modal/Modal";
import { getActions } from "./sensitive-vip-management";
import { labelPrefix } from "../../../../store/players/individual-player/sensitive-vip-manegement/sensitive-vip-management-reducer";
import { useDispatch } from "react-redux";
import { markUnmark } from "../../../../store/players/individual-player/sensitive-vip-manegement/actions";
import { useSession } from "../../../../utilities/hooks/useSession";

const SensitiveVipModal = ({ data, openModal, closeModal, contractId }) => {
  const { open, mode } = openModal;
  const t = useTranslator();
  const dispatch = useDispatch();
  const { brandSelected, partnerSelected } = useSession();

  let title = "";
  let body = "";
  if (data != null) {
    if (mode == "vip") {
      if (data.vip) {
        title = "removalVIP";
        body = "bodyRemovalVip";
      } else {
        title = "markVIP";
        body = "bodyMarkVip";
      }
    } else {
      if (data.sensitive) {
        title = "removalSensitive";
        body = "bodyRemovalSensitive";
      } else {
        title = "markSensitive";
        body = "bodyMarkSensitive";
      }
    }
  }
  return (
    <Modal
      title={t({
        needle: labelPrefix + "modal." + title,
      })}
      description={t({
        needle: labelPrefix + "modal." + body,
        variables: [contractId],
      })}
      open={open}
      content={
        <Box pt={0} p={2}>
          <Formik
            initialValues={{
              comment: "",
            }}
            onSubmit={(fields) => {
              let detail = { ...data };
              if (mode == "vip") {
                detail.vip = !data.vip;
                detail.vipComment =
                  fields.comment != "" ? fields.comment : null;
              } else if (mode == "sensitive") {
                detail.sensitive = !data.sensitive;
                detail.sensitiveComment =
                  fields.comment != "" ? fields.comment : null;
              }
              dispatch(
                markUnmark(
                  brandSelected,
                  partnerSelected,
                  contractId,
                  detail,
                  closeModal
                )
              );
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <TextField
                  name="comment"
                  type="String"
                  label={t({ needle: labelPrefix + "modal.comment" })}
                  fullWidth
                  multiline
                  inputProps={{
                    maxLength: 200,
                  }}
                  rows={4}
                  value={values.comment}
                  onChange={handleChange}
                />

                <Box display={"flex"} justifyContent={"flex-end"} pt={5}>
                  <Box mr={1}>
                    <Button
                      variant={"outlined"}
                      color={"secondary"}
                      type="button"
                      onClick={() => closeModal()}
                    >
                      {t({
                        needle: "button.cancel",
                      })}
                    </Button>
                  </Box>
                  {getActions(data.vip, data.sensitive, mode)}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      }
    />
  );
};

export default SensitiveVipModal;
