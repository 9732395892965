import { Box, Button, SvgIcon } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import Moment from "react-moment";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../../../Layouts/Currency/Currency";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  BONUS_LEVELS_FAILURE,
  BONUS_LEVELS_SUCCESS,
  DELETE_BONUS_SUCCESS,
  LOY_CREDIT_TYPE_FAILURE,
  LOY_CREDIT_TYPE_SUCCESS,
  LOY_REWARD_TYPES_FAILURE,
  LOY_REWARD_TYPES_SUCCESS,
  PROGRAM_BONUS_FAILURE,
  PROGRAM_BONUS_REQUEST,
  PROGRAM_BONUS_SUCCESS,
  PROGRAM_FAILURE,
  PROGRAM_SCALE_FAILURE,
  PROGRAM_SCALE_SUCCESS,
  PROGRAM_SUCCESS,
  SAVE_BONUS_LEVEL_SUCCESS,
  SAVE_NEW_LOY_BONUS_SUCCESS,
  SCHEDULED_PROGRAM_FAILURE,
  SCHEDULED_PROGRAM_SUCCESS,
} from "../../../constants";
import { ReactComponent as AssociateIcon } from "../../../../Images/button_associate.svg";
import { FormattedNumber } from "react-intl";
import { isActionHidden } from "../../../../utils";
import { usePageActions } from "../../../../utilities/hooks/usePageActions";


const getStatusMapping = (status) => {
  switch (status) {
    case "CONFIRMED":
      return { id: "2", label: "active" };

    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const getBonusStatusMapping = (status) => {
  switch (status) {
    case true:
      return { id: "2", label: "enabled" };
    case false:
      return { id: "6", label: "disabled" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const { buttons } = usePageActions

export const labelPrefix = "label.rewards.loyalty.program.";

const initialState = {
  scheduledProgram: {
    scheduledProgramList: {},
    scheduledProgramFields: [
      {
        field: "title",
        title: labelPrefix + "name",
      },
      {
        field: "longDescription",
        title: labelPrefix + "longDescription",
      },
      {
        field: "statusName",
        title: labelPrefix + "statusName",
        render: (rowData) => {
          if (rowData["statusName"]) {
            const statusMapped = getStatusMapping(rowData["statusName"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
              />
            );
          }
        },
      },
      {
        field: "startDate",
        title: labelPrefix + "startDate",
        render: (listData) => {
          return <Moment format={"L"}>{listData["startDate"]}</Moment>;
        },
      },
      {
        field: "endDate",
        title: labelPrefix + "endDate",
        render: (listData) => {
          return <Moment format={"L"}>{listData["endDate"]}</Moment>;
        },
      },
      {
        field: "lastUpdate",
        title: labelPrefix + "lastUpdate",
        render: (listData) => {
          return <Moment format={"L"}>{listData["lastUpdate"]}</Moment>;
        },
      },
    ],
    scheduledProgramLoading: true,
  },
  programObj: {},
  programScale: {
    scaleData: [],
    scaleColumns: [
      { field: "levelName", title: labelPrefix + "levelName", default: true },

      {
        field: "relevance",
        title: labelPrefix + "relevance",
        defaultSort: "asc",
        nowrap: false,
        render: (rowData) => {
          if (rowData["relevance"]) {
            return (
              <Box display="flex" justifyContent="flex-end">
                <FormattedNumber value={rowData["relevance"]} />
              </Box>
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "endRange",
        title: labelPrefix + "endRange",
        nowrap: false,
        default: true,
        render: (rowData) => {
          if (rowData["endRange"]) {
            return (
              <Box display="flex" justifyContent="flex-end">
                <FormattedNumber value={rowData["endRange"]} />
              </Box>
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "convertionFactor",
        title: labelPrefix + "convertionFactor",
        nowrap: false,
        render: (rowData) => {
          if (rowData["convertionFactor"]) {
            return (
              <Box display="flex" justifyContent="flex-end">
                <FormattedNumber value={rowData["convertionFactor"]} />
              </Box>
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "bonusPointsConvertionFactor",
        title: labelPrefix + "bonusPointsConvertionFactor",
        nowrap: false,
        render: (rowData) => {
          if (rowData["bonusPointsConvertionFactor"]) {
            return (
              <Box display="flex" justifyContent="flex-end">
                <FormattedNumber
                  value={rowData["bonusPointsConvertionFactor"]}
                />
              </Box>
            );
          } else {
            return "-";
          }
        },
      },
    ],
    scaleLoading: true,
  },
  programBonus: {
    bonusData: [],
    bonusColumns: (cbEdit, cdAssociate,editLevelButton,associateToLevelsButton) => [
      { field: "name", title: labelPrefix + "name", default: true },
      {
        field: "amount",
        title: labelPrefix + "amount",
        render: (rowData) => {
          if (rowData["amount"]) {
            return (
              <Currency
                amount={rowData["amount"]}
                currency={rowData["currency"]}
                applyDivisorAmount={true}
                justify="flex-end"
              />
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "currency",
        title: labelPrefix + "currency",
        render: (rowData) => {
          if (rowData["currency"]) {
            return (
              <CurrencyBadge currency={rowData["currency"]} justify="center" />
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "enabled",
        title: labelPrefix + "statusName",
        default: true,
        render: (rowData) => {
          const statusMapped = getBonusStatusMapping(rowData["enabled"]);
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "programId",
        title: labelPrefix + "associatedLevels",
        nowrap: false,
        hidden: isMobileOnly,
        hideMobile: true,
        render: (rowData) => (
          <Box display={"flex"} style={{ whiteSpace: "nowrap" }}>
            <Box>
              <Button
                size="small"
                color={"primary"}
                startIcon={<Edit />}
                disabled={editLevelButton}
                onClick={() => cbEdit(rowData)}
              >
                <Translate needle={labelPrefix + "editLevels"} />
              </Button>
            </Box>
            <Box pl={1}>
             <Button
                size="small"
                color={"primary"}
                disabled={associateToLevelsButton}
                startIcon={
                  <SvgIcon color={"inherit"} component={AssociateIcon} />
                }
                onClick={() => cdAssociate(rowData)}
              >
                <Translate needle={labelPrefix + "associateToLevels"} />
              </Button>
              </Box>
          </Box>
        ),
      },
    ],
    bonusLoading: true,
  },
  loyRewardTypes: [],
  loyCreditType: [],
  editLevelsCols: [
    { field: "level.pluginImplName", title: labelPrefix + "scale" },
    {
      field: "level.pluginImplId",
      title: labelPrefix + "level",
      render: (rowData) => {
        let label = `${labelPrefix}levelScale.${rowData["level"]["pluginImplId"]}`;
        return <Translate needle={label} />;
      },
    },
    { field: "level.levelName", title: labelPrefix + "name" },
    { field: "neededPoints", title: labelPrefix + "neededPoints" },
  ],
  success: null,
  bonusLevels: [],
  levelSuccess: null,
};

const ProgramReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULED_PROGRAM_SUCCESS: {
      return {
        ...state,
        scheduledProgram: {
          ...state.scheduledProgram,
          scheduledProgramLoading: false,
          scheduledProgramList: action.payload,
        },
      };
    }
    case SCHEDULED_PROGRAM_FAILURE: {
      return {
        ...state,
        scheduledProgram: {
          ...state.scheduledProgram,
          scheduledProgramLoading: false,
          scheduledProgramList: null,
        },
      };
    }
    case PROGRAM_SUCCESS: {
      return {
        ...state,

        programObj: action.payload,
      };
    }
    case PROGRAM_FAILURE: {
      return {
        ...state,

        programObj: null,
      };
    }
    case PROGRAM_BONUS_SUCCESS: {
      return {
        ...state,
        programBonus: {
          ...state.programBonus,
          bonusLoading: false,
          bonusData: action.payload,
        },
      };
    }

    case PROGRAM_BONUS_FAILURE: {
      return {
        ...state,
        programBonus: {
          ...state.programBonus,
          bonusLoading: false,
          bonusData: null,
        },
      };
    }

    case PROGRAM_SCALE_SUCCESS: {
      return {
        ...state,
        programScale: {
          ...state.programScale,
          scaleLoading: false,
          scaleData: action.payload,
        },
      };
    }

    case PROGRAM_SCALE_FAILURE: {
      return {
        ...state,
        programScale: {
          ...state.programScale,
          scaleLoading: false,
          scaleData: null,
        },
      };
    }

    case LOY_REWARD_TYPES_SUCCESS: {
      return {
        ...state,
        loyRewardTypes: action.payload,
        loyCreditType: [],
      };
    }

    case LOY_REWARD_TYPES_FAILURE: {
      return {
        ...state,
        loyRewardTypes: null,
        loyCreditType: [],
      };
    }
    case LOY_CREDIT_TYPE_SUCCESS: {
      return {
        ...state,
        loyCreditType: action.payload,
      };
    }

    case LOY_CREDIT_TYPE_FAILURE: {
      return {
        ...state,
        loyCreditType: [],
      };
    }

    case SAVE_NEW_LOY_BONUS_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }

    case PROGRAM_BONUS_REQUEST: {
      return {
        ...state,
        success: null,
        levelSuccess: null,
        deleteSuccess: null,
      };
    }

    case BONUS_LEVELS_SUCCESS: {
      return {
        ...state,
        bonusLevels: action.payload,
      };
    }

    case BONUS_LEVELS_FAILURE: {
      return {
        ...state,
        bonusLevels: null,
      };
    }

    case SAVE_BONUS_LEVEL_SUCCESS: {
      return {
        ...state,
        levelSuccess: true,
      };
    }

    case DELETE_BONUS_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default ProgramReducer;
