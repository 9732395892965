import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { IS_LOCAL } from "../../store/constants";
import Box from "@material-ui/core/Box";
import { isMobileOnly } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.primary2Accent2,
    height: 98,
  },
  main: {
    width: `calc(100% - ${60}px)`,
    marginLeft: 60,
  },
  pinned: {
    width: `calc(100% - ${360}px)`,
    marginLeft: 360,
  },
  link: {
    color: theme.palette.text.primary2,
    fontWeight: "bold",
    fontSize: 15,
  },
  separator: {
    marginLeft: 15,
    marginRight: 15,
  },
}));

const Disclaimer = (props) => {
  const t = useTranslator();
  const classes = useStyles();
  let path = "";
  if (IS_LOCAL === "true") {
    path = "/";
  } else {
    path = "/igtcontrol/";
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      className={clsx(classes.root, {
        [classes.main]: !isMobileOnly && props.isPinned !== undefined,
        [classes.pinned]: !isMobileOnly && props.isPinned,
      })}
    >
      <Box>
        <Typography>
          <Link
            href={path + "Disclaimer.html"}
            target="_blank"
            className={classes.link}
          >
            {t({ needle: "label.disclaimer.disclaimer" })}
          </Link>
          <span className={classes.separator}>|</span>
          <Link
            href={path + "Disclaimer.html"}
            target="_blank"
            className={classes.link}
          >
            {t({ needle: "label.disclaimer.privacyPolicy" })}
          </Link>
          <span className={classes.separator}>|</span>
          <Link
            href={path + "Disclaimer.html"}
            target="_blank"
            className={classes.link}
          >
            {t({ needle: "label.disclaimer.terms" })}
          </Link>
        </Typography>
      </Box>
      <Box mt={1}>{t({ needle: "label.disclaimer.copyrights" })}</Box>
    </Box>
  );
};

export default Disclaimer;
