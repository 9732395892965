import axios from "axios";

class IndividualConsentsService {
  async loadConsentsList(contractId, brand, partner, registrationLevelId) {
    const response = await axios.get(
      `/gov/api/rest/v1/consents-player/${contractId}?brand=${brand}&partner=${partner}&user-type-id=${registrationLevelId}`
    );
    if (response) {
      return response.data;
    }
    throw Error("Invalid response for individual get consents list");
  }

  async editConsent(
    contractId,
    brand,
    partner,
    consentTypeId,
    languageId,
    userTypeId,
    requestDate,
    state,
    text,
    version
  ) {
    const response = await axios.put(
      `/gov/api/rest/v1/consents-player/${contractId}?brand=${brand}&partner=${partner}`,
      {
        contractId: contractId,
        consentTypeId: consentTypeId,
        state: state,
        version_number: version,
        text: text,
        requestDate: requestDate,
        languageId: languageId,
        userTypeId: userTypeId,
      }
    );
    if (response) {
      return response.status;
    }
    throw Error("Invalid response for individual edit consent");
  }

  async loadConsentsHistoryList(
    contractId,
    brand,
    partner,
    consentTypeId,
    userTypeId
  ) {
    const response = await axios.get(
      `/gov/api/rest/v1/consents-player-history/${contractId}?brand=${brand}&partner=${partner}&consent_type_id=${consentTypeId}&user-type-id=${userTypeId}`
    );
    if (response) {
      return response.data;
    }
    throw Error("Invalid response for individual get consents list");
  }
}

const individualConsentsService = new IndividualConsentsService();
export default individualConsentsService;
