import React from "react";
import FormField from "./form-field";
import SelectField from "./select-field";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "./form-label";
import { useField } from "formik";
import { FormHelperText } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { getFieldInitialValue } from "./utils";

const ConditionalField = ({
  label,
  optional,
  tooltip,
  name,
  type,
  options,
  field,
  ...rest
}) => {
  const [, valueMeta, valueHelper] = useField(name + ".value");
  const [operatorField] = useField(name + ".operatorName");
  const [disabled, setDisabled] = React.useState(true);
  const valueHelperCb = React.useCallback(valueHelper, []);
  const t = useTranslator();
  const classes = useStyle();
  const { error, touched } = valueMeta;
  const operatorValue = operatorField.value;

  React.useEffect(() => {
    if (operatorValue != null && operatorValue !== "") {
      setDisabled(false);
    } else {
      valueHelperCb.setValue(getFieldInitialValue(field));
      setDisabled(true);
    }
  }, [operatorValue, valueHelperCb, field]);

  return (
    <Box mt={"15px"}>
      <Box mb={"6px"}>
        <InputLabel error={error && touched}>
          <FormLabel label={label} tooltip={tooltip} optional={optional} />
        </InputLabel>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        className={classes.conditionalFields}
      >
        <Box width={"calc(40% - 8px)"}>
          <SelectField
            fullWidth
            placeholder={"label.placeholders.selectCondition"}
            name={`${name}.operatorName`}
            options={options.map((it) => ({
              value: it.name,
              label: t({ needle: "label.form.conditionals." + it.name }),
            }))}
            valueKey={"value"}
            labelKey={"label"}
            label={null}
            error={error && touched}
          />
        </Box>
        <Box width={"calc(60% - 8px)"}>
          <FormField
            {...field}
            {...rest}
            isConditional={true}
            placeholder={"label.placeholders.enterValue"}
            name={`${name}.value`}
            disabled={disabled}
            label={null}
          />
        </Box>
      </Box>
      {touched && error ? (
        <FormHelperText error={true}>{error}</FormHelperText>
      ) : null}
    </Box>
  );
};

const useStyle = makeStyles((theme) => ({
  conditionalFields: {
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
  },
}));

export default ConditionalField;
