import axios from "axios";

class BonusAbuserHistoryService {
  async getBonusAbuserHistory(contractId, brand, partner) {
    let url = `/gov/api/rest/v2/player/bonusAbuserHistory/${contractId}/${brand}/${partner}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player notes list");
  }

  async addRemoveBonusAbuser(contractId, brand, partner, reason, bonusAbuser) {
    let url = `/gov/api/rest/v2/player/managePlayerBonusAbuser`;
    const response = await axios.post(url, {
      player: { brandId: brand, partnerId: partner, contractId: contractId },
      note: reason,
      bonusAbuser: bonusAbuser,
    });
    if (response && response.status === 204) {
      return response.status;
    }
    throw Error("Invalid response for bonus abuser management");
  }
}

const bonusAbuserHistoryService = new BonusAbuserHistoryService();
export default bonusAbuserHistoryService;
