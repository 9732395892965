import axios from "axios";

class BasicStepService {
  async getRewardTypes(brand, partner, isSinglePlayer = false) {
    let url = `/gov/api/rest/v1/massiveRewards/typeList?partnerId=${partner}&brandId=${brand}&singlePlayer=${isSinglePlayer}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getGroupName(brand, partner) {
    let url = `/gov/api/rest/v1/playergroup/get_playerGroups/${brand}/${partner}`;
    const response = await axios.get(url);
    if (response && response.data && response.data.collection) {
      return response.data.collection;
    }
    throw Error("Invalid data");
  }

  async getCommunication(brand, partner) {
    let url = `/gov/api/rest/v1/communications/massive-rewards?brand=${brand}&partner=${partner}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async putReward(
    brand,
    partner,
    data,
    checkAmount,
    approved,
    approvedForBudget,
    clone
  ) {
    const body = {
      ...data,
      brand,
      partner,
      operatorBrandId: brand,
      operatorPartnerId: partner,
      checkAmount,
      approved,
      approvedForBudget,
    };

    if (data.bonusBagReward !== undefined && data.bonusBagReward.conversionDestinationGameDomain === "") {
      const conversionDestinationGameDomain = {
        name: "",
        value: "",
      };
      data.bonusBagReward.conversionDestinationGameDomain = conversionDestinationGameDomain;
    }

    if (data.reward && data.massiveId) {
      delete body["brand"];
      delete body["partner"];
      if (body["tableData"]) {
        delete body["tableData"];
      }
    }

    let response;

    if (data.reward && data.massiveId && !clone) {
      response = await axios.put(
        `/gov/api/rest/v1/massiveRewards/${data.massiveId}`,
        body
      );
    } else {
      response = await axios.post("/gov/api/rest/v1/massiveRewards", body);
    }

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for expiring bonus");
  }

  async assignReward(
    brand,
    partner,
    data,
    checkAmount,
    approved,
    approvedForBudget
  ) {
    const body = {
      ...data,
      operatorBrandId: brand,
      operatorPartnerId: partner,
      checkAmount,
      approved,
      approvedForBudget,
    };
    
    if (data.bonusBagReward !== undefined && data.bonusBagReward.conversionDestinationGameDomain === "") {
      const conversionDestinationGameDomain = {
        name: "",
        value: "",
      };
      data.bonusBagReward.conversionDestinationGameDomain = conversionDestinationGameDomain;
    }

    const response = await axios.post(
      "/gov/api/rest/v2/player/assignReward",
      body
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for expiring bonus");
  }

  async checkLimit(rewardTypeId) {
    let url = `/gov/api/rest/v2/limit/enabled_by_role_and_reward_type?rewardTypeId=${rewardTypeId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response");
  }

  async deskValidate(deskModel) {
    const response = await axios.post("/gov/api/rest/v2/desk/validate", deskModel);
    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response");
  }

  async desk(deskModel) {
    const response = await axios.post("/gov/api/rest/v2/desk", deskModel);
    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response");
  }
}

const basicStepService = new BasicStepService();
export default basicStepService;
