import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getReasonsByAction } from "../../store/players/individual-player/club-card/actions";

export const useReasonsForActions = (brand, partner, action, locale) => {
  const { reasons } = useSelector(
    (state) => state.ClubCardReducer.clubCardDetails
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getReasonsByAction(brand, partner, action, locale));
  }, [dispatch, brand, partner, action]);

  return reasons;
};
