import { select, takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  DEDUCT_AMOUNT_FAILURE,
  DEDUCT_AMOUNT_REQUEST,
  DEDUCT_AMOUNT_SUCCESS,
  TAKE_IN_CHARGE_FAILURE,
  TAKE_IN_CHARGE_REQUEST,
  TAKE_IN_CHARGE_SUCCESS,
  TAXABLE_PRIZE_DOWNLOAD_PDF_FAILURE,
  TAXABLE_PRIZE_DOWNLOAD_PDF_REQUEST,
  TAXABLE_PRIZE_GENERATE_PDF_FAILURE,
  TAXABLE_PRIZE_GENERATE_PDF_REQUEST,
  TAXABLE_PRIZE_LIST_FILTERS_FAILURE,
  TAXABLE_PRIZE_LIST_FILTERS_REQUEST,
  TAXABLE_PRIZE_LIST_FILTERS_SUCCESS,
  TAXABLE_PRIZE_PAYMENT_FAILURE,
  TAXABLE_PRIZE_PAYMENT_REQUEST,
  TAXABLE_PRIZE_PAYMENT_SUCCESS,
  TRANSACTION_LOGS_FAILURE,
  TRANSACTION_LOGS_REQUEST,
  TRANSACTION_LOGS_SUCCESS,
  WINNINGS_LIST_FAILURE,
  WINNINGS_LIST_RELOAD,
  WINNINGS_LIST_REQUEST,
  WINNINGS_LIST_SUCCESS,
} from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import taxablePrizeService from "./taxable-prize-service";

const loadListFilter = function* (action) {
  try {
    const data = yield call(
      taxablePrizeService.getListFilter,
      action.gameAccount
    );
    yield put({
      type: TAXABLE_PRIZE_LIST_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("taxable prize filters", e);
    yield put({
      type: TAXABLE_PRIZE_LIST_FILTERS_FAILURE,
    });
  }
};

const loadWinnings = function* (action) {
  try {
    let searchTerms = null;
    if (action.gameAccount !== null) {
      searchTerms = {
        gameAccount: action.gameAccount,
      };
    }
    if (action.filters != null) {
      searchTerms = {
        paymentRequestDateFrom: action.filters.paymentRequestDate.from
          ? action.filters.paymentRequestDate.from
          : "",
        paymentRequestDateTo: action.filters.paymentRequestDate.to
          ? action.filters.paymentRequestDate.to
          : "",
        paymentDateFrom: action.filters.paymentDate.from
          ? action.filters.paymentDate.from
          : "",
        paymentDateTo: action.filters.paymentDate.to
          ? action.filters.paymentDate.to
          : "",
        gameAccount:
          action.gameAccount !== null
            ? action.gameAccount
            : action.filters.gameAccount,
        pdfStatus: action.filters.pdfStatus,
        onHoldStatus: action.filters.onHoldStatus,
        inChargeByMe: action.filters.inChargeByMe,
        paymentStatus: action.filters.paymentStatus,
        transactionId: action.filters.transactionId,
      };
      if (action.filters.taxed !== "ALL") {
        searchTerms["taxed"] = action.filters.taxed;
      }
    }

    const data = yield call(
      taxablePrizeService.getWinnings,
      action.brand.id,
      action.partner.id,
      searchTerms,
      action.page,
      action.pageSize,
      action.orderBy
    );
    data.order = action.orderBy;
    yield put({
      type: WINNINGS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadWinnings", e);
    yield put({
      type: WINNINGS_LIST_FAILURE,
    });
  }
};

const downloadPdf = function* (action) {
  try {
    const pdf = yield call(
      taxablePrizeService.downloadPdf,
      action.brand.id,
      action.partner.id,
      action.thpId,
      action.gameAccount
    );
  } catch (e) {
    console.error("Download Pdf", e);
    yield put({
      type: TAXABLE_PRIZE_DOWNLOAD_PDF_FAILURE,
    });
  }
};

const generatePdf = function* (action) {
  try {
    yield call(taxablePrizeService.generatePdf, action.thpId);

    yield put({
      type: WINNINGS_LIST_RELOAD,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.generatePdf",
        },
        options: {
          key: "generatePdf",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("Generate PDF", e);
    yield put({
      type: TAXABLE_PRIZE_GENERATE_PDF_FAILURE,
    });
  }
};

const reloadWinningList = function* () {
  const lastRequest = yield select(
    (state) => state.TaxablePrizeReducer.taxablePrizeList.lastRequest
  );
  if (lastRequest) {
    yield put(lastRequest);
  }
};

const takeInChargeOnHoldPayment = function* (action) {
  try {
    const data = yield call(
      taxablePrizeService.takeInChargeOnHoldPayment,
      action.inCharge,
      action.onHoldId,
      action.brand,
      action.partner,
      action.subjectInCharge
    );

    yield put({
      type: WINNINGS_LIST_RELOAD,
    });

    yield put({
      type: TAKE_IN_CHARGE_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: action.inCharge ? "ok.takeInCharge" : "ok.released",
        },
        options: {
          key: "takeInCharge",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("retrieve user preferences", e);
    yield put({
      type: TAKE_IN_CHARGE_FAILURE,
    });
  }
};

const deductAmount = function* (action) {
  try {
    const data = yield call(
      taxablePrizeService.deductAmount,
      action.dataRequest
    );

    yield put({
      type: WINNINGS_LIST_RELOAD,
    });

    yield put({
      type: DEDUCT_AMOUNT_SUCCESS,
      payload: data,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.deductAmount",
          variables: [action.dataRequest.formattedAmount],
        },
        options: {
          key: "deductAmount",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("retrieve user preferences", e);
    yield put({
      type: DEDUCT_AMOUNT_FAILURE,
    });
  }
};

const onHoldPayments = function* (action) {
  try {
    const data = yield call(
      taxablePrizeService.onHoldPayments,
      action.dataRequest
    );

    yield put({
      type: WINNINGS_LIST_RELOAD,
    });

    yield put({
      type: TAXABLE_PRIZE_PAYMENT_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.onHoldPayment",
          variables: [
            `label.taxablePrize.onHoldPayment.${action.dataRequest.key}.key`,
          ],
        },
        options: {
          key: "onHoldPayment",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("retrieve user preferences", e);
    yield put({
      type: TAXABLE_PRIZE_PAYMENT_FAILURE,
    });
  }
};

const getTransactionLogs = function* (action) {
  try {
    const data = yield call(
      taxablePrizeService.getTransactionLogs,
      action.dataTransaction
    );
    yield put({
      type: TRANSACTION_LOGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadWinnings", e);
    yield put({
      type: TRANSACTION_LOGS_FAILURE,
    });
  }
};

const TaxablePrizeSaga = function* TaxablePrizeSaga() {
  yield takeLatest(TAXABLE_PRIZE_LIST_FILTERS_REQUEST, loadListFilter);
  yield takeLatest(WINNINGS_LIST_REQUEST, loadWinnings);
  yield takeLatest(TAKE_IN_CHARGE_REQUEST, takeInChargeOnHoldPayment);
  yield takeLatest(WINNINGS_LIST_RELOAD, reloadWinningList);
  yield takeLatest(DEDUCT_AMOUNT_REQUEST, deductAmount);
  yield takeLatest(TAXABLE_PRIZE_PAYMENT_REQUEST, onHoldPayments);
  yield takeLatest(TRANSACTION_LOGS_REQUEST, getTransactionLogs);
  yield takeLatest(TAXABLE_PRIZE_DOWNLOAD_PDF_REQUEST, downloadPdf);
  yield takeLatest(TAXABLE_PRIZE_GENERATE_PDF_REQUEST, generatePdf);
};

export default TaxablePrizeSaga;
