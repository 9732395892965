import Moment from "react-moment";
import React from "react";
import {
  INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_FAILURE,
  INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_SUCCESS,
  INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_FAILURE,
  INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_EDIT_MODAL,
  INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_SUCCESS,
} from "../../../constants";
import Translate from "../../../../Layouts/Translator/Translate";
import { Chip } from "@material-ui/core";

const initialState = {
  loading: true,
  keyAccessList: {
    data: [],
    columns: [
      {
        field: "keyType",
        title: "label.individualplayer.keyAccessManagement.keyType.label",
        default: true,
        render: (rowData) => {
          if (rowData["keyType"]) {
            return (
              <Translate
                needle={
                  "label.individualplayer.keyAccessManagement.keyType." +
                  rowData["keyType"]
                }
              />
            );
          }
        },
      },
      {
        field: "identifier",
        title: "label.individualplayer.keyAccessManagement.identifier",
      },
      {
        field: "datePlayerLockExpire",
        title: "label.individualplayer.keyAccessManagement.unlockDate",
        render: (rowData) => {
          if (rowData["datePlayerLockExpire"] && rowData["locked"]) {
            return (
              <Chip
                label={
                  <Moment format="L LT">
                    {rowData["datePlayerLockExpire"]}
                  </Moment>
                }
                style={{
                  backgroundColor: "#4DAF4F",
                  color: "white",
                }}
              />
            );
          } else {
            return "-";
          }
        },
      },
    ],
  },
  editKeyAccess: {
    success: null,
  },
};

const KeyAccessManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        keyAccessList: { ...state.keyAccessList, data: action.payload },
        editKeyAccess: {
          ...state.editKeyAccess,
          success: false,
        },
      };
    }
    case INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        keyAccessList: { ...state.keyAccessList, data: null },
      };
    }
    case INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_FAILURE: {
      return {
        ...state,
        editKeyAccess: {
          ...state.editKeyAccess,
          success: false,
        },
      };
    }
    case INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_SUCCESS: {
      return {
        ...state,
        editKeyAccess: {
          ...state.editKeyAccess,
          success: true,
        },
      };
    }
    case INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_EDIT_MODAL: {
      return {
        ...state,
        editKeyAccess: {
          ...state.editKeyAccess,
          success: false,
        },
      };
    }
    case INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_SUCCESS: {
      return {
        ...state,
        editKeyAccess: {
          ...state.editKeyAccess,
          success: true,
        },
      };
    }
    default:
      return state;
  }
};

export default KeyAccessManagementReducer;
