import React from "react";
import moment from "moment-timezone";
import Translate from "../Translator/Translate";
import FormTooltip from "../form/form-tooltip";
import { Box } from "@material-ui/core";

const DateFormatter = ({ format = "L", value, defaultValue = "-", utc , timezone }) => {
  return value != null
    ? utc
      ? moment.utc(+value || value).format(format)
      : timezone ? moment(+value || value).tz(timezone).format(format) : moment(+value || value).format(format)
    : defaultValue;
};

export const TimezoneDate = ({ format = "L", value, defaultValue = "-", timezone = "utc" }) => {
  return <Box display="flex">
      {DateFormatter({format, value, defaultValue, utc:false, timezone})}
      {value ? <FormTooltip
        title={
          <Translate
            needle={"label.timezone"}
            variables={[timezone]}
          />
        }
      /> : ""}
    </Box>
}

export const TimezoneDateWithTimezone = ({ format = "L", value, defaultValue = "-", timezone = "utc" })=>{
  return DateFormatter({format, value, defaultValue, utc:false, timezone}) + " " +timezone
}
export default DateFormatter;