import axios from "axios";

class SecondStepService {
  async getOperations(groupId) {
    let url = `/gov/api/rest/v1/ewl/operation_by_group?groupId=${groupId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getTemplates(template, enabled, brand, partner) {
    let params = {
      enabled: enabled,
      brandId: brand,
      partnerId: partner,
    };
    if (template != null) {
      params = {
        template: template,
        ...params,
      };
    }
    let url = `/gov/api/rest/v1/ams/bonus-bags/template/list`;
    const response = await axios.get(url, { params });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getGameDetail(brand, partner, gameCode, currency) {
    let url = `/gov/api/rest/v3/game-code-freeSpinAmount?brand=${brand}&partner=${partner}&gameCode=${gameCode}&currency=${currency}`;
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getValidityDateType() {
    let url = "/gov/api/rest/v1/ams/bonus-bags/validityDateType";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getBonusTypes() {
    let url = "/gov/api/rest/v1/ams/bonus-bags/qr-types-coherence";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data.collection;
    }
    throw Error("Invalid data");
  }

  async getDevices() {
    let url = "/gov/api/rest/v2/devices/";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getThresholdTypes() {
    let url = "/gov/api/rest/v1/ams/bonus-bags/tresholdType";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data.collection;
    }
    throw Error("Invalid data");
  }

  async getOrganizations() {
    let url = "/gov/api/rest/v1/ticketTournament/organizations";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getVoucherTemplates(voucherType) {
    let url = `/gov/api/rest/v1/voucherRewards/templates?voucherType=${voucherType}`;
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }
}

const secondStepService = new SecondStepService();
export default secondStepService;
