import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
// Import Component(s)
import CommandRouter from "./CommandRouter";
import Breadcrumb from "./Layouts/Breadcrumb/Breadcrumb";
import TreeMenu from "./Layouts/TreeMenu/TreeMenu";
import Header from "./Layouts/Header/Header";
import Disclaimer from "./Layouts/Disclaimer/Disclaimer";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import SpinnerIndicator from "./Layouts/SpinnerIndicator/SpinnerIndicator";
import menuItemsService from "./utilities/menuItems";
import Hidden from "@material-ui/core/Hidden";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import AccessDenied from "./Pages/AccessDenied/access-denied";
import Box from "@material-ui/core/Box";
import { logoutHandler, refreshToken } from "./store/authentication/actions";
import { useSession } from "./utilities/hooks/useSession";
import { useExport } from "./utilities/hooks/useExport";
// Import Stylesheet(s)

const useStyle = makeStyles((theme) => ({
  root: {
    // display: "flex"
  },
  paper: {
    overflow: "hidden",
  },
  drawer: {
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    width: "100%",
    transition: theme.transitions.create(["margin-left", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 60,
      width: `calc(100% - ${60}px)`,
    },
    minHeight: `calc(100vh - ${98}px)`,
  },
  pinned: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: 360,
      width: `calc(100% - ${360}px)`,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  providerToolbar: {
    height: 40,
  },
}));

let dispatch;
let session;
let inactivityInterval;
const setInactivityInterval = () => {
  inactivityInterval = setInterval(() => {
    const idleTimeout = parseInt(sessionStorage.getItem("idleTimeout"));
    if (idleTimeout != null) {
      if (idleTimeout >= session.inactivityTimeout) {
        dispatch(logoutHandler(null, window));
      } else {
        sessionStorage.setItem("idleTimeout", idleTimeout + 1);
      }
    }
  }, 60000);
};

export const resetCounter = () => {
  sessionStorage.setItem("idleTimeout", 1);
  clearInterval(inactivityInterval);
  setInactivityInterval();
};

export const operatorActivityListener = (it) => {
  it.addEventListener("mousemove", resetCounter, false);
  it.addEventListener("mousedown", resetCounter, false);
  it.addEventListener("mouseover", resetCounter, false);
  it.addEventListener("mouseout", resetCounter, false);
  it.addEventListener("keypress", resetCounter, false);
  it.addEventListener("DOMMouseScroll", resetCounter, false);
  it.addEventListener("mousewheel", resetCounter, false);
  it.addEventListener("touchmove", resetCounter, false);
  it.addEventListener("MSPointerMove", resetCounter, false);
};

function AuthenticatedApp(props) {
  useExport(props.exportConf)
  sessionStorage.setItem("idleTimeout", 1);
  operatorActivityListener(window);
  const classes = useStyle();
  const theme = useTheme();
  dispatch = useDispatch();
  session = useSession();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const pinDisabled = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [isPinned, setIsPinned] = React.useState(false);

  const savedPinnedState = sessionStorage.getItem("isPinned") === "true";
  if (isPinned !== savedPinnedState) setIsPinned(savedPinnedState);

  let location = useLocation();
  const history = useHistory();

  const { menuItems, menus } = props;
  let defaultRoute = null;
  if (location.pathname === "/" && menuItems.length !== 0) {
    var menuItem = menuItemsService.getItemUrl(menuItems[0].url);

    defaultRoute = menuItem;
  } else if (location.pathname === "/silent-login") {
    defaultRoute = sessionStorage.getItem("silentLoginRoute");
    sessionStorage.removeItem("silentLoginRoute");
  } else if (location.pathname === "/externalOauthLogin") {
    history.replace("/");
  }

  const startCheckToken = () => {
    const token = sessionStorage.getItem("access-token");
    const nextTokenRefresh = sessionStorage.getItem("nextTokenRefresh");
    if (
      token != null &&
      nextTokenRefresh != null &&
      new Date().getTime() > nextTokenRefresh
    ) {
      sessionStorage.removeItem("nextTokenRefresh");
      dispatch(refreshToken());
    }
    setTimeout(startCheckToken, 30000);
  };

  React.useEffect(() => {
    setInactivityInterval();
    return () => clearInterval(inactivityInterval);
  }, []);

  startCheckToken();

  // (async () => {
  //   const clientIp = await publicIp.v4();
  //
  //   const { user } = props;
  //   var payload = "{\"username\" : \"" + user.username + "\"}";
  //
  //   // eslint-disable-next-line
  //   const audit = await axios.post("/common/api/v1/audit", {
  //     operationType: { id: "1" },
  //     moduleDescription: "Login",
  //     actionDescription: "Login",
  //     clientIp: clientIp,
  //     payload: encodeURIComponent(payload)
  //   });
  // })();

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Switch>
      <Route path="/404" exact component={PageNotFound} />
      <Route path="/not-authorized" exact component={AccessDenied} />
      <Route path="*">
        <div className={classes.root}>
          <Header handleOpen={handleOpen} />
          <nav className={classes.drawer}>
            {matches ? (
              <TreeMenu
                handleOpen={handleOpen}
                isMobile={true}
                open={open}
                pinDisabled={pinDisabled}
              />
            ) : (
              <TreeMenu
                isPinned={isPinned}
                pinDisabled={pinDisabled}
                togglePinned={setIsPinned}
              />
            )}
          </nav>
          <main
            className={clsx(classes.content, {
              [classes.pinned]: !pinDisabled && isPinned,
            })}
          >
            <div className={classes.toolbar} />
            <Hidden smUp>
              <div className={classes.providerToolbar} />
            </Hidden>
            <Breadcrumb menus={menus} />
            <React.Suspense fallback={<SpinnerIndicator />}>
              <Box p={isMobileOnly ? 1 : 2} pt={0}>
                <CommandRouter />
              </Box>
            </React.Suspense>
          </main>
          <Disclaimer isPinned={!pinDisabled && isPinned} />
          {defaultRoute ? <Redirect to={defaultRoute} /> : null}
        </div>
      </Route>
    </Switch>
  );
}

const mapStateToProps = (state) => {
  const { menuItems, flatMenuItems } = state.SideMenuReducer.result;
  const { session } = state.AuthenticationReducer;
  const exportConf = state.ExportReducer
  return { menus: flatMenuItems, menuItems, user: session.user, exportConf };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApp);
