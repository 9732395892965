import { REFRESH_TREE_MENU_REQUEST } from "../constants";

const sideMenuHandler = (session) => {
  return {
    type: REFRESH_TREE_MENU_REQUEST,
    payload: session,
  };
};

export { sideMenuHandler };
