import {
  PLAYER_CONTACTS_VERIFICATION_FILTERS_REQUEST,
  PLAYER_CONTACTS_VERIFICATION_FILTERS_SUCCESS,
  PLAYER_CONTACTS_VERIFICATION_LIST_FAILURE,
  PLAYER_CONTACTS_VERIFICATION_LIST_REQUEST,
  PLAYER_CONTACTS_VERIFICATION_LIST_SUCCESS,
} from "../../../constants";
import React from "react";
import Translate from "../../../../Layouts/Translator/Translate";
import { Box, Switch } from "@material-ui/core";

export const labelPrefix = "label.admin.playerContactsVerification.";

const getMappedLabel = (name) => {
  switch (name) {
    case "sms":
      return "SMS";
    case "reserved-sms": {
      return "RESERVED_SMS";
    }
    case "e-mail": {
      return "EMAIL";
    }
    case "reserved-e-mail":
      return "RESERVED_EMAIL";
    default: {
      return null;
    }
  }
};

export const getRegLevelLabel = (value) => {
  switch (value) {
    case 1:
      return "basicPlayer";
    case 2:
      return "fullPlayer";
    case 3:
      return "portalPlayer";
    case 4:
      return "anonymous";
    case 5:
      return "lightPlayer";
    case 7:
      return "connectedPlayLight";
    case 8:
      return "secondChance";
    default: {
      return ''+value;
    }
  }
};

const initialState = {
  playerContactsVerification: {
    filters: [],
    filterKey: "",
    filtersLoading: true,
    tableLoading: true,
    data: [],
    columns: (brand, partner, handleContactVerification, handleLoginCheck, buttons) => {
      return [
        {
          field: "contactType",
          title: labelPrefix + "contactType",
          default: true,
          defaultSort: "asc",
          render: (rowData) => {
            const label = getMappedLabel(rowData["contactType"]);
            if (label != null) {
              return (
                <Translate
                  needle={`label.individualplayer.contactConfig.${label}`}
                />
              );
            }
          },
        },
        {
          field: "regLevel",
          title: labelPrefix + "regLevel",
          default: true,
          render: (rowData) => {
            const label = getRegLevelLabel(rowData["regLevel"]);
            return <Translate needle={labelPrefix + label} />;
          },
        },
        {
          field: "brandId",
          title: labelPrefix + "brandId",
          render: () => {
            return brand.name;
          },
        },
        {
          field: "partnerId",
          title: labelPrefix + "partnerId",
          render: () => {
            return partner.name;
          },
        },
        {
          field: "verification",
          title: labelPrefix + "verification",
          nowrap: true,
          sorting: false,
          render: (rowData) => {
            const item =
              buttons &&
              buttons.find((it) => it.uid === "EnableDisableContact");

            return (
              <Box display={"flex"} justifyContent={"center"}>
                <Switch
                  name={"verification"}
                  checked={rowData["verification"]}
                  size={"small"}
                  onChange={() => handleContactVerification(rowData)}
                  disabled={item == null}
                />
              </Box>
            );
          },
        },
        {
          field: "loginCheck",
          title: labelPrefix + "loginCheck",
          nowrap: true,
          sorting: false,
          render: (rowData) => {
            const item =
              buttons &&
              buttons.find((it) => it.uid === "EnableDisableContact");

            return (
              <Box display={"flex"} justifyContent={"center"}>
                <Switch
                  name={"loginCheck"}
                  checked={rowData["loginCheck"]}
                  size={"small"}
                  onChange={() => handleLoginCheck(rowData)}
                  disabled={item == null}
                />
              </Box>
            );
          },
        },
      ];
    },
  },
};

const PlayerContactsVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_CONTACTS_VERIFICATION_FILTERS_REQUEST:
      return {
        ...state,
        playerContactsVerification: {
          ...state.playerContactsVerification,
          filters: [],
          filtersLoading: true,
        },
      };
    case PLAYER_CONTACTS_VERIFICATION_FILTERS_SUCCESS:
      return {
        ...state,
        playerContactsVerification: {
          ...state.playerContactsVerification,
          filters: action.payload,
          filtersLoading: false,
        },
      };
    case PLAYER_CONTACTS_VERIFICATION_LIST_REQUEST:
      return {
        ...state,
        playerContactsVerification: {
          ...state.playerContactsVerification,
          tableLoading: true,
          lastRequest: action,
        },
      };

    case PLAYER_CONTACTS_VERIFICATION_LIST_SUCCESS:
      return {
        ...state,
        playerContactsVerification: {
          ...state.playerContactsVerification,
          data: action.payload,
          tableLoading: false,
        },
      };

    case PLAYER_CONTACTS_VERIFICATION_LIST_FAILURE:
      return {
        ...state,
        playerContactsVerification: {
          ...state.playerContactsVerification,
          data: null,
          tableLoading: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default PlayerContactsVerificationReducer;
