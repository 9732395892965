import * as moment from "moment";
import React from "react";
import Moment from "react-moment";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  BLACKLIST_FAILURE,
  BLACKLIST_FILTERS_FAILURE,
  BLACKLIST_FILTERS_REQUEST,
  BLACKLIST_FILTERS_SUCCESS,
  BLACKLIST_REQUEST,
  BLACKLIST_SUCCESS,
  BLACKLIST_TYPE_FAILURE,
  BLACKLIST_TYPE_SUCCESS,
  BLACKLIST_VALUE_TYPE_FAILURE,
  BLACKLIST_VALUE_TYPE_SUCCESS,
  STYLE_LABEL,
} from "../../../constants";

export const labelPrefix = "label.rewards.campaigns.blacklist.";

const initialState = {
  blacklist: {
    idKey: "campaignId",
    filtersLoading: true,
    tableLoading: true,
    filters: [],
    data: [],
    columns: [
      {
        field: "id",
        title: labelPrefix + "id",
        default: true,
        getExportValueFn: (rowData) => {
          if (rowData["id"] != null) {
            return rowData["id"] + "";
          }
        },
      },
      {
        field: "blackListType",
        title: labelPrefix + "blackListType",
        default: true,
        render: (rowData) => {
          if (rowData["blackListType"]) {
            const blackListType =
              labelPrefix + "type." + rowData["blackListType"];
            return <Translate needle={blackListType} />;
          }
        },
        getExportTypeFn: (rowData) => {
          return {
            style: STYLE_LABEL,
            value: labelPrefix + "type." + rowData["blackListType"],
          };
        },
      },
      {
        field: "blackListValueType",
        title: labelPrefix + "blackListValueType",
        render: (rowData) => {
          if (rowData["blackListValueType"]) {
            const blackListValueType =
              labelPrefix + "valueType." + rowData["blackListValueType"];
            return <Translate needle={blackListValueType} />;
          }
        },
        getExportTypeFn: (rowData) => {
          return {
            style: STYLE_LABEL,
            value: labelPrefix + "valueType." + rowData["blackListValueType"],
          };
        },
      },
      {
        field: "value",
        title: labelPrefix + "value",
      },
      {
        field: "creationDate",
        title: labelPrefix + "creationDate",
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["creationDate"]) {
            return moment(rowData["creationDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "lastUpdateDate",
        title: labelPrefix + "lastUpdateDate",
        defaultSort: "desc",
        render: (rowData) => {
          if (rowData["lastUpdateDate"]) {
            return <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["lastUpdateDate"]) {
            return moment(rowData["lastUpdateDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
    ],
  },
  blacklistType: [],
  blacklistValueType: [],
};

const BlacklistManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case BLACKLIST_FILTERS_REQUEST: {
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          filtersLoading: true,
        },
      };
    }
    case BLACKLIST_FILTERS_SUCCESS: {
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          filters: action.payload,
          filtersLoading: false,
        },
      };
    }
    case BLACKLIST_FILTERS_FAILURE: {
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          filters: null,
          filtersLoading: false,
        },
      };
    }
    case BLACKLIST_REQUEST: {
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          lastRequest: action,
          data: null,
          tableLoading: true,
        },
      };
    }
    case BLACKLIST_SUCCESS: {
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          data: action.payload,
          tableLoading: false,
        },
      };
    }
    case BLACKLIST_FAILURE: {
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          data: null,
          tableLoading: false,
        },
      };
    }

    case BLACKLIST_TYPE_SUCCESS: {
      return {
        ...state,
        blacklistType: action.payload,
      };
    }
    case BLACKLIST_TYPE_FAILURE: {
      return {
        ...state,
        blacklistType: null,
      };
    }
    case BLACKLIST_VALUE_TYPE_SUCCESS: {
      return {
        ...state,
        blacklistValueType: action.payload,
      };
    }
    case BLACKLIST_VALUE_TYPE_FAILURE: {
      return {
        ...state,
        blacklistValueType: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default BlacklistManagementReducer;
