import { Checkbox } from "@material-ui/core";
import React from "react";
import {
  NOTIFICATION_PREFERENCES_FAILURE,
  NOTIFICATION_PREFERENCES_REQUEST,
  NOTIFICATION_PREFERENCES_SUCCESS,
  UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
  UPDATE_NOTIFICATION_PREFERENCES_REQUEST,
} from "../../../constants";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";

export const prefix = "label.player.notificationPreferences.";

const initialState = {
  loading: true,
  preferences: null,
  columns: [
    {
      field: "id",
      title: prefix + "id",
      default: true,
      defaultSort: "asc",
      editable: false,
      render: (rowData) => {
        if (
          rowData?.eventPreferences &&
          Object.values(rowData.eventPreferences).length > 0
        ) {
          let thresholdValue =
            rowData.eventPreferences[`${rowData.id}_THRESHOLD_LEVEL`].value;
          thresholdValue = thresholdValue === "-1" ? "Any" : thresholdValue;

          return `${rowData.id} - Threshold: ${thresholdValue}`;
        } else {
          return rowData.id;
        }
      },
    },
    {
      field: "notificationType",
      title: prefix + "notificationType",
      editable: false,
      nowrap: false,
    },
    {
      field: "channels.PUSH_CLIENT.enabled",
      title: prefix + "push",
      editComponentDef: (row) => {
        return {
          name: "channels.PUSH_CLIENT.enabled",
          type: "Checkbox",
          labelPrefix: prefix,
          optional: true,
          initialValue: row.channels["PUSH_CLIENT"].enabled,
        };
      },
      render: (rowdata) => (
        <Checkbox checked={rowdata.channels.PUSH_CLIENT.enabled} disabled />
      ),
    },
    {
      field: "channels.PLAYER_PORTAL.enabled",
      title: prefix + "portal",
      editComponentDef: (row) => {
        return {
          name: "channels.PLAYER_PORTAL.enabled",
          type: "Checkbox",
          labelPrefix: prefix,
          optional: true,
          initialValue: row.channels["PLAYER_PORTAL"].enabled,
        };
      },
      render: (rowdata) => (
        <Checkbox checked={rowdata.channels.PLAYER_PORTAL.enabled} disabled />
      ),
    },
    {
      field: "channels.SMS.enabled",
      title: prefix + "sms",
      editComponentDef: (row) => {
        return {
          name: "channels.SMS.enabled",
          type: "Checkbox",
          labelPrefix: prefix,
          optional: true,
          initialValue: row.channels["SMS"].enabled,
        };
      },
      render: (rowdata) => (
        <Checkbox checked={rowdata.channels.SMS.enabled} disabled />
      ),
    },
    {
      field: "channels.EMAIL.enabled",
      title: prefix + "mail",
      editComponentDef: (row) => {
        return {
          name: "channels.EMAIL.enabled",
          type: "Checkbox",
          labelPrefix: prefix,
          optional: true,
          initialValue: row.channels["EMAIL"].enabled,
        };
      },
      render: (rowdata) => (
        <Checkbox checked={rowdata.channels.EMAIL.enabled} disabled />
      ),
    },
    {
      field: "externalEvent",
      title: prefix + "externalEvent",
      render: (rowData) => (
        <YesNoBadge value={rowData["externalEvent"]} justify={"center"} />
      ),
    },
  ],
};

const NotificationPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_PREFERENCES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case NOTIFICATION_PREFERENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        preferences: action.payload,
      };
    }
    case UPDATE_NOTIFICATION_PREFERENCES_FAILURE:
    case NOTIFICATION_PREFERENCES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_NOTIFICATION_PREFERENCES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default NotificationPreferencesReducer;
