import React from "react";
import {
  COMMUNICATIONS_DELIVERY_CHANNELS_SUCCESS,
  COMMUNICATIONS_DETAIL_FAILURE,
  COMMUNICATIONS_DETAIL_REQUEST,
  COMMUNICATIONS_DETAIL_SUCCESS,
  COMMUNICATIONS_EVENT_TYPES_SUCCESS,
  COMMUNICATIONS_EXTRA_PARAMETERS_SUCCESS,
  COMMUNICATIONS_LIST_FAILURE,
  COMMUNICATIONS_LIST_FILTERS_SUCCESS,
  COMMUNICATIONS_LIST_REQUEST,
  COMMUNICATIONS_LIST_SUCCESS,
  COMMUNICATIONS_MANAGEMENT_TYPES_SUCCESS,
  COMMUNICATIONS_OFFSET_TYPES_SUCCESS,
  COMMUNICATIONS_SEND_TYPES_SUCCESS,
  COMMUNICATIONS_TEMPLATES_SUCCESS,
  COMMUNICATIONS_TYPES_SUCCESS,
  STYLE_LABEL,
} from "../../../constants";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import translateService from "../../../../utilities/translate";
import moment from "moment";
import Translate from "../../../../Layouts/Translator/Translate";

export const labelPrefix = "label.rewards.campaigns.communications.";

const getStatusMapping = (status) => {
  switch (status) {
    case "ACTIVE": {
      return { id: "2", label: "campaigns.communications." + status };
    }
    case "DISABLED": {
      return { id: "5", label: "campaigns.communications." + status };
    }
    case "EXPIRED": {
      return { id: "6", label: "campaigns.communications." + status };
    }
    case "PLANNED": {
      return { id: "1", label: "campaigns.communications." + status };
    }
    case "CAMPAIGN": {
      return { id: 1, label: "campaigns.communications." + status };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const initialState = {
  communicationList: {
    idKey: "communicationId",
    columns: [
      {
        field: "communicationId",
        title: labelPrefix + "communicationId",
      },
      {
        field: "communicationName",
        title: labelPrefix + "communicationName",
        default: true,
      },
      {
        field: "communicationType",
        title: labelPrefix + "communicationType",
        render: (rowData) =>
          translateService.translateIt(
            labelPrefix + "communicationTypes." + rowData["communicationType"]
          ),
        getExportTypeFn: (rowData) => ({
          style: STYLE_LABEL,
          value:
            labelPrefix + "communicationTypes." + rowData["communicationType"],
        }),
      },
      {
        field: "managementType",
        title: labelPrefix + "managementType",
        render: (rowData) =>
          translateService.translateIt(
            labelPrefix + "managementTypes." + rowData["managementType"]
          ),
        getExportTypeFn: (rowData) => ({
          style: STYLE_LABEL,
          value: labelPrefix + "managementTypes." + rowData["managementType"],
        }),
      },
      {
        field: "deliveryChannel",
        title: labelPrefix + "deliveryChannel",
      },
      {
        field: "templateName",
        title: labelPrefix + "templateName",
      },
      {
        field: "status",
        title: labelPrefix + "status",
        nowrap: false,
        render: (rowData) => (
          <BadgeStatus
            justify={"center"}
            {...getStatusMapping(rowData["status"])}
          />
        ),
        getExportTypeFn: (rowData) => ({
          style: STYLE_LABEL,
          value: "label.status.campaigns.communications." + rowData["status"],
        }),
      },
      {
        field: "startDate",
        title: labelPrefix + "startDate",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["startDate"]} />
        ),
        getExportValueFn: (rowData) =>
          rowData["startDate"]
            ? moment(rowData["startDate"]).format("L LT")
            : "",
      },
      {
        field: "expirationDate",
        title: labelPrefix + "expirationDate",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["expirationDate"]} />
        ),
        getExportValueFn: (rowData) =>
          rowData["expirationDate"]
            ? moment(rowData["expirationDate"]).format("L LT")
            : "",
      },
    ],
    data: null,
    fullData: [],
    loading: true,
    filters: [],
    filtersLoading: true,
  },
  communicationDetail: {
    data: null,
    loading: true,
    panels: [
      {
        title: "cardTitle.communication",
        fields: [
          {
            field: "communicationId",
          },
          {
            field: "communicationName",
          },
          {
            field: "description",
          },
          {
            field: "userCreator",
          },
          {
            field: "managementType",
            render: (rowData) => (
              <Translate
                needle={
                  labelPrefix + "managementTypes." + rowData["managementType"]
                }
              />
            ),
          },
          {
            field: "status",
            render: (rowData) => (
              <BadgeStatus
                justify={"center"}
                {...getStatusMapping(rowData["status"])}
              />
            ),
          },
          {
            field: "startDate",
            render: (rowData) => (
              <DateFormatter format={"L LT"} value={rowData["startDate"]} />
            ),
            hidden: (rowData) => rowData.managementType !== "STANDALONE",
          },
          {
            field: "expirationDate",
            render: (rowData) => (
              <DateFormatter
                format={"L LT"}
                value={rowData["expirationDate"]}
              />
            ),
            hidden: (rowData) => rowData.managementType !== "STANDALONE",
          },
        ],
      },
      {
        title: "cardTitle.template",
        fields: [
          {
            field: "sendType",
            render: (rowData) => (
              <Translate
                needle={labelPrefix + "sendTypes." + rowData["sendType"]}
              />
            ),
          },
          {
            field: "deliveryEventType",
            hidden: (rowData) => rowData.sendType !== "BY_EVENT_TYPE",
          },
          {
            field: "templateName",
            hidden: (rowData) => rowData.sendType !== "BY_TEMPLATE",
          },
          {
            field: "deliveryChannel",
            render: (rowData) =>
              rowData["deliveryChannel"] ||
              translateService.translateIt(labelPrefix + "preferredChannel"),
            hidden: (rowData) => rowData.sendType !== "BY_TEMPLATE",
          },
          {
            field: "playerGroups",
          },
        ],
      },
      {
        title: "cardTitle.scheduling",
        fields: [
          {
            field: "communicationType",
            render: (rowData) => (
              <Translate
                needle={
                  labelPrefix +
                  "communicationTypes." +
                  rowData["communicationType"]
                }
              />
            ),
          },
          {
            field: "offsetType",
            render: (rowData) => (
              <Translate
                needle={labelPrefix + "offsetTypes." + rowData["offsetType"]}
              />
            ),
            hidden: (rowData) => rowData.communicationType === "CAMPAIGN_BONUS",
          },
          {
            field: "daysOffset",
            hidden: (rowData) => rowData.communicationType === "CAMPAIGN_BONUS",
          },
          {
            field: "extraParameterPlugin",
          },
        ],
      },
    ],
  },
  communicationForm: {
    offsetTypes: [],
    communicationTypes: [],
    extraParameterPlugins: [],
    playerGroups: [],
    templateNames: {},
    deliveryChannels: [],
    deliveryEventTypes: [],
    sendTypes: [],
    managementTypes: [],
  },
};

const CommunicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMUNICATIONS_LIST_FILTERS_SUCCESS: {
      return {
        ...state,
        communicationList: {
          ...state.communicationList,
          filters: action.payload,
          filtersLoading: false,
        },
      };
    }

    case COMMUNICATIONS_LIST_REQUEST: {
      return {
        ...state,
        communicationList: {
          ...state.communicationList,
          lastRequest: action,
          loading: true,
          data: null,
        },
      };
    }

    case COMMUNICATIONS_LIST_SUCCESS: {
      return {
        ...state,
        communicationList: {
          ...state.communicationList,
          data: action.payload.filteredData,
          fullData: action.payload.data,
          loading: false,
        },
      };
    }

    case COMMUNICATIONS_LIST_FAILURE: {
      return {
        ...state,
        communicationList: {
          ...state.communicationList,
          data: null,
          loading: false,
        },
      };
    }

    case COMMUNICATIONS_DETAIL_REQUEST: {
      return {
        ...state,
        communicationDetail: {
          ...state.communicationDetail,
          loading: true,
          data: null,
        },
      };
    }

    case COMMUNICATIONS_DETAIL_SUCCESS: {
      return {
        ...state,
        communicationDetail: {
          ...state.communicationDetail,
          data: action.payload,
          loading: false,
        },
      };
    }

    case COMMUNICATIONS_DETAIL_FAILURE: {
      return {
        ...state,
        communicationDetail: {
          ...state.communicationDetail,
          data: null,
          loading: false,
        },
      };
    }

    case COMMUNICATIONS_MANAGEMENT_TYPES_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          managementTypes: action.payload,
        },
      };
    }

    case COMMUNICATIONS_DELIVERY_CHANNELS_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          deliveryChannels: action.payload,
        },
      };
    }

    case COMMUNICATIONS_TYPES_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          communicationTypes: action.payload,
        },
      };
    }

    case COMMUNICATIONS_SEND_TYPES_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          sendTypes: action.payload,
        },
      };
    }

    case COMMUNICATIONS_OFFSET_TYPES_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          offsetTypes: action.payload,
        },
      };
    }

    case COMMUNICATIONS_EXTRA_PARAMETERS_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          extraParameterPlugins: action.payload,
        },
      };
    }

    case COMMUNICATIONS_TEMPLATES_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          templateNames: {
            ...state.communicationForm.templateNames,
            [action.channel]: action.payload,
          },
        },
      };
    }

    case COMMUNICATIONS_EVENT_TYPES_SUCCESS: {
      return {
        ...state,
        communicationForm: {
          ...state.communicationForm,
          deliveryEventTypes: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default CommunicationsReducer;
