import {
  DEFAULT_LOCALE,
  LOCALE_ACTION,
  LOGOUT_ACTION,
  SET_LOCALE,
  TOGGLE_HC,
  TOGGLE_THEME,
} from "./constants";

const setToggledTheme = (newTheme) => {
  newTheme === "dark"
    ? sessionStorage.setItem("useTheme", "dark")
    : sessionStorage.removeItem("useTheme", "light");
};

const setHighContrast = (isHighContrast) => {
  sessionStorage.setItem("highContrast", isHighContrast);
};

const i18n = sessionStorage.getItem("i18n");
const MainReducer = (
  state = {
    debug: process.env.REACT_APP_DEBUG === "false" ? false : true,
    theme: sessionStorage.getItem("useTheme") || "light",
    highContrast: sessionStorage.getItem("highContrast") === "true",
    locale: sessionStorage.getItem("locale") || DEFAULT_LOCALE,
    browserLocale: navigator.language || DEFAULT_LOCALE,
    i18n: i18n ? JSON.parse(i18n) : {},
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case LOGOUT_ACTION: {
      setToggledTheme("light");
      setHighContrast(false);
      return {
        ...state,
        theme: "light",
        highContrast: false,
      };
    }
    case LOCALE_ACTION:
      const requestedLocale = action.payload.locale || DEFAULT_LOCALE;
      sessionStorage.setItem("locale", requestedLocale);
      const i18nVars = action.payload.i18n;
      sessionStorage.setItem("i18n", JSON.stringify(i18nVars));
      return {
        ...state,
        locale: requestedLocale,
        i18n: i18nVars,
      };
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    case TOGGLE_THEME:
      const newTheme = state.theme === "light" ? "dark" : "light";
      setToggledTheme(newTheme);
      return {
        ...state,
        theme: newTheme,
      };
    case TOGGLE_HC:
      const highContrast = !state.highContrast;
      setHighContrast(highContrast);
      return {
        ...state,
        highContrast,
      };
    default:
      return state;
  }
};

export default MainReducer;
