import { Redirect, Route } from "react-router-dom";
import React from "react";
import { usePageActions } from "./utilities/hooks/usePageActions";
import { useMenuItems } from "./utilities/hooks/useMenuItems";

const AuthorizedRoute = ({
  component: Component,
  checkOnlyLastPart = false,
  action = false,
  valorizedUrl = false,
  externalUrl = false,
    uid,
  ...rest
}) => {
  let pathToCheck = rest.path;
  if (valorizedUrl) {
    pathToCheck = rest.computedMatch.url;
    pathToCheck = decodeURIComponent(pathToCheck);
  } else if (externalUrl) {
    pathToCheck = "#externalUrl/" + rest.computedMatch.params.openUrl;
    pathToCheck = decodeURIComponent(pathToCheck);
  }
  if (checkOnlyLastPart) {
    pathToCheck = pathToCheck.substring(pathToCheck.lastIndexOf("/"));
    const genericUrl = pathToCheck.lastIndexOf(":");
    if (genericUrl !== -1) {
      pathToCheck = "";
    }
  }

  const { flatMenuItems } = useMenuItems();
  const { buttons, loading } = usePageActions();

  if(loading) return null;
  const menuItems = action ? buttons : flatMenuItems;

  let isPermissionFound = true;
  if (menuItems != null) {
    const menuItem = menuItems.find((item) => {
      if(uid != null) return item.uid === uid;
      return item.url === pathToCheck
    });
    isPermissionFound = menuItem != null;
  }

  return menuItems != null ? (
    isPermissionFound ? (
      Component ? (
        <Route {...rest} render={(props) => <Component {...props} />} />
      ) : (
        <Route {...rest} />
      )
    ) : (
      <Redirect to="/not-authorized" />
    )
  ) : null;
};

export default AuthorizedRoute;
