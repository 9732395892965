import { call, put } from "redux-saga/effects";
import {
  LATEST_REGULATOR_STATUS_REQUEST,
  LATEST_REGULATOR_STATUS_SUCCESS,
  LATEST_REGULATOR_STATUS_FAILED,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import LatestRegulatorStatusService from "./latest-regulator-status-service";

const loadLatestRegulatorStatus = function* (action) {
  try {
    let data = yield call(
      LatestRegulatorStatusService.loadLatestRegulatorStatus,
      action.contractId
    );
    yield put({
      type: LATEST_REGULATOR_STATUS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadLatestRegulatorStatus", e);
    yield put({
      type: LATEST_REGULATOR_STATUS_FAILED,
    });
  }
};

const LatestRegulatorStatusSaga = function* latestRegulatorStatusSaga() {
  yield takeLatest(LATEST_REGULATOR_STATUS_REQUEST, loadLatestRegulatorStatus);
};

export default LatestRegulatorStatusSaga;
