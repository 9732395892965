import { call, put, takeEvery } from "redux-saga/effects";
import secondStepService from "./currencies-service";
import {
  CURRENCIES_REQUEST,
  CURRENCIES_FAILURE,
  CURRENCIES_SUCCESS,
  CURRENCIES_FULL_DATA_REQUEST,
  CURRENCIES_FULL_DATA_SUCCESS,
  CURRENCIES_FULL_DATA_FAILURE,
  PAM_CURRENCIES_SUCCESS,
  PAM_CURRENCIES_FAILURE,
  PAM_CURRENCIES_REQUEST,
  LOY_CURRENCIES_REQUEST,
  LOY_CURRENCIES_SUCCESS,
  LOY_CURRENCIES_FAILURE,
} from "../constants";

const getCurrencies = function* (action) {
  try {
    const data = yield call(
      secondStepService.getCurrencies,
      action.brand,
      action.partner
    );
    yield put({
      type: CURRENCIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("currencyList", e);
    yield put({
      type: CURRENCIES_FAILURE,
    });
  }
};

const getCurrenciesFullData = function* (action) {
  try {
    const data = yield call(secondStepService.getCurrenciesFullData);
    yield put({
      type: CURRENCIES_FULL_DATA_SUCCESS,
      payload: data.currencies,
    });
  } catch (e) {
    console.error("currencyFullDataList", e);
    yield put({
      type: CURRENCIES_FULL_DATA_FAILURE,
    });
  }
};

const getPamCurrencies = function* (action) {
  try {
    const data = yield call(secondStepService.getPamCurrencies);
    yield put({
      type: PAM_CURRENCIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("currencyList", e);
    yield put({
      type: PAM_CURRENCIES_FAILURE,
    });
  }
};

const getLoyCurrencies = function* (action) {
  try {
    const data = yield call(
      secondStepService.getLoyCurrencies,
      action.brand,
      action.partner,
      action.rewardType
    );
    yield put({
      type: LOY_CURRENCIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("currencyList", e);
    yield put({
      type: LOY_CURRENCIES_FAILURE,
    });
  }
};

const CurrenciesSaga = function* currenciesSaga() {
  yield takeEvery(CURRENCIES_REQUEST, getCurrencies);
  yield takeEvery(CURRENCIES_FULL_DATA_REQUEST, getCurrenciesFullData);
  yield takeEvery(PAM_CURRENCIES_REQUEST, getPamCurrencies);
  yield takeEvery(LOY_CURRENCIES_REQUEST, getLoyCurrencies);
};

export default CurrenciesSaga;
