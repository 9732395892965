import React from "react";
import { FormattedNumber } from "react-intl";
import { useSession } from "../../utilities/hooks/useSession";
import Box from "@material-ui/core/Box";

const Currency = ({
  amount,
  currency,
  platform,
  applyDivisorAmount = true,
  justify = "flex-start",
  currencyPrecision = false,
}) => {
  var maxFractionsAllowed;
  const { currencySymbol, divisorAmount, systemCurrency, lotterydivisorAmount, brandSelected, partnerSelected, maxFractionDigits} = useSession();
  maxFractionDigits.forEach(function (item, index) {
    var brandPartnerIdFractionConfig = item.split(",");
	brandPartnerIdFractionConfig.forEach(function (value, index) {
		if(value.split(";")[0].includes(brandSelected.id)){			
			maxFractionsAllowed	= value.split(";")[1];
		}
	});
  });
  
  const currencyDisplay = currencySymbol ? "symbol" : "code";
  const value =
    applyDivisorAmount && (currency !== "POINTS" || currencyPrecision)
      ? (platform !==null && platform==="LOTTERY" ? amount/lotterydivisorAmount : amount / divisorAmount )
      : amount;
  let props = {};
  if (currency == null || currency !== "" || currency !== "POINTS") {
    props = {
      style: "currency",
      currency: currency == null ? systemCurrency : currency,
      currencyDisplay: currencyDisplay,
	  maximumFractionDigits: maxFractionsAllowed
    };
  }
  return (
    <Box display={"flex"} justifyContent={justify}>
      <FormattedNumber value={+value} {...props} />
    </Box>
  );
};

export default Currency;
