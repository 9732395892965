import axios from "axios";

class FinancialLimitsService {
  async getAssociatedProfiles(contractId, brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v2/ewl/profiles/${contractId}/${brand}/${partner}`
    );
    if (response) {
      return response.data;
    }
    throw Error(
      "Invalid response for financial limits get associated profiles"
    );
  }

  async editAssociatedProfile(contractId, brand, partner, profile) {
    const editData = {
      limits: [
        {
          type: profile.type.name,
          updateDate: Date.now(),
          value: parseInt(profile.value, 10),
          status: profile.status,
          alertThreshold: profile.alertThreshold == ""? "-1": profile.alertThreshold
        },
      ],
    };
    const response = await axios.put(
      `/gov/api/rest/v2/ewl/profiles/${contractId}/${brand}/${partner}`,
      editData
    );
    if (response) {
      return response.status;
    }
    throw Error(
      "Invalid response for financial limits get associated profiles"
    );
  }

  async getNotAssociatedProfiles(contractId, brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v2/ewl/profile_types/${contractId}/${brand}/${partner}`
    );
    if (response) {
      return response.data;
    }
    throw Error(
      "Invalid response for financial limits get not associated profiles"
    );
  }

  async setApproveRejectProfile(contractId, brand, partner, action, profileId) {
    const response = await axios.post(
      `/gov/api/rest/v2/ewl/profiles/${contractId}/${brand}/${partner}/${action}/${profileId}`
    );
    if (response == null || response.status !== 204) {
      throw Error("Invalid response for financial limit " + action);
    }
  }

  async getFinancialLimitHistory(brandId, partnerId, contractId, profileType) {
    const response = await axios.get(
      `/gov/api/rest/v1/limits/${contractId}/rg-limit/change-history?brandId=${brandId}&partnerId=${partnerId}&limitType=${profileType}`
    );
    if (response && response.data) {
      return response.data.collection;
    }

    throw Error("Invalid response for financial limits get history");
  }
}

const financialLimitsService = new FinancialLimitsService();
export default financialLimitsService;
