import { call, put } from "redux-saga/effects";
import {
  EXTERNAL_LINKED_ACCOUNTS_FAILURE,
  EXTERNAL_LINKED_ACCOUNTS_REQUEST,
  EXTERNAL_LINKED_ACCOUNTS_SUCCESS,
  UNLINK_EXTERNAL_LINKED_ACCOUNT_FAILURE,
  UNLINK_EXTERNAL_LINKED_ACCOUNT_REQUEST,
  UNLINK_EXTERNAL_LINKED_ACCOUNT_SUCCESS,
  UPDATE_EXTERNAL_LINKED_ACCOUNT_FAILURE,
  UPDATE_EXTERNAL_LINKED_ACCOUNT_REQUEST,
  UPDATE_EXTERNAL_LINKED_ACCOUNT_SUCCESS,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import externalLinkedAccountsService from "./external-linked-accounts-service";

const loadLinkedAccounts = function* (action) {
  try {
    const data = yield call(
      externalLinkedAccountsService.loadLinkedAccounts,
      action.brand,
      action.partner,
      action.contractId,
      action.contractType
    );
    yield put({
      type: EXTERNAL_LINKED_ACCOUNTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadExternalLinkedAccounts", e);
    yield put({
      type: EXTERNAL_LINKED_ACCOUNTS_FAILURE,
    });
  }
};

const unlinkLinkedAccount = function* (action) {
  try {
    const data = yield call(
      externalLinkedAccountsService.unlinkLinkedAccounts,
      action.brand,
      action.partner,
      action.contractId,
      action.externalAccountId,
      action.externalAccountTypeId
    );
    yield put({
      type: UNLINK_EXTERNAL_LINKED_ACCOUNT_SUCCESS,
      payload: data,
    });
    yield put({
      type: EXTERNAL_LINKED_ACCOUNTS_REQUEST,
      contractId: action.contractId,
      brand: action.brand,
      partner: action.partner,
      contractType: action.contractType,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.externalLinkAccount.unlinkAccount",
        },
        options: {
          key: "UnlinkLinkedAccount",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("UnlinkExternalLinkedAccount", e);
    yield put({
      type: UNLINK_EXTERNAL_LINKED_ACCOUNT_FAILURE,
    });
  }
};

const editLinkedAccount = function* (action) {
  try {
    const data = yield call(
      externalLinkedAccountsService.editLinkedAccounts,
      action.brand,
      action.partner,
      action.contractId,
      action.contractType,
      action.externalAccount
    );
    yield put({
      type: UPDATE_EXTERNAL_LINKED_ACCOUNT_SUCCESS,
      payload: data,
    });
    yield put({
      type: EXTERNAL_LINKED_ACCOUNTS_REQUEST,
      contractId: action.contractId,
      brand: action.brand,
      partner: action.partner,
      contractType: action.contractType,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.externalLinkAccount.editExternalAccount",
        },
        options: {
          key: "EditExternalLinkedAccount",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("EditExternalLinkedAccount", e);
    yield put({
      type: UPDATE_EXTERNAL_LINKED_ACCOUNT_FAILURE,
    });
  }
};

const ExternalLinkedAccountsSaga = function* directNotificationsSaga() {
  yield takeLatest(EXTERNAL_LINKED_ACCOUNTS_REQUEST, loadLinkedAccounts);
  yield takeLatest(UNLINK_EXTERNAL_LINKED_ACCOUNT_REQUEST, unlinkLinkedAccount);
  yield takeLatest(UPDATE_EXTERNAL_LINKED_ACCOUNT_REQUEST, editLinkedAccount);
};

export default ExternalLinkedAccountsSaga;
