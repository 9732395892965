import { INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST } from "../../../constants";

const getIndividualPlayerLoginHistoryHandler = (contractId, brand, partner, page, pageSize) => {
  return {
    type: INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST,
    contractId,
    brand,
    partner,
    page,
    pageSize
  };
};

export { getIndividualPlayerLoginHistoryHandler };
