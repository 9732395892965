import axios from "axios";

class NotificationPreferencesService {
  async loadNotificationPreferences(contractId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/notifications/direct/${contractId}/notification-preferences?brandId=${brandId}&partnerId=${partnerId}`;

    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async updateNotificationPreferences(contractId, brandId, partnerId, body) {
    let url = `/gov/api/rest/v1/notifications/direct/${contractId}/notification-preferences?brandId=${brandId}&partnerId=${partnerId}`;

    const response = await axios.put(url, body);
    if (response && response.status !== 204) {
      throw Error("Invalid response player notification preferences");
    }
  }
}

const notificationPreferencesService = new NotificationPreferencesService();
export default notificationPreferencesService;
