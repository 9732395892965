import React from "react";
import { useField, useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import FormLabel from "./form-label";

const TagField = ({
  isConditional = false,
  optional,
  label,
  tooltip,
  options,
  valueKey,
  labelKey,
  ...props
}) => {
  const t = useTranslator();
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { value: selectedValue = [] } = field;
  const { touched, error } = meta;
  const isError = touched && error && true;

  return (
    <Autocomplete
      multiple
      autoComplete
      freeSolo
      onChange={(event, value) => {
        setFieldValue(field.name, value);
      }}
      autoSelect
      value={selectedValue}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          name={field.name}
          error={isError}
          helperText={!isConditional && isError && error}
          label={
            label ? (
              <FormLabel optional={optional} label={label} tooltip={tooltip} />
            ) : null
          }
          placeholder={t({ needle: "label.placeholders.tags" })}
        />
      )}
      options={options}
      disableClearable={false}
    />
  );
};

export default TagField;
