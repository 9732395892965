import { KYC_STATUS_REQUEST } from "../../../constants";
import { KYC_STATUS_HISTORY_REQUEST } from "../../../constants";
import { KYC_STATUS_REQUEST_REQUEST } from "../../../constants";
import { KYC_EDIT_NOTE_REQUEST } from "../../../constants";
import { KYC_CHANGE_STATE_REQUEST } from "../../../constants";

export const getKycStatus = (
  brand,
  partner,
  contractId,
  registrationLevelId
) => {
  return {
    type: KYC_STATUS_REQUEST,
    contractId,
    registrationLevelId,
    brand,
    partner,
  };
};

export const getKycStatusHistory = (brand, partner, contractId) => {
  return {
    type: KYC_STATUS_HISTORY_REQUEST,
    contractId,
    brand,
    partner,
  };
};

export const getKycRequestDetail = (brand, partner, identityCheckRequestId) => {
  return {
    type: KYC_STATUS_REQUEST_REQUEST,
    identityCheckRequestId,
    brand,
    partner,
  };
};

export const editKYCNote = (
  brand,
  partner,
  statusObject,
  contractId,
  registrationLevelId
) => {
  return {
    type: KYC_EDIT_NOTE_REQUEST,
    statusObject,
    brand,
    partner,
    contractId,
    registrationLevelId,
  };
};

export const changeKYCStatus = (
  brand,
  partner,
  statusObject,
  contractId,
  registrationLevelId
) => {
  return {
    type: KYC_CHANGE_STATE_REQUEST,
    statusObject,
    brand,
    partner,
    contractId,
    registrationLevelId,
  };
};
