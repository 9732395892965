import {
  PLAYER_OVERVIEW_SETTINGS_REQUEST,
  USER_CUSTOM_SETTING_UPDATE_REQUEST,
  USER_PREFERENCES_RETRIEVE_REQUEST,
} from "../constants";

const getUserPreferencesHandler = (
  brand,
  partner,
  url,
  componentIdentifier
) => {
  return {
    type: USER_PREFERENCES_RETRIEVE_REQUEST,
    brand,
    partner,
    url,
    componentIdentifier,
  };
};

export const updateUserCustomSetting = (id, setting) => {
  return {
    type: USER_CUSTOM_SETTING_UPDATE_REQUEST,
    id,
    setting,
  };
};

export const getPlayerOverviewSettings = () => {
  return {
    type: PLAYER_OVERVIEW_SETTINGS_REQUEST,
  };
};

export { getUserPreferencesHandler };
