import React from "react";
import {
  LOTTERY_BLOCKLIST_FAILURE,
  LOTTERY_BLOCKLIST_FILTER_REQUEST,
  LOTTERY_BLOCKLIST_FILTER_SUCCESS,
  LOTTERY_BLOCKLIST_REQUEST,
  LOTTERY_BLOCKLIST_SUCCESS,
} from "../../../constants";
import Translate from "../../../../Layouts/Translator/Translate";
import Moment from "react-moment";

export const labelPrefix = "label.admin.lotteryBlocklist.";

const initialState = {
  lotteryBlocklist: {
    data: null,
    isLoading: true,
    columns: [
      {
        field: "creationDate",
        title: labelPrefix + "creationDate",
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "attributeType",
        title: labelPrefix + "attributeType",
        render: (rowData) => {
          var addType = rowData["attributeType"];
          return (
            <Translate needle={labelPrefix + "attributeTypes." + addType} />
          );
        },
      },
      {
        field: "attributeValue",
        title: labelPrefix + "attributeValue",
      },
      {
        field: "attributeType",
        title: labelPrefix + "addType",
        render: (rowData) => {
          var addType = rowData["fileName"] == null ? "manually" : "byFile";
          return <Translate needle={labelPrefix + addType} />;
        },
      },
      {
        field: "fileName",
        title: labelPrefix + "fileName",
      },
      {
        field: "boUsername",
        title: labelPrefix + "boUsername",
      },
      {
        field: "reason",
        title: labelPrefix + "reason",
      },
    ],
  },
};

const LotteryBlocklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOTTERY_BLOCKLIST_SUCCESS:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          data: action.payload,
          isLoading: false,
        },
      };
    case LOTTERY_BLOCKLIST_FAILURE:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          data: null,
          isLoading: false,
        },
      };
    case LOTTERY_BLOCKLIST_FILTER_REQUEST:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          filters: [],
          filtersLoading: true,
        },
      };
    case LOTTERY_BLOCKLIST_FILTER_SUCCESS:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          filters: action.payload,
          filtersLoading: false,
        },
      };

    case LOTTERY_BLOCKLIST_REQUEST:
      return {
        ...state,
        lotteryBlocklist: {
          ...state.lotteryBlocklist,
          isLoading: true,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default LotteryBlocklistReducer;
