import axios from "axios";
import { labelPrefix } from "./communications-reducer";
import moment from "moment";
import searchUtils from "../../../../utilities/searchUtils";

class CommunicationsService {
  statuses = ["ACTIVE", "DISABLED", "EXPIRED", "PLANNED", "CAMPAIGN"];

  getStatus = ({ managementType, startDate, expirationDate }) => {
    if (managementType === "LINKED_TO_CAMPAIGN") {
      return this.statuses[4];
    }
    const today = moment();
    const start = moment(startDate);
    const expire = moment(expirationDate);
    if (today.isBetween(start, expire)) {
      return this.statuses[0];
    }
    if (today.isAfter(expire)) {
      return this.statuses[2];
    }
    if (today.isBefore(start)) {
      return this.statuses[3];
    }
    //TODO check when it is disabled to return this.statuses[1]
  };

  getCommunications = async (brand, partner, filters) => {
    const url = "/gov/api/rest/v2/communications";
    const response = await axios.get(url, { params: { brand, partner } });
    if (response && response.data) {
      const data = response.data
        .sort((a, b) => a.lastUpdate - b.lastUpdate)
        .map((it) => {
          return {
            ...it,
            status: this.getStatus(it),
          };
        });

      return { data, filteredData: searchUtils.search(data, filters) };
    }
    throw Error("Error getting communications");
  };

  getListFilter = async () => {
    const filters = [
      {
        name: "status",
        label: labelPrefix + "status",
        type: "select",
        placeholder: "label.placeholders.select",
        options: this.statuses.map((it) => ({
          label: "label.status.campaigns.communications." + it,
          value: it,
        })),
      },
    ];

    const communicationTypes = await this.getCommunicationTypes();
    if (communicationTypes) {
      filters.push({
        name: "communicationType",
        label: labelPrefix + "communicationType",
        type: "select",
        placeholder: "label.placeholders.select",
        options: communicationTypes.map((it) => ({
          label: labelPrefix + "communicationTypes." + it.id,
          value: it.id,
        })),
      });
    }
    const managementTypes = await this.getManagementTypes();
    if (managementTypes) {
      filters.push({
        name: "managementType",
        label: labelPrefix + "managementType",
        type: "select",
        placeholder: "label.placeholders.select",
        options: managementTypes.map((it) => ({
          label: labelPrefix + "managementTypes." + it.id,
          value: it.id,
        })),
      });
    }
    const deliveryChannels = await this.getDeliveryChannels();
    if (deliveryChannels) {
      filters.push({
        name: "deliveryChannel",
        label: labelPrefix + "deliveryChannel",
        type: "select",
        placeholder: "label.placeholders.select",
        options: deliveryChannels.map((it) => ({
          label: it.channelName,
          value: it.channelName,
        })),
      });
    }

    filters.push(
      {
        name: "startDate",
        label: labelPrefix + "startDate",
        placeholder: "label.placeholders.date",
        type: "daterange",
      },
      {
        name: "expirationDate",
        placeholder: "label.placeholders.date",
        label: labelPrefix + "expirationDate",
        type: "daterange",
      }
    );

    return filters;
  };

  getManagementTypes = async () => {
    const url = "/gov/api/rest/v2/communications/managementType";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting management types");
  };

  getDeliveryChannels = async () => {
    const url = "/gov/api/rest/v1/channels";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting delivery channels");
  };

  getCommunicationTypes = async () => {
    const url = "/gov/api/rest/v2/communications/type";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting communication types");
  };

  getSendTypes = async () => {
    const url = "/gov/api/rest/v2/communications/sendType";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting communication send types");
  };

  getEventTypes = async () => {
    const url = "/gov/api/rest/v1/event_types";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting communication event types");
  };

  getTemplates = async (brand, partner, channel, content) => {
    const url = "/gov/api/rest/v2/dlv/templates";
    const response = await axios.get(url, {
      params: {
        brand,
        partner,
        channel,
        content
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting communication templates");
  };

  getOffsetTypes = async () => {
    const url = "/gov/api/rest/v2/communications/offsetType";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting communication offset types");
  };

  getExtraParameters = async (communicationType) => {
    const url = `/gov/api/rest/v2/communications/extra_params/${communicationType}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting communication extra parameters");
  };

  disableCommunication = async (communicationId) => {
    const url = `/gov/api/rest/v2/communications/${communicationId}/disable`;
    const response = await axios.put(url);
    if (response == null || response.status !== 204) {
      throw Error("Error disabling communication " + communicationId);
    }
  };

  save = async (brand, partner, body) => {
    let url = "/gov/api/rest/v2/communications";
    if (body.communicationId) {
      url = `/gov/api/rest/v2/communications/${body.communicationId}`;
    }
    if (body["offsetType"] === "") delete body.offsetType;
    if (body["sendType"] === "BY_TEMPLATE") {
      delete body.deliveryEventType;
    }
    if (body["sendType"] === "BY_EVENT_TYPE") {
      delete body.templateName;
    }
    if (body["managementType"] === "LINKED_TO_CAMPAIGN") {
      body["startDate"] = "";
      body["expirationDate"] = "";
    }
    if (body["deliveryChannel"] === "-1") {
      body["deliveryChannel"] = null;
    }

    const response = await axios({
      url,
      method: body.communicationId ? "PUT" : "POST",
      data: {
        operatorBrandId: brand,
        operatorPartnerId: partner,
        ...body,
      },
    });
    if (response == null || response.status !== 200) {
      throw Error("Error saving the communication");
    }
  };
}

const communicationsService = new CommunicationsService();
export default communicationsService;
