import { takeEvery } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  NOTIFICATION_PREFERENCES_FAILURE,
  NOTIFICATION_PREFERENCES_SUCCESS,
  NOTIFICATION_PREFERENCES_REQUEST,
  UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
  UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
  UPDATE_NOTIFICATION_PREFERENCES_REQUEST,
} from "../../../constants";
import notificationPreferencesService from "./notification-preferences-service";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadNotificationPreferences = function* (action) {
  try {
    const data = yield call(
      notificationPreferencesService.loadNotificationPreferences,
      action.contractId,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: NOTIFICATION_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("notificationPreferences", e);
    yield put({
      type: NOTIFICATION_PREFERENCES_FAILURE,
    });
  }
};

const updateNotificationPreferences = function* (action) {
  try {
    yield call(
      notificationPreferencesService.updateNotificationPreferences,
      action.contractId,
      action.brandId,
      action.partnerId,
      action.body
    );
    yield put({
      type: UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.notificationPreferences.update",
        },
        options: {
          key: "notificationPreferences",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    action.cb != null && typeof action.cb === "function" && action.cb();
    console.error("notificationPreferences", e);
    yield put({
      type: UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
    });
  }
};

const NotificationPreferencesSaga = function* notificationPreferencesSaga() {
  yield takeEvery(
    NOTIFICATION_PREFERENCES_REQUEST,
    loadNotificationPreferences
  );
  yield takeEvery(
    UPDATE_NOTIFICATION_PREFERENCES_REQUEST,
    updateNotificationPreferences
  );
};

export default NotificationPreferencesSaga;
