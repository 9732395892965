import {
  EXTERNAL_URL_ACTION,
  EXTERNAL_URL_REFRESH_KEY_ACTION,
} from "../constants";

const ExternalUrlReducer = (
  state = { iFrameUrlDecoded: "", marker: new Date().getTime() },
  action
) => {
  switch (action.type) {
    case EXTERNAL_URL_ACTION:
      return {
        ...state,
        iFrameUrlDecoded: action.payload,
      };
    case EXTERNAL_URL_REFRESH_KEY_ACTION:
      return {
        ...state,
        marker: new Date().getTime(),
      };
    default:
      return {
        ...state,
      };
  }
};

export default ExternalUrlReducer;
