import axios from "axios";

class PlayerDetailService {
  async getPlayerDetail(contractId, brand, partner, isPam) {
    const response = isPam
      ? await axios.get(
          `/gov/api/rest/v1/contracts/${contractId}/players/0?brand=${brand}&partner=${partner}`
        )
      : await axios.get(
          `/gov/api/rest/v1/network_player/${contractId}/${brand}/${partner}`
        );
    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for individual player login history");
  }
}

const playerDetailService = new PlayerDetailService();
export default playerDetailService;
