import { EXTERNAL_URL_PATH } from "./store/constants";
import * as moment from "moment";

const checkEncodeURI = (str) => {
  // eslint-disable-next-line
  return /%/i.test(str);
};

const replace = (initialString, placeholder, value) => {
  var re = new RegExp(placeholder, "g");
  initialString = initialString.replace(re, value);

  return initialString;
};

const getTranslatedLabels = (filtersReceived, options, join, t) => {
  let filterLabels = [];
  if (!Array.isArray(filtersReceived)) {
    filtersReceived = [filtersReceived];
  }
  filtersReceived.forEach(function (option) {
    let obj = options.find((it) =>
      it.value != null ? it.value === option : false
    );
    if (obj) {
      filterLabels.push(t({ needle: obj.label }));
    } else {
      filterLabels.push(option);
    }
  });
  return filterLabels.join(join);
};

const splitChartLabelsForMobile = (labels) => {
  labels.forEach((label, index) => {
    if (label.includes(" ")) {
      const labelSplit = [];
      const firstPart = label.slice(0, label.lastIndexOf(" "));
      labelSplit.push(firstPart);
      const secondPart = label.slice(label.lastIndexOf(" ") + 1);
      labelSplit.push(secondPart);
      labels[index] = labelSplit;
    }
  });
  return labels;
};

const getPlayerValue = (attribute, detail) => {
  if (attribute && attribute.includes(".")) {
    const split = attribute.split(".");
    let index = 0;
    let value = detail;
    while (index < split.length) {
      if (value) {
        value = value[split[index]];
      }
      index++;
    }
    return value;
  }
  return detail[attribute];
};

// const checkAttribute = (visibilityRule, detail) => {
//   let isVisible = true;
//   const attribute = visibilityRule.attribute;
//   const playerValue = getPlayerValue(attribute, detail);
//   const operator = visibilityRule.operator;
//   const value = visibilityRule.value;
//   if(operator === "="){
//     isVisible = playerValue+"" === value+"";
//   }
//   else if(operator === "lt"){
//     isVisible = playerValue < value;
//   }
//   else if(operator === "gt"){
//     isVisible = playerValue > value;
//   }
//   return isVisible;
// };

/* const isItemVisible = (action, detail) => {
  let isVisible = true;
  const visibilityRule = JSON.parse(action.visibilityRuleNew);
  if(visibilityRule){
    const condition = visibilityRule.condition;
    if (condition === undefined){
      return checkAttribute(visibilityRule, detail);
    }
    else{
      if(condition == "||"){
        isVisible = false;
        visibilityRule.list.map(r => {
          isVisible = isVisible || checkAttribute(r, detail);
        });
      }
      else {
        visibilityRule.list.map(r => {
          isVisible = isVisible && checkAttribute(r, detail);
        });
      }
    }
    
  }
  return isVisible;
}; */

const evaluateCondition = (condition) => {
  try {
    // eslint-disable-next-line
    return eval(condition);
  } catch (e) {
    return false;
  }
};

const isItemVisible = (action, detail) => {
  let isVisible = true;
  const visibilityRule = JSON.parse(action.visibilityRuleNew);
  if (visibilityRule && detail) {
    const attributes = visibilityRule.attributes;
    let condition = visibilityRule.condition;
    if (attributes && condition) {
      attributes.forEach((attr) => {
        const playerValue = getPlayerValue(attr, detail);
        condition = condition.replaceAll(attr, playerValue);
      });
      isVisible = evaluateCondition(condition);
    }
  }
  return isVisible;
};

const isActionHidden = (buttons, uid) => {
  if (buttons) {
    let obj = buttons.find((button) =>
      button.uid ? button.uid === uid : false
    );
    return !obj;
  } else return true;
};

function a11yProps(index, context) {
  return { id: `${context}-${index}`, "aria-controls": `${context}-${index}` };
}

const timeConvert = (n) => {
  const hours = n / 60;
  let rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  if (rhours > 0 && rminutes > 0) {
    if (rhours < 10) rhours = "0" + rhours;
    if (rminutes < 10) rminutes = "0" + rminutes;
    return rhours + ":" + rminutes;
  } else if (rhours > 0 && rminutes == 0) {
    return rhours;
  } else {
    if (rminutes < 10) rminutes = "0" + rminutes;
    return "00:" + rminutes;
  }
};

const convert = (n) => {
  n = String(n);
  if (n.length == 1) n = "0" + n;
  return n;
};

const convertFromMillsToHours = (x) => {
  var d = moment.duration(x, "milliseconds");
  var hours = Math.floor(d.asHours());
  var mins = Math.floor(d.asMinutes()) - hours * 60;
  return { hours: `${hours}`, mins: convert(mins) };
};

const convertFromMillsToHoursMinSecs = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

const getExternUrlById = (items, id) => {
  const url = items.find((it) => it.id === id).url;
  const encodedUrl =
    EXTERNAL_URL_PATH +
    encodeURIComponent(url.substring(url.indexOf("//") + 1));
  return encodedUrl;
};

export {
  checkEncodeURI,
  replace,
  getTranslatedLabels,
  splitChartLabelsForMobile,
  isItemVisible,
  isActionHidden,
  a11yProps,
  timeConvert,
  getExternUrlById,
  convertFromMillsToHours,
  convertFromMillsToHoursMinSecs,
};
