import { call, put } from "redux-saga/effects";
import {
  ACCOUNT_DETAILS_SUCCESS,
  ACCOUNT_DETAILS_FAILURE,
  ACCOUNT_DETAILS_REQUEST,
} from "../../../constants";
import accountDetailsService from "../account-details/account-details-service";
import { takeLatest } from "@redux-saga/core/effects";

const getAccountDetails = function* (action) {
  try {
    const data = yield call(
      accountDetailsService.getAccountDetails,
      action.contractId,
      action.brand,
      action.partner,
      action.currency
    );
    yield put({
      type: ACCOUNT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ACCOUNT_DETAILS_FAILURE,
    });
  }
};

const AccountDetailsSaga = function* AccountDetailsSaga() {
  yield takeLatest(ACCOUNT_DETAILS_REQUEST, getAccountDetails);
};

export default AccountDetailsSaga;
