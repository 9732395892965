import React from "react";
import Table from "../../Layouts/Table/Table";
import { getLocationsList } from "../../store/locations/action";
import { useLocations } from "../../utilities/hooks/useLocations";
import Filters from "../../Layouts/Filters/Filters";
import { Box, Button } from "@material-ui/core";
import Translate from "../../Layouts/Translator/Translate";
import { connect } from "react-redux";

const Locations = ({
  add,
  handleCloseModal,
  loadLocations,
  handleOpenEdit,
}) => {
  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  const [query, setQuery] = React.useState({
    orderBy: { field: "category", order: "asc" },
    page: 0,
    pageSize: add ? 5 : 10,
  });
  const [isLoading, setIsLoading] = React.useState(true);

  const [searchTerms, setSearchTerms] = React.useState(null);
  const { data, columns, loading } = useLocations(
    query.page,
    query.pageSize,
    searchTerms,
    add
  );

  const loadData = (q) => {
    setQuery(q);
  };
  let cityField = {
    name: "city",
    label: "label.locations.city",
    type: "Text",
    multiple: false,
  };
  let postalCodeField = {
    name: "postalCode",
    label: "label.locations.postalCode",
    type: "Text",
    multiple: false,
  };
  let filters = [cityField, postalCodeField];
  const handleSearch = (terms) => {
    setQuery({
      orderBy: { field: "category", order: "asc" },
      page: 0,
      pageSize: query.pageSize,
    });
    setSearchTerms(terms);
  };

  const handleReset = () => {
    setSearchTerms(null);
  };

  React.useEffect(() => {
    if (filters == null || (filters.length !== 0 && isLoading)) {
      setIsLoading(false);
    }
  }, [filters, isLoading]);

  const handleCloseLocations = () => {
    handleCloseModal();
  };
  return (
    <>
      <Filters
        filtersKey={"locations"}
        isLoading={isLoading}
        fields={filters || []}
        onSearch={handleSearch}
        onReset={handleReset}
        collapsible={true}
      />
      <Table
        type={"advanced"}
        filters={[filters, searchTerms]}
        resize={false}
        pagination={true}
        maxPageSize={10}
        sorting={false}
        exportButton={false}
        title={"label.locations.title"}
        columns={columns}
        data={data}
        isLoading={loading}
        {...query}
        onFetch={loadData}
        orderBy={{ field: "category", order: "asc" }}
        actions={[
          add
            ? (rowData) => ({
                color: "success",
                variant: "contained",
                tooltip: "label.player.playerFavorites.add",
                hideMobile: true,
                onClick: (event, rowData) => {
                  handleCloseLocations();
                  handleOpenEdit(rowData);
                },
              })
            : null,
        ]}
      />
      {add ? (
        <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
          <Box mr={1}>
            <Button variant={"outlined"} onClick={() => handleCloseModal()}>
              <Translate needle={"button.close"} />
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
const mapStateToProps = () => {};

const mapDispatchToProps = {
  loadLocations: getLocationsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
