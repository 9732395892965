import axios from "axios";

class AssignRewardService{
  
  
  async getActiveProgramLoyalty() {
    let url = `/gov/api/rest/v1/programs?status=ACTIVE&currency-type=LOYALTY`;

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response");
  }
}

const assignRewardService = new AssignRewardService();
export default assignRewardService;
