import { CONTRACT_TYPE_SUCCESS } from "../constants";

const INITIAL_STATE = {
  contractTypes: [],
};

const ContractTypeListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTRACT_TYPE_SUCCESS:
      return {
        ...state,
        contractTypes: action.payload,
      };

    default:
      return state;
  }
};

export default ContractTypeListReducer;
