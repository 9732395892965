import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import { CRITERIA_TYPE_DATE, CRITERIA_TYPE_SELECT } from "../../../constants";
import { labelPrefix } from "./blacklist-management-reducer";

class BlacklistManagementService {
  getBlacklistFilters = async () => {
    const filter = [];

    const blackListTypes = await this.getBlacklistType();
    if (blackListTypes) {
      let blackListTypeFilter = {
        name: "blackListType",
        label: labelPrefix + "blackListType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: blackListTypes.map((type) => {
          return {
            label: labelPrefix + "type." + type.id,
            value: type.value,
          };
        }),
      };
      filter.push(blackListTypeFilter);
    }

    const blackListValueTypes = await this.getBlacklistValueType();
    if (blackListValueTypes) {
      let blackListValueTypeFilter = {
        name: "blackListValueType",
        label: labelPrefix + "blackListValueType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: blackListValueTypes.map((valueType) => {
          return {
            label: labelPrefix + "valueType." + valueType.id,
            value: valueType.value,
          };
        }),
      };
      filter.push(blackListValueTypeFilter);
    }

    filter.push(
      {
        name: "value",
        label: labelPrefix + "value",
        selected: true,
      },
      {
        name: "notes",
        label: labelPrefix + "notes",
        selected: true,
      },
      {
        name: "creationDateFrom",
        label: labelPrefix + "creationDateFrom",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "creationDateTo",
        label: labelPrefix + "creationDateTo",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "updateDateFrom",
        label: labelPrefix + "updateDateFrom",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "updateDateTo",
        label: labelPrefix + "updateDateTo",
        type: CRITERIA_TYPE_DATE,
        placeholder: "label.placeholders.date",
      }
    );

    return filter;
  };

  getBlacklistType = async () => {
    const url = `/gov/api/rest/v2/campaign/campaignBlackListType`;
    const response = await axios.get(url);
    if (response && response.data) {
      let data = response.data.sort((a, b) => {
        return a.value.localeCompare(b.value, undefined, {
          sensitivity: "base",
        });
      });
      return data;
    }
    throw Error("Error getting blacklist type");
  };

  getBlacklistValueType = async () => {
    const url = `/gov/api/rest/v2/campaign/campaignBlackListValueType`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting blacklist value type");
  };

  getBlacklist = async (searchTerms) => {
    const url = "/gov/api/rest/v2/campaign/searchCampaignBlackListByFilter";
    const terms = searchUtils.sanitizeTerms(searchTerms);

    const response = await axios.get(url, {
      params: { ...terms },
    });

    if (response && response.data) {
      response.data.forEach((it, index) => {
        it["id"] = index;
      });

      return response.data;
    }
    throw Error("Invalid response blacklist");
  };

  manageBlacklistItem = async (body, force, blacklist, isEdit) => {
    const url = "/gov/api/rest/v2/campaign/blackList";

    body["force"] = force;
    body["blackList"] = blacklist;

    if (force && !blacklist) {
      body["valueList"] = [body.value];
      delete body["tableData"];
      delete body["id"];
    } else {
      if (isEdit) {
        body["valueList"] = [body.value];
        body["valuePlayerGroup"] = null;
        body["valueGameAccount"] = null;

        delete body["tableData"];
        delete body["id"];
        delete body["isEdit"];
      } else {
        if (body.blackListValueType === "GAME_ACCOUNT") {
          body["valueList"] = body.valueGameAccount;
          body["valuePlayerGroup"] = null;
        } else {
          body["valueList"] = body.valuePlayerGroup;
          body["valueGameAccount"] = null;
        }
        delete body["_meta"];
      }
    }

    const response = await axios.post(url, body);

    if (response && response.status === 204) {
      return response;
    }

    throw Error("Invalid response for add/remove blacklist", response);
  };
}

const blacklistManagementService = new BlacklistManagementService();
export default blacklistManagementService;
