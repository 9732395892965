import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { useRouteMatch } from "react-router-dom";
import {
  getLoyaltyDetails,
} from "../../../../store/players/individual-player/loyalty-details/actions";
import Moment from "react-moment";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import NoDataToShow from "../../../../utilities/no-data-to-show";

export const LOYALTY_MULTIPLIER = 100

function LoyaltyDetails({
  loading = true,
  getLoyaltyDetails,
  loyaltyDetails
}) {
  const t = useTranslator();
  const match = useRouteMatch();
  const contractId = match.params.id;

  useEffect(()=>{
    if(getLoyaltyDetails && contractId){
      getLoyaltyDetails(contractId)
    }
  },[getLoyaltyDetails,contractId])

  const fields= [
    {
      field:"name",
      title: t({ needle: "label.individualplayer.loyaltyDetails.name"})
    },{
      field:"subscribed",
      title:t({ needle: "label.individualplayer.loyaltyDetails.optIn"}),
      render: (rowData)=>{return <YesNoBadge value={rowData["subscribed"]} />}
    },{
      field:"subscriptionDate",
      title:t({ needle: "label.individualplayer.loyaltyDetails.lastOptIn"}),
      render: (rowData) => (
        <Moment format={"L LT"}>{rowData.subscriptionDate}</Moment>
      ),
    }
  ]
  const groupFields= [
    {
      field:"loyaltyPoint",
      title:t({ needle: "label.individualplayer.loyaltyDetails.loyaltyBalance"}),
      render: (rowData) => (
        rowData.loyaltyPoint > 0 ? rowData.loyaltyPoint / LOYALTY_MULTIPLIER : rowData.loyaltyPoint
      ),
    },{
      field:"levelDescription",
      title:t({ needle: "label.individualplayer.loyaltyDetails.currentLevel"})
    },{
      field:"currentPlayerLevelOrder",
      title:t({ needle: "label.individualplayer.loyaltyDetails.levelNumber"}),
      render: (rowData) => (
        rowData.currentPlayerLevelOrder+"/"+rowData.maxNumberOfLevel
      ),
    },{
      field:"redeemablePointConversionFactor",
      titleTooltip: t({ needle: "label.individualplayer.loyaltyDetails.pointsMultiplierDescription"}),
      title:t({ needle: "label.individualplayer.loyaltyDetails.pointsMultiplier"}),
      render: (rowData) => (
        rowData.redeemablePointConversionFactor > 0 ? rowData.redeemablePointConversionFactor / LOYALTY_MULTIPLIER : rowData.redeemablePointConversionFactor
      ),
    },{
      field:"periodPoint",
      titleTooltip: t({ needle: "label.individualplayer.loyaltyDetails.levelPointsDescription"}),
      title:t({ needle: "label.individualplayer.loyaltyDetails.levelPoints"}),
      render: (rowData) => (
        rowData.periodPoint > 0 ? rowData.periodPoint / LOYALTY_MULTIPLIER : rowData.periodPoint
      ),
    },{
      field:"previousLevelDescriprion",
      titleTooltip: t({ needle: "label.individualplayer.loyaltyDetails.retainedLevelDescription"}),
      title:t({ needle: "label.individualplayer.loyaltyDetails.retainedLevel"}),
    },{
      field:"pointToMaintainLevel",
      titleTooltip: t({ needle: "label.individualplayer.loyaltyDetails.retentionTargetDescription"}),
      title:t({ needle: "label.individualplayer.loyaltyDetails.retentionTarget"}),
      render: (rowData) => (
        rowData.pointToMaintainLevel > 0 ? rowData.pointToMaintainLevel / LOYALTY_MULTIPLIER : rowData.pointToMaintainLevel
      ),
    },{
      field:"nextLevelDescription",
      title:t({ needle: "label.individualplayer.loyaltyDetails.nextLevel"}),
    }
    ,{
      field:"pointNeededForNextLevel",
      titleTooltip: t({ needle: "label.individualplayer.loyaltyDetails.nextLevelTargetDescription"}),
      title:t({ needle: "label.individualplayer.loyaltyDetails.nextLevelTarget"}),
      render: (rowData) => (
        rowData.pointNeededForNextLevel > 0 ? rowData.pointNeededForNextLevel / LOYALTY_MULTIPLIER : rowData.pointNeededForNextLevel
      ),
    },{
      field:"qualificationPeriod",
      title:t({ needle: "label.individualplayer.loyaltyDetails.levelPeriods"}),
    },{
      field:"periodEndDate",
      title:t({ needle: "label.individualplayer.loyaltyDetails.levelPeriodEnds"}),
      render: (rowData) => (
        <Moment format={"L LT"}>{rowData.periodEndDate}</Moment>
      ),
    }
  ]
  return (
    <Grid container spacing={2}>
        
    {loyaltyDetails && loyaltyDetails[0]?
    <Grid item xs={12} md={12} lg={12}><Card>
            <CardHeader
              title={t({
                needle: "label.individualplayer.loyaltyDetails.activeProgram",
              })}
            />
            <CardContent>
            <ListPanel loading={loading} data={loyaltyDetails[0]} fields={fields} />
            </CardContent>
          </Card>
          </Grid>: <NoDataToShow/>}
    {loyaltyDetails && loyaltyDetails[0] && loyaltyDetails[0].levelGroups && loyaltyDetails[0].levelGroups.length>0 && loyaltyDetails[0].levelGroups.map((group)=>{
      return <Grid item xs={12} md={6} lg={6} xl={4}><Card>
      <CardHeader
        title={t({
          needle: group.levelGroupName
        })}
      />
      <CardContent>
      <ListPanel loading={loading} data={group} fields={groupFields} />
      </CardContent>
    </Card>
    </Grid>
    })}
    </Grid>
  )
}

const mapStateToProps = (state) => {
  const {
    loyaltyDetails,
    loading,
    levelFields,
    widgetFields,
  } = state.LoyaltyDetailsReducer;
  return { loading, loyaltyDetails, levelFields, widgetFields };
};

const mapDispatchToProps = {
  getLoyaltyDetails: getLoyaltyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyDetails);
