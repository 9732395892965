import React from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { ReactComponent as UpDoubleArrow } from "../../Images/UpDoubleArrow.svg";
import { ReactComponent as DownDoubleArrow } from "../../Images/DownDoubleArrow.svg";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import clsx from "clsx";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
  },
  paper: {
    width: 350,
    maxHeight: 260,
    overflowY: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  title: {
    lineHeight: "36px",
    textAlign: "center",
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary1,
    fontWeight: theme.typography.fontWeightBold,
  },
  hiddenButton: {
    minWidth: 64,
  },
  itemSelected: {
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary1,
  },
}));

const OrderList = ({
  buttonSide = "right",
  data,
  attribute,
  search,
  onSelect,
  onChange,
  title,
}) => {
  const classes = useStyles();
  const t = useTranslator();
  const [items, setItems] = React.useState(data);
  const [selected, setSelected] = React.useState({});
  const [searchTerms, setSearchTerms] = React.useState(null);

  if (data.length !== items.length) {
    setItems(data);
    setSelected({});
  }

  React.useEffect(() => {
    onSelect && onSelect(Object.values(selected));
    //eslint-disable-next-line
  }, [selected]);

  React.useEffect(() => {
    onChange && onChange(items);
    //eslint-disable-next-line
  }, [items]);

  const handleSelect = (it) => {
    let obj = { ...selected };
    let indexOfItem = items.indexOf(it);
    if (!selected[indexOfItem]) {
      obj[indexOfItem] = it;
    } else {
      delete obj[indexOfItem];
    }
    setSelected(obj);
  };

  const getIdVal = (v, i) => {
    switch (v) {
      case "up": {
        return i - 1;
      }
      case "down": {
        return i + 1;
      }
      default:
        return 0;
    }
  };

  const handleMoveSelected = (verse) => {
    let toUpdate = [...items];
    let newSelected = {};
    const selectedIndex = Object.keys(selected)[0];
    let idVal = getIdVal(verse, +selectedIndex);

    if (Object.keys(selected).length === 1) {
      toUpdate.splice(+selectedIndex, 1);
      idVal = verse === "bottom" ? toUpdate.length : idVal;
      toUpdate.splice(idVal, 0, Object.values(selected)[0]);
      newSelected[idVal] = Object.values(selected)[0];
    } else {
      toUpdate.splice(+selectedIndex, Object.keys(selected).length);

      Object.values(selected).forEach((it, index) => {
        let firstItemId = verse === "bottom" ? toUpdate.length : idVal;
        toUpdate.splice(index === 0 ? firstItemId : firstItemId + index, 0, it);
      });

      Object.values(selected).forEach((it) => {
        newSelected[toUpdate.indexOf(it)] = it;
      });
    }

    setSelected(newSelected);
    setItems(toUpdate);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerms(value);
  };

  const getItems = () => {
    return searchTerms
      ? items.filter(
          (it) =>
            it[attribute].toLowerCase().indexOf(searchTerms.toLowerCase()) !==
            -1
        )
      : items;
  };

  const customList = () => (
    <Paper className={classes.paper} elevation={0} variant={"outlined"}>
      <List dense component={"div"} disablePadding={true}>
        {title ? (
          <ListSubheader component={"div"} className={classes.title}>
            {t({ needle: title })}
          </ListSubheader>
        ) : null}
        {getItems().map((it, i) => (
          <ListItem
            selected={Boolean(selected[items.indexOf(it)])}
            button
            key={i}
            className={clsx(classes.item, {
              [classes.itemSelected]: it.selected,
            })}
            onClick={() => handleSelect(it)}
          >
            <ListItemText>{it[attribute]}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  );

  const getIsDisabled = (upOrDown) => {
    let arr = Object.keys(selected);
    let item = arr.find((key, index) => {
      if (index + 1 < arr.length && arr.length > 1) {
        return +arr[index + 1] !== +key + 1;
      }
    });

    let val = upOrDown === "up" ? items[0] : items[items.length - 1];

    return (
      (Object.keys(selected).length !== 1 && Boolean(item)) ||
      Object.keys(selected).length === 0 ||
      Object.values(selected).includes(val) ||
      items.length === 0 ||
      searchTerms != null
    );
  };

  const buttons = () => {
    let isDisabledUp = getIsDisabled("up");
    let isDisabledDown = getIsDisabled("down");

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        ml={buttonSide === "left" ? 0 : 2}
        mr={buttonSide === "right" ? 0 : 2}
        order={buttonSide === "left" ? 0 : 1}
      >
        <Button
          color="primary"
          size="small"
          onClick={() => handleMoveSelected("top")}
          disabled={isDisabledUp}
          aria-label="move all right"
        >
          <UpDoubleArrow />
        </Button>
        <Button
          color="primary"
          size="small"
          className={classes.button}
          onClick={() => handleMoveSelected("up")}
          disabled={isDisabledUp}
          aria-label="move selected right"
        >
          <ExpandLessIcon />
        </Button>
        <Button
          color="primary"
          onClick={() => handleMoveSelected("down")}
          disabled={isDisabledDown}
          aria-label="move selected left"
        >
          <ExpandMoreIcon />
        </Button>
        <Button
          color="primary"
          size="small"
          className={classes.button}
          onClick={() => handleMoveSelected("bottom")}
          disabled={isDisabledDown}
          aria-label="move all left"
        >
          <DownDoubleArrow />
        </Button>
      </Box>
    );
  };

  return (
    <Box display={"flex"} flexDirection={"column"} className={classes.root}>
      {search ? (
        <Box mb={1} display={"flex"}>
          <Box
            ml={buttonSide === "left" ? 0 : 2}
            mr={buttonSide === "right" ? 0 : 2}
            order={buttonSide === "left" ? 0 : 1}
          >
            <ButtonBase className={classes.hiddenButton} />
          </Box>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder={t({ needle: "label.picklist.search" })}
            fullWidth
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
        </Box>
      ) : null}
      <Box display={"flex"} flexGrow={1}>
        {buttons()}
        {customList()}
      </Box>
    </Box>
  );
};

export default OrderList;
