import { convertFromMillsToHours } from "../../../../../utils";
import {
  PLAYER_SESSION_INFO_SUCCESS,
  SESSION_LIMITS_EDIT_FAILURE,
  SESSION_LIMITS_EDIT_REQUEST,
  SESSION_LIMITS_EDIT_SUCCESS,
  SESSION_LIMITS_FAILURE,
  SESSION_LIMITS_RESET_FAILURE,
  SESSION_LIMITS_RESET_REQUEST,
  SESSION_LIMITS_RESET_SUCCESS,
  SESSION_LIMITS_SUCCESS,
} from "../../../../constants";

export const prefix = "label.individualplayer.responsibleGame.sessionLimits.";

const convertData = (data) => {
  Object.entries(data).forEach(([k, v]) => {
    var x = convertFromMillsToHours(+v.limitValue);
    data[k].limitHours = x.hours;
    data[k].limitMins = x.mins;
  });
};

const converterMaxVal = (val) => {
  var x = convertFromMillsToHours(+val);
  return `${x.hours} hours ${+x.mins} minutes`;
};

const initialState = {
  loading: true,
  fields: (data) => {
    return [
      {
        field: "session",
        name: "session",
        type: "GroupField",
        hidden: data && data["session"] == null,
        items: [
          {
            field: "limitHours",
            name: "limitHours",
            type: "Text",
            inline: true,
          },
          {
            field: "limitMins",
            name: "limitMins",
            label: ":",
            type: "Text",
            inline: true,
          },
        ],
        render: (rowData) =>
          `${rowData.session.limitHours} hours ${+rowData.session
            .limitMins} minutes`,
      },
      {
        field: "session.maxValue",
        name: "session.maxValue",
        hidden: data && data["session"] == null,
        render: (rowData) => converterMaxVal(rowData.session.maxValue),
      },
      {
        field: "daily",
        name: "daily",
        type: "GroupField",
        hidden: data && data["daily"] == null,
        items: [
          {
            field: "limitHours",
            name: "limitHours",
            type: "Text",
            inline: true,
          },
          {
            field: "limitMins",
            name: "limitMins",
            label: ": ",
            type: "Text",
            inline: true,
          },
        ],
        render: (rowData) =>
          `${rowData.daily.limitHours} hours ${+rowData.daily
            .limitMins} minutes`,
      },
      {
        field: "daily.maxValue",
        name: "daily.maxValue",
        hidden: data && data["daily"] == null,
        render: (rowData) => converterMaxVal(rowData.daily.maxValue),
      },
      {
        field: "weekly",
        name: "weekly",
        type: "GroupField",
        hidden: data && data["weekly"] == null,
        items: [
          {
            field: "limitHours",
            name: "limitHours",
            type: "Text",
            inline: true,
          },
          {
            field: "limitMins",
            name: "limitMins",
            label: ": ",
            type: "Text",
            inline: true,
          },
        ],
        render: (rowData) =>
          `${rowData.weekly.limitHours} hours ${+rowData.weekly
            .limitMins} minutes`,
      },
      {
        field: "weekly.maxValue",
        name: "weekly.maxValue",
        hidden: data && data["weekly"] == null,
        render: (rowData) => converterMaxVal(rowData.weekly.maxValue),
      },
      {
        field: "monthly",
        name: "monthly",
        type: "GroupField",
        hidden: data && data["monthly"] == null,
        items: [
          {
            field: "limitHours",
            name: "limitHours",
            type: "Text",
            inline: true,
          },
          {
            field: "limitMins",
            name: "limitMins",
            label: ": ",
            type: "Text",
            inline: true,
          },
        ],
        render: (rowData) =>
          `${rowData.monthly.limitHours} hours ${+rowData.monthly
            .limitMins} minutes`,
      },
      {
        field: "monthly.maxValue",
        name: "monthly.maxValue",
        hidden: data && data["monthly"] == null,
        render: (rowData) => converterMaxVal(rowData.monthly.maxValue),
      },
      {
        field: "yearly",
        name: "yearly",
        type: "GroupField",
        hidden: data && data["yearly"] == null,
        items: [
          {
            field: "limitHours",
            name: "limitHours",
            type: "Text",
            inline: true,
          },
          {
            field: "limitMins",
            name: "limitMins",
            label: ": ",
            type: "Text",
            inline: true,
          },
        ],
        render: (rowData) =>
          `${rowData.yearly.limitHours} hours ${+rowData.yearly
            .limitMins} minutes`,
      },
      {
        field: "yearly.maxValue",
        name: "yearly.maxValue",
        hidden: data && data["yearly"] == null,
        render: (rowData) => converterMaxVal(rowData.yearly.maxValue),
      },
    ];
  },
  sessionLimits: null,
  playerSessionInfo: null,
};

const SessionLimitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_LIMITS_SUCCESS:
    case SESSION_LIMITS_FAILURE:
    case SESSION_LIMITS_EDIT_SUCCESS:
    case SESSION_LIMITS_EDIT_FAILURE:
    case SESSION_LIMITS_RESET_SUCCESS:
    case SESSION_LIMITS_RESET_FAILURE: {
      convertData(action.payload);
      return {
        ...state,
        loading: false,
        sessionLimits: action.payload,
      };
    }
    case SESSION_LIMITS_EDIT_REQUEST:
    case SESSION_LIMITS_RESET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PLAYER_SESSION_INFO_SUCCESS: {
      return {
        ...state,
        playerSessionInfo: action.payload,
      };
    }
    default:
      return state;
  }
};

export default SessionLimitsReducer;
