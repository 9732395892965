import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  BLOCKLIST_ADD_REMOVE_FAILURE,
  BLOCKLIST_ADD_REMOVE_REQUEST,
  CHECK_PLAYERBLOCKLIST_FAILURE,
  CHECK_PLAYERBLOCKLIST_REQUEST,
  CHECK_PLAYERBLOCKLIST_SUCCESS,
  PLAYERBLOCKLIST_HISTORY_FAILURE,
  PLAYERBLOCKLIST_HISTORY_REQUEST,
  PLAYERBLOCKLIST_HISTORY_SUCCESS,
} from "../../../constants";
import { enqueueSnackbar } from "../../../snackbars/actions";
import BlocklistService from "./blocklist-service";

const addRemoveBlock = function* (action) {
  try {
    yield call(
      BlocklistService.addRemoveBlock,
      action.contractId,
      action.brand,
      action.partner,
      action.note,
      action.isBlacklisted
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: action.isBlacklisted
            ? "label.individualplayer.blocklist.removedSuccess"
            : "label.individualplayer.blocklist.insertSuccess",
        },
        options: {
          key: "Blocklist_error",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("blocklist", e);
    yield put({
      type: BLOCKLIST_ADD_REMOVE_FAILURE,
    });
  }
};

const loadSelfExclusionHistory = function* (action) {
  try {
    const data = yield call(
      BlocklistService.loadPlayerBlackListHistory,
      action.contractId,
      action.query
    );
    yield put({
      type: PLAYERBLOCKLIST_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadPlayerBlackListHistory", e);
    yield put({
      type: PLAYERBLOCKLIST_HISTORY_FAILURE,
    });
  }
};

const checkPlayerBlackListStatus = function* (action) {
  try {
    const data = yield call(
      BlocklistService.checkPlayerBlackListStatus,
      action.contractId
    );
    yield put({
      type: CHECK_PLAYERBLOCKLIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("checkPlayerIsInBlackList", e);
    yield put({
      type: CHECK_PLAYERBLOCKLIST_FAILURE,
    });
  }
};

const BlocklistSaga = function* BlocklistSaga() {
  yield takeLatest(BLOCKLIST_ADD_REMOVE_REQUEST, addRemoveBlock);
  yield takeLatest(PLAYERBLOCKLIST_HISTORY_REQUEST, loadSelfExclusionHistory);
  yield takeLatest(CHECK_PLAYERBLOCKLIST_REQUEST, checkPlayerBlackListStatus);
};

export default BlocklistSaga;
