import { call, put, takeEvery } from "redux-saga/effects";
import {
  OAUTH_PROVIDER_LOGIN_REQUEST,
  SESSION_INFO_REQUEST,
} from "../constants";
import oauthProviderService from "./oauth-provider-service";

const oauthProviderLogin = function* (action) {
  let data = { result: false, errorCode: null };
  try {
    let response = yield call(
      oauthProviderService.oauthProviderLogin,
      action.provider,
      action.brandId,
      action.partnerId,
      action.props
    );

    data = response.data;

    sessionStorage.setItem("access-token", response.tokens.accessToken);
    sessionStorage.setItem("refresh-token", response.tokens.refreshToken);
  } catch (e) {
    console.error("external login error", e);
    let errorCode =
      e.response.data != null && e.response.data !== ""
        ? e.response.data.ERROR_CODE
        : "1002";
    data = {
      result: false,
      errorCode: errorCode,
    };
  }
  yield put({
    type: SESSION_INFO_REQUEST,
    payload: data,
  });
};

const OauthProviderSaga = function* oauthProviderSaga() {
  yield takeEvery(OAUTH_PROVIDER_LOGIN_REQUEST, oauthProviderLogin);
};

export default OauthProviderSaga;
