import axios from "axios";
import { CRITERIA_TYPE_SELECT } from "../../../constants";
import { labelPrefix, getRegLevelLabel } from "./player-contacts-verification-reducer";
import playerService from "../../../players/player-service.js";

class PlayerContactsVerificationService {
  getListFilter = async (brand, partner) => {
    const data = await playerService.getPlayerLevels(brand, partner);

    let options = data.map(level => ({ label: labelPrefix + getRegLevelLabel(level.id), value: level.id }));

    const filters = [
      {
        name: "contactType",
        label: labelPrefix + "contactType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          { label: labelPrefix + "email", value: "e-mail" },
          { label: labelPrefix + "mobileNumber", value: "sms" },
        ],
      },
      {
        name: "verification",
        label: labelPrefix + "verification",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          { label: labelPrefix + "active", value: "true" },
          { label: labelPrefix + "notActive", value: "false" },
        ],
      },
      {
        name: "regLevel",
        label: labelPrefix + "regLevel",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: options,
      },
      {
        name: "loginCheck",
        label: labelPrefix + "loginCheck",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          { label: labelPrefix + "active", value: "true" },
          { label: labelPrefix + "notActive", value: "false" },
        ],
      },
    ];

    return filters;
  };

  loadData = async (brandId, partnerId, searchTerms) => {
    const url =
      "/gov/api/rest/v1/communications/contactVerification/contact-configurations";

    const response = await axios.get(url, {
      params: {
        brandId,
        partnerId,
        ...searchTerms,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response player contact verification list list");
  };

  changeVerification = async (
    brandId,
    partnerId,
    contactType,
    regLevel,
    verification,
    loginCheck
  ) => {
    let url = `/gov/api/rest/v1/communications/contactVerification/contact-configurations?brandId=${brandId}&partnerId=${partnerId}&contactType=${contactType}&regLevel=${regLevel}&verification=${verification}&loginCheck=${loginCheck}`;
    const response = await axios.put(url);

    if (response && response.status !== 204) {
      throw Error("Invalid response player contact verification list list");
    }
  };
}

const playerContactsVerificationService = new PlayerContactsVerificationService();
export default playerContactsVerificationService;
