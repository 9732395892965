import { call, put } from "redux-saga/effects";
import { takeLatest } from "@redux-saga/core/effects";
import playerFavoritesService from "../players/individual-player/player-Favorites/player-favorites-services";
import {
  LOCATIONS_ADMIN_LIST_REQUEST,
  LOCATIONS_ADMIN_LIST_SUCCESS,
  LOCATIONS_ADMIN_LIST_FAILURE,
} from "../../store/constants";
const getLocationsList = function* (action) {
  try {
    const data = yield call(
      playerFavoritesService.getLocationsList,
      action.page,
      action.pageSize,
      action.terms,
      action.add
    );
    yield put({
      type: LOCATIONS_ADMIN_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: LOCATIONS_ADMIN_LIST_FAILURE,
    });
  }
};
const LocationsSaga = function* Locations() {
  yield takeLatest(LOCATIONS_ADMIN_LIST_REQUEST, getLocationsList);
};

export default LocationsSaga;
