import { call, put } from "redux-saga/effects";
import {
  INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_REQUEST,
  INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_SUCCESS,
  INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_FAILURE,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import customerAccountUpdateService from "./customer-account-update-service";

const customerAccountUpdate = function* (action) {
  try {
    const data = yield call(
      customerAccountUpdateService.loadIndividualPlayerAccountUpdate,
      action.contractId,
      action.brand,
      action.partner
    );
    yield put({
      type: INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerCustomerAccountUpdate", e);
    yield put({
      type: INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_FAILURE,
    });
  }
};

const CustomerAccountUpdateSaga = function* customerAccountUpdateSaga() {
  yield takeLatest(
    INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_REQUEST,
    customerAccountUpdate
  );
};

export default CustomerAccountUpdateSaga;
