import axios from "axios";
import gamePlatformsService from "../../../game-platforms/game-platforms-service";

class LimitConfigurationService {
  async getLimitConfiguration(brand, partner, gameDomain) {
    const gameDomainsCollection = await gamePlatformsService.getSimplePlatforms();

    let domains = {};

    gameDomainsCollection.forEach((item) => {
      return (domains[item.gameDomainId] = item.longDescription);
    });
    const platform = gameDomain ? gameDomain : "";
    const response = await axios.get(
      `/gov/api/rest/v2/getLoyLimitConfiguration?brand=${brand}&partner=${partner}&gameDomainId=${platform}`
    );

    if (response && response.data) {
      var lastUpdate = null;
      var results = response.data.reduce(function (obj, it) {
        (obj[it.gameDomain.id] = obj[it.gameDomain.id] || []).push(it);
        return obj;
      }, {});

      var limits = Object.keys(results).map(function (key) {
        lastUpdate = results[key][0].lastUpdateDate;

        results[key].forEach((it) => {
          if (it.lastUpdateDate > lastUpdate) {
            lastUpdate = it.lastUpdateDate;
          }
        });
        return {
          platformId: key,
          platformDesc: domains[key],
          details: results[key],
          lastUpdate: lastUpdate,
        };
      });

      return limits;
    }
    throw Error("Invalid response for loyalty limit configurations");
  }

  async getLimitConfigurationHistory(
    brand,
    partner,
    gameDomain,
    limitPeriodId
  ) {
    const response = await axios.get(
      `/gov/api/rest/v2/historyLoyLimitConfiguration?brand=${brand}&partner=${partner}&gameDomainId=${
        gameDomain ? gameDomain : ""
      }&limitPeriodId=${limitPeriodId}
      `
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for loyalty limit configuration history");
  }

  async editLimitConfiguration(brand, partner, bodyRequest) {
    const url = `/gov/api/rest/v2/editLoyLimitConfiguration/${bodyRequest.id}?brand=${brand}&partner=${partner}`;
    const response = await axios.put(url, bodyRequest);

    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response for edit loyalty limit configurations");
  }
}

const limitConfigurationService = new LimitConfigurationService();
export default limitConfigurationService;
