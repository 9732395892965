import React from "react";
import Moment from "react-moment";
import {
  LOYALTY_DETAILS_SUCCESS,
  LOYALTY_DETAILS_FAILURE,
  LOYALTY_DETAILS_REQUEST,
} from "../../../constants";
import Translate from "../../../../Layouts/Translator/Translate";

const initialState = {
  loading: true,
  loyaltyDetails: [],
  widgetFields: [
    {
      field: "gameDomain.longDescription",
      title: "label.individualplayer.loyaltyDetails.gameDomain",
    },
    {
      field: "pluginImplId",
      title: "label.individualplayer.loyaltyDetails.levelScale",
      render: (rowData) => {
        return (
          <Translate
            needle={
              "label.individualplayer.loyaltyDetails.scale." +
              rowData["pluginImplId"]
            }
          />
        );
      },
    },
    {
      field: "levelName",
      title: "label.individualplayer.loyaltyDetails.levelName",
    },
  ],
  
};

const LoyaltyDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOYALTY_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOYALTY_DETAILS_SUCCESS: {
      return {
        ...state,
        loyaltyDetails: action.payload,
        loading: false,
      };
    }
    case LOYALTY_DETAILS_FAILURE: {
      return {
        ...state,
        loyaltyDetails: null,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default LoyaltyDetailsReducer;
