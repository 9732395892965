import axios from "axios";
import { CRITERIA_TYPE_DATERANGE, CRITERIA_TYPE_SELECT } from "../constants";
import { labelPrefix } from "./taxable-prize-reducer";
import searchUtils from "../../utilities/searchUtils";

const baseUrlTso = "/gov/api/rest/v1/tso/taxablePrize";
const baseUrlThp = "/gov/api/rest/v1/thp/taxablePrize";

const getUrl = (onHoldId, key, brand, partner, reason, contractId) => {
  const postLabel = `${onHoldId}?brand=${brand}&partner=${partner}`;
  switch (key) {
    case "manuallyPaidModal": {
      return {
        url: `${baseUrlTso}/manuallyPaidOnHoldPayment/${postLabel}`,
        body: { comment: reason },
      };
    }
    case "voidModal": {
      return {
        url: `${baseUrlTso}/notPaidOnHoldPayment/${postLabel}`,
        body: { comment: reason },
      };
    }
    case "approveModal": {
      return {
        url: `${baseUrlTso}/payment/${postLabel}`,
        body: { approvalReason: reason },
      };
    }
    case "claimModal": {
      return {
        url: `${baseUrlTso}/claimPayment/${postLabel}&contractId=${contractId}&reason=${reason}`,
      };
    }
    default: {
      return "";
    }
  }
};

class TaxablePrizeService {
  getListFilter = async (gameAccount) => {
    const filters = [
      {
        name: "gameAccount",
        label: labelPrefix + "contractId",
        selected: true,
        hidden: () => {
          return gameAccount !== null;
        },
      },
      {
        name: "transactionId",
        label: labelPrefix + "transactionId",
      },
      {
        name: "inChargeByMe",
        label: labelPrefix + "inChargeByMe",
        type: "checkbox",
      },
      {
        name: "paymentRequestDate",
        label: labelPrefix + "paymentRequestDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
        newLine: true,
      },

      {
        name: "paymentDate",
        label: labelPrefix + "paymentDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
    ];

    const paymentStatuses = await this.getPaymentStatuses();
    if (paymentStatuses) {
      let paymentStatusFilter = {
        name: "paymentStatus",
        label: labelPrefix + "paymentStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: paymentStatuses.map((status) => {
          return {
            label: "label.status.taxablePrize." + status,
            value: status,
          };
        }),
      };

      filters.push(paymentStatusFilter);
    }

    const pdfStatuses = await this.getPdfStatuses();
    if (pdfStatuses) {
      let pdfStatusFilter = {
        name: "pdfStatus",
        label: labelPrefix + "PDFStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: pdfStatuses.map((status) => {
          return {
            label: "label.status.taxablePrize." + status,
            value: status,
          };
        }),
      };
      filters.push(pdfStatusFilter);
    }

    const onHoldStatuses = await this.getOnHoldStatuses();
    if (onHoldStatuses) {
      let onHoldStatusFilter = {
        name: "onHoldStatus",
        label: labelPrefix + "onHoldStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: onHoldStatuses.map((status) => {
          return {
            label: "label.status.taxablePrize." + status,
            value: status,
          };
        }),
      };
      filters.push(onHoldStatusFilter);
    }

    let taxedFilter = {
      name: "taxed",
      label: labelPrefix + "taxedFilter.label",
      type: CRITERIA_TYPE_SELECT,
      placeholder: "label.placeholders.select",
      options: [
        { label: labelPrefix + "taxedFilter.taxed", value: "true" },
        { label: labelPrefix + "taxedFilter.notTaxed", value: "false" },
        { label: labelPrefix + "taxedFilter.all", value: "ALL" },
      ],
    };
    filters.push(taxedFilter);

    return filters;
  };

  getWinnings = async (
    brand,
    partner,
    searchTerms,
    page,
    pageSize,
    orderBy
  ) => {
    const order =
      orderBy != null
        ? searchUtils.getOrder(orderBy.order) + orderBy.field
        : null;
    const url = baseUrlTso + "/winnings";
    const response = await axios.get(url, {
      params: {
        brand: brand,
        partner: partner,
        pageNumber: page + 1,
        pageSize: pageSize,
        orderBy: order,
        ...searchTerms,
      },
    });
    if (response && response.data && response.data.winnings) {
      return {
        data: response.data.winnings,
        page: page,
        totalCount: response.data.totalItems,
      };
    }

    throw Error("Invalid response Taxable Payment - winnings list");
  };

  downloadPdf = async (brand, partner, thpId, gameAccount) => {
    const url = `${baseUrlThp}/transactions/${thpId}/getPDF`;
    const resp = await axios.get(url +
        "?brand=" +
        brand +
        "&partner=" +
        partner +
        "&contractId=" +
        gameAccount, {
      responseType: "blob"
    });

    let blob = new Blob([resp.data],
        {
          type: "application/pdf"
        });
    window.open(URL.createObjectURL(blob), "_blank").focus();
  };

  generatePdf = async (thpId) => {
    const url = `${baseUrlThp}/transactions/${thpId}/generatePDF`;
    const response = await axios.post(url);
    if (response && response.status !== 204) {
      throw Error("Error generating PDF");
    }
  };

  getPaymentStatuses = async () => {
    const url = baseUrlTso + "/paymentStatuses";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting paymentStatuses");
  };

  getPdfStatuses = async () => {
    const url = baseUrlTso + "/pdfStatuses";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting pdfStatuses");
  };

  getOnHoldStatuses = async () => {
    const url = baseUrlTso + "/onHoldStatuses";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting onHoldStatuses");
  };

  takeInChargeOnHoldPayment = async (
    inCharge,
    onHoldId,
    brand,
    partner,
    subjectInCharge
  ) => {
    let forceOperation = subjectInCharge !== null && inCharge;
    const url = `/gov/api/rest/v1/tso/taxablePrize/takeInChargeOnHoldPayment/${onHoldId}?brand=${brand}&partner=${partner}&inCharge=${inCharge}&forceOperation=${forceOperation}`;
    const response = await axios.put(url);
    if (response && response.status !== 204) {
      throw Error("Error taking in charge");
    }
  };

  async deductAmount({ onHoldId, body, brand, partner }) {
    const url = `/gov/api/rest/v1/tso/taxablePrize/deductAmount/${onHoldId}?brand=${brand}&partner=${partner}`;

    const response = await axios.put(url, body);
    if (response == null || response.status !== 200) {
      throw Error("Error deduct amount");
    }
  }

  async onHoldPayments({ onHoldId, reason, key, brand, partner, contractId }) {
    const value = getUrl(onHoldId, key, brand, partner, reason, contractId);

    const response = await axios.put(value.url, value.body);
    if (response && response.status !== 204 && response.status !== 200) {
      throw Error("Error getting onHoldStatuses");
    }
  }

  async getTransactionLogs({
    brandId,
    partnerId,
    page,
    pageSize,
    onHoldId,
    contractId,
  }) {
    const url = `/gov/api/rest/v1/tso/taxablePrize/claimPayment/${onHoldId}/history`;

    const response = await axios.get(url, {
      params: {
        brandId,
        partnerId,
        page: page + 1,
        pageSize,
        contractId,
      },
    });
    if (response && response.data && response.data.items) {
      return {
        data: response.data.items,
        page: page,
        totalCount: response.data.totalItems,
      };
    }
  }
}

const taxablePrizeService = new TaxablePrizeService();
export default taxablePrizeService;
