import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useSession } from "../../../../../utilities/hooks/useSession";
import { connect } from "react-redux";
import Table from "../../../../../Layouts/Table/Table";
import { useIndividualPlayerDirectNotificationsHistory } from "../../../../../utilities/hooks/useIndividualPlayerDirectNotificationsHistory";
import { usePageActions } from "../../../../../utilities/hooks/usePageActions";
import Visibility from "@material-ui/icons/Visibility";
import GetApp from "@material-ui/icons/GetApp";
import Modal from "../../../../../Layouts/Modal/Modal";
import {
  Box,
  CardContent,
  Grid,
  Typography,
  SvgIcon,
  makeStyles,
} from "@material-ui/core";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import {
  resendIndividualPlayerDirectNotificationHandler,
  getIndividualPlayerDirectNotificationAttachmentHandler,
} from "../../../../../store/players/individual-player/direct-notifications/actions";
import Fieldset from "../../../../../Layouts/form/fieldset";
import { useIndividualPlayerDirectNotificationDetail } from "../../../../../utilities/hooks/useIndividualPlayerDirectNotificationDetail";
import { ReactComponent as ResendIcon } from "../../../../../Images/button_resend.svg";
import { isMobileOnly } from "react-device-detect";
import { isActionHidden } from "../../../../../utils";
import UnsafeHtml from "../../../../../Layouts/unsafe-html";

const useStyle = makeStyles((theme) => ({
  marginTop: {
    paddingTop: theme.spacing(2),
  },
  message: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  attachments: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    fontSize: theme.spacing(4),
  },
}));

function DirectNotificationHistory({
  widget,
  loading = true,
  columns,
  resendIndividualPlayerDirectNotificationHandler,
  getIndividualPlayerDirectNotificationAttachmentHandler,
}) {
  const { buttons } = usePageActions();
  const classes = useStyle();
  const t = useTranslator();
  const match = useRouteMatch();
  const { brandSelected, partnerSelected } = useSession();
  const contractId = match.params.id;
  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: widget ? 5 : 10,
    orderBy: { field: "gameAccount", order: "asc" },
  });
  const [openResendModal, setOpenResendModal] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [notificationSelected, setNotificationSelected] = React.useState(null);
  const data = useIndividualPlayerDirectNotificationsHistory(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    query.page,
    query.pageSize
  );

  const detail = useIndividualPlayerDirectNotificationDetail(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    notificationSelected
  );

  const loadHistory = (q) => {
    setQuery(q);
  };

  const handleOpenResendModal = (notificationId) => {
    setOpenResendModal(true);
    setNotificationSelected(notificationId);
  };

  const handleOpenViewModal = (notificationId) => {
    setOpenViewModal(true);
    setNotificationSelected(notificationId);
  };

  const handleCloseResendModal = () => {
    setOpenResendModal(false);
    setNotificationSelected(null);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setNotificationSelected(null);
  };

  const handleResendDirectNotification = () => {
    resendIndividualPlayerDirectNotificationHandler(
      contractId,
      brandSelected.id,
      partnerSelected.id,
      notificationSelected
    );
    setOpenResendModal(false);
  };

  const getBodyDetail = (body) => {
    if (body) {
      body = body.replace("<p>", "").replace("</p>", "");
    }
    return body;
  };

  const selectAttachment = (att) => {
    getIndividualPlayerDirectNotificationAttachmentHandler(
      contractId,
      brandSelected.id,
      partnerSelected.id,
      notificationSelected,
      att.id
    );
  };

  const getAttachments = (attachments) => {
    return attachments.map((att) => {
      return (
        <Box display={"flex"}>
          <Typography variant={"h7"}>{att.name}</Typography>
          <SvgIcon
            component={GetApp}
            onClick={() => selectAttachment(att)}
            className={classes.icon}
          />
        </Box>
      );
    });
  };

  const createMarkup = () => {
    return {
      __html: getBodyDetail(detail.body),
    };
  };

  const getViewModalContent = () => {
    return detail ? (
      <CardContent>
        <Fieldset
          legend={t({
            needle: "label.modal.directNotifications.message",
          })}
        >
          <Grid container spacing={2} className={classes.message}>
            <Grid item container sm={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"h7"}>
                  <UnsafeHtml tag={'div'} dangerouslySetInnerHTML={createMarkup()} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Fieldset>
        {detail.attachment && detail.attachment.attachmentDetails ? (
          <Box className={classes.marginTop}>
            <Fieldset
              legend={t({
                needle: "label.modal.directNotifications.attachments",
              })}
            >
              <Grid container spacing={2} className={classes.attachments}>
                <Grid item container sm={12} spacing={2}>
                  <Grid item xs={12}>
                    {getAttachments(detail.attachment.attachmentDetails)}
                  </Grid>
                </Grid>
              </Grid>
            </Fieldset>
          </Box>
        ) : null}
      </CardContent>
    ) : null;
  };

  return (
    <Box>
      <Table
        isLoading={loading}
        title={"label.individualplayer.directNotifications.historyList"}
        placeholder={
          "label.individualplayer.directNotifications.searchPlaceholders"
        }
        type={widget ? "simple" : "advanced"}
        pagination={widget ? data != null && data.length > 5 : true}
        columns={columns.filter((it) => !widget || it.widget)}
        resize={!widget}
        data={data}
        exportButton={false}
        {...query}
        onFetch={loadHistory}
        actions={[
          {
            icon: Visibility,
            color: "primary",
            variant: "contained",
            tooltip: "button.view",
            onClick: (event, rowData) => {
              handleOpenViewModal(rowData.directNotificationId);
            },
          },
          {
            icon: ResendIcon,
            color: "primary",
            variant: "contained",
            tooltip: "button.resend",
            onClick: (event, rowData) => {
              handleOpenResendModal(rowData.directNotificationId);
            },
            hidden:
              widget || isActionHidden(buttons, "direct_notification_resend"),
          },
        ]}
      />
      <Modal
        open={openResendModal}
        title={t({
          needle: "label.modal.directNotifications.resend",
          label: "Resend Direct Notification",
        })}
        description={t({
          needle:
            "label.individualplayer.directNotifications.resendDescription",
          variables: [
            detail ? detail.channelName : "",
            detail ? detail.sender : "",
          ],
        })}
        actions={[
          {
            color: "primary",
            label: "button.cancel",
            variant: "outlined",
            handler: handleCloseResendModal,
          },
          {
            color: "primary",
            label: "button.resend",
            variant: "contained",
            handler: handleResendDirectNotification,
          },
        ]}
      />
      <Modal
        open={openViewModal}
        title={t({
          needle: "label.modal.directNotifications.view",
        })}
        maxWidth="lg"
        fullScreen={isMobileOnly}
        handleClose={isMobileOnly ? handleCloseViewModal : null}
        content={getViewModalContent()}
        actions={[
          {
            color: "primary",
            label: "button.close",
            variant: "contained",
            handler: handleCloseViewModal,
          },
        ]}
      />
    </Box>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state.DirectNotificationsReducer;
  const {
    columns,
  } = state.DirectNotificationsReducer.directNotificationsHistoryList;
  return {
    loading,
    columns,
  };
};

const mapDispatchToProps = {
  resendIndividualPlayerDirectNotificationHandler,
  getIndividualPlayerDirectNotificationAttachmentHandler,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectNotificationHistory);
