import {
  REWARD_TYPES_SUCCESS,
  REWARD_TYPES_FAILURE,
  COMMUNICATIONS_FAILURE,
  COMMUNICATIONS_SUCCESS,
  GROUP_NAME_SUCCESS,
  GROUP_NAME_FAILURE,
  CLONE_REWARD_SUCCESS,
} from "../../../../constants";

const initialState = {
  rewardTypes: [],
  groupName: [],
  communications: [],
  cloneResult: null,
};

const BasicStepReducer = (state = initialState, action) => {
  switch (action.type) {
    case REWARD_TYPES_SUCCESS: {
      return {
        ...state,
        rewardTypes: action.payload,
      };
    }
    case REWARD_TYPES_FAILURE: {
      return {
        ...state,
        rewardTypes: null,
      };
    }
    case GROUP_NAME_SUCCESS: {
      return {
        ...state,
        groupName: action.payload,
      };
    }
    case GROUP_NAME_FAILURE: {
      return {
        ...state,
        groupName: null,
      };
    }
    case COMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        communications: action.payload,
      };
    }
    case COMMUNICATIONS_FAILURE: {
      return {
        ...state,
        communications: null,
      };
    }
    case CLONE_REWARD_SUCCESS: {
      return { ...state, cloneResult: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default BasicStepReducer;
