import { BARCODE_SUCCESS, BARCODE_FAILURE } from "../constants";
const initialState = {
  barCode: {
    data: null,
    loading: true,
    success: null,
    columns: [
      {
        field: "barCodeType",
        title: "label.clubCard.barCode.type",
      },
    ],
  },
};

const BarCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case BARCODE_SUCCESS: {
      return {
        ...state,
        barCode: {
          ...state.barCode,
          data: action.payload,
          loading: false,
          success: true,
        },
      };
    }
    case BARCODE_FAILURE: {
      return {
        ...state,
        barCode: {
          ...state.barCode,
          data: null,
          loading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
export default BarCodeReducer;
