import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useSession } from "../../../../../utilities/hooks/useSession";
import { usePlayerDetail } from "../../../../../utilities/hooks/usePlayerDetail";
import { Box } from "@material-ui/core";
import ListPanel from "../../../../../Layouts/Panel/ListPanel";
import NoDataToShow from "../../../../../utilities/no-data-to-show";
import { ReactComponent as NoData } from "../../../../../Images/icon_no_data.svg";
import Table from "../../../../../Layouts/Table/Table";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import { useSelector } from "react-redux";

const prefix = "label.individualplayer.serviceParameterGroups.";

const getGroupAttributes = (group) => {
  let data = {};
  if (group != null && group.attributes) {
    group.attributes.reduce((accumulator, it) => {
      if (!it.attributeValue) {
        it.attributeValue = "";
      }
      accumulator[it.attributeName] = it.attributeValue;
      return accumulator;
    }, data);
  }
  return Object.keys(data).length > 0 ? data : null;
};

const ServiceParameterGroups = ({ widget }) => {
  const match = useRouteMatch();
  const { brandSelected, partnerSelected } = useSession();
  const contractId = match.params.id;
  const { playerServiceParametersGroups, loading } = usePlayerDetail(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    partnerSelected.pam
  );
  const { columns, detailColumns } = useSelector(
    (state) => state.PlayerDetailReducer.playerDetails.serviceParameterGroups
  );
  const t = useTranslator();

  const getWidget = () => {
    const widgetData = (playerServiceParametersGroups || []).reduce(
      (acc, group) => {
        if (acc[group.name] == null) {
          acc[group.name] = { value: group.name, children: [] };
        }
        acc[group.name].children.push(getGroupAttributes(group));
        return acc;
      },
      {}
    );
    return <ListPanel data={widgetData} loading={loading} />;
  };

  let tableData = [];

  if (playerServiceParametersGroups) {
    tableData = playerServiceParametersGroups.filter((it) => {
      return it.attributes.length > 0;
    });
  }

  return playerServiceParametersGroups &&
    playerServiceParametersGroups.length > 0 ? (
    <Box>
      {widget ? (
        getWidget()
      ) : (
        <Table
          isLoading={loading}
          title={prefix + "title"}
          type={"advanced"}
          pagination={true}
          columns={columns}
          data={tableData}
          exportButton={false}
          detailKey={"id"}
          detail={(rowData) => (
            <Table
              type={"simple"}
              resize={false}
              columns={detailColumns}
              pagination={false}
              editable={false}
              data={rowData.attributes.map((it) => ({
                attributeId: it.attributeId,
                attributeLastUpdateDate: it.attributeLastUpdateDate,
                attributeName: it.attributeName,
                attributeValue: it.attributeValue,
              }))}
            />
          )}
        />
      )}
    </Box>
  ) : (
    <NoDataToShow
      icon={NoData}
      message={"label.serviceParameterGroups.noDataToShow"}
    />
  );
};

export default ServiceParameterGroups;
