import axios from "axios";
import { CRITERIA_TYPE_SELECT } from "../../../constants";
import assignRewardService from "../../../assign-reward/assign-rewward-service";
import searchUtils from "../../../../utilities/searchUtils";

class LoyaltyTransactionsService {
  async getFilterFields() {
    const filters = [
      {
        name: "period",
        label: "label.individualplayer.loyaltyTransactions.date",
        type: "datetimerange",
        placeholder: "label.placeholders.dateTime",
      },
    ];

    let transactionTypeUrl = `/gov/api/rest/v1/player-transaction/types`;
    const responseTransactionType = await axios.get(transactionTypeUrl);
    let transactionType = []
    if (responseTransactionType && responseTransactionType.data) {
      transactionType = {
        name: "transaction-type",
        label: "label.individualplayer.loyaltyTransactions.operationType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [],
      };
      responseTransactionType.data.forEach((it) => {
        transactionType["options"].push({
          label: it,
          value: it,
        });
      });

      filters.unshift(transactionType);
    }

    const activeLevelGroups = await assignRewardService.getActiveProgramLoyalty()
    if (activeLevelGroups && activeLevelGroups.length == 1) {
      const gameDomains = {
        name: "level-group-name",
        label: "label.individualplayer.loyaltyTransactions.gameDomain",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [],
      };
      activeLevelGroups[0].levelGroups.forEach((it) => {
        gameDomains["options"].push({
          label: it.description,
          value: it.name,
        });
      });

      filters.unshift(gameDomains);
    }

    return filters;
  }

  async getTransactions(
    contractId,
    searchTerms
  ) {
    const url = `/gov/api/rest/v1/player-transaction/${contractId}/information`;
    const queryParam = searchTerms
      ? searchUtils.getQueryParam(searchTerms, false, false)
      : "";

    const response = await axios.get(url+queryParam);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for loyalty transactions");
  }
}
const loyaltyTransactionsService = new LoyaltyTransactionsService();
export default loyaltyTransactionsService;
