import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { Form, Formik } from "formik";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { pwChangeHandler } from "../../store/authentication/actions";
import { useTranslator } from "../../utilities/hooks/useTranslator";

const ChangePassword = ({
  handleCloseModal,
  user,
  pwChangeHandler,
  result,
}) => {
  const t = useTranslator();

  const matchError = t({
    needle: "validation.match.password",
    label: "The passwords do not match, please try again",
  });

  return (
    <Fragment>
      <div className="ChangePassword">
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmnewPassword: "",
          }}
          validationSchema={Yup.object().shape({
            oldPassword: Yup.string().required(
              t({ needle: "errors.oldPasswordRequired" })
            ),
            newPassword: Yup.string().required(
              t({ needle: "errors.newPasswordRequired" })
            ),
            confirmnewPassword: Yup.string()
              .oneOf([Yup.ref("newPassword"), null], matchError)
              .required(t({ needle: "errors.confNewPasswordRequired" })),
          })}
          onSubmit={(values) => {
            pwChangeHandler(
              values.oldPassword,
              values.newPassword,
              values.confirmnewPassword,
              handleCloseModal
            );
          }}
        >
          {({ values, errors, status, handleChange, handleBlur, touched }) => (
            <Form>
              <div className="input-text">
                {t({
                  needle: "label.enterNewPass",
                })}
                <b>{user.username}</b>
              </div>
              <Box p={2}>
                <TextField
                  fullWidth={true}
                  name="oldPassword"
                  type="password"
                  label={t({
                    needle: "label.old_password",
                    label: "Old Password",
                  })}
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.oldPassword && errors.oldPassword}
                  helperText={touched.oldPassword && errors.oldPassword}
                />
                <TextField
                  fullWidth={true}
                  name="newPassword"
                  type="password"
                  label={t({
                    needle: "label.new_password",
                    label: "New Password",
                  })}
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.confirmnewPassword === matchError
                      ? true
                      : touched.newPassword && errors.newPassword
                  }
                  helperText={touched.newPassword && errors.newPassword}
                />
                <TextField
                  fullWidth={true}
                  name="confirmnewPassword"
                  type="password"
                  label={t({
                    needle: "label.confirmNewPassword",
                    label: "Confirm New Password",
                  })}
                  value={values.confirmnewPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.confirmnewPassword && touched.confirmnewPassword
                  }
                  helperText={
                    touched.confirmnewPassword && errors.confirmnewPassword
                  }
                />
                {result && result.error ? (
                  <FormHelperText error={true}>
                    {t({
                      needle: result.error,
                      label: result.error_message,
                      variables: [result.error_params],
                    })}
                  </FormHelperText>
                ) : null}
              </Box>
              <DialogActions disableSpacing={true} style={{ padding: 0 }}>
                <Box display={"flex"} justifyContent={"flex-end"} py={1}>
                  <Box mr={1}>
                    <Button
                      variant={"outlined"}
                      color={"secondary"}
                      type="button"
                      onClick={() => handleCloseModal()}
                    >
                      {t({
                        needle: "button.cancel",
                      })}
                    </Button>
                  </Box>
                  <Box>
                    <Button type="submit" color={"primary"}>
                      {t({ needle: "button.save" })}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { result } = state.PwresetReducer;
  const { user } = state.AuthenticationReducer.session;
  return { result, user };
};

const mapDispatchToProps = {
  pwChangeHandler,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
