import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { ReactComponent as NotFoundIcon } from "../../Images/icon_404.svg";
import { withTranslate } from "../../utilities/hocs/withTranslate";
import { Button, Box, SvgIcon } from "@material-ui/core";

// Import Stylesheet(s)

// Import Icon(s)
import { Link } from "react-router-dom";

const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    "& .MuiButton-label": {
      textTransform: "uppercase",
    },
  },
});

class PageNotFound extends Component {
  render() {
    const { classes, t } = this.props;

    const fisrtMenuItem = sessionStorage.getItem("fisrtMenuItem");

    return (
      <Box
        className={classes.root}
        py={3}
        flexGrow={1}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
        justifyContent={"center"}
      >
        <Box fontSize={200} display={"flex"} height={"130px"}>
          <SvgIcon fontSize={"inherit"} component={NotFoundIcon} />
        </Box>
        <Box fontSize={34}>{t({ needle: "label.pageNotFound.title" })}</Box>
        <Box fontSize={20} textAlign={"center"}>
          {t({ needle: "label.pageNotFound.message" })}
        </Box>
        <Box fontSize={20} mt={3}>
          <Button
            color={"primary"}
            className={classes.button}
            component={Link}
            to={"/"}
          >
            {t({ needle: "label.pageNotFound.button" })}{" "}
            {t({ needle: fisrtMenuItem })}
          </Button>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { brandSelected, partnerSelected } = state.MainReducer;
  return {
    brandSelected,
    partnerSelected,
    ...state.PlayerReducer.bulkOperationList,
  };
};

export default connect(mapStateToProps)(
  withTranslate(withStyles(style)(PageNotFound))
);
