import { call, put, takeEvery } from "redux-saga/effects";
import {
  TASK_TYPES_REQUEST,
  TASK_TYPES_SUCCESS,
  TASK_TYPES_FAILURE,
} from "../constants";
import taskTypesService from "./task-Types-service";

const loadTaskTypes = function* (action) {
  try {
    const data = yield call(taskTypesService.getTypes,
    action.brandId,
    action.partnerId);
    yield put({
      type: TASK_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("task types", e);
    yield put({
      type: TASK_TYPES_FAILURE,
    });
  }
};

const TaskTipesSaga = function* () {
  yield takeEvery(TASK_TYPES_REQUEST, loadTaskTypes);
};

export default TaskTipesSaga;
