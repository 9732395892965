import React from "react";
import PropTypes from "prop-types";
import throttle from "lodash/throttle";

class DynamicOverflow extends React.Component {
  tabNode = {};
  tabs = {};

  constructor(props) {
    super(props);

    this.state = {
      numberOfVisibleElements: Infinity,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    /*
     * All elements in props.list are rendered on the screen.
     *
     * Then, the containerNode and tabNode are measured to calculate how many
     * elements we can display.
     *
     * If elements need to be hidden, everything is rerendered.
     */
    this.calculateSize();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.calculateSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  calculateSize = () => {
    const { tabSpacing = 0 } = this.props;
    const containerWidth = this.containerNode && this.containerNode.clientWidth;

    if (containerWidth === 0 || Object.keys(this.tabNode).length === 0) {
      setTimeout(() => {
        this.calculateSize();
      }, 25);
    }
    let maximumChildrenAllowed = 0;
    const currentChildrenCount = this.props.list({}).length;

    let numberOfVisibleElements = Infinity;
    let tabWidths = 0;
    let isFull = false;

    Object.entries(this.tabNode).forEach(([key, node]) => {
      if (node.clientWidth > 0) {
        this.tabs[key] = node.clientWidth;
      }
    });
    if (Object.keys(this.tabs).length === currentChildrenCount) {
      Object.entries(this.tabs).forEach(([key, nodeWidth]) => {
        const value = nodeWidth + tabSpacing;
        if (!isFull && value + tabWidths < containerWidth) {
          maximumChildrenAllowed++;
          tabWidths += value;
        } else {
          isFull = true;
        }
      });
    }

    if (
      maximumChildrenAllowed > 0 &&
      currentChildrenCount > maximumChildrenAllowed
    ) {
      // by default, one element is always shown
      if (tabWidths + 35 + tabSpacing > containerWidth) {
        maximumChildrenAllowed--;
      }
      numberOfVisibleElements = Math.max(maximumChildrenAllowed, 1);
    }

    if (
      this.state.containerWidth !== containerWidth ||
      this.state.numberOfVisibleElements !== numberOfVisibleElements
    ) {
      this.setState({ numberOfVisibleElements, containerWidth });
      this.props.visibleItems &&
        this.props.visibleItems(numberOfVisibleElements);
    }
  };

  handleResize = throttle(this.calculateSize, this.props.throttle);

  containerRef = (node) => {
    if (!this.containerNode) {
      this.containerNode = node;
    }
  };

  tabRef = (node) => {
    if (node != null) {
      this.tabNode[node.id] = node;
    }
  };

  render() {
    const { numberOfVisibleElements } = this.state;
    const { list, children } = this.props;
    const { containerRef, tabRef } = this;
    const elements = list({ tabRef });
    const visibleElements = elements.slice(0, numberOfVisibleElements);
    const overflowElements = elements.slice(numberOfVisibleElements);

    return children({
      visibleElements,
      overflowElements,
      containerRef,
    });
  }
}

DynamicOverflow.propTypes = {
  children: PropTypes.func,
  list: PropTypes.func,
  throttle: PropTypes.number,
};

DynamicOverflow.defaultProps = {
  throttle: 200,
};

export default DynamicOverflow;
