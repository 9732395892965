import axios from "axios";

class ComunicationHistoryService {
  async getComunicationHistory(
    contractId,
    page,
    size,
    orderBy
  ) {
    let url = `/gov/api/rest/v1/contracts/${contractId}/dlv_notifications?page-number=${page}&page-size=${size}`;
    if(orderBy && orderBy.field && orderBy.order){
      orderBy.field = orderBy.field.split(".")[orderBy.field.split(".").length -1]
      url = url + `&sort=${orderBy.field}&sort-mode=${orderBy.order}`
    }
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getComunicationHistoryDetails(
    extNotificationKey,
    extContactKey,
    brand,
    partner,
    channelName
  ) {
    extNotificationKey = encodeURIComponent(extNotificationKey);
    let url = `/gov/api/rest/v1/communications/dlv_message?extNotificationKey=${extNotificationKey}&contactTypeId=2&extContactKey=${extContactKey}&brand=${brand}&partner=${partner}&channelName=${channelName}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }
}

const comunicationHistoryService = new ComunicationHistoryService();
export default comunicationHistoryService;
