import { Box } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { useField } from "formik";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import AdvancedMomentUtils from "../../AdvancedMomentUtils";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import DatePickerField from "./date-picker-field";
import FormLabel from "./form-label";

class CustomLocalizedUtils extends AdvancedMomentUtils {
  getCalendarHeaderText(date) {
    return date.format("MMMM");
  }
}
const AnniversaryField = ({
  isConditional = false,
  placeholder,
  optional,
  label,
  tooltip,
  dateType = "date",
  ...props
}) => {
  const t = useTranslator();
  const [field] = useField(props);
  const { locale } = useSelector((state) => state.MainReducer);

  const format = t({ needle: "label.anniversaryFormat" });
  const plchld = placeholder
    ? typeof placeholder === "string"
      ? t({ needle: placeholder })
      : placeholder
    : format;

  return (
    <Box>
      <MuiPickersUtilsProvider
        utils={CustomLocalizedUtils}
        libInstance={moment}
        locale={locale}
      >
        <DatePickerField
          {...field}
          format={format}
          {...props}
          label={
            label ? (
              <FormLabel optional={optional} label={label} tooltip={tooltip} />
            ) : null
          }
          placeholder={plchld}
          ToolbarComponent={(props) => (
            <div>
              <PickerToolbar
                title={props.title}
                isLandscape={props.isLandscape}
                {...props}
              >
                <ToolbarButton
                  variant="h4"
                  selected={true}
                  label={props.date.locale(locale).format("ddd, MMM DD")}
                  {...props}
                />
              </PickerToolbar>
            </div>
          )}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default AnniversaryField;
