import {
  FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_REQUEST,
  FINANCIAL_LIMITS_SAVE_CONFIGURATION_PROFILES_REQUEST,
  FINANCIAL_LIMITS_APPROVE_REJECT_PROFILES_REQUEST,
  FINANCIAL_LIMIT_HISTORY_REQUEST,
} from "../../../../constants";

const getFinancialLimitsAssociatedProfilesHandler = (
  contractId,
  brand,
  partner,
  divisorAmount
) => {
  return {
    type: FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST,
    contractId,
    brand,
    partner,
    divisorAmount,
  };
};

const editFinancialLimitsProfileHandler = (
  contractId,
  brand,
  partner,
  divisorAmount,
  profile
) => {
  return {
    type: FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_REQUEST,
    contractId,
    brand,
    partner,
    divisorAmount,
    profile,
  };
};

const enableDisableFinancialLimitsProfileHandler = (
  contractId,
  brand,
  partner,
  profile,
  divisorAmount
) => {
  return {
    type: FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_REQUEST,
    contractId,
    brand,
    partner,
    profile,
    divisorAmount,
  };
};

const getFinancialLimitsNotAssociatedProfilesHandler = (
  contractId,
  brand,
  partner,
  isModalOpen
) => {
  return {
    type: FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_REQUEST,
    contractId,
    brand,
    partner,
  };
};

const saveConfigurationProfilesHandler = (
  contractId,
  brand,
  partner,
  currency,
  right,
  divisorAmount
) => {
  return {
    type: FINANCIAL_LIMITS_SAVE_CONFIGURATION_PROFILES_REQUEST,
    contractId,
    brand,
    partner,
    currency,
    right,
    divisorAmount,
  };
};

const approveRejectProfile = (
  contractId,
  brand,
  partner,
  action,
  profileId,
  divisorAmount,
  cb
) => {
  return {
    type: FINANCIAL_LIMITS_APPROVE_REJECT_PROFILES_REQUEST,
    contractId,
    brand,
    partner,
    action,
    profileId,
    divisorAmount,
    cb,
  };
};

const getFinancialLimitHistory = (
  brandId,
  partnerId,
  contractId,
  profileType
) => {
  return {
    type: FINANCIAL_LIMIT_HISTORY_REQUEST,
    brandId,
    partnerId,
    contractId,
    profileType,
  };
};

export {
  getFinancialLimitsAssociatedProfilesHandler,
  editFinancialLimitsProfileHandler,
  enableDisableFinancialLimitsProfileHandler,
  getFinancialLimitsNotAssociatedProfilesHandler,
  saveConfigurationProfilesHandler,
  approveRejectProfile,
  getFinancialLimitHistory,
};
