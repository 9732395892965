import { select, takeLatest } from "@redux-saga/core/effects";
import moment from "moment";
import { call, put } from "redux-saga/effects";
import {
  CLOSING_ACCOUNTS_FILTERS_FAILURE,
  CLOSING_ACCOUNTS_FILTERS_REQUEST,
  CLOSING_ACCOUNTS_FILTERS_SUCCESS,
  CLOSING_ACCOUNTS_PLAYERS_DETAIL_FAILURE,
  CLOSING_ACCOUNTS_PLAYERS_DETAIL_SUCCESS,
  CLOSING_ACCOUNTS_PLAYERS_LIST_FAILURE,
  CLOSING_ACCOUNTS_PLAYERS_LIST_RELOAD,
  CLOSING_ACCOUNTS_PLAYERS_LIST_REQUEST,
  CLOSING_ACCOUNTS_PLAYERS_LIST_SUCCESS,
  CLOSING_ACCOUNTS_PLAYER_DETAIL_REQUEST,
  CLOSING_ACCOUNTS_RETRY_TASK_FAILURE,
  CLOSING_ACCOUNTS_RETRY_TASK_REQUEST,
  CLOSING_ACCOUNTS_RETRY_TASK_SUCCESS,
} from "../constants";
import closingAccountsService from "./closing-accounts-service";

const loadListFilter = function* (action) {
  try {
    const data = yield call(closingAccountsService.getListFilter);
    yield put({
      type: CLOSING_ACCOUNTS_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("closing accounts filters", e);
    yield put({
      type: CLOSING_ACCOUNTS_FILTERS_FAILURE,
    });
  }
};

const getClosingAccounts = function* (action) {
  try {
    let searchTerms = [];
    const fltr = { ...action.filters };

    if (action.filters) {
      let tasks_ss = [];

      if (fltr?.lrtCheckClosureStatus) {
        tasks_ss.push("3-" + fltr?.lrtCheckClosureStatus);
        delete fltr?.lrtCheckClosureStatus;
      }
      if (fltr?.lrtWithdrawalStatus) {
        tasks_ss.push("4-" + fltr?.lrtWithdrawalStatus);
        delete fltr?.lrtWithdrawalStatus;
      }
      if (fltr?.lrtConfirmClosureStatus) {
        tasks_ss.push("5-" + fltr?.lrtConfirmClosureStatus);
        delete fltr?.lrtConfirmClosureStatus;
      }

      tasks_ss = tasks_ss.join(" AND ");
      searchTerms = {
        ...fltr,
        tasks_ss: tasks_ss ? `(${tasks_ss})` : "",
      };

      if (!fltr.reservedDatePeriod?.from && !fltr.reservedDatePeriod?.to) {
        searchTerms.reserve_closing_date_dt = "";
      } else {
        let reservedDatePeriodFrom = fltr.reservedDatePeriod?.from
          ? moment(fltr.reservedDatePeriod?.from).utc().format()
          : "*";
        let reservedDatePeriodTo = fltr.reservedDatePeriod?.to
          ? moment(fltr.reservedDatePeriod?.to).utc().format()
          : "*";

        searchTerms.reserve_closing_date_dt = `[${reservedDatePeriodFrom} TO ${reservedDatePeriodTo}]`;
      }

      delete searchTerms["reservedDatePeriod"];
    }

    const data = yield call(
      closingAccountsService.getClosingAccounts,
      action.brand.id,
      action.partner.id,
      searchTerms,
      action.query
    );

    yield put({
      type: CLOSING_ACCOUNTS_PLAYERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getClosingAccounts", e);
    yield put({
      type: CLOSING_ACCOUNTS_PLAYERS_LIST_FAILURE,
    });
  }
};

const reloadPlayersList = function* () {
  const lastRequest = yield select(
    (state) => state.ClosingAccountsReducer.closingAccountList.lastRequest
  );
  if (lastRequest) {
    yield put(lastRequest);
  }
};

const getDetail = function* (action) {
  try {
    const data = yield call(
      closingAccountsService.getDetail,
      action.brand_code_i,
      action.partner_code_i,
      action.id,
      action.contract_identity_s
    );
    yield put({
      type: CLOSING_ACCOUNTS_PLAYERS_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getDetail", e);
    yield put({
      type: CLOSING_ACCOUNTS_PLAYERS_DETAIL_FAILURE,
    });
  }
};

const retryTask = function* (action) {
  try {
    const data = yield call(
      closingAccountsService.retryTask,
      action.taskId,
      action.subTasks
    );
    yield put({
      type: CLOSING_ACCOUNTS_RETRY_TASK_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("retryTask", e);
    yield put({
      type: CLOSING_ACCOUNTS_RETRY_TASK_FAILURE,
    });
  }
};

const ClosingAccountsSaga = function* ClosingAccountsSaga() {
  yield takeLatest(CLOSING_ACCOUNTS_FILTERS_REQUEST, loadListFilter);
  yield takeLatest(CLOSING_ACCOUNTS_PLAYERS_LIST_REQUEST, getClosingAccounts);
  yield takeLatest(CLOSING_ACCOUNTS_PLAYERS_LIST_RELOAD, reloadPlayersList);
  yield takeLatest(CLOSING_ACCOUNTS_PLAYER_DETAIL_REQUEST, getDetail);
  yield takeLatest(CLOSING_ACCOUNTS_RETRY_TASK_REQUEST, retryTask);
};

export default ClosingAccountsSaga;
