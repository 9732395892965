import axios from "axios";
import searchUtils from "../../../utilities/searchUtils";
import { CRITERIA_TYPE_SELECT, CRITERIA_TYPE_DATERANGE } from "../../constants";

class PlayerGroupsService {
  async loadUploadHistorySearchCriteria() {
    const filter = [
      {
        name: "processName",
        label: "label.uploadGroupHistory.process.name",
        selected: true,
      },
      {
        name: "status",
        label: "label.uploadGroupHistory.process.status",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: [
          { label: "label.status.error", value: "Error" },
          { label: "label.status.rollbacked", value: "Rollbacked" },
          { label: "label.status.running", value: "Running" },
          { label: "label.status.completed", value: "Completed" },
        ],
      },
      {
        name: "startDate",
        label: "label.startDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "endDate",
        label: "label.endDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
    ];

    let url = "/gov/api/rest/v1/contactTypeList";
    const response = await axios.get(url);
    if (response && response.data) {
      let contractType = {
        name: "contactTypeId",
        label: "label.uploadGroupHistory.contactTypeId",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: [],
      };
      response.data.forEach((it) => {
        contractType["options"].push({ label: it.name, value: it.value });
      });
      filter.push(contractType);
    }

    let filters = { filters: filter, types: response.data };
    return filters;
  }
  async loadUploadHistory({ searchTerms }) {
    const response = await axios.get(
      "/gov/api/rest/v1/playergroup/find_import_data/"
    );
    if (response && response.data) {
      return searchUtils.search(response.data, searchTerms);
    }
    throw Error("Invalid response for player groups upload history");
  }

  async loadUploadHistoryView(process, version, query, brandId, partnerId) {
    let url = `/gov/api/rest/v1/playergroup/find_import_data_error?process=${process}&version=${version}&pageSize=${query.pageSize}&page=${query.page}&brandId=${brandId}&partnerId=${partnerId}`

    if (query.orderBy) {
      url = `${url}&sort=${query.orderBy.field}&sortMode=${query.orderBy.order}`;
    }

    const response = await axios.get(url);

    if (response && response.data) {
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }
    throw Error("Invalid response for player group upload history detail");
  }

  async playerGroupList(brand, partner, force) {
    let url = `/gov/api/rest/v1/playergroup/get_playerGroups/${brand}/${partner}`;
    if (force) {
      url += `?_=${new Date().getTime()}`;
    }
    const response = await axios.get(url);
    if (response && response.data && response.data.collection) {
      return response.data.collection;
    }
    throw Error("Invalid response for player group list");
  }

  async deletePlayerGroup(profileName, brand, partner) {
    const response = await axios.delete(
      `/gov/api/rest/v1/playergroup/get_playerGroups/${profileName}/${brand}/${partner}`
    );

    if (response == null || response.status !== 204) {
      throw Error("Invalid response for delete player group");
    }
  }

  async playerGroupView(contractId, brand, partner, query) {
    let url = `/gov/api/rest/v1/playergroup/get_playerDetails/${contractId}/${brand}/${partner}?pageNumber=${query.page}&pageSize=${query.pageSize}`;

    if (query.orderBy) {
      url = `${url}&sort=${query.orderBy.field}&sortMode=${query.orderBy.order}`;
    }
    const response = await axios.get(url);

    if (response && response.data && response.data.collection) {
      const totalCount = response.data.totalCount;

      return {
        data: response.data.collection,
        page: query.page,
        totalCount: totalCount,
      };
    }
    throw Error("Invalid response for player group view");
  }

  async addPlayerGroup(action) {
    let dataForm = new FormData();
    dataForm.set("name", action.name);
    dataForm.append("contactTypePlayerGroup", action.contractType);
    dataForm.append("brand", action.brand.id);
    dataForm.append("partner", action.partner.id);
    dataForm.append("fileName", action.file);
    const response = await axios({
      method: "post",
      url: "/gov/api/rest/v1/playergroup/create_player_group/",
      data: dataForm,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response == null || response.status !== 204) {
      throw Error("Invalid response for player group view");
    }
  }
}

const playerGroupsService = new PlayerGroupsService();
export default playerGroupsService;
