import React from "react";
// Import Component(s)
import Table from "../../../../../Layouts/Table/Table.js";
import { connect } from "react-redux";
import { getNoteHistory } from "../../../../../store/players/individual-player/player-notes/actions";
import Box from "@material-ui/core/Box";

function NotesHistory({ noteId, columns, data, loading, loadHistory }) {
  React.useEffect(() => {
    if (noteId) {
      loadHistory(noteId);
    }
  }, [noteId, loadHistory]);

  return (
    <Box px={2} py={1}>
      <Table
        title="label.player.playerNotes.noteHistoryTitle"
        resize={false}
        pagination={true}
        exportButton={false}
        type={"advanced"}
        columns={columns}
        data={data}
        isLoading={loading}
      />
    </Box>
  );
}

const mapStateToProps = (state) => {
  const { data, columns, loading } = state.PlayerNotesReducer.playerNote;
  return { data, columns, loading };
};
const mapDispatchToProps = {
  loadHistory: getNoteHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesHistory);
