import { CircularProgress } from "@material-ui/core";
import React from "react";

//Import Stylesheet(s)
import Box from "@material-ui/core/Box";
import useTheme from "@material-ui/core/styles/useTheme";

function SpinnerIndicator() {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      position={"absolute"}
      zIndex={9999}
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgcolor={theme.palette.background.default + "80"}
    >
      <CircularProgress disableShrink />
    </Box>
  );
}

export default SpinnerIndicator;
