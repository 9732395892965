import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { exportFn } from "../../Layouts/Table/utils";
import { finishExportAll } from "../../store/export/actions";
import { useSession } from "./useSession";
import { useTranslator } from "./useTranslator";

export const useExport = (exportConf) => {
  const { divisorAmount, currencySymbol } = useSession();
  const dispatch = useDispatch();
  const intl = useIntl();
  const t = useTranslator();

  React.useEffect(() => {
    if (exportConf?.exporting && exportConf?.tableData) {
      exportFn({
        ...exportConf,
        currencySymbol,
        intl,
        divisorAmount,
        t,
      });
      dispatch(finishExportAll());
    }
  }, [exportConf?.tableData]);

  return {};
};
