import { useSelector } from "react-redux";
import translateService from "../translate";

export const useTranslator = () => {
  const i18n = useSelector((state) => state.MainReducer.i18n);
  return ({ needle, label, variables, avoidLowerCase = false }) => {
    if (needle === "") {
      return "";
    }
    return translateService.translate({
      i18n,
      needle,
      label,
      variables,
      avoidLowerCase,
    });
  };
};
