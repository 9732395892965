import axios from "axios";

class CurrenciesService {
  async getCurrencies(brand, partner) {
    let url = `/gov/api/rest/v1/gadm/currencies?brand=${brand}&partner=${partner}`;
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getCurrenciesFullData() {
    let url = "/gov/api/rest/v1/ams/currencies/full-data?state=1&format=2";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getPamCurrencies() {
    let url = "/gov/api/rest/v1/currencies";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async getLoyCurrencies(brand, partner, rewardType) {
    const response = await axios.get(
      `/gov/api/rest/v2/loy/currency?brandId=${brand}&partnerId=${partner}&rewardType=${rewardType}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for loy currency");
  }
}

const currenciesService = new CurrenciesService();
export default currenciesService;
