import axios from "axios";

class PlayerNotesService {
  async getPlayerNotes(contractId, brand, partner) {
    let url = `/gov/api/rest/v1/contracts/${contractId}/notes?brand=${brand.id}&partner=${partner.id}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data.notes;
    }
    throw Error("Invalid response for player notes list");
  }

  async getPriorities() {
    let url = "/gov/api/rest/v1/notes/priorities";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player note priorities");
  }

  async getTypes() {
    let url = "/gov/api/rest/v1/notes/noteTypes";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player note types");
  }

  async getNote(noteId) {
    let url = `/gov/api/rest/v1/notes/history/${noteId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player note with id " + noteId);
  }

  async deleteNote(contractId, noteId) {
    let url = `/gov/api/rest/v1/contracts/${contractId}/notes/${noteId}`;
    const response = await axios.delete(url);
    if (!response || response.status !== 200) {
      throw Error("Unable to delete player note with id " + noteId);
    }
  }

  async saveNote(brand, partner, contractId, note) {
    let validResponseStatus = 200;
    let url = `/gov/api/rest/v1/contracts/${contractId}/notes/`;
    note.brandCode = brand.id;
    note.partnerCode = partner.id;
    if (note.id == null) {
      note.contractId = contractId;
    } else {
      url += note.id;
    }
    const response = await axios({
      method: note.id ? "put" : "post",
      url,
      data: note,
    });
    if (!response || response.status !== validResponseStatus) {
      throw Error("Unable to save player note");
    }
  }
}

const playerNotesService = new PlayerNotesService();
export default playerNotesService;
