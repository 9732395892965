import { call, put } from "redux-saga/effects";
import {
  APPROVE_REJECT_FAILURE,
  APPROVE_REJECT_REQUEST,
  APPROVE_REJECT_SUCCESS,
  BULK_OPERATION_LIST_FAILURE,
  BULK_OPERATION_LIST_RELOAD,
  BULK_OPERATION_LIST_REQUEST,
  BULK_OPERATION_LIST_SEARCH_CRITERIA_FAILURE,
  BULK_OPERATION_LIST_SEARCH_CRITERIA_REQUEST,
  BULK_OPERATION_LIST_SEARCH_CRITERIA_SUCCESS,
  BULK_OPERATION_LIST_SUCCESS,
  BULK_OPERATION_LIST_VIEW_FAILURE,
  BULK_OPERATION_LIST_VIEW_RELOAD,
  BULK_OPERATION_LIST_VIEW_REQUEST,
  BULK_OPERATION_LIST_VIEW_SUCCESS,
  BULK_OPERATION_TRANSACTIONS_FAILURE,
  BULK_OPERATION_TRANSACTIONS_REQUEST,
  BULK_OPERATION_TRANSACTIONS_SUCCESS,
  GO_TO_ERROR_PAGE,
} from "../../../constants";
import bulkOperationListService from "../bulk-operation-list/bulk-operation-list-service";
import { delay, select, takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadBulkOperationListCriteriaFields = function* (action) {
  try {
    const data = yield call(
      bulkOperationListService.getBulkOperationListSearchCriteria,
      action.brand,
      action.partner
    );
    yield put({
      type: BULK_OPERATION_LIST_SEARCH_CRITERIA_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: BULK_OPERATION_LIST_SEARCH_CRITERIA_FAILURE,
    });
  }
};

const bulkOperationList = function* (action) {
  try {
    const data = yield call(
      bulkOperationListService.bulkOperationList,
      action.brand,
      action.partner,
      action.searchTerms
    );
    yield put({
      type: BULK_OPERATION_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("bulkOperationList", e);
    yield put({
      type: BULK_OPERATION_LIST_FAILURE,
    });
  }
};

const bulkOperationTransactions = function* (action) {
  try {
    const data = yield call(
      bulkOperationListService.bulkOperationTransaction,
      action.massiveId,
      action.query
    );
    yield put({
      type: BULK_OPERATION_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("bulkOperationList", e);
    yield put({
      type: BULK_OPERATION_TRANSACTIONS_FAILURE,
    });
  }
};

const reloadBulkOperationList = function* () {
  const lastRequest = yield select(
    (state) => state.BulkOperationListReducer.bulkOperationList.lastRequest
  );
  yield put(lastRequest);
};

const reloadBulkOperation = function* () {
  const lastRequest = yield select(
    (state) => state.BulkOperationListReducer.operationsView.lastRequest
  );
  if (lastRequest) {
    yield put(lastRequest);
  }
};

const approveRejectReward = function* (action) {
  try {
    const data = yield call(
      bulkOperationListService.approveRejectReward,
      action.rewardId,
      action.isApproved
    );
    yield put({
      type: APPROVE_REJECT_SUCCESS,
      payload: data,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: action.isApproved
            ? "label.massiveRewards.approved"
            : "label.massiveRewards.rejected",
          variables: [action.requestName],
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: BULK_OPERATION_LIST_RELOAD,
    });

    yield put({
      type: BULK_OPERATION_LIST_VIEW_RELOAD,
    });

    const deskData = yield call(
      bulkOperationListService.deskByApprovalId,
      action.rewardId
    );

    yield call(bulkOperationListService.desk, deskData);
  } catch (e) {
    console.error("bulkOperationList", e);
    yield put({
      type: APPROVE_REJECT_FAILURE,
    });
  }
};

const bulkOperation = function* (action) {
  const { fullData, idKey, loading } = yield select(
    (state) => state.BulkOperationListReducer.bulkOperationList
  );
  if (loading) {
    yield delay(100);
    yield call(bulkOperation, action);
  } else {
    let data = fullData.find((it) => +it[idKey] === +action.id);
    if (data != null) {
      yield put({
        type: BULK_OPERATION_LIST_VIEW_SUCCESS,
        payload: data,
      });
    } else {
      console.error(
        "bulkOperation",
        Error("Error getting bulk operation with id " + action.id)
      );
      yield put({
        type: BULK_OPERATION_LIST_VIEW_FAILURE,
      });
      yield put({
        type: GO_TO_ERROR_PAGE,
        payload: action.id,
      });
    }
  }
};

const BulkOperationListSaga = function* BulkOperationListSaga() {
  yield takeLatest(
    BULK_OPERATION_LIST_SEARCH_CRITERIA_REQUEST,
    loadBulkOperationListCriteriaFields
  );
  yield takeLatest(BULK_OPERATION_LIST_REQUEST, bulkOperationList);
  yield takeLatest(BULK_OPERATION_LIST_RELOAD, reloadBulkOperationList);
  yield takeLatest(BULK_OPERATION_LIST_VIEW_REQUEST, bulkOperation);
  yield takeLatest(BULK_OPERATION_LIST_VIEW_RELOAD, reloadBulkOperation);
  yield takeLatest(
    BULK_OPERATION_TRANSACTIONS_REQUEST,
    bulkOperationTransactions
  );
  yield takeLatest(APPROVE_REJECT_REQUEST, approveRejectReward);
};

export default BulkOperationListSaga;
