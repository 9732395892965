import {
    SILENT_AUTHENTICATION_PAGE_FAILURE,
    SILENT_AUTHENTICATION_PAGE_SUCCESS,
} from "../constants";

const initialState = { pageUrl: "" };
const SilentLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case SILENT_AUTHENTICATION_PAGE_SUCCESS:
            return {
                ...state,
                pageUrl : action.payload,
            };
        case SILENT_AUTHENTICATION_PAGE_FAILURE:
            return {
                ...state,
                pageUrl : null
            };
        default:
            return state;
    }
};

export default SilentLoginReducer;
