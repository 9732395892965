import { LOAD_EXTERNAL_LOGIN_PLUGINS_SUCCESS } from "../constants";

const INITIAL_STATE = {
  externalLoginPlugins: [],
};

const ExternalLoginPluginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_EXTERNAL_LOGIN_PLUGINS_SUCCESS:
      return {
        ...state,
        externalLoginPlugins: action.payload,
      };
    case "LOAD_WHOLE_EXTERNAL_LOGIN_PLUGINS_SUCCESS":
      return {
        ...state,
        brandAndPartnerList: action.payload,
      };
    default:
      return state;
  }
};

export default ExternalLoginPluginReducer;
