import axios from "axios";

class StatusHistoryService {
  async loadStatusHistory(contractId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/smsconsole/contracts/${contractId}/customerAccountUpdateData?brand=${brandId}&partner=${partnerId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      var data = [];

      response.data.forEach((f) => {
        var list = f.customerAccountUpdateData.map((elem) => {
          elem.customerAccountUpdateDate = f.customerAccountUpdateDate;
          return elem;
        });
        data.push(...list);
      });

      return data;
    }
    throw Error("Invalid data");
  }
}

const statusHistoryService = new StatusHistoryService();
export default statusHistoryService;
