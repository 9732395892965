import axios from "axios";

class ExternalLinkedAccountsService {
  async loadLinkedAccounts(brand, partner, contractId, contractType) {
    let url = `/gov/api/rest/v2/contracts/${contractId}/contractType/${contractType}/external_account_management`;

    const response = await axios.get(url, {
      params: {
        brandCode: brand.id,
        partnerCode: partner.id,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for external linked accounts");
  }

  async unlinkLinkedAccounts(
    brand,
    partner,
    contractId,
    externalAccountId,
    externalAccountTypeId
  ) {
    let url = `/gov/api/rest/v2/contracts/${contractId}/unlink_account`;
    const body = {
      id: contractId,
      brandCode: brand.id,
      partnerCode: partner.id,
      contractId,
      externalAccountId,
      externalAccountTypeId,
    };
    const response = await axios.put(url, body);
    if (response && response.status !== 204) {
      throw Error("Invalid response for external account unlink");
    }
  }

  async editLinkedAccounts(brand, partner, contractId, contractType, body) {
    let url = `/gov/api/rest/v2/contracts/${contractId}/contractType/${contractType}/external_account_attributes`;

    body.contractId = contractId;
    body.contractType = contractType;
    body.brandCode = brand.id;
    body.partnerCode = partner.id;

    const response = await axios.put(url, body);
    if (response && response.status !== 204) {
      throw Error("Invalid response for external linked account update");
    }
  }
}

const externalLinkedAccountsService = new ExternalLinkedAccountsService();
export default externalLinkedAccountsService;
