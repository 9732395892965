import axios from "axios";

class GamePlatformsService {
  async getPlatforms(queryParams) {
    let url = "/gov/api/rest/v2/game-platforms";

    if (queryParams) {
      url += queryParams;
    }
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response");
  }

  async getSimplePlatforms() {
    let url = "/gov/api/rest/v2/loy/gameDomainSimple";

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response");
  }

  async getProgramPlatforms(brand, partner) {
    let url = `/gov/api/rest/v2/loy/program/gameDomain?brandId=${brand}&partnerId=${partner}`;

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response");
  }
}

const gamePlatformsService = new GamePlatformsService();
export default gamePlatformsService;
