import {
  ADD_BOOKMARKS_REQUEST,
  REMOVE_BOOKMARKS_REQUEST,
  GET_BOOKMARKS_REQUEST,
} from "../constants";

const addBookmarksHandler = (id) => {
  return {
    type: ADD_BOOKMARKS_REQUEST,
    payload: {
      id,
    },
  };
};

const removeBookmarksHandler = (id) => {
  return {
    type: REMOVE_BOOKMARKS_REQUEST,
    payload: {
      id,
    },
  };
};

const getBookmarksHandler = () => {
  return {
    type: GET_BOOKMARKS_REQUEST,
  };
};

export { addBookmarksHandler, removeBookmarksHandler, getBookmarksHandler };
