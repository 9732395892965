const palette = {
  bg: {
    page: "#0D0D0D",
    primary1: "#212121",
    primary2: "#39CEFF",
    primary2Accent1: "#5C6876",
    primary2Accent2: "#424850",
    primary2Accent3: "#3C3C3C",
    secondary1: "#0067ED",
    secondary2: "#FFBB00",
    secondary2Accent1: "#FFBB001A",
    secondary2Accent2: "#FF671F",
    iconsLabels: "#F0F9FF",
    success: "#118901",
    failure: "#EB0000",
    separatorsTags: "#F0F9FF",
  },
  text: {
    primary1: "#FFFFFF",
    primary2: "#FFFFFF",
    secondary: "#FFFFFF",
    secondary2: "#000000",
    textInfo1: "#BCDFFF",
    textInfo2: "#BCD94A",
  },
};
const darkHCPalette = {
  type: "dark",
  statusColors: {
    1: palette.bg.secondary1,
    2: palette.bg.success,
    3: palette.bg.secondary2,
    4: palette.bg.failure,
    5: "#ED2E2E",
    6: "#A5ACB1",
    7: palette.bg.failure,
    8: "#9F9F9F",
    9: palette.bg.failure,
    10: palette.bg.secondary1,
    11: palette.bg.success,
    12: palette.bg.secondary2,
    13: "#E3D800",
    14: "#800080",
    15: "#ED2E2E",
    16: "#82c884",
  },
  //ATTENTION, TEMPORARY COLORS DUE TO A BUG IN CHART LIBRARY WE USE SAME COLORS REGARDLESS THE THEME
  chartColors: [
    "#0C51A1",
    "#1B9DDB",
    "#FF671F",
    "#FFA300",
    "#4DAF4F",
    "#954DAF",
    "#086533",
    "#FF0000",
    "#2DAABA",
    "#DEB400",
    "#5AD3FF",
    "#912D2D",
  ],
  // chartColors:[
  //   palette.bg.secondary1,
  //   palette.bg.primary2,
  //   palette.bg.secondary2Accent2,
  //   palette.bg.secondary2,
  //   palette.bg.success,
  //   "#B078C4",
  //   "#0DA554",
  //   "#FF4242",
  //   "#2DAABA",
  //   "#DEB42D",
  //   "#5AD3FD",
  //   "#D57676",
  // ],
  envColors: {
    DEV: {
      background: palette.bg.secondary2,
      color: palette.text.secondary2,
    },
    QA: {
      background: palette.bg.secondary2Accent2,
      color: palette.text.secondary,
    },
    SIT: {
      background: palette.bg.primary2,
      color: palette.text.secondary2,
    },
    CAT: {
      background: palette.bg.secondary1,
      color: palette.text.secondary,
    },
    UAT: {
      background: palette.bg.secondary1,
      color: palette.text.secondary,
    },
    DEMO: {
      background: palette.bg.success,
      color: palette.text.secondary,
    },
    default: {
      background: palette.bg.iconsLabels,
      color: palette.text.secondary,
    },
  },
  shadows: [
    "rgba(255,255,255,0.2)",
    "rgba(255,255,255,0.12)",
    "rgba(255,255,255,0.14)",
  ],
  shadow: "#000000",
  border: {
    arrow: "#fff",
    input: "#F0F9FF",
  },
  primary: {
    main: palette.bg.primary2,
    light: "#7986cb",
    dark: "#303f9f",
    contrastText: "#fff",
  },
  primary2: {
    main: "#fff",
  },
  secondary: {
    main: palette.bg.secondary1,
    light: "rgba(12, 81, 161, 0.15)",
  },
  success: {
    main: palette.bg.success,
  },
  error: {
    main: palette.bg.failure,
  },
  warning: {
    main: palette.bg.secondary2,
  },
  info: {
    main: palette.bg.primary2,
  },
  secondary2: {
    main: palette.bg.secondary2,
    light: palette.bg.secondary2Accent1,
    dark: palette.bg.secondary2Accent2,
  },
  buttons: {
    primary: palette.bg.primary2,
    primaryFocused: "#33B9E5",
    secondary: palette.bg.secondary1,
    secondaryFocused: "#005DD5",
    outlined: "#ffffff",
    outlinedFocused: "#FFFFFF1A",
    success: palette.bg.success,
    successFocused: "#0F7B01",
    failure: palette.bg.failure,
    failureFocused: "#D30000",
  },
  text: {
    primary: palette.text.primary1,
    primary2: palette.text.primary2,
    icon: palette.bg.iconsLabels,
    warning: "#FC4545",
    highlight: palette.bg.secondary2,
    secondary: "rgb(255, 255, 255)",
    secondary1: palette.text.secondary,
    secondary2: palette.text.secondary2,
    secondary3: palette.text.secondary2,
    paletteSecondary2: palette.bg.secondary1,
    disabled: palette.text.primary1,
    hint: "rgba(255, 255, 255, 0.6)",
    textInfo1: palette.text.textInfo1,
    textInfo2: palette.text.textInfo2,
  },
  background: {
    paper: palette.bg.primary1,
    default: palette.bg.page,
    primary2Accent1: palette.bg.primary2Accent1,
    primary2Accent2: palette.bg.primary2Accent2,
    primary2Accent3: palette.bg.primary2Accent3,
  },
  backgroundClickable: {
    paper: palette.bg.page,
  },
  svgIcon: {
    primary: palette.bg.primary2,
    typeString: "#7B9023",
    typeValue: "#407CB1",
  },
  action: {
    skeleton: "#595C5F",
    placeholder: "#FFFFFFCC",
    active: "#FFF",
    sortActive: "#71777B",
    hover: "rgba(255, 255, 255, 0.08)",
    hoverOpacity: 0.1,
    selected: "rgba(255, 255, 255, 0.16)",
    clickableSelected: "rgba(255, 163, 0, 0.15)",
    clickableSelectedColor: "#FFA300",
    selectedOpacity: 0.08,
    disabled: "rgba(255, 255, 255, 0.3)",
    disabledBackground: "#585757",
    disabledOpacity: 0.38,
    focus: "rgba(255, 255, 255, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
  divider: palette.bg.separatorsTags,
};

export default darkHCPalette;
