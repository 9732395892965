import axios from "axios";

import { call, put } from "redux-saga/effects";
import { takeEvery } from "@redux-saga/core/effects";
import {
  PLAYER_PAYMENT_SUCCESS,
  PLAYER_PAYMENT_FAILURE,
  PLAYER_PAYMENT_REQUEST,
} from "../../../constants";

const getPaymentUrl = async ({ brand, partner, userIp, contractId }) => {
  const response = await axios.get(
    `/gov/api/rest/v1/onepay?brand=${brand}&partner=${partner}&userIp=${userIp}&contractId=${contractId}`
  );

  if (response && response.data) {
    return response.data;
  }
  throw Error("Invalid response for player payment");
};

const plyPayment = function* (action) {
  try {
    const data = yield call(getPaymentUrl, action.payload);
    yield put({
      type: PLAYER_PAYMENT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("PlayerPayment", e);
    yield put({
      type: PLAYER_PAYMENT_FAILURE,
    });
  }
};

const PlayerPaymentSaga = function* playerPaymentSaga() {
  yield takeEvery(PLAYER_PAYMENT_REQUEST, plyPayment);
};

export default PlayerPaymentSaga;
