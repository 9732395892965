import {
  PLAYER_PAYMENT_SUCCESS,
  PLAYER_PAYMENT_FAILURE, PLAYER_PAYMENT_REQUEST, PLAYER_PAYMENT_CLEANUP,
} from "../../../constants";

const initialState = {
  payment: "",
  loading: false
};

const PlayerPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_PAYMENT_CLEANUP:
      return {
        ...state,
        payment: ""
      }
    case PLAYER_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        payment: ""
      }
    }
    case PLAYER_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        payment: action.payload,
      };
    }
    case PLAYER_PAYMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        payment: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default PlayerPaymentReducer;
