import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_ACTIVE_LEVEL_GROUPS_FAILED,
  GET_ACTIVE_LEVEL_GROUPS_REQUEST,
  GET_ACTIVE_LEVEL_GROUPS_SUCCESS
} from "../constants";
import assignRewardService from "./assign-rewward-service";


const getActiveLevelGroups = function* (action) {
  try {
    const data = yield call(
      assignRewardService.getActiveProgramLoyalty,
    );
    
    if(data && data.length == 1 && data[0].levelGroups)
      yield put({
        type: GET_ACTIVE_LEVEL_GROUPS_SUCCESS,
        payload: {activeLevelGroup: data[0].levelGroups, programId:data[0].programId},
      });
    
  } catch (e) {
    console.error("getActiveLevelGroups", e);
    yield put({
      type: GET_ACTIVE_LEVEL_GROUPS_FAILED,
    });
  }
};

const AssignRewardSaga = function* () {
  yield takeLatest(GET_ACTIVE_LEVEL_GROUPS_REQUEST, getActiveLevelGroups);
};

export default AssignRewardSaga;
