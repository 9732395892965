import { GAME_CODES_SUCCESS, GAMES_SUCCESS, GAMES_FAILURE } from "../constants";

const INITIAL_STATE = {
  games: [],
  gameCodes: [],
};

const GameCodesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GAME_CODES_SUCCESS:
      return {
        ...state,
        games: action.payload,
      };

    case GAMES_SUCCESS: {
      return {
        ...state,
        gameCodes: action.payload,
      };
    }

    case GAMES_FAILURE: {
      return {
        ...state,
        gameCodes: null,
      };
    }

    default:
      return state;
  }
};

export default GameCodesReducer;
