import {
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_FAILURE,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_SUCCESS,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_FAILURE,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_SUCCESS,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_FAILURE,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_SUCCESS,
  SEND_DIRECT_NOTIFICATION_FAILURE,
  SEND_DIRECT_NOTIFICATION_SUCCESS,
  TEMPLATE_VARIABLES_SUCCESS,
  TEMPLATES_SUCCESS,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";

const getStatusMapping = (status) => {
  let badgeMapped = {};
  switch (status) {
    case 10:
      badgeMapped.badgeId = "3";
      badgeMapped.badgeLabel = "sending";
      break;
    case 30:
      badgeMapped.badgeId = "2";
      badgeMapped.badgeLabel = "delivered";
      break;
    case 40:
      badgeMapped.badgeId = "4";
      badgeMapped.badgeLabel = "error";
      break;
    default:
  }
  return badgeMapped;
};

const initialState = {
  loading: true,
  sendDirectNotification: {
    templateVariables: [],
    templates: [],
  },
  directNotificationsHistoryList: {
    data: [],
    columns: [
      {
        field: "channelName",
        title: "label.individualplayer.directNotifications.channelName",
        default: true,
        widget: true,
      },
      {
        field: "sender",
        title: "label.individualplayer.directNotifications.sender",
      },
      {
        field: "subject",
        title: "label.individualplayer.directNotifications.subject",
        default: true,
        widget: true,
      },
      {
        field: "creationDate",
        title: "label.individualplayer.directNotifications.creationDate",
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          }
        },
        widget: true,
      },
      {
        field: "notificationStatusId",
        title: "label.individualplayer.directNotifications.status",
        render: (rowData) => {
          if (rowData["notificationStatusId"]) {
            const badgeMapped = getStatusMapping(
              rowData["notificationStatusId"]
            );
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
                justify="center"
              />
            );
          }
        },
      },
      {
        field: "firstReadDate",
        title: "label.individualplayer.directNotifications.firstReadDate",
        render: (rowData) => {
          if (rowData["firstReadDate"]) {
            return <Moment format="L LT">{rowData["firstReadDate"]}</Moment>;
          }
        },
      },
      {
        field: "numReadings",
        title: "label.individualplayer.directNotifications.numReadings",
      },
    ],
  },
  directNotificationDetail: {
    detail: {},
  },
  directNotificationAttachment: {
    attachment: {},
  },
};
const DirectNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        directNotificationsHistoryList: {
          ...state.directNotificationsHistoryList,
          data: action.payload,
        },
      };
    }
    case INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        directNotificationsHistoryList: {
          ...state.directNotificationsHistoryList,
          data: null,
        },
      };
    }
    case INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_SUCCESS: {
      return {
        ...state,
        directNotificationDetail: { detail: action.payload },
      };
    }
    case INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_FAILURE: {
      return {
        ...state,
        directNotificationDetail: { detail: null },
      };
    }
    case INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        directNotificationAttachment: { attachment: action.payload },
      };
    }
    case INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_FAILURE: {
      return {
        ...state,
        directNotificationAttachment: { attachment: null },
      };
    }
    case TEMPLATE_VARIABLES_SUCCESS: {
      return {
        ...state,
        sendDirectNotification: {
          ...state.sendDirectNotification,
          templateVariables: action.payload,
        },
      };
    }
    case TEMPLATES_SUCCESS: {
      return {
        ...state,
        sendDirectNotification: {
          ...state.sendDirectNotification,
          templates: action.payload,
        },
      };
    }
    case SEND_DIRECT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
      };
    }
    case SEND_DIRECT_NOTIFICATION_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default DirectNotificationsReducer;
