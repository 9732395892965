import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getClubCardDetails } from "../../store/players/individual-player/club-card/actions";

export const useClubCardDetails = (brand, partner, contractId) => {
  const { dataDetails, fields, isloading, widgetColumns } = useSelector(
    (state) => state.ClubCardReducer.clubCardDetails
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getClubCardDetails(brand, partner, contractId));
  }, [dispatch, brand, partner, contractId]);

  return { dataDetails, fields, isloading, widgetColumns };
};
