import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";

class GamesOverviewService {
  async getGamesOverview(contractId, brand, partner, searchTerms) {
    const response = await axios.get(
      `/gov/api/rest/v2/players/${contractId}/statistics/played-games?brand=${brand}&partner=${partner}`
    );

    if (response && response.data) {
      return searchUtils.search(response.data, searchTerms);
    }
    throw Error("Invalid response");
  }
}

const gamesOverviewService = new GamesOverviewService();
export default gamesOverviewService;
