import axios from "axios";

class PlatformNicknamesService {
  async updatePlatformNickname(
    brandId,
    partnerId,
    gameAccount,
    newNicknameGNS
  ) {
    let url = "/gov/api/rest/v1/ums/nickname/update";

    const response = await axios.put(url, {
      brandId,
      partnerId,
      gameAccount,
      newNicknameGNS,
    });
    if (response && response.status !== 204) {
      throw Error("Invalid response for update platform nickname");
    }
  }

  async addPlatformNickname(
    brandId,
    partnerId,
    gameAccount,
    nickname,
    gameType
  ) {
    let url = "/gov/api/rest/v2/ums/platformNickname";

    const response = await axios.post(url, {
      brandId,
      partnerId,
      gameAccount,
      nickname,
      gameType,
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for add platform nickname");
  }

  async loadPlatformNicknames(brandId, partnerId, gameAccount) {
    let url = `/gov/api/rest/v2/platformNicknames?brand=${brandId}&partner=${partnerId}&gameAccount=${gameAccount}`;

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for nickname platform list");
  }

  async updateNicknameByPlatform(
    brandId,
    partnerId,
    gameAccount,
    nickname,
    gameType
  ) {
    let url = `/gov/api/rest/v2/ums/platformNickname/update/${gameAccount}`;

    const response = await axios.put(url, {
      brandId,
      partnerId,
      gameAccount,
      nickname,
      gameType,
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for update platform nickname");
  }
}

const platformNicknamesService = new PlatformNicknamesService();
export default platformNicknamesService;
