import { call, put } from "redux-saga/effects";

import comunicationHistoryService from "./communication-history-service";
import { takeEvery } from "@redux-saga/core/effects";
import {
  COMMUNICATION_HISTORY_FAILURE,
  COMMUNICATION_HISTORY_SUCCESS,
  COMMUNICATION_HISTORY_REQUEST,
  COMMUNICATION_HISTORY_DETAILS_REQUEST,
  COMMUNICATION_HISTORY_DETAILS_SUCCESS,
  COMMUNICATION_HISTORY_DETAILS_FAILURE,
} from "../../../constants";

const getComunicationHistory = function* (action) {
  try {
    const data = yield call(
      comunicationHistoryService.getComunicationHistory,
      action.contractId,
      action.page,
      action.size,
      action.orderBy
    );
    yield put({
      type: COMMUNICATION_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("communicationHistory", e);
    yield put({
      type: COMMUNICATION_HISTORY_FAILURE,
    });
  }
};

const getComunicationHistoryDetails = function* (action) {
  try {
    const data = yield call(
      comunicationHistoryService.getComunicationHistoryDetails,
      action.extNotificationKey,
      action.extContactKey,
      action.brand,
      action.partner,
      action.channelName
    );
    yield put({
      type: COMMUNICATION_HISTORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("communicationHistoryDetails", e);
    yield put({
      type: COMMUNICATION_HISTORY_DETAILS_FAILURE,
    });
  }
};

const CommunicationHistorySaga = function* communicationHistorySaga() {
  yield takeEvery(COMMUNICATION_HISTORY_REQUEST, getComunicationHistory);
  yield takeEvery(
    COMMUNICATION_HISTORY_DETAILS_REQUEST,
    getComunicationHistoryDetails
  );
};

export default CommunicationHistorySaga;
