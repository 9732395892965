import { call, put } from "redux-saga/effects";
import {
  LINK_LINKED_ACCOUNT_FAILURE,
  LINK_LINKED_ACCOUNT_REQUEST,
  LINK_LINKED_ACCOUNT_SUCCESS,
  LINKED_ACCOUNTS_FAILURE,
  LINKED_ACCOUNTS_REQUEST,
  LINKED_ACCOUNTS_SUCCESS,
  UNLINK_LINKED_ACCOUNT_FAILURE,
  UNLINK_LINKED_ACCOUNT_REQUEST,
  UNLINK_LINKED_ACCOUNT_SUCCESS,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import linkedAccountsService from "./linked-accounts-service";

const loadLinkedAccounts = function* (action) {
  try {
    const data = yield call(
      linkedAccountsService.loadLinkedAccounts,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: LINKED_ACCOUNTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadLinkedAccounts", e);
    yield put({
      type: LINKED_ACCOUNTS_FAILURE,
    });
  }
};

const unlinkLinkedAccount = function* (action) {
  try {
    const data = yield call(
      linkedAccountsService.unlinkLinkedAccounts,
      action.brand,
      action.partner,
      action.contractId,
      action.linkedAccount
    );
    yield put({
      type: UNLINK_LINKED_ACCOUNT_SUCCESS,
      payload: data,
    });
    yield put({
      type: LINKED_ACCOUNTS_REQUEST,
      contractId: action.contractId,
      brand: action.brand,
      partner: action.partner,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.linkAccount.unlinkAccount",
          variables: [action.linkedAccount],
        },
        options: {
          key: "UnlinkLinkedAccount",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("UnlinkLinkedAccount", e);
    yield put({
      type: UNLINK_LINKED_ACCOUNT_FAILURE,
    });
  }
};

const linkLinkedAccount = function* (action) {
  try {
    const data = yield call(
      linkedAccountsService.linkLinkedAccounts,
      action.brand,
      action.partner,
      action.contractId,
      action.linkedAccount
    );
    yield put({
      type: LINK_LINKED_ACCOUNT_SUCCESS,
      payload: data,
    });
    yield put({
      type: LINKED_ACCOUNTS_REQUEST,
      contractId: action.contractId,
      brand: action.brand,
      partner: action.partner,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.linkAccount.linkNewAccount",
          variables: [action.linkedAccount.contract],
        },
        options: {
          key: "LinkLinkedAccount",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("LinkLinkedAccount", e);
    yield put({
      type: LINK_LINKED_ACCOUNT_FAILURE,
    });
  }
};

const LinkedAccountsSaga = function* directNotificationsSaga() {
  yield takeLatest(LINKED_ACCOUNTS_REQUEST, loadLinkedAccounts);
  yield takeLatest(UNLINK_LINKED_ACCOUNT_REQUEST, unlinkLinkedAccount);
  yield takeLatest(LINK_LINKED_ACCOUNT_REQUEST, linkLinkedAccount);
};

export default LinkedAccountsSaga;
