import { enqueueSnackbar } from "../../../snackbars/actions";

import {
  INDIVIDUAL_PLAYER_GROUPS_REQUEST,
  INDIVIDUAL_PLAYER_GROUPS_SUCCESS,
  INDIVIDUAL_PLAYER_GROUPS_FAILURE,
  DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST,
  DELETE_INDIVIDUAL_PLAYER_GROUPS_FAILURE,
  INDIVIDUAL_PLAYER_GROUP_LIST_REQUEST,
  INDIVIDUAL_PLAYER_GROUP_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_GROUP_LIST_FAILURE,
  SAVE_INDIVIDUAL_PLAYER_GROUP_FAILURE,
  SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST,
} from "../../../constants";
import individualPlayerGroupsService from "./individual-player-groups-service";
import { takeEvery, put, call } from "redux-saga/effects";

const IndividualPlayerGroups = function* (action) {
  try {
    const data = yield call(
      individualPlayerGroupsService.individualPlayerGroups,
      action.playerContact,
      action.brand,
      action.partner,
      action.force
    );
    if (action.isWidget) {
      data.sort((a, b) => b.lastUpdated - a.lastUpdated);
    }
    yield put({
      type: INDIVIDUAL_PLAYER_GROUPS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerGroups", e);
    yield put({
      type: INDIVIDUAL_PLAYER_GROUPS_FAILURE,
    });
  }
};

const DeleteIndividualPlayerGroup = function* (action) {
  try {
    yield call(
      individualPlayerGroupsService.deleteIndividualPlayerGroup,
      action.playerContact,
      action.brand,
      action.partner,
      action.name
    );

    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.playerGroups.delete_successfully",
          variables: [action.name],
        },
        options: {
          key: "DeleteIndividualPlayerGroups",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );

    yield put({
      type: INDIVIDUAL_PLAYER_GROUPS_REQUEST,
      playerContact: action.playerContact,
      brand: action.brand,
      partner: action.partner,
      force: true,
    });
  } catch (e) {
    console.error("DeleteIndividualPlayerGroup", e);
    yield put({
      type: DELETE_INDIVIDUAL_PLAYER_GROUPS_FAILURE,
    });
  }
};

const IndividualPlayerGroupList = function* (action) {
  try {
    const data = yield call(
      individualPlayerGroupsService.getGroupList,
      action.brand,
      action.partner
    );
    yield put({
      type: INDIVIDUAL_PLAYER_GROUP_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerGroupList", e);
    yield put({
      type: INDIVIDUAL_PLAYER_GROUP_LIST_FAILURE,
    });
  }
};

const SaveIndividualPlayerGroup = function* (action) {
  try {
    yield call(
      individualPlayerGroupsService.saveGroup,
      action.playerContact,
      action.brand,
      action.partner,
      action.name
    );
    yield put({
      type: INDIVIDUAL_PLAYER_GROUPS_REQUEST,
      playerContact: action.playerContact,
      brand: action.brand,
      partner: action.partner,
      force: true,
    });
  } catch (e) {
    console.error("saveIndividualPlyGrp", e);
    yield put({
      type: SAVE_INDIVIDUAL_PLAYER_GROUP_FAILURE,
    });
  }
};

const IndividualPlayerGroupSaga = function* individualPlayerGroupSaga() {
  yield takeEvery(INDIVIDUAL_PLAYER_GROUPS_REQUEST, IndividualPlayerGroups);
  yield takeEvery(
    DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST,
    DeleteIndividualPlayerGroup
  );
  yield takeEvery(
    INDIVIDUAL_PLAYER_GROUP_LIST_REQUEST,
    IndividualPlayerGroupList
  );
  yield takeEvery(
    SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST,
    SaveIndividualPlayerGroup
  );
};

export default IndividualPlayerGroupSaga;
