import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
//Import Component(s)
import Translate from "../../../../../Layouts/Translator/Translate";
import {
  getPriorities,
  getTypes,
  savePlayerNote,
} from "../../../../../store/players/individual-player/player-notes/actions";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import { useSession } from "../../../../../utilities/hooks/useSession";

function NotesForm({
  contractId,
  note,
  handleCloseModal,
  loadPriorities,
  loadTypes,
  priorities,
  types,
  save,
  success,
}) {
  const t = useTranslator();
  const { brandSelected, partnerSelected } = useSession();

  React.useEffect(() => {
    if (success === true) {
      handleCloseModal();
    }
  }, [success, handleCloseModal]);

  React.useEffect(() => {
    loadPriorities();
  }, [loadPriorities]);

  React.useEffect(() => {
    loadTypes();
  }, [loadTypes]);
  types = types ? types.filter((type) => type.status === "1") : null;
  let initialValues = {
    value: "",
    noteTypeId: "",
    priorityId: "",
  };
  if (note) {
    initialValues = note;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          value: Yup.string().required(
            t({ needle: "label.player.playerNotes.errors.note" })
          ),
          noteTypeId: Yup.string().required(
            t({ needle: "label.player.playerNotes.errors.type" })
          ),
          priorityId: Yup.string().required(
            t({ needle: "label.player.playerNotes.errors.priority" })
          ),
        })}
        onSubmit={(fields) => {
          save(brandSelected, partnerSelected, contractId, fields);
        }}
        render={({
          values,
          errors,
          status,
          handleChange,
          handleBlur,
          touched,
        }) => (
          <Form>
            <Box p={2} pt={0}>
              <TextField
                error={errors.value && touched.value}
                helperText={errors.value && touched.value ? errors.value : null}
                fullWidth
                name={"value"}
                label={<Translate needle={"label.player.playerNotes.note"} />}
                multiline
                inputProps={{ maxLength: 4000 }}
                rows="3"
                value={values.value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                fullWidth
                error={errors.noteTypeId && touched.noteTypeId}
                helperText={
                  errors.noteTypeId && touched.noteTypeId
                    ? errors.noteTypeId
                    : null
                }
                name={"noteTypeId"}
                label={<Translate needle={"label.player.playerNotes.type"} />}
                select
                value={values.noteTypeId}
                onChange={handleChange}
              >
                {(types || []).map((it) => (
                  <MenuItem value={it.id} key={it.id}>
                    {it.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                error={errors.priorityId && touched.priorityId}
                helperText={
                  errors.priorityId && touched.priorityId
                    ? errors.priorityId
                    : null
                }
                name={"priorityId"}
                label={
                  <Translate needle={"label.player.playerNotes.priority"} />
                }
                select
                value={values.priorityId}
                onChange={handleChange}
              >
                {(priorities || []).map((it) => (
                  <MenuItem value={it.id} key={it.id}>
                    {it.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
              <Box mr={1}>
                <Button variant={"outlined"} onClick={() => handleCloseModal()}>
                  <Translate needle={"button.cancel"} />
                </Button>
              </Box>
              <Box>
                <Button color={"primary"} type="submit">
                  <Translate needle={"button.save"} />{" "}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      />
    </>
  );
}

const mapDispatchToProps = {
  loadPriorities: getPriorities,
  loadTypes: getTypes,
  save: savePlayerNote,
};
const mapStateToProps = (state) => {
  const { priorities, types } = state.PlayerNotesReducer.playerNotes;
  const { success } = state.PlayerNotesReducer.playerNote;
  return { priorities, types, success };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotesForm);
