import React from "react";
import { FormattedNumber } from "react-intl";
import Moment from "react-moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  LIMIT_CONFIGURATION_FAILURE,
  LIMIT_CONFIGURATION_REQUEST,
  LIMIT_CONFIGURATION_SUCCESS,
  LIMIT_CONFIG_EDIT_SUCCESS,
  LIMIT_CONFIG_HISTORY_FAILURE,
  LIMIT_CONFIG_HISTORY_SUCCESS,
} from "../../../constants";

const getStatusMapping = (status) => {
  switch (status) {
    case true:
      return { id: "2", label: "enabled" };
    case false:
      return { id: "6", label: "disabled" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const initialState = {
  limitConfiguration: {
    loading: true,
    limits: { data: [], page: 0, totalCount: 0 },
    columns: [
      {
        field: "platformDesc",
        title: "label.individualplayer.responsibleGame.loyaltyLimits.platform",
        defaultSort: "asc",
        default: true,
      },
      {
        field: "lastUpdate",
        title:
          "label.individualplayer.responsibleGame.loyaltyLimits.lastUpdate",
        default: true,
        render: (rowData) => {
          if (rowData["lastUpdate"]) {
            return <Moment format="L LT">{rowData["lastUpdate"]}</Moment>;
          }
        },
      },
    ],
    detailCols: (divisorAmount) => {
      return [
      {
        field: "limitPeriod.id",
        title: "label.rewards.loyalty.limitConfiguration.period",
        render: (rowData) => {
          return (
            <Translate
              needle={
                "label.individualplayer.responsibleGame.loyaltyLimits.limitPeriod." +
                rowData["limitPeriod"]["id"]
              }
            />
          );
        },
      },
      {
        field: "limitTypeDesc",
        title: "label.rewards.loyalty.limitConfiguration.type",
        default: true,
      },
      {
        field: "enabled",
        title: "label.rewards.loyalty.limitConfiguration.status",
        default: true,
        render: (rowData) => {
          if (rowData["enabled"] != null) {
            const statusMapped = getStatusMapping(rowData["enabled"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
              />
            );
          }
        },
      },
      {
        field: "value",
        title: "label.rewards.loyalty.limitConfiguration.maxLimit",
        hideMobile: true,
        nowrap: false,
        render: (rowData) => {
          const value =
            rowData["value"] && rowData["value"] > 0 ? rowData["value"] : 0;
          return <FormattedNumber value={value / divisorAmount} />;
        },
      },
      {
        field: "effectiveDate",
        title: "label.rewards.loyalty.limitConfiguration.effectiveDate",
        render: (rowData) => {
          if (rowData["effectiveDate"]) {
            return <Moment format="L LT">{rowData["effectiveDate"]}</Moment>;
          }
        },
      },
      {
        field: "lastUpdateDate",
        title:
          "label.individualplayer.responsibleGame.loyaltyLimits.lastUpdate",
        defaultSort: "asc",
        render: (rowData) => {
          if (rowData["lastUpdateDate"]) {
            return <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>;
          }
        },
      },
    ];
  },
  },
  limitConfigurationHistory: {
    data: [],
    loading: true,
    columns: [
      {
        field: "enabled",
        title: "label.rewards.loyalty.limitConfiguration.status",
        render: (rowData) => {
          if (rowData["enabled"] != null) {
            const statusMapped = getStatusMapping(rowData["enabled"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
              />
            );
          }
        },
      },
      {
        field: "value",
        title: "label.rewards.loyalty.limitConfiguration.value",
        default: true,
        render: (rowData) => {
          const value =
            rowData["value"] && rowData["value"] > 0 ? rowData["value"] : 0;
          return <FormattedNumber value={value} />;
        },
      },
      {
        field: "effectiveDate",
        title: "label.rewards.loyalty.limitConfiguration.effectiveDate",
        render: (rowData) => {
          if (rowData["effectiveDate"]) {
            return <Moment format="L LT">{rowData["effectiveDate"]}</Moment>;
          }
        },
      },
      {
        field: "lastUpdateDate",
        title:
          "label.individualplayer.responsibleGame.loyaltyLimits.lastUpdate",
        defaultSort: "asc",
        render: (rowData) => {
          if (rowData["lastUpdateDate"]) {
            return <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>;
          }
        },
      },
    ],
  },
  success: null,
};

const LimitConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIMIT_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        limitConfiguration: {
          ...state.limitConfiguration,
          loading: false,
          limits: action.payload,
        },
      };
    }
    case LIMIT_CONFIGURATION_FAILURE: {
      return {
        ...state,
        limitConfiguration: {
          ...state.limitConfiguration,
          loading: false,
          limits: null,
        },
      };
    }
    case LIMIT_CONFIG_HISTORY_SUCCESS: {
      return {
        ...state,
        limitConfigurationHistory: {
          ...state.limitConfigurationHistory,
          loading: false,
          data: action.payload,
        },
      };
    }
    case LIMIT_CONFIG_HISTORY_FAILURE: {
      return {
        ...state,
        limitConfigurationHistory: {
          ...state.limitConfigurationHistory,
          loading: false,
          data: null,
        },
      };
    }
    case LIMIT_CONFIG_EDIT_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }

    case LIMIT_CONFIGURATION_REQUEST: {
      return {
        ...state,
        success: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default LimitConfigurationReducer;
