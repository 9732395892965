import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routeAction } from "../../analytics/actions";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const TrackNavLink = (
  { tooltip = false, to, element, labelTo, ...props },
  ref
) => {
  const dispatch = useDispatch();
  const t = useTranslator();

  const handleClick = () => {
    dispatch(routeAction(element, t({ needle: labelTo })));
  };
  const Wrapper = tooltip ? Tooltip : Box;
  return (
    <Wrapper title={t({ needle: labelTo })} placement="right">
      <NavLink to={to} {...props} onClick={() => handleClick()} />
    </Wrapper>
  );
};

export default React.forwardRef(TrackNavLink);
