import {
  PLAYER_PREFERENCES_REQUEST,
  UPDATE_PLAYER_PREFERENCES_REQUEST,
} from "../../../constants";

export const loadPlayerPreferences = (playerId, brandId, partnerId) => {
  return {
    type: PLAYER_PREFERENCES_REQUEST,
    playerId,
    brandId,
    partnerId,
  };
};

export const updatePlayerPreferences = (playerId, brandId, partnerId, body) => {
  return {
    type: UPDATE_PLAYER_PREFERENCES_REQUEST,
    playerId,
    brandId,
    partnerId,
    body,
  };
};
