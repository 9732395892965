import axios from "axios";

class DirectNotificationsService {
  async loadIndividualPlayerDirectNotificationsHistory(
    contractId,
    brand,
    partner,
    pageNumber,
    pageSize
  ) {
    const response = await axios.get(
      `/gov/api/rest/v1/notifications/direct/${contractId}/history?brand=${brand}&partner=${partner}&page=${pageNumber}&pageSize=${pageSize}`
    );
    if (response && response.data && response.data.collection) {
      return {
        data: response.data.collection,
        page: pageNumber,
        totalCount: response.data.totalElements,
      };
    }
    throw Error(
      "Invalid response for individual get direct notification history"
    );
  }

  async resendDirectNotification(contractId, brand, partner, notificationId) {
    const response = await axios.post(
      `/gov/api/rest/v1/notifications/direct/resend`,
      {
        brandCode: brand,
        contractId: contractId,
        directNotificationId: notificationId,
        partnerCode: partner,
      }
    );
    if (response) {
      return response.status;
    }
    throw Error("Invalid response for individual resend direct notification");
  }

  async loadDirectNotificationDetail(
    contractId,
    brand,
    partner,
    notificationId
  ) {
    const response = await axios.get(
      `/gov/api/rest/v1/notifications/direct/${contractId}/history/${notificationId}?brand=${brand}&partner=${partner}`
    );
    if (response) {
      return response.data;
    }
    throw Error(
      "Invalid response for individual player get direct notification detail"
    );
  }

  async loadDirectNotificationAttachment(
    contractId,
    brand,
    partner,
    notificationId,
    attachmentId
  ) {
    const response = await axios.get(
      `/gov/api/rest/v1/notifications/direct/${contractId}/history/${notificationId}/attachments/${attachmentId}?brand=${brand}&partner=${partner}`
    );
    if (response) {
      return response.data;
    }
    throw Error(
      "Invalid response for individual player get direct notification attachment"
    );
  }

  async getTemplateVariables(brand, partner, contractId) {
    let response = await axios.get(
      `/gov/api/rest/v1/template_variables_by_contract/${contractId}`,
      {
        params: {
          brand,
          partner,
        },
      }
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for template variables fetch");
  }

  async getTemplates() {
    let response = await axios.get(
      "/gov/api/rest/v1/notifications/direct/templates"
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for templates fetch");
  }

  async sendNotification(brand, partner, contractId, data = {}, files = []) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("brandCode", brand);
    formData.append("partnerCode", partner);
    formData.append("contractId", contractId);
    files.forEach((it) => {
      formData.append("filename", it);
    });
    Object.keys(data).forEach((it) => {
      formData.append(it, data[it]);
    });
    let response = await axios.post(
      "/gov/api/rest/v1/notifications/direct",
      formData,
      config
    );
    if (response && response.status !== 201) {
      throw Error("Invalid response for send notification");
    }
  }
}

const directNotificationsService = new DirectNotificationsService();
export default directNotificationsService;
