import { call, put, takeEvery } from "redux-saga/effects";

import secondStepService from "./second-step-service";
import {
  BONUS_BAG_TEMPLATES_FAILURE,
  BONUS_BAG_TEMPLATES_REQUEST,
  BONUS_BAG_TEMPLATES_SUCCESS,
  BONUS_TYPES_FAILURE,
  BONUS_TYPES_REQUEST,
  BONUS_TYPES_SUCCESS,
  BULK_TEMPLATE_FAILURE,
  BULK_TEMPLATE_REQUEST,
  BULK_TEMPLATE_SUCCESS,
  DEVICES_FAILURE,
  DEVICES_REQUEST,
  DEVICES_SUCCESS,
  GAME_DETAIL_FAILURE,
  GAME_DETAIL_REQUEST,
  GAME_DETAIL_SUCCESS,
  OPERATIONS_FAILURE,
  OPERATIONS_REQUEST,
  OPERATIONS_SUCCESS,
  ORGANIZATION_FAILURE,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  THRESHOLD_TYPES_FAILURE,
  THRESHOLD_TYPES_REQUEST,
  THRESHOLD_TYPES_SUCCESS,
  VALIDITY_DATE_TYPE_FAILURE,
  VALIDITY_DATE_TYPE_REQUEST,
  VALIDITY_DATE_TYPE_SUCCESS,
  VOUCHER_TEMPLATES_FAILURE,
  VOUCHER_TEMPLATES_REQUEST,
  VOUCHER_TEMPLATES_SUCCESS,
} from "../../../../constants";

const getOperations = function* (action) {
  try {
    const data = yield call(secondStepService.getOperations, action.groupId);
    yield put({
      type: OPERATIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("operationList", e);
    yield put({
      type: OPERATIONS_FAILURE,
    });
  }
};

const getBulkTemplates = function* (action) {
  try {
    const data = yield call(
      secondStepService.getTemplates,
      action.template,
      null,
      action.brand,
      action.partner
    );
    yield put({
      type: BULK_TEMPLATE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("operationList", e);
    yield put({
      type: BULK_TEMPLATE_FAILURE,
    });
  }
};

const getGameDetail = function* (action) {
  try {
    const data = yield call(
      secondStepService.getGameDetail,
      action.brand,
      action.partner,
      action.gameCode,
      action.currency
    );
    yield put({
      type: GAME_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("gameDetail", e);
    yield put({
      type: GAME_DETAIL_FAILURE,
    });
  }
};

const getValidityDateType = function* (action) {
  try {
    const data = yield call(secondStepService.getValidityDateType);
    yield put({
      type: VALIDITY_DATE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("expirationType", e);
    yield put({
      type: VALIDITY_DATE_TYPE_FAILURE,
    });
  }
};

const getBonusTypes = function* (action) {
  try {
    const data = yield call(secondStepService.getBonusTypes);
    yield put({
      type: BONUS_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("bonusTypes", e);
    yield put({
      type: BONUS_TYPES_FAILURE,
    });
  }
};

const getDevices = function* (action) {
  try {
    const data = yield call(secondStepService.getDevices);
    yield put({
      type: DEVICES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("devices", e);
    yield put({
      type: DEVICES_FAILURE,
    });
  }
};

const getThresholdTypes = function* (action) {
  try {
    const data = yield call(secondStepService.getThresholdTypes);
    yield put({
      type: THRESHOLD_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("thresholdTypes", e);
    yield put({
      type: THRESHOLD_TYPES_FAILURE,
    });
  }
};

const getOrganizations = function* (action) {
  try {
    const data = yield call(secondStepService.getOrganizations);
    yield put({
      type: ORGANIZATION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("organizations", e);
    yield put({
      type: ORGANIZATION_FAILURE,
    });
  }
};

const getVoucherTemplates = function* (action) {
  try {
    const data = yield call(
      secondStepService.getVoucherTemplates,
      action.voucherType
    );
    yield put({
      type: VOUCHER_TEMPLATES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("voucherTemplates", e);
    yield put({
      type: VOUCHER_TEMPLATES_FAILURE,
    });
  }
};

const getBonusBagTemplates = function* (action) {
  try {
    const data = yield call(
      secondStepService.getTemplates,
      null,
      true,
      action.brand.id,
      action.partner.id
    );
    yield put({
      type: BONUS_BAG_TEMPLATES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getBonusBagTemplates", e);
    yield put({
      type: BONUS_BAG_TEMPLATES_FAILURE,
    });
  }
};

const SecondStepSaga = function* secondStepSaga() {
  yield takeEvery(OPERATIONS_REQUEST, getOperations);
  yield takeEvery(BULK_TEMPLATE_REQUEST, getBulkTemplates);
  yield takeEvery(GAME_DETAIL_REQUEST, getGameDetail);
  yield takeEvery(VALIDITY_DATE_TYPE_REQUEST, getValidityDateType);
  yield takeEvery(BONUS_TYPES_REQUEST, getBonusTypes);
  yield takeEvery(DEVICES_REQUEST, getDevices);
  yield takeEvery(THRESHOLD_TYPES_REQUEST, getThresholdTypes);
  yield takeEvery(ORGANIZATION_REQUEST, getOrganizations);
  yield takeEvery(VOUCHER_TEMPLATES_REQUEST, getVoucherTemplates);
  yield takeEvery(BONUS_BAG_TEMPLATES_REQUEST, getBonusBagTemplates);
};

export default SecondStepSaga;
