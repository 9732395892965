import axios from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GAME_CODES_REQUEST,
  GAME_CODES_SUCCESS,
  GAMES_REQUEST,
  GAMES_SUCCESS,
  GAMES_FAILURE,
  NETWORK_BANS_ALL_GAMES,
} from "../constants";

const gameCodesFetching = async ({ brand, partner, gameCodes }) => {
  const response = await axios.get(
    `/gameadmin/api/brands/${brand}/partners/${partner}/search/games.json?query=gameCode:(${gameCodes})`
  );
  return response.data;
};

export const getGameCodes = async ({
  brand,
  partner,
  platform,
  currency,
  category,
  isNetworkBans,
}) => {
  let platforms = "*";
  if (Array.isArray(platform)) {
    if (
      !platform.includes("-1") &&
      !platform.includes(NETWORK_BANS_ALL_GAMES) &&
      !platform.includes(+NETWORK_BANS_ALL_GAMES)
    ) {
      platforms = platform.join(" or ");
      if (platform.length > 1) {
        platforms = "(" + platforms + ")";
      }
    }
  } else {
    platforms = platform;
  }

  const response = await axios.get(
    `/gov/api/rest/v3/game-codes-allstatuses?brand=${brand}&partner=${partner}&platform=${platforms}&currency=${currency}&category=${
      category ? category : ""
    }`
  );

  if (response && response.data) {
    return response.data;
  }

  throw Error("Invalid response");
};

const loadgameCodes = function* (action) {
  let data = [];
  const { gameCodes } = action.payload;
  try {
    data = gameCodes ? yield call(gameCodesFetching, action.payload) : [];
  } catch (e) {
    console.error(e);
  }

  yield put({
    type: GAME_CODES_SUCCESS,
    payload: data.data,
  });
};

const getGames = function* (action) {
  try {
    const data = yield call(getGameCodes, action);
    yield put({
      type: GAMES_SUCCESS,
      payload: data,
      platform: action.platform,
      currency: action.currency,
      category: action.category,
    });
  } catch (e) {
    console.error("gameDomains", e);
    yield put({
      type: GAMES_FAILURE,
    });
  }
};

const GameCodesSaga = function* () {
  yield takeEvery(GAME_CODES_REQUEST, loadgameCodes);
  yield takeLatest(GAMES_REQUEST, getGames);
};

export default GameCodesSaga;
