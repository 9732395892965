import { Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import * as moment from "moment";
import React from "react";
import BadgeStatus from "../../Layouts/BadgeStatus/BadgeStatus";
import YesNoBadge from "../../Layouts/BadgeStatus/yes-no-badge";
import Translate from "../../Layouts/Translator/Translate";
import {
  TimezoneDate,
  TimezoneDateWithTimezone,
} from "../../Layouts/date-formatter/date-formatter";
import {
  CONSENTS_REQUEST,
  CONSENTS_SUCCESS,
  ORDER_ACTION_FAILURE,
  ORDER_ACTION_SUCCESS,
  ORDER_TYPE_FAILURE,
  ORDER_TYPE_SUCCESS,
  PLAYERS_ID_LIST_FAILURE,
  PLAYERS_ID_LIST_SUCCESS,
  PLAYERS_SEARCH_CONTRACT_HEADER_SUCCESS,
  PLAYERS_SEARCH_CRITERIA_FAILURE,
  PLAYERS_SEARCH_CRITERIA_SUCCESS,
  PLAYERS_SEARCH_FAILURE,
  PLAYERS_SEARCH_SUCCESS,
  PLAYER_LEVELS_REQUEST,
  PLAYER_LEVELS_SUCCESS,
  PLAYER_LEVEL_FIELDS_SUCCESS,
  STYLE_LABEL,
} from "../constants";

export const getStatusMapping = (status) => {
  switch (status) {
    case 1:
      return { id: "3", label: "toValidate" };
    case 2:
      return { id: "2", label: "partiallyValidated" };
    case 3:
      return { id: "2", label: "validated" };
    case 4:
      return { id: "7", label: "resolved" };
    case 5:
      return { id: "2", label: "completed" };
    case 6:
      return { id: "14", label: "hidden" };
    case 8:
      return { id: "13", label: "toClose" };
    case 9:
      return { id: "3", label: "confirmWithdrawal" };
    case 10:
      return { id: "15", label: "confirmClosure" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

export const getReserveStatusMapping = (status) => {
  switch (+status) {
    case 1:
      return { id: "13", label: "reserved" };
    case 2:
      return { id: "7", label: "closed" };
    case 3:
      return { id: "6", label: "canceled" };
    case 9:
      return { id: "3", label: "confirmWithdrawal" };
    case 10:
      return { id: "15", label: "confirmClosure" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const getAccountStatusMapping = (status) => {
  let label = "gameAccountstatus_" + status;
  switch (status) {
    case 1: {
      return { id: "13", label: label };
    }
    case 2: {
      return { id: "2", label: label };
    }
    case 3: {
      return { id: "3", label: label };
    }
    case 4:
    case 5: {
      return { id: "7", label: label };
    }
    case 6: {
      return { id: "5", label: label };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

export const testPlayerRowColor = "#fdeaea";

const initialState = {
  playerForm: {
    playerLevels: [],
    fieldSections: {},
    originalSections: {},
    consents: [],
    consentsLoaded: false,
  },
  playersId: [],
  playerSearch: {
    idKey: "CONTRACT_IDENTITY",
    searchCriteria: [],
    columnOverrides: {},
    players: { data: [], page: 0, totalCount: 0 },
    filteredColumns: [
      "USERNAME",
      "NICKNAME",
      "FULLNAME",
      "ADDRESS",
      "EMAIL",
      "CELLULAR_PHONE",
      "HOME_PHONE",
      "BIRTH_DATE",
    ],
    filteredSearchCriteria: (isPam) => {
      return isPam
        ? ["CONTRACT_IDENTITY", "USERNAME", "EMAIL", "HOME_PHONE"]
        : ["CONTRACT_IDENTITY", "NICKNAME"];
    },
    noSensitiveAndVipCriteria: () => {
      return ["VIP_SENSITIVE", "vip_date_dt", "sensitive_date_dt"];
    },
    columns: (isPam, sensitiveVip, t, defaultTimezone) => {
      return [
        {
          field: "CONTRACT_IDENTITY",
          title: "label.contract",
          defaultSort: "asc",
          nowrap: false,
          render: (rowData) => {
            return (
              <Box display={"flex"} flexDirection={"row"}>
                <Box pt={0.5}>{rowData["CONTRACT_IDENTITY"]}</Box>
                {rowData["TEST_PLAYER_ACCOUNT"] == "true" && (
                  <Tooltip
                    placement={"right"}
                    title={
                      <>
                        <Translate needle={"label.testPlayerTooltip"} />
                        <Box>{rowData["AGENCY_INFO"]}</Box>
                      </>
                    }
                  >
                    <Box>
                      <BadgeStatus
                        id={"7"}
                        label={"t"}
                        fullWidth
                        justify={"center"}
                      />
                    </Box>
                  </Tooltip>
                )}
                {rowData["STAFF_MEMBER"] && (
                  <Box>
                    <BadgeStatus
                      id={"7"}
                      label={"s"}
                      fullWidth
                      justify={"center"}
                    />
                  </Box>
                )}
                {rowData["PEP_STATUS"] != null &&
                  rowData["PEP_STATUS"] !== "DISABLED" && (
                    <Tooltip
                      placement={"right"}
                      title={
                        <>
                          <Translate needle={"label.pepTooltip"} />
                          <Box>{rowData["PEP_STATUS"]}</Box>
                        </>
                      }
                    >
                      <Box>
                        <BadgeStatus
                          id={"6"}
                          label={"P"}
                          fullWidth
                          justify={"center"}
                        />
                      </Box>
                    </Tooltip>
                  )}
              </Box>
            );
          },
        },
        {
          field: "GEO_PROVIDER",
          title: "label.playerSearch.geoProvider",
        },
        {
          field: "PLAYER_GEOCOMPLY",
          title: "label.geocomplyId",
          splitExportStringVal: true,
        },
        {
          field: "LEGACY_CONTRACT_IDENTITY",
          title: "label.legacyContractIdentity",
        },
        {
          field: "CONTRACT_STATUS_ID",
          title: "label.contractStatus",
          nowrap: false,
          render: (rowData) => {
            if (rowData["CONTRACT_STATUS_ID"]) {
              const statusMapped = getStatusMapping(
                rowData["CONTRACT_STATUS_ID"]
              );
              return (
                <BadgeStatus
                  id={statusMapped.id}
                  label={statusMapped.label}
                  justify={"center"}
                  fullWidth
                />
              );
            }
          },
          getExportTypeFn: (rowData) => {
            if (rowData["CONTRACT_STATUS_ID"]) {
              const statusMapping = getStatusMapping(
                rowData["CONTRACT_STATUS_ID"]
              );
              return {
                style: STYLE_LABEL,
                value: `label.status.${statusMapping.label}`,
              };
            }
          },
        },
        {
          field: "PLAYER_ACCOUNT_STATUS",
          title: "label.playerSearch.playerAccountStatus",
          nowrap: false,
          emptyValueJustify: "center",
          render: (rowData) => {
            if (rowData["PLAYER_ACCOUNT_STATUS"]) {
              const statusMapped = getAccountStatusMapping(
                rowData["PLAYER_ACCOUNT_STATUS"]
              );
              return (
                <BadgeStatus
                  id={statusMapped.id}
                  label={statusMapped.label}
                  justify={"center"}
                  fullWidth
                />
              );
            }
          },
          getExportTypeFn: (rowData) => {
            if (rowData["PLAYER_ACCOUNT_STATUS"]) {
              const statusMapping = getAccountStatusMapping(
                rowData["PLAYER_ACCOUNT_STATUS"]
              );
              return {
                style: STYLE_LABEL,
                value: `label.status.${statusMapping.label}`,
              };
            }
          },
        },
        {
          field: "USERNAME",
          hidden: !isPam,
          title: "label.username",
        },
        {
          field: "NICKNAME",
          title: "label.nickname",
        },
        {
          field: "FULLNAME",
          hidden: !isPam,
          title: "label.fullName",
          sortField: "FULLNAME",
        },
        {
          field: "vip_b",
          title: "label.vip",
          hidden: !isPam || !sensitiveVip,
          nowrap: false,
          sorting: false,
          render: (rowData) => {
            return (
              <Box display={"flex"} justifyContent={"center"}>
                <Tooltip
                  placement={"right"}
                  title={
                    rowData["vip_b"] ? (
                      <>
                        <Translate needle={"label.vipTooltip"} />
                        <Box>
                          {moment(rowData["vip_date_dt"]["time"]).format(
                            "L LT"
                          )}
                        </Box>
                      </>
                    ) : (
                      ""
                    )
                  }
                >
                  <Box>
                    <YesNoBadge value={rowData["vip_b"]} />
                  </Box>
                </Tooltip>
              </Box>
            );
          },
          getExportValueFn: (rowData) => {
            if (rowData["vip_b"]) {
              return t({ needle: "label.yes" });
            } else {
              return t({ needle: "label.no" });
            }
          },
        },
        {
          field: "sensitive_b",
          title: "label.sensitive",
          hidden: !isPam || !sensitiveVip,
          nowrap: false,
          sorting: false,
          render: (rowData) => {
            return (
              <Box display={"flex"} justifyContent={"center"}>
                <Tooltip
                  placement={"right"}
                  title={
                    rowData["sensitive_b"] ? (
                      <>
                        <Translate needle={"label.sensitiveTooltip"} />
                        <Box>
                          {moment(rowData["sensitive_date_dt"]["time"]).format(
                            "L LT"
                          )}
                        </Box>
                      </>
                    ) : (
                      ""
                    )
                  }
                >
                  <Box>
                    <YesNoBadge value={rowData["sensitive_b"]} />
                  </Box>
                </Tooltip>
              </Box>
            );
          },
          getExportValueFn: (rowData) => {
            if (rowData["sensitive_b"]) {
              return t({ needle: "label.yes" });
            } else {
              return t({ needle: "label.no" });
            }
          },
        },
        {
          field: "ADDRESS",
          hidden: !isPam,
          title: "label.address",
          render: (rowData) => {
            var address = rowData["ADDRESS"];
            if (rowData["POSTALCODE"]) {
              address += ` - ${rowData["POSTALCODE"]}`;
            }
            return address;
          },
        },
        {
          field: "EMAIL",
          hidden: !isPam,
          title: "label.email",
          sorting: false,
        },
        {
          field: "CELLULAR_PHONE",
          hidden: !isPam,
          title: "label.cellularphone",
          sorting: false,
        },
        {
          field: "HOME_PHONE",
          hidden: !isPam,
          title: "label.homephone",
          sorting: false,
        },
        {
          field: "CREATION_DATE",
          title: "label.player.creationDate",
          sorting: false,
          render: (rowData) => {
            if (rowData["CREATION_DATE"]) {
              return (
                <TimezoneDate
                  format={"L LT"}
                  timezone={defaultTimezone}
                  value={rowData["CREATION_DATE"]}
                />
              );
            }
          },
          getExportValueFn: (rowData) => {
            if (rowData["CREATION_DATE"]) {
              return TimezoneDateWithTimezone({
                format: "L LT",
                timezone: defaultTimezone,
                value: rowData["CREATION_DATE"],
              });
            }
          },
        },
      ].filter((it) => !it.hidden);
    },
    searchContractHeader: "",
  },
  actions: [],
  types: [],
};

const PlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYERS_SEARCH_CRITERIA_SUCCESS: {
      return {
        ...state,
        playerSearch: {
          ...state.playerSearch,
          columnOverrides: action.columnOverrides,
          searchCriteria: action.payload,
        },
      };
    }

    case PLAYERS_SEARCH_CONTRACT_HEADER_SUCCESS: {
      return {
        ...state,
        playerSearch: {
          ...state.playerSearch,
          searchContractHeader: action.contractToSearch,
        },
      };
    }

    case PLAYERS_SEARCH_CRITERIA_FAILURE: {
      return {
        ...state,
        playerSearch: { ...state.playerSearch, searchCriteria: [] },
      };
    }

    case PLAYERS_SEARCH_SUCCESS: {
      return {
        ...state,
        playerSearch: { ...state.playerSearch, players: action.payload },
      };
    }

    case PLAYERS_SEARCH_FAILURE: {
      return {
        ...state,
        playerSearch: { ...state.playerSearch, players: null },
      };
    }

    case PLAYERS_ID_LIST_SUCCESS: {
      return {
        ...state,
        playersId: action.payload,
      };
    }

    case PLAYERS_ID_LIST_FAILURE: {
      return {
        ...state,
        playersId: [],
      };
    }

    case PLAYER_LEVELS_REQUEST: {
      return {
        ...state,
        playerForm: initialState.playerForm,
      };
    }

    case PLAYER_LEVELS_SUCCESS: {
      return {
        ...state,
        playerForm: {
          ...state.playerForm,
          playerLevels: action.payload,
        },
      };
    }

    case PLAYER_LEVEL_FIELDS_SUCCESS: {
      return {
        ...state,
        playerForm: {
          ...state.playerForm,
          fieldSections: action.payload,
          originalSections: action.payload,
        },
      };
    }

    case CONSENTS_REQUEST: {
      return {
        ...state,
        playerForm: {
          ...state.playerForm,
          consentsLoaded: false,
        },
      };
    }

    case CONSENTS_SUCCESS: {
      let sections = [...state.playerForm.originalSections.sections];

      return {
        ...state,
        playerForm: {
          ...state.playerForm,
          consents: action.payload,
          consentsLoaded: true,
          fieldSections: {
            ...state.playerForm.fieldSections,
            sections,
          },
        },
      };
    }

    case ORDER_ACTION_SUCCESS: {
      return {
        ...state,
        actions: action.data,
      };
    }

    case ORDER_ACTION_FAILURE: {
      return {
        ...state,
        actions: [],
      };
    }

    case ORDER_TYPE_SUCCESS: {
      return {
        ...state,
        types: action.data,
      };
    }

    case ORDER_TYPE_FAILURE: {
      return {
        ...state,
        types: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default PlayerReducer;
