import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_REWARD_REQUEST,
  BULK_OPERATION_LIST_RELOAD,
  CHECK_LIMIT,
  COMMUNICATIONS_FAILURE,
  COMMUNICATIONS_REQUEST,
  COMMUNICATIONS_SUCCESS,
  GROUP_NAME_FAILURE,
  GROUP_NAME_REQUEST,
  GROUP_NAME_SUCCESS,
  REWARD_TYPES_FAILURE,
  REWARD_TYPES_REQUEST,
  REWARD_TYPES_SUCCESS,
  SAVE_REWARD_REQUEST,
  CLONE_REWARD_SUCCESS,
  BULK_OPERATION_LIST_VIEW_RELOAD,
} from "../../../../constants";
import { enqueueSnackbar } from "../../../../snackbars/actions";
import basicStepService from "./basic-step-service";

const getRewardTypes = function* (action) {
  try {
    const data = yield call(
      basicStepService.getRewardTypes,
      action.brand,
      action.partner,
      action.isSinglePlayer
    );
    yield put({
      type: REWARD_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("rewardTypesList", e);
    yield put({
      type: REWARD_TYPES_FAILURE,
    });
  }
};

const getGroupName = function* (action) {
  try {
    const data = yield call(
      basicStepService.getGroupName,
      action.brand,
      action.partner
    );
    yield put({
      type: GROUP_NAME_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("groupNameList", e);
    yield put({
      type: GROUP_NAME_FAILURE,
    });
  }
};

const getCommunications = function* (action) {
  try {
    const data = yield call(
      basicStepService.getCommunication,
      action.brand,
      action.partner
    );
    yield put({
      type: COMMUNICATIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("communicationsList", e);
    yield put({
      type: COMMUNICATIONS_FAILURE,
    });
  }
};

const saveReward = function* (action) {
  yield put({
    ...action,
    type: ADD_REWARD_REQUEST,
    checkAmount: !action.isApprovator,
    approved: action.approved,
    limitExceeded: action.isApprovator ? false : undefined,
  });
};

const checkLimit = function* ({
  brandSelected,
  partnerSelected,
  data,
  responseData,
  isApprovator,
  approved,
  contractId,
  clone,
  currency,
  cb,
}) {
  if (isApprovator) {
    yield performCreate({
      brandSelected,
      partnerSelected,
      data,
      checkAmount: false,
      approved: false,
      limitExceeded: false,
      isApprovator,
      contractId,
      clone,
      cb,
    });
  } else {
    let deskModel = {
      brandId: brandSelected.id,
      partnerId: partnerSelected.id,
      currency,
      amount: responseData.amount,
      amountDeskCurrency: responseData.amountDeskApprovalCurrency,
      rewardTypeId: data.rewardTypeId,
    };

    const limit = yield call(
      basicStepService.checkLimit,
      deskModel.rewardTypeId
    );
    if (limit.length > 0) {
      try {
        yield call(basicStepService.deskValidate, deskModel);

        yield performCreate({
          brandSelected,
          partnerSelected,
          data,
          deskModel,
          checkAmount: false,
          approved: false,
          limitExceeded: false,
          isApprovator,
          contractId,
          clone,
          cb,
        });
      } catch (e) {
        yield performCreate({
          brandSelected,
          partnerSelected,
          data,
          deskModel,
          checkAmount: false,
          approved: false,
          limitExceeded: true,
          isApprovator,
          contractId,
          clone,
          cb,
        });
      }
    } else {
      yield performCreate({
        brandSelected,
        partnerSelected,
        data,
        deskModel,
        checkAmount: false,
        approved: false,
        limitExceeded: false,
        isApprovator,
        contractId,
        clone,
        cb,
      });
    }
  }
};

const performCreate = function* ({
  brandSelected,
  partnerSelected,
  data,
  checkAmount,
  approved,
  limitExceeded,
  deskModel,
  isApprovator,
  contractId,
  clone,
  checkAmountModal,
  cb,
  successMessage,
  errCb,
}) {
  try {
    let response;
    if (contractId) {
      response = yield call(
        basicStepService.assignReward,
        brandSelected.id,
        partnerSelected.id,
        data,
        checkAmount,
        approved,
        limitExceeded ? false : true
      );
    } else {
      response = yield call(
        basicStepService.putReward,
        brandSelected.id,
        partnerSelected.id,
        data,
        checkAmount,
        approved,
        limitExceeded ? false : true,
        clone
      );
    }

    if (checkAmount) {
      checkAmountModal(response);
    } else {
      if (!isApprovator) {
        let deskData = { ...deskModel };
        deskData["rewardStatusId"] = limitExceeded
          ? "TO_BE_APPROVED_FOR_OVERBUDGET"
          : "TO_BE_APPROVED";
        deskData["approvalId"] = response.approvalIdentifier;
        yield call(basicStepService.desk, deskData);
      }
      if (!limitExceeded) {
        yield put(
          enqueueSnackbar({
            message: {
              needle:
                successMessage != null
                  ? successMessage
                  : "label.bulkoperationadd.successMessage",
            },
            options: {
              variant: "success",
              autoHideDuration: 8000,
            },
          })
        );
        if (!contractId) {
          if (clone) {
            yield put({
              type: CLONE_REWARD_SUCCESS,
              payload: response,
            });
          }
          yield put({
            type: BULK_OPERATION_LIST_RELOAD,
          });

          yield put({
            type: BULK_OPERATION_LIST_VIEW_RELOAD,
          });
        }

        cb &&
          typeof cb === "function" &&
          cb(clone ? response.approvalIdentifier : null);
      }
    }
  } catch (e) {
    console.error("addBulkReward", e);
    errCb && typeof errCb === "function" && errCb();
  }
};

const BasicStepSaga = function* basicStepSaga() {
  yield takeEvery(REWARD_TYPES_REQUEST, getRewardTypes);
  yield takeEvery(GROUP_NAME_REQUEST, getGroupName);
  yield takeEvery(COMMUNICATIONS_REQUEST, getCommunications);
  yield takeEvery(SAVE_REWARD_REQUEST, saveReward);
  yield takeEvery(ADD_REWARD_REQUEST, performCreate);
  yield takeEvery(CHECK_LIMIT, checkLimit);
};

export default BasicStepSaga;
