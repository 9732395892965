import React from "react";
import { useField } from "formik";
import { FormControl, InputLabel } from "@material-ui/core";
import FormLabel from "./form-label";
import { useSession } from "../../utilities/hooks/useSession";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { AddCircle, DeleteOutline } from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import MaterialFormLabel from "@material-ui/core/FormLabel";

const RangeField = ({
  min = 0,
  max = 1000,
  range = 3,
  optional,
  label,
  tooltip,
  placeholder,
  ...props
}) => {
  const { divisorAmount } = useSession();
  const t = useTranslator();
  const isAmount = range !== "dynamic";
  const sliderMin = isAmount ? min / divisorAmount : min;
  const sliderMax = isAmount ? max / divisorAmount : max;
  const [field, meta, helpers] = useField(props);
  const rows = range === "dynamic" ? 1 : range;
  const { value: fieldValue } = field;
  let sliderValues = [];

  if (fieldValue != null && fieldValue !== "") {
    sliderValues = fieldValue
      .substring(0, fieldValue.length - 1)
      .split(";")
      .map((it) => {
        let [amount, rest] = it.split("(");
        let [start, rest2] = rest.split("-");
        let [end] = rest2.split(")");
        return [
          isAmount ? Math.ceil(start / divisorAmount) : start,
          isAmount ? Math.ceil(end / divisorAmount) : end,
          isAmount ? Math.ceil(amount / divisorAmount) : amount,
        ];
      });
  } else {
    sliderValues = Array(rows)
      .fill()
      .map((i, index) => [
        Math.ceil((sliderMax * index) / rows),
        Math.ceil((sliderMax * (index + 1)) / rows),
        0,
      ]);
  }

  const handleChange = (index, newValue) => {
    if (newValue) {
      sliderValues[index] = newValue;
    } else {
      sliderValues.splice(index, 1);
    }
    if (index + 1 < sliderValues.length) {
      sliderValues[index + 1][0] = newValue[1];
    }
    const updatedValues =
      sliderValues
        .map(([minValue, maxValue, amountValue], i) => {
          return (
            (isAmount ? amountValue * divisorAmount : amountValue) +
            "(" +
            (isAmount ? minValue * divisorAmount : minValue) +
            "-" +
            (isAmount ? maxValue * divisorAmount : maxValue) +
            ")"
          );
        })
        .join(";") + ";";
    helpers.setValue(updatedValues);
  };

  return (
    <FormControl error={meta.error != null}>
      {label ? (
        <InputLabel>
          <FormLabel optional={optional} label={label} tooltip={tooltip} />
        </InputLabel>
      ) : null}
      <Box
        mb={"6px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          width={range === "dynamic" ? "calc(100% - 48px)" : 1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box width={"calc(33% - 8px)"}>
            <MaterialFormLabel>
              {t({ needle: "label.form.minRange" })}
            </MaterialFormLabel>
          </Box>
          <Box width={"calc(33% - 8px)"}>
            <MaterialFormLabel>
              {t({ needle: "label.form.maxRange" })}
            </MaterialFormLabel>
          </Box>
          <Box width={"calc(33% - 8px)"}>
            <MaterialFormLabel>
              {t({ needle: "label.form.amount" })}
            </MaterialFormLabel>
          </Box>
        </Box>
      </Box>
      {sliderValues.map(([minValue, maxValue, amountValue], index) => {
        return (
          <Box
            mt={index === 0 ? 0 : 2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            key={index}
          >
            <Box
              width={range === "dynamic" ? "calc(100% - 48px)" : 1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box width={"calc(33% - 8px)"}>
                <NumericField
                  error={range === "dynamic" && +minValue < +sliderMin}
                  disabled={range !== "dynamic" || index > 0}
                  value={minValue}
                  onChange={(event) => {
                    handleChange(index, [
                      event.target.value != null ? event.target.value : "",
                      maxValue,
                      amountValue,
                    ]);
                  }}
                />
              </Box>
              <Box width={"calc(33% - 8px)"}>
                <NumericField
                  disabled={
                    range !== "dynamic" && index === sliderValues.length - 1
                  }
                  value={maxValue}
                  error={+maxValue < +minValue || +maxValue > +sliderMax}
                  onChange={(event) => {
                    handleChange(index, [
                      minValue,
                      event.target.value != null ? event.target.value : "",
                      amountValue,
                    ]);
                  }}
                />
              </Box>
              <Box width={"calc(34% - 8px)"}>
                <NumericField
                  value={amountValue}
                  onChange={(event) => {
                    handleChange(index, [
                      minValue,
                      maxValue,
                      event.target.value != null ? event.target.value : "",
                    ]);
                  }}
                />
              </Box>
            </Box>
            {index > 0 &&
            index === sliderValues.length - 1 &&
            range === "dynamic" ? (
              <Box width={48}>
                <IconButton
                  color={"failure"}
                  onClick={() => {
                    let newFieldValue = fieldValue
                      .substring(0, fieldValue.length - 1)
                      .split(";");
                    newFieldValue.splice(newFieldValue.length - 1, 1);
                    helpers.setValue(newFieldValue.join(";") + ";");
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        );
      })}
      {meta.touched ? <FormHelperText>{meta.error}</FormHelperText> : null}
      {range === "dynamic" ? (
        <Box>
          <Button
            disabled={
              sliderValues.length === 0 ||
              sliderValues[sliderValues.length - 1][1] === 0
            }
            variant={"text"}
            startIcon={<AddCircle />}
            color={"success"}
            onClick={() => {
              helpers.setValue(
                fieldValue +
                  "0(" +
                  sliderValues[sliderValues.length - 1][1] +
                  "-0);"
              );
            }}
          >
            {t({ needle: "button.addRange" })}
          </Button>
        </Box>
      ) : null}
    </FormControl>
  );
};

const NumericField = (props) => {
  return (
    <TextField
      style={{ marginTop: 0 }}
      {...props}
      onKeyDown={(e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode >= 37 && charCode <= 40) {
          return true;
        }
        if (
          e.target.value.length < 15 &&
          (charCode < 31 ||
            (charCode >= 48 && charCode <= 57) ||
            (charCode >= 96 && charCode <= 105))
        ) {
          return true;
        }
        if (charCode === 8 || charCode === 46) {
          return true;
        }
        if (charCode === 190 && e.target.value.includes(".")) {
          return true;
        }
        e.preventDefault();
        return false;
      }}
    />
  );
};

export default RangeField;
