import { useSelector } from "react-redux";

export const usePageActions = () => {
  const { all, megamenu, accordion, buttons, id, loading } = useSelector(
    (state) => state.PageActionsReducer
  );

  const getItemByID = (itemID) => {
    return all.find((it) => it.id === itemID);
  };
  return { all, megamenu, accordion, buttons, id, getItemByID, loading };
};
