import {
  BLOCKLIST_ADD_REMOVE_REQUEST,
  CHECK_PLAYERBLOCKLIST_REQUEST,
  PLAYERBLOCKLIST_HISTORY_REQUEST,
} from "../../../constants";

export const addRemoveBlock = (
  contractId,
  brand,
  partner,
  note,
  isBlacklisted,
  cb
) => {
  return {
    type: BLOCKLIST_ADD_REMOVE_REQUEST,
    contractId,
    brand,
    partner,
    note,
    isBlacklisted,
    cb,
  };
};

export const loadPlayerBlackListHistory = (contractId, query) => {
  return {
    type: PLAYERBLOCKLIST_HISTORY_REQUEST,
    contractId,
    query,
  };
};

export const checkPlayerBlackListStatus = (contractId) => {
  return {
    type: CHECK_PLAYERBLOCKLIST_REQUEST,
    contractId,
  };
};
