import axios from "axios";
import searchUtils from "../../utilities/searchUtils";
import { CRITERIA_TYPE_DATERANGE, CRITERIA_TYPE_SELECT } from "../constants";
import { labelPrefix } from "./closing-accounts-reducer";

class ClosingAccountsService {
  getListFilter = async () => {
    const filters = [];

    const playerStatuses = this.getPlayerStatuses();
    if (playerStatuses) {
      let playerStatusFilter = {
        name: "contract_closing_status_id_i",
        label: labelPrefix + "playerStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: playerStatuses.map((status) => {
          return {
            label: "label.playerSearch." + status,
            value: status.slice(-1),
          };
        }),
      };

      filters.push(playerStatusFilter);
    }

    const gameAccountStatuses = this.getGameAccountStatuses();
    if (gameAccountStatuses) {
      let gameAccountStatusFilter = {
        name: "ewl_status_i",
        label: labelPrefix + "gameAccountStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: gameAccountStatuses.map((status) => {
          return {
            label: "label.status." + status,
            value: status.split("_")[1],
          };
        }),
      };

      filters.push(gameAccountStatusFilter);
    }

    const closeAccountStatuses = this.getCloseAccountStatuses();
    if (closeAccountStatuses) {
      let closeAccountStatusFilter = {
        name: "closing_status_id_i",
        label: labelPrefix + "closeAccountStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: closeAccountStatuses.map((status) => {
          return {
            label: "label.status." + status,
            value: status.slice(-1),
          };
        }),
      };

      filters.push(closeAccountStatusFilter);
    }

    const lrtCheckClosureStatuses = this.getLrtCheckClosureStatuses();
    if (lrtCheckClosureStatuses) {
      let lrtCheckClosureStatusFilter = {
        name: "lrtCheckClosureStatus",
        label: labelPrefix + "lrtCheckClosureStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: lrtCheckClosureStatuses.map((status) => {
          return {
            label: "label.status." + status,
            value: status.slice(-1),
          };
        }),
      };

      filters.push(lrtCheckClosureStatusFilter);
    }

    const lrtWithdrawalStatuses = this.getLrtWithdrawalStatuses();
    if (lrtWithdrawalStatuses) {
      let lrtWithdrawalStatusFilter = {
        name: "lrtWithdrawalStatus",
        label: labelPrefix + "lrtWithdrawalStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: lrtWithdrawalStatuses.map((status) => {
          return {
            label: "label.status." + status,
            value: status.slice(-1),
          };
        }),
      };

      filters.push(lrtWithdrawalStatusFilter);
    }

    const lrtConfirmClosureStatuses = this.getLrtConfirmClosureStatuses();
    if (lrtConfirmClosureStatuses) {
      let lrtConfirmClosureStatusFilter = {
        name: "lrtConfirmClosureStatus",
        label: labelPrefix + "lrtConfirmClosureStatus",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: lrtConfirmClosureStatuses.map((status) => {
          return {
            label: "label.status." + status,
            value: status.slice(-1),
          };
        }),
      };

      filters.push(lrtConfirmClosureStatusFilter);
    }

    const playerContractIdFilter = {
      name: "contract_identity_s",
      label: labelPrefix + "playerContractId",
    };
    filters.push(playerContractIdFilter);

    const reservedDatePeriodFilter = {
      name: "reservedDatePeriod",
      label: labelPrefix + "reservedDatePeriod",
      type: CRITERIA_TYPE_DATERANGE,
      placeholder: "label.placeholders.date",
    };
    filters.push(reservedDatePeriodFilter);

    return filters;
  };

  getPlayerStatuses = () => {
    return [
      "contractStatus_3",
      "contractStatus_4",
      "contractStatus_6",
      "contractStatus_8",
      "contractStatus_9",
      "contractStatus_10",
    ];
  };

  getGameAccountStatuses = () => {
    return [
      "ewlGameAccountstatus_-1",
      "ewlGameAccountstatus_1",
      "ewlGameAccountstatus_2",
      "ewlGameAccountstatus_3",
      "ewlGameAccountstatus_5",
    ];
  };

  getCloseAccountStatuses = () => {
    return [
      "closingStatus_1",
      "closingStatus_2",
      "closingStatus_3",
      "closingStatus_9",
      "closingStatus_10",
    ];
  };

  getLrtCheckClosureStatuses = () => {
    return [
      "lrtTaskStates_0",
      "lrtTaskStates_1",
      "lrtTaskStates_2",
      "lrtTaskStates_3",
      "lrtTaskStates_4",
      "lrtTaskStates_5",
      "lrtTaskStates_6",
      "lrtTaskStates_7",
      "lrtTaskStates_8",
    ];
  };

  getLrtWithdrawalStatuses = () => this.getLrtCheckClosureStatuses();

  getLrtConfirmClosureStatuses = () => this.getLrtCheckClosureStatuses();

  getClosingAccounts = async (brandId, partnerId, searchTerms, query, fq) => {
    const url = "/gov/api/rest/v2/players/account-closure";

    let luceneQuery =
      searchTerms != null ? searchUtils.getBasicLuceneQuery(searchTerms) : null;
    luceneQuery = luceneQuery?.replace(/\\/g, "");
    luceneQuery = luceneQuery?.replace(/\"/g, "");
    const start = query.pageSize * query.page;
    const filterQuery = fq ? fq : null;
    let q = luceneQuery ? `(${luceneQuery})` : "*:*";
    const rows = query.pageSize;
    let sort = null;
    let sortMode = null;
    if (query.orderBy) {
      sort = query.orderBy.field;
      sortMode = query.orderBy.order === "desc" ? "desc" : "asc";
    }

    const body = {
      q,
      brandId,
      partnerId,
      filterQuery,
      rows,
      start,
      sort,
      sortMode,
      escapeChar: true,
    };
    const response = await axios.post(url, body);
    if (response && response.data && response.data.collection) {
      response.data.collection.forEach(async (el) => {
        if (el.tasks_ss) {
          el.tasks_ss.forEach((it) => {
            el[`closureStatus_${it[0]}`] = it[2];
          });
        }
      });

      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }

    throw Error("Invalid response Closing Accounts - Player List");
  };

  getDetail = async (brand, partner, parentId, contractIdentity) => {
    const url = "/gov/api/rest/v1/lrt/getPlayerTasks/" + contractIdentity;
    const response = await axios.get(url, {
      params: {
        brand: brand,
        partner: partner,
        "parent-id": parentId,
      },
    });
    if (response && response.data) {
      return {
        data: response.data,
      };
    }

    throw Error("Invalid response Closing Account - Get Detail");
  };

  retryTask = async (taskId, subTasks) => {
    const subTasksId = subTasks.map((subTask) => subTask.payerTaskComponentId);

    const url = "/gov/api/rest/v1/lrt/retryTaskComponent/" + taskId;
    const response = await axios.put(url, {
      playerComponentsIdList: subTasksId,
    });
    if (response && response.data) {
      return {
        data: response.data,
      };
    }

    throw Error("Invalid response Closing Account - Retry Task");
  };
}

const closingAccountsService = new ClosingAccountsService();
export default closingAccountsService;
