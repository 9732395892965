import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getIndividualPlayerDirectNotificationDetailHandler } from "../../store/players/individual-player/direct-notifications/actions";

export const useIndividualPlayerDirectNotificationDetail = (
  contractId,
  brand,
  partner,
  notificationId
) => {
  const { detail } = useSelector(
    (state) => state.DirectNotificationsReducer.directNotificationDetail
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (notificationId) {
      dispatch(
        getIndividualPlayerDirectNotificationDetailHandler(
          contractId,
          brand,
          partner,
          notificationId
        )
      );
    }
  }, [dispatch, contractId, brand, partner, notificationId]);

  return detail;
};
