import axios from "axios";

class TaskTypesService {
  async getTypes(brandId,partnerId) {
    let url = `/gov/api/rest/v1/lrt/getTaskTypes?brand=${brandId}&partner=${partnerId}`;

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response");
  }
}

const taskTypesService = new TaskTypesService();
export default taskTypesService;
