import { call, put, takeEvery } from "redux-saga/effects";
import { PWRECOVERY_ACTION, PWRECOVERY_REQUEST } from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";

import axios from "axios";

const pwRecoverSet = async ({ newPassword, confirmNewPassword, token }) => {
  let result;

  if (newPassword !== confirmNewPassword) {
    return (result = { error_message: "Password don't match " });
  }

  try { 
    const checkToken = await axios.get(
      "/gov/api/rest/verifyRecoveryToken?token=" + token
    );
    // eslint-disable-next-line
    const changePassword = await axios.put(
      "/gov/api/rest/changePasswordRecovery",
      {
        id: checkToken.data.userId,
        password: newPassword,
        token: token,
      }
    );
    result = { response: true };
  } catch (error) {
    result = { error_message: "Change password error" };
  }

  return result;
};

const pwRecovery = function* (action) {
  const data = yield call(pwRecoverSet, action.payload);
  yield put({
    type: PWRECOVERY_ACTION,
    payload: data,
  });
  if (data && data.response) {
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.passwordChanged",
        },
        options: {
          key: "recovery password",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
  }
};

const PwExpiredSaga = function* pwExpiredSaga() {
  yield takeEvery(PWRECOVERY_REQUEST, pwRecovery);
};

export default PwExpiredSaga;
