import { connect } from "react-redux";
import React from "react";
import * as Yup from "yup";
import Translate from "../../../../../Layouts/Translator/Translate";
import { Box, Button, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import { usePlayerDetail } from "../../../../../utilities/hooks/usePlayerDetail";
import {
  editKYCNote,
  changeKYCStatus,
} from "../../../../../store/players/individual-player/kyc-status/actions";
import { useSession } from "../../../../../utilities/hooks/useSession";
import { useRouteMatch } from "react-router-dom";
import { getCheckStatusMapping } from "../../../../../store/players/individual-player/kyc-status/kyc-status-reducer.js";
import UnsafeHtml from "../../../../../Layouts/unsafe-html";

const KYCStatusAction = ({
  data,
  handleOpenActionModal,
  actionType,
  actionColor,
  actionLabel,
  editNote,
  changeStatus,
}) => {
  const match = useRouteMatch();
  const contractId = match.params.id;
  const t = useTranslator();
  const { brandSelected, partnerSelected } = useSession();

  const { registrationLevelId } = usePlayerDetail(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    partnerSelected.pam
  );

  let initialValues = {
    statusId: "",
    action: "",
    note: "",
  };
  if (data) {
    initialValues = {
      statusId: data.identityCheckAccountStatusId,
      note: data.note,
      action: actionType,
    };
  }

  let status = getCheckStatusMapping(
    data.identityCheckStatus.identityCheckStatusId
  ).label;
  let labelStatus = "label.status." + status;
  let messageStatus = t({ needle: labelStatus });
  let message = t({
    needle: `label.player.kyc.actionMessage.${actionType}`,
    variables: [
      actionType === "EDIT_NOTE" ? messageStatus : data.contractKey.contractId,
    ],
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={
          actionType === "EDIT_NOTE"
            ? Yup.object().shape({
                note: Yup.string().required(
                  t({ needle: "label.player.playerNotes.errors.note" })
                ),
              })
            : null
        }
        onSubmit={(fields) => {
          actionType === "EDIT_NOTE"
            ? editNote(
                brandSelected,
                partnerSelected,
                fields,
                contractId,
                registrationLevelId
              )
            : changeStatus(
                brandSelected,
                partnerSelected,
                fields,
                contractId,
                registrationLevelId
              );
          handleOpenActionModal();
        }}
        render={({
          values,
          errors,
          status,
          handleChange,
          handleBlur,
          touched,
        }) => (
          <Form>
            <Box p={2} pt={0}>
              <UnsafeHtml dangerouslySetInnerHTML={{ __html: message }} />
              <TextField
                error={errors.value && touched.value}
                helperText={errors.value && touched.value ? errors.value : null}
                fullWidth
                name={"note"}
                label={<Translate needle={"label.player.kyc.note"} />}
                multiline
                rows="3"
                value={actionType === "EDIT_NOTE" ? values.note : null}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
              <Box mr={1}>
                <Button
                  variant={"outlined"}
                  onClick={() => handleOpenActionModal()}
                >
                  <Translate needle={"button.cancel"} />
                </Button>
              </Box>
              <Box>
                {actionType === "EDIT_NOTE" ? (
                  <Button color={"primary"} type="submit">
                    <Translate needle={"button.save"} />{" "}
                  </Button>
                ) : (
                  <Button color={actionColor} type="submit">
                    <Translate needle={actionLabel} />{" "}
                  </Button>
                )}
              </Box>
            </Box>
          </Form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.KYCStatusReducer;
  return { data };
};

const mapDispatchToProps = {
  editNote: editKYCNote,
  changeStatus: changeKYCStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(KYCStatusAction);
