import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  DESK_APPROVE_REJECT_FAILURE,
  DESK_APPROVE_REJECT_REQUEST,
  DESK_APPROVE_REJECT_SUCCESS,
  DESK_EXTERNALIDS_REQUEST,
  DESK_EXTERNALIDS_REQUEST_FAILURE,
  DESK_EXTERNALIDS_REQUEST_SUCCESS,
  DESK_FILTER_FAILURE,
  DESK_FILTER_REQUEST,
  DESK_FILTER_SUCCESS,
  DESK_REWARD_RELOAD,
  DESK_SEARCH_FAILURE,
  DESK_SEARCH_REQUEST,
  DESK_SEARCH_SUCCESS,
} from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import deskService from "./desk-service";

const getDeskFilters = function* (action) {
  try {
    const data = yield call(
      deskService.getDeskFilters,
      action.rewardTypeOnchange
    );
    yield put({
      type: DESK_FILTER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("processRewardType", e);
    yield put({
      type: DESK_FILTER_FAILURE,
    });
  }
};

const searchDeskReward = function* (action) {
  try {
    let searchTerms = {
      ...action.terms,
      requestDateFrom: action.terms.requestDate?.from || "",
      requestDateTo: action.terms.requestDate?.to || "",
      approvalDateFrom: action.terms.approvalDate?.from || "",
      approvalDateTo: action.terms.approvalDate?.to || "",
      amountFrom: +action.terms.amountFrom * action.divisorAmount || "",
      amountTo: +action.terms.amountTo * action.divisorAmount || "",
    };

    delete searchTerms["requestDate"];
    delete searchTerms["approvalDate"];

    const data = yield call(deskService.searchDeskReward, searchTerms);
    yield put({
      type: DESK_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("searchDeskReward", e);
    yield put({
      type: DESK_SEARCH_FAILURE,
    });
  }
};

const reloadDeskList = function* () {
  const lastRequest = yield select(
    (state) => state.DeskReducer.deskReward.lastRequest
  );
  yield put(lastRequest);
};

const approveRejectDesk = function* (action) {
  try {
    const data = yield call(deskService.approveRejectDesk, action.body);
    yield put({
      type: DESK_REWARD_RELOAD,
    });

    yield put({
      type: DESK_APPROVE_REJECT_SUCCESS,
      payload: data,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: action.body.approve
            ? "label.desk.approved"
            : "label.desk.rejected",
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("approveRejectDesk", e);
    yield put({
      type: DESK_APPROVE_REJECT_FAILURE,
    });
  }
};

const getExternalIDs = function* (action) {
  try {
    const { rewardType } = action;
    const data = yield call(deskService.getExternalIDs, rewardType);
    yield put({
      type: DESK_EXTERNALIDS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("error getting externalIds", e);
    yield put({
      type: DESK_EXTERNALIDS_REQUEST_FAILURE,
    });
  }
};

const DeskSaga = function* deskSaga() {
  yield takeEvery(DESK_FILTER_REQUEST, getDeskFilters);
  yield takeEvery(DESK_REWARD_RELOAD, reloadDeskList);
  yield takeEvery(DESK_APPROVE_REJECT_REQUEST, approveRejectDesk);
  yield takeEvery(DESK_SEARCH_REQUEST, searchDeskReward);
  yield takeEvery(DESK_EXTERNALIDS_REQUEST, getExternalIDs);
};

export default DeskSaga;
