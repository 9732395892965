import React from "react";
import { Box, SvgIcon } from "@material-ui/core";
import { useTranslator } from "./hooks/useTranslator";
import { ReactComponent as NoDataIcon } from "../Images/icon_error.svg";

const NoDataToShow = (props) => {
  let { icon, message, messageVariables } = props;
  const t = useTranslator();

  return (
    <Box
      py={3}
      flexGrow={1}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box fontSize={60} display={"flex"}>
        <SvgIcon
          fontSize={"inherit"}
          color={"primary"}
          component={icon ? icon : NoDataIcon}
        />
      </Box>
      <Box fontSize={16}>
        {t({
          needle: message ? message : "label.noDataToShow",
          variables: messageVariables ? messageVariables : null,
        })}
      </Box>
    </Box>
  );
};

export default NoDataToShow;
