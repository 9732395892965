import React from "react";
import Table from "../../../../../Layouts/Table/Table";
import { useSession } from "../../../../../utilities/hooks/useSession";
import { usePlayerFavorites } from "../../../../../utilities/hooks/usePlayerFavorites";
import { useRouteMatch } from "react-router-dom";
import { Box } from "@material-ui/core";
import Action from "../../../../../Layouts/action/action";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Edit from "@material-ui/icons/Edit";
import Modal from "../../../../../Layouts/Modal/Modal";
import Translate from "../../../../../Layouts/Translator/Translate";
import FavoriteForm from "./player-favorites-form";
import Locations from "../../../../Admin/locations";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import { isActionHidden } from "../../../../../utils";
import { usePageActions } from "../../../../../utilities/hooks/usePageActions";

const PlayerFavorites = ({ widget }) => {
  const t = useTranslator();
  const { brandSelected, partnerSelected } = useSession();
  const match = useRouteMatch();
  const contractId = match.params.id;
  const { data, columns, loading = true, widgetFields } = usePlayerFavorites(
    brandSelected,
    partnerSelected,
    contractId
  );
  const [editModal, setEditModal] = React.useState(null);
  const [actionType, setActionType] = React.useState(null);
  const [createModal, setCreateModal] = React.useState(null);
  const { buttons } = usePageActions();

  let dataWidget = [];
  let results = {};

  function getLastestData(data) {
    if (data) {
      results = data.reduce(function (obj, it) {
        (obj[it.category] = obj[it.category] || []).push(it);
        return obj;
      }, {});
      Object.keys(results).map((it) => {
        results[it].sort((a, b) => b.timestamp - a.timestamp);
        dataWidget.push(results[it][0]);
        return results;
      });
      return dataWidget;
    }
    return null;
  }
  let latestData = getLastestData(data);

  return (
    <>
      {!widget ? (
        <>
          <Box align={"right"} mb={2}>
            <Action
              startIcon={<AddCircleIcon />}
              color={"success"}
              onClick={() => setCreateModal(true)}
              permission={"add_favorite"}
              label={"label.player.playerFavorites.addLocation"}
            />
          </Box>
          <Table
            pageSize={10}
            type={"advanced"}
            resize={false}
            pagination={true}
            exportButton={false}
            title={"label.player.playerFavorites.tableTitle"}
            columns={columns}
            data={data}
            isLoading={loading}
            actions={[
              (rowData) => ({
                icon: Edit,
                color: "primary",
                variant: "contained",
                tooltip: "button.edit",
                hideMobile: true,
                hidden: isActionHidden(buttons, "edit_favorite"),
                onClick: (event, rowData) => {
                  setActionType("edit");
                  setEditModal(rowData);
                },
              }),
            ]}
          />
        </>
      ) : (
        <Table
          type={"simple"}
          resize={false}
          exportButton={false}
          title={"label.player.playerFavorites.tableTitle"}
          columns={widgetFields}
          data={latestData}
          isLoading={loading}
        />
      )}
      <Modal
        open={Boolean(editModal)}
        title={
          <Translate
            needle={
              actionType === "add"
                ? "label.player.playerFavorites.addFavorite"
                : "label.player.playerFavorites.editFavorite"
            }
          />
        }
        description={t({
          needle:
            actionType === "add"
              ? "label.player.playerFavorites.addFavoriteDesc"
              : "label.player.playerFavorites.editFavoriteDesc",
          variables: [editModal?.name, editModal?.code, contractId],
        })}
        content={
          <FavoriteForm
            handleClose={() => setEditModal(null)}
            data={editModal}
            actionType={actionType}
          />
        }
      />
      <Modal
        open={Boolean(createModal)}
        title={
          <Translate needle={"label.player.playerFavorites.addFavorite"} />
        }
        content={
          <Locations
            handleCloseModal={() => setCreateModal(null)}
            add="add"
            handleOpenEdit={(rowData) => {
              setEditModal(rowData);
              setActionType("add");
            }}
          />
        }
        maxWidth={"lg"}
      />
    </>
  );
};

export default PlayerFavorites;
