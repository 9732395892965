const INITIAL_STATE = {
  result: null,
};

const PwResetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
  }
};

export default PwResetReducer;
