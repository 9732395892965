import React from "react";
import Moment from "react-moment";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  CHECK_PLAYERBLOCKLIST_SUCCESS,
  PLAYERBLOCKLIST_HISTORY_FAILURE,
  PLAYERBLOCKLIST_HISTORY_REQUEST,
  PLAYERBLOCKLIST_HISTORY_SUCCESS,
} from "../../../constants";

export const labelPrefix = "label.individualplayer.blocklist.";
const initialState = {
  blockListHistory: {
    loading: true,
    data: null,
    columns: [
      {
        field: "creationDate",
        title: `${labelPrefix}creationDate`,
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          }
        },
      },
      {
        field: "action",
        title: `${labelPrefix}action`,
        render: (rowData) => {
          var actionType = rowData["action"];
          return (
            <Translate needle={labelPrefix + "blockTypes." + actionType} />
          );
        },
      },
      {
        field: "attributeType",
        title: labelPrefix + "attributeType",
        render: (rowData) => {
          var addType = rowData["attributeType"];
          return (
            <Translate needle={labelPrefix + "attributeTypes." + addType} />
          );
        },
      },
      {
        field: "attributeValue",
        title: `${labelPrefix}attributeValue`,
      },
      {
        field: "reason",
        title: `${labelPrefix}reason`,
      },
      {
        field: "boUsername",
        title: `${labelPrefix}boUsername`,
      },
    ],
  },
  isPlayerBlacklistedData: {
    isBlacklisted: false,
  },
};

const BlocklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYERBLOCKLIST_HISTORY_REQUEST:
      return {
        ...state,
        blockListHistory: {
          ...state.blockListHistory,
          loading: true,
        },
      };
    case PLAYERBLOCKLIST_HISTORY_SUCCESS:
      return {
        ...state,
        blockListHistory: {
          ...state.blockListHistory,
          data: action.payload,
          loading: false,
        },
      };
    case PLAYERBLOCKLIST_HISTORY_FAILURE:
      return {
        ...state,
        blockListHistory: {
          ...state.blockListHistory,
          data: null,
          loading: false,
        },
      };
    case CHECK_PLAYERBLOCKLIST_SUCCESS:
      return {
        ...state,
        isPlayerBlacklistedData: {
          ...state.isPlayerBlacklistedData,
          isBlacklisted: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default BlocklistReducer;
