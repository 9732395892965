import axios from "axios";

class LoyaltyDetailService {
  async getLoyaltyDetails(
    contractId,
  ) {
    let url = `/gov/api/rest/v1/tcm/${contractId}/player?currency-type=LOYALTY`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for loyalty details");
  }
}

const loyaltyDetailService = new LoyaltyDetailService();
export default loyaltyDetailService;
