import React from "react";
import { useDispatch } from "react-redux";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Button from "@material-ui/core/Button";
import { useSession } from "../../../../utilities/hooks/useSession";
import { verifyPin } from "../../../../store/players/individual-player/contact-verification/actions";
const ContactVerify = ({
  brand,
  partner,
  contractId,
  channelName,
  handleClose,
}) => {
  const t = useTranslator();
  const { user } = useSession();
  const dispatch = useDispatch();

  return (
    <Box p={2} pb={0} pt={0}>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={Yup.object().shape({
          otp: Yup.string().required(
            t({
              needle:
                "label.individualplayer.contactConfig.verifyModal.otpError",
            })
          ),
        })}
        onSubmit={(fields) => {
          const data = {
            channelName,
            otp: fields.otp,
            contractId,
            verificationUser: user.username,
          };
          dispatch(verifyPin(brand, partner, data, handleClose));
        }}
      >
        {({ values, errors, handleChange, handleBlur, touched }) => (
          <Form>
            <TextField
              name="otp"
              type="String"
              label={t({
                needle: "label.individualplayer.contactConfig.verifyModal.otp",
              })}
              placeholder={t({
                needle:
                  "label.individualplayer.contactConfig.verifyModal.otpPlaceholder",
              })}
              value={values.otp}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={errors.otp && touched.otp}
              helperText={errors.otp && touched.otp && errors.otp}
            />

            <Box display={"flex"} justifyContent={"flex-end"} pt={5}>
              <Box mr={1}>
                <Button
                  variant={"outlined"}
                  color={"primary"}
                  type="button"
                  onClick={() => handleClose()}
                  //disabled={loading}
                >
                  {t({
                    needle: "button.cancel",
                  })}
                </Button>
              </Box>
              <Box>
                <Button color={"primary"} type={"submit"}>
                  {t({ needle: "button.verifyMobileNumber" })}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ContactVerify;
