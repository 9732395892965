import React, { Component } from "react";
import { Box, Button } from "@material-ui/core";
import { connect } from "react-redux";
import Delete from "@material-ui/icons/Delete";
import { withTranslate } from "../../../../../utilities/hocs/withTranslate";
import {
  deleteIndividualPlayerGroup,
  getGroupList,
  individualPlayerGroups,
  saveIndividualPlayerGroup,
} from "../../../../../store/players/individual-player/player-groups/actions";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Table from "../../../../../Layouts/Table/Table";
import Modal from "../../../../../Layouts/Modal/Modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AutocompleteField from "../../../../../Layouts/form/autocomplete-field";
import { isMobileOnly } from "react-device-detect";

class IndividualPlayerGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        page: 0,
        pageSize: props.widget || props.onDashboard ? 5 : 10,
        orderBy: { field: "name", order: "asc" },
      },
      brand: null,
      partner: null,
      openDeleteModal: false,
      openAddModal: false,
      name: null,
    };
  }

  componentDidMount() {
    const { brand, partner, widget } = this.props;
    const playerContact = this.props.match.params.id;
    this.props.getGroups(playerContact, brand, partner, widget);

    this.setState({ brand, partner });
  }

  handleOpenConfirmModal = (groupName) => {
    this.setState({ openDeleteModal: true, name: groupName });
  };

  handleCloseConfirmModal = () => {
    this.setState({ openDeleteModal: false });
  };

  handleDeleteRow = () => {
    const { brand, partner, deleteGroup } = this.props;
    const { name } = this.state;
    const rowId = this.props.match.params.id;

    deleteGroup(rowId, brand, partner, name);
    this.setState({ openDeleteModal: false });
  };

  handleOpenAddPlyModal = () => {
    const { brand, partner } = this.props;
    this.setState({ openAddModal: true });
    this.props.getGroupList(brand, partner);
  };

  handleCloseAddPlyModal = () => {
    this.setState({ openAddModal: false });
  };

  saveGroup = (fields) => {
    const { brand, partner } = this.props;
    const rowId = this.props.match.params.id;

    this.props.saveGroup(rowId, brand, partner, fields.group);
    this.setState({ openAddModal: false });
  };

  getModalContent = () => {
    const { t, data, groups = [] } = this.props;
    const gData = groups
      .filter(
        (el) => !(data || []).some((item) => item.name === el.name)
      )
      .map((it) => {
        return { label: it.name, value: it.name };
      });

    return (
      <Formik
        initialValues={{
          group: "",
        }}
        validationSchema={Yup.object().shape({
          group: Yup.string().required(
            t({ needle: "label.individualplayer.playerGroups.required" })
          ),
        })}
        onSubmit={(fields) => {
          this.saveGroup(fields);
        }}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form>
            <Box m={4} mt={0} justifyContent={"center"}>
              {gData ? (
                <AutocompleteField
                  name={"group"}
                  fullWidth
                  labelKey={"label"}
                  valueKey={"value"}
                  label={t({
                    needle:
                      "label.individualplayer.playerGroups.avalaibleGroups",
                  })}
                  options={gData}
                />
              ) : (
                t({ needle: "label.individualplayer.playerGroups.empty" })
              )}
            </Box>

            <Box display="flex" justifyContent="flex-end" m={1} mb={0}>
              <Box m={1} mb={0}>
                <Button
                  variant="outlined"
                  onClick={this.handleCloseAddPlyModal}
                >
                  {t({ needle: "button.cancel" })}
                </Button>
              </Box>
              <Box m={1} mb={0}>
                <Button type="submit" color="primary">
                  {t({ needle: "button.save" })}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { widget, columns, data, t, loading } = this.props;
    const { openDeleteModal, openAddModal, name, query } = this.state;

    return (
      <Box>
        {!isMobileOnly ? (
          <Box align={"right"} mb={2}>
            <Button
              startIcon={<AddCircleIcon />}
              label={t({
                needle: "label.individualplayer.playerGroups.create",
              })}
              color={"success"}
              onClick={this.handleOpenAddPlyModal}
            >
              {t({
                needle: "label.individualplayer.playerGroups.buttons.create",
              })}
            </Button>
          </Box>
        ) : null}

        <Table
          {...query}
          isLoading={loading}
          title={"playergroup.playerGroups"}
          type={widget ? "simple" : "advanced"}
          pagination={widget ? data != null && data.length > 5 : true}
          columns={columns}
          data={data}
          exportButton={false}
          resize={false}
          actions={[
            {
              icon: Delete,
              color: "failure",
              hideMobile: true,
              variant: "contained",
              tooltip: "button.delete",
              onClick: (event, rowData) => {
                this.handleOpenConfirmModal(rowData.name);
              },
            },
          ]}
        />
        <Modal
          open={openDeleteModal}
          title={t({
            needle: "label.modal.confirmDeletion",
            label: "Confirm Removal",
          })}
          description={t({
            needle: "label.individualplayer.playerGroups.warnDelete",
            variables: [name],
          })}
          actions={[
            {
              color: "primary",
              label: "button.cancel",
              variant: "outlined",
              handler: this.handleCloseConfirmModal,
            },
            {
              color: "failure",
              label: "button.delete",
              handler: this.handleDeleteRow,
            },
          ]}
        />
        <Modal
          open={openAddModal}
          title={t({ needle: "label.individualplayer.playerGroups.add" })}
          content={this.getModalContent()}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    brandSelected,
    partnerSelected,
  } = state.AuthenticationReducer.session;
  const { loading } = state.IndividualPlayerGroupsReducer;
  const {
    data,
    columns,
  } = state.IndividualPlayerGroupsReducer.individualPlayerGroups;
  const {
    groups,
  } = state.IndividualPlayerGroupsReducer.individualPlayerGroupList;
  return {
    loading,
    data,
    columns,
    groups,
    brand: brandSelected.id,
    partner: partnerSelected.id,
  };
};

const mapDispatchToProps = {
  getGroups: individualPlayerGroups,
  deleteGroup: deleteIndividualPlayerGroup,
  getGroupList: getGroupList,
  saveGroup: saveIndividualPlayerGroup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslate(IndividualPlayerGroups));
