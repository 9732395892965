import axios from "axios";

class PlayerDocumentsService {
  async getPlayerDocuments(brandId, partnerId, contractId, registrationLevel) {
    const url = `/gov/api/rest/v1/dom/documents?registrationLevel=${registrationLevel}`;
    const response = await axios.get(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
        "X-PLAYER-ID": contractId,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for player documents list");
  }

  async getPlayerDocumentsHistory(
    brandId,
    partnerId,
    contractId,
    documentType
  ) {
    const url = `/gov/api/rest/v1/dom/document-history?document-type=${documentType}`;
    const response = await axios.get(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
        "X-PLAYER-ID": contractId,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for player document history");
  }

  async saveOrUpdatePlyDoc(
    brandId,
    partnerId,
    contractId,
    registrationLevel,
    document
  ) {
    const url = `/gov/api/rest/v1/dom/documents/${contractId}`;
    const response = await axios.put(
      url,
      {
        playerLevel: registrationLevel,
        brand: brandId,
        partner: partnerId,
        document: document,
      },
      {
        headers: {
          "X-BRAND-ID": brandId,
          "X-PARTNER-ID": partnerId,
        },
      }
    );

    if (response && response.status !== 204) {
      throw Error("Invalid response for save or update new document");
    }
  }

  async getDocumentTypes(brandId, partnerId, contractId, registrationLevel) {
    var url = `/gov/api/rest/v1/dom/document-types?registrationLevel=${registrationLevel}`;
    if (contractId) {
      url = `${url}&contractId=${contractId}`;
    }
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      const list = response.data.map((it) => {
        it.attributeList.forEach((element) => {
          element.name = element.field;
        });
        return it;
      });
      return list;
    }

    throw Error("Invalid response for document type list");
  }

  async deleteDocument(
    brandId,
    partnerId,
    contractId,
    document
  ) {
    const url = `/gov/api/rest/v1/dom/documents/delete`;
    const response = await axios({
      method: "delete",
      url: url,
      data: document,
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
        "X-PLAYER-ID": contractId
      },
    });

    if (response && response.status !== 204) {
      throw Error("Invalid response for delete document");
    }
  }
}

const playerDocumentsService = new PlayerDocumentsService();
export default playerDocumentsService;
