// eslint-disable-next-line
import { useSelector } from "react-redux";

const currencyColors = {
  cad: "#D8534F",
  eur: "#5DB85D",
  gbp: "#4559AD",
  sek: "#F0AD4E",
  usd: "#2EA59F",
  aud: "#006C66",
  cny: "#EB5B00",
  points: "#0C51A1",
  default: "#4A4A4A",
};

export const useCurrencyColor = (currency) => {
  // const { currencyColors } = useSelector(
  //   (state) => state.SomeReducer
  // );
  return currency
    ? currencyColors[currency.toLowerCase()] || currencyColors["default"]
    : null;
};
