import { FieldArray, useField, useFormikContext } from "formik";
import { Box, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { AddCircle, DeleteForeverOutlined } from "@material-ui/icons";
import React from "react";
import { FormField } from "./index";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Translate from "../Translator/Translate";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { isFieldDisabled } from "./utils";
const useStyle = makeStyles((theme) => ({
  arrayFieldContainer: {
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
    "& + &": {
      marginTop: theme.spacing(2),
      "& .MuiFormLabel-root": {
        display: "none",
      },
    },
  },
  addButton: {
    color: theme.palette.success.main,
  },
}));
const ArrayField = ({ label, name, items, disableAdd, objetToPush }) => {
  const { values } = useFormikContext();
  const [field, meta, helpers] = useField({ name });
  const classes = useStyle();
  const t = useTranslator();
  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <Box mt={2}>
          {values[name].map((row, index) => {
            return (
              <Box
                key={index}
                display={"flex"}
                alignItems={"center"}
                className={classes.arrayFieldContainer}
              >
                <Box
                  width={"calc(100% - 50px)"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {items.map((it) => {
                    const options =
                      it.optionsFn != null && typeof it.optionsFn === "function"
                        ? it.optionsFn(index)
                        : it.options;
                    return (
                      <Box
                        width={`calc(${(1 / items.length) * 100}% - 8px)`}
                        key={`${name}.${index}.${it.name}`}
                      >
                        <FormField
                          fullWidth
                          {...it}
                          label={t({ needle: it.label })}
                          disabled={isFieldDisabled(it, values, index)}
                          onChange={(v) => {
                            return it.onChange != null &&
                              typeof it.onChange === "function"
                              ? it.onChange(v, index, field, helpers)
                              : null;
                          }}
                          options={options}
                          name={`${name}.${index}.${it.name}`}
                        />
                      </Box>
                    );
                  })}
                </Box>
                {index > 0 ? (
                  <Box>
                    <IconButton
                      title={t({
                        needle: "label.form.arrayField.deleteButton",
                      })}
                      color={"failure"}
                      onClick={() => remove(index)}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Box>
                ) : null}
              </Box>
            );
          })}
          <Box mt={1}>
            <Button
              variant={"text"}
              className={classes.addButton}
              startIcon={<AddCircle />}
              onClick={() => {
                push(objetToPush);
              }}
              disabled={disableAdd()}
            >
              <Translate
                needle={"label.form.arrayField.addButton"}
                variables={[label]}
              />
            </Button>
          </Box>
        </Box>
      )}
    </FieldArray>
  );
};
export default ArrayField;
