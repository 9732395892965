import React from "react";
import { useField, useFormikContext } from "formik";
import { Chip, makeStyles, TextField } from "@material-ui/core";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import FormLabel from "./form-label";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    marginTop: 35,
    height: 36,
    width: "25%",
  },
  uploadField: {
    width: "75%",
  },
  valueChip: {
    maxWidth: "98%",
    borderRadius: 3,
    margin: 3,
    height: 28,
    backgroundColor: theme.palette.divider,
    color: theme.palette.text.secondary3,
    "& .MuiChip-deleteIcon": {
      color: "inherit",
    },
    textOverflow: "ellipsis",
  },
}));

function FileField({
  isConditional = false,
  optional = true,
  label,
  tooltip,
  placeholder,
  ...props
}) {
  const [field, meta] = useField(props);
  const t = useTranslator();
  const { setFieldValue } = useFormikContext();
  const inputFile = React.useRef(null);
  const classes = useStyles();

  function _renderHelperText() {
    const { touched, error } = meta;

    if (touched && error && !isConditional) {
      return error;
    }
  }

  const plchld = placeholder
    ? typeof placeholder === "string"
      ? t({ needle: placeholder })
      : placeholder
    : null;

  const handleClick = (event) => {
    inputFile.current.click();
  };

  const handleDelete = () => {
    setFieldValue(field.name, "");
  };

  return (
    <>
      <TextField
        {...field}
        placeholder={plchld}
        value={""}
        type={"file"}
        onChange={(event) => {
          setFieldValue(field.name, event.currentTarget.files[0]);
        }}
        inputRef={inputFile}
        style={{ display: "none" }}
      />

      <TextField
        placeholder={!field.value ? plchld : ""}
        label={
          label ? (
            <FormLabel optional={optional} label={label} tooltip={tooltip} />
          ) : null
        }
        error={meta.error && meta.touched}
        helperText={_renderHelperText()}
        className={classes.uploadField}
        InputProps={{
          readOnly: true,
          startAdornment: field.value && (
            <Chip
              label={field.value.name || field.value}
              onDelete={handleDelete}
              deleteIcon={<Close />}
              className={classes.valueChip}
            />
          ),
        }}
      />

      <Button
        className={classes.uploadButton}
        color="primary"
        onClick={handleClick}
      >
        {t({ needle: "button.chooseFile" })}
      </Button>
    </>
  );
}

export default FileField;
