import {
  END_EXPORT_ALL,
  EXPORT_ALL_IN_PROGRESS,
  START_EXPORT_ALL,
} from "../constants";

export const labelPrefix = "label.exportTable.";

const initialState = {
  exporting: false,
  filters: null,
  exportColumns: [],
  tableTitle: null,
};

const ExportReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_EXPORT_ALL: {
      return {
        ...state,
        exporting: true,
      };
    }
    case EXPORT_ALL_IN_PROGRESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case END_EXPORT_ALL: {
      return {
        ...state,
        exporting: false,
        data: null,
        filters: null,
        tableTitle: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default ExportReducer;
