import axios from "axios";

class CommunicationPreferencesService {
  async loadCommunicationPreferences(contractId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/communications/player/${contractId}/communication-preferences?brandId=${brandId}&partnerId=${partnerId}`;

    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }

  async updateCommunicationPreferences(contractId, brandId, partnerId, body) {
    let url = `/gov/api/rest/v1/communications/player/${contractId}/communication-preferences?brandId=${brandId}&partnerId=${partnerId}`;

    const response = await axios.put(url, body);
    if (response && response.status !== 204) {
      throw Error("Invalid response player communication preferences");
    }
  }
}

const communicationPreferencesService = new CommunicationPreferencesService();
export default communicationPreferencesService;
