import searchUtils from "../../../../utilities/searchUtils";
import axios from "axios";
import { labelPrefix } from "./bulk-requests-reducer";
import {
  CRITERIA_TYPE_DATERANGE,
  CRITERIA_TYPE_SELECT,
} from "../../../constants";

class BulkRequestsService {
  getListFilter = async (brand, partner) => {
    const filters = [
      {
        name: "requestId",
        label: labelPrefix + "requestId",
      },
      {
        name: "requestName",
        label: labelPrefix + "requestName",
      },
      {
        name: "fileOriginalName",
        label: labelPrefix + "fileOriginalName",
      },
      {
        name: "startDate",
        label: labelPrefix + "startDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "endDate",
        label: labelPrefix + "endDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "uploadStatus",
        label: labelPrefix + "uploadStatus",
        type: CRITERIA_TYPE_SELECT,
        multiple: true,
        placeholder: "label.placeholders.select",
        options: [
          {
            label: labelPrefix + "uploadStatusDropDown." + "open",
            value: "UPLOADING",
          },
          {
            label: labelPrefix + "uploadStatusDropDown." + "uploadSuccess",
            value: "ACQUIRED",
          },
          {
            label: labelPrefix + "uploadStatusDropDown." + "uploadError",
            value: "ERROR",
          },
        ],
      },
      {
        name: "processingStatus",
        label: labelPrefix + "processingStatus",
        type: CRITERIA_TYPE_SELECT,
        multiple: true,
        placeholder: "label.placeholders.select",
        options: [
          {
            label: labelPrefix + "processingStatusDropDown." + "toProcess",
            value: "TO_PROCESS",
          },
          {
            label: labelPrefix + "processingStatusDropDown." + "completed",
            value: "DONE",
          },
          {
            label: labelPrefix + "processingStatusDropDown." + "inError",
            value: "ERROR",
          },
          {
            label: labelPrefix + "processingStatusDropDown." + "processing",
            value: "PROCESSING",
          },
          {
            label: labelPrefix + "processingStatusDropDown." + "inRetry",
            value: "IN_RETRY",
          },
        ],
      },
      {
        name: "requestStatus",
        label: labelPrefix + "requestStatus",
        type: CRITERIA_TYPE_SELECT,
        multiple: true,
        placeholder: "label.placeholders.select",
        options: [
          {
            label: labelPrefix + "requestStatusDropDown." + "toStart",
            value: "TO_START",
          },
          {
            label: labelPrefix + "requestStatusDropDown." + "inProgress",
            value: "ONGOING",
          },
          {
            label: labelPrefix + "requestStatusDropDown." + "error",
            value: "ERROR",
          },
          {
            label: labelPrefix + "requestStatusDropDown." + "completed",
            value: "COMPLETED_SUCCESFULLY",
          },
          {
            label:
              labelPrefix + "requestStatusDropDown." + "completedWithWarning",
            value: "COMPLETED_WITH_WARNING",
          },
        ],
      },
    ];
    const taskTypes = await this.getTaskTypes(brand, partner);
    if (taskTypes) {
      let taskTypeFilter = {
        name: "taskType",
        label: labelPrefix + "taskType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: taskTypes.taskTypeList.map((taskType) => {
          return {
            label: labelPrefix + "taskTypes." + taskType.taskDescription,
            value: taskType.taskDescription,
          };
        }),
      };
      filters.push(taskTypeFilter);
    }
    return filters;
  };

  loadBulkRequests = async (query, finalSearchTerms, brandId, partnerId) => {
    let url = `/gov/api/rest/v1/bulk-requests/requests`;
    const terms = finalSearchTerms
      ? searchUtils.sanitizeTerms(finalSearchTerms)
      : "";

    const response = await axios.get(url, {
      params: {
        page: query.page + 1,
        pageSize: query.pageSize,
        sort: `${query.orderBy.field} ${query.orderBy.order}`,
        ...terms,
      },
    });
    if (response && response.data) {
      return {
        data: response.data.bulkRequestResponseDTOPaginated.collection,
        page: query.page,
        totalCount: response.data.bulkRequestResponseDTOPaginated.totalCount,
      };
    }
    throw Error("Invalid response Bulk Requests");
  };

  getTaskTypes = async (brand, partner) => {
    const url = `/gov/api/rest/v1/bulk-requests/task-type`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting tast types");
  };

  loadBulkRequestsTransactionsById = async (id, brandId, partnerId, query) => {
    const url = `/gov/api/rest/v1/bulk-requests/requests/${id}/tasks`;

    const response = await axios.get(url, {
      params: {
        page: query.page + 1,
        pageSize: query.pageSize,
        sort: `${query.orderBy.field} ${query.orderBy.order}`,
      },
    });
    if (response && response.data) {
      return {
        data: response.data.playerTaskDTOPaginated.collection,
        page: query.page,
        totalCount: response.data.playerTaskDTOPaginated.totalCount,
      };
    }
    throw Error("Error getting tasks by ID");
  };

  async addNewBulkRequest(brandId, partnerId, body) {
    let dataForm = new FormData();
    dataForm.set("requestName", body.requestName);
    dataForm.set("taskType", body.taskType);
    dataForm.append("fileOriginalName", body.csvFile.name);
    dataForm.append("muteNotification", body.muteNotification);
    if (body.csvFile != null && body.csvFile != "") {
      dataForm.append("file", body.csvFile);
    }
    dataForm.append("requestReason", body.requestReason);
    const response = await axios({
      method: "post",
      url: "/gov/api/rest/v1/bulk-requests/upload",
      data: dataForm,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response && response.data) {
      return response.data;
    }
    if (response == null || response.status !== 204) {
      throw Error("Invalid response for add bulk request");
    }
  }
}

const bulkRequestsService = new BulkRequestsService();
export default bulkRequestsService;
