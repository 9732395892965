import { call, put, takeEvery } from "redux-saga/effects";
import {
  PWCHANGE_ACTION,
  PWCHANGE_REQUEST,
  PW_RESET_REQUEST,
} from "../constants";
import passwordServices from "./password-services";
import axios from "axios";
import { enqueueSnackbar } from "../snackbars/actions";

const pwChangeService = async (payload) => {
  let result;

  try {
    // eslint-disable-next-line
    const response = await axios.put(
      "/gov/api/rest/v2/users/change_own_password",
      {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      }
    );
    result = { response: true };
  } catch (error) {
    let params;
    let labelError = error.response.data;
    if (labelError.indexOf("_") !== -1) {
      const index = labelError.indexOf("_");
      params = error.response.data.substr(index + 1);
      labelError = error.response.data.substr(0, index);
    }
    result = {
      error_message: "Generic error",
      error: labelError,
      error_params: params,
    };
  }

  return result;
};

const pwReset = function* (action) {
  try {
    const data = yield call(
      passwordServices.pwResetCheck,
      action.currentPassword,
      action.newPassword,
      action.username
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.passwordChanged",
        },
        options: {
          key: "passwordExpired",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (error) {
    //do nothing as the method is not handling the runtime exception
  }
};

const pwChange = function* (action) {
  const data = yield call(pwChangeService, action.payload);
  yield put({
    type: PWCHANGE_ACTION,
    payload: data,
  });
  if (data.response) {
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.userPasswordSuccess",
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
  }
  action.cb != null && typeof action.cb === "function" && action.cb();
};

const PwExpiredSaga = function* pwExpiredSaga() {
  yield takeEvery(PW_RESET_REQUEST, pwReset);
  yield takeEvery(PWCHANGE_REQUEST, pwChange);
};

export default PwExpiredSaga;
