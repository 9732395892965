import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import {
  CRITERIA_TYPE_DATERANGE,
  CRITERIA_TYPE_SELECT,
} from "../../../constants";
import { labelPrefix } from "./lottery-blocklist-reducer";
import React from "react";
import Translate from "../../../../Layouts/Translator/Translate";

class LotteryBlocklistService {
  getListFilter = async () => {
    const filters = [];
    const attributeTypes = await this.getAttributeTypes();
    if (attributeTypes) {
      let attributeTypeFilter = {
        name: "attributeType",
        label: labelPrefix + "attributeType",
        type: CRITERIA_TYPE_SELECT,
        tooltip: {
          title: <Translate needle={labelPrefix + "documentTypeInputFormat"} />,
        },
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: attributeTypes.map((attributeType) => {
          return {
            label: labelPrefix + "attributeTypes." + attributeType,
            value: attributeType,
          };
        }),
      };

      filters.push(attributeTypeFilter);
    }

    filters.push(
      {
        name: "attributeValue",
        label: labelPrefix + "attributeValue",
      },

      {
        name: "added",
        label: labelPrefix + "added",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "fileName",
        label: labelPrefix + "fileName",
      },
      {
        name: "addType",
        label: labelPrefix + "addType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          { label: labelPrefix + "manually", value: "MANUAL" },
          { label: labelPrefix + "byFile", value: "BY_FILE" },
        ],
      }
    );
    return filters;
  };

  loadLotteryBlocklist = async (query, searchTerms) => {
    let url = `/gov/api/rest/v1/orc/lotteryBlacklistAttributes`;
    const terms = searchTerms ? searchUtils.sanitizeTerms(searchTerms) : "";

    const response = await axios.get(url, {
      params: {
        page: query.page + 1,
        size: query.pageSize,
        "sort-by": query.orderBy.field,
        "sort-ascending": query.orderBy.order == "asc" ? true : false,
        ...terms,
      },
    });
    if (response && response.data && response.data.collection) {
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }
    throw Error("Invalid response lottery blocklist");
  };

  getAttributeTypes = async () => {
    const url = `/gov/api/rest/v1/orc/blacklistAttributeTypes`;

    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting attribute types");
  };
}

const lotteryBlocklistService = new LotteryBlocklistService();
export default lotteryBlocklistService;
