import React, { Component, Suspense } from "react";

import { SnackbarProvider } from "notistack";
import { browserName, browserVersion } from "react-device-detect";
import UpgradeBrowser from "./Pages/UpgradeBrowser/UpgradeBrowser";
import { connect } from "react-redux";
import { localeHandler } from "./store/locale/actions";
import SpinnerIndicator from "./Layouts/SpinnerIndicator/SpinnerIndicator";
import { CssBaseline } from "@material-ui/core";

import { ThemeProvider } from "@material-ui/styles";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Close from "@material-ui/icons/Close";
import Info from "@material-ui/icons/Info";
import Warning from "@material-ui/icons/Warning";
import * as moment from "moment";
import "moment/min/locales";
import Moment from "react-moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AdvancedMomentUtils from "./AdvancedMomentUtils";
import Notifier from "./Layouts/snackbars/notifier";
import { IntlProvider } from "react-intl";
import themes from "./styles/common";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./UnauthenticatedApp"));

class App extends Component {
  constructor(props) {
    super(props);
    this.snackbarRef = React.createRef();
  }

  componentDidMount() {
    this.props.localeHandler(this.props.locale);
  }

  dismissSnackbar(key) {
    this.snackbarRef.current.closeSnackbar(key);
  }

  render() {
    const { session, theme, locale, highContrast } = this.props;
    if (theme === "dark") {
      document.querySelector("body").classList.add("dark");
    } else {
      document.querySelector("body").classList.remove("dark");
    }
    const customTheme = themes[theme + (highContrast ? "HC" : "")];
    moment.locale(locale);
    Moment.globalMoment = moment;
    Moment.globalLocale = locale;
    return (
      <ThemeProvider theme={customTheme}>
        <MuiPickersUtilsProvider
          utils={AdvancedMomentUtils}
          libInstance={moment}
          locale={locale}
        >
          <CssBaseline />
          <SnackbarProvider
            ref={this.snackbarRef}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            action={(key) => (
              <Close
                fontSize={"small"}
                onClick={() => this.dismissSnackbar(key)}
              />
            )}
            iconVariant={{
              success: <CheckCircle />,
              error: <Cancel />,
              warning: <Warning />,
              info: <Info />,
            }}
          >
            <Notifier />
            <IntlProvider locale={locale.replace("_", "-")}>
              {browserName === "IE" && parseInt(browserVersion) < 11 ? (
                <UpgradeBrowser />
              ) : (
                <Suspense fallback={<SpinnerIndicator />}>
                  {session ? <AuthenticatedApp /> : <UnauthenticatedApp />}
                </Suspense>
              )}
            </IntlProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale, theme, highContrast } = state.MainReducer;
  const { session } = state.AuthenticationReducer;
  return { locale, theme, session, highContrast };
};
const mapDispatchToProps = {
  localeHandler,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
