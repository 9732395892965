import {
  COUNTRIES_REQUEST,
  LANGUAGES_REQUEST,
  LOCALE_REQUEST,
  PLAYER_LANGUAGES_REQUEST,
} from "../constants";

export const localeHandler = (currentLocale) => {
  return {
    type: LOCALE_REQUEST,
    payload: currentLocale,
  };
};

export const languagesHandler = () => {
  return {
    type: LANGUAGES_REQUEST,
  };
};

export const getPlayerLanguages = () => {
  return {
    type: PLAYER_LANGUAGES_REQUEST,
  };
};

export const getCountries = (brand, partner) => {
  return {
    type: COUNTRIES_REQUEST,
    brand,
    partner,
    onlyEnabled: false,
  };
};
