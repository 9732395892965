import React from "react";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import useGdprCheck from "../../../../utilities/hooks/useGdprCheck";
import { useSession } from "../../../../utilities/hooks/useSession";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { checkPlayerBlackListStatus } from "../../../../store/players/individual-player/blocklist/actions";

const AccountInfo = ({ playerInfo }) => {
  const { getFields, piFields } = useSelector(
    (state) => state.PlayerDetailReducer.playerDetails.accountInfo
  );
  const { isBlacklisted } = useSelector(
    (state) => state.BlocklistReducer.isPlayerBlacklistedData
  );
  const { partnerSelected, controlDefaultTimezone } = useSession();
  const checkGdpr = useGdprCheck();
  const match = useRouteMatch();
  const contractId = match.params.id;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(checkPlayerBlackListStatus(contractId));
  }, [contractId]);

  const data =
    Object.keys(playerInfo).length > 0
      ? partnerSelected.pam
        ? playerInfo
        : playerInfo.networkPlayerDetails
      : null;

  if (data != null) {
    data.contractId = contractId;
  }

  const fields = checkGdpr(
    getFields(
      partnerSelected.pam,
      data.testPlayerAccount,
      data.suspensionReason,
      controlDefaultTimezone,
      isBlacklisted
    ),
    piFields
  );

  return <ListPanel loading={data.loading} data={data} fields={fields} />;
};

export default AccountInfo;
