import { call, put } from "redux-saga/effects";
import {
  UPDATE_PLATFORM_NICKNAME_REQUEST,
  PLATFORM_NICKAMES_LIST_SUCCESS,
  LOAD_PLATFORM_NICKNAMES_REQUEST,
  PLATFORM_NICKAMES_LIST_FAILURE,
  ADD_PLATFORM_NICKNAME_REQUEST,
  UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_REQUEST,
  UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_SUCCESS,
  UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_FAILURE,
  ADD_PLATFORM_NICKNAME_SUCCESS,
  ADD_PLATFORM_NICKNAME_FAILURE,
  UPDATE_PLATFORM_NICKNAME_SUCCESS,
  UPDATE_PLATFORM_NICKNAME_FAILURE,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import platformNicknamesService from "./platform-nicknames-service";
import { getPlayerDetail } from "../../individual-player/player-detail/actions";

const loadPlatformNicknames = function* (action) {
  try {
    const data = yield call(
      platformNicknamesService.loadPlatformNicknames,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: PLATFORM_NICKAMES_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("platform nicknames", e);
    yield put({
      type: PLATFORM_NICKAMES_LIST_FAILURE,
    });
  }
};

const updatePlatformNickname = function* (action) {
  try {
    // eslint-disable-next-line
    const data = yield call(
      platformNicknamesService.updatePlatformNickname,
      action.brand.id,
      action.partner.id,
      action.contractId,
      action.newNicknameGNS
    );

    yield put({
      type: UPDATE_PLATFORM_NICKNAME_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.platformNicknames.updateSuccess",
        },
        options: {
          key: "updatePlatformNicknames",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    yield put(
      getPlayerDetail(
        action.contractId,
        action.brand.id,
        action.partner.id,
        action.partner.pam
      )
    );
  } catch (e) {
    console.error("updatePlatformNicknames", e);
    yield put({
      type: UPDATE_PLATFORM_NICKNAME_FAILURE,
    });
  }
};

const addPlatformNickname = function* (action) {
  try {
    // eslint-disable-next-line
    if (action.closeModal != null && typeof action.closeModal === "function") {
      action.closeModal({ open: false });
    }
    const data = yield call(
      platformNicknamesService.addPlatformNickname,
      action.brand,
      action.partner,
      action.contractId,
      action.nickname,
      action.gameType.value
    );
    yield put({
      type: ADD_PLATFORM_NICKNAME_SUCCESS,
      payload: data,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.platformNicknames.addSuccess",
        },
        options: {
          key: "addPlatformNicknames",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
  } catch (e) {
    console.error("addPlatformNicknames", e);
    yield put({
      type: ADD_PLATFORM_NICKNAME_FAILURE,
    });
  }
};

const updateNicknameByPlatform = function* (action) {
  try {
    // eslint-disable-next-line
    const data = yield call(
      platformNicknamesService.updateNicknameByPlatform,
      action.brand,
      action.partner,
      action.contractId,
      action.nickname,
      action.gameType
    );
    yield put({
      type: UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_SUCCESS,
      payload: data,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.platformNicknames.updateSuccess",
        },
        options: {
          key: "updatePlatformNicknames",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
  } catch (e) {
    console.error("updatePlatformNicknames", e);
    yield put({
      type: UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_FAILURE,
    });
  }
};

const PlatformNicknamesSaga = function* platformNicknamesSaga() {
  yield takeLatest(LOAD_PLATFORM_NICKNAMES_REQUEST, loadPlatformNicknames);
  yield takeLatest(UPDATE_PLATFORM_NICKNAME_REQUEST, updatePlatformNickname);
  yield takeLatest(ADD_PLATFORM_NICKNAME_REQUEST, addPlatformNickname);
  yield takeLatest(
    UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_REQUEST,
    updateNicknameByPlatform
  );
};

export default PlatformNicknamesSaga;
