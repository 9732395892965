import Moment from "react-moment";
import React from "react";
import {
  INDIVIDUAL_PLAYER_CONSENTS_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_CONSENTS_LIST_FAILURE,
  INDIVIDUAL_PLAYER_CONSENTS_EDIT_SUCCESS,
  INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_REQUEST,
  INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_FAILURE,
} from "../../../constants";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";

const getStatusMapping = (status) => {
  let badgeMapped = {};
  switch (status) {
    case "ENABLED":
      badgeMapped.badgeId = "2";
      badgeMapped.badgeLabel = "given";
      break;
    case "DISABLED":
      badgeMapped.badgeId = "6";
      badgeMapped.badgeLabel = "notGiven";
      break;
    default:
  }
  return badgeMapped;
};

const initialState = {
  loading: true,
  consentsList: {
    data: [],
    columns: [
      {
        field: "consentType.name",
        title: "label.individualplayer.consents.name",
        default: true,
      },
      {
        field: "consentType.creationDate",
        title: "label.individualplayer.consents.creationDate",
        defaultSort: "desc",
        render: (rowData) => {
          if (rowData["consentType"]["creationDate"]) {
            return (
              <Moment format="L LT">
                {rowData["consentType"]["creationDate"]}
              </Moment>
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "consentType.consentTypeStatus",
        title: "label.individualplayer.consents.status",
        nowrap: false,
        render: (rowData) => {
          if (rowData["status"]) {
            const badgeMapped = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
                justify="center"
              />
            );
          }
        },
      },
    ],
  },
  consentView: {
    fields: [
      { field: "name", title: "label.individualplayer.consents.name" },
      {
        field: "shortDescription",
        title: "label.individualplayer.consents.shortDescription",
      },
      {
        field: "userTypeId",
        title: "label.individualplayer.consents.userTypeId",
      },
      {
        field: "userTypeDescription",
        title: "label.individualplayer.consents.userTypeDescription",
        render: (data) => {
          const label =
            "label.individualplayer.consents.userTypes." + data["userTypeId"];
          return <Translate needle={label} />;
        },
      },
      {
        field: "creationDate",
        title: "label.individualplayer.consents.creationDate",
        render: (data) => {
          if (data["creationDate"]) {
            return <Moment format="L LT">{data["creationDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "lastUpdateDate",
        title: "label.individualplayer.consents.lastUpdateDate",
        render: (data) => {
          if (data["lastUpdateDate"]) {
            return <Moment format="L LT">{data["lastUpdateDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "status",
        title: "label.individualplayer.consents.status",
        render: (data) => {
          if (data["status"]) {
            const badgeMapped = getStatusMapping(data["status"]);
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
              />
            );
          }
        },
      },
      {
        field: "dateRequest",
        title: "label.individualplayer.consents.dateRequest",
        render: (data) => {
          if (data["dateRequest"]) {
            return <Moment format="L LT">{data["dateRequest"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      { field: "channel", title: "label.individualplayer.consents.channel" },
      {
        field: "currentVersionText",
        title: "label.individualplayer.consents.currentVersionText",
      },
      {
        field: "currentVersionNumber",
        title: "label.individualplayer.consents.currentVersionNumber",
      },
      {
        field: "flagToBeReviewed",
        title: "label.individualplayer.consents.flagToBeReviewed",
        render: (data) => {
          const label = "label." + (data["flagToBeReviewed"] ? "yes" : "no");
          return <Translate needle={label} />;
        },
      },
      {
        field: "flagMandatory",
        title: "label.individualplayer.consents.flagMandatory",
        render: (data) => {
          const label = "label." + (data["flagMandatory"] ? "yes" : "no");
          return <Translate needle={label} />;
        },
      },
      {
        field: "futureVersionText",
        title: "label.individualplayer.consents.futureVersionText",
      },
      {
        field: "futureVersionNumber",
        title: "label.individualplayer.consents.futureVersionNumber",
      },
    ],
  },
  consentEdit: {
    success: null,
  },
  consentsHistoryList: {
    dataHistory: [],
    columns: [
      {
        field: "dateRequest",
        title: "label.individualplayer.consents.dateRequest",
        render: (data) => {
          if (data["dateRequest"]) {
            return <Moment format="L LT">{data["dateRequest"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "status",
        title: "label.individualplayer.consents.status",
        render: (data) => {
          if (data["status"]) {
            const badgeMapped = getStatusMapping(data["status"]);
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
              />
            );
          }
        },
      },
    ],
    fields: [],
    loading: true,
  },
};

const IndividualPlayerConsentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_PLAYER_CONSENTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        consentsList: { ...state.consentsList, data: action.payload },
        consentEdit: {
          ...state.consentEdit,
          success: false,
        },
      };
    }
    case INDIVIDUAL_PLAYER_CONSENTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        consentsList: { ...state.consentsList, data: null },
      };
    }
    case INDIVIDUAL_PLAYER_CONSENTS_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        consentEdit: {
          ...state.consentEdit,
          success: true,
        },
      };
    }
    case INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_REQUEST: {
      return {
        ...state,
        loading: false,
        consentsHistoryList: {
          ...state.consentsHistoryList,
          loading: true,
        },
      };
    }
    case INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        consentsHistoryList: {
          ...state.consentsHistoryList,
          dataHistory: action.payload,
          loading: false,
        },
      };
    }
    case INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        consentsHistoryList: {
          ...state.consentsHistoryList,
          dataHistory: [],
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default IndividualPlayerConsentsReducer;
