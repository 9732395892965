import {
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_REQUEST,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_REQUEST,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST,
  INDIVIDUAL_PLAYER_RESEND_DIRECT_NOTIFICATION_REQUEST,
  SEND_DIRECT_NOTIFICATION_REQUEST,
  TEMPLATE_VARIABLES_REQUEST,
  TEMPLATES_REQUEST,
} from "../../../constants";

const getIndividualPlayerDirectNotificationsHistoryHandler = (
  contractId,
  brand,
  partner,
  pageNumber,
  pageSize
) => {
  return {
    type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST,
    contractId,
    brand,
    partner,
    pageNumber,
    pageSize,
  };
};

const resendIndividualPlayerDirectNotificationHandler = (
  contractId,
  brand,
  partner,
  notificationId
) => {
  return {
    type: INDIVIDUAL_PLAYER_RESEND_DIRECT_NOTIFICATION_REQUEST,
    contractId,
    brand,
    partner,
    notificationId,
  };
};

const getIndividualPlayerDirectNotificationDetailHandler = (
  contractId,
  brand,
  partner,
  notificationId
) => {
  return {
    type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_REQUEST,
    contractId,
    brand,
    partner,
    notificationId,
  };
};

const getIndividualPlayerDirectNotificationAttachmentHandler = (
  contractId,
  brand,
  partner,
  notificationId,
  attachmentId
) => {
  return {
    type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_REQUEST,
    contractId,
    brand,
    partner,
    notificationId,
    attachmentId,
  };
};

export const getTemplateVariables = (brand, partner, contractId) => {
  return {
    type: TEMPLATE_VARIABLES_REQUEST,
    contractId,
    brand,
    partner,
  };
};

export const getTemplates = () => {
  return {
    type: TEMPLATES_REQUEST,
  };
};

export const sendNotification = (
  brand,
  partner,
  contractId,
  data,
  files,
  successCallback
) => {
  return {
    type: SEND_DIRECT_NOTIFICATION_REQUEST,
    contractId,
    brand,
    partner,
    data,
    files,
    successCallback,
  };
};

export {
  getIndividualPlayerDirectNotificationsHistoryHandler,
  resendIndividualPlayerDirectNotificationHandler,
  getIndividualPlayerDirectNotificationDetailHandler,
  getIndividualPlayerDirectNotificationAttachmentHandler,
};
