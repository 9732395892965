import React from "react";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MaterialFormLabel from "@material-ui/core/FormLabel";
import FormLabel from "./form-label";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { Box } from "@material-ui/core";

function RadioField({
  options = [],
  isConditional = false,
  optional,
  label,
  tooltip,
  fullWidth = false,
  disabled = false,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  const { value } = field;

  function _renderHelperText() {
    const { error } = meta;

    if (error) {
      return error;
    }
  }

  React.useEffect(() => {
    if (value == null && options.length > 0) {
      helpers.setValue(options[0].value);
    }
    // eslint-disable-next-line
  }, [value, options]);

  return (
    <FormControl fullWidth={fullWidth} error={meta.error != null}>
      {label != null ? (
        <MaterialFormLabel>
          <FormLabel optional={optional} label={label} tooltip={tooltip} />
        </MaterialFormLabel>
      ) : null}
      <RadioGroup
        {...field}
        value={value ? value : ""}
        onChange={(event) => {
          helpers.setValue(event.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <Box>
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" disabled={disabled} />}
                label={
                  <FormLabel label={option.label} tooltip={option.tooltip} />
                }
              />
            </Box>
          );
        })}
      </RadioGroup>
      {!isConditional ? (
        <FormHelperText>{_renderHelperText()}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default RadioField;
