import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { ReactComponent as ArrowDownIcon } from "../../Images/icon_form_collapse.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import Box from "@material-ui/core/Box";
import Translate from "../Translator/Translate";
import FormTooltip from "./form-tooltip";
import PropTypes from "prop-types";
import { useTransition } from "react";
import { useTranslator } from "../../utilities/hooks/useTranslator";

const useStyle = makeStyles((theme) => ({
  divider: {
    margin: "0 10px",
    flex: "1 auto",
  },
  open: {
    transform: "rotate(180deg)",
  },
  legendIcon: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  collapsable: {
    cursor: "pointer",
  },
}));
const Fieldset = ({
  tooltip,
  legend,
  children,
  collapse = false,
  style = {},
  className = null,
  translatedLegend,
}) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    if (collapse) {
      setOpen(!open);
    }
  };
  const t = useTranslator();
  return (
    <Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        style={style}
        className={className}
      >
        {translatedLegend ? (
          translatedLegend
        ) : (
          <span>
            <Translate needle={legend} />
          </span>
        )}
        {tooltip ? <FormTooltip title={tooltip} placement={"right"} /> : null}
        <Divider className={classes.divider} />
        {collapse && (
          <SvgIcon
            component={ArrowDownIcon}
            className={clsx(classes.legendIcon, {
              [classes.open]: open,
              [classes.collapsable]: collapse,
            })}
            onClick={handleClick}
          />
        )}
      </Box>
      {collapse ? <Collapse in={open}>{children}</Collapse> : children}
    </Box>
  );
};

Fieldset.propTypes = {
  legend: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  collapse: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};
export default Fieldset;
