import { call, put } from "redux-saga/effects";
import barCodeService from "./barCode_service";
import {
  BARCODE_SUCCESS,
  BARCODE_FAILURE,
  BARCODE_REQUEST,
} from "../constants";
import { takeLatest } from "@redux-saga/core/effects";

const getBarCode = function* (action) {
  try {
    const data = yield call(
      barCodeService.getBarCode,
      action.brand,
      action.partner
    );
    yield put({
      type: BARCODE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: BARCODE_FAILURE,
    });
  }
};

const BarCodeSaga = function* AccountDetailsSaga() {
  yield takeLatest(BARCODE_REQUEST, getBarCode);
};
export default BarCodeSaga;
