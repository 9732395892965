import axios from "axios";

class GameWagerLimitsService {
  async getAssociatedProfiles(contractId, brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v1/network_player/responsible_game/${contractId}/${brand}/${partner}`
    );
    if (response) {
      return response.data;
    }
    throw Error("Invalid response for gameWager limits get associated profiles");
  }

  async editProfile(
    contractId,
    brand,
    partner,
    gameDomain,
    type,
    newValue,
    currency
  ) {
    const response = await axios.post(
      `/gov/api/rest/v1/network_player/responsible_game/${contractId}/${brand}/${partner}/${gameDomain}`,
      {
        currency: currency,
        type: type,
        limitAmount: newValue,
      }
    );
    if (response) {
      return response.data;
    }
    throw Error("Invalid response for gameWager limits edit profile");
  }
}

const gameWagerLimitsService = new GameWagerLimitsService();
export default gameWagerLimitsService;
