import { Box, Card, CardContent, CardHeader } from "@material-ui/core";
import { Translate } from "@material-ui/icons";
import React from "react";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import { useSession } from "./../../../../utilities/hooks/useSession";
import { useTranslator } from "./../../../../utilities/hooks/useTranslator";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPlayerPreferences,
  updatePlayerPreferences,
} from "../../../../store/players/individual-player/player-preferences/actions";
export const prefix = "label.player.playerPreferences.";

export const fields = (widget, t) => {
  return [
    {
      field: "autoPay",
      name: "autoPay",
      type: "Switch",
      titleTooltip: t({ needle: prefix + "autoPayTooltip" }),
      render: (rowData) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap={"wrap"}
            justifyContent={"right"}
            style={{
              margin: "0 -8px",
            }}
          >
            <YesNoBadge value={rowData["autoPay"]} />
          </Box>
        );
      },
    },
    {
      field: "digitalTicket",
      name: "digitalTicket",
      type: "Switch",
      titleTooltip: t({ needle: prefix + "digitalTicketTooltip" }),
      render: (rowData) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap={"wrap"}
            justifyContent={"left"}
            style={{
              margin: "0 -8px",
            }}
          >
            <YesNoBadge value={rowData["digitalTicket"]} />
          </Box>
        );
      },
    },
  ];
};

const PlayerPreferences = ({ widget = false }) => {
  const t = useTranslator();
  const match = useRouteMatch();
  const { brandSelected, partnerSelected } = useSession();
  const playerId = match.params.id;
  const dispatch = useDispatch();
  const { loading, preferences } = useSelector(
    (state) => state.PlayerPreferencesReducer
  );

  React.useEffect(() => {
    if (playerId) {
      dispatch(
        loadPlayerPreferences(playerId, brandSelected.id, partnerSelected.id)
      );
    }
  }, [playerId, brandSelected.id, partnerSelected.id]);

  const handleEdit = (values) => {
    dispatch(
      updatePlayerPreferences(
        playerId,
        brandSelected.id,
        partnerSelected.id,
        values
      )
    );
  };

  return (
    <Card>
      {!widget && <CardHeader title={t({ needle: prefix + "title" })} />}

      <CardContent>
        <ListPanel
          loading={loading}
          editable={!widget}
          fields={fields(widget, t)}
          action={handleEdit}
          data={preferences}
          labelPrefix={prefix}
          isCancelButton
          widget={widget}
        />
      </CardContent>
    </Card>
  );
};

export default PlayerPreferences;
