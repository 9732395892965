import axios from "axios";

class ClubCardService {
  async getClubCardHistory(brand, partner, contractId) {
    const response = await axios.get(
      `/gov/api/rest/v1/sms/clubCardHistory/${contractId}?brandCode=${brand.id}&partnerCode=${partner.id}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for individual get club card history list");
  }

  async getClubCardDetails(brand, partner, contractId) {
    const response = await axios.get(
      `/gov/api/rest/v1/sms/clubCardDetails/${contractId}?brandCode=${brand.id}&partnerCode=${partner.id}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for individual get club card Details list");
  }

  async getClubCardActionsLog(brand, partner, contractId) {
    const response = await axios.get(
      `/gov/api/rest/v1/sms/clubCardActions/${contractId}?brandCode=${brand.id}&partnerCode=${partner.id}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for individual get club card Actions list");
  }

  async getReasonsByAction(brand, partner, action, locale) {
    const response = await axios.get(
      `/gov/api/rest/v1/reasons?brandCode=${brand.id}&partnerCode=${partner.id}&action=${action}&language=${locale}`
    );

    if (response && response.data) {
      const customItems = response.data.filter((it) => it.type === "CUSTOM");
      const defaultItems = response.data.filter((it) => it.type === "DEFAULT");

      // override DEFAULT entries with corresponding CUSTOM ones of the chosen locale
      const data = defaultItems.map((it) => {
        const item = customItems.find(
          ({ reasonPerAction, language }) =>
            reasonPerAction === it.reasonPerAction && language === locale
        );
        return item ? item : it;
      });

      // add CUSTOM entries of the chosen locale that are not already overriding DEFAULT ones
      const alreadyIn = data.map(it => it.reasonPerAction);
      customItems.forEach(it => {
        if (it.language === locale && !alreadyIn.includes(it.reasonPerAction)) {
          data.push(it);
        }
      });

      return data;
    }

    throw Error("Invalid response for reasons list");
  }

  async savePrintRequest(brand, partner, data, contractId) {
    let url = `/gov/api/rest/v1/sms/printRequest?brandCode=${brand.id}&partnerCode=${partner.id}`;
    data.contractId = contractId;
    const response = await axios({
      method: "post",
      url,
      data: data,
    });
    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for print request");
  }

  async RegenerateCard(brand, partner, data, contractId) {
    let url = `/gov/api/rest/v1/sms/regenerateCard?brandCode=${brand.id}&partnerCode=${partner.id}`;
    data.playerId = contractId;
    const response = await axios({
      method: "post",
      url,
      data: data,
    });
    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for regenerate request");
  }

  async sendClubCardEmail(cardId, contractId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/ilottery/players/${contractId}/club-cards/${cardId}/emails?brandId=${brandId}&partnerId=${partnerId}`;
    const response = await axios.post(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for send card via email");
  }

  async DisableEnableCard(brand, partner, data, contractId, action, serviceId) {
    let url = `/gov/api/rest/v1/sms/disableEnableCard/${contractId}?brandCode=${brand.id}&partnerCode=${partner.id}&action=${action}`;
    data.serviceId = serviceId;
    if (action === "Card_Enable") {
      data.reason = "Enable Card";
    }
    const response = await axios({
      method: "post",
      url,
      data: data,
    });
    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for regenerate request");
  }
}

const clubCardService = new ClubCardService();
export default clubCardService;
