import { connect } from "react-redux";
import React from "react";
import ListPanel from "../../../../../Layouts/Panel/ListPanel";

const KYCRequestDetail = ({ dataRequest, loadingDetail, fieldsDetail }) => {
  return (
    <>
      <ListPanel
        data={dataRequest}
        loading={loadingDetail}
        editable={false}
        fields={fieldsDetail}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { dataRequest, loadingDetail, fieldsDetail } = state.KYCStatusReducer;
  return { dataRequest, loadingDetail, fieldsDetail };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KYCRequestDetail);
