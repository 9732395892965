import {
  PLAYER_FRIENDS_FAILURE,
  PLAYER_FRIENDS_REQUEST,
  PLAYER_FRIENDS_SUCCESS,
} from "../../../constants";
import React from "react";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../../../Layouts/Currency/Currency";

const getStatusMapping = (status) => {
  let badgeMapped = {};
  switch (status) {
    case "INVITATION_SENT":
      badgeMapped.badgeId = "3";
      badgeMapped.badgeLabel = "playerFriends.invitationSent";
      break;
    case "BONUS_FRIEND_ASSIGNED":
      badgeMapped.badgeId = "3";
      badgeMapped.badgeLabel = "playerFriends.bonusFriendAssigned";
      break;
    case "FRIEND_REGISTERED":
      badgeMapped.badgeId = "2";
      badgeMapped.badgeLabel = "playerFriends.friendRegistered";
      break;
    case "BONUS_REFER_ASSIGNED":
      badgeMapped.badgeId = "2";
      badgeMapped.badgeLabel = "playerFriends.bonusReferAssigned";
      break;
    default:
      badgeMapped.badgeId = "1";
      badgeMapped.badgeLabel = "unknown";
  }
  return badgeMapped;
};

const initialState = {
  filteredColumns: ["friendName", "friendEmail"],
  columns: [
    {
      title: "label.raf.campaign.promoCodeId",
      field: "promoCodeId",
      sorting: true,
      defaultSort: "asc",
    },
    {
      title: "label.raf.campaign.rafCampaignId",
      field: "campaignId",
    },
    {
      title: "label.raf.campaign.friendContractId",
      field: "friendGameAccount",
      emptyValue: "TBD",
    },
    {
      title: "label.raf.campaign.friendName",
      field: "friendName",
      default: true,
    },
    {
      title: "label.raf.campaign.friendEmail",
      field: "friendEmail",
    },
    {
      title: "label.raf.campaign.rafFriendStatus",
      field: "status",
      default: true,
      render: function (rowData) {
        const badge = getStatusMapping(rowData.status);
        return (
          <BadgeStatus
            fullWidth={true}
            label={badge.badgeLabel}
            id={badge.badgeId}
            justify={"center"}
          />
        );
      },
    },
    {
      title: "label.raf.campaign.totalFriendBonusSent",
      field: "totalFriendBonusSent",
      render: (rowData) => (
        <Currency 
           currency={rowData["rewardTypeId"] === "LOYALTY_POINT" ? "POINTS" : rowData["campaignCurrency"]} 
           amount={rowData.totalFriendBonusSent} 
           justify="flex-end"
        />
      ),
    },
    {
      title: "label.raf.campaign.totalReferBonusSent",
      field: "totalReferBonusSent",
      render: (rowData) => (
        <Currency
           currency={rowData["rewardTypeId"] === "LOYALTY_POINT" ? "POINTS" : rowData["campaignCurrency"]} 
           amount={rowData.totalReferBonusSent} 
           justify="flex-end" 
        />
      ),
    },
  ],
  data: null,
  loading: true,
};
const PlayerFriendsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_FRIENDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case PLAYER_FRIENDS_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
      };
    }
    case PLAYER_FRIENDS_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    default:
      return state;
  }
};

export default PlayerFriendsReducer;
