import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import TextField from '@material-ui/core/TextField';
import { convertFromMillsToHours } from "../../utils";
import FormLabel from "./form-label";
import { Box, FormHelperText, Grid } from "@material-ui/core";


function getHours(valInMillis){
  return convertFromMillsToHours(valInMillis*1000).hours
}
function getMinutes(valInMillis){
  return convertFromMillsToHours(valInMillis*1000).mins
}
function durationInSec(hours, minutes){
  return (minutes*60) + (hours * 60 * 60)
}
const TimeDurationField = ({
  isConditional = false,
  placeholder,
  optional,
  label,
  tooltip,
  ...props
}) => {
  const t = useTranslator();
  const [field, meta] = useField(props);
  const [hours, setHours] = useState(getHours(field.value || 0))
  const [minutes, setMinutes] = useState(getMinutes(field.value || 0))
  const { touched, error } = meta;
  const { setFieldValue } = useFormikContext();

  const plchld = placeholder &&
    typeof placeholder === "object" && placeholder.hours && placeholder.minutes ?
      placeholder
    : {hours: "label.placeholderDurationHours", minutes: "label.placeholderDurationMinutes"};
  return (<Box><Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="baseline"
    wrap="nowrap"
  >
  
  {label ? (
          <FormLabel optional={optional} label={label} tooltip={tooltip} />
        ) : null}
    <TextField
    {...field}
      value={
        hours
      }
      {...props}
      name = {props.name + 1}
      onChange={(event) => {
        setHours(event.target.value)
        setFieldValue(field.name, durationInSec(event.target.value, minutes));
      }}
      placeholder={t({needle: plchld.hours})}
      error={error && touched}
      onKeyDown={(e) => {
        const charCode = e.which ? e.which : e.keyCode;
        const valueLength = e.target.value.length;
        if (charCode >= 37 && charCode <= 40) {
          return true;
        }
        if (
          valueLength < 15 &&
          (charCode < 31 ||
            (charCode >= 48 && charCode <= 57) ||
            (charCode >= 96 && charCode <= 105))
        ) {
          return true;
        }
        if (charCode === 8 || charCode === 46) {
          return true;
        }
        e.preventDefault();
        return false;
      }}
    /><Box p={1}>:</Box><TextField
    {...field}
    value={
      minutes
    }
    {...props}
    name = {props.name + 2}
    onChange={(event) => {
      if(!event.target.value.match(/^([0-5]?[1-9]|[1-5]0|0{1,2}|)$/g)){
        return
      }
      setMinutes(event.target.value)
      setFieldValue(field.name, durationInSec(hours, event.target.value));
    }}
    placeholder={t({needle: plchld.minutes})}
    error={error && touched}
    onKeyDown={(e) => {
      const charCode = e.which ? e.which : e.keyCode;
      const valueLength = e.target.value.length;
      if(valueLength === 2 && (charCode !== 8 && charCode !== 46)){
        e.preventDefault();
        return false
      }
      if (charCode >= 37 && charCode <= 40) {
        return true;
      }
      if (
        valueLength < 15 &&
        (charCode < 31 ||
          (charCode >= 48 && charCode <= 57) ||
          (charCode >= 96 && charCode <= 105))
      ) {
        return true;
      }
      if (charCode === 8 || charCode === 46) {
        return true;
      }
      e.preventDefault();
      return false;
    }}
  />
  </Grid>
  {touched && error && <FormHelperText error={error} >{error}</FormHelperText>}
  </Box>
  );
};





export default TimeDurationField;
