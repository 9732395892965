import React from "react";
import { useField } from "formik";
import { makeStyles, TextField } from "@material-ui/core/";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import FormLabel from "./form-label";
import clsx from "clsx";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyle = makeStyles((theme) => ({
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 8,
    "& label": {
      minWidth: " max-content",
      margin: "0 8px",
    },
  },
}));

function InputField({
  isConditional = false,
  optional,
  label,
  tooltip,
  placeholder,
  inline = false,
  ...props
}) {
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const t = useTranslator();
  const classes = useStyle();
  const { touched, error } = meta;
  const isError = touched && error && true;

  function _renderHelperText() {
    const { touched, error } = meta;

    if (touched && error && !isConditional) {
      return error;
    }
  }

  const plchld = placeholder
    ? typeof placeholder === "string"
      ? t({ needle: placeholder })
      : placeholder
    : t({ needle: "label.placeholders.enterValue" });

  return (
    <>
      <TextField
        {...field}
        className={clsx({ [classes.inline]: inline })}
        placeholder={plchld}
        value={selectedValue ? selectedValue : ""}
        label={
          label != null ? (
            <FormLabel optional={optional} label={label} tooltip={tooltip} />
          ) : null
        }
        {...props}
        error={meta.error && meta.touched}
      />
      {!isConditional && isError && error ? (
        <FormHelperText error={meta.error != null}>
          {_renderHelperText()}
        </FormHelperText>
      ) : null}
    </>
  );
}

export default InputField;
