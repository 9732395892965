import {
  KYC_STATUS_SUCCESS,
  KYC_STATUS_FAILURE,
  KYC_STATUS_HISTORY_SUCCESS,
  KYC_STATUS_HISTORY_FAILURE,
  KYC_STATUS_REQUEST_SUCCESS,
  KYC_STATUS_REQUEST_FAILURE,
  STYLE_LABEL,
  KYC_STATUS_REQUEST,
} from "../../../constants";
import React from "react";
import Moment from "react-moment";
import * as moment from "moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import { TextField } from "@material-ui/core";

const getAccountStatusMapping = (status) => {
  switch (status) {
    case 2:
      return { id: "2", label: "validated" };
    case 8:
      return { id: "3", label: "rejected" };
    case 9:
      return { id: "3", label: "notneeded" };
    default:
      return { id: "3", label: "toValidate" };
  }
};

export const getCheckStatusMapping = (status) => {
  switch (status) {
    case 1:
      return { id: "3", label: "pending" };
    case 2:
      return { id: "2", label: "approved" };
    case 3:
      return { id: "3", label: "toapprove" };
    case 4:
      return { id: "3", label: "waitforplayer" };
    case 5:
      return { id: "3", label: "inretry" };
    case 6:
      return { id: "5", label: "maxattempts" };
    case 7:
      return { id: "4", label: "failed" };
    case 8:
      return { id: "6", label: "rejected" };
    case 9:
      return { id: "1", label: "notneeded" };
    default:
      return { id: "1", label: "unknow" };
  }
};

const initialState = {
  data: null,
  dataList: [],
  dataRequest: null,
  dataStatus: null,
  fields: [
    { field: "identityCheckProvider.name", title: "label.player.kyc.provider" },
    {
      field: "accountStatus",
      title: "label.player.kyc.accountStatus",
      render: (data) => {
        const statusMapped = getAccountStatusMapping(data["accountStatus"]);
        return (
          <BadgeStatus
            id={statusMapped.id}
            label={statusMapped.label}
            justify={"center"}
          />
        );
      },
    },
    {
      field: "identityCheckStatus.identityCheckStatusId",
      title: "label.player.kyc.checkStatus",
      render: (data) => {
        const statusMapped = getCheckStatusMapping(
          data.identityCheckStatus.identityCheckStatusId
        );
        return (
          <BadgeStatus
            id={statusMapped.id}
            label={statusMapped.label}
            justify={"center"}
          />
        );
      },
    },
    { field: "identifier", title: "label.player.kyc.identifier" },
    {
      field: "action",
      title: "label.player.kyc.userAction",
      render: (data) => {
        let label = "label.player.kyc.userAction_" + data.action;
        return <Translate needle={label} />;
      },
    },
    {
      field: "attemptsCounter",
      title: "label.player.kyc.currentAttempts",
      render: (data) => {
        let label = "label.player.kyc.currentAttemptsValues";
        return (
          <Translate
            needle={label}
            variables={[
              data.attemptsCounter,
              data.maxPlayerRetry == null ? "-" : data.maxPlayerRetry,
            ]}
          />
        );
      },
    },
    {
      field: "lastUpdated",
      title: "label.player.kyc.lastUpdateDate",
      render: (data) => {
        return <Moment format="L LT">{data.lastUpdated}</Moment>;
      },
    },
    { field: "boUser", title: "label.player.kyc.updatedBy" },
    {
      field: "info",
      title: "label.player.kyc.info",
      render: (data) => {
        let label = "label.player.kyc.info_" + data.info;
        return <Translate needle={label} />;
      },
    },
    { field: "reason", title: "label.player.kyc.reason" },
    { field: "note", title: "label.player.kyc.notes" },
  ],

  filteredColumns: ["identifier", "reason"],

  columns: [
    {
      title: "label.player.kyc.provider",
      field: "identityCheckProvider.name",
    },
    {
      title: "label.player.kyc.accountStatus",
      field: "oldIdentityCheckStatus.identityCheckStatusId",
      default: true,
      render: (rowData) => {
        const badge = getAccountStatusMapping(
          rowData["oldIdentityCheckStatus"]["identityCheckStatusId"]
        );
        return (
          <BadgeStatus
            fullWidth={true}
            id={badge.id}
            label={badge.label}
            justify={"center"}
          />
        );
      },
      getExportTypeFn: (rowData) => {
        if (rowData["oldIdentityCheckStatus"]["identityCheckStatusId"]) {
          const statusMapping = getAccountStatusMapping(
            rowData["oldIdentityCheckStatus"]["identityCheckStatusId"]
          );
          return {
            style: STYLE_LABEL,
            value: `label.status.${statusMapping.label}`,
          };
        }
      },
    },
    {
      title: "label.player.kyc.checkStatus",
      field: "oldIdentityCheckStatus.identityCheckStatusId",
      render: (rowData) => {
        const badge = getCheckStatusMapping(
          rowData["oldIdentityCheckStatus"]["identityCheckStatusId"]
        );
        return (
          <BadgeStatus
            fullWidth={true}
            id={badge.id}
            label={badge.label}
            justify={"center"}
          />
        );
      },
      getExportTypeFn: (rowData) => {
        if (rowData["oldIdentityCheckStatus"]["identityCheckStatusId"]) {
          const statusMapping = getCheckStatusMapping(
            rowData["oldIdentityCheckStatus"]["identityCheckStatusId"]
          );
          return {
            style: STYLE_LABEL,
            value: `label.status.${statusMapping.label}`,
          };
        }
      },
    },
    {
      title: "label.player.kyc.userAction",
      field: "action",
      render: (rowData) => {
        let label = "label.player.kyc.userAction_" + rowData["action"];
        return <Translate needle={label} />;
      },
      getExportTypeFn: (rowData) => {
        if (rowData["action"]) {
          return {
            style: STYLE_LABEL,
            value: `label.player.kyc.userAction_${rowData["action"]}`,
          };
        }
      },
    },
    {
      title: "label.player.kyc.lastUpdateDate",
      field: "lastUpdated",
      default: true,
      defaultSort: "desc",
      render: (rowData) => {
        return <Moment format="L LT">{rowData["lastUpdated"]}</Moment>;
      },
      getExportValueFn: (rowData) => {
        if (rowData["lastUpdated"]) {
          return moment(rowData["lastUpdated"]).format("L LT");
        } else return "";
      },
    },
    {
      title: "label.player.kyc.updatedBy",
      field: "boUser",
    },
    {
      title: "label.player.kyc.info",
      field: "info",
      render: (rowData) => {
        let label = "label.player.kyc.info_" + rowData["info"];
        return <Translate needle={label} />;
      },
      getExportTypeFn: (rowData) => {
        if (rowData["info"]) {
          return {
            style: STYLE_LABEL,
            value: `label.player.kyc.info_${rowData["info"]}`,
          };
        }
      },
    },
    {
      title: "label.player.kyc.notes",
      field: "note",
    },
    {
      title: "label.player.kyc.reason",
      field: "reason",
    },
  ],
  fieldsDetail: [
    {
      field: "request",
      title: "label.player.kyc.detail.request",
      render: (rowdata) => (
        <TextField
          disabled={true}
          fullWidth
          name={"request"}
          multiline
          rowsMax={10}
          hiddenLabel={true}
          value={rowdata.request}
        />
      ),
    },
    {
      field: "response",
      title: "label.player.kyc.detail.response",
      render: (rowdata) => (
        <TextField
          disabled={true}
          fullWidth
          name={"response"}
          multiline
          rowsMax={10}
          hiddenLabel={true}
          value={rowdata.response}
        />
      ),
    },
  ],
  loading: true,
  loadingHistory: true,
  loadingDetail: true,
};

const KYCStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case KYC_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case KYC_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        dataStatus: action.payload === "" ? "" : action.payload.accountStatus,
      };
    }
    case KYC_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }
    case KYC_STATUS_HISTORY_SUCCESS: {
      return {
        ...state,
        loadingHistory: false,
        dataList: action.payload,
      };
    }
    case KYC_STATUS_HISTORY_FAILURE: {
      return {
        ...state,
        loadingHistory: false,
        dataList: null,
      };
    }
    case KYC_STATUS_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingDetail: false,
        dataRequest: action.payload,
      };
    }
    case KYC_STATUS_REQUEST_FAILURE: {
      return {
        ...state,
        loadingDetail: false,
        dataRequest: null,
      };
    }

    default:
      return state;
  }
};

export default KYCStatusReducer;
