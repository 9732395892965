import React from "react";
import YesNoBadge from "../../../Layouts/BadgeStatus/yes-no-badge";
import {
  LOYALTY_TRANSACTION_TYPES_FAILURE,
  LOYALTY_TRANSACTION_TYPES_SUCCESS,
} from "../../constants";

const initialState = {
  transactionTypeList: {
    idKey: "transactionType",
    loading: true,
    data: [],
    columns: [
      {
        field: "description",
        title: "label.rewards.loyalty.transactionType.description",
        defaultSort: "asc",
        default: true,
      },
      {
        field: "negative",
        title: "label.rewards.loyalty.transactionType.creditPoints",
        nowrap: false,
        render: (rowData) => {
          return <YesNoBadge value={rowData["negative"]} justify={"center"} />;
        },
      },
    ],
  },
};

const LoyaltyTransactionTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOYALTY_TRANSACTION_TYPES_SUCCESS: {
      return {
        ...state,
        transactionTypeList: {
          ...state.transactionTypeList,
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOYALTY_TRANSACTION_TYPES_FAILURE: {
      return {
        ...state,
        transactionTypeList: {
          ...state.transactionTypeList,
          data: [],
          loading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default LoyaltyTransactionTypesReducer;
