import React from "react";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import {
  PLAYER_FAVORITES_FAILURE,
  PLAYER_FAVORITES_SUCCESS,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAILURE,
  LOCATIONS_LIST_SUCCESS,
  LOCATIONS_LIST_FAILURE,
  SAVE_FAVORITE_SUCCESS,
  SAVE_FAVORITE_FAILURE,
  NO_OPERATOR,
  PLAYER_FAVORITES_REQUEST,
} from "../../../constants";
import Translate from "../../../../Layouts/Translator/Translate";

export const labelPrefix = "label.player.playerFavorites.";
const initialState = {
  loading: true,
  PlayerFavorites: {
    data: [],
    categoryList: [],
    locationsList: [],
    columns: [
      {
        field: "favoriteId",
        title: "label.player.playerFavorites.id",
      },
      {
        field: "type",
        title: "label.player.playerFavorites.category",
      },
      {
        field: "name",
        title: "label.player.playerFavorites.name",
      },
      {
        field: "type",
        title: "label.player.playerFavorites.operatorName",
        render: (rowData) => {
          let label =
            rowData["operatorName"] == null ||
            rowData["operatorName"] === NO_OPERATOR
              ? "portal"
              : "boUser";
          return <Translate needle={"label.player.playerFavorites." + label} />;
        },
      },
      {
        field: "operatorName",
        title: "label.player.playerFavorites.author",
        render: (rowData) => {
          let label =
            rowData["operatorName"] != null &&
            rowData["operatorName"] !== NO_OPERATOR
              ? rowData["operatorName"]
              : "-";
          return label;
        },
      },
      {
        field: "timestamp",
        title: "label.player.playerFavorites.time",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["timestamp"]} />
        ),
      },
      {
        field: "reason",
        title: "label.player.playerFavorites.reason",
      },
    ],
    widgetFields: [
      {
        field: "code",
        title: "label.player.playerFavorites.id",
      },
      {
        field: "category",
        title: "label.player.playerFavorites.category",
      },
      {
        field: "name",
        title: "label.player.playerFavorites.name",
      },
      {
        field: "timestamp",
        title: "label.player.playerFavorites.time",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["timestamp"]} />
        ),
      },
    ],
  },
};
const PlayerFavoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_FAVORITES_REQUEST: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          loading: true,
        },
      };
    }
    case PLAYER_FAVORITES_SUCCESS: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          data: action.payload,
          loading: false,
          success: true,
        },
      };
    }
    case PLAYER_FAVORITES_FAILURE: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          data: null,
          loading: false,
        },
      };
    }
    case CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          categoryList: action.payload,
          loading: false,
          success: true,
        },
      };
    }
    case CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          categoryList: [],
          loading: false,
        },
      };
    }

    case LOCATIONS_LIST_SUCCESS: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          locationsList: action.payload,
          loading: false,
          success: true,
        },
      };
    }
    case LOCATIONS_LIST_FAILURE: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          locationsList: [],
          loading: false,
        },
      };
    }
    case SAVE_FAVORITE_SUCCESS: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          success: true,
        },
      };
    }
    case SAVE_FAVORITE_FAILURE: {
      return {
        ...state,
        PlayerFavorites: {
          ...state.PlayerFavorites,
          loading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default PlayerFavoritesReducer;
