import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import { Box } from "@material-ui/core";
import React from "react";
import Moment from "react-moment";
import {
  SENSITIVE_VIP_PLAYER_DETAILS_FAILURE,
  SENSITIVE_VIP_PLAYER_DETAILS_REQUEST,
  SENSITIVE_VIP_PLAYER_DETAILS_SUCCESS,
  SENSITIVE_VIP_PLAYER_HISTORY_FAILURE,
  SENSITIVE_VIP_PLAYER_HISTORY_REQUEST,
  SENSITIVE_VIP_PLAYER_HISTORY_SUCCESS,
} from "../../../constants";

export const labelPrefix = "label.individualplayer.sensitiveVipManagement.";

const initialState = {
  fields: [
    {
      field: "vip",
      title: labelPrefix + "vip",
      render: (listData) => {
        return (
          <Box>
            <YesNoBadge value={listData["vip"]} />
          </Box>
        );
      },
    },
    {
      field: "vipDate",
      title: labelPrefix + "vipDate",
      render: (listData) => {
        if (listData["vipDate"]) {
          return <Moment format="L LT">{listData["vipDate"]}</Moment>;
        }
      },
    },
    {
      field: "vipComment",
      title: labelPrefix + "vipComment",
    },
    {
      field: "sensitive",
      title: labelPrefix + "sensitive",
      render: (listData) => {
        return (
          <Box>
            <YesNoBadge value={listData["sensitive"]} />
          </Box>
        );
      },
    },
    {
      field: "sensitiveDate",
      title: labelPrefix + "sensitiveDate",
      render: (listData) => {
        if (listData["sensitiveDate"]) {
          return <Moment format="L LT">{listData["sensitiveDate"]}</Moment>;
        }
      },
    },
    {
      field: "sensitiveComment",
      title: labelPrefix + "sensitiveComment",
    },
    {
      field: "lastUpdatedDate",
      title: labelPrefix + "lastUpdatedDate",
      defaultSort: "desc",
      render: (listData) => {
        if (listData["lastUpdatedDate"]) {
          return <Moment format="L LT">{listData["lastUpdatedDate"]}</Moment>;
        }
      },
    },
    {
      field: "lastUpdatedByUserRole",
      title: labelPrefix + "lastUpdatedByRole",
    },
    {
      field: "lastUpdatedByUsername",
      title: labelPrefix + "lastUpdatedByUsername",
    },
  ],

  detailPanel: {
    panelLoading: true,
    data: [],
  },
  historyList: {
    tableLoading: true,
    tableData: [],
  },
};

const SensitiveVipManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SENSITIVE_VIP_PLAYER_DETAILS_REQUEST: {
      return {
        ...state,
        detailPanel: {
          ...state.detailPanel,
          panelLoading: true,
          lastRequest: action,
        },
      };
    }

    case SENSITIVE_VIP_PLAYER_DETAILS_SUCCESS: {
      return {
        ...state,
        detailPanel: {
          ...state.detailPanel,
          data: action.payload,
          panelLoading: false,
        },
      };
    }

    case SENSITIVE_VIP_PLAYER_DETAILS_FAILURE: {
      return {
        ...state,
        detailPanel: {
          ...state.detailPanel,
          data: null,
          panelLoading: false,
        },
      };
    }

    case SENSITIVE_VIP_PLAYER_HISTORY_REQUEST: {
      return {
        ...state,
        historyList: {
          ...state.historyList,
          tableLoading: true,
          lastRequest: action,
        },
      };
    }

    case SENSITIVE_VIP_PLAYER_HISTORY_SUCCESS: {
      return {
        ...state,
        historyList: {
          ...state.historyList,
          tableData: action.payload,
          tableLoading: false,
        },
      };
    }

    case SENSITIVE_VIP_PLAYER_HISTORY_FAILURE: {
      return {
        ...state,
        historyList: {
          ...state.historyList,
          tableData: null,
          tableLoading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default SensitiveVipManagementReducer;
