import {
  DELETE_PLAYERS_GROUP_REQUEST,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_SUCCESS,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_FAILURE,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_SUCCESS,
  PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_FAILURE,
  PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_SUCCESS,
  PLAYERS_GROUP_DELETE_FAILURE,
  PLAYERS_GROUP_LIST_FAILURE,
  PLAYERS_GROUP_LIST_SUCCESS,
  PLAYERS_GROUP_VIEW_FAILURE,
  PLAYERS_GROUP_VIEW_SUCCESS,
  STYLE_LABEL,
  PLAYERS_GROUP_LIST_REQUEST,
} from "../../constants";
import Moment from "react-moment";
import React from "react";
import BadgeStatus from "../../../Layouts/BadgeStatus/BadgeStatus";
import badgeStatusesService from "../../../utilities/badgeStatuses";
import * as moment from "moment";

const getStatusMapping = (status) => {
  const statusMapping = [
    {
      id: "Error",
      badgeStatusId: "4",
    },
    {
      id: "Completed",
      badgeStatusId: "2",
    },
    {
      id: "Running",
      badgeStatusId: "3",
    },
    {
      id: "Rollbacked",
      badgeStatusId: "5",
    },
    {
      id: "Completed with errors",
      badgeStatusId: "1",
    },
  ];
  const badgeMapped = badgeStatusesService.filterBadgeFromMapping(
    statusMapping,
    status
  );
  return badgeMapped;
};

const initialState = {
  loading: true,
  playerGroupsHistorySearch: {
    idKey: "processName",
    searchCriteria: [],
    uploads: [],
    columns: [
      {
        field: "processName",
        title: "label.uploadGroupHistory.process.name",
        default: true,
      },
      {
        field: "processVersion",
        title: "label.uploadGroupHistory.process.version",
      },
      {
        field: "contactTypeId",
        title: "label.uploadGroupHistory.contactTypeId",
      },
      {
        field: "status",
        default: true,
        title: "label.uploadGroupHistory.process.status",
        render: (rowData) => {
          if (rowData["status"]) {
            const badgeMapped = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={badgeMapped.badgeId}
                label={badgeMapped.badgeLabel}
                justify="center"
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["status"]) {
            const badgeMapped = getStatusMapping(rowData["status"]);
            return {
              style: STYLE_LABEL,
              value: `label.status.${badgeMapped.badgeLabel}`,
            };
          }
        },
      },
      {
        field: "dataCompleted",
        title: "label.uploadGroupHistory.process.dataCompleted",
      },
      {
        field: "dataInError",
        title: "label.uploadGroupHistory.process.dataInError",
      },
      {
        field: "dataToProcess",
        title: "label.uploadGroupHistory.process.dataToProcess",
      },
      {
        field: "startDate",
        title: "label.uploadGroupHistory.process.startDate",
        defaultSort: "desc",
        render: (rowData) => {
          if (rowData["startDate"]) {
            return <Moment format="L LT">{rowData["startDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["startDate"]) {
            return moment(rowData["startDate"]).format("L LT");
          }
        },
      },
      {
        field: "endDate",
        title: "label.uploadGroupHistory.process.endDate",
        render: (rowData) => {
          if (rowData["endDate"]) {
            return <Moment format="L LT">{rowData["endDate"]}</Moment>;
          } else {
            return null;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["endDate"]) {
            return moment(rowData["endDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
    ],
  },
  playerGroupList: {
    data: [],
    idKey: "name",
    columns: [
      {
        field: "name",
        title: "label.playerGroupList.process.name",
        default: true,
        defaultSort: "asc",
        customSort: (a, b) => {
          return a.name && b.name
            ? a.name.localeCompare(b.name, undefined, {
                sensitivity: "base",
              })
            : 0;
        },
      },
      {
        field: "gmsDlvContactType.contactTypeName",
        title: "label.playerGroupList.process.plyGrpType",
        default: true,
      },
      {
        field: "size",
        title: "label.playerGroupList.process.playerNumber",
        default: true,
      },
    ],
  },
  playerGroupView: {
    data: [],
    idKey: "extContactKey",
    columns: [
      {
        field: "extContactKey",
        title: "label.playerGroupView.extContactKey",
        default: true,
      },
    ],
  },
  playerGroupsHistoryView: {
    idKey: "errorId",
    detailUpload: [],
    columns: [
      {
        field: "errorId",
        title: "label.uploadGroupHistoryView.errorId",
        default: true,
      },
      {
        field: "dataInError",
        title: "label.uploadGroupHistoryView.dataInError",
      },
      {
        field: "errorCode",
        title: "label.uploadGroupHistoryView.errorCode",
      },
      {
        field: "errorDescription",
        title: "label.uploadGroupHistoryView.errorDescription",
      },
      {
        field: "errorDate",
        title: "label.uploadGroupHistoryView.errorDate",
        render: (rowData) => {
          if (rowData["errorDate"]) {
            return <Moment format="L LT">{rowData["errorDate"]}</Moment>;
          } else {
            return null;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["errorDate"]) {
            return moment(rowData["errorDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
    ],
  },
};
const PlayerGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_SUCCESS: {
      let { types } = action.payload;
      let columns = [...state.playerGroupsHistorySearch.columns];
      let contractTypeJson = types.reduce((accumulator, it) => {
        accumulator[it.value] = it.name;
        return accumulator;
      }, {});
      columns.forEach((it) => {
        if (it.field === "contactTypeId") {
          it.lookup = contractTypeJson;
        }
      });

      return {
        ...state,
        playerGroupsHistorySearch: {
          ...state.playerGroupsHistorySearch,
          columns: columns,
          searchCriteria: action.payload.filters,
        },
      };
    }

    case PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_SUCCESS: {
      return {
        ...state,
        playerGroupsHistorySearch: {
          ...state.playerGroupsHistorySearch,
          uploads: action.payload,
        },
      };
    }
    case PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_FAILURE: {
      return {
        ...state,
        playerGroupsHistorySearch: {
          ...state.playerGroupsHistorySearch,
          uploads: null,
        },
      };
    }
    case PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_SUCCESS: {
      return {
        ...state,
        playerGroupsHistoryView: {
          ...state.playerGroupsHistoryView,
          detailUpload: action.payload,
        },
      };
    }
    case PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_FAILURE: {
      return {
        ...state,
        playerGroupsHistoryView: {
          ...state.playerGroupsHistoryView,
          detailUpload: null,
        },
      };
    }
    case PLAYERS_GROUP_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PLAYERS_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        playerGroupList: { ...state.playerGroupList, data: action.payload },
      };
    }
    case PLAYERS_GROUP_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        playerGroupList: { ...state.playerGroupList, data: null },
      };
    }
    case PLAYERS_GROUP_VIEW_SUCCESS: {
      return {
        ...state,
        playerGroupView: { ...state.playerGroupView, data: action.payload },
      };
    }
    case PLAYERS_GROUP_VIEW_FAILURE: {
      return {
        ...state,
        playerGroupView: { ...state.playerGroupView, data: null },
      };
    }
    case DELETE_PLAYERS_GROUP_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PLAYERS_GROUP_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default PlayerGroupsReducer;
