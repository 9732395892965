import axios from "axios";

class GameCategoriesService {
  async getCategories(code) {
    const response = await axios.get(
      `/gov/api/rest/v2/game-platforms/${code}/game-categories`
    );

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for get game categories");
  }
}

const gameCategoriesService = new GameCategoriesService();
export default gameCategoriesService;
