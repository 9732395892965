import React from "react";
import {
  LOAD_ADMIN_LIMITCONFIGURATION_REQUEST,
  LOAD_ADMIN_LIMITCONFIGURATION_SUCCESS,
  LOAD_ADMIN_LIMITCONFIGURATION_FAILURE,
  DELETE_LIMITCONFIGURATION_REQUEST,
  DELETE_LIMITCONFIGURATION_FAILURE,
  ADMIN_LIMIT_TYPES_SUCCESS,
  NOT_APPROVATOR_ROLES_SUCCESS,
  LIMIT_REWARD_TYPES_SUCCESS,
} from "../../../constants";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../../../Layouts/Currency/Currency";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  getRequiredValidation,
  getValidationByType,
} from "../../../../Layouts/form/utils";

export const labelPrefix = "label.admin.security.limitConfiguration.";

const initialState = {
  adminLimitConfiguration: {
    isLoading: true,
    data: [],
    columns: [
      {
        field: "limitType.name",
        title: labelPrefix + "limitTypeName",
        default: true,
        editable: false,
        defaultSort: "asc",
        render: (rowData) => {
          let label = `${labelPrefix}limitTypes.${rowData["limitType"]["name"]}`;
          return <Translate needle={label} />;
        },
      },
      {
        field: "role.name",
        editable: false,
        title: labelPrefix + "roleName",
      },
      {
        field: "amount",
        title: labelPrefix + "amount",
        editComponentDef: (row) => {
          return {
            name: "amount",
            labelPrefix: labelPrefix,
            type:
              row["rewardTypeId"] === "LOYALTY_POINT" ? "Integer" : "Amount",
            validation: getRequiredValidation(
              getValidationByType(
                row["rewardTypeId"] === "LOYALTY_POINT" ? "Integer" : "Amount"
              ),
              row["rewardTypeId"] === "LOYALTY_POINT" ? "Integer" : "Amount",
              labelPrefix,
              "amount"
            ),
          };
        },
        render: (rowData) => (
          <Currency
            currency={
              rowData["rewardTypeId"] === "LOYALTY_POINT"
                ? ""
                : rowData["currency"]
            }
            applyDivisorAmount={rowData["rewardTypeId"] !== "LOYALTY_POINT"}
            amount={rowData["amount"]}
          />
        ),
      },
      {
        field: "enabled",
        title: labelPrefix + "status",
        default: true,
        editable: false,
        render: (rowData) => {
          const statusMapped = getLimitStatusMapping(rowData["enabled"]);
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "rewardTypeId",
        title: labelPrefix + "rewardType",
        editable: false,
        render: (rowData) => {
          let label = `label.rewardType.${rowData["rewardTypeId"]}`;
          return <Translate needle={label} />;
        },
      },
    ],
  },
};

const getLimitStatusMapping = (status) => {
  switch (status) {
    case true:
      return { id: "2", label: "enabled" };
    case false:
      return { id: "6", label: "disabled" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const AdminLimitConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ADMIN_LIMITCONFIGURATION_REQUEST:
      return {
        ...state,
        adminLimitConfiguration: {
          ...state.adminLimitConfiguration,
          isLoading: true,
          lastRequest: action,
        },
      };

    case LOAD_ADMIN_LIMITCONFIGURATION_SUCCESS:
      return {
        ...state,
        adminLimitConfiguration: {
          ...state.adminLimitConfiguration,
          data: action.payload,
          isLoading: false,
        },
      };

    case LOAD_ADMIN_LIMITCONFIGURATION_FAILURE:
      return {
        ...state,
        adminLimitConfiguration: {
          ...state.adminLimitConfiguration,
          data: null,
          isLoading: false,
        },
      };

    case DELETE_LIMITCONFIGURATION_REQUEST:
      return {
        ...state,
        adminLimitConfiguration: {
          ...state.adminLimitConfiguration,
          isLoading: true,
        },
      };

    case DELETE_LIMITCONFIGURATION_FAILURE:
      return {
        ...state,
        adminLimitConfiguration: {
          ...state.adminLimitConfiguration,
          isLoading: false,
        },
      };

    case ADMIN_LIMIT_TYPES_SUCCESS:
      return {
        ...state,
        addLimitForm: {
          ...state.addLimitForm,
          limitTypes: action.payload,
        },
      };

    case NOT_APPROVATOR_ROLES_SUCCESS:
      return {
        ...state,
        addLimitForm: {
          ...state.addLimitForm,
          notApprovatorRoles: action.payload,
        },
      };

    case LIMIT_REWARD_TYPES_SUCCESS:
      return {
        ...state,
        addLimitForm: {
          ...state.addLimitForm,
          rewardTypes: action.payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default AdminLimitConfigurationReducer;
