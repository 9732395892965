import { call, put } from "redux-saga/effects";

import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import {
  LOYALTY_BONUSES_FAILURE,
  LOYALTY_BONUSES_REQUEST,
  LOYALTY_BONUSES_SUCCESS,
  LOYALTY_CONVERT_FAILURE,
  LOYALTY_CONVERT_REQUEST,
  LOYALTY_CONVERT_SUCCESS,
  LOYALTY_DETAILS_FAILURE,
  LOYALTY_DETAILS_REQUEST,
  LOYALTY_DETAILS_SUCCESS,
  LOYALTY_LEVEL_CHANGE_FAILURE,
  LOYALTY_LEVEL_CHANGE_REQUEST,
  LOYALTY_LEVEL_CHANGE_SUCCESS,
  LOYALTY_LEVEL_CONFIGURATION_FAILURE,
  LOYALTY_LEVEL_CONFIGURATION_REQUEST,
  LOYALTY_LEVEL_CONFIGURATION_SUCCESS,
} from "../../../constants";
import LoyaltyDetailService from "./loyalty-details-service";

const getLoyaltyDetails = function* (action) {
  try {
    const data = yield call(
      LoyaltyDetailService.getLoyaltyDetails,
      action.contractId
    );
    yield put({
      type: LOYALTY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getLoyaltyDetails", e);
    yield put({
      type: LOYALTY_DETAILS_FAILURE,
    });
  }
};


const LoyaltyDetailsSaga = function* loyaltyDetailsSaga() {
  yield takeLatest(LOYALTY_DETAILS_REQUEST, getLoyaltyDetails);
};

export default LoyaltyDetailsSaga;
