import { call, put } from "redux-saga/effects";
import {
  INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST,
  INDIVIDUAL_PLAYER_LOGIN_HISTORY_SUCCESS,
  INDIVIDUAL_PLAYER_LOGIN_HISTORY_FAILURE,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import loginHistoryService from "./login-history-service";

const loginHistory = function* (action) {
  try {
    const data = yield call(
      loginHistoryService.loadIndividualPlayerLoginHistory,
      action.contractId,
      action.brand,
      action.partner,
        action.page,
        action.pageSize
    );
    yield put({
      type: INDIVIDUAL_PLAYER_LOGIN_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerLoginHistory", e);
    yield put({
      type: INDIVIDUAL_PLAYER_LOGIN_HISTORY_FAILURE,
    });
  }
};

const LoginHistorySaga = function* loginHistorySaga() {
  yield takeLatest(INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST, loginHistory);
};

export default LoginHistorySaga;
