import axios from "axios";

const convertDataBack = (data) => {
  Object.keys(data).forEach((k) => {
    data[k].limitValue = `${
      data[k].limitHours * 60 * 60 * 1000 + data[k].limitMins * 60 * 1000
    }`;
    delete data[k].limitHours;
    delete data[k].limitMins;
  });
};

class SessionLimitsService {
  async getSessionLimits(contractId, brandId, partnerId) {
    const response = await axios({
      method: "get",
      url: `/gov/api/rest/v1/limits/${contractId}/session`,
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      var data = {};
      Object.entries(response.data).forEach(([key, value]) => {
        if (value.limitValue) {
          data[key] = value;
        }
      });
      return data;
    }
    throw Error("Invalid response for session limits get list");
  }

  async editSessionLimits(contractId, body, brandId, partnerId) {
    const bodyData = { ...body };
    convertDataBack(bodyData);

    const response = await axios({
      method: "put",
      url: `/gov/api/rest/v1/limits/${contractId}/session`,
      data: bodyData,
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      var data = {};
      Object.entries(response.data).forEach(([key, value]) => {
        if (value.limitValue) {
          data[key] = value;
        }
      });
      return data;
    }
    throw Error("Invalid response for session limits update");
  }

  async resetSessionLimits(contractId, body, brandId, partnerId) {
    const bodyData = { ...body };
    convertDataBack(bodyData);
    const response = await axios({
      method: "put",
      url: `/gov/api/rest/v1/limits/${contractId}/session/reset`,
      data: bodyData,
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      var data = {};
      Object.entries(response.data).forEach(([key, value]) => {
        if (value.limitValue) {
          data[key] = value;
        }
      });
      return data;
    }
    throw Error("Invalid response for session limits reset");
  }

  async fetchSessionInfo(contractId, brandId, partnerId) {
    const response = await axios.get(
      `/gov/api/rest/v1/player/session-info/${contractId}?brandId=${brandId}&partnerId=${partnerId}`
    );

    if (response) {
      if (response.status === 200) {
        return response.data;
      }

      if (response.status === 204) {
        return null;
      }
    }
    throw Error("Invalid response for fetch player session info");
  }

  async invalidateToken(contractId, brandId, partnerId) {
    const response = await axios.put(
      `/gov/api/rest/v1/player/invalidation-token/${contractId}?brandId=${brandId}&partnerId=${partnerId}`
    );

    if (response && response.status !== 204) {
      throw Error("Invalid response for token invalidation");
    }
  }
}

const sessionLimitsService = new SessionLimitsService();
export default sessionLimitsService;
