import React, { Component } from "react";
import { connect } from "react-redux";
import Translate from "../Translator/Translate";
import FormControl from "@material-ui/core/FormControl";
import {
  authenticatedHandler,
  logoutHandler,
} from "../../store/authentication/actions";
import { languagesHandler, localeHandler } from "../../store/locale/actions";
// Import Stylesheet(s)
import { TOGGLE_HC, TOGGLE_THEME } from "../../store/constants";
import { Box, Popover, TextField, withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { browserName } from "react-device-detect";
import Switch from "@material-ui/core/Switch";
import { withTranslate } from "../../utilities/hocs/withTranslate";
import MenuItem from "@material-ui/core/MenuItem";

class SettingsDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkTheme: false,
      highContrast: false,
      selectedLanguage: props.locale,
      isChanged: true,
    };
  }

  componentDidMount() {
    this.props.languagesHandler();
    this.setState({
      selectedLanguage: this.props.locale,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.locale !== this.state.selectedLanguage) {
      this.setState({ selectedLanguage: this.props.locale });
    }
  }

  toggleCheckedTheme() {
    this.props.TOGGLE_THEME_ACTION();
  }

  toggleCheckedHighContrast() {
    this.props.TOGGLE_HC_ACTION();
  }

  drawLanguages() {
    return this.props.languages.map((opt) => {
      return (
        <MenuItem key={opt.id} value={opt.languageCode}>
          {opt.languageName}
        </MenuItem>
      );
    });
  }

  changeLanguageHandler = (event) => {
    let selectedValue = event.target.value;
    let isChanged = this.props.locale !== selectedValue;
    if (isChanged) {
      this.props.localeHandler(selectedValue);
    }
    this.setState({ selectedLanguage: selectedValue });
  };

  saveForm = (event) => {
    event.preventDefault();
    this.setState({ isChanged: true });
  };

  render() {
    const { open, setOpen, classes, theme, highContrast, t } = this.props;
    return (
      <Popover
        classes={{ paper: classes.paper }}
        anchorEl={open}
        open={Boolean(open)}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        onClose={() => setOpen(null)}
      >
        {browserName !== "IE" ? (
          <>
            <Box px={2} mb={2}>
              <FormControl style={{ alignItems: "flex-start" }}>
                <FormControlLabel
                  className={classes.root}
                  style={{
                    marginLeft: 0,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                  labelPlacement={"start"}
                  label={<Translate needle="label.darkMode" />}
                  control={
                    <Switch
                      checked={theme === "dark"}
                      onChange={() => this.toggleCheckedTheme()}
                    />
                  }
                />
              </FormControl>
              <FormControl style={{ alignItems: "flex-start" }}>
                <FormControlLabel
                  className={classes.root}
                  style={{
                    marginLeft: 0,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                  labelPlacement={"start"}
                  label={<Translate needle="label.highContrast" />}
                  control={
                    <Switch
                      checked={highContrast}
                      onChange={() => this.toggleCheckedHighContrast()}
                    />
                  }
                />
              </FormControl>
            </Box>
            <Divider />
          </>
        ) : null}
        {this.props.languages.length > 0 && (
          <>
            <Box p={2} pt={0}>
              <TextField
                fullWidth={true}
                label={t({ needle: "label.language" })}
                select
                value={this.state.selectedLanguage}
                onChange={this.changeLanguageHandler}
              >
                {this.drawLanguages()}
              </TextField>
            </Box>
          </>
        )}
      </Popover>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale, theme, highContrast } = state.MainReducer;
  const { session } = state.AuthenticationReducer;
  const { languages } = state.LanguageReducer;

  return { locale, theme, session, languages, highContrast };
};

const mapDispatchToProps = {
  authenticatedHandler,
  logoutHandler,
  languagesHandler,
  localeHandler,
  TOGGLE_THEME_ACTION: () => {
    return {
      type: TOGGLE_THEME,
    };
  },
  TOGGLE_HC_ACTION: () => {
    return {
      type: TOGGLE_HC,
    };
  },
};
const styles = (theme) => ({
  root: {
    color: theme.palette.text.primary2,
  },
  paper: {
    width: theme.spacing(40),
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslate(SettingsDropdown)));
