import React from "react";
import {
  EXTERNAL_LINKED_ACCOUNTS_FAILURE,
  EXTERNAL_LINKED_ACCOUNTS_REQUEST,
  EXTERNAL_LINKED_ACCOUNTS_SUCCESS,
} from "../../../constants";
import Moment from "react-moment";

const initialState = {
  columns: [
    {
      title: "label.player.externalLinkAccount.type",
      field: "externalAccountType.name",
      default: true,
    },
    {
      title: "label.player.externalLinkAccount.linkDate",
      field: "linkDate",
      render: (rowData) => <Moment format={"L LT"}>{rowData.linkDate}</Moment>,
    },
    {
      title: "label.player.externalLinkAccount.identifier",
      field: "externalAccountId",
    },
    {
      title: "label.player.externalLinkAccount.lastUpdateDate",
      field: "lastUpdateDate",
      defaultSort: "desc",
      render: (rowData) => (
        <Moment format={"L LT"}>{rowData.lastUpdateDate}</Moment>
      ),
    },
  ],
  data: null,
  loading: true,
};
const ExternalLinkedAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXTERNAL_LINKED_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case EXTERNAL_LINKED_ACCOUNTS_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
      };
    }
    case EXTERNAL_LINKED_ACCOUNTS_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    default:
      return state;
  }
};

export default ExternalLinkedAccountsReducer;
