import axios from "axios";

class LatestRegulatorStatusService {
  async loadLatestRegulatorStatus(contractId) {
    let url = `/gov/api/rest/v1/players/${contractId}/latest-regulator`;

    const response = await axios.get(url, {});
    if (response && response.status === 200) {
      return response.data;
    }
    throw Error("Invalid response for latest regulator");
  }
}

const latestRegulatorStatusService = new LatestRegulatorStatusService();
export default latestRegulatorStatusService;
