import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import { EXPORT_ALL_IN_PROGRESS, START_EXPORT_ALL } from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import { finishExportAll } from "./actions";

const startExportAll = function* (action) {
  let data = null;
  yield put(
    enqueueSnackbar({
      message: {
        needle: "label.exportTable.startExportAll",
      },
      options: {
        key: "startExportAll",
        variant: "success",
        autoHideDuration: 8000,
      },
    })
  );
  try {
    data = yield call(action.getData);

    action.closeModal &&
      typeof action.closeModal === "function" &&
      action.closeModal();
  } catch (e) {
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.exportTable.error.exportDataRequestError",
        },
        options: {
          key: "exportDataRequestError",
          variant: "error",
          autoHideDuration: 8000,
        },
      })
    );
    yield put(finishExportAll());
    return;
  }
  if (data) {
    yield put({
      type: EXPORT_ALL_IN_PROGRESS,
      payload: { ...action, tableData: data.data },
    });
  }
};

const ExportSaga = function* ExportSaga() {
  yield takeLatest(START_EXPORT_ALL, startExportAll);
};

export default ExportSaga;
