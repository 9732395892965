import {delay, select, takeEvery, takeLatest} from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  EDIT_PEP_NOTES_FAILURE,
  EDIT_PEP_NOTES_REQUEST,
  EDIT_PEP_NOTES_SUCCESS,
  PEP_PROCESS_FAILURE,
  PEP_PROCESS_REQUEST,
  PEP_PROCESS_SUCCESS,
  PEP_SEARCH_CRITERIA_FAILURE,
  PEP_SEARCH_CRITERIA_REQUEST,
  PEP_SEARCH_CRITERIA_SUCCESS,
  PEP_SEARCH_FAILURE,
  PEP_SEARCH_REQUEST,
  PEP_SEARCH_SUCCESS,
} from "../../constants";
import { enqueueSnackbar } from "../../snackbars/actions";
import { getPlayerDetail } from "../individual-player/player-detail/actions";
import { showSnackbar } from "../player-saga";
import playerService from "../player-service";
import pepService from "./pep-service";
import moment from "moment";

const triggerPepProcess = function* (action) {
  try {
    let data = yield call(
      pepService.triggerPepProcess,
      action.contractId,
      action.pepStatus,
      action.note
    );
    yield put({ type: PEP_PROCESS_SUCCESS, data });

    if(action.search){
      const lastRequest = yield select(
          (state) =>
              state.PepReducer.pepSearch.lastRequest
      );
      yield delay(1000);
     yield put(lastRequest)
    }else {
      yield put(
          getPlayerDetail(
              action.contractId,
              action.brandId,
              action.partnerId,
              action.isPam
          )
      );
    }
  } catch (e) {
    console.error("triggerPepProcess", e);
    yield put({ type: PEP_PROCESS_FAILURE });
  }
};

const parseDate = (timestamp) => {
  if(timestamp) return moment(timestamp).utc(true).toISOString();
  return "*";
}

const searchPlayers = function* (action) {
  try {
    const searchTerms = {...action.searchParams.searchTerms};
    ['REGISTRATION_DATE', 'PEP_AUTH_DATE', 'PEP_PRE_AUTH_DATE'].forEach(it=>{
      if(searchTerms[it] && (searchTerms[it].from || searchTerms[it].to)){
        searchTerms[it] = "["+parseDate(searchTerms[it].from) + " TO " + parseDate(searchTerms[it].to) + "]";
      }else{
        delete searchTerms[it];
      }
    });
    const data = yield call(
      playerService.searchPlayers,
        {...action.searchParams, searchTerms},
      action.pepStatuses
    );
    yield put({
      type: PEP_SEARCH_SUCCESS,
      payload: data,
    });
    if (!action.sensitiveVip) {
      const snackbar = yield call(showSnackbar);
      if (snackbar.sensitive_vip_snackbar) {
        yield put(
          enqueueSnackbar({
            message: {
              needle: "label.sensitiveVipSnackbar",
            },
            options: {
              key: "sensitiveVipSnackbar",
              variant: "info",
              autoHideDuration: 5000,
            },
          })
        );
      }
    }
  } catch (e) {
    console.error("SearchPlayers", e);
    yield put({
      type: PEP_SEARCH_FAILURE,
    });
  }
};

const loadPlayerSearchCriteria = function* (action) {
  try {
    const data = yield call(
      playerService.getPlayerSearchCriteria,
      action.brand,
      action.partner
    );
    yield put({
      type: PEP_SEARCH_CRITERIA_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadPlayerSearchCriteria", e);
    yield put({
      type: PEP_SEARCH_CRITERIA_FAILURE,
    });
  }
};

const editPepNotes = function* (action) {
  try {
    let data = yield call(
      pepService.editPepNotes,
      action.contractId,
      action.note
    );
    yield put({ type: EDIT_PEP_NOTES_SUCCESS, data });
    yield put(
      getPlayerDetail(
        action.contractId,
        action.brandId,
        action.partnerId,
        action.isPam
      )
    );
  } catch (e) {
    console.error("triggerPepProcess", e);
    yield put({ type: EDIT_PEP_NOTES_FAILURE });
  }
};

const PepSaga = function* pepSaga() {
  // yield takeLatest(PEP_SEARCH_CRITERIA_REQUEST, loadPlayerSearchCriteria);
  yield takeLatest(PEP_SEARCH_REQUEST, searchPlayers);
  yield takeEvery(PEP_PROCESS_REQUEST, triggerPepProcess);
  yield takeEvery(EDIT_PEP_NOTES_REQUEST, editPepNotes);
};

export default PepSaga;
