import {
  FREE_SPIN_SEARCH_CRITERIA_SUCCESS,
  FREE_SPIN_SEARCH_CRITERIA_FAILURE,
  FREE_SPIN_LIST_SUCCESS,
  FREE_SPIN_LIST_FAILURE,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import Currency from "../../../../Layouts/Currency/Currency";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";

const getStatusMapping = (status) => {
  let badgeMapped = {};
  switch (status) {
    case 0:
      badgeMapped.badgeId = "1";
      badgeMapped.badgeLabel = "created";
      break;
    case 1:
      badgeMapped.badgeId = "2";
      badgeMapped.badgeLabel = "used";
      break;
    case 2:
      badgeMapped.badgeId = "6";
      badgeMapped.badgeLabel = "depleted";
      break;
    case 3:
      badgeMapped.badgeId = "5";
      badgeMapped.badgeLabel = "deleted";
      break;
    default:
  }
  return badgeMapped;
};

const initialState = {
  freeSpinList: {
    idKey: "ticketId",
    searchCriteria: [],
    columns: (playerCurrency) => {
      return [
        {
          field: "ticketId",
          title: "label.individualplayer.freeSpin.ticketId",
          default: true,
        },
        {
          field: "status",
          title: "label.individualplayer.freeSpin.status",
          default: true,
          render: (rowData) => {
            if (rowData["status"] != null) {
              const statusMapped = getStatusMapping(rowData["status"]);
              return (
                <BadgeStatus
                  id={statusMapped.badgeId}
                  label={statusMapped.badgeLabel}
                  justify={"center"}
                />
              );
            }
          },
        },
        {
          field: "description",
          title: "label.individualplayer.freeSpin.description",
        },
        {
          field: "amount",
          title: "label.individualplayer.freeSpin.amount",
          render: (rowData) => {
            return (
              <Currency
                amount={rowData["amount"]}
                currency={playerCurrency}
                justify="flex-end"
              />
            );
          },
        },
        {
          field: "granularity",
          title: "label.individualplayer.freeSpin.awardedFreeSpins",
        },
        {
          field: "gameTypeList",
          title: "label.individualplayer.freeSpin.gameType",
          render: (rowData) => {
            if (rowData["gameTypeList"]) {
              return rowData["gameTypeList"][0].gameDescription;
            }
          },
        },
      ];
    },
    data: [],
    loading: true,
  },
  freeSpinDetail: {
    fields: (playerCurrency) => {
      return [
        {
          field: "ticketId",
          title: "label.individualplayer.freeSpin.ticketId",
        },
        {
          field: "residualValue",
          title: "label.individualplayer.freeSpin.outstandingAmount",
          render: (rowData) => {
            return (
              <Currency
                amount={rowData["residualValue"]}
                currency={playerCurrency}
              />
            );
          },
        },
        {
          field: "granularity",
          title: "label.individualplayer.freeSpin.awardedFreeSpins",
        },
        {
          field: "player.contractId",
          title: "label.individualplayer.freeSpin.contractId",
        },
        {
          field: "name",
          title: "label.individualplayer.freeSpin.name",
        },
        {
          field: "description",
          title: "label.individualplayer.freeSpin.description",
        },
        {
          field: "amount",
          title: "label.individualplayer.freeSpin.amount",
          render: (rowData) => {
            return (
              <Currency amount={rowData["amount"]} currency={playerCurrency} />
            );
          },
        },
        {
          field: "gameTypeList",
          title: "label.individualplayer.freeSpin.gameType",
          render: (rowData) => {
            if (rowData["gameTypeList"]) {
              return rowData["gameTypeList"][0].gameDescription;
            }
          },
        },
        {
          field: "campaignId",
          title: "label.individualplayer.freeSpin.campaignId",
        },
        {
          field: "status",
          title: "label.individualplayer.freeSpin.status",
          render: (rowData) => {
            if (rowData["status"] != null) {
              const statusMapped = getStatusMapping(rowData["status"]);
              return (
                <BadgeStatus
                  id={statusMapped.badgeId}
                  label={statusMapped.badgeLabel}
                  justify={"center"}
                />
              );
            }
          },
        },
        {
          field: "creationDate",
          title: "label.individualplayer.freeSpin.creationDate",
          render: (rowData) => {
            if (rowData["creationDate"]) {
              return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
            } else {
              return null;
            }
          },
        },
        {
          field: "validityStartDate",
          title: "label.individualplayer.freeSpin.validityStartDate",
          render: (rowData) => {
            if (rowData["validityStartDate"]) {
              return (
                <Moment format="L LT">{rowData["validityStartDate"]}</Moment>
              );
            } else {
              return null;
            }
          },
        },
        {
          field: "validityEndDate",
          title: "label.individualplayer.freeSpin.validityEndDate",
          render: (rowData) => {
            if (rowData["validityEndDate"]) {
              return (
                <Moment format="L LT">{rowData["validityEndDate"]}</Moment>
              );
            } else {
              return null;
            }
          },
        },
        {
          field: "changeDate",
          title: "label.individualplayer.freeSpin.changeDate",
          render: (rowData) => {
            if (rowData["changeDate"]) {
              return <Moment format="L LT">{rowData["changeDate"]}</Moment>;
            } else {
              return null;
            }
          },
        },
        {
          field: "residualGranularity",
          title: "label.individualplayer.freeSpin.residualGranularity",
        },
        {
          field: "bbmTemplateInfo",
          title: "label.individualplayer.freeSpin.pendingWinnings",
          render: (rowData) => {
            let label = "label.individualplayer.freeSpin.realMoney";
            if (
              rowData["bbmTemplateInfo"] &&
              (rowData["bbmTemplateInfo"]["templateName"] != null ||
                rowData["bbmTemplateInfo"]["conversionMultiplier"] != null)
            ) {
              label = "label.individualplayer.freeSpin.basedOnWagering";
            }
            return <Translate needle={label} />;
          },
        },
        {
          field: "bbmTemplateInfo.templateName",
          title: "label.individualplayer.freeSpin.templateName",
        },
        {
          field: "bbmTemplateInfo.conversionMultiplier",
          title: "label.individualplayer.freeSpin.conversionMultiplier",
        },
        {
          field: "bbmTemplateInfo.validityDateNumber",
          title: "label.individualplayer.freeSpin.validityDateNumber",
        },
      ];
    },
    columns: (playerCurrency) => {
      return [
        {
          field: "idTransactionType",
          title: "label.individualplayer.freeSpin.transactionType",
          default: true,
          render: (rowData) => {
            let label = `label.individualplayer.freeSpin.transactionTypeId.${rowData["idTransactionType"]}`;
            return <Translate needle={label} />;
          },
        },
        {
          field: "amount",
          title: "label.individualplayer.freeSpin.transactionAmount",
          render: (rowData) => {
            return (
              <Currency amount={rowData["amount"]} currency={playerCurrency} />
            );
          },
        },
        {
          field: "currency",
          title: "label.individualplayer.freeSpin.currency",
          render: (rowData) => {
            if (rowData["currency"]) {
              return (
                <CurrencyBadge
                  currency={rowData["currency"]}
                  justify="center"
                />
              );
            }
          },
        },
        {
          field: "granularityUsed",
          title: "label.individualplayer.freeSpin.granularityUsed",
        },
        {
          field: "gameType.gameDescription",
          title: "label.individualplayer.freeSpin.gameTypeDescription",
        },
        {
          field: "externalSystem.externalSystemDescription",
          title: "label.individualplayer.freeSpin.externalSystemDescription",
        },
        {
          field: "externalTransactionId",
          title: "label.individualplayer.freeSpin.externalTransactionId",
        },
        {
          field: "transactionDate",
          title: "label.individualplayer.freeSpin.transactionDate",
          render: (rowData) => {
            if (rowData["transactionDate"]) {
              return (
                <Moment format="L LT">{rowData["transactionDate"]}</Moment>
              );
            } else {
              return null;
            }
          },
        },
      ];
    },
  },
};

const FreeSpinReducer = (state = initialState, action) => {
  switch (action.type) {
    case FREE_SPIN_SEARCH_CRITERIA_SUCCESS: {
      return {
        ...state,
        freeSpinList: { ...state.freeSpinList, searchCriteria: action.payload },
      };
    }

    case FREE_SPIN_SEARCH_CRITERIA_FAILURE: {
      return {
        ...state,
        freeSpinList: { ...state.freeSpinList, searchCriteria: [] },
      };
    }

    case FREE_SPIN_LIST_SUCCESS: {
      return {
        ...state,
        freeSpinList: {
          ...state.freeSpinList,
          data: action.payload,
          loading: false,
        },
      };
    }

    case FREE_SPIN_LIST_FAILURE: {
      return {
        ...state,
        freeSpinList: { ...state.freeSpinList, data: null, loading: false },
      };
    }

    default:
      return state;
  }
};

export default FreeSpinReducer;
