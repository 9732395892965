import { Box } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Table from "../../../../Layouts/Table/Table";
import { getFinancialLimitHistory } from "../../../../store/players/individual-player/responsible-game/financial-limits/actions";
import { useSession } from "../../../../utilities/hooks/useSession";

const FinancialLimitHistory = ({ data }) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { brandSelected, partnerSelected } = useSession();
  const contractId = match.params.id;
  const { history, columns, loading } = useSelector(
    (state) => state.FinancialLimitsReducer.financialLimitHistory
  );

  React.useEffect(() => {
    dispatch(
      getFinancialLimitHistory(
        brandSelected.id,
        partnerSelected.id,
        contractId,
        data?.type?.profileDataTypeId
      )
    );
  }, [brandSelected.id, partnerSelected.id, contractId]);

  return (
    <Box m={2}>
      <Table
        isLoading={loading}
        title={
          "label.individualplayer.responsibleGame.financialLimits.profile." +
          data?.type?.profileDataTypeId
        }
        type={"advanced"}
        columns={columns(data?.measure)}
        data={history}
        resize={false}
        searchBar={false}
      />
    </Box>
  );
};

export default FinancialLimitHistory;
