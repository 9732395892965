import React, { Component } from "react";
import Translate from "../Translator/Translate";
// Import Icon(s)
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import {
  addBookmarksHandler,
  getBookmarksHandler,
  removeBookmarksHandler,
} from "../../store/bookmarks/actions";
import { sessionInfoHandler } from "../../store/authentication/actions";
import TrackNavLink from "../track-nav-link/track-nav-link";
import { withRouter } from "react-router";
import { Popover, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import CustomIcon from "../CustomIcon/CustomIcon";
import menuItemsService from "../../utilities/menuItems";

class BookmarksDropdown extends Component {
  getItemByBookmark(bookmarkId) {
    const { menuItems } = this.props;
    return (
      menuItems &&
      menuItems.find((item) => {
        return item.id === bookmarkId;
      })
    );
  }

  getImageParent(item) {
    const { menuItems } = this.props;
    const parent =
      menuItems &&
      menuItems.find((i) => {
        return i.id === item.parentId;
      });

    if (parent && parent.imageOff !== null) {
      return parent.imageOff;
    } else {
      return this.getImageParent(parent);
    }
  }

  getImage = (item) => {
    return item.imageOff !== null ? item.imageOff : this.getImageParent(item);
  };

  createBookmark(bookmark) {
    let favourite = "";
    const { classes } = this.props;
    let menuItem = this.getItemByBookmark(bookmark.id);

    if (menuItem !== undefined) {
      const newMenu = { ...menuItem };
      newMenu.url = menuItemsService.getItemUrl(newMenu.url);

      favourite = (
        <ListItem
          className={classes.item}
          key={newMenu.id}
          button
          component={TrackNavLink}
          element={"bookmarks"}
          labelTo={newMenu.label}
          to={newMenu.url}
          onClick={() => this.props.setOpen(null)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CustomIcon
              className={classes.icon}
              icon={this.getImage(newMenu)}
            />
          </ListItemIcon>

          <ListItemText>
            <Translate needle={newMenu.label} label={newMenu.label} />
          </ListItemText>

          <ListItemSecondaryAction>
            <IconButton
              edge={"end"}
              onClick={() => {
                this.removeBookmark(bookmark);
              }}
            >
              <CloseIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    }

    return favourite;
  }

  getMenuItem = () => {
    let { location, menuItems } = this.props;
    if (menuItems) {
      let url = location.pathname;

      url = url.replace(/{/g, "%7B");
      url = url.replace(/}/g, "%7D");

      for (let item of menuItems) {
        if (item.url === url) {
          return item;
        } else {
          const newMenu = { ...item };

          if (newMenu.url !== null) {
            newMenu.url = menuItemsService.getItemUrl(newMenu.url);
            if (url === newMenu.url) return newMenu;
          }
        }
      }
    }
  };

  removeBookmark(bookmark) {
    this.props.removeBookmarksHandler(bookmark.id);
  }

  addBookmark(bookmark) {
    this.props.addBookmarksHandler(bookmark.id);
  }

  componentDidMount() {
    this.props.getBookmarksHandler();
  }

  render() {
    if (!this.props.menuItems) return null;
    let { bookmarks, classes, open, setOpen } = this.props;
    let currentItem = this.getMenuItem();
    const fevSelected =
      currentItem && bookmarks.find((it) => it.id === currentItem.id);
    return (
      <Popover
        anchorEl={open}
        open={Boolean(open)}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        onClose={() => setOpen(null)}
      >
        <List dense={true} className={classes.root}>
          <ListSubheader className={classes.root}>
            <Grid
              container
              spacing={2}
              alignItems={"center"}
              justify={"space-between"}
            >
              <Grid item>
                <Translate needle="label.bookmarks" label="Bookmarks" />
              </Grid>
              <Grid item>
                <Button
                  color={"primary"}
                  onClick={
                    fevSelected
                      ? () => {
                          this.removeBookmark(currentItem);
                        }
                      : () => {
                          this.addBookmark(currentItem);
                        }
                  }
                >
                  {fevSelected ? (
                    <Translate
                      needle="label.removeBookmarks"
                      label="Remove This Page"
                    />
                  ) : (
                    <Translate
                      needle="label.addBookmarks"
                      label="Add This Page"
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Divider />
          </ListSubheader>
          {bookmarks.length !== 0 ? (
            bookmarks.map((bookmark) => {
              return bookmark
                ? this.createBookmark(bookmark, currentItem)
                : null;
            })
          ) : (
            <ListItem>
              <Translate
                needle="label.noBookmarkRows"
                label="No bookmarks saved"
              />
            </ListItem>
          )}
        </List>
      </Popover>
    );
  }
}

const mapStateToProps = (state) => {
  const { flatMenuItems } = state.SideMenuReducer.result;
  const { bookmarks, menuItem } = state.BookmarksReducer;

  return {
    bookmarks,
    menuItem,
    menuItems: flatMenuItems,
  };
};

const mapDispatchToProps = {
  addBookmarksHandler,
  removeBookmarksHandler,
  getBookmarksHandler,
  sessionInfoHandler,
};

const style = (theme) => ({
  root: {
    minWidth: theme.spacing(40),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary2,
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.icon,
    },
  },
  listItemIcon: {
    minWidth: 30,
  },
  icon: {
    height: "auto",
    "& svg": {
      width: "20px!important",
      height: "20px!important",
    },
  },
  item: {
    color: "inherit",
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(BookmarksDropdown)));
