import axios from "axios";

class KYCStatusService {
  async loadKYCStatus(brand, partner, contractId, registrationLevelId) {
    let url = "/gov/api/rest/v1/players/kycStatus";
    //"
    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
        contractId: contractId,
        registrationLevelId: registrationLevelId,
      },
    });
    if (response && response.status === 200) {
      response["data"]["accountStatus"] = 
        response.data.identityCheckStatus.identityCheckStatusId;
      return response.data;
    }
    throw Error("Invalid response for KYC Status");
  }

  async loadKYCStatusHistory(brand, partner, contractId) {
    let url = "/gov/api/rest/v1/players/kycStatusHistory";
    //"
    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
        contractId: contractId,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for KYC Status History");
  }

  async loadKycRequestDetail(brand, partner, identityCheckRequestId) {
    let url = `/gov/api/rest/v1/players/kycRequest/${identityCheckRequestId}`;
    //"
    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for KYC Status Request");
  }

  async editKycNote(brand, partner, status) {
    let validResponseStatus = 204;
    let url = `/gov/api/rest/v1/players/kycStatus/${status.statusId}/note`;
    const response = await axios({
      method: "put",
      params: {
        brand: brand.id,
        partner: partner.id,
      },
      url,
      data: status,
    });
    if (!response || response.status !== validResponseStatus) {
      throw Error("Unable to save KYC note");
    }
  }

  async changeKycStatus(brand, partner, contractId, status) {
    let validResponseStatus = 204;
    let url = `/gov/api/rest/v1/players/${contractId}/kycStatus`;
    const response = await axios({
      method: "put",
      params: {
        brand: brand.id,
        partner: partner.id,
      },
      url,
      data: status,
    });
    if (!response || response.status !== validResponseStatus) {
      throw Error("Unable to chenge KYC status");
    }
  }
}

const kycStatusService = new KYCStatusService();
export default kycStatusService;
