import {call, put} from "redux-saga/effects";
import {takeLatest} from "@redux-saga/core/effects";
import rglPlayerLimitsService from "./player-limits-service";
import {CHANGE_PLAYER_LIMITS_STATUS, GET_PLAYER_LIMIT_HISTORY, GET_PLAYER_LIMITS, PLAYER_LIMIT_PREFIX, UPDATE_PLAYER_LIMITS} from "./actions";
import {enqueueSnackbar} from "../../../../snackbars/actions";


const getPlayerLimits = function* (action) {
    try {
        const data = yield call(
            rglPlayerLimitsService.getPlayerLimits,
            action.brand,
            action.partner,
            action.contractId,
            action.group
        );

        yield put({
            type: GET_PLAYER_LIMITS.SUCCESS,
            data
        });
    } catch (e) {
        console.error("GetPlayerLimits", action, e);
        yield put({
            type: GET_PLAYER_LIMITS.FAILURE,
        });
    }
};

const getPlayerLimitHistory = function* (action) {
    try {
        const data = yield call(
            rglPlayerLimitsService.getHistory,
            action.brand,
            action.partner,
            action.contractId,
            action.limit
        );

        yield put({
            type: GET_PLAYER_LIMIT_HISTORY.SUCCESS,
            data
        });
    } catch (e) {
        console.error("GetPlayerLimitHistory", action, e);
        yield put({
            type: GET_PLAYER_LIMIT_HISTORY.FAILURE,
        });
    }
};

const updatePlayerLimits = function* (action) {
    try {
        yield call(
            rglPlayerLimitsService.updatePlayerLimits,
            action.brand,
            action.partner,
            action.contractId,
            action.limits
        );

        yield put(
            enqueueSnackbar({
                message: {
                    needle: PLAYER_LIMIT_PREFIX + ".successMessages.updateLimits"
                },
                options: {
                    key: "UpdateRGLPlayerLimits",
                    variant: "success",
                    autoHideDuration: 5000,
                },
            })
        );

        yield put({
            type: UPDATE_PLAYER_LIMITS.SUCCESS,
        });
    } catch (e) {
        console.error("UpdatePlayerLimits", action, e);
        yield put({
            type: UPDATE_PLAYER_LIMITS.FAILURE,
        });
    } finally {
        yield put({
            ...action,
            type: GET_PLAYER_LIMITS.REQUEST
        });
    }
};

const changePlayerLimitStatus = function* (action) {
    try {
        yield call(
            rglPlayerLimitsService.changePlayerLimitStatus,
            action.brand,
            action.partner,
            action.contractId,
            action.limit
        );

        yield put(
            enqueueSnackbar({
                message: {
                    needle:
                        action.limit.enabled
                            ? PLAYER_LIMIT_PREFIX + ".successMessages.enabled_successfully"
                            : PLAYER_LIMIT_PREFIX + ".successMessages.disabled_successfully",
                },
                options: {
                    key: "EnableDisableRGLPlayerLimits",
                    variant: "success",
                    autoHideDuration: 5000,
                },
            })
        );

        yield put({
            type: CHANGE_PLAYER_LIMITS_STATUS.SUCCESS,
        });
    } catch (e) {
        console.error("ChangePlayerLimitsStatus", action, e);
        yield put({
            type: CHANGE_PLAYER_LIMITS_STATUS.FAILURE,
        });
    } finally {
        yield put({
            ...action,
            type: GET_PLAYER_LIMITS.REQUEST
        });
    }
};

const PlayerLimitsSaga = function* financialLimitsSaga() {
    yield takeLatest(
        GET_PLAYER_LIMITS.REQUEST,
        getPlayerLimits
    );

    yield takeLatest(
        UPDATE_PLAYER_LIMITS.REQUEST,
        updatePlayerLimits
    );

    yield takeLatest(
        CHANGE_PLAYER_LIMITS_STATUS.REQUEST,
        changePlayerLimitStatus
    );

    yield takeLatest(
        GET_PLAYER_LIMIT_HISTORY.REQUEST,
        getPlayerLimitHistory
    );


};

export default PlayerLimitsSaga;
