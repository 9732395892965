import { select, takeEvery } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  CONTACT_VERIFICATION_FAILURE,
  CONTACT_VERIFICATION_REQUEST,
  CONTACT_VERIFICATION_SUCCESS,
  SEND_PIN_REQUEST,
  SEND_PIN_SUCCESS,
  SEND_PIN_FAILURE,
  VERIFY_PIN_REQUEST,
  VERIFY_PIN_SUCCESS,
  VERIFY_PIN_FAILURE,
  RELOAD_CONTACT_VERIFICATION_LIST,
} from "../../../constants";
import { enqueueSnackbar } from "../../../snackbars/actions";
import contactVerificationService from "./contact-verification-service";

const getContactVerification = function* (action) {
  try {
    const data = yield call(
      contactVerificationService.getContactVerification,
      action.brandId,
      action.partnerId,
      action.contractId,
      action.widget
    );

    yield put({
      type: CONTACT_VERIFICATION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("contactVerification", e);
    yield put({
      type: CONTACT_VERIFICATION_FAILURE,
    });
  }
};

const sendPin = function* (action) {
  try {
    yield call(
      contactVerificationService.sendPin,
      action.brandId,
      action.partnerId,
      action.contractId,
      action.channelName
    );
    yield put({
      type: SEND_PIN_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    const channelLabel =
      action.channelName === "sms" || action.channelName === "reserved-sms"
        ? "pinSuccess"
        : "emailSuccess";
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.individualplayer.contactConfig.verificationModal." +
            channelLabel,
        },
        options: {
          key: "sendPin",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("sendPinError", e);
    yield put({
      type: SEND_PIN_FAILURE,
    });
  }
};

const verifyPin = function* (action) {
  try {
    yield call(
      contactVerificationService.verifyPin,
      action.brandId,
      action.partnerId,
      action.data
    );
    yield put({
      type: VERIFY_PIN_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.contactConfig.verifyModal.success",
        },
        options: {
          key: "verifyPin",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({ type: RELOAD_CONTACT_VERIFICATION_LIST });
  } catch (e) {
    console.error("verifyPinError", e);
    yield put({
      type: VERIFY_PIN_FAILURE,
    });
  }
};

const reloadList = function* () {
  const lastRequest = yield select(
    (state) => state.ContactVerificationReducer.lastRequest
  );
  yield put(lastRequest);
};

const ContactVerificationSaga = function* contactVerificationSaga() {
  yield takeEvery(CONTACT_VERIFICATION_REQUEST, getContactVerification);
  yield takeEvery(SEND_PIN_REQUEST, sendPin);
  yield takeEvery(VERIFY_PIN_REQUEST, verifyPin);
  yield takeEvery(RELOAD_CONTACT_VERIFICATION_LIST, reloadList);
};

export default ContactVerificationSaga;
