import axios from "axios";
import searchUtils from "../../utilities/searchUtils";
import { CRITERIA_TYPE_DATERANGE, CRITERIA_TYPE_SELECT } from "../constants";
import { getStatusMapping, labelPrefix } from "./desk-reducer";

class DeskService {
  getDeskFilters = async (rewardTypeOnchange) => {
    const processTypes = await this.getProcessRewardType();
    const statuses = await this.getStatus();
    const rewardTypes = await this.getRewardType();

    const filter = [
      {
        name: "amountFrom",
        label: labelPrefix + "amountFrom",
        type: "Amount",
      },
      {
        name: "amountTo",
        label: labelPrefix + "amountTo",
        type: "Amount",
      },
      {
        name: "userCreator",
        label: labelPrefix + "userCreator",
      },
      {
        name: "userApprove",
        label: labelPrefix + "userApprove",
      },
      {
        name: "requestDate",
        label: labelPrefix + "requestDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "approvalDate",
        label: labelPrefix + "approvalDate",
        type: CRITERIA_TYPE_DATERANGE,
        placeholder: "label.placeholders.date",
      },
      {
        name: "processRewardType",
        label: labelPrefix + "processRewardType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options:
          processTypes.map((it) => {
            return {
              label: labelPrefix + "processRewards." + it.id,
              value: it.value,
            };
          }) || [],
      },
      {
        name: "status",
        label: labelPrefix + "status",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options:
          statuses.map((it) => {
            let label = getStatusMapping(it.id).label;
            return {
              label: "label.status." + label,
              value: it.value,
            };
          }) || [],
      },
      {
        name: "rewardTypeId",
        label: labelPrefix + "rewardTypeId",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        onChange: rewardTypeOnchange,
        options:
          rewardTypes.map((it) => {
            return {
              label: "label.rewardType." + it.rewardTypeId,
              value: it.rewardTypeId,
            };
          }) || [],
      },
      {
        name: "externalId",
        label: labelPrefix + "externalId",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholdrs.select",
        options: [],
      },
    ];

    return filter;
  };

  getProcessRewardType = async () => {
    let url = "/gov/api/rest/v2/approvalProcess/process_reward_type";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  };

  getRewardType = async () => {
    let url = "/gov/api/rest/v2/approvalProcess/reward_types_need_approval";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  };

  getStatus = async () => {
    let url = "/gov/api/rest/v2/approvalProcess/process_reward_status";
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  };

  async searchDeskReward(terms) {
    const url = `/gov/api/rest/v2/approvalProcess/search_approval_process_reward`;
    const queryParam = terms
      ? searchUtils.getQueryParam(terms, false, true)
      : "";
    const response = await axios.get(url + queryParam);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for loy currency");
  }

  async approveRejectDesk(body) {
    const url = `/gov/api/rest/v2/approvalProcess/approve_reject/${body.approvalIdentifier}`;
    const response = await axios.put(url, body);

    if (response && response.status !== 204) {
      throw Error("Error reactivating game account");
    }
  }

  async getExternalIDs(rewardType) {
    const url = `/gov/api/rest/v2/approvalProcess/filter-external-id?rewardType=${rewardType}`;
    const response = await axios.get(url);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid data");
  }
}

const deskService = new DeskService();
export default deskService;
