import { call, put } from "redux-saga/effects";
import {
  GO_TO_ERROR_PAGE,
  PLAYER_DETAIL_REQUEST,
  PLAYER_DETAIL_SUCCESS,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import PlayerDetailService from "./player-detail-service";

const getPlayerDtetail = function* (action) {
  try {
    const data = yield call(
      PlayerDetailService.getPlayerDetail,
      action.contractId,
      action.brand,
      action.partner,
      action.isPam
    );
    yield put({
      type: PLAYER_DETAIL_SUCCESS,
      payload: data,
    });
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    yield put({
      type: GO_TO_ERROR_PAGE,
      payload: action.contractId,
    });
  }
};

const PlayerDetailSaga = function* playerDetailSaga() {
  yield takeLatest(PLAYER_DETAIL_REQUEST, getPlayerDtetail);
};

export default PlayerDetailSaga;
