import React from "react";
import InputField from "./input-field";
import { useTranslator } from "../../utilities/hooks/useTranslator";

function NumericField(props) {
  const t = useTranslator();

  return (
    <InputField
      {...props}
      placeholder={t({ needle: "label.placeholders.number" })}
      onKeyDown={(e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode >= 37 && charCode <= 40) {
          return true;
        }
        if (
          charCode < 31 ||
          (charCode >= 48 && charCode <= 57) ||
          (charCode >= 96 && charCode <= 105)
        ) {
          return true;
        }
        if (charCode === 8 || charCode === 46) {
          return true;
        }
        if (charCode === 190 && e.target.value.includes(".")) {
          return true;
        }
        e.preventDefault();
        return false;
      }}
    />
  );
}

export default NumericField;
