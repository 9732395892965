import React from "react";
import { useSession } from "../../../../utilities/hooks/useSession";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import { useSelector } from "react-redux";

const PersonalInfo = ({ playerInfo }) => {
  const { partnerSelected, isPoland } = useSession();
  const { getFields } = useSelector(
    (state) => state.PlayerDetailReducer.playerDetails.personalInfo
  );

  const data =
    Object.keys(playerInfo).length > 0
      ? partnerSelected.pam
        ? playerInfo
        : playerInfo.networkPlayerDetails.playerDetails
      : null;

  const fields = getFields(
    partnerSelected.pam,
    partnerSelected.pam
      ? playerInfo
      : playerInfo.networkPlayerDetails.playerDetails,
    isPoland
  );

  return <ListPanel loading={data.loading} data={data} fields={fields} />;
};

export default PersonalInfo;
