import axios from "axios";

class ReasonsService {
  async getReasons(brand, partner, searchTerms) {
    const url = "/gov/api/rest/v1/reasons";
    const response = await axios.get(url, {
      params: {
        brandCode: brand.id,
        partnerCode: partner.id,
        ...searchTerms,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for reasons list");
  }

  async saveReason(brand, partner, data, actionType, actionFilter) {
    let url = `/gov/api/rest/v1/reasons?brandCode=${brand.id}&partnerCode=${partner.id}`;
    data.action = actionFilter.action;
    data.type = "CUSTOM";
    data.brandId = brand.id;
    data.partnerId = partner.id;
    const response = await axios({
      method: actionType === "edit" ? "put" : "post",
      url,
      data: data,
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Enable to Delete");
  }

  async getActions(brand, partner) {
    let url = `/gov/api/rest/v1/actions?brandCode=${brand.id}&partnerCode=${partner.id}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for actions list");
  }

  async getLanguages() {
    let url = `/gov/api/rest/v2/languages`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for languages list");
  }

  async deleteReason(brand, partner, data, actionFilter) {
    let url = `/gov/api/rest/v1/reasons?brandCode=${brand.id}&partnerCode=${partner.id}`;
    data.action = actionFilter.action;
    data.type = "CUSTOM";
    data.lang = data.language;
    data.reasonAction = data.reasonPerAction;
    const response = await axios({
      method: "delete",
      url,
      data: data,
    });
    if (!response) {
      throw Error("Unable to delete reason");
    }
  }
}

const reasonsService = new ReasonsService();
export default reasonsService;
