import axios from "axios";

class UserPreferencesService {
  async retrieveUserPreferences(
    brand,
    partner,
    urlComponent,
    componentIdentifier
  ) {
    const url = `/gov/api/rest/v1/users-preferences?brand=${brand}&partner=${partner}&url=${urlComponent}&component-identifier=${componentIdentifier}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for retrieve user preferences");
  }

  async getPlayerSettingOverview() {
    let url = "/gov/api/rest/v1/settingsPlayerOverview";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player overiew settings");
  }
}

const userPreferencesService = new UserPreferencesService();
export default userPreferencesService;
