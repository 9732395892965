import { PLAYER_DETAIL_REQUEST } from "../../../constants";

export const getPlayerDetail = (contractId, brand, partner, isPam, cb) => {
  return {
    type: PLAYER_DETAIL_REQUEST,
    contractId: contractId,
    brand,
    partner,
    isPam,
    cb,
  };
};
