import {
  COUNTRIES_SUCCESS,
  LANGUAGES_ACTION,
  PLAYER_LANGUAGES_SUCCESS,
} from "../constants";

const initialState = { languages: [], playerLanguages: [], countries: [] };
const LanguagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGES_ACTION:
      return {
        ...state,
        languages: action.payload,
      };

    case PLAYER_LANGUAGES_SUCCESS:
      return {
        ...state,
        playerLanguages: action.payload,
      };

    case COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default LanguagesReducer;
