class ProvidersService {
  getBrandById(brands, id) {
    return brands.find((it) => {
      return it.id === id;
    });
  }

  getPartnerById(partners, id) {
    return partners.find((it) => {
      return it.id === id;
    });
  }
}

const providersService = new ProvidersService();

export default providersService;
