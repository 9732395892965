import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  PAGE_ACTIONS_FAILURE,
  PAGE_ACTIONS_REQUEST,
  PAGE_ACTIONS_SUCCESS,
} from "../constants";

const actionCategories = { 1: "accordion", 2: "megamenu", 3: "buttons" };
const fetchActions = async (menu) => {
  const page = menu.id;
  try {
    const res = await axios.get(`/gov/api/rest/v2/menuItem/${page}`);
    if (res && res.data && res.data.children) {
      const actions = res.data.children.reduce((accumulator, current) => {
        let key;
        if (current.actionCategory) {
          key = actionCategories[current.actionCategory.id];
        } else {
          key = actionCategories[3];
        }
        if (accumulator[key] === undefined) {
          accumulator[key] = [];
        }
        accumulator[key].push(current);

        return accumulator;
      }, {});

      let keyButton = actionCategories[3];
      if (actions[keyButton] === undefined) {
        actions[keyButton] = null;
      }
      const buttons = res.data.children.slice(0);
      if (buttons != null && buttons.length > 0) {
        actions[keyButton] = res.data.children.slice(0);
      }

      if (actions.accordion) {
        actions.all = [...actions.accordion];
        actions.accordion = actions.accordion.reduce((accumulator, it) => {
          if (it.groupMenu == null) {
            it.groupMenu = {};
            it.groupMenu.id = 0;
          }
          const key = it.groupMenu.id;
          if (accumulator[key] === undefined) {
            accumulator[key] = { ...it.groupMenu };
            accumulator[key].children = [];
          }

          if (it.groupMenu.subMenu == null) {
            it.groupMenu.subMenu = {};
            it.groupMenu.subMenu.id = 0;
          }
          const subKey = it.groupMenu.subMenu.id;
          if (accumulator[key].children[subKey] === undefined) {
            accumulator[key].children[subKey] = { ...it.groupMenu.subMenu };
            accumulator[key].children[subKey].children = [];
          }
          accumulator[key].children[subKey].children.push(it);
          return accumulator;
        }, {});
      }

      if (actions.megamenu) {
        actions.megamenu = actions.megamenu.reduce((accumulator, it) => {
          if (it.groupMenu != null) {
            const key = it.groupMenu.id;
            if (accumulator[key] === undefined) {
              accumulator[key] = { ...it.groupMenu };
              accumulator[key].children = [];
            }
            accumulator[key].children.push(it);
          }
          return accumulator;
        }, {});
      }
      return actions;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const loadActions = function* (action) {
  const data = yield call(fetchActions, action.payload);
  if (data) {
    data.id = action.payload.id;
    yield put({
      type: PAGE_ACTIONS_SUCCESS,
      payload: data,
    });
  } else {
    yield put({
      type: PAGE_ACTIONS_FAILURE,
      payload: data,
    });
  }
};

const PageActionsSaga = function* pageActionsSagq() {
  yield takeLatest(PAGE_ACTIONS_REQUEST, loadActions);
};

export default PageActionsSaga;
