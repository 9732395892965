import {
  CLUB_CARD_HISTORY_REQUEST,
  CLUB_CARD_DETAILS_REQUEST,
  PRINT_CARD_REQUEST,
  REASONS_BY_ACTION_REQUEST,
  CLUB_CARD_ACTIONS_REQUEST,
  REGENERATE_CARD_REQUEST,
  CLUB_CARD_EMAIL_REQUEST,
  DISABLE_ENABLE_CARD_REQUEST,
} from "../../../constants";

export const getClubCardHistory = (brand, partner, contractId) => {
  return {
    type: CLUB_CARD_HISTORY_REQUEST,
    brand,
    partner,
    contractId,
  };
};

export const getClubCardDetails = (brand, partner, contractId) => {
  return {
    type: CLUB_CARD_DETAILS_REQUEST,
    brand,
    partner,
    contractId,
  };
};

export const getClubCardActions = (brand, partner, contractId) => {
  return {
    type: CLUB_CARD_ACTIONS_REQUEST,
    brand,
    partner,
    contractId,
  };
};

export const printCard = (brand, partner, data, contractId, cb) => {
  return {
    type: PRINT_CARD_REQUEST,
    brand,
    partner,
    data,
    contractId,
    cb,
  };
};

export const getReasonsByAction = (brand, partner, action, locale) => {
  return {
    type: REASONS_BY_ACTION_REQUEST,
    brand,
    partner,
    action,
    locale,
  };
};

export const regenerateCard = (brand, partner, data, contractId, cb) => {
  return {
    type: REGENERATE_CARD_REQUEST,
    brand,
    partner,
    data,
    contractId,
    cb,
  };
};

export const emailCard = (idCard, contractId, cb, brandId, partnerId) => {
  return {
    type: CLUB_CARD_EMAIL_REQUEST,
    idCard,
    contractId,
    cb,
    brandId,
    partnerId,
  };
};

export const disableEnableCard = (
  brand,
  partner,
  data,
  contractId,
  action,
  serviceId,
  cb
) => {
  return {
    type: DISABLE_ENABLE_CARD_REQUEST,
    brand,
    partner,
    data,
    contractId,
    action,
    serviceId,
    cb,
  };
};
