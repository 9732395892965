import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import { CRITERIA_TYPE_SELECT } from "../../../constants";

class FreeSpinService {
  async getFilterFields(brand, partner) {
    let campaignOptions = [];
    try {
      let url = `/gov/api/rest/v2/campaigns?brand=${brand}&partner=${partner}&campaignId=0&name=&rewardType=&eventType=&campaignStatuses=&campaignTags=&creationDate=&lastUpdateDate=&activationDate=&expirationDate=&userCreator=&userApprove=`;
      const response = await axios.get(url);
      if (response && response.data) {
        response.data.forEach((it) => {
          campaignOptions.push({
            label: it.name,
            value: it.campaignId,
          });
        });
      }
    } catch (e) {
      console.error("error loading campaigns");
    }

    const filters = [
      {
        name: "creationPeriod",
        label: "label.individualplayer.freeSpin.creationPeriod",
        type: "period",
        placeholder: "label.placeholders.date",
      },
      {
        name: "status",
        label: "label.individualplayer.freeSpin.status",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          {
            label: "label.individualplayer.freeSpin.statusCreated",
            value: 0,
          },
          {
            label: "label.individualplayer.freeSpin.statusUsed",
            value: 1,
          },
          {
            label: "label.individualplayer.freeSpin.statusDepleted",
            value: 2,
          },
          {
            label: "label.individualplayer.freeSpin.statusDeleted",
            value: 3,
          },
        ],
      },
      {
        name: "validityPeriod",
        label: "label.individualplayer.freeSpin.validityPeriod",
        type: "period",
        placeholder: "label.placeholders.date",
      },
      {
        name: "campaignId",
        label: "label.individualplayer.freeSpin.campaign",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: campaignOptions,
      },
      {
        name: "gameDomain",
        label: "label.individualplayer.freeSpin.gameDomain",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [],
      },
      {
        name: "gameCode",
        label: "label.individualplayer.freeSpin.gameCode",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [],
      },
    ];

    return filters;
  }

  async getFreeSpin(contractId, brand, partner, searchTerms) {
    const url = `/gov/api/rest/v1/contract/${contractId}/freeSpin?brand=${brand}&partner=${partner}`;
    const queryParam = searchTerms
      ? searchUtils.getQueryParam(searchTerms, true, false)
      : "";

    const response = await axios.get(url + queryParam);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for free spin list");
  }

  async deleteFreeSpin(contractId, ticketId) {
    const url = `/gov/api/rest/v1/contract/${contractId}/ticketTournament/${ticketId}`;

    const response = await axios.delete(url);
    if (response && response.status === 200) {
      return response.status;
    }
    throw Error("Invalid response for free spin list");
  }
}
const freeSpinService = new FreeSpinService();
export default freeSpinService;
