import axios from "axios";

class IndividualPlayerGroupsService {
  async individualPlayerGroups(playerContact, brand, partner, force) {
    let url = `/gov/api/rest/v1/contracts/contract_group/${playerContact}/${brand}/${partner}`;

    if (force) {
      url += `?_=${new Date().getTime()}`;
    }
    const response = await axios.get(url);

    if (response && response.data && response.data.collection) {
      return response.data.collection;
    }

    throw Error("Invalid response for individual player groups");
  }

  async deleteIndividualPlayerGroup(playerContact, brand, partner, name) {
    const response = await axios.delete(
      `/gov/api/rest/v1/contracts/contract_group/${playerContact}/${brand}/${partner}/${name}`
    );

    if (response == null || response.status !== 204) {
      throw Error("Invalid response for delete individual player group");
    }
  }

  async getGroupList(brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v1/contracts/groups/${brand}/${partner}`
    );

    if (response && response.data && response.data.collection) {
      return response.data.collection;
    }

    throw Error("Invalid response for groups list");
  }

  async saveGroup(playerContact, brand, partner, name) {
    const response = await axios.post(
      `/gov/api/rest/v1/contracts/contract_group/${playerContact}/${brand}/${partner}`,
      {
        firstName: "",
        lastName: "",
        playerGroup: name,
      }
    );

    if (response == null || response.status !== 204) {
      throw Error("Invalid response for add player in a group");
    }
  }
}

const individualPlayerGroupsService = new IndividualPlayerGroupsService();
export default individualPlayerGroupsService;
