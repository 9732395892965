import { call, put } from "redux-saga/effects";
import {
  USER_PREFERENCES_RETRIEVE_REQUEST,
  USER_PREFERENCES_RETRIEVE_SUCCESS,
  USER_PREFERENCES_RETRIEVE_FAILURE,
  PLAYER_OVERVIEW_SETTINGS_SUCCESS,
  PLAYER_OVERVIEW_SETTINGS_FAILURE,
  PLAYER_OVERVIEW_SETTINGS_REQUEST,
} from "../constants";
import { takeLatest } from "@redux-saga/core/effects";
import userPreferencesService from "./user-preferences-service";

const retrieveUserPreferences = function* (action) {
  try {
    const preferences = yield call(
      userPreferencesService.retrieveUserPreferences,
      action.brand,
      action.partner,
      action.url,
      action.componentIdentifier
    );
    if (preferences) {
      const userPreferencesValuesString = preferences.values;
      if (userPreferencesValuesString) {
        preferences.values = userPreferencesValuesString.split(",");
      } else {
        preferences.values = [];
      }
    }
    yield put({
      type: USER_PREFERENCES_RETRIEVE_SUCCESS,
      payload: preferences,
    });
  } catch (e) {
    console.error("retrieve user preferences", e);
    yield put({
      type: USER_PREFERENCES_RETRIEVE_FAILURE,
    });
  }
};

const getPlayerOverview = function* () {
  try {
    const data = yield call(userPreferencesService.getPlayerSettingOverview);
    yield put({
      type: PLAYER_OVERVIEW_SETTINGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_OVERVIEW_SETTINGS_FAILURE,
    });
  }
};

const UserPreferencesSaga = function* userPreferencesSaga() {
  yield takeLatest(USER_PREFERENCES_RETRIEVE_REQUEST, retrieveUserPreferences);
  yield takeLatest(PLAYER_OVERVIEW_SETTINGS_REQUEST, getPlayerOverview);
};

export default UserPreferencesSaga;
