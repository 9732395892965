import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Translate from "../Translator/Translate";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";

const ColumnsSelector = ({ columns, maxColumns, onChange }) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(null);

  const handleToggle = (event) => {
    setOpen(open == null ? event.currentTarget : null);
  };

  const count = columns.filter((column) => {
    return !column.hidden;
  }).length;

  const overflowMessage = maxColumns !== 0 && count >= maxColumns;

  const isItemDisabled = (item) => {
    return maxColumns !== 0 && count >= maxColumns && item.hidden;
  };

  const getColumnList = () => {
    return columns.map((item, i) => {
      const isDisabled = isItemDisabled(item);
      return (
        <MenuItem
          key={i}
          className={classes.menuItem}
          disabled={isDisabled}
          onClick={() => onChange(item)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={!item.hidden}
              disabled={isDisabled}
              inputProps={{ "aria-labelledby": i }}
            />
          </ListItemIcon>
          <ListItemText id={i}>
            <Translate needle={item.title} />
          </ListItemText>
        </MenuItem>
      );
    });
  };

  return (
    <Box mb={2}>
      <div className="colSelection">
        <Button
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleToggle}
          color={"secondary"}
          endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          <Translate needle={"label.tableShowHideColumn"} /> ({count}/
          {columns.length})
        </Button>
        <Menu
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          id="split-button-menu"
          classes={{
            paper: classes.columnsMenu,
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {getColumnList()}
          {overflowMessage ? (
            <Box p={1} fontSize={13} textAlign={"center"}>
              <Translate needle={"label.tableMaxColumnShow"} />
            </Box>
          ) : null}
        </Menu>
      </div>
    </Box>
  );
};

const useStyle = makeStyles((theme) => ({
  columnsMenu: {
    background: theme.palette.secondary.main,
    color: theme.palette.text.secondary1,
    width: theme.spacing(30),
    "& .MuiCheckbox-root": {
      color: "white",
      "&.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiIconButton-label": {
      width: 15,
      height: 15,
    },
    "& .Mui-checked .MuiIconButton-label": {
      background: "white",
      padding: 0,
      borderRadius: 0,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
  },
  menuItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default ColumnsSelector;
