import ExpandMore from "@material-ui/icons/ExpandMore";
import Close from "@material-ui/icons/Close";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import lightPalette from "./light";
import darkPalette from "./dark";
import lightHCPalette from "./light-high-contrast";
import darkHCPalette from "./dark-high-contrast";

const typography = {
  fontFamily: ["Open Sans", "Arial", "Helvetica", "sans-serif"].join(", "),
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
};

const shape = {
  borderRadius: 2,
};

const props = {
  MuiSelect: {
    IconComponent: ExpandMore,
  },
  MuiAutocomplete: {
    popupIcon: <ExpandMore />,
    disableClearable: true,
    ChipProps: {
      deleteIcon: <Close />,
    },
  },
  MuiCheckbox: {
    color: "primary",
  },
  MuiPaper: {
    elevation: 2,
  },
  MuiCard: {
    elevation: 2,
  },
  MuiSkeleton: {
    animation: "wave",
  },
  MuiTextField: {
    fullWidth: false,
  },
  MuiLink: {
    underline: "none",
  },
  MuiButtonBase: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  MuiButton: {
    variant: "contained",
    disableElevation: true,
  },
  MuiInputLabel: {
    disableAnimation: true,
    shrink: true,
  },
  MuiFormControl: {
    margin: "none",
    variant: "standard",
    fullWidth: true,
  },
  MuiDialogContent: {
    dividers: false,
  },
  MuiDialogTitle: {
    disableTypography: true,
  },
  MuiInput: {
    disableUnderline: true,
  },
  MuiPickersBasePicker: {
    disableToolbar: true,
  },
  MuiTooltip: {
    arrow: true,
  },
};

const overrides = (theme) => {
  return {
    MuiCheckbox: {
      root: {
        color: theme.palette.border.input,
        "&$checked": {
          "&$disabled": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiInputAdornment: {
      root: {
        "& .MuiIconButton-root": {
          color: theme.palette.text.icon,
        },
      },
    },
    MuiInputBase: {
      input: {
        height: "auto",
      },
    },
    MuiAutocomplete: {
      popupIndicator: {
        color: theme.palette.text.icon + "!important",
        "&:hover": {
          backgroundColor: "unset!important",
        },
      },
      input: {
        cursor: "pointer",
      },
      tag: {
        borderRadius: 3,
        margin: 3,
        height: 28,
        backgroundColor: theme.palette.divider,
        color: theme.palette.text.secondary3,
        "& .MuiChip-deleteIcon": {
          color: "inherit",
        },
      },
      inputRoot: {
        paddingBottom: "0!important",
        '&[class*="MuiInput-root"] $input:first-child': {
          padding: "8px 12px!important",
          height: 16,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        justifyContent: "initial",
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        color: theme.palette.primary.main,
      },
      dayLabel: {
        color: "inherit",
      },
      switchHeader: {
        marginTop: 0,
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
      iconButton: {
        backgroundColor: "transparent",
        color: "white",
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: theme.palette.action.skeleton,
      },
    },
    MuiSnackbarContent: {
      root: {
        maxWidth: 360,
        "&.SnackbarItem-lessPadding-620": {
          paddingLeft: theme.spacing(2),
        },
        '&[class*="variantSuccess"]': {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.text.secondary1,
        },
        '&[class*="variantError"]': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.text.secondary1,
        },
        '&[class*="variantWarning"]': {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.text.secondary2,
        },
        '&[class*="variantInfo"]': {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.text.secondary2,
        },
      },
      message: {
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(1),
          width: 36,
          height: 36,
        },
        maxWidth: "calc(100% - 50px)",
      },
      action: {
        alignSelf: "flex-start",
        justifySelf: "flex-end",
        cursor: "pointer",
      },
    },
    MuiTabs: {
      root: {
        flexGrow: 1,
        width: "100%",
        background: theme.palette.background.primary2Accent2,
        minHeight: theme.spacing(6),
      },
      indicator: {
        height: 0,
      },
      flexContainer: {
        height: "100%",
      },
    },
    MuiTab: {
      root: {
        fontWeight: theme.typography.fontWeightBold,
        minHeight: theme.spacing(6),
        lineHeight: "1.2rem",
        borderRight: "1px solid " + theme.palette.border.input,
        color: theme.palette.text.primary2,
        textTransform: "capitalize",
        "&$selected": {
          background: theme.palette.secondary.main,
          color: "white",
        },
      },
    },
    MuiTableRow: {
      root: {
        borderLeft: "1px solid " + theme.palette.divider,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: theme.typography.fontWeightBold,
        textAlign: "center",
        padding: 0,
        "&$sizeSmall": {
          padding: 0,
        },
        "&$alignLeft": {
          textAlign: "center",
        },
        "& .MuiTableCell-title": {
          padding: "6px 12px",
          lineHeight: "1.2rem",
        },
      },
      sizeSmall: {
        padding: "6px 12px",
      },
      alignLeft: {},
      root: {
        borderRight: "1px solid " + theme.palette.divider,
        borderBottom: "1px solid " + theme.palette.divider,
        "&:last-child": {
          // borderRight: "none"
        },
      },
      body: {
        "& .MuiBox-root": {
          "& + .MuiBox-root": {
            marginLeft: 8,
          },
        },
        "& .MuiButtonBase-root.tableActionButton": {
          marginTop: 8,
          marginBottom: 8,
          "& + .MuiButtonBase-root.tableActionButton": {
            marginLeft: 8,
          },
          "& .MuiButton-startIcon": {
            marginRight: 4,
          },
        },
      },
    },
    MuiToolbar: {},
    MuiTableBody: {
      root: {
        color: theme.palette.text.primary2,
        "& .MuiTableRow-root:nth-child(2n)": {
          backgroundColor: theme.palette.background.primary2Accent3,
        },
      },
    },
    MuiTableFooter: {
      root: {},
    },
    MuiTableSortLabel: {
      root: {
        display: "flex",
        justifyContent: "center",
        padding: "6px 16px",
        "& svg": {
          display: "none",
        },
        "& .MuiTableCell-title": {
          padding: 0,
        },
      },
      active: {
        backgroundColor: theme.palette.action.sortActive,
        "& $iconDirectionAsc:before": {
          borderBottomColor: theme.palette.primary.main,
        },
        "& $iconDirectionDesc:before": {
          borderBottomColor: theme.palette.primary.main,
        },
      },
      iconDirectionAsc: {},
      iconDirectionDesc: {},
      icon: {
        transition: "none",
        opacity: 1,
        "&:before, &:after": {
          border: "4px solid transparent",
          content: "''",
          display: "block",
          height: "0",
          width: "0",
        },
        "&:after": {
          borderTopColor: theme.palette.border.arrow,
          borderTopWidth: 6,
          marginTop: 2,
        },
        "&:before": {
          borderBottomColor: theme.palette.border.arrow,
          borderBottomWidth: 6,
        },
      },
    },
    MuiPaginationItem: {
      page: {
        fontWeight: theme.typography.fontWeightBold,
        "&.PaginationIconButtons": {
          width: 26,
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
          fontSize: 30,
        },
        "&$selected": {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    MuiTablePagination: {
      caption: {
        order: 4,
        "&:nth-last-child(2)": {
          order: 1,
        },
      },
      disabled: {},
      selectRoot: {
        order: 5,
        backgroundColor: theme.palette.background.paper,
        marginRight: 0,
        "&$disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      actions: {
        order: 3,
        color: "#939393",
        flex: "1 1 auto",
      },
      toolbar: {
        display: "flex",
        paddingLeft: 1,
        paddingRight: 1,
      },
      spacer: {
        order: 2,
        flex: "1 0 auto",
      },
    },
    MuiPagination: {
      ul: {},
    },
    MuiSwitch: {
      root: {
        padding: 0,
        height: 24,
        marginRight: 10,
      },
      sizeSmall: {
        padding: 0,
        height: 22,
        "& .MuiSwitch-thumb": {
          width: 14,
          height: 14,
        },
      },
      colorPrimary: {},
      colorSecondary: {},
      switchBase: {
        padding: 4,
        "&$checked": {
          transform: "translateX(33px)",
          color: theme.palette.common.white,
          "&$colorSecondary $thumb, &$colorPrimary $thumb, & $thumb": {
            backgroundColor: "white",
          },
          "&$colorSecondary + $track, &$colorPrimary + $track, & + $track": {
            backgroundColor: "#52d869",
            opacity: 1,
            border: "none",
          },
        },
      },
      thumb: {
        boxShadow: "none",
        width: 16,
        height: 16,
      },
      track: {
        borderRadius: 26 / 2,
        backgroundColor: "#9F9F9F",
      },
      checked: {},
    },
    MuiCardHeader: {
      root: {
        backgroundColor: theme.palette.background.primary2Accent1,
        padding: theme.spacing(1, 2),
        "&.blueHeader": {
          background: theme.palette.primary.main,
          "& $title": {
            color: theme.palette.text.secondary2,
            textTransform: "uppercase",
          },
        },
      },
      title: {
        textTransform: "capitalize",
        color: theme.palette.text.primary2,
        fontWeight: 700,
        fontSize: 15,
      },
    },
    MuiCardContent: {
      root: {
        padding: isMobileOnly ? theme.spacing(1) : theme.spacing(2),
        "&:last-child": {
          paddingBottom: isMobileOnly ? theme.spacing(1) : theme.spacing(2),
        },
      },
    },
    MuiCardActions: {
      root: {
        "&.greyActions": {
          background: theme.palette.background.primary2Accent3,
        },
      },
    },
    MuiCard: {
      root: {
        border: "1px solid " + theme.palette.divider,
        "&.MuiPaper-elevation0": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      icon: {
        color: "inherit",
      },
      select: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
      selectMenu: {
        "& .placeholder": {
          color: theme.palette.action.placeholder,
        },
      },
      root: {
        "&.MuiInput-input": {
          // padding:0
        },
      },
    },
    MuiDialogActions: {
      root: {},
    },
    MuiDialogContent: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: theme.palette.background.primary2Accent2,
        padding: "8px 24px",
        fontSize: 15,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary2,
      },
    },
    MuiDialogContentText: {
      root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        padding: 5,
        color: theme.palette.text.secondary2,
      },
      root: {
        backgroundColor: theme.palette.primary.main,
        minHeight: 36,
        "&$expanded": {
          minHeight: 36,
        },
        "& .MuiSvgIcon-root": {
          color: "inherit",
        },
      },
      content: {
        color: theme.palette.text.secondary2,
        margin: 0,
        "&$expanded": {
          margin: "0",
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        border: "1px solid " + theme.palette.divider,
        borderTop: "0",
      },
    },
    MuiIconButton: {
      root: {
        "&$disabled": {
          opacity: "40%",
        },
        "&$colorSuccess": {
          color: theme.palette.buttons.success,
          "&:hover": {
            color: theme.palette.buttons.successFocused,
          },
        },
        "&$colorFailure": {
          color: theme.palette.buttons.failure,
          "&:hover": {
            color: theme.palette.buttons.failureFocused,
          },
        },
      },
      colorFailure: {},
      colorSuccess: {},
    },
    MuiButton: {
      root: {
        fontSize: "0.934rem",
        "&$disabled": {
          opacity: "40%",
          color: null,
        },
        "&$containedSuccess": {
          backgroundColor: theme.palette.buttons.success,
          color: theme.palette.text.secondary1,
          "&:hover": {
            backgroundColor: theme.palette.buttons.successFocused,
          },
        },
        "&$textSuccess": {
          color: theme.palette.buttons.success,
        },
        "&$containedFailure": {
          backgroundColor: theme.palette.buttons.failure,
          color: theme.palette.text.secondary1,
          "&:hover": {
            backgroundColor: theme.palette.buttons.failureFocused,
          },
        },
      },
      contained: {
        "&$disabled": null,
        "&:hover": {
          backgroundColor: theme.palette.background.default,
        },
      },
      containedSuccess: {},
      containedFailure: {},
      textSuccess: {},
      outlinedPrimary: {
        borderColor: theme.palette.buttons.outlined,
        color: theme.palette.buttons.outlined,
        "&:hover": {
          backgroundColor: theme.palette.buttons.outlined + "0D",
          borderColor: theme.palette.buttons.outlined,
          color: theme.palette.buttons.outlined,
        },
        "&$disabled": {
          borderColor: theme.palette.buttons.outlined,
        },
      },
      outlinedSecondary: {
        borderColor: theme.palette.buttons.outlined,
        color: theme.palette.buttons.outlined,
        "&:hover": {
          backgroundColor: theme.palette.buttons.outlined + "0D",
          borderColor: theme.palette.buttons.outlined,
          color: theme.palette.buttons.outlined,
        },
        "&$disabled": {
          borderColor: theme.palette.buttons.outlined,
        },
      },
      outlined: {
        borderColor: theme.palette.buttons.outlined,
        color: theme.palette.buttons.outlined,
        "&:hover": {
          backgroundColor: theme.palette.buttons.outlined + "0D",
          borderColor: theme.palette.buttons.outlined,
          color: theme.palette.buttons.outlined,
        },
        "&$disabled": {
          borderColor: theme.palette.buttons.outlined,
        },
      },
      containedPrimary: {
        color: theme.palette.text.secondary2,
        "&:hover": {
          backgroundColor: theme.palette.buttons.primaryFocused,
        },
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: theme.palette.buttons.secondaryFocused,
        },
      },
      label: {
        textTransform: "capitalize",
        fontWeight: "600",
      },
      text: {
        textTransform: "capitalize",
      },
    },
    MuiFormLabel: {
      root: {
        "&$error": {
          color: theme.palette.text.warning,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginTop: 15,
      },
    },
    MuiInputLabel: {
      root: {
        color: theme.palette.text.primary2,
        fontSize: 14,
      },
      formControl: {
        position: "initial",
        transform: "unset",
      },
      shrink: {
        transform: "unset",
        transformOrigin: "unset",
      },
    },
    MuiFormHelperText: {
      root: {
        justifyContent: "center",
        "&$error": {
          color: theme.palette.text.warning,
          fontSize: 14,
          marginTop: 5,
          "&:before": {
            fontFamily: "Material Icons",
            content: "'highlight_off'",
            verticalAlign: "top",
            marginRight: 4,
          },
        },
      },
    },
    MuiInput: {
      root: {
        border: "1px solid " + theme.palette.border.input,
        borderRadius: 2,
      },
      input: {
        padding: "8px 12px",
        backgroundColor: "transparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
      },
      formControl: {
        backgroundColor: theme.palette.background.paper,
        "label + &": {
          marginTop: 6,
          minHeight: 36,
        },
        // padding: "10px 12px",

        "&$focused": {
          borderColor: theme.palette.primary.main,
          boxShadow: "#2699FB95 0 0 3px",
        },
        "&$disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
        },
        "&$error": {
          borderColor: theme.palette.text.warning,
          boxShadow: theme.palette.text.warning + " 0 0 3px",
        },
        position: "relative",
        fontSize: 15,
      },
    },
    MuiDivider: {
      vertical: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.header,
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: 10,
        borderRadius: 7,
        backgroundColor: "rgba(0,0,0,0.79)",
      },
      arrow: {
        color: "rgba(0,0,0,0.79)",
      },
      tooltipPlacementBottom: {
        marginTop: "5px !important",
      },
      tooltipPlacementRight: {
        marginLeft: "5px !important",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: "inherit",
      },
    },
    MuiStepIcon: {
      root: {
        width: 30,
        height: 30,
        color: theme.palette.text.hint,
      },
      text: {
        fill: theme.palette.text.secondary2,
      },
    },
    MuiStepLabel: {
      label: {
        "&$active": {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: 14,
        left: "calc(-50% + 14px)",
        right: "calc(50% + 14px)",
      },
    },
    MuiSlider: {
      markLabelActive: {
        color: theme.palette.primary.main,
      },
      valueLabel: {
        "& > span > span": {
          color: theme.palette.text.secondary2,
        },
      },
      track: {
        height: 4,
      },
      rail: {
        height: 4,
        opacity: 0.8,
        backgroundColor: "#bfbfbf",
      },
      mark: {
        backgroundColor: "#bfbfbf",
        height: 6,
        width: 1,
        marginTop: 6,
      },
      markActive: {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  };
};

const baseTheme = createMuiTheme({
  shape,
  props,
  typography,
});

const initialThemes = {
  light: createMuiTheme({
    ...baseTheme,
    palette: lightPalette,
  }),
  dark: createMuiTheme({
    ...baseTheme,
    palette: darkPalette,
  }),
  lightHC: createMuiTheme({
    ...baseTheme,
    palette: lightHCPalette,
  }),
  darkHC: createMuiTheme({
    ...baseTheme,
    palette: darkHCPalette,
  }),
};

const getTheme = (type) => {
  const theme = initialThemes[type];
  return createMuiTheme({
    ...theme,
    overrides: overrides(theme),
  });
};

const themes = {
  light: getTheme("light"),
  dark: getTheme("dark"),
  lightHC: getTheme("lightHC"),
  darkHC: getTheme("darkHC"),
};

export default themes;
