import { Box } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../Layouts/Table/Table";
import { labelPrefix } from "../../../../store/players/individual-player/self-exclusion/self-exclusion-reducer";
import { loadSelfExclusionHistory } from "../../../../store/players/individual-player/self-exclusion/actions";
import { useRouteMatch } from "react-router-dom";
import { useSession } from "../../../../utilities/hooks/useSession";

const SelfExclusionHistory = ({ data }) => {
  const { loading, history, columns } = useSelector(
    (state) => state.SelfExclusionReducer.selfExclusionHistory
  );
  const { brandSelected, partnerSelected } = useSession();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const contractId = match.params.id;
  const [query, setQuery] = React.useState({
    page: 0,
    pageSize: 5,
    orderBy: { field: "TRANSACTION_DATE", order: "asc" },
  });

  React.useEffect(() => {
    dispatch(
      loadSelfExclusionHistory(
        brandSelected.id,
        partnerSelected.id,
        contractId,
        {
          ...query,
        }
      )
    );
  }, [
    query.page,
    query.pageSize,
    query.orderBy,
    brandSelected.id,
    partnerSelected.id,
    contractId,
  ]);

  const loadData = (q) => {
    if (
      q.orderBy.field != query.orderBy.field ||
      q.page != query.page ||
      q.pageSize != query.pageSize ||
      q.orderBy.order != query.orderBy.order
    ) {
      setQuery(q);
    }
  };

  return (
    <Box m={2}>
      <Table
        {...query}
        isLoading={loading}
        pagination={true}
        title={labelPrefix + "selfExclusionHistoryTable"}
        type={"advanced"}
        columns={columns}
        data={history}
        searchBar={true}
        onFetch={loadData}
      />
    </Box>
  );
};

export default SelfExclusionHistory;
