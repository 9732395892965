import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getPlayerFavorites } from "../../store/players/individual-player/player-Favorites/actions";

export const usePlayerFavorites = (brand, partner, contractId) => {
  const { data, columns, loading, widgetFields } = useSelector(
    (state) => state.PlayerFavoritesReducer.PlayerFavorites
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPlayerFavorites(brand, partner, contractId));
  }, [dispatch, brand, partner, contractId]);

  return { data, columns, loading, widgetFields };
};
