import Typography from "@material-ui/core/Typography";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  tabContent: {
    "& .MuiTabs-root": {
      minHeight: theme.spacing(5),
      background: "transparent",
      "& .MuiTab-root": {
        opacity: 1,
        [theme.breakpoints.up("sm")]: {
          minWidth: theme.spacing(16),
        },
        minHeight: theme.spacing(5),
        borderRight: "none",
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.buttons.outlined,
        "&.Mui-selected": {
          background: "transparent",
          color: theme.palette.buttons.outlined,
          fontWeight: theme.typography.fontWeightBold,
          borderBottom: "2px solid " + theme.palette.buttons.outlined,
        },
        "&.MuiTab-dropdown": {
          borderRadius: 5,
          border: "1px solid " + theme.palette.buttons.outlined,
          padding: "5px",
          margin: "4px 0px 4px auto",
          minHeight: 32,
          height: 32,
          [theme.breakpoints.up("sm")]: {
            minWidth: "auto",
          },
        },
        background: "transparent",
      },
    },
  },
}));

const TabContent = ({ children, value, index, ...other }) => {
  const classes = useStyle();

  return (
    <Typography
      component="div"
      className={classes.tabContent}
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
};

export default TabContent;
