import { delay, select, takeLatest } from "@redux-saga/core/effects";
import {
  COMMUNICATIONS_DELIVERY_CHANNELS_FAILURE,
  COMMUNICATIONS_DELIVERY_CHANNELS_REQUEST,
  COMMUNICATIONS_DELIVERY_CHANNELS_SUCCESS,
  COMMUNICATIONS_DETAIL_FAILURE,
  COMMUNICATIONS_DETAIL_REQUEST,
  COMMUNICATIONS_DETAIL_SUCCESS,
  COMMUNICATIONS_DISABLE_FAILURE,
  COMMUNICATIONS_DISABLE_REQUEST,
  COMMUNICATIONS_DISABLE_SUCCESS,
  COMMUNICATIONS_EVENT_TYPES_FAILURE,
  COMMUNICATIONS_EVENT_TYPES_REQUEST,
  COMMUNICATIONS_EVENT_TYPES_SUCCESS,
  COMMUNICATIONS_EXTRA_PARAMETERS_FAILURE,
  COMMUNICATIONS_EXTRA_PARAMETERS_REQUEST,
  COMMUNICATIONS_EXTRA_PARAMETERS_SUCCESS,
  COMMUNICATIONS_LIST_FAILURE,
  COMMUNICATIONS_LIST_FILTERS_REQUEST,
  COMMUNICATIONS_LIST_FILTERS_SUCCESS,
  COMMUNICATIONS_LIST_RELOAD,
  COMMUNICATIONS_LIST_REQUEST,
  COMMUNICATIONS_LIST_SUCCESS,
  COMMUNICATIONS_MANAGEMENT_TYPES_FAILURE,
  COMMUNICATIONS_MANAGEMENT_TYPES_REQUEST,
  COMMUNICATIONS_MANAGEMENT_TYPES_SUCCESS,
  COMMUNICATIONS_OFFSET_TYPES_FAILURE,
  COMMUNICATIONS_OFFSET_TYPES_REQUEST,
  COMMUNICATIONS_OFFSET_TYPES_SUCCESS,
  COMMUNICATIONS_SAVE_FAILURE,
  COMMUNICATIONS_SAVE_REQUEST,
  COMMUNICATIONS_SAVE_SUCCESS,
  COMMUNICATIONS_SEND_TYPES_FAILURE,
  COMMUNICATIONS_SEND_TYPES_REQUEST,
  COMMUNICATIONS_SEND_TYPES_SUCCESS,
  COMMUNICATIONS_TEMPLATES_FAILURE,
  COMMUNICATIONS_TEMPLATES_REQUEST,
  COMMUNICATIONS_TEMPLATES_SUCCESS,
  COMMUNICATIONS_TYPES_FAILURE,
  COMMUNICATIONS_TYPES_REQUEST,
  COMMUNICATIONS_TYPES_SUCCESS,
  GO_TO_ERROR_PAGE,
} from "../../../constants";
import { call, put } from "redux-saga/effects";
import communicationsService from "./communications-service";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadListFilter = function* () {
  try {
    const data = yield call(communicationsService.getListFilter);
    yield put({
      type: COMMUNICATIONS_LIST_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
  }
};

const reloadCommunications = function* (action) {
  const lastRequest = yield select(
    (state) => state.CommunicationsReducer.communicationList.lastRequest
  );
  if (lastRequest != null) {
    yield put(lastRequest);
  }
};

const loadCommunications = function* (action) {
  try {
    const data = yield call(
      communicationsService.getCommunications,
      action.brand.id,
      action.partner.id,
      action.filters
    );
    yield put({
      type: COMMUNICATIONS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadCommunications", e);
    yield put({
      type: COMMUNICATIONS_LIST_FAILURE,
    });
  }
};

const loadCommunication = function* (action) {
  const { fullData, idKey, loading } = yield select(
    (state) => state.CommunicationsReducer.communicationList
  );
  if (loading) {
    yield delay(100);
    yield call(loadCommunication, action);
  } else {
    const data = fullData.find((it) => +it[idKey] === +action.communicationId);
    if (data != null) {
      yield put({
        type: COMMUNICATIONS_DETAIL_SUCCESS,
        payload: data,
      });
    } else {
      console.error(
        "loadCommunication",
        Error("Error getting communication with id " + action.communicationId)
      );
      yield put({
        type: COMMUNICATIONS_DETAIL_FAILURE,
      });
      yield put({
        type: GO_TO_ERROR_PAGE,
        payload: action.communicationId,
      });
    }
  }
};

const disableCommunication = function* (action) {
  try {
    yield call(
      communicationsService.disableCommunication,
      action.communicationId
    );
    yield put({
      type: COMMUNICATIONS_DISABLE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.disableCommunication",
        },
        options: {
          key: "disableCommunication",
          variant: "success",
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb(true);
    yield put({
      type: COMMUNICATIONS_LIST_RELOAD,
    });
  } catch (e) {
    console.error("disableCommunication", e);
    yield put({
      type: COMMUNICATIONS_DISABLE_FAILURE,
    });
    action.cb != null && typeof action.cb === "function" && action.cb(false);
  }
};

const loadManagementTypes = function* (action) {
  try {
    const data = yield call(communicationsService.getManagementTypes);
    yield put({
      type: COMMUNICATIONS_MANAGEMENT_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadManagementTypes", e);
    yield put({
      type: COMMUNICATIONS_MANAGEMENT_TYPES_FAILURE,
    });
  }
};

const loadDeliveryChannels = function* (action) {
  try {
    const data = yield call(communicationsService.getDeliveryChannels);
    yield put({
      type: COMMUNICATIONS_DELIVERY_CHANNELS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadDeliveryChannels", e);
    yield put({
      type: COMMUNICATIONS_DELIVERY_CHANNELS_FAILURE,
    });
  }
};

const loadCommunicationTypes = function* (action) {
  try {
    const data = yield call(communicationsService.getCommunicationTypes);
    yield put({
      type: COMMUNICATIONS_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadCommunicationTypes", e);
    yield put({
      type: COMMUNICATIONS_TYPES_FAILURE,
    });
  }
};

const loadSendTypes = function* (action) {
  try {
    const data = yield call(communicationsService.getSendTypes);
    yield put({
      type: COMMUNICATIONS_SEND_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadSendTypes", e);
    yield put({
      type: COMMUNICATIONS_SEND_TYPES_FAILURE,
    });
  }
};

const loadEventTypes = function* (action) {
  try {
    const data = yield call(communicationsService.getEventTypes);
    yield put({
      type: COMMUNICATIONS_EVENT_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadEventTypes", e);
    yield put({
      type: COMMUNICATIONS_EVENT_TYPES_FAILURE,
    });
  }
};

const loadTemplates = function* (action) {
  try {
    const data = yield call(
      communicationsService.getTemplates,
      action.brand.id,
      action.partner.id,
      action.channel,
      action.content
    );
    yield put({
      type: COMMUNICATIONS_TEMPLATES_SUCCESS,
      payload: data,
      channel: action.channel,
    });
  } catch (e) {
    console.error("loadTemplates", e);
    yield put({
      type: COMMUNICATIONS_TEMPLATES_FAILURE,
    });
  }
};

const loadOffsetTypes = function* (action) {
  try {
    const data = yield call(communicationsService.getOffsetTypes);
    yield put({
      type: COMMUNICATIONS_OFFSET_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadOffsetTypes", e);
    yield put({
      type: COMMUNICATIONS_OFFSET_TYPES_FAILURE,
    });
  }
};

const loadExtraParameters = function* (action) {
  try {
    const data = yield call(
      communicationsService.getExtraParameters,
      action.communicationType
    );
    yield put({
      type: COMMUNICATIONS_EXTRA_PARAMETERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadExtraParameters", e);
    yield put({
      type: COMMUNICATIONS_EXTRA_PARAMETERS_FAILURE,
    });
  }
};

const saveCommunication = function* (action) {
  try {
    yield call(
      communicationsService.save,
      action.brand.id,
      action.partner.id,
      action.body
    );
    yield put({
      type: COMMUNICATIONS_SAVE_SUCCESS,
    });
    yield put({
      type: COMMUNICATIONS_LIST_RELOAD,
    });
    if (action.body.communicationId) {
      yield put({
        type: COMMUNICATIONS_DETAIL_REQUEST,
        communicationId: action.body.communicationId,
      });
    }
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "ok.communications." +
            (action.body.communicationId ? "edit" : "create"),
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("saveCommunication", e);
    action.errCb && typeof action.errCb && action.errCb(e);
    if (
      e.response &&
      e.response.data &&
      e.response.data === "ko.communication.save.error_15"
    ) {
      return;
    }
    yield put({
      type: COMMUNICATIONS_SAVE_FAILURE,
    });
  }
};

const CommunicationsSaga = function* CampaignManagementSaga() {
  yield takeLatest(COMMUNICATIONS_LIST_FILTERS_REQUEST, loadListFilter);
  yield takeLatest(COMMUNICATIONS_LIST_REQUEST, loadCommunications);
  yield takeLatest(COMMUNICATIONS_DISABLE_REQUEST, disableCommunication);
  yield takeLatest(COMMUNICATIONS_LIST_RELOAD, reloadCommunications);
  yield takeLatest(COMMUNICATIONS_DETAIL_REQUEST, loadCommunication);

  yield takeLatest(
    COMMUNICATIONS_MANAGEMENT_TYPES_REQUEST,
    loadManagementTypes
  );
  yield takeLatest(
    COMMUNICATIONS_DELIVERY_CHANNELS_REQUEST,
    loadDeliveryChannels
  );
  yield takeLatest(COMMUNICATIONS_TYPES_REQUEST, loadCommunicationTypes);

  yield takeLatest(COMMUNICATIONS_SEND_TYPES_REQUEST, loadSendTypes);
  yield takeLatest(COMMUNICATIONS_EVENT_TYPES_REQUEST, loadEventTypes);
  yield takeLatest(COMMUNICATIONS_TEMPLATES_REQUEST, loadTemplates);
  yield takeLatest(COMMUNICATIONS_OFFSET_TYPES_REQUEST, loadOffsetTypes);
  yield takeLatest(
    COMMUNICATIONS_EXTRA_PARAMETERS_REQUEST,
    loadExtraParameters
  );

  yield takeLatest(COMMUNICATIONS_SAVE_REQUEST, saveCommunication);
};

export default CommunicationsSaga;
