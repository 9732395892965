import React from "react";
import * as Yup from "yup";
import {
  getPatternValidation,
  getRequiredValidation,
  getValidationByType,
} from "../../../../Layouts/form/utils";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  COMMUNICATION_PREFERENCES_FAILURE,
  COMMUNICATION_PREFERENCES_REQUEST,
  COMMUNICATION_PREFERENCES_SUCCESS,
} from "../../../constants";

const pattern = "^[0-9_]{2}[:][0-9_]{2}$";

export const prefix = "label.player.communicationPreferences.";

export const defaultData = (values) => {
  return {
    channelName: "e-mail",
    pushClients: {
      MAINTENANCE: true,
      ALARM: true,
      ALERT: false,
      PROMOTION: false,
    },
    sms: {
      MAINTENANCE: true,
      ALARM: true,
      ALERT: false,
      PROMOTION: false,
    },
    email: {
      MAINTENANCE: true,
      ALARM: true,
      ALERT: true,
      PROMOTION: false,
    },
    emailFormat: "HTML",
    smsVerified: true,
    smsPersonalBlackoutTime: {
      enabled: false,
      startTime: "00:00",
      endTime: "00:00",
    },
    smsGlobalBlackoutTime: {
      ...values.smsGlobalBlackoutTime,
    },
    emailVerified: true,
    pushClientPersonalBlackoutTime: {
      enabled: false,
      startTime: "00:00",
      endTime: "00:00",
    },
    pushClientGlobalBlackoutTime: {
      ...values.pushClientGlobalBlackoutTime,
    },
  };
};

const getValidationGroupField = () => {
  return Yup.object()
    .shape({
      enabled: Yup.string().nullable(),
      startTime: getPatternValidation(
        getRequiredValidation(
          getValidationByType("Text"),
          "Text",
          prefix,
          "startTime"
        ),
        pattern,
        prefix,
        "startTime"
      ),
      endTime: getPatternValidation(
        getRequiredValidation(
          getValidationByType("Text"),
          "Text",
          prefix,
          "endTime"
        ),
        pattern,
        prefix,
        "endTime"
      ),
    })
    .nullable();
};

export const fields = [
  {
    field: "channelName",
    name: "channelName",
    type: "Select",
    valueKey: "value",
    labelKey: "label",
    fullWidth: false,
  },
  {
    field: "emailFormat",
    name: "emailFormat",
    type: "Select",
    options: [
      { value: "HTML", label: "HTML" },
      { value: "TEXT", label: "TEXT" },
    ],
    valueKey: "value",
    labelKey: "label",
  },
  {
    field: "sms",
    name: "sms",
    type: "GroupField",
    items: [
      {
        field: "MAINTENANCE",
        name: "MAINTENANCE",
        label: <Translate needle={prefix + "MAINTENANCE"} />,
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "ALARM",
        name: "ALARM",
        label: <Translate needle={prefix + "ALARM"} />,
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "ALERT",
        name: "ALERT",
        label: <Translate needle={prefix + "Alert"} />,
        type: "Checkbox",
      },
      {
        field: "PROMOTION",
        name: "PROMOTION",
        label: <Translate needle={prefix + "Promotion"} />,
        type: "Checkbox",
      },
    ],
  },
  {
    field: "email",
    name: "email",
    type: "GroupField",
    items: [
      {
        field: "MAINTENANCE",
        name: "MAINTENANCE",
        label: <Translate needle={prefix + "MAINTENANCE"} />,
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "ALARM",
        name: "ALARM",
        label: <Translate needle={prefix + "ALARM"} />,
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "ALERT",
        name: "ALERT",
        label: <Translate needle={prefix + "Alert"} />,
        type: "Checkbox",
      },
      {
        field: "PROMOTION",
        name: "PROMOTION",
        label: <Translate needle={prefix + "Promotion"} />,
        type: "Checkbox",
      },
    ],
  },
  {
    field: "pushClients",
    name: "pushClients",
    type: "GroupField",
    items: [
      {
        field: "MAINTENANCE",
        name: "MAINTENANCE",
        label: <Translate needle={prefix + "MAINTENANCE"} />,
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "ALARM",
        name: "ALARM",
        label: <Translate needle={prefix + "ALARM"} />,
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "ALERT",
        name: "ALERT",
        label: <Translate needle={prefix + "Alert"} />,
        type: "Checkbox",
      },
      {
        field: "PROMOTION",
        name: "PROMOTION",
        label: <Translate needle={prefix + "Promotion"} />,
        type: "Checkbox",
      },
    ],
  },
  {
    field: "smsPersonalBlackoutTime",
    name: "smsPersonalBlackoutTime",
    type: "GroupField",
    items: [
      {
        field: "enabled",
        name: "enabled",
        type: "Checkbox",
      },
      {
        field: "startTime",
        name: "startTime",
        label: <Translate needle={prefix + "from"} />,
        type: "Text",
        inline: true,
        disabled: (values) => !values?.smsPersonalBlackoutTime?.enabled,
      },
      {
        field: "endTime",
        name: "endTime",
        label: <Translate needle={prefix + "to"} />,
        type: "Text",
        inline: true,
        disabled: (values) => !values?.smsPersonalBlackoutTime?.enabled,
      },
    ],
    validation: getValidationGroupField(),
  },
  {
    field: "smsGlobalBlackoutTime",
    name: "smsGlobalBlackoutTime",
    type: "GroupField",
    items: [
      {
        field: "enabled",
        name: "enabled",
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "startTime",
        name: "startTime",
        label: <Translate needle={prefix + "from"} />,
        type: "Text",
        inline: true,
        disabled: true,
      },
      {
        field: "endTime",
        name: "endTime",
        label: <Translate needle={prefix + "to"} />,
        type: "Text",
        inline: true,
        disabled: true,
      },
    ],
    validation: getValidationGroupField(),
  },
  {
    field: "pushClientPersonalBlackoutTime",
    name: "pushClientPersonalBlackoutTime",
    type: "GroupField",
    items: [
      {
        field: "enabled",
        name: "enabled",
        type: "Checkbox",
      },
      {
        field: "startTime",
        name: "startTime",
        label: <Translate needle={prefix + "from"} />,
        type: "Text",
        inline: true,
        disabled: (values) => !values?.pushClientPersonalBlackoutTime?.enabled,
      },
      {
        field: "endTime",
        name: "endTime",
        label: <Translate needle={prefix + "to"} />,
        type: "Text",
        inline: true,
        disabled: (values) => !values?.pushClientPersonalBlackoutTime?.enabled,
      },
    ],
    validation: getValidationGroupField(),
  },
  {
    field: "pushClientGlobalBlackoutTime",
    name: "pushClientGlobalBlackoutTime",
    type: "GroupField",
    items: [
      {
        field: "enabled",
        name: "enabled",
        type: "Checkbox",
        disabled: true,
      },
      {
        field: "startTime",
        name: "startTime",
        label: <Translate needle={prefix + "from"} />,
        type: "Text",
        inline: true,
        disabled: true,
      },
      {
        field: "endTime",
        name: "endTime",
        label: <Translate needle={prefix + "to"} />,
        type: "Text",
        inline: true,
        disabled: true,
      },
    ],
    validation: getValidationGroupField(),
  },
];

const initialState = {
  loading: true,
  preferences: null,
};

const CommunicationPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMUNICATION_PREFERENCES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case COMMUNICATION_PREFERENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        preferences: action.payload,
      };
    }

    case COMMUNICATION_PREFERENCES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default CommunicationPreferencesReducer;
