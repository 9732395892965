import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  RAF_LIST_FAILURE,
  RAF_LIST_REQUEST,
  RAF_LIST_SUCCESS,
} from "../../../constants";
import rafListService from "./raf-list-service";

const getRafList = function* (action) {
  try {
    let terms = {...action.terms};
    const { friendRegistrationDate, friendSendMailDate } = terms;
    terms.friendRegistrationDateFrom =
      friendRegistrationDate && friendRegistrationDate.from
        ? friendRegistrationDate.from
        : "";
    terms.friendRegistrationDateTo =
      friendRegistrationDate && friendRegistrationDate.to
        ? friendRegistrationDate.to
        : "";
    terms.friendSendMailDateFrom =
      friendSendMailDate && friendSendMailDate.from
        ? friendSendMailDate.from
        : "";
    terms.friendSendMailDateTo =
      friendSendMailDate && friendSendMailDate.to ? friendSendMailDate.to : "";
    delete terms.friendRegistrationDate;
    delete terms.friendSendMailDate;
    const data = yield call(
      rafListService.getRafList,
      action.brand,
      action.partner,
      terms
    );
    yield put({
      type: RAF_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("rafList", e);
    yield put({
      type: RAF_LIST_FAILURE,
    });
  }
};

const RafListSaga = function* () {
  yield takeLatest(RAF_LIST_REQUEST, getRafList);
};

export default RafListSaga;
