import { call, put } from "redux-saga/effects";
import {
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_FAILURE,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_REQUEST,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_FAILURE,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_REQUEST,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_SUCCESS,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_FAILURE,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST,
  INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_SUCCESS,
  INDIVIDUAL_PLAYER_RESEND_DIRECT_NOTIFICATION_REQUEST,
  SEND_DIRECT_NOTIFICATION_FAILURE,
  SEND_DIRECT_NOTIFICATION_REQUEST,
  SEND_DIRECT_NOTIFICATION_SUCCESS,
  TEMPLATE_VARIABLES_FAILURE,
  TEMPLATE_VARIABLES_REQUEST,
  TEMPLATE_VARIABLES_SUCCESS,
  TEMPLATES_FAILURE,
  TEMPLATES_REQUEST,
  TEMPLATES_SUCCESS,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import directNotificationsService from "./direct-notifications-service";

const directNotificationsHistory = function* (action) {
  try {
    const data = yield call(
      directNotificationsService.loadIndividualPlayerDirectNotificationsHistory,
      action.contractId,
      action.brand,
      action.partner,
      action.pageNumber,
      action.pageSize
    );
    yield put({
      type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerDirectNotificationsHistory", e);
    yield put({
      type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_FAILURE,
    });
  }
};

const resendDirectNotification = function* (action) {
  try {
    yield call(
      directNotificationsService.resendDirectNotification,
      action.contractId,
      action.brand,
      action.partner,
      action.notificationId
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.individualplayer.directNotifications.resend_successfully",
        },
        options: {
          key: "ResendDirectNotification",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
  } catch (e) {
    console.error("ResendDirectNotification", e);
  }
};

const directNotificationDetail = function* (action) {
  try {
    const data = yield call(
      directNotificationsService.loadDirectNotificationDetail,
      action.contractId,
      action.brand,
      action.partner,
      action.notificationId
    );
    yield put({
      type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerDirectNotificationDetail", e);
    yield put({
      type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_FAILURE,
    });
  }
};

const directNotificationAttachment = function* (action) {
  try {
    const attachment = yield call(
      directNotificationsService.loadDirectNotificationAttachment,
      action.contractId,
      action.brand,
      action.partner,
      action.notificationId,
      action.attachmentId
    );
    if (attachment && attachment.name && attachment.attachmentData) {
      let link = document.createElement("a");
      link.download = attachment.name;
      const byteCharacters = atob(attachment.attachmentData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let uArray = new Uint8Array(byteNumbers);
      let blob = new Blob([uArray], { type: "application/octet-stream" });
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    }
  } catch (e) {
    console.error("GetAttachmentDirectNotification", e);
    yield put({
      type: INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_FAILURE,
    });
  }
};

const loadTemplateVariables = function* (action) {
  try {
    const data = yield call(
      directNotificationsService.getTemplateVariables,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: TEMPLATE_VARIABLES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadTemplateVariables", e);
    yield put({
      type: TEMPLATE_VARIABLES_FAILURE,
    });
  }
};

const loadTemplates = function* (action) {
  try {
    const data = yield call(directNotificationsService.getTemplates);
    yield put({
      type: TEMPLATES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadTemplates", e);
    yield put({
      type: TEMPLATES_FAILURE,
    });
  }
};

const sendDirectNotification = function* (action) {
  try {
    yield call(
      directNotificationsService.sendNotification,
      action.brand,
      action.partner,
      action.contractId,
      action.data,
      action.files
    );
    action.successCallback();
    yield put({
      type: SEND_DIRECT_NOTIFICATION_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.send.directNotification",
          variables: [action.contractId],
        },
        options: {
          key: "SendDirectNotification",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
  } catch (e) {
    console.error("SendDirectNotification", e);
    yield put({
      type: SEND_DIRECT_NOTIFICATION_FAILURE,
    });
  }
};

const DirectNotificationsSaga = function* directNotificationsSaga() {
  yield takeLatest(
    INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST,
    directNotificationsHistory
  );
  yield takeLatest(
    INDIVIDUAL_PLAYER_RESEND_DIRECT_NOTIFICATION_REQUEST,
    resendDirectNotification
  );
  yield takeLatest(
    INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_REQUEST,
    directNotificationDetail
  );
  yield takeLatest(
    INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_REQUEST,
    directNotificationAttachment
  );
  yield takeLatest(TEMPLATE_VARIABLES_REQUEST, loadTemplateVariables);
  yield takeLatest(TEMPLATES_REQUEST, loadTemplates);
  yield takeLatest(SEND_DIRECT_NOTIFICATION_REQUEST, sendDirectNotification);
};

export default DirectNotificationsSaga;
