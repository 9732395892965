import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getPlayerDetail } from "../../store/players/individual-player/player-detail/actions";
import { isMobileOnly } from "react-device-detect";

const getEmailVerification = (playerDetail) => {
  let emailVerification = true;
  if (playerDetail && playerDetail.playerServiceParametersGroups) {
    playerDetail.playerServiceParametersGroups.forEach((i) => {
      if (i.attributeId === 1 && i.attributeValue === "true") {
        emailVerification = false;
      }
    });
  }
  return emailVerification;
};

export const usePlayerDetail = (contractId, brand, partner, isPam) => {
  const { data, loading } = useSelector((state) => state.PlayerDetailReducer);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPlayerDetail(contractId, brand, partner, isPam));
  }, [dispatch, contractId, brand, partner, isPam]);

  if (data != null) {
    data.emailVerification = getEmailVerification(data);
  }

  const detail = { ...data, isMobile: isMobileOnly, loading };

  return detail;
};
