import axios from "axios";

class AdminLimitConfigurationService {
  async loadlimitConfigurationList() {
    const response = await axios.get("/gov/api/rest/v2/limit");
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for limit configuration list");
  }

  async deleteLimitConfiguration(limitId) {
    const response = await axios.delete(`/gov/api/rest/v2/limit/${limitId}`);

    if (response == null || response.status !== 204) {
      throw Error("Invalid response for delete limit config");
    }
  }

  async enableDisableLimitConfig(enableDisableLimitdata, limitId) {
    const response = await axios.put(
      `/gov/api/rest/v2/limit/${limitId}/enable_disable`,
      enableDisableLimitdata
    );
    if (response) {
      return response.status;
    }
    throw Error("Invalid response for Limit Configuration");
  }

  async getLimitTypes() {
    let url = "/gov/api/rest/v2/limitType";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for limit types");
  }

  async getNotApprovatorRoles(brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v2/roles/not_approvator?brand-id=${brand.id}&partner-id=${partner.id}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for notApprovator roles");
  }

  async getRewardTypes() {
    let url = "/gov/api/rest/v2/approvalProcess/reward_types_need_approval";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for reward types");
  }

  async addLimitConfiguration(addLimitdata, divisorAmount) {
    addLimitdata.amount =
      addLimitdata.rewardTypeId === "LOYALTY_POINT"
        ? addLimitdata.amount / divisorAmount
        : addLimitdata.amount;
    const response = await axios.post(`/gov/api/rest/v2/limit`, addLimitdata);
    if (response && response.status !== 204) {
      throw Error("Invalid response for add limit");
    }
  }

  async editLimitConfiguration(editLimitdata) {
    const response = await axios.put(
      `/gov/api/rest/v2/limit/${editLimitdata.id}`,
      editLimitdata
    );
    if (response && response.status !== 204) {
      throw Error("Invalid response for edit limit");
    }
  }
}

const adminLimitConfigurationService = new AdminLimitConfigurationService();
export default adminLimitConfigurationService;
