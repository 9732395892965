import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  CONTACT_VERIFICATION_FAILURE,
  CONTACT_VERIFICATION_SUCCESS,
  STYLE_LABEL,
  SEND_PIN_FAILURE,
  SEND_PIN_SUCCESS,
  CONTACT_VERIFICATION_REQUEST,
} from "../../../constants";

export const getStatusMapping = (status) => {
  if (status) {
    return { id: "2", label: "verified" };
  } else {
    return { id: "3", label: "toVerify" };
  }
};

export const getMappedLabel = (name) => {
  switch (name) {
    case "sms":
      return "SMS";
    case "reserved-sms": {
      return "RESERVED_SMS";
    }
    case "e-mail": {
      return "EMAIL";
    }
    case "reserved-e-mail":
      return "RESERVED_EMAIL";
    default: {
      return null;
    }
  }
};

const initialState = {
  loading: true,
  data: [],
  columns: (isWidget) => {
    let cols = [
      {
        field: "channelName",
        title: "label.individualplayer.contactConfig.channelName",
        default: true,
        render: (rowData) => {
          const label = getMappedLabel(rowData["channelName"]);
          return (
            <Translate
              needle={`label.individualplayer.contactConfig.${label}`}
            />
          );
        },
      },
      {
        field: "addressValue",
        title: "label.individualplayer.contactConfig.addressValue",
      },
      {
        field: "enabled",
        title: "label.individualplayer.contactConfig.enabled",
        render: (rowData) => {
          if (rowData["enabled"] != null) {
            const statusMapping = getStatusMapping(rowData["enabled"]);
            return (
              <BadgeStatus
                id={statusMapping.id}
                label={statusMapping.label}
                justify={"center"}
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["enabled"] != null) {
            const statusMapping = getStatusMapping(rowData["enabled"]);
            return {
              style: STYLE_LABEL,
              value: `label.status.${statusMapping.label}`,
            };
          }
        },
      },
    ];

    if (!isWidget) {
      cols.push(
        {
          field: "verificationDate",
          title: "label.individualplayer.contactConfig.verificationDate",
          render: (rowData) => {
            if (rowData["verificationDate"] && rowData["enabled"]) {
              return <Moment format="L LT">{rowData["verificationDate"]}</Moment>;
            } else {
              return "-";
            }
          },
        },
        {
          field: "verificationChannel",
          title: "label.individualplayer.contactConfig.verificationChannel",
        },
        {
          field: "verificationUser",
          title: "label.individualplayer.contactConfig.verificationUser",
          render: (rowData) => {
            return rowData["enabled"] ? rowData["verificationUser"] : "-";
          },
        }
      );
    }

    return cols;
  },
};

const ContactVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_VERIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
        lastRequest: action,
      };
    }

    case CONTACT_VERIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case CONTACT_VERIFICATION_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }
    case SEND_PIN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEND_PIN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default ContactVerificationReducer;
