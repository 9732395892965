import {
  LATEST_REGULATOR_STATUS_FAILED,
  LATEST_REGULATOR_STATUS_REQUEST,
  LATEST_REGULATOR_STATUS_SUCCESS,
} from "../../../constants";
import React from "react";
import Moment from "react-moment";
const initialState = {
  data: null,
  columns: [
    {
      title: "label.player.latestRegulatorStatus.status",
      field: "status",
    },
    {
      title: "label.player.latestRegulatorStatus.lastUpdateDate",
      field: "lastUpdateDate",
      render: (rowData) => {
        if (rowData["lastUpdateDate"]) {
          return <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>;
        }
      },
    },
    {
      title: "label.player.latestRegulatorStatus.reason",
      field: "reason",
    },
  ],
  loading: true,
};

const LatestRegulatorStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case LATEST_REGULATOR_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case LATEST_REGULATOR_STATUS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default LatestRegulatorStatusReducer;
