import { select, takeEvery } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  BLACKLIST_FAILURE,
  BLACKLIST_FILTERS_FAILURE,
  BLACKLIST_FILTERS_REQUEST,
  BLACKLIST_FILTERS_SUCCESS,
  BLACKLIST_REQUEST,
  BLACKLIST_SUCCESS,
  BLACKLIST_TYPE_FAILURE,
  BLACKLIST_TYPE_REQUEST,
  BLACKLIST_TYPE_SUCCESS,
  BLACKLIST_VALUE_TYPE_FAILURE,
  BLACKLIST_VALUE_TYPE_REQUEST,
  BLACKLIST_VALUE_TYPE_SUCCESS,
  BLACKLIST_MANAGE_REQUEST,
  BLACKLIST_MANAGE_SUCCESS,
  BLACKLIST_MANAGE_FAILURE,
  BLACKLIST_RELOAD,
} from "../../../constants";
import { enqueueSnackbar } from "../../../snackbars/actions";
import blacklistManagementService from "./blacklist-management-service";

const getBlacklistFilters = function* (action) {
  try {
    const data = yield call(blacklistManagementService.getBlacklistFilters);
    yield put({
      type: BLACKLIST_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("blacklistFilters", e);
    yield put({
      type: BLACKLIST_FILTERS_FAILURE,
    });
  }
};

const getBlacklistType = function* (action) {
  try {
    const data = yield call(blacklistManagementService.getBlacklistType);
    yield put({
      type: BLACKLIST_TYPE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("blacklistType", e);
    yield put({
      type: BLACKLIST_TYPE_FAILURE,
    });
  }
};

const getBlacklistValueType = function* (action) {
  try {
    const data = yield call(blacklistManagementService.getBlacklistValueType);
    yield put({
      type: BLACKLIST_VALUE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("blacklistValueType", e);
    yield put({
      type: BLACKLIST_VALUE_TYPE_FAILURE,
    });
  }
};

const getBlacklist = function* (action) {
  try {
    const data = yield call(
      blacklistManagementService.getBlacklist,
      action.searchTerms
    );
    yield put({
      type: BLACKLIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("blacklist", e);
    yield put({
      type: BLACKLIST_FAILURE,
    });
  }
};

const reloadBlacklist = function* (action) {
  const lastRequest = yield select(
    (state) => state.BlacklistManagementReducer.blacklist.lastRequest
  );
  yield put(lastRequest);
};

const manageBlacklistItem = function* ({
  body,
  force,
  blacklist,
  isEdit,
  cb,
  errCb,
}) {
  try {
    yield call(
      blacklistManagementService.manageBlacklistItem,
      body,
      force,
      blacklist,
      isEdit
    );
    yield put({
      type: BLACKLIST_MANAGE_SUCCESS,
    });
    const managed =
      force && !blacklist
        ? "removeBlacklist"
        : isEdit
        ? "editBlacklist"
        : "addBlacklist";
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.rewards.campaigns.blacklist." + managed,
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    cb && typeof cb === "function" && cb();

    yield put({
      type: BLACKLIST_RELOAD,
    });
  } catch (e) {
    if (e.response && e.response["data"] !== "") {
      if (e.response["data"].indexOf("warning") !== -1) {
        cb != null && typeof cb === "function" && cb();
        errCb != null && typeof errCb === "function" && errCb();
        return false;
      }
    }
    yield put({
      type: BLACKLIST_MANAGE_FAILURE,
    });
  }
};

const BlacklistManagement = function* () {
  yield takeEvery(BLACKLIST_FILTERS_REQUEST, getBlacklistFilters);
  yield takeEvery(BLACKLIST_TYPE_REQUEST, getBlacklistType);
  yield takeEvery(BLACKLIST_VALUE_TYPE_REQUEST, getBlacklistValueType);
  yield takeEvery(BLACKLIST_REQUEST, getBlacklist);
  yield takeEvery(BLACKLIST_MANAGE_REQUEST, manageBlacklistItem);
  yield takeEvery(BLACKLIST_RELOAD, reloadBlacklist);
};

export default BlacklistManagement;
