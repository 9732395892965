class TranslateService {
  format(textString, args) {
    if (this.isObject(args)) {
      let newString = textString;
      Object.entries(args).forEach(([key, value]) => {
        let replace = "{" + key + "}";
        let re = new RegExp(replace, "g");
        newString = newString.replace(re, value);
      });
      return newString;
    } else {
      if (
        !textString.match(
          /^(?:(?:(?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{[0-9]+\}))+$/
        )
      ) {
        throw new Error("invalid format string.");
      }
      return textString.replace(
        /((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g,
        (m, str, index) => {
          if (str) {
            return str.replace(/(?:{{)|(?:}})/g, (m) => m[0]);
          } else {
            if (index >= args.length) {
              throw new Error("argument index is out of range in format");
            }
            return args[index];
          }
        }
      );
    }
  }

  isObject(obj) {
    return obj != null && obj.constructor.name === "Object";
  }

  translateIt = (needle, variables) => {
    return this.translate({
      i18n: JSON.parse(sessionStorage.getItem("i18n")),
      needle,
      variables,
    });
  };

  translate = ({ i18n, needle, label, variables, avoidLowerCase = false }) => {
    if (needle) {
      const explodedString = ("" + needle).split(".");
      let i,
        length = explodedString.length;
      let translated = i18n;
      for (i = 0; i < length; i++) {
        if (translated[explodedString[i]]) {
          translated = translated[explodedString[i]];
        } else {
          translated = undefined;
          break;
        }
      }

      if (this.isObject(translated)) {
        translated = translated[0] || undefined;
      }
      if (translated && translated !== "") {
        if (variables) {
          let translatedVariables = null;
          if (Array.isArray(variables)) {
            translatedVariables = variables.map((it) => {
              return avoidLowerCase
                ? this.translate({ i18n, needle: "" + it })
                : this.translate({ i18n, needle: "" + it })?.toLowerCase();
            });
          } else {
            translatedVariables = {};
            Object.keys(variables).forEach((it) => {
              translatedVariables[it] = avoidLowerCase
                ? this.translate({ i18n, needle: "" + variables[it] })
                : this.translate({
                    i18n,
                    needle: "" + variables[it],
                  })?.toLowerCase();
            });
          }

          if (translatedVariables != null) {
            return this.format(translated, translatedVariables);
          }
        }
        return translated;
      }
      return label && label !== "" ? label : needle;
    }
    return undefined;
  };
}

const translateService = new TranslateService();
export default translateService;
