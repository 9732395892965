import { useSelector } from "react-redux";

const useGdprCheck = () => {
  const isGdpr = useSelector(
    (state) => state.AuthenticationReducer.session.user.role.gdpr
  );
  return (columns, fields) => {
    if (isGdpr) {
      return columns;
    }
    return columns.filter((it) => !fields.includes(it.field));
  };
};

export default useGdprCheck;
