import axios from "axios";

class BonusBagsService {
  async removeBonusBag(contractId, brand, partner, bonusId, reason) {
    const response = await axios.delete(
      `/gov/api/rest/v1/ams/bonus-bags/${bonusId}`,
      {
        params: {
          brandId: brand.id,
          partnerId: partner.id,
          playerId: contractId,
          reason: reason,
        },
      }
    );

    if (response == null || response.status !== 204) {
      throw Error("Invalid response for remove Bonus Bag");
    }
  }

  async getBonusBags(contractId, brand, partner) {
    let url = `/gov/api/rest/v1/ams/bonus-bags/wrapped/`;
    const response = await axios.get(url, {
      params: {
        brandId: brand.id,
        partnerId: partner.id,
        playerId: contractId,
      },
    });
    if (response && response.data) {
      return response.data.bonusBags;
    }
    throw Error("Invalid response for bonus bag list");
  }

  async getBonusBag(bonusId, brand, partner) {
    let url = `/gov/api/rest/v1/ams/bonus-bags/${bonusId}`;
    const response = await axios.get(url, {
      params: {
        brandId: brand.id,
        partnerId: partner.id,
      },
    });
    if (response && response.data) {
      return response.data.bonusBagDTO;
    }
    throw Error("Invalid response for bonus bag detail");
  }

  async getBonusBagTransactions(bonusId, brand, partner) {
    let url = `/gov/api/rest/v1/ams/bonus-bags/transactions/${bonusId}`;
    const response = await axios.get(url, {
      params: {
        brandId: brand.id,
        partnerId: partner.id,
      },
    });
    if (response && response.data) {
      return response.data.transactions;
    }
    throw Error("Invalid response for bonus bag transactions");
  }

  async convert(body, brand, partner) {
    let url = `/gov/api/rest/v1/ams/bonus-bags/converted`;
    const response = await axios.put(url, body, {
      params: {
        brandId: brand.id,
        partnerId: partner.id,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for bonus bags conversion");
  }

  async unfreeze(body, brand, partner) {
    let url = `/gov/api/rest/v1/ams/bonus-bags/unfrozen`;
    const response = await axios.put(url, body, {
      params: {
        brandId: brand.id,
        partnerId: partner.id,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for bonus bags unfreeze");
  }

  async accept(bonusId, brand, partner, contractId, systemId) {
    let url = `/gov/api/rest/v1/ams/bonus-bags/accept`;
    var bonusIdArray = [];
    bonusIdArray.push(bonusId);
    const response = await axios.put(
      url,
      {
        bbmIds: JSON.parse(JSON.stringify(bonusIdArray)),
      },
      {
        params: {
          brandId: brand.id,
          partnerId: partner.id,
          playerId: contractId,
          systemId,
        },
      }
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for bonus bag accept");
  }
}

const bonusBagsService = new BonusBagsService();
export default bonusBagsService;
