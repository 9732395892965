import { Box } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { ReactComponent as CheckmarkIcon } from "../../../../Images/button_checkmark_24.svg";
import { ReactComponent as EmailIcon } from "../../../../Images/button_email.svg";
import { ReactComponent as MessageIcon } from "../../../../Images/button_message.svg";
import Table from "../../../../Layouts/Table/Table";
import {
  getContactVerification,
  sendPin,
} from "../../../../store/players/individual-player/contact-verification/actions";
import { usePageActions } from "../../../../utilities/hooks/usePageActions";
import { useSession } from "../../../../utilities/hooks/useSession";
import { isActionHidden } from "../../../../utils";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import Modal from "../../../../Layouts/Modal/Modal";
import { usePlayerDetail } from "../../../../utilities/hooks/usePlayerDetail";
import { getMappedLabel } from "../../../../store/players/individual-player/contact-verification/contact-verification-reducer";
import ContactVerify from "./contact-verification-verify-modal";

function ContactVerification({ widget }) {
  const { brandSelected, partnerSelected } = useSession();
  const match = useRouteMatch();
  const contractId = match.params.id;
  const dispatch = useDispatch();
  const { buttons } = usePageActions();
  const { columns, data, loading = true } = useSelector(
    (state) => state.ContactVerificationReducer
  );
  const isPam = partnerSelected.pam;
  const playerDetail = usePlayerDetail(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    isPam
  );
  const query = {
    page: 0,
    pageSize: 10,
    orderBy: { field: "consentType.creationDate", order: "desc" },
  };

  React.useEffect(() => {
    dispatch(
      getContactVerification(
        contractId,
        brandSelected.id,
        partnerSelected.id,
        widget
      )
    );
    // eslint-disable-next-line
  }, [
    dispatch,
    getContactVerification,
    contractId,
    brandSelected.id,
    partnerSelected.id,
    widget,
  ]);
  const [verificationModal, setVerificationModal] = React.useState({
    open: false,
  });
  const [verifyModal, setVerifyModal] = React.useState({
    open: false,
  });

  const t = useTranslator();

  const openVerificationModal = (rowData) => {
    const label = getMappedLabel(rowData["channelName"]);
    setVerificationModal({
      open: true,
      contactType: t({
        needle: "label.individualplayer.contactConfig." + label,
      }),
      contactDetails: rowData["addressValue"],
      channelName: rowData["channelName"],
      title:
        "label.individualplayer.contactConfig.verificationModal." +
        (rowData["channelName"] === "sms" ||
        rowData["channelName"] === "reserved-sms"
          ? "pinTitle"
          : "emailTitle"),
      body:
        "label.individualplayer.contactConfig.verificationModal." +
        (rowData["channelName"] === "sms" ||
        rowData["channelName"] === "reserved-sms"
          ? "pinBody"
          : "emailBody"),
    });
  };

  const handleClose = () => {
    setVerificationModal({ open: false });
  };

  const handleSend = () => {
    dispatch(
      sendPin(
        brandSelected.id,
        partnerSelected.id,
        contractId,
        verificationModal.channelName,
        handleClose
      )
    );
  };

  const handleVerifyModalOpen = (rowData) => {
    setVerifyModal({
      open: true,
      contactDetails: rowData["addressValue"],
      channelName: rowData["channelName"],
    });
  };

  const handleVerifyModalClose = () => {
    setVerifyModal({
      open: false,
    });
  };
  return (
    <Box>
      <Table
        {...query}
        searchBar={false}
        isLoading={loading}
        title={"label.individualplayer.contactConfig.list"}
        type={widget ? "simple" : "advanced"}
        resize={!widget}
        pagination={false}
        columns={columns(widget)}
        data={data}
        exportButton={false}
        actions={
          !widget && [
            (rowData) => {
              return {
                icon: MessageIcon,
                color: "primary",
                variant: "contained",
                tooltip: "button.sendPin",
                hidden:
                  isActionHidden(buttons, "send_pin") ||
                  rowData.enabled ||
                  rowData.channelName === "e-mail" ||
                  rowData.channelName === "reserved-e-mail",
                hideMobile: true,
                disabled : playerDetail.status > 3,
                onClick: (event, rowData) => {
                  openVerificationModal(rowData);
                },
              };
            },
            (rowData) => {
              return {
                icon: CheckmarkIcon,
                color: "success",
                variant: "contained",
                tooltip: "button.verify",
                hidden:
                  isActionHidden(buttons, "verify") ||
                  rowData.enabled ||
                  rowData.channelName === "e-mail" ||
                  rowData.channelName === "reserved-e-mail",
                hideMobile: true,
                disabled : playerDetail.status > 3,
                onClick: (event, rowData) => {
                  handleVerifyModalOpen(rowData);
                },
              };
            },
            (rowData) => {
              return {
                icon: EmailIcon,
                color: "primary",
                variant: "contained",
                tooltip: "button.sendLink",
                hidden:
                  isActionHidden(buttons, "send_email") ||
                  rowData.enabled ||
                  rowData.channelName === "sms" ||
                  rowData.channelName === "reserved-sms",
                hideMobile: true,
                disabled : playerDetail.status > 3,
                onClick: (event, rowData) => {
                  openVerificationModal(rowData);
                },
              };
            },
          ]
        }
      />
      <Modal
        open={verificationModal.open}
        title={t({
          needle: verificationModal.title,
        })}
        description={t({
          needle: verificationModal.body,
          variables: [
            verificationModal.contactType,
            verificationModal.contactDetails,
          ],
        })}
        actions={[
          {
            label: "button.cancel",
            handler: handleClose,
            variant: "outlined",
          },
          {
            label:
              verificationModal.channelName === "sms" ||
              verificationModal.channelName === "reserved-sms"
                ? "button.sendPin"
                : "button.sendLink",
            handler: handleSend,
            color: "primary",
          },
        ]}
      />
      <Modal
        open={verifyModal.open}
        title={t({
          needle: "label.individualplayer.contactConfig.verifyModal.title",
        })}
        description={t({
          needle: "label.individualplayer.contactConfig.verifyModal.body",
          variables: [contractId, verifyModal.contactDetails],
        })}
        content={
          <ContactVerify
            brand={brandSelected}
            partner={partnerSelected}
            contractId={contractId}
            channelName={verifyModal.channelName}
            handleClose={handleVerifyModalClose}
          />
        }
      />
    </Box>
  );
}

export default ContactVerification;
