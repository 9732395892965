import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  PLAYER_SESSION_INFO_REQUEST,
  PLAYER_SESSION_INFO_SUCCESS,
  SESSION_LIMITS_EDIT_FAILURE,
  SESSION_LIMITS_EDIT_REQUEST,
  SESSION_LIMITS_EDIT_SUCCESS,
  SESSION_LIMITS_FAILURE,
  SESSION_LIMITS_REQUEST,
  SESSION_LIMITS_RESET_FAILURE,
  SESSION_LIMITS_RESET_REQUEST,
  SESSION_LIMITS_RESET_SUCCESS,
  SESSION_LIMITS_SUCCESS,
  TOKEN_INVALIDATION_REQUEST,
} from "../../../../constants";
import { enqueueSnackbar } from "../../../../snackbars/actions";
import { labelPrefix } from "../../../pep/pep-reducer";
import { prefix } from "./session-limits-reducer";
import sessionLimitsService from "./session-limits-service";

const getSessionLimits = function* (action) {
  try {
    let data = yield call(
      sessionLimitsService.getSessionLimits,
      action.contractId,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: SESSION_LIMITS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("SessionLimits", e);
    yield put({
      type: SESSION_LIMITS_FAILURE,
    });
  }
};

const editSessionLimits = function* (action) {
  try {
    const data = yield call(
      sessionLimitsService.editSessionLimits,
      action.contractId,
      action.body,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: SESSION_LIMITS_EDIT_SUCCESS,
      payload: data,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: prefix + "editSessionLimits",
        },
        options: {
          key: "editSessionLimits",
          variant: "success",
          autoHideDuration: 5000,
        },
      })
    );
  } catch (e) {
    console.error("SessionLimitsEdit", e);
    yield put({
      type: SESSION_LIMITS_EDIT_FAILURE,
      payload: action.oldData,
    });
  }
};

const resetSessionLimits = function* (action) {
  try {
    const data = yield call(
      sessionLimitsService.resetSessionLimits,
      action.contractId,
      action.body,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: SESSION_LIMITS_RESET_SUCCESS,
      payload: data,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: prefix + "resetSessionLimits",
        },
        options: {
          key: "resetSessionLimits",
          variant: "success",
          autoHideDuration: 5000,
        },
      })
    );
  } catch (e) {
    console.error("SessionLimitsEdit", e);
    yield put({
      type: SESSION_LIMITS_RESET_FAILURE,
      payload: action.body,
    });
  }
};

const fetchSessionInfo = function* (action) {
  try {
    const data = yield call(
      sessionLimitsService.fetchSessionInfo,
      action.contractId,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: PLAYER_SESSION_INFO_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("fetchSessionInfo", e);
  }
};

const invalidateToken = function* (action) {
  try {
    yield call(
      sessionLimitsService.invalidateToken,
      action.contractId,
      action.brandId,
      action.partnerId
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: prefix + "invalidateToken",
        },
        options: {
          key: "invalidateToken",
          variant: "success",
          autoHideDuration: 5000,
        },
      })
    );
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("tokenInvalidation", e);
  }
};

const SessionLimitsSaga = function* sessionLimitsSaga() {
  yield takeLatest(SESSION_LIMITS_REQUEST, getSessionLimits);
  yield takeLatest(SESSION_LIMITS_EDIT_REQUEST, editSessionLimits);
  yield takeLatest(SESSION_LIMITS_RESET_REQUEST, resetSessionLimits);
  yield takeLatest(PLAYER_SESSION_INFO_REQUEST, fetchSessionInfo);
  yield takeLatest(TOKEN_INVALIDATION_REQUEST, invalidateToken);
};

export default SessionLimitsSaga;
