import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import Currency from "../Currency/Currency";

const AmountNoLimitProgressBar = ({
  limit,
  currency,
  applyDivisorAmount = true,
}) => {
  return (
    <Box component="div">
      <Box display="flex" justifyContent="center">
        <span style={{ fontWeight: "bold" }}>
          <Currency
            amount={limit}
            currency={currency}
            applyDivisorAmount={applyDivisorAmount}
          />
        </span>
      </Box>
      <LinearProgress
        variant="buffer"
        value={(0 / limit) * 100}
        valueBuffer={100}
        style={{ height: 10, borderRadius: 6 }}
      />
    </Box>
  );
};

export default AmountNoLimitProgressBar;
