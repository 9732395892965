import {
  CONTACT_VERIFICATION_REQUEST,
  SEND_PIN_REQUEST,
  VERIFY_PIN_REQUEST,
} from "../../../constants";

export const getContactVerification = (
  contractId,
  brandId,
  partnerId,
  widget
) => {
  return {
    type: CONTACT_VERIFICATION_REQUEST,
    contractId,
    brandId,
    partnerId,
    widget,
  };
};

export const sendPin = (brandId, partnerId, contractId, channelName, cb) => {
  return {
    type: SEND_PIN_REQUEST,
    brandId,
    partnerId,
    contractId,
    channelName,
    cb,
  };
};

export const verifyPin = (brandId, partnerId, data, cb) => {
  return {
    type: VERIFY_PIN_REQUEST,
    brandId,
    partnerId,
    data,
    cb,
  };
};
