import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";

class ExpiringBonusService {
  async loadExpiringBonus({ searchTerms }) {
    const url = "/gov/api/rest/v1/massiveRequests";
    const queryParam = searchTerms
      ? searchUtils.getQueryParam(searchTerms)
      : "";

    const response = await axios.get(url + queryParam);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for expiring bonus");
  }

  async expiringBonusView(massiveId, query) {
    const response = await axios.get(
      `/gov/api/rest/v2/massiveRequests/${massiveId}?pageNumber=${query.page}&pageSize=${query.pageSize}`
    );

    if (response && response.data && response.data.collection) {
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalElements,
      };
    }
    throw Error("Invalid response for expiring bonus view");
  }

  async loadPlayers(brand, partner, filters) {
    const response = await axios.get(
      `/gov/api/rest/v1/massiveRequests/searchForPlayers?brand=${brand}&partner=${partner}&${filters}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for expiring bonus load players");
  }

  async create(
    brand,
    partner,
    playerSelected,
    bonusAmount,
    currency,
    requestName
  ) {
    const response = await axios.post(
      `/gov/api/rest/v1/massiveRequests/createMassiveRewarding?amount=${bonusAmount}&currency=${currency}&jobName=${requestName}`,
      {
        players: playerSelected,
        operatorBrandId: brand,
        operatorPartnerId: partner,
      }
    );

    if (response && response.status === 200) {
      return response.status;
    }
    throw Error("Invalid response for expiring bonus load players");
  }
}

const expiringBonusService = new ExpiringBonusService();
export default expiringBonusService;
