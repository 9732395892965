import {
  EDIT_PEP_NOTES_REQUEST,
  PEP_PROCESS_REQUEST,
  PEP_SEARCH_CRITERIA_REQUEST,
  PEP_SEARCH_REQUEST,
} from "../../constants";

export const triggerPepProcess = (
  contractId,
  pepStatus,
  note,
  brandId,
  partnerId,
  isPam,
  search
) => {
  return {
    type: PEP_PROCESS_REQUEST,
    contractId,
    pepStatus,
    note,
    brandId,
    partnerId,
    isPam,
    search
  };
};

export const loadPepCriteriaFields = (brand, partner) => {
  return {
    type: PEP_SEARCH_CRITERIA_REQUEST,
    brand,
    partner,
  };
};

export const searchPeps = (searchParams, sensitiveVip) => {
  return {
    type: PEP_SEARCH_REQUEST,
    searchParams,
    sensitiveVip,
    pepStatuses: ["ENABLED", "PRE_AUTHORIZED", "AUTHORIZED", "REJECTED"],
  };
};

export const editPepNotes = (contractId, note, brandId, partnerId, isPam) => {
  return {
    type: EDIT_PEP_NOTES_REQUEST,
    contractId,
    note,
    brandId,
    partnerId,
    isPam,
  };
};
