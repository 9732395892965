import { takeEvery } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  COMMUNICATION_PREFERENCES_FAILURE,
  COMMUNICATION_PREFERENCES_REQUEST,
  COMMUNICATION_PREFERENCES_SUCCESS,
  UPDATE_COMMUNICATION_PREFERENCES_FAILURE,
  UPDATE_COMMUNICATION_PREFERENCES_REQUEST,
  UPDATE_COMMUNICATION_PREFERENCES_SUCCESS,
} from "../../../constants";
import communicationPreferencesService from "./communication-preferences-service";

const loadCommunicationPreferences = function* (action) {
  try {
    const data = yield call(
      communicationPreferencesService.loadCommunicationPreferences,
      action.contractId,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: COMMUNICATION_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("communicationPreferences", e);
    yield put({
      type: COMMUNICATION_PREFERENCES_FAILURE,
    });
  }
};

const updateCommunicationPreferences = function* (action) {
  try {
    yield call(
      communicationPreferencesService.updateCommunicationPreferences,
      action.contractId,
      action.brandId,
      action.partnerId,
      action.body
    );
    yield put({
      type: UPDATE_COMMUNICATION_PREFERENCES_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("communicationPreferences", e);
    yield put({
      type: UPDATE_COMMUNICATION_PREFERENCES_FAILURE,
    });
  }
};

const CommunicationPreferencesSaga = function* communicationPreferencesSaga() {
  yield takeEvery(
    COMMUNICATION_PREFERENCES_REQUEST,
    loadCommunicationPreferences
  );
  yield takeEvery(
    UPDATE_COMMUNICATION_PREFERENCES_REQUEST,
    updateCommunicationPreferences
  );
};

export default CommunicationPreferencesSaga;
