import Moment from "react-moment";
import React from "react";
import {
  NETWORK_LIMITS_ASSOCIATED_PROFILES_SUCCESS,
  NETWORK_LIMITS_ASSOCIATED_PROFILES_FAILURE,
} from "../../../../constants";
import Translate from "../../../../../Layouts/Translator/Translate";
import { isMobileOnly } from "react-device-detect";
import BadgeStatus from "../../../../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../../../../Layouts/Currency/Currency";
import AmountProgressBar from "../../../../../Layouts/progress-bar/amount-progress-bar";

const getStatusMapping = (status) => {
  let badgeMapped = {};
  if (status) {
    badgeMapped.badgeId = "2";
    badgeMapped.badgeLabel = "yes";
  } else {
    badgeMapped.badgeId = "6";
    badgeMapped.badgeLabel = "no";
  }
  return badgeMapped;
};

const initialState = {
  loading: true,
  associatedProfileList: {
    data: [],
    columns: [
      {
        field: "gameDomainName",
        title:
          "label.individualplayer.responsibleGame.networkLimits.gameDomain",
        default: true,
      },
      {
        field: "hasActiveLimits",
        title:
          "label.individualplayer.responsibleGame.networkLimits.hasActiveLimits",
        nowrap: false,
        render: (rowData) => {
          const badgeMapped = getStatusMapping(rowData["hasActiveLimits"]);
          return (
            <BadgeStatus
              id={badgeMapped.badgeId}
              label={badgeMapped.badgeLabel}
              justify="center"
            />
          );
        },
        default: true,
      },
      {
        field: "hasPendingLimits",
        title:
          "label.individualplayer.responsibleGame.networkLimits.hasPendingLimits",
        nowrap: false,
        render: (rowData) => {
          const badgeMapped = getStatusMapping(rowData["hasPendingLimits"]);
          return (
            <BadgeStatus
              id={badgeMapped.badgeId}
              label={badgeMapped.badgeLabel}
              justify="center"
            />
          );
        },
      },
    ],
    detailColumns: [
      {
        title: "label.individualplayer.responsibleGame.networkLimits.type",
        field: "buyLimit.type",
        lookup: {
          1: (
            <Translate needle="label.individualplayer.responsibleGame.networkLimits.daily" />
          ),
          2: (
            <Translate needle="label.individualplayer.responsibleGame.networkLimits.weekly" />
          ),
          3: (
            <Translate needle="label.individualplayer.responsibleGame.networkLimits.monthly" />
          ),
        },
        editable: "never",
      },
      {
        title: "label.individualplayer.responsibleGame.networkLimits.value",
        field: "buyLimit.limitAmount",
        nowrap: false,
        render: (rowData) => {
          if (rowData["buyLimit"]["limitAmount"] > 0) {
            const actualValue =
              rowData["actualLimit"] &&
              rowData["actualLimit"]["limitAmount"] > 0
                ? rowData["actualLimit"]["limitAmount"]
                : 0;
            const limit = rowData["buyLimit"]["limitAmount"];
            const currency = rowData["buyLimit"]["currency"];
            return (
              <AmountProgressBar
                value={actualValue}
                limit={limit}
                currency={currency}
                applyDivisorAmount={false}
              />
            );
          } else {
            rowData["buyLimit"]["limitAmount"] = 0;
            return "-";
          }
        },
        hideMobile: true,
      },
      {
        title:
          "label.individualplayer.responsibleGame.networkLimits.spentToDate",
        field: "actualLimit.limitAmount",
        render: (rowData) => {
          if (rowData["actualLimit"]["limitAmount"] > 0) {
            const actualValue = rowData["actualLimit"]["limitAmount"];
            const currency = rowData["actualLimit"]["currency"];
            return (
              <Currency
                amount={actualValue}
                currency={currency}
                applyDivisorAmount={false}
                justify={"flex-end"}
              />
            );
          } else {
            rowData["actualLimit"]["limitAmount"] = 0;
            return "-";
          }
        },
        hidden: !isMobileOnly,
      },
      {
        title: "label.individualplayer.responsibleGame.networkLimits.maxLimit",
        field: "buyLimit.limitAmount",
        render: (rowData) => {
          if (rowData["buyLimit"]["limitAmount"] > 0) {
            const actualValue = rowData["buyLimit"]["limitAmount"];
            const currency = rowData["buyLimit"]["currency"];
            return (
              <Currency
                amount={actualValue}
                currency={currency}
                applyDivisorAmount={false}
                justify={"flex-end"}
              />
            );
          } else {
            rowData["buyLimit"]["limitAmount"] = 0;
            return "-";
          }
        },
        hidden: !isMobileOnly,
      },
      {
        title:
          "label.individualplayer.responsibleGame.networkLimits.pendingLimit",
        field: "buyLimitPending.limitAmount",
        render: (rowData) => {
          if (rowData["buyLimitPending"]["limitAmount"] > 0) {
            const amount = rowData["buyLimitPending"]["limitAmount"];
            const currency = rowData["buyLimitPending"]["currency"];
            return (
              <Currency
                amount={amount}
                currency={currency}
                applyDivisorAmount={false}
                justify={"flex-end"}
              />
            );
          } else {
            rowData["buyLimitPending"]["limitAmount"] = 0;
            return "-";
          }
        },
        editable: "never",
      },
      {
        title:
          "label.individualplayer.responsibleGame.networkLimits.activationDate",
        field: "buyLimitPending.activationDate",
        render: (rowData) => {
          if (rowData["buyLimitPending"]["activationDate"]) {
            return (
              <Moment format={"L LT"}>
                {rowData.buyLimitPending.activationDate}
              </Moment>
            );
          } else {
            return "-";
          }
        },
        editable: "never",
      },
    ],
  },
};

const NetworkLimitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_LIMITS_ASSOCIATED_PROFILES_SUCCESS: {
      return {
        ...state,
        loading: false,
        associatedProfileList: {
          ...state.associatedProfileList,
          data: action.payload,
        },
      };
    }
    case NETWORK_LIMITS_ASSOCIATED_PROFILES_FAILURE: {
      return {
        ...state,
        loading: false,
        associatedProfileList: { ...state.associatedProfileList, data: null },
      };
    }
    default:
      return state;
  }
};

export default NetworkLimitsReducer;
