import { Box } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSession } from "../../utilities/hooks/useSession";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1),
  },
  label: {
    padding: theme.spacing(0, 1),
    fontWeight: theme.typography.fontWeightSemiBold,
  },
}));

const EnvironmentBadge = () => {
  const classes = useStyle();
  const { controlEnvironment } = useSession();
  const theme = useTheme();

  if (!controlEnvironment) {
    return null;
  }

  const env = controlEnvironment.split("-");
  const envColor =
    theme.palette.envColors[env[0]] || theme.palette.envColors["default"];
  const envLabel = env.join("");

  return (
    <Box mr={1}>
      <Chip
        classes={{
          root: classes.root,
          label: classes.label,
        }}
        style={{ backgroundColor: envColor.background, color: envColor.color }}
        label={envLabel}
      />
    </Box>
  );
};

export default EnvironmentBadge;
