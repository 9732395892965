import React from "react";
import { useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { useFinancialLimitsAssociatedProfiles } from "../../../../utilities/hooks/useFinancialLimitsAssociatedProfiles";
import Modal from "../../../../Layouts/Modal/Modal";
import { useSession } from "../../../../utilities/hooks/useSession";
import Table from "../../../../Layouts/Table/Table";
import { ReactComponent as SettingsIcon } from "../../../../Images/icon_user_settings.svg";
import {
  editFinancialLimitsProfileHandler,
  enableDisableFinancialLimitsProfileHandler,
  saveConfigurationProfilesHandler,
  approveRejectProfile,
} from "../../../../store/players/individual-player/responsible-game/financial-limits/actions";
import PickList from "../../../../Layouts/Lists/PickList";
import { useFinancialLimitsNotAssociatedProfiles } from "../../../../utilities/hooks/useFinancialLimitsNotAssociatedProfiles";
import { usePlayerDetail } from "../../../../utilities/hooks/usePlayerDetail";
import Check from "@material-ui/icons/Check";
import Block from "@material-ui/icons/Block";
import { isActionHidden } from "../../../../utils";
import { usePageActions } from "../../../../utilities/hooks/usePageActions";
import History from "@material-ui/icons/History";
import FinancialLimitHistory from "./financial-limit-history";

const useStyle = makeStyles((theme) => ({
  pickList: {
    paddingLeft: theme.spacing(3),
  },
}));

const FinancialLimits = ({
  widget,
  loading = true,
  columns,
  editHandler,
  enableDisableHandler,
  approveReject,
}) => {
  const classes = useStyle();
  const t = useTranslator();
  const match = useRouteMatch();
  const { brandSelected, partnerSelected, divisorAmount, systemCurrency } = useSession();
  const contractId = match.params.id;
  const [historyModal, setHistoryModal] = React.useState(null);
  const [openConfigProfilesModal, setOpenConfigProfilesModal] = React.useState(
    false
  );
  const [approveRejectModal, setApproveRejectModal] = React.useState({
    open: false,
  });

  const query = {
    page: 0,
    pageSize: widget ? 5 : 10,
  };
  const attribute = "attribute";

  const { buttons } = usePageActions();

  let data = useFinancialLimitsAssociatedProfiles(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    divisorAmount
  );

  let right = [];

  let left = useFinancialLimitsNotAssociatedProfiles(
    contractId,
    brandSelected.id,
    partnerSelected.id
  );

  const { currency } = usePlayerDetail(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    partnerSelected.pam
  );

  const handleUpdateRow = (newData) => {
    if (newData.measure || newData.type.name === "ALERT_BAL_ABOVE_THRESHOLD" || newData.type.name === "ALERT_BAL_BELOW_THRESHOLD") {
      newData.value = newData.value * divisorAmount;
    }
    newData.status = newData.enabled ? 1 : -1;
    editHandler(
      contractId,
      brandSelected.id,
      partnerSelected.id,
      divisorAmount,
      newData
    );
  };

  const handleEnableProfile = (newData) => {
    newData.status = 1;
    if (newData.type && (newData.type.macroType === "CURRENCY" || newData.type.name === "ALERT_BAL_ABOVE_THRESHOLD" || newData.type.name === "ALERT_BAL_BELOW_THRESHOLD")) {
      newData.value = newData.value * divisorAmount;
      newData.spentLimit = newData.spentLimit * divisorAmount;
      if (newData.reserveValue) {
        newData.reserveValue = newData.reserveValue * divisorAmount;
      }
    }
    enableDisableHandler(
      contractId,
      brandSelected.id,
      partnerSelected.id,
      newData,
      divisorAmount
    );
  };

  const handleDisableProfile = (newData) => {
    newData.status = -1;
    if (newData.type && (newData.type.macroType === "CURRENCY" || newData.type.name === "ALERT_BAL_ABOVE_THRESHOLD" || newData.type.name === "ALERT_BAL_BELOW_THRESHOLD")) {
      newData.value = newData.value * divisorAmount;
      newData.spentLimit = newData.spentLimit * divisorAmount;
      if (newData.reserveValue) {
        newData.reserveValue = newData.reserveValue * divisorAmount;
      }
    }
    enableDisableHandler(
      contractId,
      brandSelected.id,
      partnerSelected.id,
      newData,
      divisorAmount
    );
  };

  const handleOpenConfigProfilesModal = () => {
    setOpenConfigProfilesModal(true);
  };

  const handleCloseConfigProfilesModal = () => {
    setOpenConfigProfilesModal(false);
  };

  const handleSaveConfigProfilesModal = () => {
    if (right) {
      right.forEach((r) => {
        let profile = {};
        profile["value"] = r.defaultValue;
        profile["status"] = -1;
        profile["type"] = r;
        profile["measure"] = r.macroType === "CURRENCY" ? currency : "";
        editHandler(
          contractId,
          brandSelected.id,
          partnerSelected.id,
          divisorAmount,
          profile
        );
      });
    }
    setOpenConfigProfilesModal(false);
  };

  const handleSelectChange = (selected) => {
    right = selected;
  };

  const setAttributeRight = () => {
    if (right) {
      right.forEach((el) => {
        el.attribute = t({
          needle:
            "label.individualplayer.responsibleGame.financialLimits.profile." +
            el["type"]["profileDataTypeId"],
        });
      });
    }
  };

  const setAttributeLeft = () => {
    if (left) {
      left.forEach((el) => {
        el.attribute = t({
          needle:
            "label.individualplayer.responsibleGame.financialLimits.profile." +
            el["profileDataTypeId"],
        });
      });
    }
  };

  const getViewConfigProfilesContent = () => {
    setAttributeRight();
    setAttributeLeft();
    return (
      <Box className={classes.pickList}>
        <PickList
          data={left}
          selected={right}
          onChange={handleSelectChange}
          attribute={attribute}
        />
      </Box>
    );
  };

  const handleOpenApproveRejectModal = (action, data) => {
    setApproveRejectModal({
      open: true,
      title: "label.modal.responsibleGame.financialLimits." + action,
      profileName: t({
        needle:
          "label.individualplayer.responsibleGame.financialLimits.profile." +
          data.type.profileDataTypeId,
      }),
      action: action,
      profileId: data.id,
    });
  };

  const handleCloseApproveRejectModal = () => {
    setApproveRejectModal({ open: false });
  };

  const getApproveRejectActions = (action) => {
    let modalAction = [
      {
        color: "primary",
        label: "button.cancel",
        variant: "outlined",
        handler: handleCloseApproveRejectModal,
      },
    ];

    if (action === "approve") {
      modalAction.push({
        color: "success",
        label: "button.approve",
        hidden: approveRejectModal.action !== "approve",
        handler: () =>
          aprroveRejectHandler(
            approveRejectModal.action,
            approveRejectModal.profileId
          ),
      });
    } else {
      modalAction.push({
        color: "failure",
        label: "button.reject",
        hidden: approveRejectModal.action !== "reject",
        handler: () =>
          aprroveRejectHandler(
            approveRejectModal.action,
            approveRejectModal.profileId
          ),
      });
    }

    return modalAction;
  };

  const aprroveRejectHandler = (action, profileId) => {
    approveReject(
      contractId,
      brandSelected,
      partnerSelected,
      action,
      profileId,
      divisorAmount,
      handleCloseApproveRejectModal
    );
  };

  const handleOpenHistoryModal = (rowData) => {
    setHistoryModal({
      data: rowData,
    });
  };

  const handleCloseHistoryModal = () => {
    setHistoryModal(null);
  };

  return (
    <Box>
      <Table
        isLoading={loading}
        title={"label.individualplayer.responsibleGame.financialLimits.list"}
        type={widget ? "simple" : "advanced"}
        resize={!widget}
        pagination={widget ? data != null && data.length > 5 : true}
        columns={columns(divisorAmount,systemCurrency).filter((it) => !widget || it.widget)}
        data={data}
        editable={!isActionHidden(buttons, "edit_limit")}
        isEditable={(rowData) => rowData.status + "" === "1"}
        rowUpdate={(newData, oldData) =>
          new Promise((resolve, reject) => {
            handleUpdateRow(newData);
            return resolve();
          })
        }
        exportButton={false}
        {...query}
        actions={
          !widget
            ? [
                {
                  icon: SettingsIcon,
                  color: "secondary",
                  variant: "contained",
                  tooltip: "button.configureProfiles",
                  hideMobile: true,
                  position: "toolbar",
                  onClick: (event, rowData) => {
                    handleOpenConfigProfilesModal();
                  },
                },
                (rowData) => ({
                  icon: Check,
                  color: "success",
                  variant: "contained",
                  tooltip: "button.enable",
                  hideMobile: true,
                  hidden:
                    rowData.enabled ||
                    isActionHidden(buttons, "enable_disable_limit"),
                  onClick: (event, rowDataEnable) => {
                    handleEnableProfile(rowDataEnable);
                  },
                }),
                (rowData) => ({
                  icon: Block,
                  color: "failure",
                  variant: "contained",
                  tooltip: "button.disable",
                  hidden:
                    !rowData.enabled ||
                    isActionHidden(buttons, "enable_disable_limit"),
                  hideMobile: true,
                  onClick: (event, rowDataDisable) => {
                    handleDisableProfile(rowDataDisable);
                  },
                }),
                (rowData) => ({
                  icon: Check,
                  color: "success",
                  tooltip: "button.approve",
                  hidden: !(
                    rowData.enabled && rowData.reserveStatus + "" === "3"
                  ),
                  hideMobile: true,
                  onClick: () => {
                    handleOpenApproveRejectModal("approve", rowData);
                  },
                }),
                (rowData) => ({
                  icon: Block,
                  tooltip: "button.reject",
                  color: "failure",
                  hidden: !(
                    rowData.enabled && rowData.reserveStatus + "" === "3"
                  ),
                  hideMobile: true,
                  onClick: () => {
                    handleOpenApproveRejectModal("reject", rowData);
                  },
                }),

                (rowData) => ({
                  icon: History,
                  tooltip: "button.history",
                  color: "primary",
                  hidden: isActionHidden(buttons, "financial_limits_history"),
                  onClick: (e, rowData) => {
                    handleOpenHistoryModal(rowData);
                  },
                }),
              ]
            : undefined
        }
      />
      <Modal
        open={openConfigProfilesModal}
        title={t({
          needle:
            "label.modal.responsibleGame.financialLimits.configureProfiles",
        })}
        content={getViewConfigProfilesContent()}
        maxWidth="md"
        actions={[
          {
            color: "primary",
            label: "button.cancel",
            variant: "outlined",
            handler: handleCloseConfigProfilesModal,
          },
          {
            color: "primary",
            label: "button.save",
            variant: "contained",
            handler: handleSaveConfigProfilesModal,
          },
        ]}
      />

      <Modal
        open={approveRejectModal.open}
        title={t({
          needle: approveRejectModal.title,
        })}
        description={t({
          needle:
            "label.individualplayer.responsibleGame.financialLimits.modal" +
            approveRejectModal.action,
          variables: [approveRejectModal.profileName],
        })}
        actions={getApproveRejectActions(approveRejectModal.action)}
      />

      <Modal
        open={Boolean(historyModal)}
        title={t({
          needle:
            "label.individualplayer.responsibleGame.financialLimits.history.title",
        })}
        content={<FinancialLimitHistory data={historyModal?.data} />}
        maxWidth="md"
        actions={[
          {
            color: "primary",
            label: "button.close",
            variant: "outlined",
            handler: handleCloseHistoryModal,
          },
        ]}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { loading } = state.FinancialLimitsReducer;
  const { columns } = state.FinancialLimitsReducer.associatedProfileList;
  return {
    loading,
    columns,
  };
};

const mapDispatchToProps = {
  editHandler: editFinancialLimitsProfileHandler,
  enableDisableHandler: enableDisableFinancialLimitsProfileHandler,
  saveConfigurationHandler: saveConfigurationProfilesHandler,
  approveReject: approveRejectProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialLimits);
