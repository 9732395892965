import {
  SIMPLE_PLATFORMS_FAILURE,
  SIMPLE_PLATFORMS_SUCCESS,
} from "../../constants";

const initialState = {
  gameDomainList: {
    idKey: "gameDomainId",
    loading: true,
    columns: [
      {
        field: "shortDescription",
        title: "label.rewards.loyalty.gameDomains.shortDescription",
        defaultSort: "asc",
        default: true,
      },
      {
        field: "longDescription",
        title: "label.rewards.loyalty.gameDomains.longDescription",
      },
    ],
  },
};

const LoyaltyGameDomainsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIMPLE_PLATFORMS_SUCCESS: {
      return {
        ...state,
        gameDomainList: { ...state.gameDomainList, loading: false },
      };
    }
    case SIMPLE_PLATFORMS_FAILURE: {
      return {
        ...state,
        gameDomainList: { ...state.gameDomainList, loading: false },
      };
    }
    default: {
      return state;
    }
  }
};

export default LoyaltyGameDomainsReducer;
