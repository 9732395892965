import {Box, ListItem, ListItemText} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import * as moment from "moment";
import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../../Layouts/BadgeStatus/BadgeStatus";
import YesNoBadge from "../../../Layouts/BadgeStatus/yes-no-badge";
import DateFormatter from "../../../Layouts/date-formatter/date-formatter";
import Translate from "../../../Layouts/Translator/Translate";
import * as criteria from "./criteria.json";
import {
  PEP_PROCESS_REQUEST,
  PEP_PROCESS_SUCCESS,
  PEP_SEARCH_CRITERIA_FAILURE,
  PEP_SEARCH_CRITERIA_REQUEST,
  PEP_SEARCH_CRITERIA_SUCCESS,
  PEP_SEARCH_FAILURE,
  PEP_SEARCH_REQUEST,
  PEP_SEARCH_SUCCESS,
  STYLE_LABEL,
} from "../../constants";
import List from "@material-ui/core/List";

export const labelPrefix = "label.pepSearch.columns";

const getStatusMapping = (status) => {
  switch (status) {
    case 1:
      return { id: "3", label: "toValidate" };
    case 2:
      return { id: "2", label: "partiallyValidated" };
    case 3:
      return { id: "2", label: "validated" };
    case 4:
      return { id: "1", label: "resolved" };
    case 5:
      return { id: "1", label: "completed" };
    case 6:
      return { id: "6", label: "hidden" };
    case 8:
      return { id: "7", label: "toClose" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const getPepStatusMapping = (status) => {
  const toReturn = {fullLabel :  "label.pepStatuses." + status, id: "1"};
  switch (status) {
    case "ENABLED":
      toReturn.id = "3";
      break;
    case "PRE_AUTHORIZED":
      toReturn.id = "2";
      break
    case "AUTHORIZED":
      toReturn.id = "2";
      break;
  }
  return toReturn;
};

const initialState = {
  pepSearch: {
    idKey: "CONTRACT_IDENTITY",
    isLoading: true,
    fieldsLoading: false,
    searchCriteria: criteria.default,
    players: { data: null, page: 0, totalCount: 0 },
    filteredColumns: [
      "USERNAME",
      "NICKNAME",
      "FULLNAME",
      "ADDRESS",
      "EMAIL",
      "CELLULAR_PHONE",
      "HOME_PHONE",
    ],
    columns: (isPam, sensitiveVip, t) => {
      return [
        {
          field: "CONTRACT_IDENTITY",
          title: labelPrefix + ".CONTRACT_IDENTITY",
          defaultSort: "asc",
          nowrap: false,
          render: (rowData) => {
            return (
              <Box display={"flex"} flexDirection={"row"}>
                <Box pt={0.5}>{rowData["CONTRACT_IDENTITY"]}</Box>
              </Box>
            );
          },
        },
        {
          field: "PEP_STATUS",
          title: labelPrefix + ".PEP_STATUS",
          nowrap: false,
          render: (rowData) => {
            if (rowData["PEP_STATUS"]) {
              const statusMapped = getPepStatusMapping(rowData["PEP_STATUS"]);
              return (
                <BadgeStatus
                    {...statusMapped}
                  justify={"center"}
                />
              );
            }
          },
        },
        {
          field: "CONTRACT_STATUS_ID",
          title: labelPrefix + ".CONTRACT_STATUS_ID",
          nowrap: false,
          render: (rowData) => {
            if (rowData["CONTRACT_STATUS_ID"]) {
              const statusMapped = getStatusMapping(
                rowData["CONTRACT_STATUS_ID"]
              );
              return (
                <BadgeStatus
                  id={statusMapped.id}
                  label={statusMapped.label}
                  justify={"center"}
                />
              );
            }
          },
          getExportTypeFn: (rowData) => {
            if (rowData["CONTRACT_STATUS_ID"]) {
              const statusMapping = getStatusMapping(
                rowData["CONTRACT_STATUS_ID"]
              );
              return {
                style: STYLE_LABEL,
                value: `label.status.${statusMapping.label}`,
              };
            }
          },
        },
        {
          field: "FULLNAME",
          hidden: !isPam,
          title: labelPrefix + ".FULLNAME",
          sortField: "FULLNAME",
        },
        {
          field: "EMAIL",
          hidden: !isPam,
          title: labelPrefix + ".EMAIL",
          sorting: false,
        },
        {
          field: "REGISTRATION_DATE",
          title: labelPrefix + ".REGISTRATION_DATE",
          sorting: true,
          render: (rowData) => {
            if (rowData["REGISTRATION_DATE"]) {
              return <Moment format="L LT">{rowData["REGISTRATION_DATE"].time}</Moment>;
            }
          },
          getExportValueFn: (rowData) => {
            if (rowData["REGISTRATION_DATE"]) {
              return moment(rowData["REGISTRATION_DATE"]).format("L LT");
            }
          },
        },
        {
          field: "PEP_PRE_AUTH_AUTHOR",
          title: labelPrefix + ".PEP_PRE_AUTH_AUTHOR",
        },
        {
          field: "PEP_PRE_AUTH_DATE",
          title: labelPrefix + ".PEP_PRE_AUTH_DATE",
          render: (rowData) => {
            if (rowData["PEP_PRE_AUTH_DATE"]) {
              return (
                <Moment format="L LT">{rowData["PEP_PRE_AUTH_DATE"].time}</Moment>
              );
            }
          },
        },
        {
          field: "PEP_AUTH_AUTHOR",
          title: labelPrefix + ".PEP_AUTH_AUTHOR",
        },
        {
          field: "PEP_AUTH_DATE",
          title: labelPrefix + ".PEP_AUTH_DATE",
          render: (rowData) => {
            if (rowData["PEP_AUTH_DATE"]) {
              return <Moment format="L LT">{rowData["PEP_AUTH_DATE"].time}</Moment>;
            }
          },
        },
      ].filter((it) => !it.hidden);
    },
  },

  pepDetails: {
    fields: [
      {
        field: "5700",
        title: "label.player.personalInfo.pepState",
        render: (rowData) => (
          <Translate needle={"label.pepStatuses."+rowData["5700"]}/>
        )
      },
      {
        field: "5701",
        title: "label.player.personalInfo.preAuthDate",

        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["5701"]} />
        ),
      },
      {
        field: "5702",
        title: "label.player.personalInfo.preAuthAuthor",
      },
      {
        field: "5703",
        title: "label.player.personalInfo.authDate",

        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["5703"]} />
        ),
      },
      {
        field: "5704",
        title: "label.player.personalInfo.authAuthor",
      },
      {
        field: "5705",
        title: "label.player.personalInfo.resetDate",

        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["5705"]} />
        ),
      },
      {
        field: "5706",
        title: "label.player.personalInfo.resetAuthor",
      },
      {
        field: "5707",
        title: "label.player.personalInfo.rejectDate",

        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["5707"]} />
        ),
      },
      {
        field: "5708",
        title: "label.player.personalInfo.rejectAuthor",
      },
      {
        field: "304",
        title: "label.player.personalInfo.countryOfBirth",
      },
    ],
  },
};

const PepReducer = (state = initialState, action) => {
  switch (action.type) {
    case PEP_PROCESS_SUCCESS: {
      return {
        ...state,
        pepSearch: { ...state.pepSearch},
      };
    }

    case PEP_PROCESS_REQUEST: {
      return {
        ...state,
        pepSearch: { ...state.pepSearch, isLoading: true },
      };
    }

    case PEP_SEARCH_REQUEST: {
      return {
        ...state,
        pepSearch: { ...state.pepSearch, isLoading: true, lastRequest: action },
      };
    }

    case PEP_SEARCH_SUCCESS: {
      return {
        ...state,
        pepSearch: {
          ...state.pepSearch,
          players: action.payload,
          isLoading: false,
        },
      };
    }

    case PEP_SEARCH_FAILURE: {
      return {
        ...state,
        pepSearch: { ...state.pepSearch, players: { data: null, page: 0, totalCount: 0 }, isLoading: false },
      };
    }

    default: {
      return state;
    }
  }
};

export default PepReducer;
