import { Box, Button } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InputField from "../../../Layouts/form/input-field";
import {
  editPepNotes,
  triggerPepProcess,
} from "../../../store/players/pep/actions";
import { labelPrefix } from "../../../store/players/pep/pep-reducer";
import { useSession } from "../../../utilities/hooks/useSession";
import { useTranslator } from "../../../utilities/hooks/useTranslator";
import moment from "moment";

const PepChangeStatusModal = ({
  contractId,
  modalData,
  handleClosePepModal,
  isEditNote,
    search= false
}) => {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { brandSelected, partnerSelected, user } = useSession();

  const noteTranslationPrefix = 'label.individualplayer.pepdetails.predefinedNote';
const predefinedNotes = {
  AUTHORIZED: noteTranslationPrefix+'.AUTHORIZED',
  PRE_AUTHORIZED: noteTranslationPrefix+'.PRE_AUTHORIZED'
};

const predefinedNote = predefinedNotes[modalData?.data?.pepState];
const initialNote = predefinedNote != null ? t({needle: predefinedNote, variables:{login: user?.username, time: moment().format('L LTS')}}) : "";

  return (
    <Box>
      <Formik
        initialValues={{
          note: initialNote,
        }}
        validationSchema={Yup.object().shape({
          note: Yup.string().required(
            t({
              needle: labelPrefix + "noteRequired",
            })
          ),
        })}
        onSubmit={(values) => {
          if (isEditNote) {
            dispatch(
              editPepNotes(
                contractId,
                values.note,
                brandSelected.id,
                partnerSelected.id,
                partnerSelected.pam
              )
            );
          } else {
            dispatch(
              triggerPepProcess(
                contractId,
                modalData.data.pepState,
                values.note,
                brandSelected.id,
                partnerSelected.id,
                partnerSelected.pam,
                  search
              )
            );
          }

          handleClosePepModal();
        }}
      >
        {(formikProps) => (
          <Form>
            <Box m={2}>
              <InputField
                name={"note"}
                label={t({
                  needle: labelPrefix + "note",
                })}
                fullWidth
              />
            </Box>
            <Box m={2} display={"flex"} justifyContent={"flex-end"} pt={5}>
              <Box mr={1}>
                <Button
                  variant={"outlined"}
                  color={"secondary"}
                  type="button"
                  onClick={() => handleClosePepModal()}
                >
                  {t({ needle: "button.cancel" })}
                </Button>
              </Box>
              <Box>
                <Button type={"submit"} color={modalData.props.color}>
                  {t({ needle: modalData.props.buttonLabel })}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PepChangeStatusModal;
