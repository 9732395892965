import axios from "axios";
import { CRITERIA_TYPE_SELECT } from "../../../constants";
import searchUtils from "../../../../utilities/searchUtils";
import { labelPrefix } from "./network-bans-reducer";
import gamePlatformsService from "../../../game-platforms/game-platforms-service";

class NetworkBansService {
  getListFilter = async (cb) => {
    const filter = [
      {
        name: "contractId",
        label: labelPrefix + "contractId",
        selected: true,
      },
      {
        name: "playerNickname",
        label: labelPrefix + "nickname",
      },
      {
        name: "event",
        label: labelPrefix + "event",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        newLine: true,
        options: [
          { label: labelPrefix + "login", value: "LOGIN" },
          { label: labelPrefix + "subscription", value: "SUBSCRIPTION" },
        ],
      },
      {
        name: "type",
        label: labelPrefix + "banType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          { label: labelPrefix + "permanent", value: "PERMANENT" },
          { label: labelPrefix + "temporary", value: "TEMPORARY" },
        ],
      },
    ];

    const gamePlatforms = await gamePlatformsService.getPlatforms();

    if (gamePlatforms && Array.isArray(gamePlatforms)) {
      let gameDomainFilter = {
        name: "gameDomain",
        label: labelPrefix + "gameDomain",
        type: CRITERIA_TYPE_SELECT,
        onChange: (event) => {
          cb(event);
        },
        placeholder: "label.placeholders.multiselect",
        options: gamePlatforms
          .filter((it) => it.value !== "ALL")
          .map((gamePlatform) => {
            return {
              label: gamePlatform.value,
              value: gamePlatform.key,
            };
          }),
      };

      filter.push(gameDomainFilter);
    }

    let gameCategoriesFilter = {
      disabled: true,
      name: "gameCategories",
      label: labelPrefix + "gameCategories",
      type: CRITERIA_TYPE_SELECT,
      placeholder: "label.placeholders.multiselect",
      options: [],
    };

    filter.push(gameCategoriesFilter);

    const filterKey = new Date().getTime();

    return {
      filter,
      filterKey,
    };
  };

  /*getGamePlatforms = async () => {
    const response = await gamePlatformsService.getPlatforms();
    if (response) {
      return response;
    }
  };*/

  getListData = async (brandId, parnterId, searchTerms) => {
    const url = "/gov/api/rest/v2/player-ban";
    const terms = searchUtils.sanitizeTerms(searchTerms);

    const response = await axios.get(url, {
      params: {
        brand: brandId,
        partner: parnterId,
        active: true,
        ...terms,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response campaign managememt list");
  };

  getCooldown = async (brandId, parnterId) => {
    const response = await axios.get(
      `/gov/api/rest/v2/player-ban/cooldown?brand=${brandId}&partner=${parnterId}`
    );

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response during get cooldown period");
  };

  addBan = async (body, banMode) => {
    let response = [];
    let url = "";

    if (banMode !== "1") {
      url = "/gov/api/rest/v2/player-ban";
      response = await axios.post(url, body);
    } else {
      url = "/gov/api/rest/v2/player-ban/massive-ban";

      let dataForm = new FormData();
      dataForm.set("banTypologyDtos", JSON.stringify(body.banTypologyDtos));
      dataForm.append("banExpirationDate", body.banExpirationDate);
      dataForm.append("reason", body.reason);
      dataForm.append("comment", body.comment);
      dataForm.append("eventType", body.eventType);
      dataForm.append("isBannedByBO", body.isBannedByBO);
      dataForm.append("banType", body.banType);
      dataForm.append("brandId", body.brandId);
      dataForm.append("partnerId", body.partnerId);
      dataForm.append("fileName", body.fileName);

      response = await axios({
        method: "post",
        url: url,
        data: dataForm,
        headers: { "Content-Type": "multipart/form-data" },
      });
    }

    if (response && response.status === 204) {
      return response;
    }
    throw Error("Invalid response for add Ban");
  };

  removeBan = async (brandId, partnerId, banId, contractId) => {
    const url = `/gov/api/rest/v2/player-ban?brand=${brandId}&partner=${partnerId}&contractId=${contractId}&banId=${banId}`;
    const response = await axios.delete(url);
    if (!response || response.status !== 204) {
      throw Error("Error during ban deletation");
    }
  };

  saveCooldown = async (brandId, partnerId, data) => {
    const url = `/gov/api/rest/v2/player-ban/cooldown?brandId=${brandId}&partnerId=${partnerId}`;
    data.cooldownTime = data.cooldownTime * 60;
    const response = await axios({
      method: "PUT",
      data: data,
      url: url,
    });
    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response during set cooldown period");
  };
}

const networkBansService = new NetworkBansService();
export default networkBansService;
