import React from "react";
import Table from "../../../../Layouts/Table/Table";
import {
  CardContent,
  CardHeader,
  Card,
  CardActions,
  Box,
} from "@material-ui/core";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import { useSession } from "../../../../utilities/hooks/useSession";
import { useClubCardHistory } from "../../../../utilities/hooks/useClubCard";
import { useClubCardActions } from "../../../../utilities/hooks/useClubCardActions";
import { useRouteMatch } from "react-router-dom";
import { useClubCardDetails } from "../../../../utilities/hooks/useClubCardDetails";
import Translate from "../../../../Layouts/Translator/Translate";
import Button from "@material-ui/core/Button";
import Print from "@material-ui/icons/Print";
import Email from "@material-ui/icons/Email";
import Delete from "@material-ui/icons/Delete";
import TabContent from "../../../../Layouts/tabs/tab-content";
import Modal from "../../../../Layouts/Modal/Modal";
import Tab from "@material-ui/core/Tab";
import Tabs from "../../../../Layouts/tabs/tabs";
import Grid from "@material-ui/core/Grid";
import PrintCardForm from "./print-card-form";
import RegenerateCardForm from "./regenerate-card-form";
import { useDispatch } from "react-redux";
import { emailCard } from "../../../../store/players/individual-player/club-card/actions";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import DisableEnableCardForm from "./disableEnableCard";
import { CARD_PROXY_ID, PRINT_REQUEST_DATE } from "../../../../store/constants";

import moment from "moment";
import Action from "../../../../Layouts/action/action";

function ClubCard({ widget }) {
  const { brandSelected, partnerSelected, printDaysAllowed } = useSession();
  const [printModal, setPrintModal] = React.useState(null);
  const [regenerateModal, setRegenerateModal] = React.useState(null);
  const [disableEnableModal, setDisableEnableModal] = React.useState(null);
  const match = useRouteMatch();
  const contractId = match.params.id;
  const { data, columns, loading = true } = useClubCardHistory(
    brandSelected,
    partnerSelected,
    contractId
  );
  const [tab, setTab] = React.useState(0);
  const {
    dataDetails,
    fields,
    isloading = true,
    widgetColumns,
  } = useClubCardDetails(brandSelected, partnerSelected, contractId);
  const { actionsData, actionsColumns } = useClubCardActions(
    brandSelected,
    partnerSelected,
    contractId
  );
  let isReprint = dataDetails
    ? dataDetails.printRequestDate
      ? true
      : false
    : false;
  const t = useTranslator();
  const dispatch = useDispatch();
  const [sendClubCardOpen, setSendClubCardOpen] = React.useState(false);

  const handleSend = () => {
    dispatch(
      emailCard(
        dataDetails[CARD_PROXY_ID],
        contractId,
        handleClose,
        brandSelected.id,
        partnerSelected.id
      )
    );
  };

  const handleClose = () => {
    setSendClubCardOpen(false);
  };

  let possibleToPrint = false;
  if (
    dataDetails != null &&
    printDaysAllowed != null &&
    dataDetails &&
    dataDetails[PRINT_REQUEST_DATE] != null
  ) {
    possibleToPrint =
      moment().subtract(printDaysAllowed, "days").valueOf() >=
      dataDetails[PRINT_REQUEST_DATE];
  }

  return (
    <>
      {!widget ? (
        <>
          {dataDetails ? (
            <Box display="flex" flex={1} justifyContent="flex-end" mb={2}>
              <Box ml={1}>
                <Action
                  color={"primary"}
                  label={"button.regenerate"}
                  permission={"regenerate-card"}
                  onClick={(event, rowData) => {
                    setRegenerateModal(dataDetails[CARD_PROXY_ID]);
                  }}
                  disabled={
                    dataDetails == null || dataDetails["status"] !== "2"
                  }
                />
              </Box>
              <Box ml={1}>
                {dataDetails != null && dataDetails["status"] === "3" ? (
                  <Action
                    color={"success"}
                    label={"button.enable"}
                    permission={"enable-disable-card"}
                    onClick={(event, rowData) => {
                      setDisableEnableModal("Card_Enable");
                    }}
                  />
                ) : (
                  <Action
                    startIcon={<Delete />}
                    color={"failure"}
                    label={"button.disableCard"}
                    permission={"enable-disable-card"}
                    onClick={(event, rowData) => {
                      setDisableEnableModal("Card_Disable");
                    }}
                    disabled={
                         dataDetails == null || dataDetails["status"] !== "2"
                    }
                  />
                )}
              </Box>
            </Box>
          ) : null}
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader
                title={
                  <Translate needle={"label.player.clubCardDetails.title"} />
                }
              />
              <CardContent>
                <ListPanel
                  data={dataDetails}
                  loading={isloading}
                  fields={fields(printDaysAllowed)}
                  maxHeight={360}
                />
              </CardContent>
              {dataDetails ? (
                <CardActions>
                  <Box display="flex" flex={1} justifyContent="flex-end" mb={1}>
                    <Box mr={2}>
                      <Action
                        startIcon={<Print />}
                        color={"primary"}
                        label={"button.print"}
                        permission={"print-card"}
                        disabled={
                          dataDetails == null ||
                          dataDetails["status"] !== "2" ||
                          (dataDetails[PRINT_REQUEST_DATE] && !possibleToPrint)
                        }
                        onClick={(event, rowData) => {
                          setPrintModal(dataDetails[CARD_PROXY_ID]);
                        }}
                      />
                    </Box>
                    <Box mr={2}>
                      <Action
                        startIcon={<Email />}
                        color={"primary"}
                        label={"button.email"}
                        permission={"send-virtual-card"}
                        onClick={() => {
                          setSendClubCardOpen(true);
                        }}
                        disabled={
                          dataDetails == null || dataDetails.status !== "2"
                        }
                      />
                    </Box>
                    {/* <Box mr={2}>
                  <Button color={"primary"}>
                    {<Translate needle={"button.upgrade"}/>}
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button color={"primary"}>
                    {<Translate needle={"button.downgrade"}/>}
                  </Button>
                </Box> */}
                  </Box>
                </CardActions>
              ) : null}
            </Card>
          </Grid>
          <Box mt={3} mb={2}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => {
                setTab(newValue);
              }}
            >
              <Tab label={"Card History"} value={0} />
              <Tab
                label={t({ needle: "label.player.clubCardActions.title" })}
                value={1}
              />
            </Tabs>
          </Box>
          <TabContent index={0} value={tab}>
            <Table
              pageSize={10}
              type={"advanced"}
              resize={true}
              pagination={true}
              exportButton={false}
              title={"label.player.clubCardHistory.tableTitle"}
              columns={columns}
              data={data}
              isLoading={loading}
            />
          </TabContent>
          <TabContent index={1} value={tab}>
            <Table
              pageSize={10}
              type={"advanced"}
              resize={false}
              pagination={true}
              exportButton={false}
              title={"label.player.clubCardActions.title"}
              columns={actionsColumns}
              data={actionsData}
              isLoading={loading}
            />
          </TabContent>
          <Modal
            open={Boolean(printModal)}
            title={
              <Translate needle={"label.player.clubCardDetails.printCard"} />
            }
            content={
              <PrintCardForm
                handleCloseModal={() => setPrintModal(null)}
                idCard={printModal}
                contractId={contractId}
                action={isReprint ? "Card_Reprint" : "Card_Print"}
              />
            }
          />
          <Modal
            open={Boolean(regenerateModal)}
            title={
              <Translate
                needle={"label.player.clubCardDetails.regenerate.title"}
              />
            }
            content={
              <RegenerateCardForm
                handleCloseModal={() => setRegenerateModal(null)}
                idCard={regenerateModal}
                contractId={contractId}
                status={dataDetails && dataDetails.status}
              />
            }
          />
          <Modal
            open={sendClubCardOpen}
            title={"label.player.clubCardSendEmail.title"}
            description={t({
              needle: "label.player.clubCardSendEmail.description",
              variables: [
                dataDetails && dataDetails[CARD_PROXY_ID],
                contractId,
              ],
            })}
            actions={[
              {
                label: "button.cancel",
                handler: handleClose,
                variant: "outlined",
              },
              {
                label: "button.send",
                handler: handleSend,
                color: "primary",
              },
            ]}
          />
          <Modal
            open={Boolean(disableEnableModal)}
            title={
              disableEnableModal === "Card_Enable" ? (
                <Translate
                  needle={"label.player.clubCardDetails.enable.title"}
                />
              ) : (
                <Translate
                  needle={"label.player.clubCardDetails.disable.title"}
                />
              )
            }
            content={
              <DisableEnableCardForm
                handleCloseModal={() => setDisableEnableModal(null)}
                idCard={dataDetails && dataDetails[CARD_PROXY_ID]}
                contractId={contractId}
                action={disableEnableModal}
                serviceId={dataDetails && dataDetails["serviceId"]}
              />
            }
          />
        </>
      ) : (
        <Box>
          <ListPanel
            key="0"
            data={dataDetails}
            loading={loading}
            editable={false}
            fields={widgetColumns}
          />
        </Box>
      )}
    </>
  );
}

export default ClubCard;
