import { SENDMAIL_ACTION, SENDMAIL_RESET } from "../constants";

const INITIAL_STATE = {
  result: undefined,
};

const SendMailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SENDMAIL_ACTION:
      return {
        ...state,
        result: action.payload,
      };
      case SENDMAIL_RESET:
      return {
        ...state,
        result: undefined,
      };

    default:
      return {
        ...state,
      };
  }
};

export default SendMailReducer;
