import React, { Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Moment from "react-moment";
import { connect } from "react-redux";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    fontSize: "14px",
  },
  listItem: {
    "&:nth-child(even)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemText: {
    width: "50%",
    color: theme.palette.text.primary2,
    "&.text > .MuiTypography-root": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.value > .MuiTypography-root": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));
const UserProfile = ({ user }) => {
  const t = useTranslator();
  const classes = useStyle();
  return (
    <Fragment>
      <List className={classes.root}>
        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.username.base", label: "UserName" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.username !== null ? user.username : "-"}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.firstName.base", label: "First Name" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.firstName !== null ? user.firstName : "-"}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.middleName", label: "Middle Name" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.middleName !== null ? user.middleName : "-"}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.lastName.base", label: "Last Name" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.lastName !== null ? user.lastName : "-"}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.email.base", label: "Email" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.email !== null ? user.email : "-"}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.language", label: "Language" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.language.languageName !== null
              ? user.language.languageName
              : "-"}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.user.lockedout", label: "Locked" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.locked
              ? t({ needle: "label.yes", label: "Yes" })
              : t({ needle: "label.no", label: "No" })}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.user.dtLastLogin", label: "Last Login" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.lastLogin !== null ? (
              <Moment format="L LT">{user.lastLogin}</Moment>
            ) : (
              "-"
            )}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.user.dtCreated", label: "Creation Date" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.creationDate !== null ? (
              <Moment format="L LT">{user.creationDate}</Moment>
            ) : (
              "-"
            )}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({
              needle: "label.user.dtLastLockedout",
              label: "Locked Date",
            })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.lockedDate !== null ? (
              <Moment format="L LT">{user.lockedDate}</Moment>
            ) : (
              "-"
            )}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({
              needle: "label.user.dtPasswordCreated",
              label: "Passwod Creation Date",
            })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.passwordCreation !== null ? (
              <Moment format="L LT">{user.passwordCreation}</Moment>
            ) : (
              "-"
            )}
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={clsx(classes.itemText, "text")}>
            {t({ needle: "label.user.role", label: "User Role" })}
          </ListItemText>
          <ListItemText className={clsx(classes.itemText, "value")}>
            {user.role.name !== null ? user.role.name : "-"}
          </ListItemText>
        </ListItem>
      </List>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.AuthenticationReducer.session;
  return { user };
};

export default connect(mapStateToProps)(UserProfile);
