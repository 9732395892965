import React, { useEffect } from "react";
import { connect } from "react-redux";
import NoDataToShow from "../../../../utilities/no-data-to-show";
import { LOYALTY_MULTIPLIER } from "./loyalty-details";
import Table from "../../../../Layouts/Table/Table";
import { getLoyaltyDetails } from "../../../../store/players/individual-player/loyalty-details/actions";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { useRouteMatch } from "react-router-dom";


const LoyaltyDetailsWidget = ({ playerInfo, loading, loyaltyDetails, getLoyaltyDetails  }) => {
  const t = useTranslator();
  const match = useRouteMatch();
  const contractId = match.params.id;


  useEffect(()=>{
    if(getLoyaltyDetails && contractId){
      getLoyaltyDetails(contractId)
    }
  },[getLoyaltyDetails,contractId])
  
  const columns = [
    {
      field:"levelGroupName",
      title:t({ needle: "label.individualplayer.loyaltyDetails.domain"}),
    },{
        field:"loyaltyPoint",
        title:t({ needle: "label.individualplayer.loyaltyDetails.loyaltyBalance"}),
        align: "right",
        render: (rowData) => (
          rowData.loyaltyPoint > 0 ? rowData.loyaltyPoint / LOYALTY_MULTIPLIER : rowData.loyaltyPoint
        ),
      },{
        field:"levelDescription",
        title:t({ needle: "label.individualplayer.loyaltyDetails.currentLevel"}),
        align: "center",
      },{
        field:"periodPoint",
      titleTooltip: t({ needle: "label.individualplayer.loyaltyDetails.levelPointsDescription"}),
      title:t({ needle: "label.individualplayer.loyaltyDetails.levelPoints"}),
      render: (rowData) => (
        rowData.periodPoint > 0 ? rowData.periodPoint / LOYALTY_MULTIPLIER : rowData.periodPoint
      ),
      align: "right",
    },
]


  return loyaltyDetails && loyaltyDetails[0] && loyaltyDetails[0].levelGroups && loyaltyDetails[0].levelGroups.length>0 ? <Table
  isLoading={loading}
  type={ "simple"}
  pagination={loyaltyDetails[0].levelGroups != null && loyaltyDetails[0].levelGroups.length > 5}
  columns={columns}
  resize={false}
  data={loyaltyDetails[0].levelGroups}
/> : <NoDataToShow/>
};

const mapStateToProps = (state) => {
    const {
      loyaltyDetails,
      loading
    } = state.LoyaltyDetailsReducer;
    return { loading, loyaltyDetails };
  };
  
  const mapDispatchToProps = {
    getLoyaltyDetails: getLoyaltyDetails,
  };

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyDetailsWidget);

