import { takeEvery } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  PLAYER_PREFERENCES_FAILURE,
  PLAYER_PREFERENCES_REQUEST,
  PLAYER_PREFERENCES_SUCCESS,
  UPDATE_PLAYER_PREFERENCES_FAILURE,
  UPDATE_PLAYER_PREFERENCES_REQUEST,
  UPDATE_PLAYER_PREFERENCES_SUCCESS,
} from "../../../constants";
import playerPreferencesService from "./player-preferences-service";

const loadPlayerPreferences = function* (action) {
  try {
    const data = yield call(
      playerPreferencesService.loadPlayerPreferences,
      action.playerId,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: PLAYER_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("playerPreferences", e);
    yield put({
      type: PLAYER_PREFERENCES_FAILURE,
    });
  }
};

const updatePlayerPreferences = function* (action) {
  try {
    const data = yield call(
      playerPreferencesService.updatePlayerPreferences,
      action.playerId,
      action.brandId,
      action.partnerId,
      action.body
    );
    yield put({
      type: UPDATE_PLAYER_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("playerPreferences", e);
    yield put({
      type: UPDATE_PLAYER_PREFERENCES_FAILURE,
    });
  }
};

const PlayerPreferencesSaga = function* playerPreferencesSaga() {
  yield takeEvery(PLAYER_PREFERENCES_REQUEST, loadPlayerPreferences);
  yield takeEvery(UPDATE_PLAYER_PREFERENCES_REQUEST, updatePlayerPreferences);
};

export default PlayerPreferencesSaga;
