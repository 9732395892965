import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getFinancialLimitsNotAssociatedProfilesHandler } from "../../store/players/individual-player/responsible-game/financial-limits/actions";

export const useFinancialLimitsNotAssociatedProfiles = (
  contractId,
  brand,
  partner
) => {
  const data = useSelector(
    (state) => state.FinancialLimitsReducer.notAssociatedProfileList
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getFinancialLimitsNotAssociatedProfilesHandler(contractId, brand, partner)
    );
  }, [dispatch, contractId, brand, partner]);

  return data.filter(i=>![5,6].includes(i?.profileDataTypeId));;
};
