import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
import { CRITERIA_TYPE_SELECT } from "../../../constants";

class TicketService {
  async getFilterFields(brand, partner) {
    const filters = [
      {
        name: "validityPeriod",
        label: "label.individualplayer.tickets.validityPeriod",
        type: "period",
        placeholder: "label.placeholders.date",
      },
      {
        name: "status",
        label: "label.individualplayer.tickets.status",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: [
          {
            label: "label.individualplayer.tickets.statusCreated",
            value: 0,
          },
          {
            label: "label.individualplayer.tickets.statusUsed",
            value: 1,
          },
          {
            label: "label.individualplayer.tickets.statusDepleted",
            value: 2,
          },
          {
            label: "label.individualplayer.tickets.statusDeleted",
            value: 3,
          },
        ],
      },
      {
        name: "tournamentName",
        label: "label.individualplayer.tickets.tournamentName",
      },
    ];

    return filters;
  }

  async getTickets(contractId, brand, partner, searchTerms) {
    const url = `/gov/api/rest/v1/contract/${contractId}/ticketTournament?brand=${brand}&partner=${partner}`;
    const queryParam = searchTerms
      ? searchUtils.getQueryParam(searchTerms, true, false)
      : "";

    const response = await axios.get(url + queryParam);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for free spin list");
  }

  async deleteTicket(contractId, ticketId) {
    const url = `/gov/api/rest/v1/contract/${contractId}/ticketTournament/${ticketId}`;

    const response = await axios.delete(url);
    if (response && response.status === 200) {
      return response.status;
    }
    throw Error("Invalid response for free spin list");
  }
}
const ticketService = new TicketService();
export default ticketService;
