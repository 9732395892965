import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";

class SelfExclusionService {
  async loadSelfExclusion(brand, partner, contractId) {
    let url = `/gov/api/rest/v3/ewl/selfexclusion/${contractId}`;

    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for load self exclusion");
  }

  async updateSelfExclusion(brand, partner, contractId, body) {
    let url = `/gov/api/rest/v3/ewl/selfexclusion/${contractId}`;

    const response = await axios.post(url, body, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && response.status !== 204) {
      throw Error("Invalid response for update self exclusion");
    }
  }

  async loadSelfExclusionHistory(brand, partner, contractId, query) {
    let url = `/gov/api/rest/v3/ewl/selfExclusionHistory`;
    let searchTerms = { CONTRACT_IDENTITY: contractId, LIMIT_TYPE: ["SELF_EXCLUSION", "4"] };
    let luceneQuery = searchUtils.getBasicLuceneQuery(searchTerms);
    const start = query.pageSize * query.page;
    const rows = query.pageSize;
    const brandId = brand;
    const partnerId = partner;
    const q = `(${luceneQuery})`;
    let sort = null;
    let sortMode = null;
    if (query.orderBy) {
      sort = query.orderBy.field;
      sortMode = query.orderBy.order === "desc" ? "desc" : "asc";
    }
    const body = {
      q,
      brandId,
      partnerId,
      rows,
      start,
      sort,
      sortMode,
    };

    const response = await axios.post(url, body);

    if (response && response.data && response.data.collection) {
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }

    throw Error("Invalid response for load self exclusion history");
  }
}

const selfExclusionService = new SelfExclusionService();
export default selfExclusionService;
