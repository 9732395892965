import { GO_TO_ERROR_PAGE } from "../constants";
import history from "../../history";
import { takeEvery } from "redux-saga/effects";

const redirect = function* () {
  yield history.replace("/404");
};

const ErrorPageSaga = function* errorPageSaga() {
  yield takeEvery(GO_TO_ERROR_PAGE, redirect);
};

export default ErrorPageSaga;
