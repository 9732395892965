import { call, put } from "redux-saga/effects";
import { takeLatest } from "@redux-saga/core/effects";
import playerFavoritesService from "./player-favorites-services";
import { enqueueSnackbar } from "../../../snackbars/actions";

import {
  PLAYER_FAVORITES_REQUEST,
  PLAYER_FAVORITES_SUCCESS,
  PLAYER_FAVORITES_FAILURE,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAILURE,
  LOCATIONS_LIST_REQUEST,
  LOCATIONS_LIST_SUCCESS,
  LOCATIONS_LIST_FAILURE,
  SAVE_FAVORITE_REQUEST,
  SAVE_FAVORITE_SUCCESS,
  SAVE_FAVORITE_FAILURE,
} from "../../../constants";
const getPlayerFavorites = function* (action) {
  try {
    const data = yield call(
      playerFavoritesService.getPlayerFavorites,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: PLAYER_FAVORITES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: PLAYER_FAVORITES_FAILURE,
    });
  }
};

const getCategoryList = function* (action) {
  try {
    const data = yield call(playerFavoritesService.getCategoryList);
    yield put({
      type: CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: CATEGORY_LIST_FAILURE,
    });
  }
};

const getLocationsList = function* (action) {
  try {
    action.category = action.category.value;
    const data = yield call(
      playerFavoritesService.getLocationsList,
      action.category
    );
    yield put({
      type: LOCATIONS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: LOCATIONS_LIST_FAILURE,
    });
  }
};

const saveFavorite = function* (action) {
  try {
    yield call(
      playerFavoritesService.saveFavorite,
      action.brand,
      action.partner,
      action.fields,
      action.contractId,
      action.actionType
    );
    yield put({
      type: SAVE_FAVORITE_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            action.actionType === "edit"
              ? "label.player.playerFavorites.edit_success"
              : "label.player.playerFavorites.add_success",
          variables: ["label.player.playerFavorites"],
        },
        options: {
          key: "playerFavorites",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({
      type: PLAYER_FAVORITES_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: SAVE_FAVORITE_FAILURE,
    });
  }
};

const PlayerFavoritesSaga = function* PlayerFavorites() {
  yield takeLatest(PLAYER_FAVORITES_REQUEST, getPlayerFavorites);
  yield takeLatest(CATEGORY_LIST_REQUEST, getCategoryList);
  yield takeLatest(LOCATIONS_LIST_REQUEST, getLocationsList);
  yield takeLatest(SAVE_FAVORITE_REQUEST, saveFavorite);
};

export default PlayerFavoritesSaga;
