import React from "react";
import { fieldByType } from "./index";

const FormField = ({ initialValue, hidden, type, ...rest }) => {
  const Component = fieldByType[type];

  return Component ? <Component {...rest} /> : null;
};

export default FormField;
