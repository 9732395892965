import {Box, Card, CardContent, CardHeader, ListItem, ListItemText, SvgIcon,} from "@material-ui/core";
import {Block} from "@material-ui/icons";
import React from "react";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {ReactComponent as AssociateIcon} from "../../../../Images/button_associate.svg";
import {ReactComponent as UnlinkIcon} from "../../../../Images/button_unlink.svg";
import {ReactComponent as UnlockIcon} from "../../../../Images/button_unlock.svg";
import Action from "../../../../Layouts/action/action";
import Modal from "../../../../Layouts/Modal/Modal";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import {useTranslator} from "../../../../utilities/hooks/useTranslator";
import PepChangeStatusModal from "../../pep/pep-change-status-modal";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";

const labelPrefix = "label.individualplayer.pepdetails.";

const PepDetails = ({playerInfo, widget}) => {
    const match = useRouteMatch();
    const contractId = match.params.id;
    const {fields} = useSelector((state) => state.PepReducer.pepDetails);
    const {loading} = useSelector((state) => state.PlayerDetailReducer);
    const t = useTranslator();
    var data = {};
    const [openPepModal, setOpenPepModal] = React.useState(null);

    if (playerInfo && playerInfo.playerServiceParametersGroups) {
        const groupsData = playerInfo.playerServiceParametersGroups.filter(
            (it) => it.type === 5700
        )[0];
        if (groupsData) {
            groupsData.attributes.forEach((attr) => {
                data[attr.attributeId] = attr.attributeValue;
            });
        }
    }

    const pepState = data["5700"] || null;

    const handleOpenPepModal = (data, pepState, modalProps) => {
        setOpenPepModal({
            data: {...data, pepState},
            title: t({
                needle: `${labelPrefix}changePepStatusModal`,
            }),
            description: t({
                needle: `${labelPrefix}changePepStatusDesc`,
                variables: [`${labelPrefix}${pepState}`],
            }),
            props: modalProps,
        });
    };

    const handleClosePepModal = () => {
        setOpenPepModal(null);
    };

    const getPepModal = () => {
        return (
            <Modal
                open={Boolean(openPepModal)}
                title={openPepModal?.title}
                description={openPepModal?.description || null}
                content={
                    openPepModal ? (
                        <PepChangeStatusModal
                            contractId={contractId}
                            modalData={openPepModal}
                            handleClosePepModal={handleClosePepModal}
                            isEditNote={openPepModal.isEditNote}
                        />
                    ) : null
                }
            />
        );
    };

    const handleOpenEditNoteModal = (data, pepState, modalProps) => {
        setOpenPepModal({
            data: {...data, pepState},
            title: t({
                needle: `${labelPrefix}editNoteModal`,
            }),
            props: modalProps,
            isEditNote: true,
        });
    };

    const getButtons = (data) => {
        const buttons = [
            <Action
                variant="contained"
                color="primary"
                startIcon={<SvgIcon component={AssociateIcon}/>}
                label={"button.enable"}
                onClick={(rowData) =>
                    handleOpenPepModal(rowData, "ENABLED", {
                        color: "success",
                        buttonLabel: "button.enable",
                    })
                }
                permission={"enable_pep_status"}
                detail={{pepState: pepState || null}}
                margins={{ml: 1}}
            />,
            <Action
                variant="contained"
                color="primary"
                startIcon={<SvgIcon component={UnlinkIcon}/>}
                label={"button.preAuthorize"}
                onClick={(rowData) =>
                    handleOpenPepModal(rowData, "PRE_AUTHORIZED", {
                        color: "success",
                        buttonLabel: "button.preAuthorize",
                    })
                }
                permission={"preAuth_pep_status"}
                detail={{pepState}}
                margins={{ml: 1}}
            />,
            <Action
                variant="contained"
                color="primary"
                startIcon={<SvgIcon component={UnlockIcon}/>}
                label={"button.authorize"}
                onClick={(rowData) =>
                    handleOpenPepModal(rowData, "AUTHORIZED", {
                        color: "success",
                        buttonLabel: "button.authorize",
                    })
                }
                permission={"auth_pep_status"}
                detail={{pepState}}
                margins={{ml: 1}}
            />,
            <Action
                variant="contained"
                color="primary"
                startIcon={<SvgIcon component={Block}/>}
                label={"button.disable"}
                onClick={(rowData) =>
                    handleOpenPepModal(rowData, "DISABLED", {
                        color: "failure",
                        buttonLabel: "button.disable",
                    })
                }
                permission={"disable_pep_status"}
                detail={{pepState}}
                margins={{ml: 1}}
            />,
            <Action
                variant="contained"
                color="primary"
                startIcon={<SvgIcon component={AssociateIcon}/>}
                label={"button.revoke"}
                onClick={(rowData) =>
                    handleOpenPepModal(rowData, "REJECTED", {
                        color: "failure",
                        buttonLabel: "button.revoke",
                    })
                }
                permission={"revoke_pep_status"}
                detail={{pepState: pepState || null}}
                margins={{ml: 1}}
            />,
        ];
        return !widget ? (
            <Box mt={2} display="flex" justifyContent="flex-end">
                {/*NOTE removed as part of POLAND-147*/}
                {/*{pepState != null && pepState !== "REJECTED" && (*/}
                {/*  <Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    startIcon={<SvgIcon component={AssociateIcon} />}*/}
                {/*    label={"button.addNote"}*/}
                {/*    onClick={(rowData) =>*/}
                {/*      handleOpenEditNoteModal(rowData, pepState, {*/}
                {/*        color: "primary",*/}
                {/*        buttonLabel: data["5709"]*/}
                {/*          ? "button.editNote"*/}
                {/*          : "button.addNote",*/}
                {/*      })*/}
                {/*    }*/}
                {/*    margins={{ ml: 1 }}*/}
                {/*  >*/}
                {/*    <Translate*/}
                {/*      needle={data["5709"] ? "button.editNote" : "button.addNote"}*/}
                {/*    />*/}
                {/*  </Button>*/}
                {/*)}*/}
                {buttons.map((it) => {
                    return it;
                })}
            </Box>
        ) : null;
    };

    let notes = [];
    if (data && data["5709"]) {
        notes = data["5709"].split(
            ","
        );
    }

    const CardComponent = ({children, title}) => (
        <Card>
            <CardHeader title={title}/>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    );

    const WrapperComponent = widget ? Box : CardComponent


    return pepState != null ? (
        <>
            <WrapperComponent title={t({needle: `${labelPrefix}title`})}>

                <ListPanel
                    loading={data.loading || loading}
                    data={data}
                    fields={fields}
                    getButtons={() => getButtons(data)}
                    hideEditButton
                    labelPrefix={labelPrefix}
                />
            </WrapperComponent>

            {notes && notes.length > 0 ? (
                <Box mt={widget ? 0 : 2}>
                    <WrapperComponent title={t({needle: `${labelPrefix}note`})}>
                        <List dense={!widget} subheader={widget ? (
                            <ListSubheader>{t({needle: `${labelPrefix}note`})}</ListSubheader>
                        ) : null}>
                            {notes.map((element, i) => (<ListItem disableGutters={!widget} key={i}><ListItemText primary={element}></ListItemText></ListItem>))}
                        </List>
                    </WrapperComponent>
                </Box>
            ) : null}
            {getPepModal()}
        </>
    ) : (
        <Box mr={1}>
            <ListPanel loading={data.loading} data={null} labelPrefix={labelPrefix}/>

            {getButtons(data)}
            {getPepModal()}
        </Box>
    );
};

export default PepDetails;
