import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";

class RafListService {
  async getRafList(brand, partner, terms) {
    const url = `/gov/api/rest/v2/raf/referFriends?brand=${brand}&partner=${partner}`;
    const queryParam = terms
      ? searchUtils.getQueryParam(terms, true, true)
      : "";
    const response = await axios.get(url + queryParam);

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for raf list");
  }
}

const rafListService = new RafListService();
export default rafListService;
