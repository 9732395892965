import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { SESSION_INFO_ACTION, SESSION_INFO_REQUEST } from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import { isMobileOnly } from "react-device-detect";
import { localeHandler } from "../locale/actions";

const getSession = async () => {
  const session = await axios.get("/gov/api/rest/v1/sessionInfo");
  return session.data;
};

const getControlVersion = async () => {
  const versions = await axios.get(process.env.PUBLIC_URL + "/versions.json");

  return versions.data;
};

const sessionInfo = function* (action) {
  let session = undefined;
  let controlVersion;
  try {
    if (action.payload.result) {
      session = yield call(getSession, action.payload);
      session.brandSelected = session.user.brands[0];
      session.partnerSelected = session.user.brands[0].partners[0];
      if (
        session.user.language != null &&
        session.user.language.languageCode != null
      ) {
        yield put(localeHandler(session.user.language.languageCode));
      }
      sessionStorage.setItem("session", JSON.stringify(session));
      sessionStorage.setItem(
        "nextTokenRefresh",
        new Date().getTime() + (session.accessTimeout - 2) * 60000
      );
      controlVersion = yield call(getControlVersion);
      sessionStorage.setItem("controlVersion", JSON.stringify(controlVersion));
    } else {
      sessionStorage.clear();
    }
    yield put({
      type: SESSION_INFO_ACTION,
      payload: {
        session,
        response: action.payload,
        controlVersion,
      },
    });
  } catch (e) {
    console.error("Error during sessionInfo request", e);
  }
  if (isMobileOnly) {
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.mobile_limited_feature.notification",
        },
        options: {
          variant: "warning",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      })
    );
  }
};

const Saga = function* loginSaga() {
  yield takeEvery(SESSION_INFO_REQUEST, sessionInfo);
};

export default Saga;
