import { call, put } from "redux-saga/effects";
import {
  LOYALTY_TRANSACTION_TYPES_SUCCESS,
  LOYALTY_TRANSACTION_TYPES_FAILURE,
  LOYALTY_TRANSACTION_TYPES_REQUEST,
} from "../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import loyaltyTransactionTypesService from "./loyalty-transaction-type-service";

const getTransactionTypes = function* (action) {
  try {
    const data = yield call(loyaltyTransactionTypesService.getTransactionTypes);
    yield put({
      type: LOYALTY_TRANSACTION_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyalty transaction types list ", e);
    yield put({
      type: LOYALTY_TRANSACTION_TYPES_FAILURE,
    });
  }
};

const LoyaltyTransactionTypesSaga = function* LoyaltyTransactionTypesSaga() {
  yield takeLatest(LOYALTY_TRANSACTION_TYPES_REQUEST, getTransactionTypes);
};

export default LoyaltyTransactionTypesSaga;
