import axios from "axios";

class SensitiveLimitConfigurationService {
  loadData = async (brandId, partnerId) => {
    const response = await axios.get(
      `/gov/api/rest/v1/sensitive-limit/details?brand=${brandId}&partner=${partnerId}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response sensitive limit configuration details");
  };

  editLimit = async (brandId, partnerId, amount) => {
    const response = await axios.put(
      `/gov/api/rest/v1/sensitive-limit/change-amount?brand=${brandId}&partner=${partnerId}&amount=${amount}`
    );
    if (response && response.status === 200) {
      return response.data;
    }
    throw Error("Invalid response sensitive limit cedit amount");
  };

  enableDisableLimit = async (brandId, partnerId, enable) => {
    const response = await axios.put(
      `/gov/api/rest/v1/sensitive-limit/enable-disable-limit?brand=${brandId}&partner=${partnerId}&enable=${enable}`
    );
    if (response && response.status === 200) {
      return response.data;
    }
    throw Error("Invalid response sensitive limit cedit amount");
  };
}

const sensitiveLimitConfigurationService = new SensitiveLimitConfigurationService();
export default sensitiveLimitConfigurationService;
