import {
  TICKETS_SEARCH_CRITERIA_SUCCESS,
  TICKETS_SEARCH_CRITERIA_FAILURE,
  TICKETS_LIST_SUCCESS,
  TICKETS_LIST_FAILURE,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import Currency from "../../../../Layouts/Currency/Currency";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";

const getStatusMapping = (status) => {
  let badgeMapped = {};
  switch (status) {
    case 0:
      badgeMapped.badgeId = "1";
      badgeMapped.badgeLabel = "created";
      break;
    case 1:
      badgeMapped.badgeId = "2";
      badgeMapped.badgeLabel = "used";
      break;
    case 2:
      badgeMapped.badgeId = "6";
      badgeMapped.badgeLabel = "depleted";
      break;
    case 3:
      badgeMapped.badgeId = "5";
      badgeMapped.badgeLabel = "deleted";
      break;
    default:
  }
  return badgeMapped;
};

const initialState = {
  ticketList: {
    idKey: "ticketId",
    searchCriteria: [],
    columns: [
      {
        field: "ticketId",
        title: "label.individualplayer.tickets.ticketId",
      },
      {
        field: "status",
        title: "label.individualplayer.tickets.status",
        render: (rowData) => {
          if (rowData["status"] != null) {
            const statusMapped = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapped.badgeId}
                label={statusMapped.badgeLabel}
                justify={"center"}
              />
            );
          }
        },
      },
      {
        field: "name",
        title: "label.individualplayer.tickets.name",
        default: true,
      },
      {
        field: "description",
        title: "label.individualplayer.tickets.description",
      },
      {
        field: "amount",
        title: "label.individualplayer.tickets.amount",
        render: (rowData) => {
          return (
            <Currency
              amount={rowData["amount"]}
              currency={rowData["currency"]}
              justify="flex-end"
            />
          );
        },
      },
      {
        field: "currency",
        title: "label.individualplayer.tickets.currency",
        render: (rowData) => {
          if (rowData["currency"]) {
            return (
              <CurrencyBadge currency={rowData["currency"]} justify="center" />
            );
          }
        },
      },
    ],
    data: [],
    loading: true,
  },
  ticketDetail: {
    fields: (playerCurrency) => {
      return [
        {
          field: "ticketId",
          title: "label.individualplayer.tickets.ticketId",
        },
        {
          field: "status",
          title: "label.individualplayer.tickets.status",
          render: (rowData) => {
            if (rowData["status"] != null) {
              const statusMapped = getStatusMapping(rowData["status"]);
              return (
                <BadgeStatus
                  id={statusMapped.badgeId}
                  label={statusMapped.badgeLabel}
                  justify={"center"}
                />
              );
            }
          },
        },
        {
          field: "residualValue",
          title: "label.individualplayer.tickets.residualValue",
          render: (rowData) => {
            return (
              <Currency
                amount={rowData["residualValue"]}
                currency={playerCurrency}
              />
            );
          },
        },
        {
          field: "granularity",
          title: "label.individualplayer.tickets.granularity",
        },
        {
          field: "player.contractId",
          title: "label.individualplayer.tickets.contractId",
        },
        {
          field: "name",
          title: "label.individualplayer.tickets.name",
        },
        {
          field: "description",
          title: "label.individualplayer.tickets.description",
        },
        {
          field: "amount",
          title: "label.individualplayer.tickets.amount",
          render: (rowData) => {
            return (
              <Currency amount={rowData["amount"]} currency={playerCurrency} />
            );
          },
        },
        {
          field: "currency",
          title: "label.individualplayer.tickets.currency",
          render: (rowData) => {
            if (rowData["currency"]) {
              return (
                <CurrencyBadge
                  currency={rowData["currency"]}
                  justify="center"
                />
              );
            }
          },
        },
        {
          field: "validityStartDate",
          title: "label.individualplayer.tickets.validityStartDate",
          render: (rowData) => {
            if (rowData["validityStartDate"]) {
              return (
                <Moment format="L LT">{rowData["validityStartDate"]}</Moment>
              );
            } else {
              return null;
            }
          },
        },
        {
          field: "validityEndDate",
          title: "label.individualplayer.tickets.validityEndDate",
          render: (rowData) => {
            if (rowData["validityEndDate"]) {
              return (
                <Moment format="L LT">{rowData["validityEndDate"]}</Moment>
              );
            } else {
              return null;
            }
          },
        },
        {
          field: "changeDate",
          title: "label.individualplayer.tickets.changeDate",
          render: (rowData) => {
            if (rowData["changeDate"]) {
              return <Moment format="L LT">{rowData["changeDate"]}</Moment>;
            } else {
              return null;
            }
          },
        },
        {
          field: "residualGranularity",
          title: "label.individualplayer.tickets.residualGranularity",
        },
        {
          field: "divisibleFlag",
          title: "label.individualplayer.tickets.divisibleFlag",
        },
        {
          field: "exactValueFlag",
          title: "label.individualplayer.tickets.exactValueFlag",
        },
        {
          field: "multiplayerSessionName",
          title: "label.individualplayer.tickets.multiplayerSessionName",
        },
        {
          field: "sitAndGoFlag",
          title: "label.individualplayer.tickets.sitAndGoFlag",
        },
        {
          field: "scheduledFlag",
          title: "label.individualplayer.tickets.scheduledFlag",
        },
        {
          field: "chargeTo",
          title: "label.individualplayer.tickets.chargeTo",
        },
        {
          field: "gameCodeList",
          title: "label.individualplayer.tickets.gameDomains",
          render: (rowData) => {
            if (rowData["gameCodeList"]) {
              return rowData["gameCodeList"]
                .reduce((accumulator, key) => {
                  accumulator.push(key.gameDomainDescription);
                  return accumulator;
                }, [])
                .join(", ");
            }
          },
        },
        {
          field: "gameTypeList",
          title: "label.individualplayer.tickets.gameList",
          render: (rowData) => {
            if (rowData["gameTypeList"]) {
              return rowData["gameTypeList"]
                .reduce((accumulator, key) => {
                  accumulator.push(key.gameDescription);
                  return accumulator;
                }, [])
                .join(", ");
            }
          },
        },
      ];
    },
    columnsTab2: [
      {
        field: "externalPlatformTournamentIdString",
        title: "label.individualplayer.tickets.tournamentId",
        default: true,
      },
      {
        field: "tournamentBrandId",
        title: "label.individualplayer.tickets.brand",
      },
      {
        field: "tournamentPartnerId",
        title: "label.individualplayer.tickets.partner",
      },
    ],
    columnsTab3: (playerCurrency) => {
      return [
        {
          field: "idTransactionType",
          title: "label.individualplayer.tickets.transactionType",
          default: true,
          render: (rowData) => {
            let label = `label.individualplayer.tickets.transactionTypeId.${rowData["idTransactionType"]}`;
            return <Translate needle={label} />;
          },
        },
        {
          field: "residualValue",
          title: "label.individualplayer.tickets.residualValue",
          render: (rowData) => {
            return (
              <Currency
                amount={rowData["residualValue"]}
                currency={playerCurrency}
                justify="flex-end"
              />
            );
          },
        },
        {
          field: "residualGranularity",
          title: "label.individualplayer.tickets.residualGranularity",
        },
        {
          field: "amount",
          title: "label.individualplayer.tickets.amount",
          render: (rowData) => {
            return (
              <Currency
                amount={rowData["amount"]}
                currency={playerCurrency}
                justify="flex-end"
              />
            );
          },
        },
        {
          field: "currency",
          title: "label.individualplayer.tickets.currency",
          render: (rowData) => {
            if (rowData["currency"]) {
              return (
                <CurrencyBadge
                  currency={rowData["currency"]}
                  justify="center"
                />
              );
            }
          },
        },
        {
          field: "granularityUsed",
          title: "label.individualplayer.tickets.granularityUsed",
        },
        {
          field: "gameDomain",
          title: "label.individualplayer.tickets.gameDomainId",
        },
        {
          field: "gameType",
          title: "label.individualplayer.tickets.gameId",
        },
        {
          field: "gnsInstanceId",
          title: "label.individualplayer.tickets.gnsInstanceId",
        },
        {
          field: "externalSystemId",
          title: "label.individualplayer.tickets.externalSystemId",
        },
        {
          field: "externalTransactionId",
          title: "label.individualplayer.tickets.externalTransactionId",
        },
        {
          field: "transactionDate",
          title: "label.individualplayer.tickets.transactionDate",
          render: (rowData) => {
            if (rowData["transactionDate"]) {
              return (
                <Moment format="L LT">{rowData["transactionDate"]}</Moment>
              );
            } else {
              return null;
            }
          },
        },
      ];
    },
  },
};

const TicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TICKETS_SEARCH_CRITERIA_SUCCESS: {
      return {
        ...state,
        ticketList: { ...state.ticketList, searchCriteria: action.payload },
      };
    }

    case TICKETS_SEARCH_CRITERIA_FAILURE: {
      return {
        ...state,
        ticketList: { ...state.ticketList, searchCriteria: [] },
      };
    }

    case TICKETS_LIST_SUCCESS: {
      return {
        ...state,
        ticketList: {
          ...state.ticketList,
          data: action.payload,
          loading: false,
        },
      };
    }

    case TICKETS_LIST_FAILURE: {
      return {
        ...state,
        ticketList: { ...state.ticketList, data: null, loading: false },
      };
    }

    default:
      return state;
  }
};

export default TicketsReducer;
