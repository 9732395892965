import React, { Component } from "react";
import { connect } from "react-redux";
import Translate from "../Translator/Translate";
import {
  externalUrlRefreshKeyHandler,
  updateBrandPartnerHandler,
} from "../../store/authentication/actions";
// Import Stylesheet(s)
import { Box, Popover, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import providersService from "../../utilities/providers";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "../Modal/Modal";

const ProviderSelectionPopover = ({ open, setOpen }) => {
  const classes = useStyle();
  return (
    <Popover
      classes={{ paper: classes.paper }}
      anchorEl={open}
      open={Boolean(open)}
      keepMounted
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      getContentAnchorEl={null}
      onClose={() => setOpen(null)}
    >
      <Box p={2}>
        <ConnectedProviderSelection setOpen={() => setOpen(null)} />
      </Box>
    </Popover>
  );
};

const ProviderSelectionModal = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      title={"label.edit_brand_partner"}
      content={
        <ConnectedProviderSelection
          setOpen={() => setOpen(false)}
          showCancel={true}
        />
      }
    />
  );
};

class ProviderSelection extends Component {
  constructor(props) {
    super(props);
    const { selectedBrand, selectedPartner } = this.getBrandAndPartner();
    this.state = {
      isChanged: true,
      selectedBrand,
      selectedPartner,
    };
  }

  getBrandAndPartner = () => {
    const selectedBrand = providersService.getBrandById(
      this.props.session.user.brands,
      this.props.session.brandSelected.id
    );

    const selectedPartner = providersService.getPartnerById(
      selectedBrand.partners,
      this.props.session.partnerSelected.id
    );
    return { selectedBrand, selectedPartner };
  };

  componentDidMount() {
    const { selectedBrand, selectedPartner } = this.getBrandAndPartner();
    this.setState({
      selectedBrand,
      selectedPartner,
    });
  }

  drawBrands() {
    return this.props.session.user.brands.map((opt) => {
      return (
        <MenuItem key={`${opt.id}`} value={`${opt.id}`}>
          {opt.name}
        </MenuItem>
      );
    });
  }

  drawPartners() {
    return this.state.selectedBrand.partners.map((opt) => {
      return (
        <MenuItem key={`${opt.id}`} value={`${opt.id}`}>
          {opt.name}
        </MenuItem>
      );
    });
  }

  changeBrandHandler = (event) => {
    let selectedValue = providersService.getBrandById(
      this.props.session.user.brands,
      parseInt(event.target.value)
    );

    let isChanged =
      this.props.session.brandSelected.id !== this.state.selectedBrand.id;

    this.setState({
      selectedBrand: selectedValue,
      selectedPartner: selectedValue.partners[0],
      isChanged: isChanged,
    });
  };

  changePartnerHandler = (event) => {
    let isChanged =
      this.props.session.partnerSelected.id !== this.state.selectedPartner.id;

    this.setState({
      selectedPartner: providersService.getPartnerById(
        this.state.selectedBrand.partners,
        parseInt(event.target.value)
      ),
      isChanged: isChanged,
    });
  };

  saveForm = (event) => {
    event.preventDefault();

    if (
      this.props.session.partnerSelected.id !== this.state.selectedPartner.id
    ) {
      this.props.updateBrandPartnerHandler(
        this.state.selectedBrand.id,
        this.state.selectedPartner.id
      );
      this.props.externalUrlRefreshKeyHandler();
    }

    this.setState({ isChanged: true });
    this.props.setOpen && this.props.setOpen();
  };

  render() {
    const { showCancel, setOpen } = this.props;
    return (
      <>
        <TextField
          fullWidth={true}
          label={<Translate needle={"label.settingsBrand"} />}
          select
          onChange={this.changeBrandHandler}
          value={this.state.selectedBrand.id}
        >
          {this.drawBrands()}
        </TextField>

        <TextField
          fullWidth={true}
          label={<Translate needle={"label.settingsPartner"} />}
          select
          onChange={this.changePartnerHandler}
          value={this.state.selectedPartner.id}
        >
          {this.drawPartners()}
        </TextField>
        <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
          {showCancel && (
            <Box mr={1}>
              <Button variant={"outlined"} onClick={setOpen}>
                <Translate needle="button.cancel" />
              </Button>
            </Box>
          )}
          <Button
            color={"primary"}
            onClick={this.saveForm}
            disabled={this.state.isChanged}
          >
            <Translate needle="label.saveChanges" label="Save Changes" />
          </Button>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.MainReducer;
  const { session } = state.AuthenticationReducer;

  return { locale, session };
};

const mapDispatchToProps = {
  updateBrandPartnerHandler,
  externalUrlRefreshKeyHandler,
};
const useStyle = makeStyles((theme) => ({
  paper: {
    width: theme.spacing(40),
  },
}));
const ConnectedProviderSelection = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderSelection);

export { ProviderSelectionPopover, ProviderSelectionModal };
