import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  ADD_BULK_REQUEST_FAILURE,
  ADD_BULK_REQUEST_REQUEST,
  ADD_BULK_REQUEST_SUCCESS,
  BULK_REQUESTS_FILTERS_FAILURE,
  BULK_REQUESTS_FILTERS_REQUEST,
  BULK_REQUESTS_FILTERS_SUCCESS,
  BULK_REQUESTS_LIST_FAILURE,
  BULK_REQUESTS_LIST_RELOAD,
  BULK_REQUESTS_LIST_REQUEST,
  BULK_REQUESTS_LIST_SUCCESS,
  BULK_REQUEST_TASK_TYPES_FAILURE,
  BULK_REQUEST_TASK_TYPES_REQUEST,
  BULK_REQUEST_TASK_TYPES_SUCCESS,
  BULK_REQUEST_TRANSACTIONS_FAILURE,
  BULK_REQUEST_TRANSACTIONS_REQUEST,
  BULK_REQUEST_TRANSACTIONS_SUCCESS,
} from "../../../constants";
import bulkRequestsService from "./bulk-requests-service";
import { enqueueSnackbar } from "../../../snackbars/actions";
import { labelPrefix } from "./bulk-requests-reducer";

const loadListFilter = function* (action) {
  try {
    const data = yield call(
      bulkRequestsService.getListFilter,
      action.brand.id,
      action.partner.id
    );
    yield put({
      type: BULK_REQUESTS_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("closing accounts filters", e);
    yield put({
      type: BULK_REQUESTS_FILTERS_FAILURE,
    });
  }
};

const loadBulkRequests = function* (action) {
  try {
    let dateSearchTerms = null;
    if (action.searchTerms != null) {
      dateSearchTerms = {
        startDateFrom: action.searchTerms.startDate?.from
          ? action.searchTerms.startDate.from
          : "",
        startDateTo: action.searchTerms.startDate?.to
          ? action.searchTerms.startDate.to
          : "",
        endDateFrom: action.searchTerms.endDate?.from
          ? action.searchTerms.endDate.from
          : "",
        endDateTo: action.searchTerms.endDate?.to
          ? action.searchTerms.endDate.to
          : "",
      };
      delete action.searchTerms.startDate;
      delete action.searchTerms.endDate;
    }
    const finalSearchTerms = { ...action.searchTerms, ...dateSearchTerms };
    const data = yield call(
      bulkRequestsService.loadBulkRequests,
      action.query,
      finalSearchTerms,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: BULK_REQUESTS_LIST_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("bulk requests load", e);
    yield put({
      type: BULK_REQUESTS_LIST_FAILURE,
    });
  }
};

const getTaskTypes = function* (action) {
  try {
    const data = yield call(
      bulkRequestsService.getTaskTypes,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: BULK_REQUEST_TASK_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("taskTypes", e);
    yield put({
      type: BULK_REQUEST_TASK_TYPES_FAILURE,
    });
  }
};

const reloadBulkRequests = function* (action) {
  const lastRequest = yield select(
    (state) => state.BulkRequestsReducer.bulkRequestsList.lastRequest
  );
  yield put(lastRequest);
};

const addNewBulkRequest = function* (action) {
  try {
    const data = yield call(
      bulkRequestsService.addNewBulkRequest,
      action.brand.id,
      action.partner.id,
      action.body
    );
    yield put({
      type: ADD_BULK_REQUEST_SUCCESS,
      payload: data,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "insertSuccess",
        },
        options: {
          key: "bulkRequestAdd",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    action.cb && typeof action.cb === "function" && action.cb();
    yield put({
      type: BULK_REQUESTS_LIST_RELOAD,
    });
  } catch (e) {
    console.error("amountTypes", e);
    yield put({
      type: ADD_BULK_REQUEST_FAILURE,
    });
  }
};

const loadBulkRequestsTransactionsById = function* (action) {
  try {
    const data = yield call(
      bulkRequestsService.loadBulkRequestsTransactionsById,
      action.id,
      action.brandId,
      action.partnerId,
      action.query
    );
    yield put({
      type: BULK_REQUEST_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadSelfExclusion", e);
    yield put({
      type: BULK_REQUEST_TRANSACTIONS_FAILURE,
    });
  }
};

const BulkRequestSaga = function* BulkRequestSaga() {
  yield takeLatest(BULK_REQUESTS_LIST_REQUEST, loadBulkRequests);
  yield takeLatest(BULK_REQUESTS_FILTERS_REQUEST, loadListFilter);
  yield takeLatest(ADD_BULK_REQUEST_REQUEST, addNewBulkRequest);
  yield takeLatest(BULK_REQUEST_TASK_TYPES_REQUEST, getTaskTypes);
  yield takeLatest(
    BULK_REQUEST_TRANSACTIONS_REQUEST,
    loadBulkRequestsTransactionsById
  );
  yield takeLatest(BULK_REQUESTS_LIST_RELOAD, reloadBulkRequests);
};

export default BulkRequestSaga;
