import { getLocationsList } from "../../store/locations/action";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
export const useLocations = (page, pageSize, terms, add) => {
  const { data, columns, loading } = useSelector(
    (state) => state.LocationsReducer.location
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getLocationsList(page, pageSize, terms, add));
  }, [dispatch, page, pageSize, terms, add]);
  return { data, columns, loading };
};
