import { Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FieldArray, useField } from "formik";
import React from "react";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { FormField } from "./index";
import { isFieldDisabled } from "./utils";

const useStyle = makeStyles((theme) => ({
  arrayFieldContainer: {
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
    "& + &": {
      "& .MuiFormLabel-root": {
        display: "none",
      },
    },
  },
}));

const GroupField = ({ name, items, values, ...props }) => {
  const classes = useStyle();

  return (
    <FieldArray name={name}>
      <Box display={"flex"} className={classes.arrayFieldContainer}>
        {items.map((it, index) => {
          return (
            <Box
              display={"block"}
              key={`${name}.${it.name}`}
              mt={!it.label ? "6px" : 0}
            >
              <FormField
                {...it}
                disabled={isFieldDisabled(it, values, index)}
                name={`${name}.${it.name}`}
              />
            </Box>
          );
        })}
      </Box>
    </FieldArray>
  );
};
export default GroupField;
