import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getIndividualPlayerLoginHistoryHandler } from "../../store/players/individual-player/login-history/actions";

export const useIndividualPlayerLoginHistory = () => {
  const { data, columns, fields, loading } = useSelector(
    (state) => state.LoginHistoryReducer.loginHistoryList
  );
  const dispatch = useDispatch();

  const loadData = (contractId, brand, partner, page, pageSize) => {
    dispatch(
      getIndividualPlayerLoginHistoryHandler(contractId, brand, partner, page, pageSize)
    );
  };

  return { data, columns, fields, loading,  loadData };
};
