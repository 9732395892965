import { call, put } from "redux-saga/effects";
import {
  KYC_STATUS_REQUEST,
  KYC_STATUS_SUCCESS,
  KYC_STATUS_FAILURE,
  KYC_STATUS_HISTORY_SUCCESS,
  KYC_STATUS_HISTORY_REQUEST,
  KYC_STATUS_HISTORY_FAILURE,
  KYC_STATUS_REQUEST_SUCCESS,
  KYC_STATUS_REQUEST_REQUEST,
  KYC_STATUS_REQUEST_FAILURE,
  KYC_EDIT_NOTE_SUCCESS,
  KYC_EDIT_NOTE_FAILURE,
  KYC_CHANGE_STATE_SUCCESS,
  KYC_CHANGE_STATE_FAILURE,
  KYC_EDIT_NOTE_REQUEST,
  KYC_CHANGE_STATE_REQUEST,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import kycStatusService from "./kyc-status-service";

const loadKycStatus = function* (action) {
  try {
    let data = [];
    if (action.registrationLevelId) {
      data = yield call(
        kycStatusService.loadKYCStatus,
        action.brand,
        action.partner,
        action.contractId,
        action.registrationLevelId
      );
    }
    yield put({
      type: KYC_STATUS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadKYCStatus", e);
    yield put({
      type: KYC_STATUS_FAILURE,
    });
  }
};

const loadKycStatusHistory = function* (action) {
  try {
    const dataList = yield call(
      kycStatusService.loadKYCStatusHistory,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: KYC_STATUS_HISTORY_SUCCESS,
      payload: dataList,
    });
  } catch (e) {
    console.error("loadKYCStatusHistory", e);
    yield put({
      type: KYC_STATUS_HISTORY_FAILURE,
    });
  }
};

const loadKycRequestDetail = function* (action) {
  try {
    const dataRequest = yield call(
      kycStatusService.loadKycRequestDetail,
      action.brand,
      action.partner,
      action.identityCheckRequestId
    );
    yield put({
      type: KYC_STATUS_REQUEST_SUCCESS,
      payload: dataRequest,
    });
  } catch (e) {
    console.error("loadKycRequestDetail", e);
    yield put({
      type: KYC_STATUS_REQUEST_FAILURE,
    });
  }
};

const editKycNote = function* (action) {
  try {
    yield call(
      kycStatusService.editKycNote,
      action.brand,
      action.partner,
      action.statusObject
    );
    yield put({
      type: KYC_EDIT_NOTE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.kyc.editSuccess",
        },
        options: {
          key: "KYCNoteSave",
          variant: "success",
        },
      })
    );
    yield put({
      type: KYC_STATUS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
      registrationLevelId: action.registrationLevelId,
    });
    yield put({
      type: KYC_STATUS_HISTORY_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: KYC_EDIT_NOTE_FAILURE,
    });
  }
};

const changeKycStatus = function* (action) {
  try {
    yield call(
      kycStatusService.changeKycStatus,
      action.brand,
      action.partner,
      action.contractId,
      action.statusObject
    );
    yield put({
      type: KYC_CHANGE_STATE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: `label.player.kyc.changeStatus_${action.statusObject.action}`,
        },
        options: {
          key: "KYCChangeStatus",
          variant: "success",
        },
      })
    );
    yield put({
      type: KYC_STATUS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
      registrationLevelId: action.registrationLevelId,
    });
    yield put({
      type: KYC_STATUS_HISTORY_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: KYC_CHANGE_STATE_FAILURE,
    });
  }
};

const KYCStatusSaga = function* kycStatusSaga() {
  yield takeLatest(KYC_STATUS_REQUEST, loadKycStatus);
  yield takeLatest(KYC_STATUS_HISTORY_REQUEST, loadKycStatusHistory);
  yield takeLatest(KYC_STATUS_REQUEST_REQUEST, loadKycRequestDetail);
  yield takeLatest(KYC_EDIT_NOTE_REQUEST, editKycNote);
  yield takeLatest(KYC_CHANGE_STATE_REQUEST, changeKycStatus);
};

export default KYCStatusSaga;
