import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import {
  PROGRAM_BONUS_REQUEST,
  PROGRAM_FAILURE,
  PROGRAM_REQUEST,
  PROGRAM_SCALE_REQUEST,
  PROGRAM_SUCCESS,
  SCHEDULED_PROGRAM_FAILURE,
  SCHEDULED_PROGRAM_REQUEST,
  SCHEDULED_PROGRAM_SUCCESS,
  PROGRAM_SCALE_SUCCESS,
  PROGRAM_SCALE_FAILURE,
  PROGRAM_BONUS_SUCCESS,
  PROGRAM_BONUS_FAILURE,
  LOY_REWARD_TYPES_REQUEST,
  LOY_CREDIT_TYPE_REQUEST,
  LOY_REWARD_TYPES_SUCCESS,
  LOY_REWARD_TYPES_FAILURE,
  LOY_CREDIT_TYPE_SUCCESS,
  LOY_CREDIT_TYPE_FAILURE,
  SAVE_NEW_LOY_BONUS_SUCCESS,
  SAVE_NEW_LOY_BONUS_FAILURE,
  SAVE_NEW_LOY_BONUS_REQUEST,
  REMOVE_LEVEL_BONUS_SUCCESS,
  REMOVE_LEVEL_BONUS_FAILURE,
  REMOVE_LEVEL_BONUS_REQUEST,
  BONUS_LEVELS_SUCCESS,
  BONUS_LEVELS_FAILURE,
  BONUS_LEVELS_REQUEST,
  SAVE_BONUS_LEVEL_REQUEST,
  SAVE_BONUS_LEVEL_FAILURE,
  SAVE_BONUS_LEVEL_SUCCESS,
  DELETE_BONUS_FAILURE,
  DELETE_BONUS_SUCCESS,
  DELETE_BONUS_REQUEST,
} from "../../../constants";
import programService from "./program-service";

const getScheduledProgram = function* (action) {
  try {
    const data = yield call(
      programService.getScheduledProgram,
      action.brand,
      action.partner
    );

    yield put({
      type: SCHEDULED_PROGRAM_SUCCESS,
      payload: data[0],
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: SCHEDULED_PROGRAM_FAILURE,
    });
  }
};

const getLoyProgram = function* (action) {
  try {
    const data = yield call(programService.getLoyProgram, action.programId);

    yield put({
      type: PROGRAM_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);

    yield put({
      type: PROGRAM_FAILURE,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "errors.generic_error",
          variables: ["label.rewards.loyalty.program.context"],
        },
        options: {
          key: "program_error",
          variant: "error",
          autoHideDuration: 8000,
        },
      })
    );
  }
};

const getBonus = function* (action) {
  try {
    const data = yield call(
      programService.getBonus,
      action.programId,
      action.gameDomainId
    );

    yield put({
      type: PROGRAM_BONUS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: PROGRAM_BONUS_FAILURE,
    });
  }
};

const getLevelScale = function* (action) {
  try {
    const data = yield call(
      programService.getLevelScale,
      action.programId,
      action.gameDomainId
    );

    yield put({
      type: PROGRAM_SCALE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: PROGRAM_SCALE_FAILURE,
    });
  }
};

const getLoyRewardTypes = function* (action) {
  try {
    const data = yield call(
      programService.getLoyRewardTypes,
      action.brand,
      action.partner
    );

    yield put({
      type: LOY_REWARD_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: LOY_REWARD_TYPES_FAILURE,
    });
  }
};

const getLoyCreditType = function* (action) {
  try {
    const data = yield call(
      programService.getLoyCreditType,
      action.brand,
      action.partner,
      action.rewardType
    );

    yield put({
      type: LOY_CREDIT_TYPE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: LOY_CREDIT_TYPE_FAILURE,
    });
  }
};

const saveNewBonus = function* (action) {
  try {
    yield call(programService.saveNewBonus, action.body, action.isEdit);

    yield put({
      type: SAVE_NEW_LOY_BONUS_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: action.isEdit
            ? "label.rewards.loyalty.program.successEditBonus"
            : "label.rewards.loyalty.program.successAddBonus",
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: PROGRAM_BONUS_REQUEST,
      programId: action.programId,
      gameDomainId: action.gameDomainId,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: SAVE_NEW_LOY_BONUS_FAILURE,
    });
  }
};

const removeLevel = function* (action) {
  try {
    yield call(programService.removeLevel, action.levelId, action.bonusId);

    yield put({
      type: REMOVE_LEVEL_BONUS_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.rewards.loyalty.program.successRemoveLevel",
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: PROGRAM_BONUS_REQUEST,
      programId: action.programId,
      gameDomainId: action.gameDomainId,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: REMOVE_LEVEL_BONUS_FAILURE,
    });
  }
};

const getLevels = function* (action) {
  try {
    const data = yield call(
      programService.getLevels,
      action.programId,
      action.gameDomainId
    );

    yield put({
      type: BONUS_LEVELS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: BONUS_LEVELS_FAILURE,
    });
  }
};

const saveAssociatedLevel = function* (action) {
  try {
    yield call(
      programService.saveAssociatedLevel,
      action.body,
      action.bonusId,
      action.programId,
      action.gameDomainId
    );

    yield put({
      type: SAVE_BONUS_LEVEL_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.rewards.loyalty.program.successAssociateLevel",
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: PROGRAM_BONUS_REQUEST,
      programId: action.programId,
      gameDomainId: action.gameDomainId,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: SAVE_BONUS_LEVEL_FAILURE,
    });
  }
};

const deleteBonus = function* (action) {
  try {
    yield call(
      programService.deleteBonus,
      action.bonusId,
      action.programId,
      action.gameDomainId
    );

    yield put({
      type: DELETE_BONUS_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.rewards.loyalty.program.successDeleteBonus",
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: PROGRAM_BONUS_REQUEST,
      programId: action.programId,
      gameDomainId: action.gameDomainId,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: DELETE_BONUS_FAILURE,
    });
  }
};

const ProgramSaga = function* () {
  yield takeLatest(SCHEDULED_PROGRAM_REQUEST, getScheduledProgram);
  yield takeLatest(PROGRAM_REQUEST, getLoyProgram);
  yield takeLatest(PROGRAM_BONUS_REQUEST, getBonus);
  yield takeLatest(PROGRAM_SCALE_REQUEST, getLevelScale);
  yield takeLatest(LOY_REWARD_TYPES_REQUEST, getLoyRewardTypes);
  yield takeLatest(LOY_CREDIT_TYPE_REQUEST, getLoyCreditType);
  yield takeLatest(SAVE_NEW_LOY_BONUS_REQUEST, saveNewBonus);
  yield takeLatest(REMOVE_LEVEL_BONUS_REQUEST, removeLevel);
  yield takeLatest(BONUS_LEVELS_REQUEST, getLevels);
  yield takeLatest(SAVE_BONUS_LEVEL_REQUEST, saveAssociatedLevel);
  yield takeLatest(DELETE_BONUS_REQUEST, deleteBonus);
};

export default ProgramSaga;
