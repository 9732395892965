import { call, put, takeEvery } from "redux-saga/effects";
import { SENDMAIL_ACTION, SENDMAIL_REQUEST } from "../constants";

import axios from "axios";

const mailSender = async ({ username }) => {
  let result;

  try {
    // eslint-disable-next-line
    const response = await axios.get(
      "/gov/api/rest/sendMail?username=" + username
    );
    result = response.data;
  } catch (error) {
    result = { email: null };
  }
  return result;
};

const sendMail = function* (action) {
  const data = yield call(mailSender, action.payload);
  yield put({
    type: SENDMAIL_ACTION,
    payload: data,
  });
};

const SendMailSaga = function* SendMailSaga() {
  yield takeEvery(SENDMAIL_REQUEST, sendMail);
};

export default SendMailSaga;
