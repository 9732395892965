import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getClubCardHistory } from "../../store/players/individual-player/club-card/actions";

export const useClubCardHistory = (brand, partner, contractId) => {
  const { data, columns, loading } = useSelector(
    (state) => state.ClubCardReducer.clubCardHistory
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getClubCardHistory(brand, partner, contractId));
  }, [dispatch, brand, partner, contractId]);

  return { data, columns, loading };
};
