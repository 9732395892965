import { select, takeLatest } from "@redux-saga/core/effects";
import {
  PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_FAILURE,
  PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_REQUEST,
  PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_SUCCESS,
  PLAYER_CONTACTS_VERIFICATION_FILTERS_FAILURE,
  PLAYER_CONTACTS_VERIFICATION_FILTERS_REQUEST,
  PLAYER_CONTACTS_VERIFICATION_FILTERS_SUCCESS,
  PLAYER_CONTACTS_VERIFICATION_LIST_FAILURE,
  PLAYER_CONTACTS_VERIFICATION_LIST_RELOAD,
  PLAYER_CONTACTS_VERIFICATION_LIST_REQUEST,
  PLAYER_CONTACTS_VERIFICATION_LIST_SUCCESS,
} from "../../../constants";
import { call, put } from "redux-saga/effects";
import playerContactsVerificationService from "./player-contacts-verification-service";
import { enqueueSnackbar } from "../../../snackbars/actions";
import { labelPrefix } from "./player-contacts-verification-reducer";

const loadListFilter = function* (action) {
  try {
    const data = yield call(playerContactsVerificationService.getListFilter,
      action.brand,
      action.partner);
    yield put({
      type: PLAYER_CONTACTS_VERIFICATION_FILTERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin player contacts verification filters error", e);
    yield put({
      type: PLAYER_CONTACTS_VERIFICATION_FILTERS_FAILURE,
    });
  }
};

const loadData = function* (action) {
  try {
    const data = yield call(
      playerContactsVerificationService.loadData,
      action.brand.id,
      action.partner.id,
      action.searchTerms
    );
    yield put({
      type: PLAYER_CONTACTS_VERIFICATION_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin player contacts verification list error", e);
    yield put({
      type: PLAYER_CONTACTS_VERIFICATION_LIST_FAILURE,
    });
  }
};

const changeVerification = function* (action) {
  try {
    yield call(
      playerContactsVerificationService.changeVerification,
      action.brand.id,
      action.partner.id,
      action.contactType,
      action.regLevel,
      action.verification,
      action.loginCheck
    );
    yield put({
      type: PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_SUCCESS,
    });

    var verficationMessage = "";

     
    if(action.verification == true && action.loginCheck == false) {        
      verficationMessage = labelPrefix + "verificationSuccessandlogincheckDisabled";
    } 
    else if (action.loginCheck == true && action.verification == true)  {
      verficationMessage = labelPrefix + "verificationSuccess";
    } 
    else if(action.verification == false && action.loginCheck == false) {
      verficationMessage = labelPrefix + "verificationDisabled";
    }  

    yield put(
      enqueueSnackbar({
        message: {
          needle: verficationMessage,
        },
        options: {
          key: "sendPin",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({ type: PLAYER_CONTACTS_VERIFICATION_LIST_RELOAD });
  } catch (e) {
    console.error("admin player contacts verification error", e);
    yield put({
      type: PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_FAILURE,
    });
  }
};

const reloadList = function* () {
  const lastRequest = yield select(
    (state) =>
      state.PlayerContactsVerificationReducer.playerContactsVerification
        .lastRequest
  );
  yield put(lastRequest);
};

const PlayerContactsVerificationsSaga = function* PlayerContactsVerificationsSaga() {
  yield takeLatest(
    PLAYER_CONTACTS_VERIFICATION_FILTERS_REQUEST,
    loadListFilter
  );
  yield takeLatest(PLAYER_CONTACTS_VERIFICATION_LIST_REQUEST, loadData);
  yield takeLatest(
    PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_REQUEST,
    changeVerification
  );
  yield takeLatest(PLAYER_CONTACTS_VERIFICATION_LIST_RELOAD, reloadList);
};

export default PlayerContactsVerificationsSaga;
