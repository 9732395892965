const palette = {
  bg: {
    page: "#EAEAEA",
    primary1: "#FEFEFF",
    primary2: "#0052BD",
    primary2Accent1: "#B2C7D2",
    primary2Accent2: "#D3D9E0",
    primary2Accent3: "#E2E7ED",
    secondary1: "#120974",
    secondary2: "#AD6500",
    secondary2Accent1: "#AD65001A",
    secondary2Accent2: "#9E5400",
    iconsLabels: "#2B2B31",
    success: "#016506",
    failure: "#8E0000",
    separatorsTags: "#6A6A71",
  },
  text: {
    primary1: "#000000",
    primary2: "#000000",
    secondary: "#FFFFFF",
    secondary2: "#FFFFFF",
    textInfo1: "#0B4D99",
    textInfo2: "#505E17",
  },
};

const lightHCPalette = {
  statusColors: {
    1: palette.bg.secondary1,
    2: palette.bg.success,
    3: palette.bg.secondary2,
    4: palette.bg.failure,
    5: "#ED2E2E",
    6: "#464A4D",
    7: palette.bg.failure,
    8: "#9F9F9F",
    9: palette.bg.failure,
    10: palette.bg.secondary1,
    11: palette.bg.success,
    12: palette.bg.secondary2,
    13: "#E3D800",
    14: "#800080",
    15: "#ED2E2E",
    16: "#82c884",
  },
  //ATTENTION, TEMPORARY COLORS DUE TO A BUG IN CHART LIBRARY WE USE SAME COLORS REGARDLESS THE THEME
  chartColors: [
    "#0C51A1",
    "#1B9DDB",
    "#FF671F",
    "#FFA300",
    "#4DAF4F",
    "#954DAF",
    "#086533",
    "#FF0000",
    "#2DAABA",
    "#DEB400",
    "#5AD3FF",
    "#912D2D",
  ],
  // chartColors: [
  //   palette.bg.secondary1,
  //   palette.bg.primary2,
  //   palette.bg.secondary2Accent2,
  //   palette.bg.secondary2,
  //   palette.bg.success,
  //   "#954DAF",
  //   "#086533",
  //   "#DB0000",
  //   "#207983",
  //   "#856C00",
  //   "#007BA8",
  //   "#912D2D",
  // ],
  envColors: {
    DEV: {
      background: palette.bg.secondary2,
      color: palette.text.secondary2,
    },
    QA: {
      background: palette.bg.secondary2Accent2,
      color: palette.text.secondary,
    },
    SIT: {
      background: palette.bg.primary2,
      color: palette.text.secondary2,
    },
    CAT: {
      background: palette.bg.secondary1,
      color: palette.text.secondary,
    },
    UAT: {
      background: palette.bg.secondary1,
      color: palette.text.secondary,
    },
    DEMO: {
      background: palette.bg.success,
      color: palette.text.secondary,
    },
    default: {
      background: palette.bg.iconsLabels,
      color: palette.text.secondary,
    },
  },
  shadows: ["rgba(0,0,0,0.2)", "rgba(0,0,0,0.12)", "rgba(0,0,0,0.14)"],
  shadow: "#122541",
  border: {
    arrow: "#666",
    input: "#6A6A71",
  },
  primary: {
    main: palette.bg.primary2,
    light: "#7986cb",
    dark: "#303f9f",
    contrastText: "#fff",
  },
  primary2: {
    main: "#fff",
  },
  secondary: {
    main: palette.bg.secondary1,
    light: "rgba(12, 81, 161, 0.15)",
  },
  secondary2: {
    main: palette.bg.secondary2,
    light: palette.bg.secondary2Accent1,
    dark: palette.bg.secondary2Accent2,
  },
  buttons: {
    primary: palette.bg.primary2,
    primaryFocused: "#004AAA",
    secondary: palette.bg.secondary1,
    secondaryFocused: "#100868",
    outlined: palette.bg.secondary1,
    outlinedFocused: "#1008681A",
    success: palette.bg.success,
    successFocused: "#015B05",
    failure: palette.bg.failure,
    failureFocused: "#800000",
  },
  success: {
    main: palette.bg.success,
  },
  error: {
    main: palette.bg.failure,
  },
  warning: {
    main: palette.bg.secondary2,
  },
  info: {
    main: palette.bg.primary2,
  },
  text: {
    primary: palette.text.primary1,
    primary2: palette.text.primary2,
    icon: palette.bg.iconsLabels,
    warning: "#C64343",
    highlight: palette.bg.secondary2,
    secondary: "rgb(0, 0, 0)",
    secondary1: palette.text.secondary,
    secondary2: palette.text.secondary2,
    secondary3: palette.text.secondary,
    paletteSecondary2: palette.bg.secondary1,
    disabled: palette.text.primary1,
    hint: "rgba(0, 0, 0, 0.38)",
    textInfo1: palette.text.textInfo1,
    textInfo2: palette.text.textInfo2,
  },
  background: {
    paper: palette.bg.primary1,
    default: palette.bg.page,
    primary2Accent1: palette.bg.primary2Accent1,
    primary2Accent2: palette.bg.primary2Accent2,
    primary2Accent3: palette.bg.primary2Accent3,
  },
  backgroundClickable: {
    paper: palette.bg.page,
  },
  svgIcon: {
    primary: palette.bg.primary2,
    typeString: "#7B9023",
    typeValue: "#407CB1",
  },
  action: {
    skeleton: "#E1E9EE",
    placeholder: "#000000CC",
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    hoverOpacity: 0.1,
    sortActive: "#C1C9D1",
    selected: "rgba(0, 0, 0, 0.08)",
    clickableSelected: "rgba(255, 163, 0, 0.15)",
    clickableSelectedColor: "#FFA300",
    selectedOpacity: 0.08,
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "#D3D3D3",
    disabledOpacity: 0.38,
    focus: "rgba(0, 0, 0, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  divider: palette.bg.separatorsTags,
};

export default lightHCPalette;
