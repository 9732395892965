import {
  SELF_EXCLUSION_REQUEST,
  UPDATE_SELF_EXCLUSION_REQUEST,
  SELF_EXCLUSION_HISTORY_REQUEST,
} from "../../../constants";

export const loadSelfExclusion = (brand, partner, contractId) => {
  return {
    type: SELF_EXCLUSION_REQUEST,
    contractId,
    brand,
    partner,
  };
};

export const updateSelfExclusion = (brand, partner, contractId, body) => {
  return {
    type: UPDATE_SELF_EXCLUSION_REQUEST,
    contractId,
    brand,
    partner,
    body,
  };
};

export const loadSelfExclusionHistory = (brand, partner, contractId, query) => {
  return {
    type: SELF_EXCLUSION_HISTORY_REQUEST,
    contractId,
    brand,
    partner,
    query,
  };
};
