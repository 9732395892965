import React from "react";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import Typography from "@material-ui/core/Typography";

const ErrorPage = (props) => {
  const t = useTranslator();
  return <Typography>{t({ needle: "label.errorPageMessage" })}</Typography>;
};

export default ErrorPage;
