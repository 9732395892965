import React, { Component } from "react";

// Import Stylesheet(s)
import "./styles/UpgradeBrowser.scss";

// Import Icon(s)
class UpgradeBrowser extends Component {
  componentDidMount() {
    document.title = "IGT Control - Outdated Browser";
  }
  render() {
    const logo = `${process.env.REACT_APP_IMAGEPATH}${process.env.REACT_APP_LOGO}`;
    return (
      <div className="Login">
        <div className="Modal">
          <div className="Logo">
            <img src={logo} alt={process.env.REACT_APP_TITLE} width={220} />
          </div>
          <p className="text-center">
            Oops! Please upgrade your browser, or use either Chrome, Firefox,
            Safari.
          </p>
        </div>
      </div>
    );
  }
}

export default UpgradeBrowser;
