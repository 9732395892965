import { select, takeLatest } from "@redux-saga/core/effects";
import {
  SENSITIVE_VIP_PLAYER_DETAILS_FAILURE,
  SENSITIVE_VIP_PLAYER_DETAILS_RELOAD,
  SENSITIVE_VIP_PLAYER_DETAILS_REQUEST,
  SENSITIVE_VIP_PLAYER_DETAILS_SUCCESS,
  SENSITIVE_VIP_PLAYER_HISTORY_FAILURE,
  SENSITIVE_VIP_PLAYER_HISTORY_RELOAD,
  SENSITIVE_VIP_PLAYER_HISTORY_REQUEST,
  SENSITIVE_VIP_PLAYER_HISTORY_SUCCESS,
  SENSITIVE_VIP_PLAYER_MARK_UNMARK_FAILURE,
  SENSITIVE_VIP_PLAYER_MARK_UNMARK_REQUEST,
  SENSITIVE_VIP_PLAYER_MARK_UNMARK_SUCCESS,
} from "../../../constants";
import { call, put } from "redux-saga/effects";
import sensitiveVipManagementService from "./sensitive-vip-management-service";

const loadDetails = function* (action) {
  try {
    const data = yield call(
      sensitiveVipManagementService.loadDetails,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: SENSITIVE_VIP_PLAYER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadSensitiveVipManagementDetails", e);
    yield put({
      type: SENSITIVE_VIP_PLAYER_DETAILS_FAILURE,
    });
  }
};

const loadHistory = function* (action) {
  try {
    const data = yield call(
      sensitiveVipManagementService.loadHistory,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: SENSITIVE_VIP_PLAYER_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadSensitiveVipManagementHistory", e);
    yield put({
      type: SENSITIVE_VIP_PLAYER_HISTORY_FAILURE,
    });
  }
};

const markUnmark = function* (action) {
  try {
    const data = yield call(
      sensitiveVipManagementService.markUnmark,
      action.brand,
      action.partner,
      action.contractId,
      action.detail
    );
    yield put({
      type: SENSITIVE_VIP_PLAYER_MARK_UNMARK_SUCCESS,
      payload: data,
    });
    yield put({ type: SENSITIVE_VIP_PLAYER_HISTORY_RELOAD });
    yield put({ type: SENSITIVE_VIP_PLAYER_DETAILS_RELOAD });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("loadSensitiveVipManagementHistory", e);
    yield put({
      type: SENSITIVE_VIP_PLAYER_MARK_UNMARK_FAILURE,
    });
  }
};

const reloadHistory = function* () {
  const lastRequestHistory = yield select(
    (state) => state.SensitiveVipManagementReducer.historyList.lastRequest
  );
  yield put(lastRequestHistory);
};

const reloadDetails = function* () {
  const lastRequest = yield select(
    (state) => state.SensitiveVipManagementReducer.detailPanel.lastRequest
  );
  yield put(lastRequest);
};

const SensitiveVipManagementSaga = function* sensitiveVipManagementSaga() {
  yield takeLatest(SENSITIVE_VIP_PLAYER_DETAILS_REQUEST, loadDetails);
  yield takeLatest(SENSITIVE_VIP_PLAYER_HISTORY_REQUEST, loadHistory);
  yield takeLatest(SENSITIVE_VIP_PLAYER_MARK_UNMARK_REQUEST, markUnmark);
  yield takeLatest(SENSITIVE_VIP_PLAYER_HISTORY_RELOAD, reloadHistory);
  yield takeLatest(SENSITIVE_VIP_PLAYER_DETAILS_RELOAD, reloadDetails);
};

export default SensitiveVipManagementSaga;
