import { select, takeLatest } from "@redux-saga/core/effects";
import {
  SENSITIVE_LIMIT_CONFIGURATION_DETAILS_FAILURE,
  SENSITIVE_LIMIT_CONFIGURATION_DETAILS_RELOAD,
  SENSITIVE_LIMIT_CONFIGURATION_DETAILS_REQUEST,
  SENSITIVE_LIMIT_CONFIGURATION_DETAILS_SUCCESS,
  SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_FAILURE,
  SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_REQUEST,
  SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_SUCCESS,
  SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_FAILURE,
  SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_REQUEST,
  SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_SUCCESS,
} from "../../../constants";
import { call, put } from "redux-saga/effects";
import sensitiveLimitConfigurationService from "./sensitive-limit-configuration-service";

const loadData = function* (action) {
  try {
    const data = yield call(
      sensitiveLimitConfigurationService.loadData,
      action.brand.id,
      action.partner.id
    );
    yield put({
      type: SENSITIVE_LIMIT_CONFIGURATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin get sensitive limit details error ", e);
    yield put({
      type: SENSITIVE_LIMIT_CONFIGURATION_DETAILS_FAILURE,
    });
  }
};

const editLimit = function* (action) {
  try {
    const data = yield call(
      sensitiveLimitConfigurationService.editLimit,
      action.brand.id,
      action.partner.id,
      action.amount
    );
    yield put({
      type: SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_SUCCESS,
      payload: data,
    });
    yield put({ type: SENSITIVE_LIMIT_CONFIGURATION_DETAILS_RELOAD });
    action.cb != null && typeof action.cb === "function" && action.cb(false);
  } catch (e) {
    console.error("admin get sensitive limit details error ", e);
    yield put({
      type: SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_FAILURE,
    });
  }
};

const enableDisableLimit = function* (action) {
  try {
    const data = yield call(
      sensitiveLimitConfigurationService.enableDisableLimit,
      action.brand.id,
      action.partner.id,
      action.enable
    );
    if (!action.enable) {
      yield put({
        type: SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_SUCCESS,
        payload: data,
      });
      yield put({ type: SENSITIVE_LIMIT_CONFIGURATION_DETAILS_RELOAD });
      action.cb != null && typeof action.cb === "function" && action.cb(false);
    } else {
      yield put({
        type: SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_REQUEST,
        brand: action.brand,
        partner: action.partner,
        amount: action.amount,
        cb: action.cb,
      });
    }
  } catch (e) {
    console.error("admin get sensitive limit details error ", e);
    yield put({
      type: SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_FAILURE,
    });
  }
};

const reloadData = function* () {
  const lastRequestHistory = yield select(
    (state) =>
      state.SensitiveLimitConfigurationReducer.sensitiveLimitConfiguration
        .lastRequest
  );
  yield put(lastRequestHistory);
};

const SensitiveLimitConfigurationSaga = function* SensitiveLimitConfigurationSaga() {
  yield takeLatest(SENSITIVE_LIMIT_CONFIGURATION_DETAILS_REQUEST, loadData);
  yield takeLatest(SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_REQUEST, editLimit);
  yield takeLatest(
    SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_REQUEST,
    enableDisableLimit
  );
  yield takeLatest(SENSITIVE_LIMIT_CONFIGURATION_DETAILS_RELOAD, reloadData);
};

export default SensitiveLimitConfigurationSaga;
