import axios from "axios";

class LongRunningTaskService {
  async loadTaskList(contractId, brand, partner) {
    const response = await axios.get(
      `/gov/api/rest/v1/lrt/getPlayerTasks/${contractId}?brand=${brand}&partner=${partner}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for expiring bonus");
  }

  async addTask(taskId, contractId, brand, partner, username) {
    const response = await axios.post("/gov/api/rest/v2/lrt/longRunningTask", {
      brandId: brand.id,
      contractId,
      partnerId: partner.id,
      taskTypeId: taskId,
      createdBy: `BO User: ${username}`,
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for add player in a group");
  }

  async removeTask(taskId, brandId, partnerId) {
    const response = await axios.put(
      `/gov/api/rest/v1/lrt/cancelLongRunningTask/${taskId}?brand=${brandId}&partner=${partnerId}`
    );

    if (response && response.status === 204) {
      return response.data;
    }

    throw Error("Invalid response for add player in a group");
  }

  async retryTask(taskId, componentId, componentDesc) {
    const response = await axios.put(
      `/gov/api/rest/v1/lrt/retryTaskComponent/${taskId}`,
      {
        playerComponentsIdList: [componentId],
      }
    );

    if (response && response.status !== 204) {
      throw Error("Invalid response for add player in a group");
    }
  }

  async triggerTask(taskId, brandId, partnerId, username) {
    const response = await axios.put(
      `/gov/api/rest/v1/lrt/forceActivationTaskComponent/${taskId}`,
      {
        changedBy: `BO User: ${username}`,
      }
    );

    if (response && response.status !== 204) {
      throw Error("Invalid response for trigger the task");
    }
  }

  async getDataPortabilityAttachment(contractId, values) {
    var url = `/gov/api/rest/v1/dataPortability/${contractId}/attachments`;
    var params = new URLSearchParams();

    values.forEach((val) => {
      params.append("attachmentPath", val.value);
    });

    const response = await axios.get(url, {
      params: params,
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for trigger the task");
  }
}

const longRunningTaskService = new LongRunningTaskService();
export default longRunningTaskService;
