import { call, put } from "redux-saga/effects";
import {
  PLAYER_FRIENDS_FAILURE,
  PLAYER_FRIENDS_REQUEST,
  PLAYER_FRIENDS_SUCCESS,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import playerFriendsService from "./player-friends-service";

const loadPlayerFriends = function* (action) {
  try {
    const data = yield call(
      playerFriendsService.loadPlayerFriends,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: PLAYER_FRIENDS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadPlayerFriends", e);
    yield put({
      type: PLAYER_FRIENDS_FAILURE,
    });
  }
};

const PlayerFriendsSaga = function* directNotificationsSaga() {
  yield takeLatest(PLAYER_FRIENDS_REQUEST, loadPlayerFriends);
};

export default PlayerFriendsSaga;
