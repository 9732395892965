import axios from "axios";
import searchUtils from "../../../../utilities/searchUtils";
class PlayerFavoritesService {
  async getPlayerFavorites(brand, partner, contractId) {
    const response = await axios.get(
      `/gov/api/rest/v1/players/playerFavorites?brand=${brand.id}&partner=${partner.id}&contractId=${contractId}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for individual player favorites list");
  }

  async getCategoryList() {
    /* const response = await axios.get(
              `/gov/api/rest/v1/players/category`,
            );
            if (response && response.data) {
              return response.data;
            }
            throw Error("Invalid response for individual player favorites list");*/
    const response = [{ category: "RETAILER" }];
    return response;
  }

  async getLocationsList(page, pageSize, terms, add) {
    const url = `/gov/api/rest/v1/locations?page=${page}&pageSize=${pageSize}`;
    let queryParam = terms ? searchUtils.getQueryParam(terms, true, false) : "";
    if (add) {
      queryParam += "&status=ACTIVE";
    }
    const response = await axios.get(url + queryParam);
    if (response && response.data) {
      const totalCount = response.data.nextPageUrl
        ? response.data.nextItems + response.data.previousItems + pageSize
        : response.data.previousItems + response.data.locations.length;

      return {
        data: response.data.locations,
        page: page,
        totalCount: totalCount,
      };
    }
    throw Error("Invalid response for locations list");
  }

  async saveFavorite(brand, partner, fields, contractId, actionType) {
    fields.category = fields.type;
    fields.contractId = contractId;
    let favoriteId = fields.id;
    delete fields["id"];
    const response = await axios({
      method: actionType === "edit" ? "put" : "post",
      url:
        actionType === "edit"
          ? `/gov/api/rest/v1/players/playerFavorites/${favoriteId}?brand=${brand.id}&partner=${partner.id}`
          : `/gov/api/rest/v1/players/playerFavorites?brand=${brand.id}&partner=${partner.id}`,
      data: fields,
    });
    if (!response) {
      throw Error(" Unable to add/Edit response");
    }
  }
}

const playerFavoritesService = new PlayerFavoritesService();
export default playerFavoritesService;
