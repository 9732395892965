import {
  SENSITIVE_LIMIT_CONFIGURATION_DETAILS_FAILURE,
  SENSITIVE_LIMIT_CONFIGURATION_DETAILS_REQUEST,
  SENSITIVE_LIMIT_CONFIGURATION_DETAILS_SUCCESS,
} from "../../../constants";
import React from "react";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../../../Layouts/Currency/Currency";
import Moment from "react-moment";

export const labelPrefix = "label.admin.sensitiveLimitConfiguration.";

const initialState = {
  sensitiveLimitConfiguration: {
    loading: true,
    data: [],
    fields: [
      {
        field: "status",
        title: labelPrefix + "status",
        default: true,
        render: (rowData) => {
          if (rowData["status"] === true) {
            return <BadgeStatus id={"2"} label={"enabled"} />;
          } else {
            return <BadgeStatus id={"6"} label={"disabled"} />;
          }
        },
      },
      {
        field: "amount",
        title: labelPrefix + "amount",
        default: true,
        render: (rowData) => {
          if (rowData["amount"] && rowData["status"] === true) {
            return <Currency amount={rowData["amount"]} justify={"flex-end"} />;
          }
        },
      },
      {
        field: "lastUpdate",
        title: labelPrefix + "lastUpdate",
        default: true,
        render: (rowData) => {
          if (rowData["lastUpdate"]) {
            return <Moment format="L LT">{rowData["lastUpdate"]}</Moment>;
          }
        },
      },
      {
        field: "lastUpdatedUserRole",
        title: labelPrefix + "lastUpdatedUserRole",
        default: true,
      },
      {
        field: "lastUpdatedUserName",
        title: labelPrefix + "lastUpdatedUserName",
        default: true,
      },
    ],
  },
};

const SensitiveLimitConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SENSITIVE_LIMIT_CONFIGURATION_DETAILS_REQUEST:
      return {
        ...state,
        sensitiveLimitConfiguration: {
          ...state.sensitiveLimitConfiguration,
          filters: [],
          loading: true,
          lastRequest: action,
        },
      };
    case SENSITIVE_LIMIT_CONFIGURATION_DETAILS_SUCCESS:
      return {
        ...state,
        sensitiveLimitConfiguration: {
          ...state.sensitiveLimitConfiguration,
          data: action.payload,
          loading: false,
        },
      };
    case SENSITIVE_LIMIT_CONFIGURATION_DETAILS_FAILURE:
      return {
        ...state,
        sensitiveLimitConfiguration: {
          ...state.sensitiveLimitConfiguration,
          loading: false,
          data: null,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default SensitiveLimitConfigurationReducer;
