import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { CONTRACT_TYPE_REQUEST, CONTRACT_TYPE_SUCCESS } from "../constants";

const contractListFetching = async () => {
  const response = await axios.get("/gov/api/rest/v1/contactTypeList");
  return response.data;
};

const loadContractList = function* (action) {
  let data = [];
  try {
    data = yield call(contractListFetching, action.payload);
  } catch (e) {
    console.error(e);
  }

  yield put({
    type: CONTRACT_TYPE_SUCCESS,
    payload: data,
  });
};

const ContractTypeListSaga = function* () {
  yield takeEvery(CONTRACT_TYPE_REQUEST, loadContractList);
};

export default ContractTypeListSaga;
