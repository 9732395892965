import { Box, Switch } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../Layouts/Currency/Currency";
import FormTooltip from "../../Layouts/form/form-tooltip";
import Translate from "../../Layouts/Translator/Translate";
import {
  TAXABLE_PRIZE_LIST_FILTERS_FAILURE,
  TAXABLE_PRIZE_LIST_FILTERS_SUCCESS,
  TRANSACTION_LOGS_FAILURE,
  TRANSACTION_LOGS_SUCCESS,
  WINNINGS_LIST_FAILURE,
  WINNINGS_LIST_REQUEST,
  WINNINGS_LIST_SUCCESS,
} from "../constants";

export const deductLabelPrefix = "label.taxablePrize.deductAmount.";
export const onHoldPaymentLabelPrefix = "label.taxablePrize.onHoldPayment.";

export const getPaymentStatusMapping = (status) => {
  let label = "taxablePrize." + status;
  switch (status) {
    case "ON_HOLD_IN_PROGRESS":
      return { id: "1", label: label };
    case "NOT_EXECUTED":
      return { id: "1", label: label };
    case "LOSER":
      return { id: "2", label: label };
    case "WINNING":
      return { id: "2", label: label };
    case "PAID":
      return { id: "2", label: label };
    case "ON_HOLD_APPROVED":
      return { id: "2", label: label };
    case "MANUALLY_PAID":
      return { id: "2", label: label };
    case "ON_HOLD":
      return { id: "3", label: label };
    case "VALIDATION_ERROR":
      return { id: "4", label: label };
    case "NOT_PAID":
      return { id: "6", label: label };
    case "ADJUSTED":
      return { id: "7", label: label };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const getPDFStatusMapping = (status) => {
  let label = "taxablePrize." + status;
  switch (status) {
    case "0":
      return { id: "6", label: label };
    case "1":
      return { id: "12", label: label };
    case "2":
      return { id: "10", label: label };
    case "3":
      return { id: "11", label: label };
    case "4":
      return { id: "9", label: label };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const getOnHoldStatusMapping = (status) => {
  let label = "taxablePrize." + status;
  switch (status) {
    case "WAITING_CLAIM":
      return { id: "3", label: label };
    case "CLAIM_INITIATED":
      return { id: "1", label: label };
    case "WAITING_ACCOUNT_UPGRADE":
      return { id: "3", label: label };
    case "SUCCESS_PROCESSED_CAP":
      return { id: "2", label: "taxablePrize.capSuccess" };
    case "ERROR_PROCESSED_CAP":
      return { id: "4", label: "taxablePrize.capError" };
    case "CLAIM_COMPLETED":
      return { id: "2", label: label };
    case "WAITING_APPROVAL":
      return { id: "1", label: label };
    case "MANUALLY_PAID":
      return { id: "2", label: label };
    case "VOIDED":
      return { id: "6", label: label };
    case "APPROVED":
      return { id: "2", label: label };
    case "ACCOUNT_UPGRADED":
      return { id: "2", label: label };
    case "APPROVED_PAID":
      return { id: "2", label: label };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

export const deductVisibility = (data) => {
  let payment = data["paymentStatus"];
  let onHold = data["onHoldStatus"];
  let visibility = false;
  if (payment === "ON_HOLD_IN_PROGRESS") {
    switch (onHold) {
      case "WAITING_APPROVAL": {
        visibility = true;
        break;
      }
      case "WAITING_CLAIM":
        visibility = true;
        break;
      case "ERROR_PROCESSED_CAP":
        visibility = true;
        break;
      case "WAITING_ACCOUNT_UPGRADE":
        visibility = true;
        break;
      default: {
        return false;
      }
    }
  }
  return visibility;
};

export const approveVisibility = (data) => {
  let payment = data["paymentStatus"];
  let onHold = data["onHoldStatus"];
  let visibility = false;
  if (payment === "ON_HOLD_IN_PROGRESS" && onHold === "WAITING_APPROVAL") {
    visibility = true;
  }
  return visibility;
};

export const manuallyVisibility = (data) => {
  let payment = data["paymentStatus"];
  let onHold = data["onHoldStatus"];
  let visibility = false;
  if (payment === "ON_HOLD_IN_PROGRESS") {
    switch (onHold) {
      case "WAITING_APPROVAL": {
        visibility = true;
        break;
      }
      case "ERROR_PROCESSED_CAP":
        visibility = true;
        break;
      default: {
        return false;
      }
    }
  }
  return visibility;
};

export const claimVisibility = (data) => {
  let payment = data["paymentStatus"];
  let onHold = data["onHoldStatus"];
  let visibility = false;
  if (payment === "ON_HOLD_IN_PROGRESS") {
    switch (onHold) {
      case "WAITING_CLAIM":
        visibility = true;
        break;
      case "ERROR_PROCESSED_CAP":
        visibility = true;
        break;
      default: {
        return false;
      }
    }
  }
  return visibility;
};

export const voidVisibility = (data) => {
  let payment = data["paymentStatus"];
  let onHold = data["onHoldStatus"];
  let visibility = false;
  if (payment === "ON_HOLD_IN_PROGRESS" && onHold === "WAITING_APPROVAL") {
    visibility = true;
  }
  return visibility;
};

export const pdfVisibility = (data, action) => {
  let pdf = data["pdfStatus"];
  let visibility = false;
  switch (action) {
    case "DOWNLOAD":
      if (pdf === "2") {
        visibility = true;
      }
      break;
    case "GENERATE":
      if (pdf === "4") {
        visibility = true;
      }
      break;
    case "OPTIONS_LIST":
      if (pdf === "3") {
        visibility = true;
      }
      break;
    default: {
      return false;
    }
  }
  return visibility;
};

export const labelPrefix = "label.taxablePrize.";

var paymentInChargeEnable = ["VALIDATION_ERROR", "ON_HOLD"];

const initialState = {
  taxablePrizeList: {
    idKey: "winningId",
    filtersLoading: true,
    tableLoading: true,
    filters: [],
    filtersKey: "",
    winnings: [],
    sort: { field: "paymentRequestDate", order: "asc" },
    columns: (handleTakeInCharge, admin) => {
      return [
        {
          field: "inCharge",
          title: labelPrefix + "winningsList.inCharge",
          nowrap: false,
          sorting: false,
          render: (rowData) => {
            if (rowData["inCharge"] != null) {
              return (
                <Box display={"flex"} justifyContent={"center"}>
                  <Tooltip
                    placement={"right"}
                    disableHoverListener={
                      rowData["subjectInCharge"] === null ||
                      (rowData["subjectInCharge"] !== null &&
                        rowData["inCharge"])
                    }
                    title={
                      <Translate
                        needle={labelPrefix + "winningsList.otherUser"}
                      />
                    }
                  >
                    <Box>
                      <Switch
                        name={"inCharge"}
                        checked={rowData["inCharge"]}
                        size={"small"}
                        disabled={
                          !(
                            paymentInChargeEnable.includes(
                              rowData["paymentStatus"]
                            ) ||
                            (rowData.inCharge &&
                              rowData["paymentStatus"] ===
                                "ON_HOLD_IN_PROGRESS") ||
                            (!rowData.inCharge &&
                              admin &&
                              rowData["paymentStatus"] ===
                                "ON_HOLD_IN_PROGRESS")
                          )
                        }
                        onChange={() => handleTakeInCharge(rowData)}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              );
            }
          },
        },
        {
          field: "pdfStatus",
          title: labelPrefix + "winningsList.pdfStatus",
          default: true,
          nowrap: false,
          emptyValueJustify: "center",
          sorting: false,
          render: (rowData) => {
            const statusMapped = getPDFStatusMapping(rowData["pdfStatus"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                tooltip={true}
                fullWidth
                justify={"center"}
              />
            );
          },
        },
        {
          field: "gameAccount",
          title: labelPrefix + "winningsList.gameAccount",
          sorting: false,
        },
        {
        field: "thpId",
          title: labelPrefix + "winningsList.thpId",
          sorting: false,
        },
        {
          field: "transactionId",
          title: labelPrefix + "winningsList.transactionId",
          sorting: false,
        },
        {
          field: "gameName",
          title: labelPrefix + "winningsList.gameName",
          sorting: false,
        },
        {
          field: "paymentStatus",
          title: labelPrefix + "winningsList.paymentStatus",
          default: true,
          nowrap: false,
          emptyValueJustify: "center",
          sorting: false,
          render: (rowData) => {
            const statusMapped = getPaymentStatusMapping(
              rowData["paymentStatus"]
            );
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                fullWidth
                tooltip={true}
                justify={"center"}
              />
            );
          },
        },
        {
          field: "onHoldStatus",
          title: labelPrefix + "winningsList.onHoldStatus",
          default: true,
          nowrap: false,
          emptyValueJustify: "center",
          sorting: false,
          render: (rowData) => {
            const statusMapped = getOnHoldStatusMapping(
              rowData["onHoldStatus"]
            );
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                fullWidth
                tooltip={true}
                justify={"center"}
              />
            );
          },
        },
        {
          field: "paymentRequestDate",
          title: labelPrefix + "winningsList.paymentRequestDate",
          render: (rowData) => {
            if (rowData["paymentRequestDate"]) {
              return (
                <Moment format="L LT">{rowData["paymentRequestDate"]}</Moment>
              );
            }
          },
        },
        {
          field: "paymentDate",
          title: labelPrefix + "winningsList.paymentDate",
          render: (rowData) => {
            if (rowData["paymentDate"]) {
              return <Moment format="L LT">{rowData["paymentDate"]}</Moment>;
            }
          },
        },
      ];
    },

    detailCols: [
      {
        field: "grossAmount",
        title: labelPrefix + "winningsList.grossAmount",
        nowrap: false,
        render: (rowData) => {
          return (
            <Currency
              currency={rowData["currency"]}
              amount={rowData["grossAmount"]}
              justify={"flex-end"}
            />
          );
        },
      },
      {
        field: "taxAmount",
        title: labelPrefix + "winningsList.taxAmount",
        nowrap: false,
        render: (rowData) => {
          return (
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Box mt={0.5}>
                <Currency
                  currency={rowData["currency"]}
                  amount={rowData["taxAmount"]}
                />
              </Box>
              <Box>
                {rowData["taxDetail"] != null &&
                rowData["taxDetail"].length > 0 ? (
                  <FormTooltip
                    multiline="true"
                    title={rowData["taxDetail"].map((it) => {
                      return (
                        <Box display={"flex"}>
                          <Box mr={0.5}>
                            <Translate
                              needle={
                                labelPrefix +
                                "winningsList.amount_" +
                                it.taxType
                              }
                            />
                          </Box>
                          <Box>
                            <Currency
                              currency={rowData["currency"]}
                              amount={it.taxAmount}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                    placement="right"
                  />
                ) : null}
              </Box>
            </Box>
          );
        },
      },
      {
        field: "netAmount",
        title: labelPrefix + "winningsList.netAmount",
        nowrap: false,
        render: (rowData) => {
          return (
            <Currency
              currency={rowData["currency"]}
              amount={rowData["netAmount"]}
              justify={"flex-end"}
            />
          );
        },
      },
      {
        field: "deductAmount",
        title: labelPrefix + "winningsList.deductAmount",
        nowrap: false,
        render: (rowData) => {
          return (
            <Currency
              currency={rowData["currency"]}
              amount={rowData["deductAmount"]}
              justify={"flex-end"}
            />
          );
        },
      },
    ],
  },
  transactionLogs: {
    isLoading: true,
    columns: [
      {
        field: "operationType",
        title: labelPrefix + "transactionDetail.operationType",
        render: (rowData) => {
          return (
            <Translate
              needle={
                `${labelPrefix}transactionDetail.actionType.` +
                rowData["operationType"]
              }
            />
          );
        },
      },
      {
        field: "operationAmount",
        title: labelPrefix + "transactionDetail.operationAmount",
        render: (rowData) => {
          if (rowData.operationAmount) {
            return <Currency amount={rowData.operationAmount} />;
          } else {
            return "-";
          }
        },
      },
      {
        field: "operationOwnerType",
        title: labelPrefix + "transactionDetail.operationOwnerType",
        render: (rowData) => {
          return (
            <Translate
              needle={
                `${labelPrefix}transactionDetail.ownerType.` +
                rowData["operationOwnerType"]
              }
              variables={
                rowData["operationOwnerType"] !== "SYSTEM" && [
                  rowData["operationOwner"],
                ]
              }
            />
          );
        },
      },
      {
        field: "operationDate",
        title: labelPrefix + "transactionDetail.operationDate",
        render: (rowData) => {
          if (rowData["operationDate"]) {
            return <Moment format="L LT">{rowData["operationDate"]}</Moment>;
          }
        },
      },
      {
        field: "comment",
        title: labelPrefix + "transactionDetail.reason",
      },
      {
        field: "paymentStatus",
        title: labelPrefix + "transactionDetail.paymentStatus",
        default: true,
        nowrap: false,
        render: (rowData) => {
          if (rowData["paymentStatus"] != null) {
            const statusMapped = getPaymentStatusMapping(
              rowData["paymentStatus"]
            );
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                tooltip={true}
                justify={"center"}
              />
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "onHoldStatus",
        title: labelPrefix + "transactionDetail.onHoldStatus",
        default: true,
        nowrap: false,
        render: (rowData) => {
          if (rowData["onHoldStatus"] != null) {
            const statusMapped = getOnHoldStatusMapping(
              rowData["onHoldStatus"]
            );
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                tooltip={true}
                justify={"center"}
              />
            );
          } else {
            return "-";
          }
        },
      },
    ],
    tableData: [],
  },
};

const TaxablePrizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TAXABLE_PRIZE_LIST_FILTERS_SUCCESS: {
      return {
        ...state,
        taxablePrizeList: {
          ...state.taxablePrizeList,
          filters: action.payload,
          filtersLoading: false,
        },
      };
    }

    case TAXABLE_PRIZE_LIST_FILTERS_FAILURE: {
      return {
        ...state,
        taxablePrizeList: {
          ...state.taxablePrizeList,
          filters: [],
          filtersLoading: false,
        },
      };
    }
    case WINNINGS_LIST_SUCCESS: {
      return {
        ...state,
        taxablePrizeList: {
          ...state.taxablePrizeList,
          winnings: action.payload,
          tableLoading: false,
          sort: action.payload.order,
        },
      };
    }
    case WINNINGS_LIST_FAILURE: {
      return {
        ...state,
        taxablePrizeList: {
          ...state.taxablePrizeList,
          winnings: [],
          tableLoading: false,
        },
      };
    }

    case WINNINGS_LIST_REQUEST: {
      return {
        ...state,
        taxablePrizeList: {
          ...state.taxablePrizeList,
          lastRequest: action,
          tableLoading: true,
        },
      };
    }

    case TRANSACTION_LOGS_SUCCESS: {
      return {
        ...state,
        transactionLogs: {
          ...state.transactionLogs,
          tableData: action.payload,
          isLoading: false,
        },
      };
    }
    case TRANSACTION_LOGS_FAILURE: {
      return {
        ...state,
        transactionLogs: {
          ...state.transactionLogs,
          tableData: null,
          isLoading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default TaxablePrizeReducer;
