import React from "react";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import { useSelector } from "react-redux";

const ClosingAccountStatus = ({ playerInfo }) => {
  const { fields } = useSelector(
    (state) => state.PlayerDetailReducer.playerDetails.closingAccount
  );

  return (
    <ListPanel
      data={playerInfo.closedPlayerAccount}
      fields={fields}
      loading={playerInfo.loading}
    />
  );
};

export default ClosingAccountStatus;
