import {
  LOCATIONS_ADMIN_LIST_SUCCESS,
  LOCATIONS_ADMIN_LIST_FAILURE,
  LOCATIONS_ADMIN_LIST_REQUEST,
} from "../../store/constants";
import React from "react";
import BadgeStatus from "../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../Layouts/Translator/Translate";
const statusRequestMapping = (request) => {
  if (request) {
    return { id: "2", label: "active" };
  } else {
    return { id: "6", label: "terminated" };
  }
};
const servicesRequestMapping = (request) => {
  let services = [
    "assisted",
    "selfService",
    "monitorDisplay",
    "ticketSelfCheck",
    "smokingAllowed",
    "lucky",
    "sellsDrawGames",
    "sellsInstantGames",
    "cashingAgent",
  ];
  let servicesReturned = [];
  services.map((r) => {
    if (request[r]) {
      servicesReturned.push(<Translate needle={"label.locations." + r} />);
      servicesReturned.push(" ,");
    }
  });
  servicesReturned.pop();
  return servicesReturned;
};
const initialState = {
  location: {
    data: [],
    loading: true,
    columns: [
      {
        field: "id",
        title: "label.locations.id",
      },
      {
        field: "name",
        title: "label.locations.name",
      },
      {
        field: "address1",
        title: "label.locations.address",
      },
      {
        field: "postalCode",
        title: "label.locations.postalCode",
      },
      {
        field: "city",
        title: "label.locations.city",
      },
      {
        field: "type",
        title: "label.locations.category",
      },
      {
        field: "id",
        title: "label.locations.status",
        render: (rowData) => {
          const requestMapped = statusRequestMapping(rowData["status"]);
          return (
            <BadgeStatus
              id={requestMapped.id}
              label={requestMapped.label}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "id",
        title: "label.locations.services",
        render: (rowData) => {
          const requestMapped = servicesRequestMapping(rowData);
          return requestMapped;
        },
      },
    ],
  },
};
const LocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS_ADMIN_LIST_REQUEST: {
      return {
        ...state,
        location: {
          ...state.location,
          loading: true,
        },
      };
    }
    case LOCATIONS_ADMIN_LIST_SUCCESS: {
      return {
        ...state,
        location: {
          ...state.location,
          data: action.payload,
          loading: false,
        },
      };
    }
    case LOCATIONS_ADMIN_LIST_FAILURE: {
      return {
        ...state,
        location: {
          ...state.location,
          data: null,
          loading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
export default LocationsReducer;
