import axios from "axios";

class SilentLoginService {

    async pageFetching(id) {
        const response = await axios.get(process.env.PUBLIC_URL + "/silent-login.json");
        const page = response.data.find((it) => it.id === id);
        return page ? page.url : null
    }

    async silentLoginRequest(body) {
        let url = "/gov/api/rest/v1/users/silent-login";

        const response = await axios.post(url,body);

        if (response && response.data) {
            return response.data;
        }

        throw Error("Invalid response");
    }
}

const silentLoginService = new SilentLoginService();
export default silentLoginService;
