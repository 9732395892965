import { call, put } from "redux-saga/effects";
import {
  ACCOUNT_TRANSACTION_SERACH_CRETERIA_REQUEST,
  ACCOUNT_TRANSACTION_SERACH_CRETERIA_SUCCESS,
  ACCOUNT_TRANSACTION_SERACH_CRETERIA_FAILURE,
  ACCOUNT_TRANSACTION_REQUEST,
  ACCOUNT_TRANSACTION_SUCCESS,
  ACCOUNT_TRANSACTION_FAILURE,
  ACCOUNT_TRANSACTION_DETAIL_REQUEST,
  ACCOUNT_TRANSACTION_DETAIL_SUCCESS,
  ACCOUNT_TRANSACTION_DETAIL_FAILURE,
  ACCOUNT_TRANSACTION_WRITE_OFF_REQUEST,
  ACCOUNT_TRANSACTION_CONFIRM_CANCEL_RESERVE_REQUEST,
} from "../../../constants";
import AccountTransactionsService from "./account-transaction-service";
import { takeLatest, delay } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadSearchCriteria = function* (action) {
  try {
    const filters = yield call(AccountTransactionsService.getFilterFields);
    yield put({
      type: ACCOUNT_TRANSACTION_SERACH_CRETERIA_SUCCESS,
      payload: filters,
    });
  } catch (e) {
    console.error("account transaction filter", e);
    yield put({
      type: ACCOUNT_TRANSACTION_SERACH_CRETERIA_FAILURE,
    });
  }
};

const loadTransactions = function* (action) {
  try {
    const data = yield call(
      AccountTransactionsService.getTransactions,
      action.contractId,
      action.brand,
      action.partner,
      action.searchTerms,
      action.page,
      action.pageSize,
      action.orderBy
    );
    data.order = action.orderBy;
    yield put({
      type: ACCOUNT_TRANSACTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("account transaction list", e);
    yield put({
      type: ACCOUNT_TRANSACTION_FAILURE,
    });
  }
};

const loadTransactionDetails = function* (action) {
  try {
    const data = yield call(
      AccountTransactionsService.getTransactionsDetails,
      action.transactionId,
      action.externalSystemId,
      action.confirmTransactionId,
      action.isConfirm,
      action.brand,
      action.partner,
      action.phase
    );
    yield put({
      type: ACCOUNT_TRANSACTION_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("transaction acoount details", e);
    yield put({
      type: ACCOUNT_TRANSACTION_DETAIL_FAILURE,
    });
  }
};

const putWriteOff = function* (action) {
  try {
    yield call(
      AccountTransactionsService.setWriteOff,
      action.amount,
      action.reason,
      action.brandSelected,
      action.partnerSelected,
      action.contractId,
      action.transactionId,
      action.gameAccountId
    );
    yield delay(1000);
    if (
      action.successCallback != null &&
      typeof action.successCallback === "function"
    ) {
      action.successCallback();
    }
    yield put({
      type: ACCOUNT_TRANSACTION_REQUEST,
      contractId: action.contractId,
      brand: action.brandSelected,
      partner: action.partnerSelected,
      searchTerms: action.searchTerms,
      page: action.query.page,
      pageSize: action.query.pageSize,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.accountTransactions.writeOffSuccessMessage",
          variables: [
            action.currency + " " + action.amount / action.divisorAmount,
            action.operation,
          ],
        },
        options: {
          key: "AccountTransactions_writeOff_success",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    if (action.setLoading != null && typeof action.setLoading === "function") {
      action.setLoading(false);
    }
    console.error("account transaction set write off", e);
  }
};

const confirmCancelReserve = function* (action) {
  try {
    yield call(
      AccountTransactionsService.setReserve,
      action.action,
      action.body
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.accountTransactions.success_" + action.action,
        },
        options: {
          key: "AccountTransactions_" + action.action + "_success",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    if (action.cb != null && typeof action.cb === "function") {
      action.cb(true);
    }
  } catch (e) {
    console.error("account transaction " + action.action, e);
  }
};

const AccountTransactionsSaga = function* accountTransactionsSaga() {
  yield takeLatest(ACCOUNT_TRANSACTION_REQUEST, loadTransactions);
  yield takeLatest(
    ACCOUNT_TRANSACTION_SERACH_CRETERIA_REQUEST,
    loadSearchCriteria
  );
  yield takeLatest(ACCOUNT_TRANSACTION_DETAIL_REQUEST, loadTransactionDetails);
  yield takeLatest(ACCOUNT_TRANSACTION_WRITE_OFF_REQUEST, putWriteOff);
  yield takeLatest(
    ACCOUNT_TRANSACTION_CONFIRM_CANCEL_RESERVE_REQUEST,
    confirmCancelReserve
  );
};

export default AccountTransactionsSaga;
