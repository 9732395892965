import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  ADD_MULTIPLE_FAILURE,
  ADD_MULTIPLE_REQUEST,
  ADD_MULTIPLE_SUCCESS,
  ADD_SINGLE_FAILURE,
  ADD_SINGLE_REQUEST,
  ADD_SINGLE_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  EDIT_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  LOAD_ROLES_FAILURE,
  LOAD_ROLES_REQUEST,
  LOAD_ROLES_SUCCESS,
  LOAD_USERS_FAILURE,
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  PARSE_MULTIPLE_FAILURE,
  PARSE_MULTIPLE_REQUEST,
  PARSE_MULTIPLE_SUCCESS,
  UNLOCK_USER_FAILURE,
  UNLOCK_USER_REQUEST,
  UNLOCK_USER_SUCCESS,
  USER_CHANGE_PWD_FAILURE,
  USER_CHANGE_PWD_REQUEST,
  USER_CHANGE_PWD_SUCCESS,
  USER_DETAIL_FAILURE,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_SET_HOME_PG_FAILURE,
  USER_SET_HOME_PG_REQUEST,
  USER_SET_HOME_PG_SUCCESS,
} from "../../../constants";
import { enqueueSnackbar } from "../../../snackbars/actions";
import { labelPrefix } from "./users-reducer";
import usersService from "./users-service";

const loadUsers = function* (action) {
  try {
    const data = yield call(
      usersService.loadUsers,
      action.brand,
      action.partner
    );
    yield put({
      type: LOAD_USERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin security users error", e);
    yield put({
      type: LOAD_USERS_FAILURE,
    });
  }
};

const getUserDetail = function* (action) {
  try {
    const data = yield call(usersService.getUserDetail, action.userId);
    yield put({
      type: USER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin user detail error", e);
    yield put({
      type: USER_DETAIL_FAILURE,
    });
  }
};

const getRoles = function* (action) {
  try {
    const data = yield call(
      usersService.getRoles,
      action.own,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: LOAD_ROLES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin user detail error", e);
    yield put({
      type: LOAD_ROLES_FAILURE,
    });
  }
};

const addSingleUser = function* (action) {
  try {
    let body = { ...action.values };
    delete body.addType;
    delete body.skipPassword;
    delete body.csvFile;
    body.wait = true;

    const data = yield call(usersService.addSingleUser, body);
    yield put({
      type: ADD_SINGLE_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();

    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "addSingleUserSuccess",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("add single user error", e);
    yield put({
      type: ADD_SINGLE_FAILURE,
    });
  }
};

const parseMultipleUsersData = function* (action) {
  try {
    const data = yield call(usersService.parseMultipleUsersData, action.values);

    yield put({
      type: PARSE_MULTIPLE_SUCCESS,
      payload: data,
    });
    if (data) {
      action.cb != null && typeof action.cb === "function" && action.cb();
    }
  } catch (e) {
    console.error("admin add single user error", e);
    yield put({
      type: PARSE_MULTIPLE_FAILURE,
    });
  }
};

const addMultipleUsers = function* (action) {
  try {
    const data = yield call(usersService.addMultipleUsers, action.body);

    yield put({
      type: ADD_MULTIPLE_SUCCESS,
      payload: data,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "addMultipleUsersSuccess",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("admin add multiple users error", e);
    yield put({
      type: ADD_MULTIPLE_FAILURE,
    });
  }
};

const editUser = function* (action) {
  try {
    yield call(usersService.editUser, action.body);

    yield put({
      type: EDIT_USER_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "editUserSuccess",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("admin add multiple users error", e);
    yield put({
      type: EDIT_USER_FAILURE,
    });
  }
};

const changeUserPassword = function* (action) {
  try {
    yield call(usersService.changeUserPassword, action.userId, action.body);

    yield put({
      type: USER_CHANGE_PWD_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "changeUserPasswordSuccess",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("admin change user password error", e);
    yield put({
      type: USER_CHANGE_PWD_FAILURE,
    });
  }
};

const setUserHomePage = function* (action) {
  try {
    yield call(
      usersService.setUserHomePage,
      action.userId,
      action.body,
      action.wait
    );

    yield put({
      type: USER_SET_HOME_PG_SUCCESS,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "setUserHomePageSuccess",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("admin set user home page error", e);
    yield put({
      type: USER_SET_HOME_PG_FAILURE,
    });
  }
};

const deleteUser = function* (action) {
  try {
    yield call(usersService.deleteUser, action.userId);

    yield put({
      type: DELETE_USER_SUCCESS,
    });

    action.cb != null && typeof action.cb === "function" && action.cb();

    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "deleteUserSuccess",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("admin delete user error", e);
    yield put({
      type: DELETE_USER_FAILURE,
    });
  }
};

const unlockUser = function* (action) {
  try {
    yield call(usersService.unlockUser, action.userId);

    yield put({
      type: UNLOCK_USER_SUCCESS,
    });

    action.cb != null && typeof action.cb === "function" && action.cb();

    yield put(
      enqueueSnackbar({
        message: {
          needle: labelPrefix + "unlockUserSuccess",
        },
        options: {
          key: "cooldown",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
  } catch (e) {
    console.error("admin unlock user error", e);
    yield put({
      type: UNLOCK_USER_FAILURE,
    });
  }
};

const UsersSaga = function* UsersSaga() {
  yield takeLatest(LOAD_USERS_REQUEST, loadUsers);
  yield takeLatest(USER_DETAIL_REQUEST, getUserDetail);
  yield takeLatest(LOAD_ROLES_REQUEST, getRoles);
  yield takeLatest(ADD_SINGLE_REQUEST, addSingleUser);
  yield takeLatest(PARSE_MULTIPLE_REQUEST, parseMultipleUsersData);
  yield takeLatest(ADD_MULTIPLE_REQUEST, addMultipleUsers);
  yield takeLatest(EDIT_USER_REQUEST, editUser);
  yield takeLatest(USER_CHANGE_PWD_REQUEST, changeUserPassword);
  yield takeLatest(USER_SET_HOME_PG_REQUEST, setUserHomePage);
  yield takeLatest(DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(UNLOCK_USER_REQUEST, unlockUser);
};

export default UsersSaga;
