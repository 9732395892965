import Box from "@material-ui/core/Box";
import { Card } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ListPanel from "../../../../Layouts/Panel/ListPanel";
import React from "react";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { labelPrefix } from "../../../../store/players/individual-player/sensitive-vip-manegement/sensitive-vip-management-reducer";
import Table from "../../../../Layouts/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useSession } from "../../../../utilities/hooks/useSession";
import {
  loadHistory,
  loadDetails,
} from "../../../../store/players/individual-player/sensitive-vip-manegement/actions";
import { useRouteMatch } from "react-router-dom";
import { ReactComponent as CheckmarkIcon } from "../../../../Images/status_icon_success.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SensitiveVipModal from "./sensitive-vip-management-modal";
import Action from "../../../../Layouts/action/action";

export const getActions = (vip, sensitive, modalFor, openModal) => {
  const sensitiveIcon = sensitive ? (
    <HighlightOffIcon />
  ) : (
    <SvgIcon component={CheckmarkIcon} />
  );
  const sensitiveLabel =
    labelPrefix + (sensitive ? "sensitiveUnmark" : "sensitiveMark");
  const sensitiveoColor = sensitive ? "failure" : "success";
  const vipIcon = vip ? (
    <HighlightOffIcon />
  ) : (
    <SvgIcon component={CheckmarkIcon} />
  );
  const vipLabel = labelPrefix + (vip ? "vipUnmark" : "vipMark");
  const vipColor = vip ? "failure" : "success";
  return (
    <>
      {(modalFor == null || modalFor == "vip") && (
        <Box mr={modalFor == null ? 2 : 0}>
          <Action
            startIcon={vipIcon}
            color={vipColor}
            onClick={() => modalFor == null && openModal("vip")}
            type={modalFor == null ? "button" : "submit"}
            permission={"markUnmarkVip"}
            label={vipLabel}
          />
        </Box>
      )}
      {(modalFor == null || modalFor == "sensitive") && (
        <Box>
          <Action
            startIcon={sensitiveIcon}
            color={sensitiveoColor}
            onClick={() => modalFor == null && openModal("sensitive")}
            type={modalFor == null ? "button" : "submit"}
            permission={"markUnmarkSensitive"}
            label={sensitiveLabel}
          />
        </Box>
      )}
    </>
  );
};

const SensitiveVipManagement = ({ widget }) => {
  const t = useTranslator();
  const dispatch = useDispatch();

  const { fields } = useSelector(
    (state) => state.SensitiveVipManagementReducer
  );

  const { data, panelLoading } = useSelector(
    (state) => state.SensitiveVipManagementReducer.detailPanel
  );
  const { tableData, tableLoading } = useSelector(
    (state) => state.SensitiveVipManagementReducer.historyList
  );
  const { brandSelected, partnerSelected } = useSession();
  const match = useRouteMatch();
  const contractId = match.params.id;
  const [modalChange, setModalChange] = React.useState({ open: false });

  const openModal = (mode) => {
    setModalChange({
      open: true,
      mode: mode,
    });
  };

  const closeModal = () => {
    setModalChange({ open: false });
  };

  React.useEffect(() => {
    dispatch(loadDetails(brandSelected, partnerSelected, contractId));
    // eslint-disable-next-line
  }, [loadDetails, brandSelected, partnerSelected, contractId]);

  React.useEffect(() => {
    dispatch(loadHistory(brandSelected, partnerSelected, contractId));
    // eslint-disable-next-line
  }, [loadHistory, brandSelected, partnerSelected, contractId]);

  const getPanelDetail = () => {
    return (
      <ListPanel
        maxHeight={widget ? 0 : 290}
        key="0"
        data={data}
        loading={panelLoading}
        fields={fields}
      />
    );
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"} pb={2}>
        {data != null &&
          !widget &&
          getActions(data.vip, data.sensitive, null, openModal)}
      </Box>
      <Box>
        {widget ? (
          <>
            <Box>{getPanelDetail()}</Box>
            <Box display={"flex"} justifyContent={"flex-end"} pb={2} pt={2}>
              {getActions(data.vip, data.sensitive, null, openModal)}
            </Box>
          </>
        ) : (
          <Card>
            <CardHeader title={t({ needle: labelPrefix + "panelTitle" })} />
            <CardContent>{getPanelDetail()}</CardContent>
          </Card>
        )}
      </Box>
      {!widget && (
        <Box mt={5}>
          <Table
            pageSize={10}
            type={"advanced"}
            pagination={true}
            exportButton={false}
            title={labelPrefix + "tableTitle"}
            columns={fields}
            data={tableData}
            isLoading={tableLoading}
          />
        </Box>
      )}

      <SensitiveVipModal
        data={data}
        openModal={modalChange}
        closeModal={closeModal}
        contractId={contractId}
      />
    </>
  );
};

export default SensitiveVipManagement;
