import {
  EXPIRING_BONUS_SEARCH_SUCCESS,
  EXPIRING_BONUS_SEARCH_FAILURE,
  STYLE_CURRENCY,
  STYLE_LABEL,
  EXPIRING_BONUS_VIEW_FAILURE,
  EXPIRING_BONUS_VIEW_SUCCESS,
  EXPIRING_BONUS_PLAYER_LIST_SUCCESS,
  EXPIRING_BONUS_PLAYER_LIST_FAILURE,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import Currency from "../../../../Layouts/Currency/Currency";
import * as moment from "moment";

const getStatusMapping = (status) => {
  switch (status) {
    case "COMPLETED_ERROR": {
      return { id: "4", label: "error" };
    }
    case "CREATED": {
      return { id: "2", label: "created" };
    }
    case "PENDING": {
      return { id: "3", label: "pending" };
    }
    case "COMPLETED_SUCCESS": {
      return { id: "2", label: "success" };
    }
    case "TO_BE_APPROVED": {
      return { id: "3", label: "pendingApproval" };
    }
    case "TO_BE_APPROVED_FOR_OVERBUDGET": {
      return { id: "3", label: "overBudget" };
    }
    case "APPROVED": {
      return { id: "1", label: "approved" };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

export const getPlayerStatusMapping = (status) => {
  switch (status) {
    case 1: {
      return { id: "1", label: "gameAccountstatus_1" };
    }
    case 2: {
      return { id: "2", label: "gameAccountstatus_2" };
    }
    case 3:
    case 4: {
      return { id: "3", label: "gameAccountstatus_" + status };
    }
    case 5:
    case 6: {
      return { id: "6", label: "gameAccountstatus_" + status };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const initialState = {
  expiringBonusSearch: {
    idKey: "massiveId",
    searchCriteria: [
      {
        name: "requestDate",
        label: "label.expiringBonus.creationDate",
        type: "date",
        selected: true,
        placeholder: "label.placeholders.date",
      },
      {
        name: "requestBy",
        label: "label.expiringBonus.userCreator",
      },
      {
        name: "jobName",
        label: "label.expiringBonus.requestName",
      },
      {
        name: "status",
        label: "label.expiringBonus.status",
        type: "select",
        multiple: true,
        placeholder: "label.placeholders.multiselect",
        options: [
          { label: "label.status.pending", value: "PENDING" },
          { label: "label.status.approved", value: "APPROVED" },
          { label: "label.status.success", value: "COMPLETED_SUCCESS" },
          { label: "label.status.error", value: "COMPLETED_ERROR" },
          {
            label: "label.status.overBudget",
            value: "TO_BE_APPROVED_FOR_OVERBUDGET",
          },
          {
            label: "label.status.toapprove",
            value: "TO_BE_APPROVED",
          },
        ],
      },
    ],
    uploads: [],
    columns: [
      {
        field: "creationDate",
        title: "label.expiringBonus.creationDate",
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["creationDate"]) {
            return moment(rowData["creationDate"]).format("L LT");
          }
        },
        default: true,
        defaultSort: "desc",
      },
      {
        field: "requestName",
        title: "label.expiringBonus.requestName",
        default: true,
      },
      { field: "userCreator", title: "label.expiringBonus.userCreator" },
      {
        field: "status",
        default: true,
        title: "label.expiringBonus.status",
        nowrap: false,
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapping = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapping.id}
                label={statusMapping.label}
                justify="center"
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["status"]) {
            const statusMapping = getStatusMapping(rowData["status"]);
            return {
              style: STYLE_LABEL,
              value: `label.status.${statusMapping.label}`,
            };
          }
        },
      },
      {
        field: "reward.currency",
        title: "label.expiringBonus.currency",
        nowrap: false,
        render: (rowData) => {
          if (rowData["reward"]["currency"]) {
            return (
              <CurrencyBadge
                currency={rowData["reward"]["currency"]}
                justify="center"
              />
            );
          }
        },
      },
      {
        field: "totalAmount",
        title: "label.expiringBonus.totalAmount",
        nowrap: false,
        render: (rowData) => {
          if (rowData["totalAmount"]) {
            return (
              <Currency
                amount={rowData["totalAmount"]}
                currency={rowData["reward"]["currency"]}
                justify="flex-end"
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["totalAmount"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["reward"]["currency"],
              value: rowData["totalAmount"],
            };
          }
        },
      },
    ],
  },
  expiringBonusView: {
    uploads: [],
    idKey: "gameAccount",
    columns: [
      {
        field: "gameAccount",
        title: "label.expiringBonusView.gameAccount",
        default: true,
      },
      {
        field: "result",
        title: "label.expiringBonusView.result",
      },
      {
        field: "resultDescription",
        title: "label.expiringBonusView.resultDescription",
      },
      {
        field: "externalTransactionId",
        title: "label.expiringBonusView.externalTransactionId",
      },
    ],
  },
  expiringBonusCreate: {
    searchCriteria: [
      {
        name: "DAYS_SINCE_LAST_LOGGED",
        label: "label.expiringBonus.create.daysSinceLastLogged",
        type: "conditional",
      },
      {
        name: "DAYS_SINCE_LAST_DEPOSITED",
        label: "label.expiringBonus.create.daysSinceLastDeposited",
        type: "conditional",
      },
      {
        name: "DAYS_SINCE_LAST_PLAYED",
        label: "label.expiringBonus.create.daysSinceLastPlayed",
        type: "conditional",
      },
      {
        name: "PLAYER_STATUS",
        label: "label.expiringBonus.create.playerStatus",
        type: "select",
        options: [
          {
            label: "label.status.gameAccountstatus_1",
            value: "1",
          },
          {
            label: "label.status.gameAccountstatus_2",
            value: "2",
          },
          {
            label: "label.status.gameAccountstatus_3",
            value: "3",
          },
          {
            label: "label.status.gameAccountstatus_4",
            value: "4",
          },
          {
            label: "label.status.gameAccountstatus_5",
            value: "5",
          },
          {
            label: "label.status.gameAccountstatus_6",
            value: "6",
          },
        ],
      },
    ],
    data: [],
    idKey: "player.gameAccount",
    columns: [
      {
        field: "player.gameAccount",
        title: "label.expiringBonus.create.gameAccount",
        nowrap: false,
      },
      {
        field: "totalBonusAmount",
        title: "label.expiringBonus.create.totalAvailableBonus",
      },
      {
        field: "currency",
        title: "label.expiringBonus.currency",
        nowrap: false,
        render: (rowData) => {
          if (rowData["currency"]) {
            return (
              <CurrencyBadge currency={rowData["currency"]} justify="center" />
            );
          }
        },
      },
      {
        field: "daysSinceLastLogged",
        title: "label.expiringBonus.create.daysSinceLastLogged",
      },
      {
        field: "daysSinceLastPlayed",
        title: "label.expiringBonus.create.daysSinceLastPlayed",
      },
      {
        field: "daysSinceLastDeposited",
        title: "label.expiringBonus.create.daysSinceLastDeposited",
      },
      {
        field: "playerStatus",
        title: "label.expiringBonus.create.playerStatus",
        nowrap: false,
        render: (rowData) => {
          if (rowData["playerStatus"]) {
            const statusMapping = getPlayerStatusMapping(
              rowData["playerStatus"]
            );
            return (
              <BadgeStatus
                id={statusMapping.id}
                label={statusMapping.label}
                justify="center"
              />
            );
          }
        },
      },
    ],
  },
};
const ExpiringBonusReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPIRING_BONUS_SEARCH_SUCCESS: {
      return {
        ...state,
        expiringBonusSearch: {
          ...state.expiringBonusSearch,
          uploads: action.payload,
        },
      };
    }
    case EXPIRING_BONUS_SEARCH_FAILURE: {
      return {
        ...state,
        expiringBonusSearch: { ...state.expiringBonusSearch, uploads: null },
      };
    }
    case EXPIRING_BONUS_VIEW_SUCCESS: {
      return {
        ...state,
        expiringBonusView: {
          ...state.expiringBonusView,
          uploads: action.payload,
        },
      };
    }
    case EXPIRING_BONUS_VIEW_FAILURE: {
      return {
        ...state,
        expiringBonusView: { ...state.expiringBonusView, uploads: null },
      };
    }
    case EXPIRING_BONUS_PLAYER_LIST_SUCCESS: {
      return {
        ...state,
        expiringBonusCreate: {
          ...state.expiringBonusCreate,
          data: action.payload,
        },
      };
    }
    case EXPIRING_BONUS_PLAYER_LIST_FAILURE: {
      return {
        ...state,
        expiringBonusCreate: { ...state.expiringBonusCreate, data: [] },
      };
    }
    default:
      return state;
  }
};

export default ExpiringBonusReducer;
