import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  CHANNELS_FAILURE,
  CHANNELS_REQUEST,
  CHANNELS_SUCCESS,
} from "../constants";

const channelsFetching = async () => {
  const response = await axios.get("/gov/api/rest/v1/channels");
  return response.data;
};

const loadChannels = function* (action) {
  let data = [];
  try {
    data = yield call(channelsFetching, action.payload);
    yield put({
      type: CHANNELS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LoadChannels", e);
    yield put({
      type: CHANNELS_FAILURE,
    });
  }
};

const ChannelsSaga = function* () {
  yield takeEvery(CHANNELS_REQUEST, loadChannels);
};

export default ChannelsSaga;
