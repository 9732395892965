import { Box, Button } from "@material-ui/core";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { startExportAll } from "../../store/export/actions";
import { enqueueSnackbar } from "../../store/snackbars/actions";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { FormField } from "../form";
import {
  getInitialValues,
  getRequiredValidation,
  getValidationByType,
  getValidationSchema,
  isFieldDisabled,
  isFieldHidden,
} from "../form/utils";

const labelPrefix = "label.exportTable.";

const ExportAllModalContent = ({
  columns,
  handleClose,
  totalElems,
  filters,
  exportAllRowsMaxVal,
  exportModalProps,
  onExportAllFn,
  title,
}) => {
  const t = useTranslator();
  const { exporting } = useSelector((state) => state.ExportReducer);
  const dispatch = useDispatch();

  const formFields = {
    includeFilters: {
      name: "includeFilters",
      type: "Switch",
    },
    exportType: {
      name: "exportType",
      type: "Select",
      hidden: (values) => {
        return !exportAllRowsMaxVal;
      },
      options: [
        {
          label: t({ needle: labelPrefix + "exportTableView" }),
          value: "EXPORT_TABLE_VIEW",
        },
        {
          label: t({ needle: labelPrefix + "exportMaxRecords" }),
          value: "EXPORT_ALL",
        },
      ],

      validation: getValidationByType("Select").when("exportAllRowsMaxVal", {
        is: (item) => {
          return exportAllRowsMaxVal;
        },
        then: getRequiredValidation(
          getValidationByType("Select"),
          "Select",
          labelPrefix,
          "exportType"
        ).test(
          "exportType",
          t({
            needle: "label.exportTable.error.tooManyRowsToExportError",
            variables: [exportAllRowsMaxVal],
          }),
          (value) => {
            return !(
              value === "EXPORT_ALL" && totalElems > exportAllRowsMaxVal
            );
          }
        ),
        otherwise: Yup.string(),
      }),
    },
    fileFormat: {
      name: "fileFormat",
      type: "Select",
      options: [
        {
          label: t({ needle: labelPrefix + "exportCsv" }),
          value: "CSV",
        },
        {
          label: t({ needle: labelPrefix + "exportExcel" }),
          value: "EXCEL",
        },
        {
          label: t({ needle: labelPrefix + "exportPDF" }),
          value: "PDF",
        },
      ],
      validation: getRequiredValidation(
        getValidationByType("Select"),
        "Select",
        labelPrefix,
        "fileFormat"
      ),
    },
    exportColumns: {
      name: "exportColumns",
      type: "AutoComplete",
      multiple: true,
      initialValue: [],
      selectAllOption: true,
      options: columns.map((c) => ({
        value: c.field,
        label: t({ needle: c.title }),
      })),
      validation: getRequiredValidation(
        getValidationByType("Select"),
        "Select",
        labelPrefix,
        "exportColumns"
      ),
    },
  };

  const initialValues = getInitialValues(formFields);

  const validationSchema = getValidationSchema(labelPrefix, formFields);

  function _handleSubmit(values) {
    values.exportColumns = columns
      .filter((c) => {
        return values.exportColumns.includes(c.field);
      })
      .map((it) => ({ ...it, title: t({ needle: it.title }) }));

    values.exportType = !exportAllRowsMaxVal
      ? "EXPORT_TABLE_VIEW"
      : values.exportType;
    if (!exporting || values.exportType === "EXPORT_TABLE_VIEW") {
      if (values.exportType === "EXPORT_TABLE_VIEW") {
        exportModalProps.handleExport({
          ...values,
          localSearch: exportModalProps?.searchText,
          tableData: exportModalProps?.data,
        });
      } else {
        dispatch(
          startExportAll({
            ...values,
            getData: onExportAllFn,
            filters,
            title: title,
          })
        );
        handleClose();
      }
    } else {
      dispatch(
        enqueueSnackbar({
          message: {
            needle: labelPrefix + "error.exportInProgressError",
          },
          options: {
            variant: "error",
            autoHideDuration: 8000,
          },
        })
      );
    }
    handleClose();
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        return validateYupSchema(values, validationSchema, false, values)
          .then(() => ({}))
          .catch((err) => {
            return yupToFormErrors(err);
          });
      }}
      onSubmit={_handleSubmit}
      onReset={() => {}}
    >
      {({ values }) => {
        return (
          <Form>
            <Box p={2}>
              {Object.keys(formFields)
                .filter(
                  (fieldName) => !isFieldHidden(formFields[fieldName], values)
                )
                .map((fieldName) => {
                  var field = { ...formFields[fieldName] };
                  field.disabled = isFieldDisabled(field, values);
                  field.label = t({
                    needle: labelPrefix + field.name,
                  });
                  if (
                    field.type === "Select" ||
                    field.type === "AutoComplete"
                  ) {
                    field.valueKey = "value";
                    field.labelKey = "label";
                  }

                  return (
                    <Box key={fieldName}>
                      <FormField fullWidth {...field} />
                    </Box>
                  );
                })}
            </Box>
            <Box flex={1} display={"flex"} justifyContent={"flex-end"} p={2}>
              <Box ml={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {t({ needle: "button.close" })}
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t({ needle: "button.confirm" })}
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExportAllModalContent;
