import { LOCATIONS_ADMIN_LIST_REQUEST } from "../constants";
export const getLocationsList = (page, pageSize, terms, add) => {
  return {
    type: LOCATIONS_ADMIN_LIST_REQUEST,
    page,
    pageSize,
    terms,
    add,
  };
};
