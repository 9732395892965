import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { Pagination as MuiPagination } from "@material-ui/lab";
import PaginationItem from "@material-ui/lab/PaginationItem";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Translate from "../Translator/Translate";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const getIcon = (icons, type) => {
  let icon;
  if (type === "first") {
    icon = icons.FirstPage;
  }
  if (type === "last") {
    icon = icons.LastPage;
  }
  if (type === "previous") {
    icon = icons.PreviousPage;
  }
  if (type === "next") {
    icon = icons.PreviousPage;
  }
  return icon;
};

const PaginationActions = ({
  isLoading,
  onChangePage,
  page,
  className,
  count,
  showFirstLastPageButtons,
  icons,
}) => {
  return (
    <MuiPagination
      disabled={isLoading}
      className={className}
      count={count}
      onChange={(event, page) => onChangePage(event, page - 1)}
      page={page + 1}
      showFirstButton={showFirstLastPageButtons}
      showLastButton={showFirstLastPageButtons}
      shape="rounded"
      size={"small"}
      renderItem={(item) => (
        <PaginationItem
          disabled={isLoading}
          {...item}
          className={item.type}
          component={getIcon(icons, item.type)}
        />
      )}
    />
  );
};

const MobilePaginationActions = ({ isLoading, count, page, onChangePage }) => {
  const handlePageChange = (event) => {
    const value = event.target.value;
    onChangePage(event, value);
  };

  return (
    <>
      <Button
        variant={"text"}
        color={"primary"}
        className={"buttonPrev"}
        disabled={isLoading || page === 0}
        onClick={(event) => onChangePage(event, page - 1)}
      >
        <Translate needle={"button.prev"} />
      </Button>
      <div className="mobileSpacer first" />
      <Select
        onChange={handlePageChange}
        value={page}
        disabled={isLoading}
        className={
          "MuiTablePagination-input MuiTablePagination-selectRoot pageSelect"
        }
      >
        {Array(count)
          .fill()
          .map((_, it) => (
            <MenuItem key={it} value={it}>
              {it + 1}
            </MenuItem>
          ))}
      </Select>
      <div className="mobileSpacer second" />
      <Button
        variant={"text"}
        color={"primary"}
        className={"buttonNext"}
        disabled={isLoading || page === count - 1}
        onClick={(event) => onChangePage(event, page + 1)}
      >
        <Translate needle={"button.next"} />
      </Button>
    </>
  );
};

const Pagination = ({
  isLoading,
  tablet = false,
  icons,
  showFirstLastPageButtons,
  ...props
}) => {
  const classes = useStyle();
  const count = Math.ceil(props.count / props.rowsPerPage);

  const getActionsComponent = (actionProps) => {
    const paginationActionsProps = {
      count,
      icons,
      showFirstLastPageButtons,
      isLoading,
    };

    return tablet ? (
      <MobilePaginationActions {...actionProps} {...paginationActionsProps} />
    ) : (
      <PaginationActions {...actionProps} {...paginationActionsProps} />
    );
  };

  const mobileClasses = tablet
    ? {
        root: clsx(classes.mobile, props.classes.root),
        caption: classes.mobileCaption,
        selectRoot: classes.mobileSelectRoot,
        spacer: classes.mobileSpacer,
      }
    : { root: props.classes.root };

  return (
    <TablePagination
      {...props}
      classes={mobileClasses}
      SelectProps={{
        disabled: isLoading,
      }}
      ActionsComponent={getActionsComponent}
    />
  );
};

const useStyle = makeStyles((theme) => {
  return {
    mobile: {
      "& .mobileSpacer": {
        flex: "1 auto",
        "&.first": {
          order: 2,
        },
        "&.second": {
          order: 4,
        },
      },
      "& .pageSelect": {
        marginLeft: 0,
        order: 3,
        minWidth: theme.spacing(10),
        color: theme.palette.primary.main,
        "& .MuiSelect-selectMenu": {
          fontWeight: theme.typography.fontWeightBold,
          textAlign: "center",
        },
        "&.Mui-disabled": {
          color: theme.palette.primary.main.concat("50"),
        },
      },
      "& .buttonNext": {
        fontWeight: theme.typography.fontWeightBold,
        order: 5,
      },
      "& .buttonPrev": {
        fontWeight: theme.typography.fontWeightBold,
        order: 1,
      },
    },
    mobileCaption: {
      display: "none",
    },
    mobileSelectRoot: {
      "&:not(.pageSelect)": {
        display: "none",
      },
    },
    mobileSpacer: {
      display: "none",
    },
  };
});

export default Pagination;
