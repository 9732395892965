export const GET_PLAYER_LIMITS = {
    REQUEST: "get-playerlimits-request",
    SUCCESS: "get-playerlimits-success",
    FAILURE: "get-playerlimits-failure"
};

export const UPDATE_PLAYER_LIMITS = {
    REQUEST: "update-playerlimits-request",
    SUCCESS: "update-playerlimits-success",
    FAILURE: "update-playerlimits-failure"
}

export const CHANGE_PLAYER_LIMITS_STATUS = {
    REQUEST: "changestatus-playerlimits-request",
    SUCCESS: "changestatus-playerlimits-success",
    FAILURE: "changestatus-playerlimits-failure"
}

export const GET_PLAYER_LIMIT_HISTORY = {
    REQUEST: "get-playerlimithistory-request",
    SUCCESS: "get-playerlimithistory-success",
    FAILURE: "get-playerlimithistory-failure"
}

export const PLAYER_LIMIT_PREFIX = "label.individualplayer.responsibleGame.playerLimits";

export const COLUMN_PREFIX = PLAYER_LIMIT_PREFIX + ".tableColumns.";
export const HISTORY_COLUMN_PREFIX = PLAYER_LIMIT_PREFIX + ".history.tableColumns.";

export const COOLOFF_MESSAGE_PREFIX = PLAYER_LIMIT_PREFIX + ".message.cooloff.";

const getPlayerLimits = (
    contractId,
    brand,
    partner,
    group
) => {
    return {
        type: GET_PLAYER_LIMITS.REQUEST,
        contractId,
        brand,
        partner,
        group
    };
};

const updatePlayerLimits = (
    contractId,
    brand,
    partner,
    limits,
    group
) => {
    return {
        type: UPDATE_PLAYER_LIMITS.REQUEST,
        contractId,
        brand,
        partner,
        limits,
        group
    };
};

const changeLimitStatus = (
    contractId,
    brand,
    partner,
    limit,
    group
) => {
    return {
        type: CHANGE_PLAYER_LIMITS_STATUS.REQUEST,
        contractId,
        brand,
        partner,
        limit,
        group
    };
};

const getPlayerLimitHistory = (
    brand,
    partner,
    contractId,
    limit
) => {
    return {
        type: GET_PLAYER_LIMIT_HISTORY.REQUEST,
        contractId,
        brand,
        partner,
        limit
    };
}


export {
    getPlayerLimits,
    updatePlayerLimits,
    changeLimitStatus,
    getPlayerLimitHistory
};
