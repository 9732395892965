import axios from "axios";

class BlocklistService {
  async addRemoveBlock(contractId, brand, partner, note, isBlacklisted) {
    let url = `/gov/api/rest/v1/orc/blacklist/${contractId}`;
    url += isBlacklisted ? "/remove" : "/add";

    const response = await axios({
      method: isBlacklisted ? "delete" : "post",
      url,
      data: {
        reason: note,
      },
    });

    if (!response || response.status !== 204) {
      throw Error("Invalid response for add/remove block");
    }
  }

  async loadPlayerBlackListHistory(query, contractId) {
    const url = `/gov/api/rest/v1/orc/playerBlacklistHistory/${contractId}`;

    const response = await axios.get(url, {
      params: {
        page: query.page + 1,
        pageSize: query.pageSize,
        sort: `${query.orderBy.field} ${query.orderBy.order}`,
      },
    });
    if (response && response.data) {
      return {
        data: response.data.collection,
        page: query.page,
        totalCount: response.data.totalCount,
      };
    }
    throw Error("Error getting tasks by ID");
  }

  async checkPlayerBlackListStatus(contractId) {
    const url = `/gov/api/rest/v1/orc/checkPlayerIsInBlackList/${contractId}`;
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data.blacklist;
    }
    throw Error("Error getting checkPlayerIsInBlackList");
  }
}

const blocklistService = new BlocklistService();
export default blocklistService;
