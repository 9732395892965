import { orderBy } from "lodash";
import {
  COMMUNICATION_HISTORY_REQUEST,
  COMMUNICATION_HISTORY_DETAILS_REQUEST,
} from "../../../constants";

export const getComunicationHistory = (
  contractId,
  page,
  size,
  orderBy
) => {
  return {
    type: COMMUNICATION_HISTORY_REQUEST,
    contractId,
    page,
    size,
    orderBy
  };
};

export const getComunicationDetails = (
  extNotificationKey,
  extContactKey,
  brand,
  partner,
  channelName
) => {
  return {
    type: COMMUNICATION_HISTORY_DETAILS_REQUEST,
    extNotificationKey,
    extContactKey,
    brand,
    partner,
    channelName,
  };
};
