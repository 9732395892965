import React from "react";
import BadgeStatus from "./BadgeStatus";

const yesBadge = {
  id: "2",
  label: "yes",
};
const noBadge = {
  id: "6",
  label: "no",
};

const yesNegativeBadge = {
  id: "7",
  label: "yes",
};

const YesNoBadge = ({ value, justify = "flex-start", negativeYes }) => {
  const badgeConf =
    value === true || value === "true"
      ? negativeYes
        ? yesNegativeBadge
        : yesBadge
      : noBadge;
  return <BadgeStatus {...badgeConf} justify={justify} />;
};

export default YesNoBadge;
