import { TASK_TYPES_SUCCESS, TASK_TYPES_FAILURE } from "../constants";

const INITIAL_STATE = {
  tasks: [],
};

const TaskTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TASK_TYPES_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      };

    case TASK_TYPES_FAILURE: {
      return {
        ...state,
        tasks: null,
      };
    }

    default:
      return state;
  }
};

export default TaskTypesReducer;
