import axios from "axios";

class PlayerFriendsService {
  async loadPlayerFriends(brand, partner, contractId) {
    let url = "/gov/api/rest/v2/raf/referFriends";
    //"
    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
        referrerGameAccount: contractId,
        friendName: "",
        friendEmailAddress: "",
        friendGameAccount: "",
        referrerName: "",
        referrerEmailAddress: "",
        campaignId: 0,
        promoCodeId: "",
        friendSendMailDateFrom: "",
        friendSendMailDateTo: "",
        friendRegistrationDateFrom: "",
        friendRegistrationDateTo: "",
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for player friends");
  }
}

const playerFriendsService = new PlayerFriendsService();
export default playerFriendsService;
