import React from "react";
import MaterialTabs from "@material-ui/core/Tabs";
import DynamicOverflow from "../TabRoute/DynamicOverflow";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Popover from "@material-ui/core/Popover";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  dropdownButton: {
    marginLeft: "auto",
    minWidth: 0,
    padding: "0 2px",
    borderRight: "none",
    "&.Mui-selected": {
      background: "none",
      color: "inherit",
    },
  },
}));
const OverflowTabs = ({ children, onChange, ...props }) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [kids, setKids] = React.useState(React.Children.toArray(children));

  const getTabs = ({ tabRef }) => {
    return React.Children.map(kids, (child, index) => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ref: tabRef, id: index });
      }
      return child;
    });
  };

  const toggleDropdown = (event) => {
    if (anchorEl == null) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DynamicOverflow list={getTabs}>
      {({ visibleElements, overflowElements, containerRef }) => {
        return (
          <div ref={containerRef}>
            <MaterialTabs {...props} onChange={onChange}>
              {visibleElements}
              {overflowElements.length > 0 && (
                <Tab
                  value={props.value}
                  selected={false}
                  className={clsx(classes.dropdownButton, "MuiTab-dropdown")}
                  onClick={(event) => toggleDropdown(event)}
                  icon={<DoubleArrowIcon />}
                />
              )}
            </MaterialTabs>
            {overflowElements.length > 0 && (
              <Popover
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MaterialTabs
                  classes={{
                    flexContainer: classes.verticalFlexContainer,
                  }}
                  orientation={"vertical"}
                  value={false}
                >
                  {React.Children.map(overflowElements, (child, index) => {
                    // checking isValidElement is the safe way and avoids a typescript error too
                    if (React.isValidElement(child)) {
                      return React.cloneElement(child, {
                        onClick: (event) => {
                          let newKids = [...kids];
                          let fromIndex = child.props.id;
                          let toIndex = visibleElements.length - 1;
                          newKids.splice(
                            toIndex,
                            0,
                            newKids.splice(fromIndex, 1)[0]
                          );
                          setKids(newKids);
                          onChange({}, child.props.value);
                          handleClose();
                        },
                      });
                    }
                    return child;
                  })}
                </MaterialTabs>
              </Popover>
            )}
          </div>
        );
      }}
    </DynamicOverflow>
  );
};
const Tabs = ({ overflow, children, ...props }) => {
  return overflow ? (
    <OverflowTabs {...props}>{children}</OverflowTabs>
  ) : (
    <MaterialTabs {...props}>{children}</MaterialTabs>
  );
};

export default Tabs;
