import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { convertFromMillsToHoursMinSecs } from "../../utils";

const TimeProgressBar = ({ value, limit }) => {
  return (
    <Box component="div">
      <Box display="flex" justifyContent="center">
        HH:MM:SS&nbsp;
        <span>
          <Box display={"flex"}>{convertFromMillsToHoursMinSecs(value)}</Box>
        </span>
        <span>/</span>
        <span style={{ fontWeight: "bold" }}>
          <Box display={"flex"}>{convertFromMillsToHoursMinSecs(limit)}</Box>
        </span>
      </Box>
      <LinearProgress
        variant="buffer"
        value={(value / limit) * 100}
        valueBuffer={100}
        style={{ height: 10, borderRadius: 6 }}
      />
    </Box>
  );
};

export default TimeProgressBar;
