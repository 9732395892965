import { select, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  ADD_TEMPLATE_FAILURE,
  ADD_TEMPLATE_REQUEST,
  ADD_TEMPLATE_SUCCESS,
  ALL_LANGUAGES_FAILURE,
  ALL_LANGUAGES_REQUEST,
  ALL_LANGUAGES_SUCCESS,
  DELETE_CHANNEL_FAILURE,
  DELETE_CHANNEL_REQUEST,
  DELETE_CHANNEL_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  EDIT_CHANNEL_REQUEST,
  EDIT_CHANNEL_SUCCESS,
  GET_CHANNEL_FAILURE,
  GET_CHANNEL_REQUEST,
  GET_CHANNEL_SUCCESS,
  HEADER_FOOTER_FAILURE,
  HEADER_FOOTER_REQUEST,
  HEADER_FOOTER_SUCCESS,
  LANGUAGES_CHANNEL_FAILURE,
  LANGUAGES_CHANNEL_REQUEST,
  LANGUAGES_CHANNEL_SUCCESS,
  MANAGE_TEMPLATE_FAILURE,
  MANAGE_TEMPLATE_REQUEST,
  MANAGE_TEMPLATE_SUCCESS,
  MANAGE_TEMPLATE_VARIABLES_FAILURE,
  MANAGE_TEMPLATE_VARIABLES_REQUEST,
  MANAGE_TEMPLATE_VARIABLES_SUCCESS,
  SAVE_CHANNEL_REQUEST,
  SAVE_CHANNEL_SUCCESS,
  TEMPLATE_LIST_RELOAD,
  VERSION_CHANNEL_DETAILS_FAILURE,
  VERSION_CHANNEL_DETAILS_REQUEST,
  VERSION_CHANNEL_DETAILS_SUCCESS,
  VERSION_FAILURE,
  VERSION_REQUEST,
  VERSION_SUCCESS,
} from "../constants";
import { enqueueSnackbar } from "../snackbars/actions";
import manageTemplateService from "./manage-template-service";

const getTemplates = function* (action) {
  try {
    const data = yield call(
      manageTemplateService.getTemplates,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: MANAGE_TEMPLATE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("TemplateError", e);
    yield put({
      type: MANAGE_TEMPLATE_FAILURE,
    });
  }
};

const getDetails = function* (action) {
  try {
    const data = yield call(
      manageTemplateService.getDetails,
      action.brand,
      action.partner,
      action.templateName,
      action.channelName,
      action.language,
      action.contentTypeId
    );
    yield put({
      type: GET_CHANNEL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("TemplateError", e);
    yield put({
      type: GET_CHANNEL_FAILURE,
    });
  }
};

const getTemplateVariables = function* (action) {
  try {
    const data = yield call(
      manageTemplateService.getTemplateVariables,
      action.templateName
    );
    yield put({
      type: MANAGE_TEMPLATE_VARIABLES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("TemplateError", e);
    yield put({
      type: MANAGE_TEMPLATE_VARIABLES_FAILURE,
    });
  }
};

const getLanguages = function* (action) {
  try {
    const data = yield call(
      manageTemplateService.getLanguages,
      action.templateName
    );
    yield put({
      type: LANGUAGES_CHANNEL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LanguagesError", e);
    yield put({
      type: LANGUAGES_CHANNEL_FAILURE,
    });
  }
};

const getVersionChannelDetail = function* (action) {
  try {
    const data = yield call(
      manageTemplateService.getDetails,
      action.brand,
      action.partner,
      action.templateName,
      action.channelName,
      action.language,
      action.contentTypeId
    );
    yield put({
      type: VERSION_CHANNEL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("detailsError", e);
    yield put({
      type: VERSION_CHANNEL_DETAILS_FAILURE,
    });
  }
};

const getVersion = function* (action) {
  try {
    const data = yield call(
      manageTemplateService.getVersion,
      action.contentTypeId,
      action.data,
      action.brand,
      action.partner,
      action.templateName
    );
    yield put({
      type: VERSION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LanguagesError", e);
    yield put({
      type: VERSION_FAILURE,
    });
  }
};

const loadHeaderFooter = function* (action) {
  try {
    const data = yield call(
      manageTemplateService.loadHeaderFooter,
      action.brand,
      action.partner
    );
    yield put({
      type: HEADER_FOOTER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("LanguagesError", e);
    yield put({
      type: HEADER_FOOTER_FAILURE,
    });
  }
};

const templateListReload = function* () {
  const lastRequest = yield select(
    (state) => state.ManageTemplateReducer.lastRequest
  );
  if (lastRequest) {
    yield put(lastRequest);
  }
};

const deleteTemplate = function* (action) {
  try {
    yield call(
      manageTemplateService.deleteTemplate,
      action.brandId,
      action.partnerId,
      action.templateName
    );
    yield put({
      type: DELETE_TEMPLATE_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.notifications.template.delete.success",
        },
        options: {
          key: "deleteTemplate",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: TEMPLATE_LIST_RELOAD,
    });
  } catch (e) {
    console.error("TemplateError", e);
    yield put({
      type: DELETE_TEMPLATE_FAILURE,
    });
  }
};

const deleteChannel = function* (action) {
  try {
    yield call(
      manageTemplateService.deleteChannel,
      action.brand,
      action.partner,
      action.data
    );
    yield put({
      type: DELETE_CHANNEL_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.notifications.template.deleteChannel.success",
        },
        options: {
          key: "deleteChannel",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: TEMPLATE_LIST_RELOAD,
    });
  } catch (e) {
    console.error("TemplateError", e);
    yield put({
      type: DELETE_CHANNEL_FAILURE,
    });
  }
};

const addTemplate = function* (action) {
  try {
    yield call(
      manageTemplateService.addTemplate,
      action.brand,
      action.partner,
      action.data
    );
    yield put({
      type: ADD_TEMPLATE_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.notifications.template.templateAdd.success",
        },
        options: {
          key: "addTemplate",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: TEMPLATE_LIST_RELOAD,
    });
  } catch (e) {
    console.error("TemplateError", e);
    yield put({
      type: ADD_TEMPLATE_FAILURE,
    });
  }
};

const saveChannel = function* (action) {
  try {
    yield call(manageTemplateService.saveChannel, action.values);
    yield put({
      type: SAVE_CHANNEL_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.notifications.channel.AddChannel.success",
        },
        options: {
          key: "addChannel",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: TEMPLATE_LIST_RELOAD,
    });

    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("ChannelError", e);
    action.cb && typeof action.cb === "function" && action.cb();
  }
};

const getAllLanguages = function* () {
  try {
    const data = yield call(manageTemplateService.getAllLanguages);
    yield put({
      type: ALL_LANGUAGES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("allLanguagesError", e);
    yield put({
      type: ALL_LANGUAGES_FAILURE,
    });
  }
};

const editChannel = function* (action) {
  try {
    yield call(manageTemplateService.editChannel, action.body);
    yield put({
      type: EDIT_CHANNEL_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.notifications.channel.successEdit",
        },
        options: {
          key: "successEdit",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: TEMPLATE_LIST_RELOAD,
    });

    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("ChannelError", e);
    action.cb && typeof action.cb === "function" && action.cb();
  }
};

const ManageTemplateSaga = function* ManageTemplateSaga() {
  yield takeEvery(MANAGE_TEMPLATE_REQUEST, getTemplates);
  yield takeEvery(DELETE_TEMPLATE_REQUEST, deleteTemplate);
  yield takeEvery(DELETE_CHANNEL_REQUEST, deleteChannel);
  yield takeEvery(GET_CHANNEL_REQUEST, getDetails);
  yield takeEvery(ADD_TEMPLATE_REQUEST, addTemplate);
  yield takeEvery(LANGUAGES_CHANNEL_REQUEST, getLanguages);
  yield takeEvery(SAVE_CHANNEL_REQUEST, saveChannel);
  yield takeEvery(VERSION_REQUEST, getVersion);
  yield takeEvery(MANAGE_TEMPLATE_VARIABLES_REQUEST, getTemplateVariables);
  yield takeEvery(HEADER_FOOTER_REQUEST, loadHeaderFooter);
  yield takeEvery(VERSION_CHANNEL_DETAILS_REQUEST, getVersionChannelDetail);
  yield takeEvery(ALL_LANGUAGES_REQUEST, getAllLanguages);
  yield takeEvery(EDIT_CHANNEL_REQUEST, editChannel);
  yield takeLatest(TEMPLATE_LIST_RELOAD, templateListReload);
};

export default ManageTemplateSaga;
