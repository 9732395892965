import React, { useState } from "react";
import InputField from "./input-field";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { useLocale } from "../../utilities/hooks/useLocale";
import { useSession } from "../../utilities/hooks/useSession";

export const NumberFormatCustom = ({
  inputRef,
  onChange,
  isInteger,
  decimalScale,
  isDecimal = false,
  ...other
}) => {
  const { decimalSeparator } = useLocale();
  const { divisorAmount } = useSession();
  const initialValue =
    other.value !== ""
      ? !isDecimal
        ? other.value / divisorAmount
        : other.value
      : null;
  const [valueFE, setValueFE] = useState(initialValue);

  React.useEffect(() => {
    setValueFE(initialValue);
    // eslint-disable-next-line
  }, [initialValue]);

  return (
    <NumberFormat
      {...other}
      decimalScale={decimalScale}
      getInputRef={inputRef}
      onValueChange={(values) => {
        setValueFE(values.value);
        if (!isDecimal) {
          values.value = (values.value * divisorAmount).toFixed();
        } else {
          if (values.value) {
            values.value = parseFloat(values.value).toFixed(2);
          }
        }
        onChange({
          target: {
            name: other.name,
            value: values.value || "",
          },
        });
      }}
      value={valueFE ? valueFE : ""}
      onKeyDown={(e) => {
        const charCode = e.which ? e.which : e.keyCode;
        const valueLength = e.target.value.length;
        if (charCode >= 37 && charCode <= 40) {
          return true;
        }
        if (
          valueLength < 15 &&
          (charCode < 31 ||
            (charCode >= 48 && charCode <= 57) ||
            (charCode >= 96 && charCode <= 105))
        ) {
          return true;
        }
        if (charCode === 8 || charCode === 46) {
          return true;
        }
        if (!isInteger && charCode === 190 && !e.target.value.includes(".")) {
          return true;
        }
        e.preventDefault();
        return false;
      }}
      thousandSeparator={false}
      decimalSeparator={decimalSeparator}
      isNumericString
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const IntegerNumberFormat = (props) => {
  return <NumberFormatCustom isInteger={true} {...props} />;
};

const AmountField = ({ isInteger, ...props }) => {
  const t = useTranslator();
  const inputComp = isInteger ? IntegerNumberFormat : NumberFormatCustom;
  return (
    <InputField
      {...props}
      placeholder={t({ needle: "label.placeholders.amount" })}
      InputProps={{
        inputComponent: inputComp,
      }}
    />
  );
};

export default AmountField;
