import React from "react";
import Moment from "react-moment";
import {
  BONUS_ABUSER_HISTORY_SUCCESS,
  BONUS_ABUSER_HISTORY_FAILURE,
} from "../../../../constants";

const initialState = {
  bonusAbuserHistory: [],
  loading: true,
  columns: [
    {
      field: "operationExecutionType",
      title: "label.individualplayer.bonusAbuserHistory.operationExecutionType",
      default: true,
    },
    {
      field: "executionDate",
      title: "label.individualplayer.bonusAbuserHistory.executionDate",
      default: true,
      render: (rowData) => {
        if (rowData["executionDate"]) {
          return <Moment format="L LT">{rowData["executionDate"]}</Moment>;
        }
      },
    },
    {
      field: "operatorExecutor",
      title: "label.individualplayer.bonusAbuserHistory.operatorExecutor",
    },
    {
      field: "note",
      title: "label.individualplayer.bonusAbuserHistory.note",
    },
  ],
};

const BonusAbuserHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case BONUS_ABUSER_HISTORY_SUCCESS: {
      return {
        ...state,
        bonusAbuserHistory: action.payload,
        loading: false,
      };
    }
    case BONUS_ABUSER_HISTORY_FAILURE: {
      return {
        ...state,
        bonusAbuserHistory: null,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default BonusAbuserHistoryReducer;
