import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  LIMIT_CONFIGURATION_FAILURE,
  LIMIT_CONFIGURATION_REQUEST,
  LIMIT_CONFIGURATION_SUCCESS,
  LIMIT_CONFIG_EDIT_FAILURE,
  LIMIT_CONFIG_EDIT_REQUEST,
  LIMIT_CONFIG_EDIT_SUCCESS,
  LIMIT_CONFIG_HISTORY_FAILURE,
  LIMIT_CONFIG_HISTORY_REQUEST,
  LIMIT_CONFIG_HISTORY_SUCCESS,
} from "../../../constants";
import { enqueueSnackbar } from "../../../snackbars/actions";
import limitConfigurationService from "./limit-configuration-service";

const getLimitConfiguration = function* (action) {
  try {
    const data = yield call(
      limitConfigurationService.getLimitConfiguration,
      action.brand,
      action.partner,
      action.gameDomain
    );
    yield put({
      type: LIMIT_CONFIGURATION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("limitConfigurations", e);
    yield put({
      type: LIMIT_CONFIGURATION_FAILURE,
    });
  }
};

const getLimitConfigurationHistory = function* (action) {
  try {
    const data = yield call(
      limitConfigurationService.getLimitConfigurationHistory,
      action.brand,
      action.partner,
      action.gameDomain,
      action.limitPeriod
    );
    yield put({
      type: LIMIT_CONFIG_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("limitConfigurationsHistory", e);
    yield put({
      type: LIMIT_CONFIG_HISTORY_FAILURE,
    });
  }
};

const editLimitConfiguration = function* (action) {
  try {
    yield call(
      limitConfigurationService.editLimitConfiguration,
      action.brand,
      action.partner,
      action.bodyRequest
    );

    yield put({
      type: LIMIT_CONFIG_EDIT_SUCCESS,
    });

    yield put({
      type: LIMIT_CONFIGURATION_REQUEST,
      brand: action.brand,
      partner: action.partner,
      gameDomain: null,
    });

    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.limitConfiguration",
          variables: [action.bodyRequest.gameDomainDesc],
        },
        options: {
          key: "limitConfiguration_success",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("limitConfigurationsHistory", e);
    yield put({
      type: LIMIT_CONFIG_EDIT_FAILURE,
    });
  }
};

const LimitConfigurationSaga = function* () {
  yield takeLatest(LIMIT_CONFIGURATION_REQUEST, getLimitConfiguration);
  yield takeLatest(LIMIT_CONFIG_HISTORY_REQUEST, getLimitConfigurationHistory);
  yield takeLatest(LIMIT_CONFIG_EDIT_REQUEST, editLimitConfiguration);
};

export default LimitConfigurationSaga;
