import { takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { MODAL_CONFIG_REQUEST, MODAL_CONFIG_SUCCESS } from "../constants";

const getModal = async () => {
  const modal = await axios.get(process.env.PUBLIC_URL + "/modal-config.json");
  return modal.data;
};

const getModalConfig = function* (action) {
  const data = yield call(getModal);

  const modalConfig = data ? data.find((it) => it.id === action.id) : null;
  yield put({
    type: MODAL_CONFIG_SUCCESS,
    payload: modalConfig,
  });
};

const ModalConfigSaga = function* modalConfigSaga() {
  yield takeLatest(MODAL_CONFIG_REQUEST, getModalConfig);
};

export default ModalConfigSaga;
