import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import { Tooltip } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { isMobileOnly } from "react-device-detect";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoIcon from "@material-ui/icons/Info";
import {useTranslator} from "../../utilities/hooks/useTranslator";

const useStyle = makeStyles((theme) => ({
  tooltip: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary2,
    fontSize: "1rem",
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
}));

const FormTooltip = ({ ...rest }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyle();
  const Wrapper = isMobileOnly ? ClickAwayListener : React.Fragment;

  const t = useTranslator();
  const handleTooltipOpen = () => {
    setIsOpen(true);
  };

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  let tooltipProps = {};
  let fragmentProps = {};

  if (isMobileOnly) {
    tooltipProps = {
      disablePortal: true,
      onClose: handleTooltipClose,
      open: isOpen,
      disableFocusListener: true,
      disableHoverListener: true,
      disableTouchListener: true,
    };

    fragmentProps = {
      onClickAway: handleTooltipClose,
    };
  }

  const Icon = rest.type === "info" ? InfoIcon : HelpIcon;

  return (
    <Wrapper {...fragmentProps}>
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        {...rest}
        title={rest.title && typeof rest.title === "string" ? t({needle:rest.title}) : rest.title}
        {...tooltipProps}
      >
        <Icon classes={{ root: classes.icon }} fontSize={rest.fontSize} onClick={handleTooltipOpen} />
      </Tooltip>
    </Wrapper>
  );
};

FormTooltip.propTypes = {
  ...Tooltip.propTypes,
};

export default FormTooltip;
