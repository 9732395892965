import { Box, TextField, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { getCroppedImg } from "./canvas-utils";

const useStyle = makeStyles((theme) => ({
  cropContainer: {
    position: "relative",
    width: 200,
    height: 200,
    background: "#eaeaea",
    border: "2px solid #fff",
    [theme.breakpoints.up("sm")]: {
      height: 200,
      width: 200,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  slider: {
    width: 200,
    padding: "22px 0px",
    marginLeft: 16,
    flexDirection: "row",
    alignItems: "center",
    margin: "0 16px",
  },
  cropBox: {
    border: "2px solid #eee",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    margin: 8,
    width: "fit-content",
    background: "#fff",
  },
}));

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const CroopieField = ({
  isConditional = false,
  optional = true,
  label,
  tooltip,
  placeholder,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const t = useTranslator();
  const { setFieldValue } = useFormikContext();
  const inputFile = React.useRef("");
  const classes = useStyle();
  const [imageSrc, setImageSrc] = React.useState(field.value || "");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  React.useEffect(() => {
    if (croppedAreaPixels !== "" && imageSrc !== "") {
      setZoomChanges();
    }
  }, [croppedAreaPixels, imageSrc]);

  const plchld = placeholder
    ? typeof placeholder === "string"
      ? t({ needle: placeholder })
      : placeholder
    : null;

  const chooseFile = (event) => {
    inputFile.current.click();
  };

  React.useEffect(() => {
    if (
      field.value !== "" &&
      typeof field.value === "string" &&
      field.value.includes("base64") &&
      !meta.touched
    ) {
      setFieldValue(field.name, field.value);
    } else {
      setFieldValue(field.name, croppedImage);
    }
  }, [croppedImage]);

  React.useEffect(() => {
    if (field.value === "") {
      removeFile();
    }
  }, [zoom]);

  const handleDelete = () => {
    setFieldValue(field.name, "");
  };

  const removeFile = () => {
    handleDelete();
    setImageSrc("");
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedImage("");
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };

  const setZoomChanges = useCallback(
    async (e, zoom) => {
      try {
        const croppedImage = await getCroppedImg(
          false,
          field.name,
          imageSrc,
          croppedAreaPixels
        );
        setCroppedImage(croppedImage);
        helpers.setTouched(true, true);
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrc, croppedAreaPixels]
  );

  return (
    <>
      <Box className={classes.cropBox}>
        <Box className={classes.cropContainer}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={4 / 4}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
            objectFit={"cover"}
          />
        </Box>
        <Box className={classes.sliderContainer}>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Box>
      </Box>

      <TextField
        {...field}
        placeholder={plchld}
        value={""}
        type={"file"}
        onChange={(event) => {
          setFieldValue(field.name, event.currentTarget.files[0]);
          onFileChange(event);
        }}
        inputRef={inputFile}
        style={{ display: "none" }}
        accept="image/*"
      />

      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        paddingTop={2}
        margin={1}
        justifyContent="flex-end"
      >
        <Box mr={1}>
          {imageSrc !== "" && (
            <Button color="failure" onClick={removeFile}>
              {t({ needle: "button.removeFile" })}
            </Button>
          )}
        </Box>

        <Box>
          <Button color="primary" onClick={chooseFile}>
            {t({ needle: "button.chooseFile" })}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CroopieField;
