import { useSession } from "../../../../utilities/hooks/useSession";
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { Box } from "@material-ui/core";
import Translate from "../../../../Layouts/Translator/Translate";
import { connect } from "react-redux";
import SelectField from "../../../../Layouts/form/select-field";
import Button from "@material-ui/core/Button";
import { printCard } from "../../../../store/players/individual-player/club-card/actions";
import { useReasonsForActions } from "../../../../utilities/hooks/useReasonsForActions";
import { useLocale } from "../../../../utilities/hooks/useLocale";

const PrintCardForm = ({
  idCard,
  contractId,
  action,
  handleCloseModal,
  save,
}) => {
  const t = useTranslator();
  const { brandSelected, partnerSelected } = useSession();
  const { locale } = useLocale();
  const reason = useReasonsForActions(
    brandSelected,
    partnerSelected,
    action,
    locale
  );
  let initialValues = {
    reason: "",
    cardNumber: idCard,
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          reason: Yup.string().required(
            t({
              needle: "label.player.clubCardDetails.print.errorsDescription",
            })
          ),
        })}
        onSubmit={(fields) => {
          delete fields["_meta"];
          save(
            brandSelected,
            partnerSelected,
            fields,
            contractId,
            handleCloseModal
          );
        }}
        render={() => (
          <Form>
            <Box p={2} pt={0}>
              <span>Card Number: {idCard}</span>
            </Box>
            <Box p={2} pt={0}>
              <span>
                Do you want to print this card? Please choose the reason.
              </span>
            </Box>
            <Box p={2} pt={0}>
              <SelectField
                labelKey={"reasonDescription"}
                label={
                  <Translate
                    needle={"label.player.clubCardDetails.print.reasons"}
                  />
                }
                options={reason}
                name={"reason"}
                valueKey={"reasonDescription"}
                fullWidth
              />
            </Box>
            <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
              <Box mr={1}>
                <Button variant={"outlined"} onClick={() => handleCloseModal()}>
                  <Translate needle={"button.cancel"} />
                </Button>
              </Box>
              <Box>
                <Button color={"primary"} type="submit">
                  <Translate needle={"button.yes"} />{" "}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      />
    </>
  );
};

const mapDispatchToProps = {
  save: printCard,
};
const mapStateToProps = (state) => {};
export default connect(mapStateToProps, mapDispatchToProps)(PrintCardForm);
