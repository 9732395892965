import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// Import Stylesheet(s)
import { useTranslator } from "../../utilities/hooks/useTranslator";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Box, IconButton, Slide } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useTheme from "@material-ui/core/styles/useTheme";
import SpinnerButton from "../spinner-button/spinner-button";
import UnsafeHtml from "../unsafe-html";

const a11yProps = (title, description) => {
  const props = {};
  if (title) props["aria-labelledby"] = `alert-dialog-${title}`;
  if (description) props["aria-describedby"] = `alert-dialog-${description}`;
  return props;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({
  title,
  description,
  open,
  handleClose,
  actions,
  content,
  paddingContent,
  actionBorder,
  fullScreen,
  ...props
}) => {
  const t = useTranslator();
  const theme = useTheme();
  const paddingCont = paddingContent ? paddingContent : null;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      {...a11yProps(title, description)}
      {...props}
      fullScreen={fullScreen}
    >
      {title && (
        <DialogTitle
          id="alert-dialog-title"
          style={{
            backgroundColor: fullScreen && theme.palette.primary.main,
            color: fullScreen && theme.palette.background.paper,
          }}
        >
          <Box display="flex" alignItems={"center"}>
            <Box flexGrow={1} justifyContent="center">
              {title ? t({ needle: title }) : null}
            </Box>
            {fullScreen && handleClose && (
              <Box>
                <IconButton onClick={handleClose} color="inherit">
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Box>
            )}
          </Box>
        </DialogTitle>
      )}
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          padding: paddingCont,
        }}
      >
        {description && (
          <DialogContentText id="alert-dialog-description">
            <UnsafeHtml
              dangerouslySetInnerHTML={{ __html: t({ needle: description }) }}
            />
          </DialogContentText>
        )}
        {content}
      </DialogContent>
      {!fullScreen && (actions || handleClose) ? (
        <DialogActions
          style={{
            borderTop: actionBorder,
          }}
        >
          {actions && (
            <>
              {actions &&
                actions
                  .filter((it) => !it.hidden)
                  .map((action) => {
                    return action.spinner ? (
                      <SpinnerButton
                        loading={action.isSpinning}
                        key={action.label}
                        onClick={action.handler}
                        color={action.color || "primary"}
                        variant={action.variant || "contained"}
                      >
                        {t({ needle: action.label })}
                      </SpinnerButton>
                    ) : (
                      <Button
                        key={action.label}
                        onClick={action.handler}
                        color={
                          action.color ||
                          (action.variant === "outlined"
                            ? "default"
                            : "primary")
                        }
                        variant={action.variant || "contained"}
                        disabled={action.disabled}
                      >
                        {t({ needle: action.label })}
                      </Button>
                    );
                  })}
            </>
          )}
          {handleClose && (
            <Button onClick={handleClose} color="primary" variant="contained">
              {t({ needle: "button.close" })}
            </Button>
          )}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default Modal;
