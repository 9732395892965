import React from "react";

import { Box } from "@material-ui/core";
import ListPanel from "../../Layouts/Panel/ListPanel";
import Fieldset from "../../Layouts/form/fieldset";
import Translate from "../Translator/Translate";
import useTheme from "@material-ui/core/styles/useTheme";

const Versions = ({ versions }) => {
  const theme = useTheme();
  const logo =
    process.env.REACT_APP_IMAGEPATH +
    (theme.palette.type === "dark"
      ? "logo_darkmode@2x.png"
      : "logo_lightmode@2x.png");

  const removeReleaseVersion = (versions) => {
    if (versions != null) {
      delete versions.release_version;
    }
    return versions;
  };

  return (
    <>
      <Box display={"flex"}>
        <Box width={"50%"} px={2}>
          <img src={logo} width={"70%"} alt={process.env.REACT_APP_TITLE} />
        </Box>

        <Box
          width={"50%"}
          px={2}
          display="flex"
          flexDirection="column"
          alignItems={"center"}
          justifyContent={"center"}
          my={2}
          mr={2}
          bgcolor={"background.default"}
          color={"text.primary2"}
        >
          <Box fontSize={"14px"}>
            <Translate needle={"label.releaseVersion"} />
          </Box>
          <Box fontSize={"17px"} fontWeight={"fontWeightBold"}>
            {versions ? versions.release_version : null}
          </Box>
        </Box>
      </Box>
      <Box color={"text,primary2"} fontSize={15}>
        <Fieldset legend={"label.moduleVersion"} />
      </Box>
      <Box style={{ maxHeight: "400px", overflowY: "auto" }}>
        <ListPanel data={removeReleaseVersion(versions) || ""} />
      </Box>
    </>
  );
};

export default Versions;
