class BadgeStatusesService {
  getBadge(id) {
    const result = require("./statusColor.json");

    let def;
    let badge;
    result.forEach((it) => {
      if (it.default) def = it;
      if (it.id === id) badge = it;
    });
    return badge ? badge : def;
  }

  filterBadgeFromMapping(mapping, value) {
    let badgeId = 0;
    let badgeLabel = "unknown";
    const mappingFiltered = mapping.filter((it) => it["id"] === value);
    if (mappingFiltered && mappingFiltered.length > 0) {
      badgeId = mappingFiltered[0].badgeStatusId;
      badgeLabel = mappingFiltered[0].id.toLowerCase();
    }
    return { badgeId, badgeLabel };
  }
}

const badgeStatusesService = new BadgeStatusesService();

export default badgeStatusesService;
