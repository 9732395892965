import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import CustomIcon from "../CustomIcon/CustomIcon";
import badgeStatusesService from "../../utilities/badgeStatuses";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Box } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import LaunchIcon from "@material-ui/icons/Launch";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyle = makeStyles((theme) => ({
  root: {
    height: "28px",
  },
  label: {
    fontWeight: theme.typography.fontWeightSemiBold,
    textTransform: "capitalize",
    color: "white",
    ".highContrast &": {
      color: theme.palette.text.secondary2,
    },
  },
  tooltipDescription: {
    whiteSpace: "pre-line",
  },
  icon: {
    marginTop: theme.spacing(0.5),
    width: 20,
    height: 20,
    "& svg": {
      display: "block",
      width: "15px!important",
      height: "15px!important",
      color: "white",
    },
  },
  link: {
    color: "inherit",
    fontWeight: "bold",
  },
  onlyIcon: {
    width: 28,
    "& svg": {
      display: "block",
      width: "17px!important",
      height: "21px!important",
      color: "white",
    },
  },
  onlyIconLabel: {
    padding: 0,
  },
}));

const BadgeStatus = ({
  id,
  fullLabel,
  label = "unknown",
  justify = "flex-start",
  fullWidth = false,
  tooltip = false,
  tooltipDescription,
  tooltipLink,
}) => {
  const t = useTranslator();
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const badge = badgeStatusesService.getBadge(""+id);
  return (
    <Box display={"flex"} justifyContent={justify}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            style: {
              pointerEvents: "auto",
            },
          }}
          placement={"right"}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Box maxWidth={180} fontSize={12} lineHeight={"1.2rem"}>
              <Box mb={0.5} className={classes.label}>
                {t({
                  needle: "label.status.tooltipTitle",
                  variables: [`label.status.${label}`],
                })}
              </Box>
              <Box className={classes.tooltipDescription} mb={0.5}>
                {tooltipDescription != null &&
                  t({ needle: tooltipDescription })}
              </Box>
              {tooltipLink && (
                <Box>
                  <Link
                    href={tooltipLink != null ? tooltipLink : ""}
                    target="_blank"
                    className={classes.link}
                  >
                    {t({ needle: "label.status.learnMore" })}
                    <LaunchIcon
                      style={{ fontSize: 16, marginBottom: -2, marginLeft: 2 }}
                    />
                  </Link>
                </Box>
              )}
            </Box>
          }
        >
          <Chip
            classes={{
              root: classes.root,
              label: badge.onlyIcon ? classes.onlyIconLabel : classes.label,
            }}
            className={badge.isSecondary2 ? "highContrast" : ""}
            icon={
              badge.icon ? (
                <CustomIcon
                  className={badge.onlyIcon ? classes.onlyIcon : classes.icon}
                  icon={badge.icon}
                />
              ) : null
            }
            label={
              !badge.onlyIcon ? t({ needle: fullLabel || `label.status.${label}`, label: fullLabel || label }) : null
            }
            style={{
              backgroundColor: theme.palette.statusColors[badge.id],
              width: fullWidth ? "auto" : "100px",
            }}
            onClick={tooltip ? handleTooltipOpen : null}
          />
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default BadgeStatus;
