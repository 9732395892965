import { call, put } from "redux-saga/effects";
import {
  TICKETS_SEARCH_CRITERIA_SUCCESS,
  TICKETS_SEARCH_CRITERIA_FAILURE,
  TICKETS_SEARCH_CRITERIA_REQUEST,
  TICKETS_LIST_REQUEST,
  TICKETS_LIST_SUCCESS,
  TICKETS_LIST_FAILURE,
  TICKETS_DELETE_REQUEST,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import ticketService from "./tickets-service";

const loadSearchCriteria = function* (action) {
  try {
    const filters = yield call(
      ticketService.getFilterFields,
      action.brand,
      action.partner
    );
    yield put({
      type: TICKETS_SEARCH_CRITERIA_SUCCESS,
      payload: filters,
    });
  } catch (e) {
    console.error("tickets filter", e);
    yield put({
      type: TICKETS_SEARCH_CRITERIA_FAILURE,
    });
  }
};

const loadTickets = function* (action) {
  try {
    let searchTerms = {
      validityFrom: action.terms.validityPeriod.from
        ? action.terms.validityPeriod.from
        : "",
      validityTo: action.terms.validityPeriod.to
        ? action.terms.validityPeriod.to
        : "",
      gameDomains: action.terms.gameDomain,
      gameTypes: action.terms.gameCode,
      status: action.terms.status,
    };
    const data = yield call(
      ticketService.getTickets,
      action.contractId,
      action.brandId,
      action.partnerId,
      searchTerms
    );
    yield put({
      type: TICKETS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("tickets list", e);
    yield put({
      type: TICKETS_LIST_FAILURE,
    });
  }
};

const deleteTicket = function* (action) {
  try {
    const { terms, brandId, partnerId, contractId } = action;
    yield call(ticketService.deleteTicket, action.contractId, action.ticketId);
    yield put({
      type: TICKETS_LIST_REQUEST,
      terms,
      brandId,
      partnerId,
      contractId,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.tickets.delete_successfully",
        },
        options: {
          key: "DeleteTickets",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
  } catch (e) {
    console.error("ticket delete", e);
    yield put({
      type: TICKETS_LIST_FAILURE,
    });
  }
};

const TicketsSaga = function* ticketsSaga() {
  yield takeLatest(TICKETS_SEARCH_CRITERIA_REQUEST, loadSearchCriteria);
  yield takeLatest(TICKETS_LIST_REQUEST, loadTickets);
  yield takeLatest(TICKETS_DELETE_REQUEST, deleteTicket);
};

export default TicketsSaga;
