import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { isMobileOnly } from "react-device-detect";
import { Box, Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import Create from "@material-ui/icons/Create";
import Skeleton from "@material-ui/lab/Skeleton";
import NoDataToShow from "../../utilities/no-data-to-show";
import Translate from "../Translator/Translate";
import { getRenderDataValue } from "../Table/utils";
import FormTooltip from "../form/form-tooltip";
import searchUtils from "../../utilities/searchUtils";
import { Form, Formik } from "formik";
import { FormField } from "../form";
import { getInitialValues, getValidationSchema } from "../form/utils";
import UnsafeHtml from "../unsafe-html";

const useStyle = (widget) =>
  makeStyles((theme) => ({
    root: {
      fontSize: "14px",
      overflow: "hidden",
    },
    listItem: {
      display: "flex",
      "&:nth-child(even)": {
        backgroundColor: theme.palette.background.primary2Accent3,
      },
    },
    childItem: {
      paddingLeft: theme.spacing(4),
    },
    itemText: {
      color: theme.palette.text.primary2,
      wordWrap: "break-word",
      flex: "1 1 auto",
      "&.text": {
        flex: widget ? "0 0 50%" : "0 0 35%",
        fontWeight: theme.typography.fontWeightMedium,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box !important",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
      },
      "&.value": {
        marginLeft: theme.spacing(1),
        flex: "1 1 auto",
        "& > .MuiTypography-root": {
          overflowWrap: "anywhere",
          fontWeight: theme.typography.fontWeightBold,
        },
        "& .MuiChip-root": {
          height: "25px",
        },
      },
    },
    inline: {
      overflowWrap: "normal",
      marginRight: 5,
      "&.MuiFormControl-root": {
        marginTop: 0,
      },
    },
    radio: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    flexibleClass: {
      overflowX: "auto",
    },
    skeleton: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridColumnGap: "4px",
      gridRowGap: "4px",
    },
  }));

const ListPanel = ({
  data,
  fields,
  editable = false,
  maxHeight,
  action,
  loading,
  labelPrefix,
  isToEdit,
  isCancelButton = false,
  isResetButton = false,
  getButtons = null,
  widget = null,
  hideEditButton = false,
  disableEdit = false,
}) => {
  const t = useTranslator();
  const classes = useStyle(widget)();

  const [isEditable, setEdit] = React.useState(isToEdit || false);
  const [width, setWidth] = React.useState("0%");
  const [validationSchema, setValidationSchema] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState({});
  const [dataPanel, setDataPanel] = React.useState(null);

  const getFields = (dataObj) => {
    if (dataObj) {
      return Object.keys(dataObj).map((element) => {
        return { field: element, title: element, type: "text" };
      });
    }

    return [];
  };

  useEffect(() => {
    if (data) {
      setDataPanel(data);
    }
  }, [data]);

  const fieldList = data
    ? (fields ? fields : getFields(data)).filter((it) => {
        if (typeof it.hidden === "function") return it.hidden(data) === false;
        return it.hidden !== true;
      })
    : [];

  useEffect(() => {
    if (!isMobileOnly && maxHeight) {
      let nRow = maxHeight / 50;
      const widthPerCol =
        Math.ceil(fieldList.length / nRow) <= 2
          ? `${100 / Math.ceil(fieldList.length / nRow)}%`
          : `${100 / 3}%`;
      setWidth(widthPerCol);
    } else {
      setWidth("100%");
    }
  }, [maxHeight, fieldList.length]);

  useEffect(() => {
    if ((isEditable || isToEdit) && dataPanel) {
      let valSchema = getValidationSchema(
        labelPrefix != null ? labelPrefix : "",
        fieldList
      );
      let initValues = {};
      initValues = getInitialValues(fieldList, dataPanel);
      setValidationSchema(valSchema);
      setInitialValues(initValues);
    }
  }, [isEditable, dataPanel]);

  const setEditable = () => {
    setEdit(!isEditable);
  };

  const getFormField = (key, values) => {
    const style =
      key.type === "Select"
        ? {
            minWidth: "202.4px",
            marginTop: 0,
          }
        : { marginTop: 0 };

    return (
      <FormField {...key} name={key.field} values={values} style={style} />
    );
  };

  const saveForm = (values, actions) => {
    if (!isToEdit) {
      setEdit(!isEditable);
      setInitialValues({});
    }
    if (action != null && typeof action === "function") action(values, actions);
  };

  const cancelForm = () => {
    setInitialValues({});
    setEdit(!isEditable);
  };

  const valueSkeleton = Array(12)
    .fill()
    .map(() => `${Math.random() * 50 + 30}%`);

  const getValue = (fieldDef, dataObj, values) => {
    if ((isEditable || isToEdit) && fieldDef.type) {
      return getFormField(fieldDef, values);
    }
    let field = getRenderDataValue(dataObj, {
      ...fieldDef,
      renderData: fieldDef.render,
    });
    if (field == null) return "-";
    if (field.enabled !== undefined) {
      return field.from && field.to ? `${field.from} - ${field.to}` : "-";
    }
    return typeof field === "string" ? (
      <UnsafeHtml dangerouslySetInnerHTML={{ __html: field }} />
    ) : (
      field
    );
  };

  const getItem = (fieldDef, dataObj, values, isChild) => {
    return (
      <ListItem
        style={{
          width: width === "100%" ? width : `calc(${width} - 16px)`,
          margin: width === "100%" ? 0 : "0 8px",
        }}
        key={fieldDef.field}
        className={clsx(classes.listItem, { [classes.childItem]: isChild })}
      >
        <ListItemText className={clsx(classes.itemText, "text")}>
          <Box display={"flex"} alignItems={"center"}>
            <Translate
              needle={
                fieldDef.title ? fieldDef.title : labelPrefix + fieldDef.field
              }
            />
            {(isEditable || isToEdit) &&
              fieldDef.optional != null &&
              " " + t({ needle: "label.form.optional" })}
            {fieldDef.titleTooltip ? (
              <FormTooltip title={fieldDef.titleTooltip} />
            ) : null}
          </Box>
        </ListItemText>
        <ListItemText className={clsx(classes.itemText, "value")}>
          <Box alignItems={"center"}>
            {getValue(fieldDef, dataObj, values)}
            {fieldDef.valueTooltip ? (
              <FormTooltip title={fieldDef.valueTooltip} />
            ) : null}
          </Box>
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        saveForm(values, actions);
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            <List
              disablePadding={true}
              className={classes.root}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <Box
                display="flex"
                flexDirection="column"
                flexWrap={isMobileOnly ? "nowrap" : "wrap"}
                maxHeight={!isMobileOnly && maxHeight}
                className={classes.flexibleClass}
                style={{
                  margin: width === "100%" ? 0 : "0 -8px",
                }}
              >
                {loading ? (
                  valueSkeleton.map((w, index) => {
                    return (
                      <ListItem
                        style={{
                          width:
                            width === "100%" ? width : `calc(${width} - 16px)`,
                          margin: width === "100%" ? 0 : "0 8px",
                        }}
                        key={index}
                        className={classes.listItem}
                      >
                        <ListItemText
                          className={clsx(classes.itemText, "text")}
                        >
                          <Skeleton variant={"text"} width={w} height={32} />
                        </ListItemText>
                        <ListItemText
                          className={clsx(classes.itemText, "value")}
                        >
                          <Skeleton variant={"text"} width={w} height={32} />
                        </ListItemText>
                      </ListItem>
                    );
                  })
                ) : data ? (
                  fieldList.map((fieldDef) => {
                    if (
                      searchUtils.isObject(data[fieldDef.field]) &&
                      data[fieldDef.field].hasOwnProperty("value")
                    ) {
                      return (
                        <>
                          <ListItem
                            style={{
                              width:
                                width === "100%"
                                  ? width
                                  : `calc(${width} - 16px)`,
                              margin: width === "100%" ? 0 : "0 8px",
                            }}
                            key={fieldDef.field}
                            className={classes.listItem}
                          >
                            <ListItemText className={clsx(classes.itemText)}>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                fontWeight={"fontWeightSemiBold"}
                              >
                                <Translate needle={fieldDef.title} />
                                {fieldDef.titleTooltip ? (
                                  <FormTooltip title={fieldDef.titleTooltip} />
                                ) : null}
                              </Box>
                            </ListItemText>
                          </ListItem>
                          {data[fieldDef.field].children.map((child) =>
                            getFields(child).map((childFieldDef) =>
                              getItem(
                                childFieldDef,
                                child,
                                formikProps.values,
                                true
                              )
                            )
                          )}
                        </>
                      );
                    }
                    return getItem(fieldDef, data, formikProps.values);
                  })
                ) : (
                  <NoDataToShow />
                )}
              </Box>
            </List>

            {data ? (
              getButtons && hideEditButton ? (
                getButtons(formikProps)
              ) : editable ? (
                <Box display="flex" justifyContent="flex-end">
                  {!isEditable ? (
                    <>
                      {getButtons && getButtons(formikProps)}
                      <Box m={1}>
                        <Button
                          startIcon={<Create />}
                          text-align="right"
                          color="primary"
                          onClick={setEditable}
                          disabled={disableEdit}
                        >
                          {t({ needle: "button.edit", label: "Edit" })}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {isCancelButton && (
                        <Box m={1}>
                          <Button variant="outlined" onClick={cancelForm}>
                            {t({ needle: "button.cancel", label: "Cancel" })}
                          </Button>
                        </Box>
                      )}

                      {isResetButton && (
                        <Box m={1}>
                          <Button variant="outlined" type="reset">
                            {t({ needle: "button.reset", label: "Reset" })}
                          </Button>
                        </Box>
                      )}

                      <Box m={1}>
                        <Button color="primary" type="submit">
                          {t({ needle: "button.save", label: "Save" })}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              ) : null
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ListPanel;
