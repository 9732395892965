import Moment from "react-moment";
import React from "react";
import {
  GAMES_OVERVIEW_SUCCESS,
  GAMES_OVERVIEW_FAILURE,
} from "../../../constants";
import Currency from "../../../../Layouts/Currency/Currency";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";

const initialState = {
  loading: true,
  data: [],
  fields: [
    {
      field: "currency",
      title: "label.individualplayer.gamesOverview.currency",
      render: (rowData) => {
        if (rowData["currency"] != null) {
          return <CurrencyBadge currency={rowData["currency"]} />;
        }
      },
    },
    {
      field: "lastPlayedHand",
      title: "label.individualplayer.gamesOverview.lastPlayedDate",
      render: (rowData) => {
        if (rowData["lastPlayedHand"]) {
          return <Moment format="L LT">{rowData["lastPlayedHand"]}</Moment>;
        } else {
          return null;
        }
      },
    },
    {
      field: "lastmonetaryPlayedHand",
      title: "label.individualplayer.gamesOverview.lastMonetaryPlayedDate",
      render: (rowData) => {
        if (rowData["lastMonetaryPlayedDate"]) {
          return (
            <Moment format="L LT">{rowData["lastMonetaryPlayedDate"]}</Moment>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "totalPlayedGames",
      title: "label.individualplayer.gamesOverview.totalPlayedGames",
    },
    {
      field: "totalAmountWon",
      title: "label.individualplayer.gamesOverview.totalAmountWon",
      render: (rowData) => {
        if (rowData["totalAmountWon"] != null) {
          return (
            <Currency
              amount={rowData["totalAmountWon"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalEarnedLoyaltyPoint",
      title: "label.individualplayer.gamesOverview.totalEarnedLoyaltyPoint",
    },
    {
      field: "totalBonusBagAmountWon",
      title: "label.individualplayer.gamesOverview.totalBonusBagAmountWon",
      render: (rowData) => {
        if (rowData["totalBonusBagAmountWon"] != null) {
          return (
            <Currency
              amount={rowData["totalBonusBagAmountWon"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalRealMoneyAmountWon",
      title: "label.individualplayer.gamesOverview.totalWithdrawableAmountWon",
      render: (rowData) => {
        if (rowData["totalRealMoneyAmountWon"] != null) {
          return (
            <Currency
              amount={rowData["totalRealMoneyAmountWon"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalNetRealMoneyAmountWon",
      title:
        "label.individualplayer.gamesOverview.totalWithdrawableNetAmountWonTD",
      render: (rowData) => {
        if (rowData["totalNetRealMoneyAmountWon"] != null) {
          return (
            <Currency
              amount={rowData["totalNetRealMoneyAmountWon"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalAmountSpent",
      title: "label.individualplayer.gamesOverview.totalAmountSpent",
      render: (rowData) => {
        if (rowData["totalAmountSpent"] != null) {
          return (
            <Currency
              amount={rowData["totalAmountSpent"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalBonusBagAmountSpent",
      title: "label.individualplayer.gamesOverview.totalBonusBagAmountSpent",
      render: (rowData) => {
        if (rowData["totalBonusBagAmountSpent"] != null) {
          return (
            <Currency
              amount={rowData["totalBonusBagAmountSpent"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalBonusCashAmountSpent",
      title: "label.individualplayer.gamesOverview.totalBonusCashAmountSpent",
      render: (rowData) => {
        if (rowData["totalBonusCashAmountSpent"] != null) {
          return (
            <Currency
              amount={rowData["totalBonusCashAmountSpent"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalSpentLoyaltyPoint",
      title: "label.individualplayer.gamesOverview.totalLoyaltyPointsSpent",
    },
    {
      field: "totalTournamentTicketSpent",
      title: "label.individualplayer.gamesOverview.totalTournamentTicketSpent",
      render: (rowData) => {
        if (rowData["totalTournamentTicketSpent"] != null) {
          return (
            <Currency
              amount={rowData["totalTournamentTicketSpent"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "grossGamingAmount",
      title: "label.individualplayer.gamesOverview.grossGamingAmount",
      render: (rowData) => {
        if (rowData["grossGamingAmount"] != null) {
          return (
            <Currency
              amount={rowData["grossGamingAmount"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "grossRevenueAmount",
      title: "label.individualplayer.gamesOverview.grossRevenueAmount",
      render: (rowData) => {
        if (rowData["grossRevenueAmount"] != null) {
          return (
            <Currency
              amount={rowData["grossRevenueAmount"]}
              currency={rowData["currency"]}
            />
          );
        }
      },
    },
    {
      field: "totalShadowBalance",
      title: "label.individualplayer.gamesOverview.totalShadowBalance",
    },
  ],
};

const GamesOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GAMES_OVERVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case GAMES_OVERVIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    default:
      return state;
  }
};

export default GamesOverviewReducer;
