import {
  WIDGET_DATA_SUCCESS,
  WIDGET_HOME_DATA_REQUEST,
  WIDGET_DATA_FAILURE,
  WIDGET_LAYOUT_SUCCESS,
  WIDGET_REWARD_DATA_REQUEST,
} from "../constants";

const initialState = {
  layouts: {},
  data: {},
};

const WidgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_LAYOUT_SUCCESS: {
      return {
        ...state,
        layouts: {
          ...state.layouts,
          [action.payload.id]: action.payload.response,
        },
      };
    }
    case WIDGET_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.action]: { data: action.data, loading: false },
        },
      };
    }
    case WIDGET_DATA_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.action]: { data: null, error: true, loading: false },
        },
      };
    }

    case WIDGET_REWARD_DATA_REQUEST:
    case WIDGET_HOME_DATA_REQUEST: {
      return {
        ...state,
        data: { ...state.data, [action.payload.name]: { loading: true } },
      };
    }

    default: {
      return state;
    }
  }
};

export default WidgetReducer;
