import React from "react";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { NumberFormatCustom } from "./amount-field";
import InputField from "./input-field";

const DecimalCustom = ({ isInteger, ...other }) => {
  return <NumberFormatCustom {...other} isDecimal={true} decimalScale={2} />;
};

const DecimalField = ({ isInteger, ...props }) => {
  const t = useTranslator();

  return (
    <InputField
      {...props}
      placeholder={t({ needle: "label.placeholders.amount" })}
      InputProps={{
        inputComponent: DecimalCustom,
      }}
    />
  );
};

export default DecimalField;
