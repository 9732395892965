import { call, put, takeEvery } from "redux-saga/effects";
import {
  GAME_CATEGORIES_FAILURE,
  GAME_CATEGORIES_REQUEST,
  GAME_CATEGORIES_SUCCESS,
} from "../constants";
import gameCategoriesService from "./game-categories-service";

const loadCategories = function* (action) {
  try {
    const data = yield call(gameCategoriesService.getCategories, action.code);
    yield put({
      type: GAME_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: GAME_CATEGORIES_FAILURE,
    });
  }
};

const GameCategoriesSaga = function* () {
  yield takeEvery(GAME_CATEGORIES_REQUEST, loadCategories);
};

export default GameCategoriesSaga;
