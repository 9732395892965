import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { logoutHandler } from "../../store/authentication/actions";
// Import Component(s)
import ChangePassword from "./ChangePassword.js";
import UserProfile from "./UserProfile.js";
// Import Icon(s)
import { ReactComponent as IconAvatar } from "../../Images/Icon-Avatar.svg";
import { ReactComponent as IconChangePassword } from "../../Images/Icon-ChangePassword.svg";
import { ReactComponent as IconLogout } from "../../Images/Icon-Logout.svg";
import { ListItemIcon, ListItemText, Menu, SvgIcon } from "@material-ui/core";
import Modal from "../Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { useSession } from "../../utilities/hooks/useSession";

const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary2,
  },
  listItemIcon: {
    color: "inherit",
    minWidth: "unset",
    marginRight: 10,
  },
  icon: {
    color: "inherit",
    fill: "currentColor",
    width: "20px",
    height: "20px",
  },
}));
const ProfileDropdown = ({ logout, open, setOpen }) => {
  const [openProfileModal, setOpenProfileModal] = React.useState(false);
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  const { user } = useSession();
  const t = useTranslator();

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true);
    setOpen(null);
  };
  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);
    setOpen(null);
  };
  const handleCloseModal = () => {
    setOpenProfileModal(false);
    setOpenPasswordModal(false);
  };
  const labelChangePassword = t({
    needle: "label.user.changePassword",
    label: "Change Password",
  });
  const classes = useStyle();
  return (
    <>
      <Menu
        classes={{ list: classes.root }}
        anchorEl={open}
        open={Boolean(open)}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        onClose={() => setOpen(null)}
      >
        <MenuItem onClick={() => handleOpenProfileModal()}>
          <ListItemIcon className={classes.listItemIcon}>
            <SvgIcon
              component={IconAvatar}
              className={classes.icon}
              viewBox={"0 0 35 35"}
            />
          </ListItemIcon>
          <ListItemText>
            {t({ needle: "label.profile", label: "Profile" })}
          </ListItemText>
        </MenuItem>
        {(user.brandCode === -1 && user.partnerCode === -1) ||
        user.extAuthProvider === null ? (
          <MenuItem onClick={() => handleOpenPasswordModal()}>
            <ListItemIcon className={classes.listItemIcon}>
              <SvgIcon
                component={IconChangePassword}
                className={classes.icon}
              />
            </ListItemIcon>
            <ListItemText>{labelChangePassword}</ListItemText>
          </MenuItem>
        ) : null}
        <MenuItem onClick={() => logout(null, window)}>
          <ListItemIcon className={classes.listItemIcon}>
            <SvgIcon component={IconLogout} className={classes.icon} />
          </ListItemIcon>
          <ListItemText>
            {t({ needle: "label.logOut", label: "Logout" })}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Modal
        open={openProfileModal}
        title={t({ needle: "label.userProfile", label: "User Profile" })}
        content={<UserProfile />}
        handleClose={handleCloseModal}
      />
      <Modal
        open={openPasswordModal}
        title={labelChangePassword}
        content={<ChangePassword handleCloseModal={handleCloseModal} />}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  logout: logoutHandler,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
