import {
  INDIVIDUAL_PLAYER_GROUP_LIST_REQUEST,
  INDIVIDUAL_PLAYER_GROUPS_REQUEST,
  DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST,
  SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST,
} from "../../../constants";

export const individualPlayerGroups = (
  playerContact,
  brand,
  partner,
  isWidget
) => {
  return {
    type: INDIVIDUAL_PLAYER_GROUPS_REQUEST,
    playerContact,
    brand,
    partner,
    isWidget,
  };
};

export const deleteIndividualPlayerGroup = (
  playerContact,
  brand,
  partner,
  name
) => {
  return {
    type: DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST,
    playerContact,
    brand,
    partner,
    name,
  };
};

export const getGroupList = (brand, partner) => {
  return {
    type: INDIVIDUAL_PLAYER_GROUP_LIST_REQUEST,
    brand,
    partner,
  };
};

export const saveIndividualPlayerGroup = (
  playerContact,
  brand,
  partner,
  name
) => {
  return {
    type: SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST,
    playerContact,
    brand,
    partner,
    name,
  };
};
