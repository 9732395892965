import { call, put } from "redux-saga/effects";
import { takeLatest } from "@redux-saga/core/effects";
import gamesOverviewService from "./games-overview-service";
import {
  GAMES_OVERVIEW_REQUEST,
  GAMES_OVERVIEW_SUCCESS,
  GAMES_OVERVIEW_FAILURE,
} from "../../../constants";

const getGamesOverview = function* (action) {
  try {
    const data = yield call(
      gamesOverviewService.getGamesOverview,
      action.constractId,
      action.brand,
      action.partner,
      action.searchTerms
    );
    yield put({
      type: GAMES_OVERVIEW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("gamesOverview", e);
    yield put({
      type: GAMES_OVERVIEW_FAILURE,
    });
  }
};

const GamesOverviewSaga = function* gamesOverviewSaga() {
  yield takeLatest(GAMES_OVERVIEW_REQUEST, getGamesOverview);
};

export default GamesOverviewSaga;
