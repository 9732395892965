export const actionStart = (id) => {
  return {
    type: "ACTION_START",
    payload: {
      id,
    },
  };
};
export const actionEnd = (id) => {
  return {
    type: "ACTION_END",
    payload: {
      id,
    },
  };
};
export const menuOpen = (id) => {
  return {
    type: "MENU_OPEN",
    payload: {
      id,
    },
  };
};

export const routeAction = (element, to) => {
  return {
    type: "ROUTE_ACTION",
    payload: {
      element,
      to,
    },
  };
};
