import { call, put } from "redux-saga/effects";
import {
  BONUS_BAG_ACCEPT_FAILURE,
  BONUS_BAG_ACCEPT_REQUEST,
  BONUS_BAG_ACCEPT_SUCCESS,
  BONUS_BAG_CONVERT_FAILURE,
  BONUS_BAG_CONVERT_REQUEST,
  BONUS_BAG_CONVERT_SUCCESS,
  BONUS_BAG_FAILURE,
  BONUS_BAG_REQUEST,
  BONUS_BAG_SUCCESS,
  BONUS_BAG_TRANSACTIONS_FAILURE,
  BONUS_BAG_TRANSACTIONS_REQUEST,
  BONUS_BAG_TRANSACTIONS_SUCCESS,
  BONUS_BAG_UNFREEZE_FAILURE,
  BONUS_BAG_UNFREEZE_REQUEST,
  BONUS_BAG_UNFREEZE_SUCCESS,
  BONUS_BAGS_FAILURE,
  BONUS_BAGS_REQUEST,
  BONUS_BAGS_SUCCESS,
  REMOVE_BONUS_BAG_FAILURE,
  REMOVE_BONUS_BAG_REQUEST,
} from "../../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../../snackbars/actions";
import bonusBagsService from "./bonus-bags-service";

const getBonusBags = function* (action) {
  try {
    const data = yield call(
      bonusBagsService.getBonusBags,
      action.contractId,
      action.brand,
      action.partner
    );
    yield put({
      type: BONUS_BAGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getBonusBags", e);
    yield put({
      type: BONUS_BAGS_FAILURE,
    });
  }
};

const getBonusBagTransactions = function* (action) {
  try {
    const data = yield call(
      bonusBagsService.getBonusBagTransactions,
      action.bonusId,
      action.brand,
      action.partner
    );
    yield put({
      type: BONUS_BAG_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getBonusBagTransactions", e);
    yield put({
      type: BONUS_BAG_TRANSACTIONS_FAILURE,
    });
  }
};

const getBonusBag = function* (action) {
  try {
    const data = yield call(
      bonusBagsService.getBonusBag,
      action.bonusId,
      action.brand,
      action.partner
    );
    yield put({
      type: BONUS_BAG_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getBonusBag", e);
    yield put({
      type: BONUS_BAG_FAILURE,
    });
  }
};

const unfreezeBonusBag = function* (action) {
  try {
    const data = yield call(
      bonusBagsService.unfreeze,
      [{ bonusBagId: action.bonusId }],
      action.brand,
      action.partner
    );
    if (Array.isArray(data) && data.length > 0) {
      yield put(
        enqueueSnackbar({
          message: {
            needle: "errors.bonusBags.unfreeze",
            variables: [data.map((it) => it.value).join("<br>")],
          },
          options: {
            key: "unfreezeBonusBag",
            variant: "error",
            autoHideDuration: 8000,
          },
        })
      );
    } else {
      yield put({
        type: BONUS_BAG_UNFREEZE_SUCCESS,
      });
      yield put(
        enqueueSnackbar({
          message: {
            needle: "ok.bonusBags.unfreeze",
          },
          options: {
            key: "unfreezeBonusBag",
            variant: "success",
          },
        })
      );
    }
  } catch (e) {
    console.error("unfreezeBonusBag", e);
    yield put({
      type: BONUS_BAG_UNFREEZE_FAILURE,
    });
  }
  yield put({
    ...action,
    type: BONUS_BAGS_REQUEST,
  });
};

const convertBonusBag = function* (action) {
  try {
    const data = yield call(
      bonusBagsService.convert,
      [{ bonusBagId: action.bonusId }],
      action.brand,
      action.partner
    );
    if (Array.isArray(data) && data.length > 0) {
      yield put(
        enqueueSnackbar({
          message: {
            needle: "errors.bonusBags.convert",
            variables: [data.map((it) => it.value).join("<br>")],
          },
          options: {
            key: "convertBonusBag",
            variant: "error",
            autoHideDuration: 8000,
          },
        })
      );
    } else {
      yield put({
        type: BONUS_BAG_CONVERT_SUCCESS,
      });
      yield put(
        enqueueSnackbar({
          message: {
            needle: "ok.bonusBags.convert",
          },
          options: {
            key: "convertBonusBag",
            variant: "success",
          },
        })
      );
    }
  } catch (e) {
    console.error("convertBonusBag", e);
    yield put({
      type: BONUS_BAG_CONVERT_FAILURE,
    });
  }
  yield put({
    ...action,
    type: BONUS_BAGS_REQUEST,
  });
};

const acceptBonusBag = function* (action) {
  try {
    yield call(
      bonusBagsService.accept,
      action.bonusId,
      action.brand,
      action.partner,
      action.contractId,
      action.systemId
    );
    yield put({
      type: BONUS_BAG_ACCEPT_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.bonusBags.accept",
        },
        options: {
          key: "acceptBonusBag",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("acceptBonusBag", e);
    yield put({
      type: BONUS_BAG_ACCEPT_FAILURE,
    });
  }
  yield put({
    ...action,
    type: BONUS_BAGS_REQUEST,
  });
};

const removeBonusBag = function* (action) {
  try {
    yield call(
      bonusBagsService.removeBonusBag,
      action.contractId,
      action.brand,
      action.partner,
      action.bonusId,
      action.reason
    );
    yield put({
      ...action,
      type: BONUS_BAGS_REQUEST,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: `label.player.bonusBags.cancelBonusBagSuccess`,
        },
        options: {
          key: "cancelBonusBag_success",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("CancelBonusBag", e);
    yield put({
      type: REMOVE_BONUS_BAG_FAILURE,
    });
  }
};

const BonusBagsSaga = function* () {
  yield takeLatest(BONUS_BAGS_REQUEST, getBonusBags);
  yield takeLatest(BONUS_BAG_REQUEST, getBonusBag);
  yield takeLatest(BONUS_BAG_TRANSACTIONS_REQUEST, getBonusBagTransactions);
  yield takeLatest(BONUS_BAG_UNFREEZE_REQUEST, unfreezeBonusBag);
  yield takeLatest(BONUS_BAG_CONVERT_REQUEST, convertBonusBag);
  yield takeLatest(BONUS_BAG_ACCEPT_REQUEST, acceptBonusBag);
  yield takeLatest(REMOVE_BONUS_BAG_REQUEST, removeBonusBag);
};

export default BonusBagsSaga;
