import axios from "axios";

class LoginHistoryService {
  async loadIndividualPlayerLoginHistory(contractId, brand, partner, page, pageSize) {
    const response = await axios.get(
      `/gov/api/rest/v1/contracts/history_login/${contractId}/${brand}/${partner}`,
        {params: {"page": page, "page-size": pageSize}}
    );
    if (response && response.data) {
      response.data.data = response.data.collection;
      response.data.page = response.data.pageNumber;
      return response.data;
    }
    throw Error("Invalid response for individual player login history");
  }
}

const loginHistoryService = new LoginHistoryService();
export default loginHistoryService;
