import {
  BONUS_BAG_FAILURE,
  BONUS_BAG_REQUEST,
  BONUS_BAG_SUCCESS,
  BONUS_BAG_TRANSACTIONS_FAILURE,
  BONUS_BAG_TRANSACTIONS_REQUEST,
  BONUS_BAG_TRANSACTIONS_SUCCESS,
  BONUS_BAGS_FAILURE,
  BONUS_BAGS_REQUEST,
  BONUS_BAGS_SUCCESS,
  STYLE_CURRENCY,
  STYLE_LABEL,
} from "../../../../constants";
import React from "react";
import BadgeStatus from "../../../../../Layouts/BadgeStatus/BadgeStatus";
import Moment from "react-moment";
import CurrencyBadge from "../../../../../Layouts/badges/currency-badge";
import Currency from "../../../../../Layouts/Currency/Currency";
import Translate from "../../../../../Layouts/Translator/Translate";

const statusMapping = (status) => {
  switch (status) {
    case "1":
    case "USABLE":
      return {
        id: "1",
        label: "bonusBags.usable",
      };
    case "2":
    case "CREATED":
      return {
        id: "1",
        label: "bonusBags.created",
      };
    case "3":
    case "CONVERTED":
      return {
        id: "2",
        label: "bonusBags.converted",
      };
    case "4":
    case "CONSUMED":
      return {
        id: "2",
        label: "bonusBags.consumed",
      };
    case "5":
    case "REMOVED":
      return {
        id: "5",
        label: "bonusBags.removed",
      };
    case "6":
    case "EXPIRED":
      return {
        id: "5",
        label: "bonusBags.expired",
      };
    case "7":
    case "IN_PLAY":
      return {
        id: "1",
        label: "bonusBags.inPlay",
      };
    case "8":
    case "FROZEN":
      return {
        id: "6",
        label: "bonusBags.frozen",
      };
    case "9":
    case "TO_BE_CONVERTED":
      return {
        id: "5",
        label: "bonusBags.toBeConverted",
      };
    case "10":
    case "TO_BE_ACCEPTED":
      return {
        id: "3",
        label: "bonusBags.toBeAccepted",
      };
    case "11":
    case "PENDING_CONVERTION":
      return {
        id: "3",
        label: "bonusBags.pendingConversion",
      };
    default:
      return {};
  }
};

const initialState = {
  bonusBagTransactions: {
    loading: true,
    data: [],
    columns: (currency) => [
      {
        field: "bonusBagId",
        title: "label.player.bonusBags.header.bonusBagId",
      },
      {
        field: "transactionType",
        title: "label.player.bonusBags.header.transType",
      },
      {
        field: "transactionDate",
        title: "label.player.bonusBags.header.transDate",
        render: (rowData) => (
          <Moment format={"L LT"}>{parseInt(rowData.transactionDate)}</Moment>
        ),
        defaultSort: "desc",
      },
      {
        field: "systemId",
        title: "label.player.bonusBags.header.systemId",
      },
      {
        field: "amount",
        title: "label.player.bonusBags.header.amount",
        render: (rowData) => (
          <Currency amount={rowData.amount} currency={currency} />
        ),
      },
      {
        field: "pendingWinnings",
        title: "label.player.bonusBags.header.pendingWinnings",
        render: (rowData) => <Currency amount={rowData.pendingWinnings} />,
      },
      {
        field: "gameType",
        title: "label.player.bonusBags.header.gameType",
      },
      {
        field: "gameContribution",
        title: "label.player.bonusBags.header.gameContribution",
      },
    ],
  },
  bonusBag: {
    loading: true,
    data: {},
    fields: [
      { field: "name", title: "label.player.bonusBags.list.name" },
      {
        field: "bonusState",
        title: "label.player.bonusBags.list.bonusState",
        render: (rowData) => (
          <BadgeStatus {...statusMapping(rowData.bonusState)} fullWidth />
        ),
      },
      {
        field: "bonusTypeId",
        title: "label.player.bonusBags.list.bonusTypeDesc",
        render: (rowData) => {
          if (rowData["bonusTypeId"]) {
            const bonusBagType =
              "label.player.bonusBags.list.bonusTypeId." +
              rowData["bonusTypeId"];
            return <Translate needle={bonusBagType} />;
          }
        },
      },
      {
        field: "triggerName",
        title: "label.player.bonusBags.list.triggerName",
      },
      {
        field: "creationDate",
        title: "label.player.bonusBags.list.creationDate",
        render: (rowData) => {
          return <Moment format={"L LT"}>{rowData.creationDate}</Moment>;
        },
      },
      {
        field: "convertedDate",
        title: "label.player.bonusBags.list.convertedDate",
        render: (rowData) => {
          return <Moment format={"L LT"}>{rowData.convertedDate}</Moment>;
        },
      },
      {
        field: "reachedDateQr1",
        title: "label.player.bonusBags.list.reachedDateQr1",
        render: (rowData) => {
          return <Moment format={"L LT"}>{rowData.reachedDateQr1}</Moment>;
        },
      },
      {
        field: "reachedDateQr2",
        title: "label.player.bonusBags.list.reachedDateQr2",
        render: (rowData) => {
          return <Moment format={"L LT"}>{rowData.reachedDateQr2}</Moment>;
        },
      },
      {
        field: "currency",
        title: "label.player.bonusBags.list.currency",
        render: (rowData) => <CurrencyBadge currency={rowData.currency} />,
      },
      {
        field: "bbmQrTypeDesc",
        title: "label.player.bonusBags.list.bbmQrTypeDesc",
      },
      {
        field: "originalAmount",
        title: "label.player.bonusBags.list.originalAmount",
        render: (rowData) => (
          <Currency
            amount={rowData.originalAmount}
            currency={rowData.currency}
          />
        ),
      },
      {
        field: "currentAmount",
        title: "label.player.bonusBags.list.currentAmount",
        render: (rowData) => (
          <Currency
            amount={rowData.currentAmount}
            currency={rowData.currency}
          />
        ),
      },
      {
        field: "conversionDestinationGameDomainDescription",
        title:
          "label.player.bonusBags.list.conversionDestinationGameDomainDescription",
      },
      {
        field: "pendingWinningsAmount",
        title: "label.player.bonusBags.list.pendingWinningsAmount",
        render: (rowData) => (
          <Currency
            amount={rowData.pendingWinningsAmount}
            currency={rowData.currency}
          />
        ),
      },
      {
        field: "validityDateDTO.validityEndDate",
        title: "label.player.bonusBags.list.validityEndDate",
        render: (rowData) => {
          return (
            <Moment format={"L LT"}>
              {rowData.validityDateDTO.validityEndDate}
            </Moment>
          );
        },
      },
      {
        field: "validityDateDTO.validityNumDays",
        title: "label.player.bonusBags.list.validityNumDays",
      },
      {
        field: "validityDateDTO.type",
        title: "label.player.bonusBags.list.validityType",
      },
    ],
  },
  bonusBags: {
    loading: true,
    data: [],
    idKey: "bonusBagId",
    columns: [
      {
        title: "label.player.bonusBags.header.bonusBagId",
        field: "bonusBagId",
        defaultSort: "asc",
      },
      {
        title: "label.player.bonusBags.header.name",
        field: "name",
        default: true,
      },
      {
        title: "label.player.bonusBags.header.bonusState",
        field: "bonusState",
        default: true,
        render: (rowData) => (
          <BadgeStatus
            justify={"center"}
            {...statusMapping(rowData.bonusState)}
            fullWidth
          />
        ),
        getExportTypeFn: (rowData) => {
          if (rowData["bonusState"]) {
            const badgeMapped = statusMapping(rowData["bonusState"]);
            return {
              style: STYLE_LABEL,
              value: `label.status.${badgeMapped.label}`,
            };
          }
        },
      },
      {
        title: "label.player.bonusBags.header.bonusBagDescription",
        field: "description",
      },
      {
        title: "label.player.bonusBags.header.removalReason",
        field: "removalReason",
      },
      {
        title: "label.player.bonusBags.header.QR1NeededAmount",
        field: "thresholdQr1",
        render: (rowData) => {
          return (
            <Currency
              amount={rowData["thresholdQr1"]}
              currencySymbol={rowData["currency"]}
            />
          );
        },
        getExportTypeFn: (rowData) => {
          if (rowData["thresholdQr1"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["thresholdQr1"],
            };
          }
        },
      },
      {
        title: "label.player.bonusBags.header.QR1CurrentAmount",
        field: "qr1CurrentAmount",
        render: (rowData) => {
          return (
            <Currency
              amount={rowData["qr1CurrentAmount"]}
              currencySymbol={rowData["currency"]}
            />
          );
        },
        getExportTypeFn: (rowData) => {
          if (rowData["qr1CurrentAmount"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["qr1CurrentAmount"],
            };
          }
        },
      },
      {
        title: "label.player.bonusBags.header.QR2NeededAmount",
        field: "thresholdQr2",
        render: (rowData) => {
          return (
            <Currency
              amount={rowData["thresholdQr2"]}
              currencySymbol={rowData["currency"]}
            />
          );
        },
        getExportTypeFn: (rowData) => {
          if (rowData["thresholdQr2"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["thresholdQr2"],
            };
          }
        },
      },
      {
        title: "label.player.bonusBags.header.QR2CurrentAmount",
        field: "qr2CurrentAmount",
        render: (rowData) => {
          return (
            <Currency
              amount={rowData["qr2CurrentAmount"]}
              currencySymbol={rowData["currency"]}
            />
          );
        },
        getExportTypeFn: (rowData) => {
          if (rowData["qr2CurrentAmount"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["qr2CurrentAmount"],
            };
          }
        },
      },
      {
        title: "label.player.bonusBags.header.currentValue",
        field: "currentAmount",
        render: (rowData) => {
          return (
            <Currency
              amount={rowData["currentAmount"]}
              currencySymbol={rowData["currency"]}
            />
          );
        },
        getExportTypeFn: (rowData) => {
          if (rowData["currentAmount"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["currentAmount"],
            };
          }
        },
      },
    ],
  },
};

const BonusBagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BONUS_BAGS_REQUEST: {
      return {
        ...state,
        bonusBags: {
          ...state.bonusBags,
          data: null,
          loading: true,
        },
      };
    }
    case BONUS_BAGS_SUCCESS: {
      return {
        ...state,
        bonusBags: {
          ...state.bonusBags,
          data: action.payload,
          loading: false,
        },
      };
    }
    case BONUS_BAGS_FAILURE: {
      return {
        ...state,
        bonusBags: {
          ...state.bonusBags,
          data: null,
          loading: false,
        },
      };
    }

    case BONUS_BAG_REQUEST: {
      return {
        ...state,
        bonusBag: {
          ...state.bonusBag,
          data: null,
          loading: true,
        },
      };
    }
    case BONUS_BAG_SUCCESS: {
      return {
        ...state,
        bonusBag: {
          ...state.bonusBag,
          data: action.payload,
          loading: false,
        },
      };
    }
    case BONUS_BAG_FAILURE: {
      return {
        ...state,
        bonusBag: {
          ...state.bonusBag,
          data: null,
          loading: false,
        },
      };
    }

    case BONUS_BAG_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        bonusBagTransactions: {
          ...state.bonusBagTransactions,
          data: null,
          loading: true,
        },
      };
    }
    case BONUS_BAG_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        bonusBagTransactions: {
          ...state.bonusBagTransactions,
          data: action.payload,
          loading: false,
        },
      };
    }
    case BONUS_BAG_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        bonusBagTransactions: {
          ...state.bonusBagTransactions,
          data: null,
          loading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default BonusBagsReducer;
