import {
  LINKED_ACCOUNTS_FAILURE,
  LINKED_ACCOUNTS_REQUEST,
  LINKED_ACCOUNTS_SUCCESS,
} from "../../../constants";

const initialState = {
  columns: [
    {
      title: "label.player.linkAccount.contractId",
      field: "contractId",
      default: true,
      defaultSort: "desc",
    },
    {
      title: "label.player.linkAccount.brandId",
      field: "brandCode",
    },
    {
      title: "label.player.linkAccount.partnerId",
      field: "partnerCode",
    },
  ],
  data: null,
  loading: true,
};
const LinkedAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LINKED_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case LINKED_ACCOUNTS_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
      };
    }
    case LINKED_ACCOUNTS_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    default:
      return state;
  }
};

export default LinkedAccountsReducer;
