import * as moment from "moment";
import React from "react";
import Moment from "react-moment";
import CurrencyBadge from "../../Layouts/badges/currency-badge";
import BadgeStatus from "../../Layouts/BadgeStatus/BadgeStatus";
import Currency from "../../Layouts/Currency/Currency";
import {
  DESK_EXTERNALIDS_REQUEST,
  DESK_EXTERNALIDS_REQUEST_SUCCESS,
  DESK_FILTER_FAILURE,
  DESK_FILTER_REQUEST,
  DESK_FILTER_SUCCESS,
  DESK_SEARCH_FAILURE,
  DESK_SEARCH_REQUEST,
  DESK_SEARCH_SUCCESS,
  STYLE_LABEL,
} from "../constants";

export const labelPrefix = "label.desk.";

export const getStatusMapping = (status) => {
  switch (status) {
    case "TO_BE_APPROVED":
      return { id: "3", label: "pending" };
    case "TO_BE_APPROVED_FOR_OVERBUDGET": {
      return { id: "3", label: "overBudget" };
    }
    case "APPROVED": {
      return { id: "3", label: "approved" };
    }
    case "REJECTED":
      return { id: "5", label: "rejected" };
    case "COMPLETED_SUCCESS":
      return { id: "2", label: "completed" };
    case "COMPLETED_ERROR":
      return { id: "5", label: "failed" };
    case "PROCESSING":
      return { id: "3", label: "processing" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const initialState = {
  filters: [],
  filtersLoading: true,
  deskReward: {
    data: [],
    loading: true,
    columns: [
      {
        field: "rewardTypeId",
        title: labelPrefix + "rewardTypeId",
        default: true,
      },
      {
        field: "amount",
        title: labelPrefix + "amount",
        render: (rowData) => {
          if (rowData["amount"]) {
            return (
              <Currency
                currency={rowData["currency"]}
                amount={rowData["amount"]}
                justify={"flex-end"}
              />
            );
          }
        },
      },
      {
        field: "groupOrGameAccount",
        title: labelPrefix + "groupOrGameAccount",
      },
      {
        field: "status",
        title: labelPrefix + "status",
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapping = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapping.id}
                label={statusMapping.label}
                justify={"center"}
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["status"]) {
            const statusMapping = getStatusMapping(rowData["status"]);
            return {
              style: STYLE_LABEL,
              value: `label.status.${statusMapping.label}`,
            };
          }
        },
        default: true,
      },
      {
        field: "processRewardType",
        title: labelPrefix + "processRewardType",
      },
      {
        field: "requestDate",
        title: labelPrefix + "requestDate",
        render: (rowData) => {
          if (rowData["requestDate"]) {
            return <Moment format="L LT">{rowData["requestDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["requestDate"]) {
            return moment(rowData["requestDate"]).format("L LT");
          }
        },
        defaultSort: "desc",
      },
      {
        field: "approvalDate",
        title: labelPrefix + "approvalDate",
        render: (rowData) => {
          if (rowData["approvalDate"]) {
            return <Moment format="L LT">{rowData["approvalDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["approvalDate"]) {
            return moment(rowData["approvalDate"]).format("L LT");
          }
        },
      },
      {
        field: "userCreator",
        title: labelPrefix + "userCreator",
      },
      {
        field: "userApprove",
        title: labelPrefix + "userApprove",
      },
      {
        field: "externalId",
        title: labelPrefix + "externalId",
      },
      {
        field: "lastResultDescription",
        title: labelPrefix + "lastResultDescription",
      },
      {
        field: "currency",
        title: labelPrefix + "currency",
        render: (rowData) => {
          if (rowData["currency"]) {
            return (
              <CurrencyBadge
                currency={rowData["currency"]}
                justify={"center"}
              />
            );
          }
        },
      },
    ],
  },
};

const DeskReducer = (state = initialState, action) => {
  switch (action.type) {
    case DESK_FILTER_REQUEST:
      return {
        ...state,
        filtersLoading: true,
      };

    case DESK_FILTER_SUCCESS: {
      return {
        ...state,
        filters: action.payload,
        filtersLoading: false,
      };
    }
    case DESK_FILTER_FAILURE: {
      return {
        ...state,
        filters: null,
        filtersLoading: false,
      };
    }

    case DESK_SEARCH_REQUEST: {
      return {
        ...state,
        deskReward: {
          ...state.deskReward,
          lastRequest: action,
          loading: true,
        },
      };
    }

    case DESK_SEARCH_SUCCESS: {
      return {
        ...state,
        deskReward: {
          ...state.deskReward,
          data: action.payload,
          loading: false,
        },
      };
    }
    case DESK_SEARCH_FAILURE: {
      return {
        ...state,
        deskReward: {
          ...state.deskReward,
          data: null,
          loading: false,
        },
      };
    }
    case DESK_EXTERNALIDS_REQUEST_SUCCESS: {
      return {
        ...state,
        filters: state.filters.map((el) => {
          if (el.name == "externalId") {
            return {
              ...el,
              options:
                action.payload.externalIds.map((it) => {
                  return {
                    label: it.description,
                    value: it.id,
                  };
                }) || [],
            };
          }
          return el;
        }),
      };
    }

    default: {
      return state;
    }
  }
};

export default DeskReducer;
