import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getFinancialLimitsAssociatedProfilesHandler } from "../../store/players/individual-player/responsible-game/financial-limits/actions";

export const useFinancialLimitsAssociatedProfiles = (
  contractId,
  brand,
  partner,
  divisorAmount
) => {
  const { data } = useSelector(
    (state) => state.FinancialLimitsReducer.associatedProfileList
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getFinancialLimitsAssociatedProfilesHandler(
        contractId,
        brand,
        partner,
        divisorAmount
      )
    );
  }, [dispatch, contractId, brand, partner, divisorAmount]);

  return data.filter(i=>![5,6].includes(i?.type?.profileDataTypeId));
};
