import React from "react";
import Table from "../../../../../Layouts/Table/Table";
import {
  deletePlayerNote,
  getPlayerNotes,
} from "../../../../../store/players/individual-player/player-notes/actions";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import { useRouteMatch } from "react-router-dom";
import { useSession } from "../../../../../utilities/hooks/useSession";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import History from "@material-ui/icons/History";
import { connect } from "react-redux";
import Modal from "../../../../../Layouts/Modal/Modal";
import NotesHistory from "./notes-history";
import Action from "../../../../../Layouts/action/action";
import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import NotesForm from "./notes-form";
import { isMobileOnly } from "react-device-detect";
import ListPanel from "../../../../../Layouts/Panel/ListPanel";
import { usePageActions } from "../../../../../utilities/hooks/usePageActions";
// Import Component(s)
// Import Icon(s)

function PlayerNotes({
  widget,
  columns,
  fields,
  data,
  loading,
  loadPlayerNotes,
  deleteNote,
}) {
  const t = useTranslator();
  const match = useRouteMatch();
  const { brandSelected, partnerSelected } = useSession();
  const contractId = match.params.id;

  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(null);
  const [editModal, setEditModal] = React.useState(null);
  const [historyModal, setHistoryModal] = React.useState(null);
  const [createModal, setCreateModal] = React.useState(null);
  const { buttons } = usePageActions();

  React.useEffect(() => {
    if (brandSelected && partnerSelected) {
      loadPlayerNotes(contractId, brandSelected, partnerSelected);
    }
  }, [loadPlayerNotes, brandSelected, partnerSelected, contractId]);

  const handleDelete = () => {
    deleteNote(
      brandSelected,
      partnerSelected,
      contractId,
      deleteConfirmModal.id
    );
    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setDeleteConfirmModal(null);
  };

  const isActionHidden = (buttons, uid) => {
    if (buttons) {
      let obj = buttons.find((button) =>
        button.uid ? button.uid === uid : false
      );
      return !obj;
    } else return true;
  };

  const actions = [
    {
      icon: Edit,
      color: "primary",
      variant: "contained",
      tooltip: "button.edit",
      hideMobile: true,
      onClick: (event, rowData) => {
        setEditModal(rowData);
      },
      hidden: isActionHidden(buttons, "edit_note"),
    },
    (row) => {
      return {
        icon: History,
        color: "primary",
        variant: "contained",
        tooltip: "button.history",
        onClick: (event, rowData) => {
          setHistoryModal(rowData.id);
        },
        disabled: !row.hasHistory,
        hidden: isActionHidden(buttons, "history_note"),
      };
    },
    {
      icon: Delete,
      color: "failure",
      variant: "contained",
      tooltip: "button.delete",
      hideMobile: true,
      onClick: (event, rowData) => {
        setDeleteConfirmModal(rowData);
      },
      hidden: isActionHidden(buttons, "delete_note"),
    },
  ];

  const lastData =
    data && data.length > 0
      ? data.sort(function (a, b) {
          return (
            new Date(b.lastUpdateDate).getTime() -
            new Date(a.lastUpdateDate).getTime()
          );
        })[0]
      : null;

  return (
    <>
      {!isMobileOnly ? (
        <Box align={"right"} mb={2}>
          <Action
            startIcon={<AddCircleIcon />}
            color={"success"}
            onClick={() => setCreateModal(true)}
            permission={"add_note"}
          />
        </Box>
      ) : null}

      {widget ? (
        <ListPanel loading={loading} data={lastData} fields={fields} />
      ) : (
        <Table
          pageSize={10}
          type={"advanced"}
          resize={false}
          pagination={true}
          exportButton={false}
          title={"label.player.playerNotes.title"}
          placeholder={"label.player.playerNotes.searchPlaceholders"}
          columns={columns}
          data={data}
          isLoading={loading}
          actions={actions}
        />
      )}

      <Modal
        open={Boolean(historyModal)}
        maxWidth={"lg"}
        fullScreen={isMobileOnly}
        title={t({
          needle: "label.player.playerNotes.noteHistoryModalTitle",
        })}
        content={<NotesHistory noteId={historyModal} />}
        handleClose={() => setHistoryModal(null)}
      />
      <Modal
        open={Boolean(editModal)}
        title={t({
          needle: "label.player.playerNotes.editNote",
        })}
        content={
          <NotesForm
            contractId={contractId}
            handleCloseModal={() => setEditModal(null)}
            note={editModal}
          />
        }
      />
      <Modal
        open={Boolean(createModal)}
        title={t({
          needle: "label.player.playerNotes.createNote",
        })}
        content={
          <NotesForm
            contractId={contractId}
            handleCloseModal={() => setCreateModal(null)}
          />
        }
      />
      <Modal
        open={Boolean(deleteConfirmModal)}
        title={t({
          needle: "label.modal.confirmDeletion",
        })}
        description={t({
          needle: "warn.noteDeleteAlert",
          variables: {
            type: deleteConfirmModal ? deleteConfirmModal.noteName : "",
            message: deleteConfirmModal ? deleteConfirmModal.value : "",
          },
          avoidLowerCase: true,
        })}
        actions={[
          {
            color: "primary",
            label: "button.cancel",
            variant: "outlined",
            handler: handleCloseDeleteModal,
          },
          {
            color: "failure",
            label: "button.delete",
            handler: handleDelete,
          },
        ]}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    columns,
    loading,
    data,
    fields,
  } = state.PlayerNotesReducer.playerNotes;
  return { columns, loading, data, fields };
};

const mapDispatchToProps = {
  loadPlayerNotes: getPlayerNotes,
  deleteNote: deletePlayerNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerNotes);
