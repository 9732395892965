import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Translate from "../Translator/Translate";
import Action from "./action";
import Skeleton from "@material-ui/lab/Skeleton";
import { getRenderDataValue } from "./utils";
import LimitedFeatureButton from "../limited-feature-button/limited-feature-button";

const ListTable = ({ isLoading, data, columns, actions, detail, editable }) => {
  const classes = useStyle();

  const iterableColumns = isLoading
    ? Array(5).fill({
        title: <Skeleton variant={"rect"} width={"40%"} />,
        emptyValue: <Skeleton variant={"rect"} width={"70%"} />,
      })
    : columns;

  const displayableActions = actions.filter((it) => {
    let action = it;
    if (typeof action === "function") {
      action = action(data);
    }
    return action.hideMobile === undefined || action.hideMobile === false;
  });
  const actionsDisabled =
    (actions.length + editable ? 1 : 0) > 0 && displayableActions.length === 0;
  return (
    <>
      <List disablePadding={true} className={classes.mobileListTable}>
        {iterableColumns
          .filter((it) => it.title !== "" && it.hideMobile !== true)
          .map((it, index) => (
            <ListItem
              className={classes.listItemRoot}
              key={it.field || index}
              alignItems={"center"}
            >
              <ListItemText className={classes.listItemLabel}>
                {it.title}
              </ListItemText>
              <ListItemText className={classes.listItemValue}>
                <Box
                  component={"div"}
                  style={{ wordBreak: "break-word" }}
                  whiteSpace={"normal"}
                >
                  {getRenderDataValue(data, it)}
                </Box>
              </ListItemText>
            </ListItem>
          ))}
        {detail != null ? (
          <ListItem className={classes.listItemDetailWrapper}>
            {detail(data)}
          </ListItem>
        ) : null}
        {!isLoading && (actions.length > 0 || editable) && (
          <>
            <Divider light />
            <ListItem>
              <Box display={"flex"} width={1} alignItems={"center"}>
                <Box
                  flexGrow={1}
                  color={"text.primary"}
                  className={classes.actionsText}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Box component={"span"} fontSize={15}>
                    <Translate needle={"button.actions"} />
                  </Box>
                  {displayableActions.length !== actions.length || editable ? (
                    <LimitedFeatureButton />
                  ) : null}
                </Box>
                <Box>
                  {actionsDisabled ? (
                    <Box fontSize={15} fontStyle={"italic"}>
                      <Translate needle={"label.disabled"} />
                    </Box>
                  ) : (
                    displayableActions.map((it) => (
                      <Action tablet={true} key={it} action={it} data={data} />
                    ))
                  )}
                </Box>
              </Box>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  listItemLabel: {
    width: "50%",
  },
  listItemDetailWrapper: {
    padding: 0,
  },
  listItemValue: {
    width: "50%",
    textAlign: "right",
    justifyContent: "flex-end",
    display: "flex",
  },
  actionsText: {
    textTransform: "capitalize",
  },
  listItemRoot: {},
  mobileListTable: {
    "& .mobileRowTitle": {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: "1rem",
    },
    "& tr, & td": {
      border: "none",
    },
    "& $mobileListTable": {
      backgroundColor: theme.palette.background.default,
    },
    "& tr $listItemRoot": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

export default ListTable;
