import { call, put } from "redux-saga/effects";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import {
  CLUB_CARD_HISTORY_REQUEST,
  CLUB_CARD_HISTORY_SUCCESS,
  CLUB_CARD_HISTORY_FAILURE,
  CLUB_CARD_DETAILS_REQUEST,
  CLUB_CARD_DETAILS_SUCCESS,
  CLUB_CARD_DETAILS_FAILURE,
  CLUB_CARD_ACTIONS_REQUEST,
  CLUB_CARD_ACTIONS_SUCCESS,
  CLUB_CARD_ACTIONS_FAILURE,
  REASONS_BY_ACTION_REQUEST,
  REASONS_BY_ACTION_SUCCESS,
  REASONS_BY_ACTION_FAILURE,
  SAVE_PRINT_REQUEST_SUCCESS,
  SAVE_PRINT_REQUEST_FAILURE,
  PRINT_CARD_REQUEST,
  REGENERATE_CARD_REQUEST,
  REGENERATE_CARD_SUCCESS,
  REGENERATE_CARD_FAILURE,
  CLUB_CARD_EMAIL_REQUEST,
  CLUB_CARD_EMAIL_FAILURE,
  CLUB_CARD_EMAIL_SUCCESS,
  DISABLE_ENABLE_CARD_REQUEST,
  DISABLE_ENABLE_CARD_SUCCESS,
  DISABLE_ENABLE_CARD_FAILURE,
} from "../../../constants";
import clubCardService from "./club-card-service";

const getClubCardHistory = function* (action) {
  try {
    const data = yield call(
      clubCardService.getClubCardHistory,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: CLUB_CARD_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: CLUB_CARD_HISTORY_FAILURE,
    });
  }
};

const getClubCardDetails = function* (action) {
  try {
    const data = yield call(
      clubCardService.getClubCardDetails,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: CLUB_CARD_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: CLUB_CARD_DETAILS_FAILURE,
    });
  }
};

const getClubCardActionsLog = function* (action) {
  try {
    const data = yield call(
      clubCardService.getClubCardActionsLog,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: CLUB_CARD_ACTIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: CLUB_CARD_ACTIONS_FAILURE,
    });
  }
};

const getReasonsByAction = function* (action) {
  try {
    const data = yield call(
      clubCardService.getReasonsByAction,
      action.brand,
      action.partner,
      action.action,
      action.locale
    );
    yield put({
      type: REASONS_BY_ACTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: REASONS_BY_ACTION_FAILURE,
    });
  }
};

const savePrintRequest = function* (action) {
  try {
    yield call(
      clubCardService.savePrintRequest,
      action.brand,
      action.partner,
      action.data,
      action.contractId
    );
    yield put({
      type: SAVE_PRINT_REQUEST_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.clubCardDetails.print.message",
          variables: ["label.player.clubCardDetails"],
        },
        options: {
          key: "printMessage",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({
      type: CLUB_CARD_DETAILS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
    yield put({
      type: CLUB_CARD_ACTIONS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: SAVE_PRINT_REQUEST_FAILURE,
    });
  }
};

const RegenerateCard = function* (action) {
  try {
    yield call(
      clubCardService.RegenerateCard,
      action.brand,
      action.partner,
      action.data,
      action.contractId
    );
    yield put({
      type: REGENERATE_CARD_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.clubCardDetails.regenerate.message",
          variables: ["label.player.clubCardDetails"],
        },
        options: {
          key: "regenerateMessage",
          variant: "success",
          autoHideDuration: 4000,
        },
      })
    );
    yield put({
      type: CLUB_CARD_DETAILS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
    yield put({
      type: CLUB_CARD_HISTORY_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
    yield put({
      type: CLUB_CARD_ACTIONS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: REGENERATE_CARD_FAILURE,
    });
  }
};

const DisableEnableCard = function* (action) {
  try {
    yield call(
      clubCardService.DisableEnableCard,
      action.brand,
      action.partner,
      action.data,
      action.contractId,
      action.action,
      action.serviceId
    );
    yield put({
      type: DISABLE_ENABLE_CARD_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
    action.action === "Card_Enable"
      ? yield put(
          enqueueSnackbar({
            message: {
              needle: "label.player.clubCardDetails.enable.message",
              variables: ["label.player.clubCardDetails"],
            },
            options: {
              key: "enableMessage",
              variant: "success",
              autoHideDuration: 4000,
            },
          })
        )
      : yield put(
          enqueueSnackbar({
            message: {
              needle: "label.player.clubCardDetails.disable.message",
              variables: ["label.player.clubCardDetails"],
            },
            options: {
              key: "disableMessage",
              variant: "success",
              autoHideDuration: 4000,
            },
          })
        );
    yield put({
      type: CLUB_CARD_DETAILS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
    yield put({
      type: CLUB_CARD_HISTORY_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
    yield put({
      type: CLUB_CARD_ACTIONS_REQUEST,
      brand: action.brand,
      partner: action.partner,
      contractId: action.contractId,
    });
  } catch (e) {
    yield put({
      type: DISABLE_ENABLE_CARD_FAILURE,
    });
  }
};

const sendClubCardEmail = function* (action) {
  try {
    yield call(
      clubCardService.sendClubCardEmail,
      action.idCard,
      action.contractId,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: CLUB_CARD_EMAIL_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.sendClubCardEmail",
        },
        options: {
          key: "sendClubCardEmail",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("sendClubCardEmail", e);
    yield put({
      type: CLUB_CARD_EMAIL_FAILURE,
    });
  } finally {
    action.cb != null && typeof action.cb === "function" && action.cb();
  }
};

const ClubCard = function* clubCard() {
  yield takeLatest(CLUB_CARD_HISTORY_REQUEST, getClubCardHistory);
  yield takeLatest(CLUB_CARD_DETAILS_REQUEST, getClubCardDetails);
  yield takeLatest(CLUB_CARD_ACTIONS_REQUEST, getClubCardActionsLog);
  yield takeLatest(REASONS_BY_ACTION_REQUEST, getReasonsByAction);
  yield takeLatest(PRINT_CARD_REQUEST, savePrintRequest);
  yield takeLatest(REGENERATE_CARD_REQUEST, RegenerateCard);
  yield takeLatest(CLUB_CARD_EMAIL_REQUEST, sendClubCardEmail);
  yield takeLatest(DISABLE_ENABLE_CARD_REQUEST, DisableEnableCard);
};

export default ClubCard;
