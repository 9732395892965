import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useLocation } from "react-router-dom";
import Translate from "../Translator/Translate";
import {
  Box,
  Hidden,
  IconButton,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import CustomIcon from "../CustomIcon/CustomIcon";
import menuItemsService from "../../utilities/menuItems";
import { useMenuItems } from "../../utilities/hooks/useMenuItems";
import clsx from "clsx";
import { ReactComponent as InfoIcon } from "../../Images/icon_info.svg";
import { ReactComponent as TradeIcon } from "../../Images/icon_trademark.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import { isMobileOnly } from "react-device-detect";
import TrackNavLink from "../track-nav-link/track-nav-link";
import EnvironmentBadge from "./env-badge";
import { ReactComponent as FolderIcon } from "../../Images/icon_folder.svg";
import { ReactComponent as DotIcon } from "../../Images/icon_dot.svg";
import Axios from "axios";
import { useDispatch } from "react-redux";

const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary2,
    "&$selected > $content $label, &:focus > $content $label, &:hover > $content $label, &$selected > $content $label:hover, &$selected:focus > $content $label": {
      backgroundColor: "transparent",
    },
    "&[aria-expanded]": {
      "& > $content": {
        color: theme.palette.text.primary2,
      },
      "&$expanded > $content, &$expanded > $content $labelIcon, &$expanded > $content $iconContainer svg": {
        color: theme.palette.text.highlight,
      },
    },
    "&:not([aria-expanded])": {
      "&$active > $content": {
        backgroundColor: theme.palette.secondary2.light,
        color: theme.palette.text.highlight,
        borderRight: "5px solid " + theme.palette.secondary2.main,
      },
      "&$active > $content $labelIcon, &$active > $content $iconContainer svg": {
        color: theme.palette.text.highlight,
      },
    },
  },
  expanded: {},
  iconContainer: {
    marginRight: 20,
  },
  content: {
    flexDirection: "row-reverse",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selected: {},
  active: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  labelIcon: {
    display: "flex",
    marginRight: theme.spacing(1),
    "& svg": {
      width: "20px!important",
      height: "20px!important",
    },
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  dotIcon: {
    width: 8,
    height: 12,
  },
}));

function StyledTreeItem({ menuItem, ignoreChildrenIcon, ...other }) {
  const classes = useStyle();
  const { flatMenuItems } = useMenuItems();
  const location = useLocation();
  const {
    root,
    expanded,
    iconContainer,
    content,
    selected,
    label,
    active,
  } = classes;

  const haveChildren = menuItem.children && menuItem.children.length;

  const getItem = () => {
    return (
      <div className={classes.labelRoot}>
        {!ignoreChildrenIcon ? (
          menuItem.imageOff !== null && menuItem.imageOff[0] !== "d" ? (
            <CustomIcon
              icon={menuItem.imageOff}
              className={classes.labelIcon}
            />
          ) : (
            <Box mr={1}>
              <SvgIcon
                component={haveChildren ? FolderIcon : DotIcon}
                fontSize={"small"}
                className={!haveChildren && classes.dotIcon}
              />
            </Box>
          )
        ) : null}
        <div className={classes.labelText}>
          <Translate needle={menuItem.label} />
        </div>
      </div>
    );
  };

  const itemLabel = () => {
    return menuItem.children && menuItem.children.length ? (
      getItem()
    ) : (
      <TrackNavLink
        className={classes.link}
        to={menuItemsService.getItemUrl(menuItem.url)}
        element={"treeView"}
        labelTo={menuItem.label}
      >
        {getItem()}
      </TrackNavLink>
    );
  };

  const getActive = () => {
    let foundItem = menuItemsService.findItemByLocation(
      location,
      flatMenuItems
    );

    return !!(foundItem && foundItem.id === menuItem.id);
  };

  const isActive = !haveChildren && getActive();

  return (
    <TreeItem
      label={itemLabel()}
      className={clsx({ [active]: isActive })}
      classes={{
        root,
        expanded,
        iconContainer,
        content,
        selected,
        label,
      }}
      {...other}
    />
  );
}

const TreeViewItems = ({
  classes,
  controlVersion,
  parentMenu,
  openModal,
  ignoreChildrenIcon = true,
}) => {
  const location = useLocation();
  const { flatMenuItems } = useMenuItems();
  const getExpanded = () => {
    let foundItem = menuItemsService.findItemByLocation(
      location,
      flatMenuItems
    );
    let selected = [];

    if (foundItem !== undefined) {
      selected = menuItemsService.getSelected(foundItem, flatMenuItems, []);
    }

    return selected;
  };
  const [defaultExpanded, setDefaultExpanded] = React.useState(getExpanded());

  const checkVersionsInSessionStorage = async () => {
    let versions;
    if (
      sessionStorage.getItem("versions") == null ||
      Object.keys(sessionStorage.getItem("versions")).length === 0
    ) {
      versions = await getVersions();
      Object.assign(versions, controlVersion);
      sessionStorage.setItem("versions", JSON.stringify(versions));
    }
    openModal();
  };

  const getVersions = async () => {
    const versions = await Axios({
      method: "get",
      url: `/gov/api/rest/v1/module/versions`,
    });
    if (versions && versions.data) {
      return versions.data;
    }
    throw Error("Invalid response for module versions service");
  };

  const treeItem = (menuItem) => {
    return (
      menuItem && (
        <StyledTreeItem
          key={menuItem.id}
          nodeId={menuItem.id.toString()}
          ignoreChildrenIcon={ignoreChildrenIcon}
          menuItem={menuItem}
          id={"menuItem_" + menuItem.id}
        >
          {menuItem.children.map((subMenu) => treeItem(subMenu))}
        </StyledTreeItem>
      )
    );
  };

  return (
    <>
      <div
        className={clsx(classes.treeviewWrapper, classes.noScrollbar, {
          [classes.fixedWidth]: isMobileOnly,
        })}
      >
        <TreeView
          className={classes.treeviewRoot}
          defaultCollapseIcon={<ExpandLessIcon />}
          defaultExpandIcon={<ExpandMoreIcon />}
          expanded={defaultExpanded}
          onNodeToggle={(e, nodes) => setDefaultExpanded(nodes)}
        >
          {parentMenu && parentMenu.children && parentMenu.children.length
            ? parentMenu.children.map((subMenu) => treeItem(subMenu))
            : treeItem(parentMenu)}
        </TreeView>
      </div>
      <Box
        className={"treeview " + classes.version}
        p={1}
        display="flex"
        alignItems="center"
      >
        <Hidden mdUp>
          <Box mr={1}>
            <SvgIcon component={TradeIcon} />
          </Box>
        </Hidden>
        <EnvironmentBadge />
        IGT Control v
        {controlVersion !== null ? controlVersion.control_version : ""}
        <IconButton onClick={() => checkVersionsInSessionStorage()}>
          <SvgIcon component={InfoIcon} />
        </IconButton>
      </Box>
    </>
  );
};

const style = (theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  treeviewWrapper: {
    height: "calc(100% - 40px)",
    overflowY: "scroll",
  },
  noScrollbar: {
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  fixedWidth: {
    width: 296,
  },
  fakeHeader: {
    height: 37,
  },
  version: {
    wordWrap: "break-word",
    whiteSpace: "normal",
    borderTop: `1px solid ${theme.palette.divider}`,
    height: 40,
  },
});
export default withStyles(style)(TreeViewItems);
