import { useSelector } from "react-redux";
import moment from "moment";

export const useLocale = () => {
  const { locale } = useSelector((state) => state.MainReducer);
  const formatLT = moment.localeData().longDateFormat("LT");
  const formatLTLength = moment.localeData().longDateFormat("LT").length;
  const isAmPm =
    ["a", "A"].indexOf(formatLT.substring(formatLTLength - 1)) !== -1;
  let parts = Intl.NumberFormat(locale.replace("_", "-")).formatToParts(1000.1);
  let decimalSeparator = parts.find((it) => it.type === "decimal").value;
  let check = parts.find((it) => it.type === "group");
  let thousandSeparator ="";
  if(check !== undefined){
    thousandSeparator=check.value;
  }
  return { locale, isAmPm, decimalSeparator, thousandSeparator };
};
