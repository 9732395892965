import {
  GAME_PLATFORMS_SUCCESS,
  PLATFORMS_SUCCESS,
  PLATFORMS_FAILURE,
  SIMPLE_PLATFORMS_SUCCESS,
  SIMPLE_PLATFORMS_FAILURE,
  PROGRAM_PLATFORMS_SUCCESS,
  PROGRAM_PLATFORMS_FAILURE,
} from "../constants";

const INITIAL_STATE = {
  platforms: [],
  platformList: [],
  simplePlatforms: [],
  programPlatforms: [],
};

const GamePlatformsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GAME_PLATFORMS_SUCCESS:
      return {
        ...state,
        platforms: action.payload,
      };

    case PLATFORMS_SUCCESS: {
      return {
        ...state,
        platformList: action.payload,
      };
    }

    case PLATFORMS_FAILURE: {
      return {
        ...state,
        platformList: null,
      };
    }

    case SIMPLE_PLATFORMS_SUCCESS: {
      return {
        ...state,
        simplePlatforms: action.payload,
      };
    }

    case SIMPLE_PLATFORMS_FAILURE: {
      return {
        ...state,
        simplePlatforms: null,
      };
    }

    case PROGRAM_PLATFORMS_SUCCESS: {
      return {
        ...state,
        programPlatforms: action.payload,
      };
    }

    case PROGRAM_PLATFORMS_FAILURE: {
      return {
        ...state,
        programPlatforms: null,
      };
    }

    default:
      return state;
  }
};

export default GamePlatformsReducer;
