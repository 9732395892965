import {
  INDIVIDUAL_PLAYER_GROUPS_SUCCESS,
  INDIVIDUAL_PLAYER_GROUPS_FAILURE,
  DELETE_INDIVIDUAL_PLAYER_GROUPS_FAILURE,
  INDIVIDUAL_PLAYER_GROUP_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_GROUP_LIST_FAILURE,
  DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST,
  SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST,
  SAVE_INDIVIDUAL_PLAYER_GROUP_FAILURE,
  INDIVIDUAL_PLAYER_GROUPS_REQUEST,
} from "../../../constants";

const initialState = {
  loading: true,
  individualPlayerGroups: {
    data: [],
    idKey: "name",
    columns: [
      {
        field: "name",
        title: "label.individualplayer.playerGroups.groupName",
        default: true,
      },
      {
        field: "gmsDlvContactType.contactTypeName",
        title: "label.individualplayer.playerGroups.groupType",
      },
    ],
  },
  individualPlayerGroupList: {
    groups: [],
  },
};

const IndividualPlayerGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_PLAYER_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        individualPlayerGroups: {
          ...state.individualPlayerGroups,
          data: action.payload,
        },
      };
    }
    case INDIVIDUAL_PLAYER_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        individualPlayerGroups: {
          ...state.individualPlayerGroups,
          data: null,
        },
      };
    }
    case DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST:
    case SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST:
    case INDIVIDUAL_PLAYER_GROUPS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_INDIVIDUAL_PLAYER_GROUPS_FAILURE:
    case SAVE_INDIVIDUAL_PLAYER_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case INDIVIDUAL_PLAYER_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        individualPlayerGroupList: {
          ...state.individualPlayerGroups,
          groups: action.payload,
        },
      };
    }
    case INDIVIDUAL_PLAYER_GROUP_LIST_FAILURE: {
      return {
        ...state,
        individualPlayerGroupList: {
          ...state.individualPlayerGroups,
          groups: null,
        },
      };
    }
    default:
      return state;
  }
};

export default IndividualPlayerGroupsReducer;
