import { useSession } from "../../../../utilities/hooks/useSession";
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { Box } from "@material-ui/core";
import Translate from "../../../../Layouts/Translator/Translate";
import { connect } from "react-redux";
import SelectField from "../../../../Layouts/form/select-field";
import Button from "@material-ui/core/Button";
import { regenerateCard } from "../../../../store/players/individual-player/club-card/actions";
import { useReasonsForActions } from "../../../../utilities/hooks/useReasonsForActions";
import { useLocale } from "../../../../utilities/hooks/useLocale";

function RegenerateCardForm({ idCard, contractId, handleCloseModal, save }) {
  const t = useTranslator();
  const { brandSelected, partnerSelected } = useSession();
  const { locale } = useLocale();
  const reason = useReasonsForActions(
    brandSelected,
    partnerSelected,
    "Card_Regenerate",
    locale
  );

  let initialValues = {
    reason: "",
    cardNumber: idCard,
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          reason: Yup.string().required(
            t({
              needle:
                "label.player.clubCardDetails.regenerate.errorsDescription",
            })
          ),
        })}
        onSubmit={(fields) => {
          delete fields["_meta"];
          save(
            brandSelected,
            partnerSelected,
            fields,
            contractId,
            handleCloseModal
          );
        }}
        render={() => (
          <Form>
            <Box p={2} pt={0}>
              <span>
                <Translate
                  needle={"label.player.clubCardDetails.regenerate.cardNumber"}
                  variables={[idCard]}
                />
              </span>
            </Box>
            <Box p={2} pt={0}>
              <span>
                {
                  <Translate
                    needle={"label.player.clubCardDetails.regenerate.request"}
                  />
                }
              </span>
            </Box>
            <Box p={2} pt={0}>
              <SelectField
                labelKey={"reasonDescription"}
                label={
                  <Translate
                    needle={"label.player.clubCardDetails.regenerate.reasons"}
                  />
                }
                options={reason}
                name={"reason"}
                valueKey={"reasonDescription"}
                fullWidth
              />
            </Box>
            <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
              <Box mr={1}>
                <Button variant={"outlined"} onClick={() => handleCloseModal()}>
                  <Translate needle={"button.cancel"} />
                </Button>
              </Box>
              <Box>
                <Button color={"primary"} type="submit">
                  <Translate needle={"button.yes"} />{" "}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      />
    </>
  );
}

const mapDispatchToProps = {
  save: regenerateCard,
};
const mapStateToProps = (state) => {};
export default connect(mapStateToProps, mapDispatchToProps)(RegenerateCardForm);
