import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import history from "./history";
// Redux Initialization
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import setupAxiosInterceptors from "./Interceptor/axiosInterceptor";
import sagas from "./sagas";
import reducers from "./reducers";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import axios from "axios";

const sagaMiddleware = createSagaMiddleware();

axios
  .get(process.env.PUBLIC_URL + "/analytics-setting.json")
  .then((response) => {
    if (response.data.analytics) {
      import("./analytics/index").then((AnalyticsModule) => {
        const { initAnalyticsTool, AnalyticsReducer } = AnalyticsModule;
        const options = {
          trackingCode: response.data.trackingCode,
          options: {
            debug: response.data.options.debug,
          },
        };
        const analyticsTool = initAnalyticsTool(
          Store,
          response.data.analyticsToolName,
          options
        );
        // eslint-disable-next-line
        const unlisten = history.listen(analyticsTool.trackHistoryChanges);
        reducers["analytics"] = AnalyticsReducer;
        Store.replaceReducer(combineReducers(reducers));
      });
    }
  });

const Store = createStore(
  combineReducers(reducers),
  {},
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(sagas);

setupAxiosInterceptors(Store);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={Store}>
    <Router history={history}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
  rootElement
);
