import {
  BULK_OPERATION_LIST_FAILURE,
  BULK_OPERATION_LIST_REQUEST,
  BULK_OPERATION_LIST_SEARCH_CRITERIA_FAILURE,
  BULK_OPERATION_LIST_SEARCH_CRITERIA_REQUEST,
  BULK_OPERATION_LIST_SEARCH_CRITERIA_SUCCESS,
  BULK_OPERATION_LIST_SUCCESS,
  BULK_OPERATION_LIST_VIEW_REQUEST,
  BULK_OPERATION_LIST_VIEW_SUCCESS,
  BULK_OPERATION_TRANSACTIONS_FAILURE,
  BULK_OPERATION_TRANSACTIONS_SUCCESS,
  STYLE_LABEL,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import * as moment from "moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import { FormattedNumber } from "react-intl";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import Currency from "../../../../Layouts/Currency/Currency";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";

const getStatusMapping = (status) => {
  switch (status) {
    case "COMPLETED_SUCCESS":
      return { id: "2", label: "completed" };
      case "APPROVED":
        return { id: "1", label: "approved" };
    case "COMPLETED_ERROR":
      return { id: "5", label: "completedWithErrors" };
    case "REJECTED":
      return { id: "5", label: "rejected" };
    case "TO_BE_APPROVED":
      return { id: "3", label: "pendingApproval" };
    case "TO_BE_APPROVED_FOR_OVERBUDGET":
      return { id: "3", label: "overBudget" };
    case "OK":
      return { id: "2", label: "success" };
    case "KO":
      return { id: "5", label: "failed" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const initialState = {
  bulkOperationList: {
    idKey: "massiveId",
    searchCriteria: [],
    data: [],
    loading: true,
    filteredColumns: [],
    fieldsLoading: true,
    columns: [
      {
        field: "massiveId",
        title: "label.massiveRewards.requestId",
        default: true,
      },
      {
        field: "requestName",
        title: "label.massiveRewards.requestName",
        default: true,
      },
      { field: "playerGroupName", title: "label.massiveRewards.groupName" },
      {
        field: "status",
        title: "label.massiveRewards.requestStatus",
        nowrap: false,
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapped = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
                fullWidth
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["status"]) {
            const statusMapping = getStatusMapping(rowData["status"]);
            return {
              style: STYLE_LABEL,
              value: `label.status.${statusMapping.label}`,
            };
          }
        },
      },
      {
        field: "affectedPlayers",
        title: "label.massiveRewards.affectedPlayers",
      },
      {
        field: "rewardTypeId",
        title: "label.massiveRewards.rewardType",
        render: (rowData) => {
          return (
            <Translate needle={"label.rewardType." + rowData["rewardTypeId"]} />
          );
        },
        getExportTypeFn: (rowData) => {
          if (rowData["rewardTypeId"]) {
            return {
              style: STYLE_LABEL,
              value: `label.rewardType.${rowData["rewardTypeId"]}`,
            };
          }
        },
      },
      {
        field: "startDate",
        title: "label.massiveRewards.startDate",
        render: (rowData) => {
          if (rowData["startDate"]) {
            return <Moment format="L LT">{rowData["startDate"]}</Moment>;
          } else {
            return null;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["startDate"]) {
            return moment(rowData["startDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "endDate",
        title: "label.massiveRewards.endDate",
        defaultSort: "desc",
        render: (rowData) => {
          if (rowData["endDate"]) {
            return <Moment format="L LT">{rowData["endDate"]}</Moment>;
          } else {
            return null;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["endDate"]) {
            return moment(rowData["endDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
    ],
  },
  operationsView: {
    loading: true,
    massiveRewardDetail: {
      fields: [
        {
          field: "massiveId",
          title: "label.massiveRewards.requestId",
        },
        { field: "requestName", title: "label.massiveRewards.requestName" },
        { field: "description", title: "label.massiveRewards.description" },
        { field: "userCreator", title: "label.massiveRewards.userCreator" },
        {
          field: "creationDate",
          title: "label.massiveRewards.creationDate",
          render: (data) => {
            return <Moment format="L LT">{data.creationDate}</Moment>;
          },
        },
        {
          field: "startDate",
          title: "label.massiveRewards.startDate",
          render: (data) => {
            return <Moment format="L LT">{data.startDate}</Moment>;
          },
        },
        {
          field: "endDate",
          title: "label.massiveRewards.endDate",
          render: (data) => {
            return <Moment format="L LT">{data.endDate}</Moment>;
          },
        },
        {
          field: "affectedPlayers",
          title: "label.massiveRewards.affectedPlayers",
        },
        {
          field: "status",
          title: "label.massiveRewards.requestStatus",
          render: (data) => {
            const statusMapped = getStatusMapping(data.status);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
                fullWidth
              />
            );
          },
        },
        { field: "groupName", title: "label.massiveRewards.groupName" },
        { field: "communication", title: "label.massiveRewards.communication" },
        { field: "brand", title: "label.massiveRewards.brand" },
        { field: "partner", title: "label.massiveRewards.partner" },
      ],
    },
    reward: {
      fields: (rewardTypeId) => {
        const cash = [
          {
            field: "amount",
            render: (rowData) => {
              if (rowData["amount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amount"]}
                  />
                );
              }
            },
          },
          {
            field: "currency",
            render: (rowData) => {
              if (rowData["currency"]) {
                return <CurrencyBadge currency={rowData["currency"]} />;
              }
            },
          },
          { field: "reason" },
        ];

        const loyaltyPoint = [
          {
            title: "label.bulkoperationadd.gameDomains",
            field: "loyaltyDomain.name",
          },
          {
            field: "points",
            render: (rowData) => {
              if (rowData["points"]) {
                return <FormattedNumber value={rowData["points"]} />;
              }
            },
          },
          { field: "reason" },
        ];

        const eboBonus = [
          {
            field: "amount",
            render: (rowData) => {
              if (rowData["amount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amount"]}
                  />
                );
              }
            },
          },
          {
            field: "currency",
            render: (rowData) => {
              if (rowData["currency"]) {
                return <CurrencyBadge currency={rowData["currency"]} />;
              }
            },
          },
          {
            title: "label.bulkoperationadd.platform",
            field: "gameDomainId.name",
          },
          { field: "reason" },
        ];

        const bonusDebit = [
          {
            title: "label.bulkoperationadd.operation",
            field: "operationId.name",
          },
          {
            field: "amount",
            render: (rowData) => {
              if (rowData["amount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amount"]}
                  />
                );
              }
            },
          },
          { field: "reason" },
        ];

        const tournamentCredits = [
          {
            field: "amount",
            render: (rowData) => {
              if (rowData["amount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amount"]}
                  />
                );
              }
            },
          },
          {
            field: "currency",
            render: (rowData) => {
              if (rowData["currency"]) {
                return <CurrencyBadge currency={rowData["currency"]} />;
              }
            },
          },
          { field: "reason" },
        ];

        const ewlCredit = [
          {
            title: "label.bulkoperationadd.operation",
            field: "operationId.name",
          },
          {
            field: "currency",
            render: (rowData) => {
              if (rowData["currency"]) {
                return <CurrencyBadge currency={rowData["currency"]} />;
              }
            },
          },
          {
            field: "amount",
            render: (rowData) => {
              if (rowData["amount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amount"]}
                  />
                );
              }
            },
          },
          { field: "reason" },
        ];

        const voucherCredit = [
          {
            title: "label.bulkoperationadd.voucherTemplate",
            field: "templateName",
          },
          {
            field: "rewardTypeId",
            render: (rowData) => {
              return (
                <Translate
                  needle={"label.rewardType." + rowData["rewardTypeId"]}
                />
              );
            },
          },
          {
            field: "voucherValue",
            render: (rowData) => {
              if (rowData["voucherValue"]) {
                if (rowData["voucherValueType"] === "LOYALTY") {
                  return <FormattedNumber value={rowData["voucherValue"]} />;
                } else {
                  return <Currency amount={rowData["voucherValue"]} />;
                }
              }
            },
          },
          { field: "voucherValueType" },
        ];

        const freeSpin = [
          {
            field: "currency",
            render: (rowData) => {
              if (rowData["currency"]) {
                return <CurrencyBadge currency={rowData["currency"]} />;
              }
            },
          },
          { field: "description" },
          {
            title: "label.bulkoperationadd.startDate",
            field: "activationDate",
            render: (rowData) => {
              if (rowData["activationDate"]) {
                return (
                  <Moment format="L LT">{rowData["activationDate"]}</Moment>
                );
              }
            },
          },
          {
            title: "label.bulkoperationadd.endDate",
            field: "expirationDate",
            render: (rowData) => {
              if (rowData["expirationDate"]) {
                return (
                  <Moment format="L LT">{rowData["expirationDate"]}</Moment>
                );
              }
            },
          },
          {
            title: "label.bulkoperationadd.gameDomains",
            field: "gamePlatform.name",
          },
          { title: "label.bulkoperationadd.game", field: "gameCode.name" },
          {
            title: "label.bulkoperationadd.valueFS",
            field: "amountSingleFreeSpin",
            render: (rowData) => {
              if (rowData["amountSingleFreeSpin"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amountSingleFreeSpin "]}
                  />
                );
              }
            },
          },
          { title: "label.bulkoperationadd.valueFS", field: "freeSpinNumber" },
          {
            title: "label.bulkoperationadd.totalAmount",
            field: "amount",
            render: (rowData) => {
              if (rowData["amount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amount"]}
                  />
                );
              }
            },
          },
          {
            title: "label.bulkoperationadd.template",
            field: "bbTemplate.name",
          },
          { title: "label.bulkoperationadd.multiplier", field: "bbMultiplier" },
          { title: "label.bulkoperationadd.duration", field: "bbValidityDays" },
        ];

        const freeTicket = [
          { field: "name" },
          { field: "description" },
          {
            field: "amount",
            render: (rowData) => {
              if (rowData["amount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["amount"]}
                  />
                );
              }
            },
          },
          {
            field: "currency",
            render: (rowData) => {
              if (rowData["currency"]) {
                return <CurrencyBadge currency={rowData["currency"]} />;
              }
            },
          },
          { field: "granularity" },
          {
            title: "label.bulkoperationadd.startDate",
            field: "activationDate",
            render: (rowData) => {
              if (rowData["activationDate"]) {
                return (
                  <Moment format="L LT">{rowData["activationDate"]}</Moment>
                );
              }
            },
          },
          {
            title: "label.bulkoperationadd.endDate",
            field: "expirationDate",
            render: (rowData) => {
              if (rowData["expirationDate"]) {
                return (
                  <Moment format="L LT">{rowData["expirationDate"]}</Moment>
                );
              }
            },
          },
          {
            field: "gamePlatforms",
            render: (rowData) => {
              if (
                rowData["gamePlatforms"] &&
                rowData["gamePlatforms"].length > 0
              ) {
                return rowData["gamePlatforms"].map((it) => it.name).join(", ");
              }
            },
          },
          {
            field: "gameCodes",
            title: "label.bulkoperationadd.gameList",
            render: (rowData) => {
              if (rowData["gameCodes"] && rowData["gameCodes"].length > 0) {
                return rowData["gameCodes"].map((it) => it.name).join(", ");
              }
            },
          },
          { field: "multiplayerSessionName" },
          { field: "chargeTo" },
          { field: "divisible" },
          { field: "exactValue" },
          { field: "sitAndGo" },
          { field: "scheduled" },
        ];

        var bonusBag = [
          { field: "name" },
          { field: "description" },
          { field: "constantPriority" },
          {
            title: "label.bulkoperationadd.bonusTypeId",
            field: "bonusTypeId.name",
          },
          {
            title: "label.bulkoperationadd.bbmQrType",
            field: "bbmQrType.name",
          },
          {
            field: "currency",
            render: (rowData) => {
              if (rowData["currency"]) {
                return <CurrencyBadge currency={rowData["currency"]} />;
              }
            },
          },
          {
            field: "originalAmount",
            render: (rowData) => {
              if (rowData["originalAmount"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["originalAmount"]}
                  />
                );
              }
            },
          },
          {
            field: "usableGamePlatforms",
            render: (rowData) => {
              if (
                rowData["usableGamePlatforms"] &&
                rowData["usableGamePlatforms"].length > 0
              ) {
                return rowData["usableGamePlatforms"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "usableGameCodes",
            render: (rowData) => {
              if (
                rowData["usableGameCodes"] &&
                rowData["usableGameCodes"].length > 0
              ) {
                return rowData["usableGameCodes"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "usableFamilies",
            render: (rowData) => {
              if (
                rowData["usableFamilies"] &&
                rowData["usableFamilies"].length > 0
              ) {
                return rowData["usableFamilies"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "usableDevices",
            render: (rowData) => {
              if (
                rowData["usableDevices"] &&
                rowData["usableDevices"].length > 0
              ) {
                return rowData["usableDevices"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "feedableGamePlatforms",
            render: (rowData) => {
              if (
                rowData["feedableGamePlatforms"] &&
                rowData["feedableGamePlatforms"].length > 0
              ) {
                return rowData["feedableGamePlatforms"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "feedableGameCodes",
            render: (rowData) => {
              if (
                rowData["feedableGameCodes"] &&
                rowData["feedableGameCodes"].length > 0
              ) {
                return rowData["feedableGameCodes"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "feedableFamilies",
            render: (rowData) => {
              if (
                rowData["feedableFamilies"] &&
                rowData["feedableFamilies"].length > 0
              ) {
                return rowData["feedableFamilies"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "feedableDevices",
            render: (rowData) => {
              if (
                rowData["feedableDevices"] &&
                rowData["feedableDevices"].length > 0
              ) {
                return rowData["feedableDevices"]
                  .filter((it) => it && it.name !== "undefined")
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            title: "label.bulkoperationadd.conversionDestinationGameDomain",
            field: "conversionDestinationGameDomain.name",
          },
          {
            field: "wageringRequirement",
            render: (rowData) => {
              if (rowData["wageringRequirement"]) {
                if (rowData["multiplierRequired"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["wageringRequirement"]}
                    />
                  );
                } else {
                  return (
                    <FormattedNumber value={rowData["wageringRequirement"]} />
                  );
                }
              }
            },
          },
          {
            field: "conversionRequirement",
            render: (rowData) => {
              if (rowData["conversionRequirement"]) {
                if (rowData["multiplierRequired"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["conversionRequirement"]}
                    />
                  );
                } else {
                  return (
                    <FormattedNumber value={rowData["conversionRequirement"]} />
                  );
                }
              }
            },
          },
          {
            field: "toPay",
            render: (rowData) => {
              if (rowData["toPay"]) {
                return (
                  <Currency
                    currency={rowData["currency"]}
                    amount={rowData["toPay"]}
                  />
                );
              }
            },
          },
          {
            field: "toGive",
            render: (rowData) => {
              if (rowData["toGive"]) {
                if (rowData["multiplierRequired"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["toGive"]}
                    />
                  );
                } else {
                  return <FormattedNumber value={rowData["toGive"]} />;
                }
              }
            },
          },
          { field: "optIn" },
          { field: "reedemable" },
          { field: "usable" },
          {
            field: "validityDateType.name",
            title: "label.bulkoperationadd.validityDateType",
            render: (rowData) => {
              if (rowData["validityDateType"]) {
                return (
                  <Translate
                    needle={`label.bulkoperationadd.${rowData["validityDateType"].name}`}
                  />
                );
              }
            },
          },
          {
            field: "validityDays",
            render: (rowData) => {
              if (rowData["validityDays"]) {
                return <FormattedNumber value={rowData["validityDays"]} />;
              }
            },
          },
          {
            field: "validityHours",
            render: (rowData) => {
              if (rowData["validityHours"]) {
                return <FormattedNumber value={rowData["validityHours"]} />;
              }
            },
          },
          {
            field:"expirationDate",
            render: (rowData) => {
              if (rowData["validityDateType"].value==="1" && rowData["expirationDate"]) {
                return (
                    <Moment format="L LT">{rowData["expirationDate"]}</Moment>
                );
              }
            }
          }
        ];

        switch (rewardTypeId) {
          case "BONUS_BAG": {
            return bonusBag;
          }
          case "CASH": {
            return cash;
          }
          case "FREE_SPIN": {
            return freeSpin;
          }
          case "FREE_TICKET": {
            return freeTicket;
          }
          case "LOYALTY_POINT": {
            return loyaltyPoint;
          }
          case "EBO_BONUS": {
            return eboBonus;
          }
          case "BONUS_DEBIT": {
            return bonusDebit;
          }
          case "TOURNAMENT_CREDITS": {
            return tournamentCredits;
          }
          case "EWL_BONUS_CREDIT":
          case "EWL_CASH_CREDIT": {
            return ewlCredit;
          }
          case "DISCOUNT_MONEY_VOUCHER":
          case "DISCOUNT_LOYALTY_VOUCHER":
          case "MERCHANDISE_MONEY_VOUCHER":
          case "MERCHANDISE_LOYALTY_VOUCHER":
            return voucherCredit;
          default: {
            return null;
          }
        }
      },
    },
  },
  bulkOperationTransactions: {
    idKey: "gameAccount",
    searchCriteria: [],
    data: [],
    filteredColumns: [],
    columns: [
      {
        field: "gameAccount",
        title: "label.massiveRewards.transactions.contractId",
        default: true,
      },
      {
        field: "lastExecutionDate",
        title: "label.massiveRewards.transactions.lastExecutionDate",
        default: true,
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["lastExecutionDate"]} />
        ),
      },
      {
        field: "result",
        title: "label.massiveRewards.transactions.resultCode",
        render: (rowData) => {
          if (rowData["result"]) {
            const statusMapped = getStatusMapping(rowData["result"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["result"]) {
            const statusMapping = getStatusMapping(rowData["result"]);
            return {
              style: STYLE_LABEL,
              value: `label.status.${statusMapping.label}`,
            };
          } else return "";
        },
      },
      {
        field: "resultDescription",
        title: "label.massiveRewards.transactions.resultCodeDescription",
      },
      {
        field: "externalTransactionId",
        title: "label.massiveRewards.transactions.externalTransactionId",
      },
      {
        field: "communicationSentDate",
        title: "label.massiveRewards.transactions.communicationSent",
        render: (rowData) => {
          if (rowData["communicationSentDate"]) {
            return (
              <Moment format="L LT">{rowData["communicationSentDate"]}</Moment>
            );
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["communicationSentDate"]) {
            return moment(rowData["communicationSentDate"]).format("L LT");
          } else return "";
        },
      },
    ],
  },
};

const BulkOperationListReducer = (state = initialState, action) => {
  switch (action.type) {
    case BULK_OPERATION_LIST_SEARCH_CRITERIA_REQUEST: {
      return {
        ...state,
        bulkOperationList: {
          ...state.bulkOperationList,
          fieldsLoading: true,
        },
      };
    }

    case BULK_OPERATION_LIST_SEARCH_CRITERIA_SUCCESS: {
      return {
        ...state,
        bulkOperationList: {
          ...state.bulkOperationList,
          searchCriteria: action.payload,
          fieldsLoading: false,
        },
      };
    }

    case BULK_OPERATION_LIST_SEARCH_CRITERIA_FAILURE: {
      return {
        ...state,
        bulkOperationList: {
          ...state.bulkOperationList,
          searchCriteria: [],
          fieldsLoading: false,
        },
      };
    }

    case BULK_OPERATION_LIST_REQUEST: {
      return {
        ...state,
        bulkOperationList: {
          ...state.bulkOperationList,
          lastRequest: action,
          loading: true,
        },
      };
    }

    case BULK_OPERATION_LIST_SUCCESS: {
      return {
        ...state,
        bulkOperationList: {
          ...state.bulkOperationList,
          fullData: action.payload.fullData,
          data: action.payload.data,
          loading: false,
        },
      };
    }

    case BULK_OPERATION_LIST_FAILURE: {
      return {
        ...state,
        bulkOperationList: {
          ...state.bulkOperationList,
          data: null,
          loading: false,
        },
      };
    }

    case BULK_OPERATION_LIST_VIEW_REQUEST: {
      return {
        ...state,
        operationsView: {
          ...state.operationsView,
          lastRequest: action,
          loading: true,
        },
      };
    }
    case BULK_OPERATION_LIST_VIEW_SUCCESS: {
      const filteredItem = action.payload;

      let data = null;
      let reward = null;
      if (filteredItem) {
        data = {
          massiveId: filteredItem.massiveId,
          requestName: filteredItem.requestName,
          description: filteredItem.description,
          userCreator: filteredItem.userCreator,
          creationDate: filteredItem.creationDate,
          startDate: filteredItem.startDate,
          endDate: filteredItem.endDate,
          affectedPlayers: filteredItem.affectedPlayers,
          status: filteredItem.status,
          groupName: filteredItem.playerGroupName,
          communication: filteredItem.communicationName,
          brand: filteredItem.brandId,
          partner: filteredItem.partnerId,
        };
        reward = filteredItem.reward;
      }

      return {
        ...state,
        operationsView: {
          ...state.operationsView,
          loading: false,
          massiveRewardDetail: {
            ...state.operationsView.massiveRewardDetail,
            data,
          },
          reward: { ...state.operationsView.reward, data: reward },
          filteredItem: filteredItem,
        },
      };
    }

    case BULK_OPERATION_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        bulkOperationTransactions: {
          ...state.bulkOperationTransactions,
          data: action.payload,
        },
      };
    }

    case BULK_OPERATION_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        bulkOperationTransactions: {
          ...state.bulkOperationTransactions,
          data: null,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default BulkOperationListReducer;
