import axios from "axios";

class PepService {
  async triggerPepProcess(contractId, pepStatus, note) {
    let url = "/gov/api/poland/v1/pep";
    let requestBody = {
      contractId,
      state: pepStatus,
      reason: note,
    };
    const response = await axios.post(url, requestBody);

    if (response && response.status !== 204) {
      throw Error("Invalid response for pep service");
    }
  }

  async editPepNotes(contractId, notes) {
    let url = "/gov/api/poland/v1/pep/notes";
    let requestBody = {
      contractId,
      notes,
    };
    const response = await axios.post(url, requestBody);

    if (response && response.status !== 204) {
      throw Error("Invalid response for pep service");
    }
  }
}

const pepService = new PepService();
export default pepService;
