import React from "react";
import * as DOMPurify from 'dompurify';
import PropTypes from "prop-types";

const UnsafeHtml = ({dangerouslySetInnerHTML, tag='span', component, ...others})=>{

    const sanitizedContent = DOMPurify.sanitize(dangerouslySetInnerHTML.__html);
    const Component = component == null ? tag : component;
    return (
        <Component {...others} dangerouslySetInnerHTML={{__html: sanitizedContent}}/>
    );
}

UnsafeHtml.propTypes = {
    dangerouslySetInnerHTML: PropTypes.shape({
        __html: PropTypes.string.isRequired
    }),
    tag: PropTypes.element,
    component: PropTypes.elementType
};

export default UnsafeHtml;