import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getIndividualPlayerDirectNotificationsHistoryHandler } from "../../store/players/individual-player/direct-notifications/actions";

export const useIndividualPlayerDirectNotificationsHistory = (
  contractId,
  brand,
  partner,
  pageSize,
  pageNumber
) => {
  const { data } = useSelector(
    (state) => state.DirectNotificationsReducer.directNotificationsHistoryList
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getIndividualPlayerDirectNotificationsHistoryHandler(
        contractId,
        brand,
        partner,
        pageSize,
        pageNumber
      )
    );
  }, [dispatch, contractId, brand, partner, pageSize, pageNumber]);

  return data;
};
