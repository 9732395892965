import axios from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GAME_PLATFORMS_REQUEST,
  GAME_PLATFORMS_SUCCESS,
  PLATFORMS_REQUEST,
  PLATFORMS_SUCCESS,
  PLATFORMS_FAILURE,
  SIMPLE_PLATFORMS_SUCCESS,
  SIMPLE_PLATFORMS_REQUEST,
  SIMPLE_PLATFORMS_FAILURE,
  PROGRAM_PLATFORMS_SUCCESS,
  PROGRAM_PLATFORMS_FAILURE,
  PROGRAM_PLATFORMS_REQUEST,
} from "../constants";
import gamePlatformService from "./game-platforms-service";

const platformsFetching = async () => {
  const response = await axios.get("/gameadmin/api/v1/platforms");
  return response.data;
};

const loadPlatforms = function* (action) {
  let data = [];
  try {
    data = yield call(platformsFetching, action.payload);
  } catch (e) {
    console.error(e);
  }

  yield put({
    type: GAME_PLATFORMS_SUCCESS,
    payload: data,
  });
};

const getPlatforms = function* (action) {
  try {
    const data = yield call(
      gamePlatformService.getPlatforms,
      action.queryParams
    );
    yield put({
      type: PLATFORMS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("platforms", e);
    yield put({
      type: PLATFORMS_FAILURE,
    });
  }
};

const getSimplePlatforms = function* (action) {
  try {
    const data = yield call(gamePlatformService.getSimplePlatforms);
    yield put({
      type: SIMPLE_PLATFORMS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("programPlatforms", e);
    yield put({
      type: SIMPLE_PLATFORMS_FAILURE,
    });
  }
};

const getProgramPlatforms = function* (action) {
  try {
    const data = yield call(
      gamePlatformService.getProgramPlatforms,
      action.brand,
      action.partner
    );
    yield put({
      type: PROGRAM_PLATFORMS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("simplePlatforms", e);
    yield put({
      type: PROGRAM_PLATFORMS_FAILURE,
    });
  }
};

const GamePlatformsSaga = function* () {
  yield takeEvery(GAME_PLATFORMS_REQUEST, loadPlatforms);
  yield takeLatest(PLATFORMS_REQUEST, getPlatforms);
  yield takeLatest(SIMPLE_PLATFORMS_REQUEST, getSimplePlatforms);
  yield takeLatest(PROGRAM_PLATFORMS_REQUEST, getProgramPlatforms);
};

export default GamePlatformsSaga;
