import { call, put } from "redux-saga/effects";
import {
  FREE_SPIN_SEARCH_CRITERIA_SUCCESS,
  FREE_SPIN_SEARCH_CRITERIA_FAILURE,
  FREE_SPIN_SEARCH_CRITERIA_REQUEST,
  FREE_SPIN_LIST_REQUEST,
  FREE_SPIN_LIST_SUCCESS,
  FREE_SPIN_LIST_FAILURE,
  FREE_SPIN_DELETE_REQUEST,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import freeSpinService from "./free-spin-service";

const loadSearchCriteria = function* (action) {
  try {
    const filters = yield call(
      freeSpinService.getFilterFields,
      action.brand,
      action.partner
    );
    yield put({
      type: FREE_SPIN_SEARCH_CRITERIA_SUCCESS,
      payload: filters,
    });
  } catch (e) {
    console.error("free spin filter", e);
    yield put({
      type: FREE_SPIN_SEARCH_CRITERIA_FAILURE,
    });
  }
};

const loadFreeSpin = function* (action) {
  try {
    let searchTerms = {
      creationFrom: action.terms.creationPeriod.from
        ? action.terms.creationPeriod.from
        : "",
      creationTo: action.terms.creationPeriod.to
        ? action.terms.creationPeriod.to
        : "",
      validityFrom: action.terms.validityPeriod.from
        ? action.terms.validityPeriod.from
        : "",
      validityTo: action.terms.validityPeriod.to
        ? action.terms.validityPeriod.to
        : "",
      gameDomains: action.terms.gameDomain,
      gameTypes: action.terms.gameCode,
      campaignId: action.terms.campaignId,
      status: action.terms.status,
    };
    const data = yield call(
      freeSpinService.getFreeSpin,
      action.contractId,
      action.brandId,
      action.partnerId,
      searchTerms
    );
    yield put({
      type: FREE_SPIN_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("free spin list", e);
    yield put({
      type: FREE_SPIN_LIST_FAILURE,
    });
  }
};

const deleteFreeSpin = function* (action) {
  try {
    const { terms, brandId, partnerId, contractId } = action;
    yield call(
      freeSpinService.deleteFreeSpin,
      action.contractId,
      action.ticketId
    );
    yield put({
      type: FREE_SPIN_LIST_REQUEST,
      terms,
      brandId,
      partnerId,
      contractId,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.individualplayer.freeSpin.delete_successfully",
        },
        options: {
          key: "DeleteFreeSpin",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
  } catch (e) {
    console.error("free spin list", e);
    yield put({
      type: FREE_SPIN_LIST_FAILURE,
    });
  }
};

const FreeSpinSaga = function* freeSpinSaga() {
  yield takeLatest(FREE_SPIN_SEARCH_CRITERIA_REQUEST, loadSearchCriteria);
  yield takeLatest(FREE_SPIN_LIST_REQUEST, loadFreeSpin);
  yield takeLatest(FREE_SPIN_DELETE_REQUEST, deleteFreeSpin);
};

export default FreeSpinSaga;
