import {
  PLAYER_NOTE_DELETE_REQUEST,
  PLAYER_NOTE_REQUEST,
  PLAYER_NOTE_SAVE_REQUEST,
  PLAYER_NOTES_PRIORITIES_REQUEST,
  PLAYER_NOTES_REQUEST,
  PLAYER_NOTES_TYPES_REQUEST,
} from "../../../constants";

export const getPlayerNotes = (contractId, brand, partner) => {
  return {
    type: PLAYER_NOTES_REQUEST,
    contractId,
    brand,
    partner,
  };
};

export const getPriorities = () => {
  return {
    type: PLAYER_NOTES_PRIORITIES_REQUEST,
  };
};

export const getTypes = () => {
  return {
    type: PLAYER_NOTES_TYPES_REQUEST,
  };
};

export const getNoteHistory = (noteId) => {
  return {
    type: PLAYER_NOTE_REQUEST,
    noteId,
  };
};

export const deletePlayerNote = (brand, partner, contractId, noteId) => {
  return {
    type: PLAYER_NOTE_DELETE_REQUEST,
    brand,
    partner,
    noteId,
    contractId,
  };
};

export const savePlayerNote = (brand, partner, contractId, note) => {
  return {
    type: PLAYER_NOTE_SAVE_REQUEST,
    brand,
    partner,
    contractId,
    note,
  };
};
