import axios from "axios";

class RGLPlayerLimitsService {
    async getPlayerLimits(brand, partner, contractId, group) {
        const response = await axios.get(
            `/gov/api/rest/v1/limits/${contractId}`,
            {
                headers: {
                    "X-BRAND-ID": brand,
                    "X-PARTNER-ID": partner,
                },
                params: {
                    group
                }
            }
        );
        if (response) {
            return response.data;
        }
        throw Error(
            "Invalid response getting player limits"
        );
    }


    async updatePlayerLimits(brand, partner, contractId, limits) {
        const response = await axios.put(
            `/gov/api/rest/v1/limits/${contractId}`,
            {type: limits.type, limit: limits.limit,threshold: limits.threshold},
            {
                headers: {
                    "X-BRAND-ID": brand,
                    "X-PARTNER-ID": partner,
                },
            }
        );
        if (response) {
            return;
        }
        throw Error(
            "Invalid response updating player limits"
        );
    }

    async changePlayerLimitStatus(brand, partner, contractId, limit) {
        const response = await axios.put(
            `/gov/api/rest/v1/limits/${contractId}/${limit.type}/status`,
            limit,
            {
                headers: {
                    "X-BRAND-ID": brand,
                    "X-PARTNER-ID": partner,
                }
            }
        );
        if (response) {
            return;
        }
        throw Error(
            "Invalid response updating player limits status"
        );
    }

    async getHistory(brand, partner, contractId, limit) {
        const response = await axios.get(
            `/gov/api/rest/v1/limits/${contractId}/rg-limit/change-history?brandId=${brand}&partnerId=${partner}&limitType=${limit}`,
            {
                headers: {
                    "X-BRAND-ID": brand,
                    "X-PARTNER-ID": partner,
                }
            }
        );
        if (response && response.data) {
            return response.data.collection;
        }

        throw Error("Invalid response getting player limits history");
    }
}

const rglPlayerLimitsService = new RGLPlayerLimitsService();
export default rglPlayerLimitsService;
