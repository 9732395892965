import { call, put } from "redux-saga/effects";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../../snackbars/actions";
import {
  NETWORK_LIMITS_ASSOCIATED_PROFILES_REQUEST,
  NETWORK_LIMITS_ASSOCIATED_PROFILES_SUCCESS,
  NETWORK_LIMITS_ASSOCIATED_PROFILES_FAILURE,
  NETWORK_LIMITS_EDIT_PROFILE_REQUEST,
} from "../../../../constants";
import networkLimitsService from "./network-limits-service";

const getAssociatedProfiles = function* (action) {
  try {
    let data = yield call(
      networkLimitsService.getAssociatedProfiles,
      action.contractId,
      action.brand,
      action.partner
    );
    if (data && data.buyLimit) {
      data.buyLimit.forEach((l) => {
        let hasActiveLimits = false;
        let hasPendingLimits = false;
        if (l.buyLimit) {
          l.buyLimit.forEach((al) => {
            hasActiveLimits = hasActiveLimits || al.limitAmount > 0;
          });
        }
        if (l.buyLimitPending) {
          l.buyLimitPending.forEach((pl) => {
            hasPendingLimits = hasPendingLimits || pl.limitAmount > 0;
          });
        }
        l.hasActiveLimits = hasActiveLimits;
        l.hasPendingLimits = hasPendingLimits;
      });
    }
    yield put({
      type: NETWORK_LIMITS_ASSOCIATED_PROFILES_SUCCESS,
      payload: data.buyLimit,
    });
  } catch (e) {
    console.error("NetworkLimitsAssociatedProfiles", e);
    yield put({
      type: NETWORK_LIMITS_ASSOCIATED_PROFILES_FAILURE,
    });
  }
};

const editProfile = function* (action) {
  try {
    const {
      contractId,
      brand,
      partner,
      gameDomainName,
      typeTranslated,
    } = action;
    yield call(
      networkLimitsService.editProfile,
      action.contractId,
      action.brand,
      action.partner,
      action.gameDomain,
      action.typeToChange,
      action.newValue,
      action.currency
    );
    yield put({
      type: NETWORK_LIMITS_ASSOCIATED_PROFILES_REQUEST,
      contractId,
      brand,
      partner,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.individualplayer.responsibleGame.networkLimits.edit_successfully",
          variables: [typeTranslated, gameDomainName],
        },
        options: {
          key: "EditProfileNetworkLimits",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
  } catch (e) {
    console.error("NetworkLimitsAssociatedProfiles", e);
  }
};

const NetworkLimitsSaga = function* networkLimitsSaga() {
  yield takeLatest(
    NETWORK_LIMITS_ASSOCIATED_PROFILES_REQUEST,
    getAssociatedProfiles
  );
  yield takeLatest(NETWORK_LIMITS_EDIT_PROFILE_REQUEST, editProfile);
};

export default NetworkLimitsSaga;
