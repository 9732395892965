import React from "react";
import Button from "@material-ui/core/Button";
import { usePageActions } from "../../utilities/hooks/usePageActions";
import Translate from "../Translator/Translate";
import { useSession } from "../../utilities/hooks/useSession";
import { isMobileOnly } from "react-device-detect";
import { isItemVisible } from "../../utils";
import { Box } from "@material-ui/core";

const Action = ({
  hideMobile = false,
  permission,
  children,
  isPam = false,
  detail,
  label,
  margins = {},
  ...rest
}) => {
  const { buttons } = usePageActions();
  const { partnerSelected } = useSession();
  const item = buttons && buttons.find((it) => it.uid === permission);

  let display = item != null;
  if (isPam) {
    display = display && partnerSelected.pam;
  }
  if (hideMobile && isMobileOnly) {
    display = false;
  }
  if (item != null && detail != null) {
    display = display && isItemVisible(item, detail);
  }
  return display ? (
    <Box {...margins}>
      <Button {...rest}>
        <Translate needle={label ? label : item.label} />
      </Button>
    </Box>
  ) : null;
};

export default Action;
