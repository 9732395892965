import React from "react";
import { useField, useFormikContext } from "formik";
import { Slider, FormControl, InputLabel } from "@material-ui/core";
import FormLabel from "./form-label";

const SliderField = ({ fullWidth, optional, label, tooltip, ...props }) => {
  const [field] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { value: selectedValue } = field;

  return (
    <FormControl fullWidth={fullWidth}>
      {label ? (
        <InputLabel>
          <FormLabel optional={optional} label={label} tooltip={tooltip} />
        </InputLabel>
      ) : null}
      <Slider
        {...field}
        {...props}
        value={selectedValue ? selectedValue : 1}
        onChange={(event, value) => setFieldValue(field.name, value)}
      />
    </FormControl>
  );
};

export default SliderField;
