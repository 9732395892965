import axios from "axios";
import queryString from "query-string";
import { ENTRA_PROVIDER } from "../constants";

class OauthProviderService {
  oauthProviderLogin = async (provider, brandId, partnerId, props) => {
    let data = {};

    switch (provider) {
      case ENTRA_PROVIDER:
        const { code } = queryString.parse(props.location.search);
        const codeVerifier = sessionStorage.getItem("codeVerifier");
        data = {
          code,
          code_verifier: codeVerifier,
          brandId: brandId,
          partnerId: partnerId,
        };
        break;
      default:
        break;
    }
    const response = await axios.post(
      `/gov/api/rest/v1/users/oauth/${provider}`,
      data
    );

    if (response && response.data) {
      return {
        tokens: response.data,
        data: { result: true, errorCode: null },
      };
    }
    throw Error("Invalid response for oauth login");
  };
}
const oauthProviderService = new OauthProviderService();

export default oauthProviderService;
