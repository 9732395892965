const palette = {
  bg: {
    page: "#0D0D0D",
    primary1: "#333333",
    primary2: "#1B9DDB",
    primary2Accent1: "#4B535C",
    primary2Accent2: "#474D50",
    primary2Accent3: "#3C3C3C",
    secondary1: "#0C51A1",
    secondary2: "#FFA300",
    secondary2Accent1: "#FFA3001A",
    secondary2Accent2: "#FF671F",
    iconsLabels: "#daecfa",
    success: "#4DAF4F",
    failure: "#C64343",
    separatorsTags: "#6A6A6A",
  },
  text: {
    primary1: "#FFFFFF",
    primary2: "#E0EEFF",
    secondary: "#FFFFFF",
    secondary2: "#FFFFFF",
    textInfo1: "#85BDEF",
    textInfo2: "#AACA2B",
  },
};
const darkPalette = {
  type: "dark",
  statusColors: {
    1: palette.bg.secondary1,
    2: palette.bg.success,
    3: palette.bg.secondary2,
    4: palette.bg.failure,
    5: "#ED2E2E",
    6: "#A3A3A3",
    7: palette.bg.failure,
    8: "#9F9F9F",
    9: palette.bg.failure,
    10: palette.bg.secondary1,
    11: palette.bg.success,
    12: palette.bg.secondary2,
    13: "#E3D800",
    14: "#800080",
    15: "#ED2E2E",
    16: "#82c884",
  },
  //ATTENTION, TEMPORARY COLORS DUE TO A BUG IN CHART LIBRARY WE USE SAME COLORS REGARDLESS THE THEME
  chartColors: [
    "#0C51A1",
    "#1B9DDB",
    "#FF671F",
    "#FFA300",
    "#4DAF4F",
    "#954DAF",
    "#086533",
    "#FF0000",
    "#2DAABA",
    "#DEB400",
    "#5AD3FF",
    "#912D2D",
  ],
  // chartColors:[
  //   palette.bg.secondary1,
  //   palette.bg.primary2,
  //   palette.bg.secondary2Accent2,
  //   palette.bg.secondary2,
  //   palette.bg.success,
  //   "#954DAF",
  //   "#086533",
  //   "#FF0000",
  //   "#2DAABA",
  //   "#DEB400",
  //   "#5AD3FF",
  //   "#912D2D",
  // ],
  envColors: {
    DEV: {
      background: palette.bg.secondary2,
      color: palette.text.secondary2,
    },
    QA: {
      background: palette.bg.secondary2Accent2,
      color: palette.text.secondary,
    },
    SIT: {
      background: palette.bg.primary2,
      color: palette.text.secondary2,
    },
    CAT: {
      background: palette.bg.secondary1,
      color: palette.text.secondary,
    },
    UAT: {
      background: palette.bg.secondary1,
      color: palette.text.secondary,
    },
    DEMO: {
      background: palette.bg.success,
      color: palette.text.secondary,
    },
    default: {
      background: palette.bg.iconsLabels,
      color: palette.text.secondary,
    },
  },
  shadows: [
    "rgba(255,255,255,0.2)",
    "rgba(255,255,255,0.12)",
    "rgba(255,255,255,0.14)",
  ],
  shadow: "#000000",
  border: {
    arrow: "#fff",
    input: "#B4B4B4",
  },
  primary: {
    main: palette.bg.primary2,
    light: "#7986cb",
    dark: "#303f9f",
    contrastText: "#fff",
  },
  primary2: {
    main: "#fff",
  },
  secondary: {
    main: palette.bg.secondary1,
    light: "rgba(12, 81, 161, 0.15)",
  },
  success: {
    main: palette.bg.success,
  },
  error: {
    main: palette.bg.failure,
  },
  warning: {
    main: palette.bg.secondary2,
  },
  info: {
    main: palette.bg.primary2,
  },
  secondary2: {
    main: palette.bg.secondary2,
    light: palette.bg.secondary2Accent1,
    dark: palette.bg.secondary2Accent2,
  },
  buttons: {
    primary: palette.bg.primary2,
    primaryFocused: "#1A8FC7",
    secondary: palette.bg.secondary1,
    secondaryFocused: "#0B4991",
    outlined: "#ffffff",
    outlinedFocused: "#FFFFFF1A",
    success: palette.bg.success,
    successFocused: "#459D47",
    failure: palette.bg.failure,
    failureFocused: "#B23C3C",
  },
  text: {
    primary: palette.text.primary1,
    primary2: palette.text.primary2,
    icon: palette.bg.iconsLabels,
    warning: "#FA1E1E",
    highlight: palette.bg.secondary2,
    secondary: "rgb(255, 255, 255)",
    secondary1: palette.text.secondary,
    secondary2: palette.text.secondary2,
    secondary3: palette.text.primary1,
    paletteSecondary2: palette.bg.secondary1,
    disabled: palette.text.primary1,
    hint: "rgba(255, 255, 255, 0.5)",
    textInfo1: palette.text.textInfo1,
    textInfo2: palette.text.textInfo2,
  },
  background: {
    paper: palette.bg.primary1,
    default: palette.bg.page,
    primary2Accent1: palette.bg.primary2Accent1,
    primary2Accent2: palette.bg.primary2Accent2,
    primary2Accent3: palette.bg.primary2Accent3,
  },
  backgroundClickable: {
    paper: palette.bg.page,
  },
  svgIcon: {
    primary: palette.bg.primary2,
    typeString: "#7B9023",
    typeValue: "#407CB1",
  },
  action: {
    skeleton: "#595C5F",
    placeholder: "#FFFFFF66",
    active: "#FFF",
    sortActive: "#71777B",
    hover: "rgba(255, 255, 255, 0.08)",
    hoverOpacity: 0.1,
    selected: "rgba(255, 255, 255, 0.16)",
    clickableSelected: "rgba(255, 163, 0, 0.15)",
    clickableSelectedColor: "#FFA300",
    selectedOpacity: 0.08,
    disabled: "rgba(255, 255, 255, 0.3)",
    disabledBackground: "#4A4A4A",
    disabledOpacity: 0.38,
    focus: "rgba(255, 255, 255, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
  divider: palette.bg.separatorsTags,
};
// const theme = createMuiTheme({
//   props,
//   typography,
//   shape,
//   palette: darkPalette,
// {
//     ...defaultTheme.palette,
//     type: "dark",
//     border: {
//       ...defaultTheme.palette.border,
//       table: "#6a6a6a",
//       chartBox: "#484848",
//       separator: "#6A6A6A",
//     },
//     background: {
//       paper: "#333333",
//       default: "#0D0D0D",
//       headerButton: "#4f4f4f",
//       primary2Accent1: "#4B535C",
//       primary2Accent2: "#474D50",
//     },
//     backgroundClickable: {
//       paper: "#333333",
//       default: "#0D0D0D",
//       headerButton: "#4f4f4f",
//       primary2Accent1: "#595C5F",
//       primary2Accent2: "#64696C",
//     },
//     text: {
//       primary: "#fff",
//       primary2: "#E0EEFF",
//       warning: "#FA1E1E",
//       highlight: "#FFA300",
//       secondary: "#FFF",
//       secondary2: "#FFF",
//       disabled: "rgba(255,255,255, 0.40)",
//       hint: "rgba(255,255,255, 0.38)",
//     },
//     svgIcon: {
//       base: "#9DB3C4",
//       primary: "#1B9DDB",
//     },
//     action: {
//       active: "#fff",
//       hover: "rgba(255, 255, 255, 0.08)",
//       hoverOpacity: 0.08,
//       selected: "rgba(255, 255, 255, 0.16)",
//       clickableSelected: "rgba(255, 163, 0, 0.15)",
//       clickableSelectedColor: "#FFA300",
//       selectedOpacity: 0.16,
//       disabled: "rgba(255, 255, 255, 0.3)",
//       disabledBackground: "rgba(255, 255, 255, 0.12)",
//       disabledOpacity: 0.38,
//       focus: "rgba(255, 255, 255, 0.12)",
//       focusOpacity: 0.12,
//       activatedOpacity: 0.24,
//     },
//     divider: "rgba(200, 200, 200, 0.5)",
//   },
//   overrides: {
//     ...defaultTheme.overrides,
//     // MuiSelect:{
//     //   ...defaultTheme.overrides.MuiSelect,
//     //   selectMenu:{
//     //     "& .placeholder":{
//     //       color:"#E0EEFF40"
//     //     }
//     //   }
//     // },
//     // MuiSkeleton: {
//     //   root: {
//     //     backgroundColor: "#595C5F",
//     //   },
//     // },
//     // MuiTabs: {
//     //   ...defaultTheme.overrides.MuiTabs,
//     //   root: {
//     //     ...defaultTheme.overrides.MuiTabs.root,
//     //     background: "#474D50",
//     //   },
//     // },
//     // MuiTab: {
//     //   ...defaultTheme.overrides.MuiTab,
//     //   root: {
//     //     ...defaultTheme.overrides.MuiTab.root,
//     //     borderRight: "1px solid #8F8F99",
//     //     color: "#434B60",
//     //     "&$selected": {
//     //       background: "#0C51A1",
//     //       color: "white",
//     //     },
//     //   },
//     // },
//     // MuiAutocomplete: {
//     //   ...defaultTheme.overrides.MuiAutocomplete,
//     //   popupIndicator: {
//     //     color: "white !important",
//     //   },
//     // },
//     // MuiInputLabel: {
//     //   ...defaultTheme.overrides.MuiInputLabel,
//     //   formControl: {
//     //     ...defaultTheme.overrides.MuiInputLabel.formControl,
//     //     color: "#e0eeff",
//     //   },
//     // },
//     // MuiInput: {
//     //   ...defaultTheme.overrides.MuiInput,
//     //   formControl: {
//     //     ...defaultTheme.overrides.MuiInput.formControl,
//     //     backgroundColor: "#333333",
//     //     border: "1px solid #8F8F99",
//     //     "&$disabled": {
//     //       backgroundColor: "#3d3d3d",
//     //     },
//     //     "&:focus": {
//     //       outline: "1px solid #1B9DDB",
//     //       boxShadow: "#2699FB95 0 0 3px",
//     //     },
//     //   },
//     // },
//     // MuiExpansionPanelDetails: {
//     //   root: {
//     //     border: "1px solid #0d0d0d",
//     //     borderTop: "0",
//     //   },
//     // },
//     // MuiButton: {
//     //   ...defaultTheme.overrides.MuiButton,
//     //   outlined: {
//     //     borderColor: "#fff",
//     //     color: "#fff",
//     //     "&:hover, &:focus": {
//     //       borderColor: "#f0f0f0",
//     //       color: "#f0f0f0",
//     //     },
//     //   },
//     // },
//     // MuiCardHeader: {
//     //   root: {
//     //     backgroundColor: "#4B535C",
//     //     padding: defaultTheme.spacing(1, 2),
//     //   },
//     //   title: {
//     //     color: "#fff",
//     //     fontWeight: 700,
//     //     fontSize: 15,
//     //   },
//     // },
//     // MuiAppBar: {
//     //   colorDefault: {
//     //     backgroundColor: "#333",
//     //     color: "#fefefe",
//     //   },
//     // },
//     // MuiDialogTitle: {
//     //   root: {
//     //     backgroundColor: "#64696C",
//     //     padding: "8px 24px",
//     //     fontSize: 15,
//     //     fontWeight: defaultTheme.typography.fontWeightBold,
//     //   },
//     // },
//     // MuiTableRow: {
//     //   ...defaultTheme.overrides.MuiTableRow,
//     //   root: {
//     //     ...defaultTheme.overrides.MuiTableRow.root,
//     //     borderLeft: "1px solid rgba(63, 63, 63, 1)",
//     //   },
//     // },
//     // MuiTableCell: {
//     //   ...defaultTheme.overrides.MuiTableCell,
//     //   root: {
//     //     ...defaultTheme.overrides.MuiTableCell.root,
//     //     borderRight: "1px solid rgba(63, 63, 63, 1)",
//     //     borderBottom: "1px solid rgba(63, 63, 63, 1)",
//     //   },
//     //   head: {
//     //     ...defaultTheme.overrides.MuiTableCell.head,
//     //   },
//     // },
//     // MuiTableBody: {
//     //   root: {
//     //     color: "#BCD3EF",
//     //   },
//     // },
//     // MuiTablePagination: {
//     //   ...defaultTheme.overrides.MuiTablePagination,
//     //   selectRoot: {
//     //     ...defaultTheme.overrides.MuiTablePagination.selectRoot,
//     //     backgroundColor: "#0D0D0D",
//     //   },
//     //   toolbar: {
//     //     backgroundColor: "#595C5F",
//     //   },
//     // },
//     // MuiPaginationItem: {
//     //   page: {
//     //     color: "#fff",
//     //     fontWeight: defaultTheme.typography.fontWeightBold,
//     //     "&.MuiSvgIcon-root": {
//     //       color: "#1B9DDB",
//     //       fontSize: 42,
//     //       marginRight: -10,
//     //       marginLeft: -10,
//     //     },
//     //     "&$selected": {
//     //       color: "#1B9DDB",
//     //       backgroundColor: "#0D0D0D",
//     //       "&:hover": {
//     //         color: "#1B9DDB",
//     //         backgroundColor: "#0D0D0D",
//     //       },
//     //     },
//     //   },
//     // },
//     // MuiTableSortLabel: {
//     //   ...defaultTheme.overrides.MuiTableSortLabel,
//     //   active: {
//     //     ...defaultTheme.overrides.MuiTableSortLabel.active,
//     //     backgroundColor: "#2a2a2a",
//     //     "& $iconDirectionAsc:before": {
//     //       borderBottomColor: "#1b9ddb",
//     //     },
//     //     "& $iconDirectionDesc:before": {
//     //       borderBottomColor: "#1b9ddb",
//     //     },
//     //   },
//     //   iconDirectionAsc: {},
//     //   iconDirectionDesc: {},
//     //   icon: {
//     //     ...defaultTheme.overrides.MuiTableSortLabel.icon,
//     //     "&:after": {
//     //       borderTopColor: "#e4e4e4",
//     //       marginTop: 2,
//     //     },
//     //     "&:before": {
//     //       borderBottomColor: "#e4e4e4",
//     //       marginTop: -2,
//     //     },
//     //   },
//     // },
//     // MuiCardActions: {
//     //   root: {
//     //     "&.greyActions": {
//     //       background: "#414446",
//     //     },
//     //   },
//     // },
//   },
// });

export default darkPalette;
