import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  LONG_RUNNING_RETRY_TASK_FAILURE,
  LONG_RUNNING_RETRY_TASK_REQUEST,
  LONG_RUNNING_RETRY_TASK_SUCCESS,
  LONG_RUNNING_TASK_FAILURE,
  LONG_RUNNING_TASK_SUCCESS,
} from "../../../constants";

export const getStatusMapping = (status) => {
  switch (parseInt(status)) {
    case 0:
      return { id: "1", label: "open" };
    case 1:
      return { id: "13", label: "toProcess" };
    case 2:
      return { id: "3", label: "inProgress" };
    case 3:
      return { id: "2", label: "completed" };
    case 4:
      return { id: "5", label: "failed" };
    case 5:
      return { id: "6", label: "cancelled" };
    case 6:
      return { id: "7", label: "inError" };
    case 7:
      return { id: "16", label: "complWithWarning" };
    case 8:
      return { id: "5", label: "rolledBack" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const initialState = {
  loading: true,
  taskList: {
    data: [],
    columns: [
      {
        field: "taskType.taskID",
        title: "label.individualplayer.longRunningTask.playerTaskType",
        render: (rowData) => {
          if (rowData["taskType"]["taskID"] != null) {
            let taskName =
              "label.individualplayer.longRunningTask.taskName." +
              rowData["taskType"]["taskID"];
            return <Translate needle={taskName} />;
          }
        },
        default: true,
      },
      {
        field: "dateRequest",
        title: "label.individualplayer.longRunningTask.dateRequest",
        render: (rowData) => {
          if (rowData["dateRequest"]) {
            return <Moment format="L LT">{rowData["dateRequest"]}</Moment>;
          }
        },
      },
      {
        field: "createdBy",
        title: "label.individualplayer.longRunningTask.createdBy",
        emptyValueCustom: <Translate needle={"label.closingAccounts.system"} />,
      },
      {
        field: "state",
        title: "label.individualplayer.longRunningTask.state",
        render: (rowData) => {
          if (rowData["state"] != null) {
            const statusMapped = getStatusMapping(rowData["state"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
                fullWidth
              />
            );
          }
        },
      },
      {
        field: "dateCompleted",
        title: "label.individualplayer.longRunningTask.dateCompleted",
        render: (rowData) => {
          if (rowData["dateCompleted"]) {
            return <Moment format="L LT">{rowData["dateCompleted"]}</Moment>;
          }
        },
      },
      {
        field: "transactionID",
        title: "label.individualplayer.longRunningTask.transactionID",
      },
    ],
  },
  taskDetail: {
    loadingDetail: true,
    columnsDetail: (taskID) => {
      return [
        {
          field: "componentInvolved.description",
          title: "label.individualplayer.longRunningTask.componentName",
        },
        {
          field: "taskState.taskStateDescription",
          title: "label.individualplayer.longRunningTask.componentState",
          render: (rowData) => {
            if (rowData["taskState"]["taskStateId"] != null) {
              const statusMapped = getStatusMapping(
                rowData["taskState"]["taskStateId"]
              );
              return (
                <BadgeStatus
                  id={statusMapped.id}
                  label={statusMapped.label}
                  justify={"center"}
                  fullWidth
                />
              );
            }
          },
        },
        {
          field: "executionResult",
          title: "label.individualplayer.longRunningTask.parameterDescription",
          hidden: taskID != 1 && taskID != 0 && (taskID < 3 || taskID > 5),
          render: (rowData) => {
            return (
              (taskID == 1 ||
                rowData["taskState"]["taskStateId"] === 4 ||
                rowData["taskState"]["taskStateId"] === 6 ||
                rowData["taskState"]["taskStateId"] === 7 ||
                rowData["taskState"]["taskStateId"] === 8) && (
                <Translate
                  needle={
                    "label.individualplayer.longRunningTask." +
                    rowData["executionResult"]
                  }
                  label={rowData["executionResult"]}
                />
              )
            );
          },
        },
        {
          field: "componentParameters",
          title: "label.individualplayer.longRunningTask.parameterID",
          hidden: taskID != 1 && taskID != 0 && (taskID < 3 || taskID > 5),
          render: (rowData) => {
            if (rowData["componentParameters"]) {
              return rowData["componentParameters"].map((param) => {
                let paramDesc =
                  taskID === 1 && "label.individualplayer.longRunningTask.path";

                return [<Translate needle={paramDesc} />, " " + param.value];
              });
            }
          },
        },
      ].filter((it) => !it.hidden);
    },
  },
};

const LongRunningTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case LONG_RUNNING_TASK_SUCCESS: {
      return {
        ...state,
        loading: false,

        taskDetail: { ...state.taskDetail, loadingDetail: false },
        taskList: { ...state.taskList, data: action.payload },
      };
    }
    case LONG_RUNNING_TASK_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingDetail: false,
        taskDetail: { ...state.taskDetail, loadingDetail: false },
        taskList: { ...state.taskList, data: null },
      };
    }

    case LONG_RUNNING_RETRY_TASK_REQUEST: {
      return {
        ...state,
        taskDetail: { ...state.taskDetail, loadingDetail: true },
      };
    }
    case LONG_RUNNING_RETRY_TASK_FAILURE: {
      return {
        ...state,
        taskDetail: { ...state.taskDetail, loadingDetail: false },
      };
    }
    case LONG_RUNNING_RETRY_TASK_SUCCESS: {
      return {
        ...state,
        taskDetail: { ...state.taskDetail, loadingDetail: false },
      };
    }
    default:
      return state;
  }
};

export default LongRunningTaskReducer;
