import React from "react";
import OrderList from "../../Layouts/Lists/OrderList";
import { Button, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const PickList = ({ data, selected, onChange, attribute }) => {
  const classes = useStyles();

  const [left, setLeft] = React.useState(data);
  const [right, setRight] = React.useState(selected);
  const [leftSelected, setLeftSelected] = React.useState([]);
  const [rightSelected, setRightSelected] = React.useState([]);

  React.useEffect(() => {
    onChange && onChange(right);
    //eslint-disable-next-line
  }, [right]);

  React.useEffect(() => {
    setLeftSelected([]);
    setRightSelected([]);
  }, []);

  const handleAllToRight = () => {
    setRight([...right, ...left]);
    setLeft([]);
  };

  const handleMoveToRight = () => {
    setRight(right.concat(leftSelected));
    setLeft(left.filter((it) => !leftSelected.find((el) => el === it)));
  };

  const handleMoveToLeft = () => {
    setLeft(left.concat(rightSelected));
    setRight(right.filter((it) => !rightSelected.find((el) => el === it)));
  };

  const handleAllToLeft = () => {
    setLeft([...right, ...left]);
    setRight([]);
  };

  const handleChange = (side, values) => {
    if (side === "left") {
      setLeft(values);
    }
    if (side === "right") {
      setRight(values);
    }
  };

  const handleSelect = (side, values) => {
    if (side === "left") {
      setLeftSelected(values);
    }
    if (side === "right") {
      setRightSelected(values);
    }
  };

  if (left == null) return null;

  return (
    <Box display={"flex"}>
      <OrderList
        title={"label.picklist.from"}
        data={left}
        attribute={attribute}
        buttonSide={"left"}
        onChange={(values) => handleChange("left", values)}
        onSelect={(value) => handleSelect("left", value)}
        search={true}
      />

      <Box display={"flex"} mt={8} mx={2} flexDirection={"column"}>
        <Button
          color="primary"
          size="small"
          className={classes.button}
          onClick={handleAllToRight}
          disabled={left.length === 0}
          aria-label="move all right"
        >
          ≫
        </Button>
        <Button
          color="primary"
          size="small"
          className={classes.button}
          onClick={handleMoveToRight}
          disabled={leftSelected.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          color="primary"
          size={"small"}
          className={classes.button}
          onClick={handleMoveToLeft}
          disabled={rightSelected.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
        <Button
          color="primary"
          size="small"
          className={classes.button}
          onClick={handleAllToLeft}
          disabled={right.length === 0}
          aria-label="move all left"
        >
          ≪
        </Button>
      </Box>

      <OrderList
        title={"label.picklist.to"}
        data={right}
        attribute={attribute}
        onChange={(values) => handleChange("right", values)}
        search={true}
        onSelect={(value) => handleSelect("right", value)}
      />
    </Box>
  );
};

export default PickList;
