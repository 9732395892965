import { call, put } from "redux-saga/effects";

import { takeEvery } from "@redux-saga/core/effects";
import {
  STATUS_HISTORY_FAILURE,
  STATUS_HISTORY_REQUEST,
  STATUS_HISTORY_SUCCESS,
} from "../../../constants";
import statusHistoryService from "./status-history-service";

const loadStatusHistory = function* (action) {
  try {
    const data = yield call(
      statusHistoryService.loadStatusHistory,
      action.contractId,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: STATUS_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("statusHistory", e);
    yield put({
      type: STATUS_HISTORY_FAILURE,
    });
  }
};

const StatusHistorySaga = function* statusHistorySaga() {
  yield takeEvery(STATUS_HISTORY_REQUEST, loadStatusHistory);
};

export default StatusHistorySaga;
