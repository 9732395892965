import {
  PLAYER_FAVORITES_REQUEST,
  CATEGORY_LIST_REQUEST,
  LOCATIONS_LIST_REQUEST,
  SAVE_FAVORITE_REQUEST,
} from "../../../constants";
export const getPlayerFavorites = (brand, partner, contractId) => {
  return {
    type: PLAYER_FAVORITES_REQUEST,
    brand,
    partner,
    contractId,
  };
};

export const getCategoryList = () => {
  return {
    type: CATEGORY_LIST_REQUEST,
  };
};

export const getLocationsList = (category) => {
  return {
    type: LOCATIONS_LIST_REQUEST,
    category,
  };
};

export const savePlayerFavorites = (
  brand,
  partner,
  fields,
  contractId,
  actionType,
  cb
) => {
  return {
    type: SAVE_FAVORITE_REQUEST,
    brand,
    partner,
    fields,
    contractId,
    actionType,
    cb,
  };
};
