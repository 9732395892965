import {
  SENSITIVE_VIP_PLAYER_DETAILS_REQUEST,
  SENSITIVE_VIP_PLAYER_HISTORY_REQUEST,
  SENSITIVE_VIP_PLAYER_MARK_UNMARK_REQUEST,
} from "../../../constants";

export const loadDetails = (brand, partner, contractId) => {
  return {
    type: SENSITIVE_VIP_PLAYER_DETAILS_REQUEST,
    contractId,
    brand,
    partner,
  };
};

export const loadHistory = (brand, partner, contractId) => {
  return {
    type: SENSITIVE_VIP_PLAYER_HISTORY_REQUEST,
    contractId,
    brand,
    partner,
  };
};

export const markUnmark = (brand, partner, contractId, detail, cb) => {
  return {
    type: SENSITIVE_VIP_PLAYER_MARK_UNMARK_REQUEST,
    contractId,
    brand,
    partner,
    detail,
    cb,
  };
};
