import {
  PAGE_ACTIONS_FAILURE, PAGE_ACTIONS_REQUEST,
  PAGE_ACTIONS_RESET,
  PAGE_ACTIONS_SUCCESS,
} from "../constants";

const initialState = { all: [], megamenu: [], accordion: [], buttons: null, loading: false };
const PageActionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_ACTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case PAGE_ACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PAGE_ACTIONS_RESET:
    case PAGE_ACTIONS_FAILURE:
      return initialState;
    default:
      return state;
  }
};

export default PageActionsReducer;
