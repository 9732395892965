import { Checkbox } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useField, useFormikContext } from "formik";
import React from "react";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import FormLabel from "./form-label";

const AutocompleteField = ({
  optional,
  label,
  tooltip,
  options,
  multiple,
  labelKey,
  valueKey,
  optionId,
  placeholder,
  isConditional = false,
  disabled,
  onChange,
  closeOnSelect = false,
  showCheckbox = true,
  fullWidth = true,
  selectAllOption = false,
  ...props
}) => {
  const t = useTranslator();
  const [field, meta, helpers] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { value: selectedValue = [] } = field;
  const { touched, error } = meta;
  const isError = touched && error && true;
  const ph = placeholder
    ? typeof placeholder === "string"
      ? t({ needle: placeholder })
      : placeholder
    : multiple
    ? "label.placeholders.multiselect"
    : "label.placeholders.autocomplete";
  const [plcholder, setPlaceholder] = React.useState(ph);
  const [prevSelected, setPrevSelected] = React.useState([]);

  if (selectAllOption) {
    options = [{ value: "*", label: t({ needle: "label.all" }) }, ...options];
  }

  return (
    <Autocomplete
      multiple={multiple}
      disabled={disabled}
      autoComplete
      onChange={(event, value) => {
        if (multiple) {
          let val = value.map((it) => (valueKey ? it[valueKey] : it));

          if (selectAllOption) {
            if (val.includes("*")) {
              if (
                value.length === options.length - 1 &&
                prevSelected.includes("*")
              ) {
                val = val.filter((it) => it !== "*");
              } else {
                val = options.map((it) => (valueKey ? it[valueKey] : it));
              }
            } else {
              if (value.length === options.length - 1) {
                if (prevSelected.includes("*")) {
                  val = [];
                } else {
                  val = ["*", ...val];
                }
              }
            }
          }
          setPrevSelected(val);
          setFieldValue(field.name, val);
        } else {
          setFieldValue(
            field.name,
            value ? (valueKey ? value[valueKey] : value) : ""
          );
        }

        onChange != null &&
          typeof onChange === "function" &&
          (!multiple
            ? onChange(value ? (valueKey ? value[valueKey] : value) : "")
            : onChange(
                value
                  ? value.reduce((acc, it) => {
                      acc.push(it[valueKey]);
                      return acc;
                    }, [])
                  : ""
              ));
      }}
      value={
        multiple
          ? options.filter((it) => {
              return (
                selectedValue &&
                selectedValue.find((item) =>
                  valueKey
                    ? "" + item === "" + it[valueKey]
                    : "" + item.value === "" + it.value
                ) !== undefined
              );
            })
          : options.find((it) => {
              return valueKey
                ? "" + it[valueKey] === "" + selectedValue
                : "" + it.value === "" + selectedValue.value;
            }) || ""
      }
      onFocus={(event) => {
        setPlaceholder("label.placeholders.autocompleteFocus");
      }}
      onBlur={(event) => {
        setPlaceholder(ph);
        helpers.setTouched(true, true);
      }}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          fullWidth={fullWidth}
          name={field.name}
          error={isError}
          helperText={!isConditional && isError && error}
          label={
            label ? (
              <FormLabel optional={optional} label={label} tooltip={tooltip} />
            ) : null
          }
          placeholder={
            multiple && selectedValue && selectedValue.length > 0
              ? null
              : multiple
              ? t({ needle: plcholder })
              : t({
                  needle: plcholder,
                  variables: [label != null ? label : ""],
                })
          }
        />
      )}
      disableClearable={false}
      options={options}
      getOptionLabel={(option) =>
        option ? (labelKey ? option[labelKey] : option) : !multiple && ""
      }
      disableCloseOnSelect={multiple && !closeOnSelect}
      renderOption={(option, { selected }) => (
        <>
          {multiple && showCheckbox && (
            <Checkbox checked={selected} style={{ margin: 0, padding: 4 }} />
          )}
          {option ? (labelKey ? option[labelKey] : option) : !multiple && ""}
        </>
      )}
    />
  );
};

export default AutocompleteField;
