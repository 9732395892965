import { useSession } from "../../../../utilities/hooks/useSession";
import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { Box } from "@material-ui/core";
import Translate from "../../../../Layouts/Translator/Translate";
import { connect } from "react-redux";
import SelectField from "../../../../Layouts/form/select-field";
import Button from "@material-ui/core/Button";
import { disableEnableCard } from "../../../../store/players/individual-player/club-card/actions";
import { useReasonsForActions } from "../../../../utilities/hooks/useReasonsForActions";
import { useLocale } from "../../../../utilities/hooks/useLocale";
function DisableEnableCardForm({
  idCard,
  contractId,
  action,
  serviceId,
  handleCloseModal,
  save,
}) {
  const t = useTranslator();
  const { locale } = useLocale();
  const { brandSelected, partnerSelected } = useSession();
  const reason = useReasonsForActions(
    brandSelected,
    partnerSelected,
    "Card_Disable",
    locale
  );
  let initialValues = {
    reason: "",
    cardNumber: idCard,
  };
  return (
    <>
      <Formik
        initialValues={
          action === "Card_Disable" ? initialValues : initialValues.cardNumber
        }
        validationSchema={
          action === "Card_Disable"
            ? Yup.object().shape({
                reason: Yup.string().required(
                  t({
                    needle:
                      "label.player.clubCardDetails.regenerate.errorsDescription",
                  })
                ),
              })
            : null
        }
        onSubmit={(fields) => {
          if (action === "Card_Disable") {
            delete fields["_meta"];
            delete fields["cardNumber"];
          } else {
            fields = {};
          }
          save(
            brandSelected,
            partnerSelected,
            fields,
            contractId,
            action,
            serviceId,
            handleCloseModal
          );
        }}
        render={() => (
          <Form>
            <Box p={2} pt={0}>
              <span>Card Number: {idCard}</span>
            </Box>
            {action === "Card_Enable" ? (
              <Box p={2} pt={0}>
                <span>
                  {
                    <Translate
                      needle={"label.player.clubCardDetails.enable.request"}
                    />
                  }
                </span>
              </Box>
            ) : (
              <Box p={2} pt={0}>
                <span>
                  {
                    <Translate
                      needle={"label.player.clubCardDetails.disable.request"}
                    />
                  }
                </span>
              </Box>
            )}
            {action === "Card_Disable" ? (
              <Box p={2} pt={0}>
                <SelectField
                  labelKey={"reasonDescription"}
                  label={
                    <Translate
                      needle={"label.player.clubCardDetails.disable.reasons"}
                    />
                  }
                  options={reason}
                  name={"reason"}
                  valueKey={"reasonDescription"}
                  fullWidth
                />
              </Box>
            ) : null}
            <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
              <Box mr={1}>
                <Button variant={"outlined"} onClick={() => handleCloseModal()}>
                  <Translate needle={"button.cancel"} />
                </Button>
              </Box>
              {action === "Card_Enable" ? (
                <Box>
                  <Button color={"success"} type="submit">
                    <Translate needle={"button.enable"} />{" "}
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Button color={"failure"} type="submit">
                    <Translate needle={"button.disable"} />{" "}
                  </Button>
                </Box>
              )}
            </Box>
          </Form>
        )}
      />
    </>
  );
}
const mapDispatchToProps = {
  save: disableEnableCard,
};
const mapStateToProps = (state) => {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisableEnableCardForm);
