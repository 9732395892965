import {
  REFRESH_SIDE_MENU_ACTION,
  REFRESH_TREE_MENU_REQUEST,
} from "../constants";

const INITIAL_STATE = {
  result: { flatMenuItems: null, menuItems: [], label: [] },
};

const SideMenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFRESH_TREE_MENU_REQUEST:
      return INITIAL_STATE;
    case REFRESH_SIDE_MENU_ACTION:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
};

export default SideMenuReducer;
