import * as moment from "moment";
import React from "react";
import { FormattedNumber } from "react-intl";
import Moment from "react-moment";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import Currency from "../../../../Layouts/Currency/Currency";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  PROMO_CODE_AMOUNT_TYPES_FAILURE,
  PROMO_CODE_AMOUNT_TYPES_SUCCESS,
  PROMO_CODE_CAMPAIGNS_FAILURE,
  PROMO_CODE_CAMPAIGNS_SUCCESS,
  PROMO_CODE_FAILURE,
  PROMO_CODE_FILTERS_FAILURE,
  PROMO_CODE_FILTERS_REQUEST,
  PROMO_CODE_FILTERS_SUCCESS,
  PROMO_CODE_REQUEST,
  PROMO_CODE_SUCCESS,
  STYLE_CURRENCY,
  STYLE_LABEL,
} from "../../../constants";

export const labelPrefix = "label.rewards.campaigns.promoCode.";

const initialState = {
  promoCodeList: {
    idKey: "campaignId",
    filtersLoading: true,
    tableLoading: true,
    filters: [],
    data: [],
    columns: [
      {
        field: "campaignId",
        title: labelPrefix + "campaignId",
      },
      {
        field: "promoCodeId",
        title: labelPrefix + "promoCodeId",
        default: true,
        nowrap: false,
      },
      {
        field: "activationDate",
        title: labelPrefix + "activationDate",
        defaultSort: "desc",
        render: (rowData) => {
          if (rowData["activationDate"]) {
            return <Moment format="L LT">{rowData["activationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["activationDate"]) {
            return moment(rowData["activationDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "expirationDate",
        title: labelPrefix + "expirationDate",
        render: (rowData) => {
          if (rowData["expirationDate"]) {
            return <Moment format="L LT">{rowData["expirationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["expirationDate"]) {
            return moment(rowData["expirationDate"]).format("L LT");
          } else {
            return "";
          }
        },
      },
      {
        field: "utilizationLimit",
        title: labelPrefix + "utilizationLimit",
        render: (rowData) => {
          if (rowData["utilizationLimit"]) {
            return <FormattedNumber value={rowData["utilizationLimit"]} />;
          }
        },
      },
      {
        field: "playerUtilizationLimit",
        title: labelPrefix + "playerUtilizationLimit",
        render: (rowData) => {
          if (rowData["playerUtilizationLimit"]) {
            return (
              <FormattedNumber value={rowData["playerUtilizationLimit"]} />
            );
          }
        },
      },
      {
        field: "amountType",
        title: labelPrefix + "amountType",
        render: (rowData) => {
          if (rowData["amountType"]) {
            const amountType =
              labelPrefix + "amountTypes." + rowData["amountType"];
            return <Translate needle={amountType} />;
          }
        },
        getExportTypeFn: (rowData) => {
          return {
            style: STYLE_LABEL,
            value: labelPrefix + "amountTypes." + rowData["amountType"],
          };
        },
      },
      {
        field: "amount",
        title: labelPrefix + "amount",
        render: (rowData) => {
          if (rowData["amount"]) {
            return (
              <Currency
                amount={rowData["amount"]}
                currency={rowData["currency"]}
                justify="flex-end"
              />
            );
          } else {
            return "-";
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["amount"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["amount"],
            };
          } else {
            return null;
          }
        },
        default: true,
      },
      {
        field: "currency",
        title: labelPrefix + "currency",
        render: (rowData) => {
          if (rowData["currency"]) {
            return (
              <CurrencyBadge currency={rowData["currency"]} justify="center" />
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "promoType",
        title: labelPrefix + "promoType",
      },
    ],
  },
  campaignList: [],
  amountTypeList: [],
};

const PromoCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROMO_CODE_FILTERS_SUCCESS: {
      return {
        ...state,
        promoCodeList: {
          ...state.promoCodeList,
          filters: action.payload,
          filtersLoading: false,
        },
      };
    }
    case PROMO_CODE_FILTERS_FAILURE: {
      return {
        ...state,
        promoCodeList: {
          ...state.promoCodeList,
          filters: null,
          filtersLoading: false,
        },
      };
    }
    case PROMO_CODE_REQUEST: {
      return {
        ...state,
        promoCodeList: {
          ...state.promoCodeList,
          lastRequest: action,
          data: null,
          tableLoading: true,
        },
      };
    }

    case PROMO_CODE_FILTERS_REQUEST: {
      return {
        ...state,
        promoCodeList: {
          ...state.promoCodeList,
          filtersLoading: true,
        },
      };
    }

    case PROMO_CODE_SUCCESS: {
      return {
        ...state,
        promoCodeList: {
          ...state.promoCodeList,
          data: action.payload,
          tableLoading: false,
        },
      };
    }
    case PROMO_CODE_FAILURE: {
      return {
        ...state,
        promoCodeList: {
          ...state.promoCodeList,
          data: null,
          tableLoading: false,
        },
      };
    }

    case PROMO_CODE_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        campaignList: action.payload,
      };
    }
    case PROMO_CODE_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        campaignList: null,
      };
    }

    case PROMO_CODE_AMOUNT_TYPES_SUCCESS: {
      return {
        ...state,
        amountTypeList: action.payload,
      };
    }
    case PROMO_CODE_AMOUNT_TYPES_FAILURE: {
      return {
        ...state,
        amountTypeList: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default PromoCodeReducer;
