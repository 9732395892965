import { call, put } from "redux-saga/effects";
import {
  EXPIRING_BONUS_SEARCH_REQUEST,
  EXPIRING_BONUS_SEARCH_SUCCESS,
  EXPIRING_BONUS_SEARCH_FAILURE,
  EXPIRING_BONUS_VIEW_REQUEST,
  EXPIRING_BONUS_VIEW_SUCCESS,
  EXPIRING_BONUS_VIEW_FAILURE,
  GO_TO_ERROR_PAGE,
  EXPIRING_BONUS_PLAYER_LIST_SUCCESS,
  EXPIRING_BONUS_PLAYER_LIST_FAILURE,
  EXPIRING_BONUS_PLAYER_LIST_REQUEST,
  EXPIRING_BONUS_CREATE_REQUEST,
} from "../../../constants";
import expiringBonusService from "./expiring-bonus-service";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadExpiringBonus = function* (action) {
  try {
    const data = yield call(
      expiringBonusService.loadExpiringBonus,
      action.searchParams
    );
    yield put({
      type: EXPIRING_BONUS_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("expiring bonus", e);
    yield put({
      type: EXPIRING_BONUS_SEARCH_FAILURE,
    });
  }
};

const expiringBonusView = function* (action) {
  try {
    const data = yield call(
      expiringBonusService.expiringBonusView,
      action.massiveId,
      action.query
    );
    yield put({
      type: EXPIRING_BONUS_VIEW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("expiring bonus view", e);
    yield put({
      type: EXPIRING_BONUS_VIEW_FAILURE,
    });
    yield put({
      type: GO_TO_ERROR_PAGE,
      payload: action.massiveId,
    });
  }
};

const loadPlayers = function* (action) {
  try {
    const { terms } = action;
    let filters = Object.keys(terms).reduce((accumulator, key) => {
      const term = terms[key];
      if (term) {
        const filter = {};
        const isPlayerStatus = key === "PLAYER_STATUS";
        filter["field"] = key;
        filter["operator"] = isPlayerStatus ? "EQUAL" : term["condition"];
        filter["value"] = isPlayerStatus ? term : term["value"];
        accumulator.push(filter);
      }
      return accumulator;
    }, []);
    filters = "filter=" + JSON.stringify(filters);
    filters = encodeURI(filters);
    const data = yield call(
      expiringBonusService.loadPlayers,
      action.brand,
      action.partner,
      filters
    );
    yield put({
      type: EXPIRING_BONUS_PLAYER_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("expiring bonus view", e);
    yield put({
      type: EXPIRING_BONUS_PLAYER_LIST_FAILURE,
    });
  }
};

const createExpiringBonus = function* (action) {
  try {
    let { fields } = { ...action };
    let { bonusAmount } = fields;
    let { playerSelected, amountToShow } = action;
    yield call(
      expiringBonusService.create,
      action.brand,
      action.partner,
      playerSelected,
      bonusAmount,
      action.fields.currency,
      action.fields.requestName
    );
    const numberPlayers = playerSelected.length.toString();
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.expiringBonus.create.create_successfully",
          variables: [amountToShow, numberPlayers],
        },
        options: {
          key: "CreateExpiringBonus",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
    let savedFilters = JSON.parse(sessionStorage.filters);
    delete savedFilters["expiringBonusCreate"];
    sessionStorage.setItem("filters", JSON.stringify(savedFilters));
  } catch (e) {
    console.error("expiring bonus create", e);
  }
};

const ExpiringBonusSaga = function* expiringBonusSaga() {
  yield takeLatest(EXPIRING_BONUS_SEARCH_REQUEST, loadExpiringBonus);
  yield takeLatest(EXPIRING_BONUS_VIEW_REQUEST, expiringBonusView);
  yield takeLatest(EXPIRING_BONUS_PLAYER_LIST_REQUEST, loadPlayers);
  yield takeLatest(EXPIRING_BONUS_CREATE_REQUEST, createExpiringBonus);
};

export default ExpiringBonusSaga;
