import * as moment from "moment";
import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../Layouts/BadgeStatus/BadgeStatus";
import YesNoBadge from "../../Layouts/BadgeStatus/yes-no-badge";
import FormTooltip from "../../Layouts/form/form-tooltip";
import Translate from "../../Layouts/Translator/Translate";
import {
  CLOSING_ACCOUNTS_FILTERS_FAILURE,
  CLOSING_ACCOUNTS_FILTERS_REQUEST,
  CLOSING_ACCOUNTS_FILTERS_SUCCESS,
  CLOSING_ACCOUNTS_PLAYERS_LIST_FAILURE,
  CLOSING_ACCOUNTS_PLAYERS_LIST_REQUEST,
  CLOSING_ACCOUNTS_PLAYERS_LIST_SUCCESS,
} from "../constants";

export const deductLabelPrefix = "label.closingAccounts.deductAmount.";
export const onHoldPaymentLabelPrefix = "label.closingAccounts.onHoldPayment.";

export const getContractClosingStatusMapping = (status) => {
  let label = "contractStatus_" + status;
  switch (status) {
    case 1:
    case 2:
      return { id: "3", label: label };
    case 3:
    case 5:
      return { id: "2", label: label };
    case 4:
      return { id: "7", label: label };
    case 6:
      return { id: "14", label: label };
    case 8:
      return { id: "13", label: label };
    case 9:
      return { id: "3", label: label };
    case 10:
      return { id: "15", label: label };
    default: {
      return { id: "1", label: label };
    }
  }
};

export const getEwlStatusMapping = (status) => {
  let label = "ewlGameAccountstatus_" + status;

  switch (status) {
    case -1: {
      return { id: "13", label: label };
    }
    case 2: {
      return { id: "3", label: label };
    }
    case 1:
    case 6: {
      return { id: "2", label: label };
    }
    case 3:
    case 4: {
      return { id: "7", label: label };
    }
    case 5: {
      return { id: "5", label: label };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

export const getClosingStatusMapping = (status) => {
  let label = "closingStatus_" + status;
  switch (status) {
    case 1:
      return { id: "13", label: label };
    case 2:
      return { id: "7", label: label };
    case 3:
      return { id: "6", label: label };
    case 9:
      return { id: "3", label: label };
    case 10:
      return { id: "15", label: label };
    default: {
      return { id: "1", label: label };
    }
  }
};

export const getLrtTaskStatesMapping = (status) => {
  switch (parseInt(status)) {
    case 0:
      return { id: "1", label: "open" };
    case 1:
      return { id: "13", label: "toProcess" };
    case 2:
      return { id: "3", label: "inProgress" };
    case 3:
      return { id: "2", label: "completed" };
    case 4:
      return { id: "5", label: "failed" };
    case 5:
      return { id: "6", label: "cancelled" };
    case 6:
      return { id: "7", label: "inError" };
    case 7:
      return { id: "16", label: "complWithWarning" };
    case 8:
      return { id: "5", label: "rolledBack" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

export const getTaskTypeMapping = (taskType) => {
  let label = (
    <Translate needle={labelPrefix + "lrtTaskTypes_" + taskType.taskID} />
  );
  return { id: 1, label: label };
};

export const labelPrefix = "label.closingAccounts.";

const initialState = {
  closingAccountsList: {
    idKey: "closingAccountsId",
    filtersLoading: true,
    tableLoading: false,
    filters: [],
    filtersKey: "",
    playerList: [],
    columns: [
      {
        field: "id",
        title: labelPrefix + "id",
        sorting: false,
      },
      {
        field: "contract_identity_s",
        title: labelPrefix + "contractIdentity",
        sorting: false,
      },
      {
        field: "username_s",
        title: labelPrefix + "username",
        sorting: false,
      },
      {
        field: "email_s",
        title: labelPrefix + "email",
        sorting: false,
      },
      {
        field: "contract_closing_status_id_i",
        title: labelPrefix + "contractClosingStatusId",
        sorting: false,
        default: true,
        nowrap: false,
        emptyValueJustify: "center",
        render: (rowData) => {
          const statusMapped = getContractClosingStatusMapping(
            rowData["contract_closing_status_id_i"]
          );
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "ewl_status_i",
        title: labelPrefix + "ewlStatus",
        sorting: false,
        default: true,
        nowrap: false,
        emptyValueJustify: "center",
        render: (rowData) => {
          const statusMapped = getEwlStatusMapping(rowData["ewl_status_i"]);
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              justify={"center"}
            />
          );
        },
      },
      {
        field: "closing_status_id_i",
        title: labelPrefix + "closingStatusId",
        sorting: false,
        default: true,
        nowrap: false,
        emptyValueJustify: "center",
        render: (rowData) => {
          const statusMapped = getClosingStatusMapping(
            rowData["closing_status_id_i"]
          );
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "started_by_s",
        title: labelPrefix + "startedBy",
        sorting: false,
      },
      {
        field: "reserve_closing_date_dt",
        title: labelPrefix + "reserveClosingDate",
        render: (rowData) => {
          if (rowData["reserve_closing_date_dt"]) {
            return (
              <Moment format="L LT">
                {rowData["reserve_closing_date_dt"]["time"]}
              </Moment>
            );
          }
        },
      },
      {
        field: "reason_s",
        title: labelPrefix + "reason",
        sorting: false,
      },
      {
        field: "right_to_be_forgotten_b",
        title: labelPrefix + "rightToBeForgotten",
        render: (rowData) => (
          <YesNoBadge
            value={rowData["right_to_be_forgotten_b"]}
            justify={"center"}
          />
        ),
      },
      {
        field: "cancel_closing_date_dt",
        title: labelPrefix + "cancelClosingDate",
        render: (rowData) => {
          if (rowData["cancel_closing_date_dt"]) {
            return (
              <Moment format="L LT">
                {rowData["cancel_closing_date_dt"]["time"]}
              </Moment>
            );
          }
        },
      },
      {
        field: "closureStatus_3",
        title: labelPrefix + "checkClosureStatus",
        sorting: false,
        default: true,
        nowrap: false,
        emptyValueJustify: "center",
        align: "center",
        render: (rowData) => {
          const statusMapped = getLrtTaskStatesMapping(
            rowData["closureStatus_3"]
          );
          if (statusMapped !== undefined) {
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                fullWidth
                tooltip={true}
                justify={"center"}
              />
            );
          } else return "-";
        },
      },
      {
        field: "closureStatus_4",
        title: labelPrefix + "withdrawalClosureStatus",
        sorting: false,
        default: true,
        nowrap: false,
        emptyValueJustify: "center",
        align: "center",
        render: (rowData) => {
          const statusMapped = getLrtTaskStatesMapping(
            rowData["closureStatus_4"]
          );
          if (statusMapped !== undefined) {
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                fullWidth
                tooltip={true}
                justify={"center"}
              />
            );
          } else return "-";
        },
      },
      {
        field: "closureStatus_5",
        title: labelPrefix + "confirmClosureStatus",
        sorting: false,
        default: true,
        nowrap: false,
        emptyValueJustify: "center",
        align: "center",
        render: (rowData) => {
          const statusMapped = getLrtTaskStatesMapping(
            rowData["closureStatus_5"]
          );
          if (statusMapped !== undefined) {
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                fullWidth
                tooltip={true}
                justify={"center"}
              />
            );
          } else return "-";
        },
      },
    ],
    detailCols: [
      {
        field: "id",
        title: labelPrefix + "transactionId",
        sorting: false,
      },
      {
        field: "taskType.taskID",
        title: labelPrefix + "taskType",
        sorting: false,
        default: true,
        render: (rowData) => {
          const statusMapped = getTaskTypeMapping(rowData["taskType"]);
          return statusMapped.label;
        },
      },
      {
        field: "dateStarted",
        title: labelPrefix + "requestDate",
        render: (rowData) => {
          if (rowData["dateStarted"]) {
            return <Moment format="L LT">{rowData["dateStarted"]}</Moment>;
          }
        },
      },
      {
        field: "dateRequest",
        title: labelPrefix + "toStart",
        render: (rowData) => {
          if (rowData["dateRequest"]) {
            return (
              <>
                <Moment format="L LT">{rowData["dateRequest"]}</Moment>
                <FormTooltip
                  title={
                    <Translate
                      needle={labelPrefix + "tooltip"}
                      variables={[
                        moment(rowData["dateStarted"]).format("L LT"),
                        rowData["taskType"].taskCoolOffMinutes > 0
                          ? (
                              rowData["taskType"].taskCoolOffMinutes / 60
                            ).toFixed(2)
                          : 0,
                      ]}
                    />
                  }
                  type={"info"}
                />
              </>
            );
          }
        },
      },
      {
        field: "createdBy",
        title: labelPrefix + "createdBy",
        sorting: false,
        emptyValueCustom: <Translate needle={labelPrefix + "system"} />,
        render: (rowData) => {
          if (rowData["createdBy"] && rowData["createdBy"] != "")
            return rowData["createdBy"];
          return <Translate needle={labelPrefix + "system"} />;
        },
      },
      {
        field: "state",
        title: labelPrefix + "state",
        sorting: false,
        default: true,
        nowrap: false,
        emptyValueJustify: "center",
        render: (rowData) => {
          const statusMapped = getLrtTaskStatesMapping(rowData["state"]);
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "dateCompleted",
        title: labelPrefix + "dateCompleted",
        render: (rowData) => {
          if (rowData["dateCompleted"]) {
            return <Moment format="L LT">{rowData["dateCompleted"]}</Moment>;
          }
        },
      },
    ],
  },
};

const ClosingAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSING_ACCOUNTS_FILTERS_SUCCESS: {
      return {
        ...state,
        closingAccountsList: {
          ...state.closingAccountsList,
          filtersLoading: false,
          filters: action.payload,
        },
      };
    }

    case CLOSING_ACCOUNTS_FILTERS_FAILURE: {
      return {
        ...state,
        closingAccountsList: {
          ...state.closingAccountsList,
          filtersLoading: false,
          filters: [],
        },
      };
    }

    case CLOSING_ACCOUNTS_FILTERS_REQUEST: {
      return {
        ...state,
        closingAccountsList: {
          ...state.closingAccountsList,
          filtersLoading: true,
          lastRequest: action,
        },
      };
    }

    case CLOSING_ACCOUNTS_PLAYERS_LIST_SUCCESS: {
      return {
        ...state,
        closingAccountsList: {
          ...state.closingAccountsList,
          playerList: action.payload,
          tableLoading: false,
        },
      };
    }
    case CLOSING_ACCOUNTS_PLAYERS_LIST_FAILURE: {
      return {
        ...state,
        closingAccountsList: {
          ...state.closingAccountsList,
          playerList: [],
          tableLoading: false,
        },
      };
    }

    case CLOSING_ACCOUNTS_PLAYERS_LIST_REQUEST: {
      return {
        ...state,
        closingAccountsList: {
          ...state.closingAccountsList,
          lastRequest: action,
          tableLoading: true,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default ClosingAccountsReducer;
