import { call, put, takeEvery } from "redux-saga/effects";
import { DEFAULT_LOCALE, LOGOUT_ACTION, LOGOUT_REQUEST } from "../constants";
import { localeHandler } from "../locale/actions";
import { resetCounter } from "../../AuthenticatedApp";
import usersService from "../admin/security/users/users-service";

const removeOperatorActivityListener = (it) => {
  it.removeEventListener("mousemove", resetCounter, false);
  it.removeEventListener("mousedown", resetCounter, false);
  it.removeEventListener("mouseover", resetCounter, false);
  it.removeEventListener("mouseout", resetCounter, false);
  it.removeEventListener("keypress", resetCounter, false);
  it.removeEventListener("DOMMouseScroll", resetCounter, false);
  it.removeEventListener("mousewheel", resetCounter, false);
  it.removeEventListener("touchmove", resetCounter, false);
  it.removeEventListener("MSPointerMove", resetCounter, false);
};

const logout = function* () {
  try {
    yield call(usersService.jwtLogout);
    sessionStorage.clear();
    let data = { result: false };
    removeOperatorActivityListener(window);
    yield put({
      type: LOGOUT_ACTION,
      payload: data,
    });
    yield put(localeHandler(DEFAULT_LOCALE));
  } catch (e) {
    // do nothing
  }
};

const LogoutSaga = function* logoutSaga() {
  yield takeEvery(LOGOUT_REQUEST, logout);
};

export default LogoutSaga;
