import { Box } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Table from "../../../../Layouts/Table/Table";
import { getContactVerification } from "../../../../store/players/individual-player/contact-verification/actions";
import { usePageActions } from "../../../../utilities/hooks/usePageActions";
import { useSession } from "../../../../utilities/hooks/useSession";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import { getLatestRegulatorStatus } from "../../../../store/players/individual-player/latest-regulator-status/actions";

function LatestRegulatorStatus({ widget }) {
  const { brandSelected, partnerSelected } = useSession();
  const match = useRouteMatch();
  const contractId = match.params.id;
  const dispatch = useDispatch();
  const { buttons } = usePageActions();
  const { columns, data, loading = true } = useSelector(
    (state) => state.LatestRegulatorStatusReducer
  );

  React.useEffect(() => {
    dispatch(
      getLatestRegulatorStatus(contractId, brandSelected.id, partnerSelected.id)
    );
  }, [
    dispatch,
    getContactVerification,
    contractId,
    brandSelected.id,
    partnerSelected.id,
    widget,
  ]);

  const t = useTranslator();

  return (
    <Box>
      <Table
        searchBar={false}
        isLoading={loading}
        type={"simple"}
        resize={false}
        pagination={false}
        columns={columns}
        data={data ? [data] : null}
        exportButton={false}
      />
    </Box>
  );
}

export default LatestRegulatorStatus;
