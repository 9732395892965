import { call, put } from "redux-saga/effects";

import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../../snackbars/actions";
import BonusAbuserHistoryService from "./bonus-abuser-history-service";
import {
  BONUS_ABUSER_HISTORY_FAILURE,
  BONUS_ABUSER_HISTORY_SUCCESS,
  BONUS_ABUSER_HISTORY_REQUEST,
  BONUS_ABUSER_MANAGEMENT_REQUEST,
} from "../../../../constants";
import { getPlayerDetail } from "../../player-detail/actions";

const getBonusAbuserHistory = function* (action) {
  try {
    const data = yield call(
      BonusAbuserHistoryService.getBonusAbuserHistory,
      action.contractId,
      action.brand,
      action.partner
    );
    yield put({
      type: BONUS_ABUSER_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: BONUS_ABUSER_HISTORY_FAILURE,
    });
  }
};

const managementBonusAbuser = function* (action) {
  const { bonusAbuser, contractId, brand, partner } = action;
  try {
    yield call(
      BonusAbuserHistoryService.addRemoveBonusAbuser,
      contractId,
      brand.id,
      partner.id,
      action.reason,
      bonusAbuser
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: bonusAbuser
            ? "label.individualplayer.bonusAbuserHistory.remove_success"
            : "label.individualplayer.bonusAbuserHistory.add_success",
        },
        options: {
          key: "ManagementBonusAbuserSuccess",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
    yield put({
      type: BONUS_ABUSER_HISTORY_REQUEST,
      contractId,
      brand: brand.id,
      partner: partner.id,
    });
    yield put(
      getPlayerDetail(contractId, brand.id, partner.id, partner.pam, action.cb)
    );
  } catch (e) {}
};

const BonusAbuserHistorySaga = function* bonusAbuserHistorySaga() {
  yield takeLatest(BONUS_ABUSER_HISTORY_REQUEST, getBonusAbuserHistory);
  yield takeLatest(BONUS_ABUSER_MANAGEMENT_REQUEST, managementBonusAbuser);
};

export default BonusAbuserHistorySaga;
