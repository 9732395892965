import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { removeSnackbar } from "../../store/snackbars/actions";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { useSession } from "../../utilities/hooks/useSession";
import UnsafeHtml from "../unsafe-html";

let displayed = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { brandSelected, partnerSelected } = useSession();
  const notifications = useSelector(
    (store) => store.SnackbarsReducer.notifications || []
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    closeSnackbar();
  }, [brandSelected, partnerSelected, closeSnackbar]);

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        if (message != null && message.constructor.name === "Object") {
          let variables = message.variables
            ? message.variables.map((it) => t({ needle: it }))
            : [];
          message = t({ needle: message.needle, variables: variables });
        }
        enqueueSnackbar(
          <UnsafeHtml dangerouslySetInnerHTML={{ __html: message }} />,
          {
            key,
            ...options,
            onClose: (event, reason, myKey) => {
              if (options.onClose) {
                options.onClose(event, reason, myKey);
              }
            },
            onExited: (event, myKey) => {
              // removen this snackbar from redux store
              dispatch(removeSnackbar(myKey));
              removeDisplayed(myKey);
            },
          }
        );

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

  return null;
};

export default Notifier;
