import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  LOTTERY_BLOCKLIST_FAILURE,
  LOTTERY_BLOCKLIST_FILTER_FAILURE,
  LOTTERY_BLOCKLIST_FILTER_REQUEST,
  LOTTERY_BLOCKLIST_FILTER_SUCCESS,
  LOTTERY_BLOCKLIST_REQUEST,
  LOTTERY_BLOCKLIST_SUCCESS,
} from "../../../constants";
import lotteryBlocklistService from "./lottery-blocklist-service";

const loadListFilter = function* (action) {
  try {
    const data = yield call(
      lotteryBlocklistService.getListFilter,
      action.brand,
      action.partner
    );
    yield put({
      type: LOTTERY_BLOCKLIST_FILTER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("lottery block list filters error", e);
    yield put({
      type: LOTTERY_BLOCKLIST_FILTER_FAILURE,
    });
  }
};

const loadLotteryBlocklist = function* (action) {
  try {
    let finalSearchTerms = null;
    if (action.searchTerms != null) {
      finalSearchTerms = {
        "date-from": action.searchTerms.added.from
          ? action.searchTerms.added.from
          : "",
        "date-to": action.searchTerms.added.to
          ? action.searchTerms.added.to
          : "",
        "attribute-type": action.searchTerms["attributeType"],
        "attribute-value": action.searchTerms["attributeValue"],
        "file-name": action.searchTerms["fileName"],
        addType: action.searchTerms["addType"],
      };
    }
    const data = yield call(
      lotteryBlocklistService.loadLotteryBlocklist,
      action.query,
      finalSearchTerms
    );
    yield put({
      type: LOTTERY_BLOCKLIST_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("lottery blocklist load", e);
    yield put({
      type: LOTTERY_BLOCKLIST_FAILURE,
    });
  }
};

const LotteryBlocklistSaga = function* LotteryBlocklistSaga() {
  yield takeLatest(LOTTERY_BLOCKLIST_REQUEST, loadLotteryBlocklist);
  yield takeLatest(LOTTERY_BLOCKLIST_FILTER_REQUEST, loadListFilter);
};

export default LotteryBlocklistSaga;
