import axios from "axios";

class SensitiveVipManagementService {
  async loadDetails(brand, partner, contractId) {
    let url = `/gov/api/rest/v1/player/sensitive-vip/${contractId}/details`;

    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for get sencitive VIP details");
  }

  async loadHistory(brand, partner, contractId) {
    let url = `/gov/api/rest/v1/player/sensitive-vip/${contractId}/history`;

    const response = await axios.get(url, {
      params: {
        brand: brand.id,
        partner: partner.id,
      },
    });
    if (response && response.data) {
      return response.data.collection;
    }
    throw Error("Invalid response for get sensitive VIP history");
  }

  async markUnmark(brand, partner, contractId, detail) {
    let url = `/gov/api/rest/v1/player/sensitive-vip/mark-unmark?contractId=${contractId}&brand=${brand.id}&partner=${partner.id}`;

    const response = await axios.put(url, detail);

    if (response && response.status !== 200) {
      throw Error("Invalid response for external account unlink");
    }
  }
}

const sensitiveVipManagementService = new SensitiveVipManagementService();
export default sensitiveVipManagementService;
