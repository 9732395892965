import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";

import {
  DEFAULT_LOCALE,
  LOCALE_ACTION,
  LOCALE_REQUEST,
  EXTERNAL_URL_REFRESH_KEY_ACTION,
} from "../constants";

const getLocale = async (requestedLocale) => {
  let localeProps = {};
  try {
    localeProps = await axios.get(
      process.env.PUBLIC_URL + "/locale/messages_" + requestedLocale + ".json"
    );
  } catch (e) {
    if(requestedLocale !== 'test') {
      console.log("[localSage.js] ... Local not found: ", requestedLocale);
      localeProps = await axios.get(
          process.env.PUBLIC_URL + "/locale/messages_" + DEFAULT_LOCALE + ".json"
      );
    }
    requestedLocale = DEFAULT_LOCALE;
  }
  return {
    i18n: (localeProps && localeProps.data) ? localeProps.data : {},
    locale: requestedLocale,
  };
};

const getLocaleRequests = function* (action) {
  const data = yield call(getLocale, action.payload);
  yield put({
    type: LOCALE_ACTION,
    payload: data,
  });
  yield put({
    type: EXTERNAL_URL_REFRESH_KEY_ACTION,
  });
};

const loadLocale = function* () {
  yield takeEvery(LOCALE_REQUEST, getLocaleRequests);
};

export default loadLocale;
