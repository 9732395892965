import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";

import {
  REFRESH_SIDE_MENU_ACTION,
  REFRESH_TREE_MENU_REQUEST,
} from "../constants";

const hardCodedMenu = require("./hardcoded.json");

const bamSideMenu = async ({ brandSelected, partnerSelected }) => {
  try {
    const response = await axios.get(
      "/gov/api/rest/v2/menuItems?actions=false&brandId=" +
        brandSelected.id +
        "&partnerId=" +
        partnerSelected.id
    );
    sessionStorage.setItem("fisrtMenuItem", response.data[0].label);
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getMenus = (menu) => {
  let r = menu ? [...menu] : [];
  for (let m of menu) {
    if (m.children && m.children.length > 0) {
      r = r.concat(getMenus(m.children));
    }
  }
  return r;
};

const refreshSideMenu = function* (action) {
  let data = yield call(bamSideMenu, action.payload);
  const installDemoRoutes = action.payload.installDemoRoutes || false;
  if (installDemoRoutes) {
    data = data.concat(hardCodedMenu);
  }
  const flatMenuItems = yield getMenus(data);
  const result = { menuItems: data, flatMenuItems };
  yield put({
    type: REFRESH_SIDE_MENU_ACTION,
    payload: result,
  });
};

const TreeSideMenuSaga = function* sideMenuSaga() {
  yield takeEvery(REFRESH_TREE_MENU_REQUEST, refreshSideMenu);
};

export default TreeSideMenuSaga;
