import {
  REASONS_REQUEST,
  REASONS_SUCCESS,
  REASONS_FAILURE,
  REASONS_SAVE_SUCCESS,
  REASONS_SAVE_FAILURE,
  REASONS_ACTIONS_FAILURE,
  REASONS_ACTIONS_SUCCESS,
  LANGUAGES_SUCCESS,
  LANGUAGES_FAILURE,
} from "../constants";
import YesNoBadge from "../../Layouts/BadgeStatus/yes-no-badge";
import React from "react";
const initialState = {
  reasons: {
    data: null,
    success: null,
    isloading: true,
    columns: [
      {
        field: "reasonPerAction",
        title: "label.clubCard.reasons.reasonAction",
      },
      {
        field: "reasonDescription",
        title: "label.clubCard.reasons.reasonDescription",
      },
      {
        field: "visibleToAll",
        title: "label.clubCard.reasons.visibleToAll",
        render: (rowData) => (
          <YesNoBadge value={rowData["visibleToAll"]} justify={"center"} />
        ),
      },
      {
        field: "language",
        title: "label.clubCard.reasons.language",
      },
      {
        field: "note",
        title: "label.clubCard.reasons.note",
      },
    ],
  },
  actions: {
    action: [],
    language: [],
    loading: true,
    searchCriteria: [],
  },
};

const ReasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case REASONS_REQUEST: {
      return {
        ...state,
        reasons: {
          ...state.reasons,
          loading: true,
        },
      };
    }
    case REASONS_SUCCESS: {
      return {
        ...state,
        reasons: {
          ...state.reasons,
          data: action.payload,
          isloading: false,
          success: true,
        },
      };
    }
    case REASONS_FAILURE: {
      return {
        ...state,
        reasons: {
          ...state.reasons,
          data: null,
          isloading: false,
        },
      };
    }
    case REASONS_SAVE_SUCCESS: {
      return {
        ...state,
        reasons: {
          ...state.reasons,
          success: true,
        },
      };
    }
    case REASONS_SAVE_FAILURE: {
      return {
        ...state,
        reasons: {
          ...state.reasons,
          isloading: false,
        },
      };
    }
    case REASONS_ACTIONS_SUCCESS: {
      return {
        ...state,
        actions: {
          ...state.actions,
          action: action.payload,
          loading: false,
        },
      };
    }
    case REASONS_ACTIONS_FAILURE: {
      return {
        ...state,
        actions: {
          ...state.actions,
          action: null,
          success: false,
          loading: false,
        },
      };
    }

    case LANGUAGES_SUCCESS: {
      return {
        ...state,
        actions: {
          ...state.actions,
          language: action.payload,
          loading: false,
        },
      };
    }
    case LANGUAGES_FAILURE: {
      return {
        ...state,
        actions: {
          ...state.actions,
          language: null,
          success: false,
          loading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default ReasonReducer;
