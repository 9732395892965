import React from "react";
import Modal from "../Modal/Modal";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import IconButton from "@material-ui/core/IconButton";

const LimitedFeatureButton = () => {
  const [limitedModalOpen, setLimitedModalOpen] = React.useState(false);

  return (
    <>
      <IconButton
        onClick={() => setLimitedModalOpen(true)}
        size={"small"}
        color={"primary"}
      >
        <InfoOutlinedIcon fontSize={"20px"} />
      </IconButton>
      <Modal
        open={limitedModalOpen}
        handleClose={() => setLimitedModalOpen(false)}
        title={"label.mobile_limited_feature.table.modal_title"}
        description={"label.mobile_limited_feature.table.modal_content"}
      />
    </>
  );
};

export default LimitedFeatureButton;
