import React from "react";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import { Box } from "@material-ui/core";
import FormTooltip from "../../../../Layouts/form/form-tooltip";
import {
  CLUB_CARD_HISTORY_SUCCESS,
  CLUB_CARD_HISTORY_FAILURE,
  CLUB_CARD_DETAILS_SUCCESS,
  CLUB_CARD_DETAILS_FAILURE,
  CLUB_CARD_DETAILS_REQUEST,
  CLUB_CARD_HISTORY_REQUEST,
  CLUB_CARD_ACTIONS_REQUEST,
  CLUB_CARD_ACTIONS_SUCCESS,
  CLUB_CARD_ACTIONS_FAILURE,
  REASONS_BY_ACTION_SUCCESS,
  REASONS_BY_ACTION_FAILURE,
  SAVE_PRINT_REQUEST_SUCCESS,
  SAVE_PRINT_REQUEST_FAILURE,
  ACTION_LOG_REQUEST_SUCCESS,
  ACTION_LOG_REQUEST_FAILURE,
  REGENERATE_CARD_SUCCESS,
  REGENERATE_CARD_FAILURE,
  DISABLE_ENABLE_CARD_SUCCESS,
  DISABLE_ENABLE_CARD_FAILURE,
  CARD_PROXY_ID,
  LEVEL,
  PRINT_TYPE,
  CURRENCY,
  AUTO_PAY,
  AUTHENTICATION_TYPE,
  PRINT_REQUEST_DATE,
  PRINT_REASON,
} from "../../../constants";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import Translate from "../../../../Layouts/Translator/Translate";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
const statusRequestMapping = (status) => {
  switch (status) {
    case "2":
      return { id: "2", label: "active" };
    case "3":
      return { id: "6", label: "disabled" };
    case "6":
    default:
      return { id: "5", label: "inactive" };
  }
};
const statusMapping = (statusRequested) => {
  switch (statusRequested) {
    case "REQUESTED":
      return { id: "1", label: "requested" };
    case "SENT":
      return { id: "1", label: "sent" };
    case "SUCCESS":
      return { id: "2", label: "success" };
    case "ERROR":
      return { id: "4", label: "error" };
    default:
      return { id: "1", label: "unknown" };
  }
};
const printRequestMapping = (request) => {
  return request === "YES" ? true : false;
};
const getTooltip = () => {
  let content = [
    {
      title: "0",
      description: "disable",
    },
    {
      title: "1",
      description: "enable_subset",
    },
    {
      title: "2",
      description: "enable",
    },
  ];
  return content;
};

const initialState = {
  clubCardHistory: {
    data: [],
    loading: true,
    columns: [
      {
        field: "cardId",
        title: "label.player.clubCardHistory.cardNumber",
      },
      {
        field: "level",
        title: "label.player.clubCardHistory.level",
      },
      {
        field: "creationDate",
        title: "label.player.clubCardHistory.creationDate",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["creationDate"]} />
        ),
      },
      {
        field: "creationReason",
        title: "label.player.clubCardHistory.creationReason",
      },
      {
        field: "printType",
        title: "label.player.clubCardHistory.printType"
      },
      {
        field: "toPrint",
        title: "label.player.clubCardHistory.toPrint",
        render: (rowData) => <YesNoBadge value={rowData["toPrint"]} />,
      },
      {
        field: "printRequestDate",
        title: "label.player.clubCardDetails.print.PrintRequestDate",
        render: (rowData) => {
          if (rowData["printRequestDate"] != null) {
            return (
              <DateFormatter
                format={"L LT"}
                value={rowData["printRequestDate"]}
              />
            );
          }
        },
      },
      {
        field: "printReason",
        title: "label.player.clubCardDetails.print.reasons",
      },
      {
        field: "status",
        title: "label.player.clubCardDetails.print.statusRequested",
      },
    ],
  },
  clubCardDetails: {
    dataDetails: null,
    isloading: true,
    reasons: [],
    fields: (printDaysAllowed) => [
      {
        field: CARD_PROXY_ID,
        title: "label.player.clubCardHistory.cardNumber",
      },
      {
        field: "status",
        title: "label.player.clubCardDetails.status",
        render: (rowData) => {
          const requestMapped = statusRequestMapping(rowData["status"]);
          return (
            <BadgeStatus
              id={requestMapped.id}
              label={requestMapped.label}
              justify={"center"}
            />
          );
        },
      },
      {
        field: LEVEL,
        title: "label.player.clubCardHistory.level",
        titleTooltip: getTooltip().map((t) => {
          return (
            <Box>
              <Box fontWeight={"fontWeightBold"}>
                {
                  <Translate
                    needle={"label.player.clubCardDetails.level." + t.title}
                  />
                }
              </Box>
              {
                <Translate
                  needle={"label.player.clubCardDetails.level." + t.description}
                />
              }
            </Box>
          );
        }),
      },
      {
        field: "dateInsert",
        title: "label.player.clubCardHistory.dateInsert",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["dateInsert"]} />
        ),
      },
      {
        field: "creationReason",
        title: "label.player.clubCardHistory.creationReason",
      },
      {
        field: CURRENCY,
        title: "label.player.clubCardDetails.currency",
        render: (rowData) => {
          if (rowData[CURRENCY]) {
            return (
              <CurrencyBadge currency={rowData[CURRENCY]} justify={"center"} />
            );
          }
        },
      },
      {
        5091: "AutoPay",
        title: "label.player.clubCardDetails.autoPay",
      },
      {
        field: PRINT_TYPE,
        title: "label.player.clubCardHistory.printType",
        render: (rowData) => {
          if (rowData && rowData[PRINT_TYPE]) {
            return rowData[PRINT_TYPE] === "true" ? (
              <Translate
                needle={"label.player.clubCardHistory.printTypePlastic"}
              />
            ) : (
              <Translate
                needle={"label.player.clubCardHistory.printTypeDigital"}
              />
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "printRequested",
        title: "label.player.clubCardDetails.print.PrintRequested",
        render: (rowData) => {
          const requestMapped = printRequestMapping(rowData["printRequested"]);
          return <YesNoBadge value={requestMapped} />;
        },
      },
      {
        field: PRINT_REQUEST_DATE,
        title: "label.player.clubCardDetails.print.PrintRequestDate",
        render: (rowData) => (
          <>
            <DateFormatter
              format={"L LT"}
              value={rowData[PRINT_REQUEST_DATE]}
            />
            <Box>
              <FormTooltip
                title={
                  <Translate
                    needle={"label.player.clubCardDetails.print.tooltip"}
                    variables={[printDaysAllowed]}
                  />
                }
              />
            </Box>
          </>
        ),
      },
      {
        field: PRINT_REASON,
        title: "label.player.clubCardDetails.print.reasons",
      },
      {
        field: "statusRequested",
        title: "label.player.clubCardDetails.print.statusRequested",
        render: (rowData) => {
          if (rowData["statusRequested"]) {
            const requestMapped = statusMapping(rowData["statusRequested"]);
            return (
              <BadgeStatus
                id={requestMapped.id}
                label={requestMapped.label}
                justify={"center"}
              />
            );
          }
        },
      },
      {
        field: AUTHENTICATION_TYPE,
        title: "label.player.clubCardDetails.authenticationType",
      },
    ],
    widgetColumns: [
      {
        field: CARD_PROXY_ID,
        title: "label.player.clubCardHistory.cardNumber",
      },
      {
        field: PRINT_TYPE,
        title: "label.player.clubCardHistory.printType",
        render: (rowData) => {
          if (rowData && rowData[PRINT_TYPE]) {
            return rowData[PRINT_TYPE] === "true" ? (
              <Translate
                needle={"label.player.clubCardHistory.printTypePlastic"}
              />
            ) : (
              <Translate
                needle={"label.player.clubCardHistory.printTypeDigital"}
              />
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: LEVEL,
        title: "label.player.clubCardHistory.level",
      },
      {
        field: AUTHENTICATION_TYPE,
        title: "label.player.clubCardDetails.authenticationType",
      },
      {
        field: AUTO_PAY,
        title: "label.player.clubCardDetails.autoPay",
      },
      {
        field: CURRENCY,
        title: "label.player.clubCardDetails.currency",
        render: (rowData) => {
          if (rowData[CURRENCY]) {
            return (
              <CurrencyBadge currency={rowData[CURRENCY]} justify={"center"} />
            );
          }
        },
      },
    ],
  },
  clubCardActions: {
    actionsData: [],
    actionsColumns: [
      {
        field: "transactionDate",
        title: "label.player.clubCardActions.transactionDate",
        render: (rowData) => (
          <DateFormatter format={"L LT"} value={rowData["transactionDate"]} />
        ),
      },
      {
        field: "userAction",
        title: "label.player.clubCardActions.userAction",
      },
      {
        field: "action",
        title: "label.player.clubCardActions.action",
        render: (rowData) => {
          return rowData["action"].toLowerCase();
        },
      },
      {
        field: "reason",
        title: "label.player.clubCardActions.reason",
      },
      {
        field: "transactionLog",
        title: "label.player.clubCardActions.transactionLog",
      },
    ],
  },
};

const ClubCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLUB_CARD_HISTORY_REQUEST: {
      return {
        ...state,
        clubCardHistory: {
          ...state.clubCardHistory,
          loading: true,
        },
      };
    }
    case CLUB_CARD_HISTORY_SUCCESS: {
      return {
        ...state,
        clubCardHistory: {
          ...state.clubCardHistory,
          data: action.payload,
          loading: false,
          success: true,
        },
      };
    }
    case CLUB_CARD_HISTORY_FAILURE: {
      return {
        ...state,
        clubCardHistory: {
          ...state.clubCardHistory,
          data: null,
          loading: false,
        },
      };
    }
    case CLUB_CARD_DETAILS_REQUEST: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          isloading: true,
        },
      };
    }
    case CLUB_CARD_DETAILS_SUCCESS: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          dataDetails: action.payload,
          isloading: false,
          success: true,
        },
      };
    }
    case CLUB_CARD_DETAILS_FAILURE: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          dataDetails: null,
          isloading: false,
        },
      };
    }
    case REASONS_BY_ACTION_SUCCESS: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          reasons: action.payload,
        },
      };
    }
    case REASONS_BY_ACTION_FAILURE: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          reasons: [],
          isloading: false,
        },
      };
    }
    case SAVE_PRINT_REQUEST_SUCCESS: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          success: true,
        },
      };
    }
    case SAVE_PRINT_REQUEST_FAILURE: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          isloading: false,
        },
      };
    }
    case CLUB_CARD_ACTIONS_REQUEST: {
      return {
        ...state,
        clubCardActions: {
          ...state.clubCardActions,
          loading: true,
        },
      };
    }
    case CLUB_CARD_ACTIONS_SUCCESS: {
      return {
        ...state,
        clubCardActions: {
          ...state.clubCardActions,
          actionsData: action.payload,
          loading: false,
          success: true,
        },
      };
    }
    case CLUB_CARD_ACTIONS_FAILURE: {
      return {
        ...state,
        clubCardActions: {
          ...state.clubCardActions,
          actionsData: null,
          loading: false,
        },
      };
    }
    case ACTION_LOG_REQUEST_SUCCESS: {
      return {
        ...state,
        clubCardActions: {
          ...state.clubCardActions,
          actionsData: action.payload,
          loading: false,
          success: true,
        },
      };
    }

    case ACTION_LOG_REQUEST_FAILURE: {
      return {
        ...state,
        clubCardActions: {
          ...state.clubCardActions,
          actionsData: null,
          loading: false,
        },
      };
    }

    case REGENERATE_CARD_SUCCESS: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          success: true,
        },
      };
    }

    case REGENERATE_CARD_FAILURE: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          isloading: false,
        },
      };
    }

    case DISABLE_ENABLE_CARD_SUCCESS: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          success: true,
        },
      };
    }

    case DISABLE_ENABLE_CARD_FAILURE: {
      return {
        ...state,
        clubCardDetails: {
          ...state.clubCardDetails,
          isloading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};
export default ClubCardReducer;
