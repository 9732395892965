import {
  CURRENCIES_SUCCESS,
  CURRENCIES_FAILURE,
  CURRENCIES_FULL_DATA_SUCCESS,
  CURRENCIES_FULL_DATA_FAILURE,
  PAM_CURRENCIES_FAILURE,
  PAM_CURRENCIES_SUCCESS,
  LOY_CURRENCIES_SUCCESS,
  LOY_CURRENCIES_FAILURE,
} from "../constants";

const initialState = {
  currencies: [],
  fullCurrencies: [],
  pamCurrencies: [],
};

const CurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENCIES_SUCCESS: {
      return {
        ...state,
        currencies: action.payload,
      };
    }
    case CURRENCIES_FAILURE: {
      return {
        ...state,
        currencies: null,
      };
    }

    case CURRENCIES_FULL_DATA_SUCCESS: {
      return {
        ...state,
        fullCurrencies: action.payload,
      };
    }
    case CURRENCIES_FULL_DATA_FAILURE: {
      return {
        ...state,
        fullCurrencies: null,
      };
    }
    case PAM_CURRENCIES_SUCCESS: {
      return {
        ...state,
        pamCurrencies: action.payload,
      };
    }
    case PAM_CURRENCIES_FAILURE: {
      return {
        ...state,
        pamCurrencies: [],
      };
    }
    case LOY_CURRENCIES_SUCCESS: {
      return {
        ...state,
        loyCurrencies: action.payload,
      };
    }
    case LOY_CURRENCIES_FAILURE: {
      return {
        ...state,
        loyCurrencies: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default CurrenciesReducer;
