import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getClubCardActions } from "../../store/players/individual-player/club-card/actions";

export const useClubCardActions = (brand, partner, contractId) => {
  const { actionsData, actionsColumns } = useSelector(
    (state) => state.ClubCardReducer.clubCardActions
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getClubCardActions(brand, partner, contractId));
  }, [dispatch, brand, partner, contractId]);

  return { actionsData, actionsColumns };
};
