import {
  LOYALTY_BONUSES_REQUEST,
  LOYALTY_CONVERT_REQUEST,
  LOYALTY_DETAILS_REQUEST,
  LOYALTY_LEVEL_CHANGE_REQUEST,
  LOYALTY_LEVEL_CONFIGURATION_REQUEST,
} from "../../../constants";

export const getLoyaltyDetails = (
  contractId
) => {
  return {
    type: LOYALTY_DETAILS_REQUEST,
    contractId
  };
};
