import React from "react";
import Chip from "@material-ui/core/Chip";
import { useCurrencyColor } from "../../utilities/hooks/useCurrencyColor";
import { useTranslator } from "../../utilities/hooks/useTranslator";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary1,
    borderRadius: theme.spacing(1),
  },
  label: {
    padding: theme.spacing(0, 1),
  },
}));
const CurrencyBadge = ({ currency, justify = "flex-start" }) => {
  const classes = useStyle();
  const t = useTranslator();
  const color = useCurrencyColor(currency);
  return (
    <Box display={"flex"} justifyContent={justify}>
      <Chip
        classes={{
          root: classes.root,
          label: classes.label,
        }}
        style={{ backgroundColor: color }}
        label={
          <Box fontWeight="fontWeightBold">
            {currency === "POINTS"
              ? t({ needle: "label.currencies.points" })
              : currency.toUpperCase()}
          </Box>
        }
      />
    </Box>
  );
};

export default CurrencyBadge;
