import {
  BONUS_BAG_TEMPLATES_SUCCESS,
  BONUS_TYPES_FAILURE,
  BONUS_TYPES_SUCCESS,
  BULK_TEMPLATE_FAILURE,
  BULK_TEMPLATE_SUCCESS,
  DEVICES_FAILURE,
  DEVICES_SUCCESS,
  GAME_DETAIL_FAILURE,
  GAME_DETAIL_SUCCESS,
  GAMES_SUCCESS,
  OPERATIONS_FAILURE,
  OPERATIONS_SUCCESS,
  ORGANIZATION_FAILURE,
  ORGANIZATION_SUCCESS,
  THRESHOLD_TYPES_FAILURE,
  THRESHOLD_TYPES_SUCCESS,
  VALIDITY_DATE_TYPE_FAILURE,
  VALIDITY_DATE_TYPE_SUCCESS,
  VOUCHER_TEMPLATES_FAILURE,
  VOUCHER_TEMPLATES_SUCCESS,
} from "../../../../constants";

const initialState = {
  operations: [],
  templates: [],
  gameDetail: {},
  validityDateType: [],
  bonusTypes: [],
  devices: [],
  thresholdTypes: [],
  gameCodes: {},
  organizations: [],
  voucherTemplates: [],
  bonusBagTemplates: [],
};

const SecondStepReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPERATIONS_SUCCESS: {
      return {
        ...state,
        operations: action.payload,
      };
    }
    case OPERATIONS_FAILURE: {
      return {
        ...state,
        operations: null,
      };
    }

    case BULK_TEMPLATE_SUCCESS: {
      return {
        ...state,
        templates: action.payload,
      };
    }
    case BULK_TEMPLATE_FAILURE: {
      return {
        ...state,
        templates: null,
      };
    }

    case BONUS_BAG_TEMPLATES_SUCCESS: {
      return {
        ...state,
        bonusBagTemplates: action.payload,
      };
    }

    case GAME_DETAIL_SUCCESS: {
      return {
        ...state,
        gameDetail: action.payload,
      };
    }
    case GAME_DETAIL_FAILURE: {
      return {
        ...state,
        gameDetail: null,
      };
    }

    case VALIDITY_DATE_TYPE_SUCCESS: {
      return {
        ...state,
        validityDateType: action.payload,
      };
    }
    case VALIDITY_DATE_TYPE_FAILURE: {
      return {
        ...state,
        validityDateType: null,
      };
    }
    case BONUS_TYPES_SUCCESS: {
      return {
        ...state,
        bonusTypes: action.payload,
      };
    }
    case BONUS_TYPES_FAILURE: {
      return {
        ...state,
        bonusTypes: null,
      };
    }
    case DEVICES_SUCCESS: {
      return {
        ...state,
        devices: action.payload,
      };
    }
    case DEVICES_FAILURE: {
      return {
        ...state,
        devices: null,
      };
    }
    case THRESHOLD_TYPES_SUCCESS: {
      return {
        ...state,
        thresholdTypes: action.payload,
      };
    }
    case THRESHOLD_TYPES_FAILURE: {
      return {
        ...state,
        thresholdTypes: null,
      };
    }
    case GAMES_SUCCESS: {
      let platformKey = action.platform;
      if (Array.isArray(action.platform)) {
        platformKey = action.platform
          .sort((a, b) => {
            return a.localeCompare(b, undefined, { sensitivity: "base" });
          })
          .join("-");
      }
      return {
        ...state,
        gameCodes: { ...state.gameCodes, [platformKey]: action.payload },
      };
    }

    case ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizations: action.payload,
      };
    }
    case ORGANIZATION_FAILURE: {
      return {
        ...state,
        organizations: null,
      };
    }
    case VOUCHER_TEMPLATES_SUCCESS: {
      return {
        ...state,
        voucherTemplates: action.payload,
      };
    }
    case VOUCHER_TEMPLATES_FAILURE: {
      return {
        ...state,
        voucherTemplates: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default SecondStepReducer;
