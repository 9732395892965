import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import Currency from "../Currency/Currency";

const AmountProgressBar = ({
  value,
  limit,
  currency,
  applyDivisorAmount = true,
}) => {
  const currentValue = value > limit ? limit : value;
  return (
    <Box component="div">
      <Box display="flex" justifyContent="center">
        <span>
          <Currency
            amount={value}
            currency={currency}
            applyDivisorAmount={applyDivisorAmount}
          />
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span style={{ fontWeight: "bold" }}>
          <Currency
            amount={limit}
            currency={currency}
            applyDivisorAmount={applyDivisorAmount}
          />
        </span>
      </Box>
      <LinearProgress
        variant="buffer"
        value={(currentValue / limit) * 100}
        valueBuffer={100}
        style={{ height: 10, borderRadius: 6 }}
      />
    </Box>
  );
};

export default AmountProgressBar;
