import { GAME_CATEGORIES_FAILURE, GAME_CATEGORIES_SUCCESS } from "../constants";

const INITIAL_STATE = {
  gameCategories: [],
};

const GameCategoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GAME_CATEGORIES_SUCCESS:
      return {
        ...state,
        gameCategories: action.payload,
      };

    case GAME_CATEGORIES_FAILURE:
      return {
        ...state,
        gameCategories: null,
      };

    default:
      return state;
  }
};

export default GameCategoriesReducer;
