import { call, put } from "redux-saga/effects";
import {
  SELF_EXCLUSION_FAILURE,
  SELF_EXCLUSION_HISTORY_FAILURE,
  SELF_EXCLUSION_HISTORY_REQUEST,
  SELF_EXCLUSION_HISTORY_SUCCESS,
  SELF_EXCLUSION_REQUEST,
  SELF_EXCLUSION_SUCCESS,
  UPDATE_SELF_EXCLUSION_FAILURE,
  UPDATE_SELF_EXCLUSION_REQUEST,
  UPDATE_SELF_EXCLUSION_SUCCESS,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import selfExclusionService from "./self-exclusion-service";

const loadSelfExclusion = function* (action) {
  try {
    const data = yield call(
      selfExclusionService.loadSelfExclusion,
      action.brand,
      action.partner,
      action.contractId
    );
    yield put({
      type: SELF_EXCLUSION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadSelfExclusion", e);
    yield put({
      type: SELF_EXCLUSION_FAILURE,
    });
  }
};

const loadSelfExclusionHistory = function* (action) {
  try {
    const data = yield call(
      selfExclusionService.loadSelfExclusionHistory,
      action.brand,
      action.partner,
      action.contractId,
      action.query
    );
    yield put({
      type: SELF_EXCLUSION_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loadSelfExclusion", e);
    yield put({
      type: SELF_EXCLUSION_HISTORY_FAILURE,
    });
  }
};

const updateSelfExclusion = function* (action) {
  try {
    const data = yield call(
      selfExclusionService.updateSelfExclusion,
      action.brand,
      action.partner,
      action.contractId,
      action.body
    );
    yield put({
      type: UPDATE_SELF_EXCLUSION_SUCCESS,
      payload: data,
    });
    yield put({
      type: SELF_EXCLUSION_REQUEST,
      contractId: action.contractId,
      brand: action.brand,
      partner: action.partner,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: action.body.playerSelfExcluded
            ? "ok.selfExclusion.add"
            : "ok.selfExclusion.remove",
        },
        options: {
          key: "updateSelfExclusion",
          variant: action.body.playerSelfExcluded ? "success" : "info",
          autoHideDuration: 2000,
        },
      })
    );
  } catch (e) {
    console.error("updateSelfExclusion", e);
    yield put({
      type: UPDATE_SELF_EXCLUSION_FAILURE,
    });
  }
};

const SelfExclusionSaga = function* SelfExclusionSaga() {
  yield takeLatest(SELF_EXCLUSION_REQUEST, loadSelfExclusion);
  yield takeLatest(UPDATE_SELF_EXCLUSION_REQUEST, updateSelfExclusion);
  yield takeLatest(SELF_EXCLUSION_HISTORY_REQUEST, loadSelfExclusionHistory);
};

export default SelfExclusionSaga;
