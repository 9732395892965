import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  LOAD_ADMIN_LIMITCONFIGURATION_REQUEST,
  LOAD_ADMIN_LIMITCONFIGURATION_SUCCESS,
  LOAD_ADMIN_LIMITCONFIGURATION_FAILURE,
  DELETE_LIMITCONFIGURATION_REQUEST,
  DELETE_LIMITCONFIGURATION_FAILURE,
  ENABLE_OR_DISABLE_LIMITCONFIGURATION,
  ENABLE_OR_DISABLE_LIMITCONFIGURATION_FAILURE,
  ADMIN_LIMIT_TYPES_SUCCESS,
  ADMIN_LIMIT_TYPES_FAILURE,
  NOT_APPROVATOR_ROLES_SUCCESS,
  NOT_APPROVATOR_ROLES_FAILURE,
  ADMIN_LIMIT_TYPES_REQUEST,
  NOT_APPROVATOR_ROLES_REQUEST,
  LIMIT_REWARD_TYPES_REQUEST,
  LIMIT_REWARD_TYPES_SUCCESS,
  LIMIT_REWARD_TYPES_FAILURE,
  ADD_LIMITCONFIGURATION_REQUEST,
  ADD_LIMITCONFIGURATION_FAILURE,
  ADD_LIMITCONFIGURATION_SUCCESS,
  EDIT_LIMITCONFIGURATION_REQUEST,
  EDIT_LIMITCONFIGURATION_SUCCESS,
  EDIT_LIMITCONFIGURATION_FAILURE,
} from "../../../constants";
import adminLimitConfigurationService from "./limit-configuration-service";
import { enqueueSnackbar } from "../../../snackbars/actions";
import { labelPrefix } from "./limit-configuration-reducer";

const loadlimitConfigurationList = function* (action) {
  try {
    const data = yield call(
      adminLimitConfigurationService.loadlimitConfigurationList
    );
    yield put({
      type: LOAD_ADMIN_LIMITCONFIGURATION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("admin security limit configuration error", e);
    yield put({
      type: LOAD_ADMIN_LIMITCONFIGURATION_FAILURE,
    });
  }
};

const deleteLimitConfiguration = function* (action) {
  try {
    yield call(
      adminLimitConfigurationService.deleteLimitConfiguration,
      action.limitId
    );
    yield put({
      type: LOAD_ADMIN_LIMITCONFIGURATION_REQUEST,
      force: true,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: `${labelPrefix}deleteLimitConfigurationSuccess`,
        },
        options: {
          key: "DeleteLimitConfig_success",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("DeleteLimitConfig", e);
    yield put({
      type: DELETE_LIMITCONFIGURATION_FAILURE,
    });
  }
};

const enableDisableLimit = function* (action) {
  try {
    const { enableDisableLimitdata, limitId } = action;
    yield call(
      adminLimitConfigurationService.enableDisableLimitConfig,
      enableDisableLimitdata,
      limitId
    );
    yield put({
      type: LOAD_ADMIN_LIMITCONFIGURATION_REQUEST,
      force: true,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            enableDisableLimitdata.enabled === true
              ? labelPrefix + "limitEnabled"
              : labelPrefix + "limitDisabled",
        },
        options: {
          key: "EnableDisableLimit",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("EnableDisableLimitConfig", e);
    yield put({
      type: ENABLE_OR_DISABLE_LIMITCONFIGURATION_FAILURE,
    });
  }
};

const getLimitTypes = function* (action) {
  try {
    const data = yield call(adminLimitConfigurationService.getLimitTypes);
    yield put({
      type: ADMIN_LIMIT_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADMIN_LIMIT_TYPES_FAILURE,
    });
  }
};

const getNotApprovatorRoles = function* (action) {
  try {
    const data = yield call(
      adminLimitConfigurationService.getNotApprovatorRoles,
      action.brand,
      action.partner
    );
    yield put({
      type: NOT_APPROVATOR_ROLES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: NOT_APPROVATOR_ROLES_FAILURE,
    });
  }
};

const getRewardTypes = function* (action) {
  try {
    const data = yield call(adminLimitConfigurationService.getRewardTypes);
    yield put({
      type: LIMIT_REWARD_TYPES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: LIMIT_REWARD_TYPES_FAILURE,
    });
  }
};

const addLimitConfiguration = function* (action) {
  try {
    yield call(
      adminLimitConfigurationService.addLimitConfiguration,
      action.addLimitdata,
      action.divisorAmount
    );

    yield put(
      enqueueSnackbar({
        message: {
          needle: `${labelPrefix}addLimitConfigurationSuccess`,
        },
        options: {
          key: "AddLimitConfig_success",
          variant: "success",
        },
      })
    );

    yield put({
      type: ADD_LIMITCONFIGURATION_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("AddLimitConfig", e);
    yield put({
      type: ADD_LIMITCONFIGURATION_FAILURE,
    });
  }
};

const editLimitConfiguration = function* (action) {
  try {
    yield call(
      adminLimitConfigurationService.editLimitConfiguration,
      action.editLimitdata
    );

    yield put(
      enqueueSnackbar({
        message: {
          needle: `${labelPrefix}editLimitConfigurationSuccess`,
        },
        options: {
          key: "EditLimitConfig_success",
          variant: "success",
        },
      })
    );

    yield put({
      type: EDIT_LIMITCONFIGURATION_SUCCESS,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("AddLimitConfig", e);
    yield put({
      type: EDIT_LIMITCONFIGURATION_FAILURE,
    });
  }
};

const AdminLimitConfigurationSaga = function* AdminLimitConfigurationSaga() {
  yield takeLatest(
    LOAD_ADMIN_LIMITCONFIGURATION_REQUEST,
    loadlimitConfigurationList
  );
  yield takeLatest(DELETE_LIMITCONFIGURATION_REQUEST, deleteLimitConfiguration);
  yield takeLatest(ENABLE_OR_DISABLE_LIMITCONFIGURATION, enableDisableLimit);
  yield takeLatest(ADMIN_LIMIT_TYPES_REQUEST, getLimitTypes);
  yield takeLatest(NOT_APPROVATOR_ROLES_REQUEST, getNotApprovatorRoles);
  yield takeLatest(LIMIT_REWARD_TYPES_REQUEST, getRewardTypes);
  yield takeLatest(ADD_LIMITCONFIGURATION_REQUEST, addLimitConfiguration);
  yield takeLatest(EDIT_LIMITCONFIGURATION_REQUEST, editLimitConfiguration);
};

export default AdminLimitConfigurationSaga;
